import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class BlankQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Heat Stroke</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Most common due to over-exertion on hot days, accidentally left in car, cat/kitten in dryer. Patients with Brachycephalic Airway Syndrome (BAS) are at greater risk.  Less commonly seen following seizures or muscle tremors (hypocalcemia, toxicity).</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Initial Diagnostics:</span> CBC, PT/PTT, blood smear (for nRBCs), chemistry with electrolytes (consider ionized calcium)</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Key points for communication:</span> Mortality rate of approximately 50%. Prolonged clotting times, acute renal injury and DIC, persistent hypoglycemia, &ge; 18 nRBCs/100 leukocytes are all factors associated with mortality. Patients that survive the first 48 hours typically do well.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Start cooling to target of 103.5&deg;F</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
            <li>
              When possible, cooling at home just prior to transport may improve outcome
            </li>
            <li>
              Consider immersion/ice bath or running cool water over patient and directing a fan towards patient
            </li>
            <li>
              Start IV fluids as soon as possible
            </li>
            <li>
              Do NOT cover with wet towel (which will insulate patient)
            </li>
            <li>
              Cool water enemas may help in severe cases, but will limit ability to measure rectal temperature
            </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place IV catheter</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider pulling blood for PCV/TS, PT/PTT, and other blood work at IV catheter placement
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
            </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Consider a 1/4 shock bolus of 15 mL/kg (cats) to 22 mL/kg (dogs) = {(this.state.weightkg*(15)).toFixed(0)} mL to {(this.state.weightkg*(22)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
            </li>
            <li>
              Distributive shock is common
            </li>
            <li>
              Consider norepinephrine if persistent hypotension despite fluid resuscitation
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Provide flow by oxygen</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>If seizures or tremors, treat appropriately</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          If seizures, give 0.25 mg/kg midazolam (5 mg/mL) = {(this.state.weightkg*(0.25)/5).toFixed(2)} mL IV.  Patients on phenobarbital may need 2 mg/kg. Hypoglycemia should be treated with dextrose.
          </li>
          <li>
          If tremors (secondary to mycotoxins or pyrethrins), give 55 to 220 mg/kg methocarbamol IV to effect. Consider starting with 110 mg/kg = {(this.state.weightkg*(110)/100).toFixed(0)} mL IV at 2mL/min (no faster), then give remainder to effect.
          </li>
          <li>
          If hypocalcemia (as in eclampsia), give 0.5 to 1.5 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1.5)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV over 5-10 minutes while monitoring ECG.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>If prolonged PT/PTT, administer plasma</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Fresh Frozen Plasma (FFP) is generally given at 10-30 mL/kg = {(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(30)).toFixed(0)} mL
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Administer anti-emetics and GI protectants to prevent vomiting/aspiration and further GI damage</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
           Consider maropitant at 1 mg/kg = {(this.state.weightkg*(1)/10).toFixed(2)} mL IV of 10 mg/mL maropitant
          </li>
          <li>
           Consider pantoprazole at 1 mg/kg = {(this.state.weightkg*(1)/4).toFixed(2)} mL IV of 4 mg/mL pantoprazole
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>If concern for GI bacterial translocation and sepsis, consider IV antibiotics</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>If concern for cerebral edema, consider mannitol</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
           Mannitol at 0.5 to 1 g/kg = {(this.state.weightkg*(0.5)/0.2).toFixed(1)} mL to {(this.state.weightkg*(1)/0.2).toFixed(1)} mL IV of 20% mannitol over 10-20 minutes.
          </li>
          <li>
            Do not give prior to fluid resuscitation.
          </li>
          <li>
            May be beneficial if AKI, can repeat 1-2 times over first 12 hours of hospitalization.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Hospitalization with reassessment over 48 or more hours</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Monitor BP, ECG, and urine (production and for myoglobinuria)
          </li>
          <li>
          Recheck PT/PTT, renal values, electrolytes and platelets q12-24h
          </li>
          </ul>
          </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p>Aroch et al. Peripheral Nucleated Red Blood Cells as a Prognostic Indicator in Heatstroke in Dogs. JVIM 2009.</p>
                    <p>Bruchim et al. Heat stroke in dogs: A retrospective study of 54 cases (1999–2004) and analysis of risk factors for death. JVIM 2006.</p>
                    <p>Teichmann et al. Heatstroke in dogs in southern Germany. A retrospective study over a 5.5-year period. Tierarztl Prax Ausg K Kleintiere Heimtiere 2014.</p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default BlankQS;
