import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import clawFoldSample from './skinCytologyImages/clawFoldSample.gif';
import clawFoldSmoosh from './skinCytologyImages/clawFoldSmoosh.gif';
import yeastClawFold from './skinCytologyImages/yeastClawFold.jpg';

export default function ClawFold() {

  return (

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Claw Fold Cytology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                Dogs with itchy feet often have yeast overgrowth at the base of their claws. Dogs with salivary staining (brown discolored paw fur), reported allergies, or reported foot licking and chewing should have their claw folds examined for yeast overgrowth.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Use the wooden end of a cotton-tipped applicator to collect the waxy sample from the base of the claw.</span> Multiple claws can be sampled for the same slide.  If there's not much waxy debris, you can sample with the moistened cotton end.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>3. As soon as you have a small amount of sample, transfer it to the slide</span>.  Smoosh all the collected waxy debris into a small area near the frosted portion at the base of the glass slide.  You need to be aggressive and smoosh it good so it doesn't fall off.  A small amount of sample can host a yeast party.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Claw fold sample collection</span></Typography>
                  <img src={clawFoldSample} alt="claw fold sample" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Claw fold slide preparation</span></Typography>
                  <img src={clawFoldSmoosh} alt="claw fold smoosh" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>4. Stain the slide</span>.  There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>5. Evaluate the slide under the microscope</span>.  The 10x lens can be used to find the area where you smooshed the samples. Find that spot, add a drop of immersion oil over the tape, and go to the 100x lens.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span></Typography>
                  <img src={yeastClawFold} alt="yeast claw fold" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>This infection was treated with twice weekly bathing and oral fluconazole.</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

  );
}
