import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FibroticMyopathy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Fibrotic Myopathy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        Often characterized by severe fibrosis of the gracilis muscle. Most commonly seen in German Shepard Dogs.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Characteristic gait in the right patient is usually enough to make a diagnosis. There is no good treatment.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.vetmed.wisc.edu/lab/corl/fibrotic-myopathy-information/" target="_blank" rel="noopener noreferrer">Genetics of fibrotic myopathy in the German Shepherd Dog</a> from University of Wisconsin includes good videos
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
