import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import NormalHipRads from './NormalHipRads';
import ChdRads from './ChdRads';
import HipLuxationRads from './HipLuxationRads';

import FHOimg from './images/orthopedic/hindlimb/hip_VD_FHO.jpg';

export default function HipRadiographs() {

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Hip Radiographs</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
        <Typography>
        Hip Radiographs: VD typically has hind limbs extended with rotation of femurs inward. Lateral should have affected limb on table with label next to this limb.
        </Typography>
        </Grid>
        <NormalHipRads />
        <ChdRads />
        <HipLuxationRads />
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Femoral Head Ostectomy (FHO)</span></div>
          <img src={FHOimg} alt="FHO post op" style={{maxWidth: "96%"}}/>
          <div>The head of the femur has been removed and a pain associated with bone-bone contact and osteoarthritis are gone.</div>
          </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
