import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import MyCytologyFlowChart from './CytologyFlowChart.pdf';

export default function ClinPathCyto() {
    return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="dex-torb-content"
              id="dex-torb-header"
            >
              <Typography>Cytology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto">
                  <Button href={MyCytologyFlowChart} variant="outlined" target="_blank" rel="noreferrer">Cytology Flow Chart <PictureAsPdfIcon /></Button>
                </Grid>
                <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={10} align="left">Fluid Cytology</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Low-Protein Transudate</TableCell>
                      <TableCell align="center">High-Protein Transudate</TableCell>
                      <TableCell align="center">Hemorrhage</TableCell>
                      <TableCell align="center">Non-septic Exudate</TableCell>
                      <TableCell align="center">Septic Exudate</TableCell>
                      <TableCell align="center">FIP</TableCell>
                      <TableCell align="center">Bilious Effusion</TableCell>
                      <TableCell align="center">Uroperitoneum</TableCell>
                      <TableCell align="center">Chylous Effusion</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow>
                        <TableCell align="left" style={{fontWeight: "700"}}>Gross Appearance</TableCell>
                        <TableCell align="center">Clear, Watery</TableCell>
                        <TableCell align="center">Staw to Pink, Watery to Serum-like, Clear to Turbid</TableCell>
                        <TableCell align="center">Bloody</TableCell>
                        <TableCell align="center">Serum-like to Mucoid, Cloudy</TableCell>
                        <TableCell align="center">Creamy, Cloudy, Flocculent, Thick</TableCell>
                        <TableCell align="center">Straw-colored to Yellow, Clear to Cloudy, Sticky (like serum)</TableCell>
                        <TableCell align="center">Yellow, Dark Green, Brown, Redish, Cloudy to Opaque, Serum-like or Thicker</TableCell>
                        <TableCell align="center">Clear to Dark Yellow</TableCell>
                        <TableCell align="center">Opalescent to Opaque, White or Pink</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{fontWeight: "700"}}>Specific Grav. / TS</TableCell>
                        <TableCell align="left">&le;1.018 / &le;2.5</TableCell>
                        <TableCell align="left">1.018-1.025 / 2.5-5.0</TableCell>
                        <TableCell align="left">&ge;1.025 / &ge;3.0</TableCell>
                        <TableCell align="left">&ge;1.025 / &ge;3.0</TableCell>
                        <TableCell align="left">&ge;1.025 / &ge;3.0</TableCell>
                        <TableCell align="left">1.018-1.050 / &ge;3.5 </TableCell>
                        <TableCell align="left">&ge;1.025 / &ge;3.0</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">&ge;1.018 / &ge;2.5</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{fontWeight: "700"}}>Cells</TableCell>
                        <TableCell align="left">Macrophages, small lymphocytes, rare non-degenerate neutrophils, rare mesothelial cells if chronic</TableCell>
                        <TableCell align="center">Macrophage, non-degenerate neutrophils, small lymphocytes (especially in cats with heart failure), erythrocytes, mesothelial cells if chronic</TableCell>
                        <TableCell align="center">Similar to peripheral blood</TableCell>
                        <TableCell align="center">Non-degenerate to mildly degenerate neutrophils, macrophages, erythrocytes, reactive mesothelial cells if chronic, +/- neoplastic cells</TableCell>
                        <TableCell align="center">Degenerate neutrophils +/- phagocytized bacteria, free bacteria, macrophages, mesothelial cells if chronic, erythrocytes</TableCell>
                        <TableCell align="center">Proteinaceous background, small lymphocytes, macrophages, plasma cells, non-degenerate neutrophils if chronic</TableCell>
                        <TableCell align="center">Bilirubin crystals, neutrophils, macrophages, +/- basophilic mucinous material, +/- bacteria if infectious cause</TableCell>
                        <TableCell align="center">+/- Bacteria if UTI</TableCell>
                        <TableCell align="center">Variable, higher with chronicity; Small lymphocytes predominate early, with non-degerate neutrophils, macrophages, and mesothelial cells increasing with chronicity</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{fontWeight: "700"}}>Special Tests</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">Compare PCV to that of peripheral blood</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">Abdominal effusion glucose &ge; 20 mg/dL lower than serum glucose: septic abdomen likely; Abdominal effusion lactate &ge; 2.5 mm/L and &ge;2 mmol/L higher than peripheral lactate: septic abdomen likely</TableCell>
                        <TableCell align="center">TP of 5-12 g/dL with A:G ratio of &le;0.8 (&le;0.45 is strong evidence)</TableCell>
                        <TableCell align="center">Bilirubin in fluid &ge;2x that of serum</TableCell>
                        <TableCell align="center">BUN in fluid = BUN in serum; Creatinine of fluid &ge; that of serum</TableCell>
                        <TableCell align="center">Triglyceride level of fluid &ge; of serum; Cholesterol level of fluid &le; of serum</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
    );
  }
