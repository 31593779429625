import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


export default function JuvenileHypoglycemia() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Juvenile Hypoglycemia</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Usually present as lethargic, ataxic, minimally responsive, or comatose small breed puppies; may present for seizures</Typography>

          <Typography><span style={{fontWeight: '700'}}>Differential diagnoses:</span> Infectious disease (including distemper, parvovirus, GI parasitism), congenital neurologic abnormality, portosystemic shunt</Typography>
          <Typography>
            <span style={{fontWeight: '700'}}>Diagnostics: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            BG - initially and at least hourly initially; FreeStyle Libre preferred for continuous monitoring
            </li>
            <li>
            CBC
            </li>
            <li>
            Chemistry
            </li>
            <li>
            Ammonia - increased if hemolysis or not run right away
            </li>
            <li>
            Bile Acid Panel (fasting and post-prandial)
            </li>
            <li>
            Abdominal Ultrasound (although may not be sensitive enough to identify some portosystemic shunts in tiny patients)
            </li>
            <li>
            SNAP Parvo (if GI signs or any suspicion)
            </li>
            <li>
            Distemper PCR (if respiratory, GI, neurologic signs suggestive)
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Place IV or jugular catheter</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Give a Dextrose bolus (Usually present with BG &lt;60)</span>
        </Grid>
        <Grid item xs={12}>
          <ul style={{marginTop: 0}}>
            <li>
            Give 0.5 to 1 mL/kg = {(weightkg*(0.5)).toFixed(2)} mL to {(weightkg*(1)).toFixed(2)} mL of 50% dextrose IV diluted 1:2 with sterile saline
            </li>
            <li>
            If no IV catheter, give dextrose (undiluted) onto the gums
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Start a 5% Dextrose CRI</span>
        </Grid>
        <Grid item xs={12}>
          <ul style={{marginTop: 0}}>
            <li>
            Start at 2-3 mL/kg = {(weightkg*(2)).toFixed(0)} mL to {(weightkg*(3)).toFixed(0)} mL if patient is euhydrated
            </li>
            <li>
            Can be bolused
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Consider maropitant at 1 mg/kg IV</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Consider fenbendazole (Panacur) dewormer at 50 mg/kg PO q24h for 3-5 days</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Consider L-Carnitine at 50-100 mg/kg q12h</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Anticipate 1-3 days in hospital, diagnostics should aim to rule out other underlying causes</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952329" target="_blank" rel="noopener noreferrer">Hypoglycemia handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

          <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};