import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function FelineBehaviorDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Common Drugs</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>Drug (dose range)</TableCell>
            <TableCell align="center" colSpan={2}>Starting Dose Range (mg)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">min</TableCell>
            <TableCell align="center">max</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>

        <TableRow>
          <TableCell align="center"><span style={{fontWeight: '900'}}>Amitriptyline</span> (0.5 - 2 mg/kg PO q12-24h)</TableCell>
          <TableCell align="left">{(weightKg*(0.5)).toFixed(2)}</TableCell>
          <TableCell align="left">{(weightKg*(2)).toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
          Anxiety, FLUTD/FIC (generally 5 mg/cat PO q24 in the evening, 10 mg/cat if clinical)
        </li>
        <li>
         Tablets: 10, 25, 50, 75, 100, 150 mg
        </li>
        </ul>
        </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="center"><span style={{fontWeight: '900'}}>Gabapentin</span> (3 - 10 mg/kg PO q12h)</TableCell>
          <TableCell align="left">{(weightKg*(3)).toFixed(2)}</TableCell>
          <TableCell align="left">{(weightKg*(20)).toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
          Decreases overall arousal and anxiety levels with few potential side effects
        </li>
        <li>
          Dose up to 5 mg/kg for anti-anxiety affects, 5-10 mg/kg for pain.  Can dose higher for pre-visit drug for fractious cats.
        </li>
        <li>
         Liquid: 250 mg/5mL = 50 mg/mL
        </li>
        </ul>
        </TableCell>
        </TableRow>


        </TableBody>




      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    This calculator is meant to double check your math.  It is not a substitute for calculating your patient's dose.  Always double check drug dosages and concentrations.  These rough dose ranges are based on clinical experience.
    </li>
    <li>
    Many of these drugs have side effects, reported adverse events, and drug interactions; be aware of these before use.
    </li>
    <li>
    Table modified from Overall Manual of Clinical Behavioral Medicine for Dogs and Cats (2013)
    </li>
    </ul>
    </Grid>
    </Grid>
    </AccordionDetails>
    </Accordion>

  );
}
