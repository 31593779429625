import React, { Component } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select } from '@mui/material';

class BlankQS extends Component {

  constructor(props) {
    super(props);
    this.state = {
      motor: '',
      brain: '',
      loc: '',

    }

    this.handleMotorChange = this.handleMotorChange.bind(this)
    this.handleBrainChange = this.handleBrainChange.bind(this)
    this.handleLocChange = this.handleLocChange.bind(this)

  }


  handleBrainChange(event) {
    this.setState({
      brain: event.target.value
    })
  }
  handleLocChange(event) {
    this.setState({
      loc: event.target.value
    })
  }  handleMotorChange(event) {
      this.setState({
        motor: event.target.value
      })
    }


  render() {
    return (
          <>
            <h1 className="calcH1">Modified Glascow Coma Scale</h1>

            <Grid container spacing={1}>

                                          <Grid item xs={12}>
                                          <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Motor Activity</InputLabel>

                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.motor}
                                            label="Motor Activity"
                                            variant="outlined"

                                            onChange={this.handleMotorChange}
                                          >
                                            <MenuItem style={{overflow: 'auto'}} value={6}>6) Normal gait, normal spinal reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={5}>5) Hemiparesis, tetraparesis, or decerebrate activity</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={4}>4) Recumbent, intermittent extensor rigidity</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={3}>3) recumbent, constant extensor rigidity</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={2}>2) Recoment, constant extensor rigidity with opisthotonus</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={1}>1) Recumbent, hypotonia of muscles, depressed or absent spinal reflexes</MenuItem>
                                          </Select>
                                          </FormControl>
                                          </Grid>
                                          <Grid item xs={12}>

                                          <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Brain Stem Reflexes</InputLabel>

                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.brain}
                                            label="Brain Stem Reflexes"
                                            variant="outlined"

                                            onChange={this.handleBrainChange}
                                          >
                                            <MenuItem style={{overflow: 'auto'}} value={6}>6) Normal PLRs and oculocephalic reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={5}>5) Slow PLRs and normal to reduce oculocephalic reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={4}>4) Bilateral unresponsive miosis with normal to reduced oculocephalic reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={3}>3) Pinpoint pupils with reduced to absent oculocephalic reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={2}>2) Unilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={1}>1) Bilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</MenuItem>
                                          </Select>
                                          </FormControl>
                                          </Grid>
                                          <Grid item xs={12}>
                                          <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Level of Consciousness</InputLabel>

                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.loc}
                                            label="Level of Consciousness"
                                            variant="outlined"

                                            onChange={this.handleLocChange}
                                          >
                                            <MenuItem style={{overflow: 'auto'}} value={6}>6) Occasional periods of alertness and responsive to environment</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={5}>5) Depression or delirium, capable of responding but response may be inappropriate</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={4}>4) Semicomatose, responsive to visual stimuli</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={3}>3) Semicomatose, responsive to auditory stimuli</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={2}>2) Semicomatose, responsive only to repeated noxious stimuli</MenuItem>
                                            <MenuItem style={{overflow: 'auto'}} value={1}>1) Comatose, unresponsive to repeated noxious stimuli</MenuItem>
                                          </Select>
                                          </FormControl>
                                          </Grid>
                                          <Grid item xs={12}>
                                          Score: <span style={{fontWeight: '700'}}>{(this.state.motor ? this.state.motor : 0) + (this.state.loc ? this.state.loc : 0) + (this.state.brain ? this.state.brain : 0) ? (this.state.motor ? this.state.motor : 0) + (this.state.loc ? this.state.loc : 0) + (this.state.brain ? this.state.brain : 0) : ''}</span>
                                          </Grid>
                      </Grid>

          </>

    )
  }


};

export default BlankQS;
