import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function TracheostomyProcedure() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Tracheostomy Procedure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Tracheostomy Tube Supplies:</span> 
            </Typography>
            <ul>
              <li>#15 scalpel blade</li>
              <li>Scalpel handle</li>
              <li>Metzenbaum scissors</li>
              <li>Mayo scissors</li>
              <li>2-0 nonabsorbable monofilament suture (or 2-0 PDS)</li>
              <li>2 small curved hemostatic forceps</li>
              <li>Olsen Hegar Needle Drivers</li>
              <li>Allis tissue forceps</li>
              <li>2” tape (with two pieces to be labeled “Up” and Down”)</li>
              <li>1 small Gelpi Retractor</li>
              <li>Multiple of several sizes of canine tracheostomy tubes</li>
              <li>Umbilical tape</li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Procedure:</span> 
            </Typography>
            <Typography>Patient is placed in dorsal recumbency&mdash;often unconscious or under general anesthesia. Endotracheal intubation should be considered if possible. In emergency situations (as when unable to intubate an unconscious patient), there is generally no need to clip or prep the site.
            </Typography>
            <ol>
              <li>Make a ventral midline incision just caudal to the cricoid cartilage (wings of atlas can be used as a landmark).  Be generous and give yourself 4 or more centimeters to better visualize the trachea.</li>
              <li>The midline raphe between paired sternohyoideus muscles is identified and dissected with metzenbaum scissors to expose tracheal rings.  The Gelpi is used to retract these muscles away and better expose the trachea.</li>
              <li>Make a stab incision at least a couple rings down from the cricoid cartilage between two tracheal rings. Use the scalpel blade to extend this incision laterally equidistant from midline (be generous).</li>
              <li>Place 2 loops of suture--1 above and 1 below the tracheostomy site.  Hold the sutures with the hemostatic forceps and label them “Up” and “Down” on two respective pieces of tape.  When the forceps are removed, the sutures can be identified and facilitate changing a clogged tube as needed.</li>
              <li>Place the tracheostomy tube into the hole and secure in place or tie around the neck with umbilical tape.</li>
              <li>Cutaneous tissue can be closed around the site if needed with a few quick cruciate sutures.</li>
            </ol>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Temporary Tracheostomy Tube Care:</span> 
            </Typography>
            <ol>
              <li>Careful suctioning of the tube every 4-6 hours to clear mucus</li>
                <ul>
                  <li>Moisten tube with 0.1 to 0.2 mL/kg saline</li>
                  <li>Keep supplies sterile to avoid introducing infection into compromised airway</li>
                  <li>Take care not to cause hypoxemia or vagal response</li>
                </ul>
              <li>Clean the tube every 12 hours</li>
               <ul>
                <li>Remove inner cannula, rinse with sterile saline, soak in 2% dilute chlorhexidine, rinse with sterile saline</li>
                <li>Remove and clean entire tube every 1-2 days</li>
                <li>Consider replacement with clean/autoclaved tube if available</li>
                <li>Clear around the tube every 6-12 hours as needed</li>
                <li>Replace bandage every 24 hours or more frequently if soiled</li>
               </ul>
              <li>Humidification of the airway</li>
                <ul><li>Saline nebulization every 4-6 hours</li></ul>
            </ol>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
