import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TearTestReading from './images/TearTestReading.gif';
import KCS from './images/KCS.gif';

export default function Kcs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Keratoconjunctivitis sicca (KCS)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Dry Eye (KCS)</span></div>
            <img src={KCS} alt="dry eye" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>Uncomfortable eye in an at-risk breed&mdash;think KCS. Not an at-risk breed&mdash;still think KCS.</div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography>
            Dogs with dry eye are uncomfortable, often have red eyes, and may produce extra eye goop (mucous).  The STT is used to diagnose this condition.  Tear replacers (drops or ointment) and tear stimulating medications are needed for life to protect the surface health of the eyes.  There is a surgery where one of the salivary ducts is moved and saliva is used to lubricate the eye.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Schirmer Tear Test</span></div>
            <img src={TearTestReading} alt="STT" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div><span style={{fontWeight: "700"}}>Normal dogs have an STT of &ge;15.</span>  10-15 may suggest keratoconjunctivitis sicca (KCS, dry eye). &le;10 confirms KCS.  This dog has KCS.</div>
          </Paper>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> Initially, treatment should include tear stimulant (Optimmune/cyclosporine, or tacrolimus) 3 times each day (taper to 1-2 times each day life long).  NeoPolyBac may be a good choice at initial diagnosis.  In cases without corneal ulceration, you may consider an ophthalmic steroid initially.
          </Typography>
          </Grid>          <Grid item xs={12}>
          <Typography>
          <a href="http://www.acvo.org/common-conditions-1/2018/2/2/dry-eye-keratoconjunctivitis-sicca" target="_blank" rel="noopener noreferrer">Dry Eye Handout</a> from the ACVO
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
