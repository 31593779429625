import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AcuteAbdomen() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Acute Abdomen</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography >
                    <span style={{fontWeight: "700"}}>Initial Approach:</span> Focused history and physical with abdominal palpation (as tolerated). IV catheter with fluid resuscitation, abdominal point of care ultrasound to rule out peritoneal effusion (+/- retroperitoneal effusion); radiographs to rule out GDV, gastric dilation, signs of retroperitoneal effusion, free gas (on lateral views, often see radiolucent bubbles between the diaphragm and liver or over the liver), signs of mechanical obstruction. Patient-side pancreatic lipase test may help rule out pancreatitis. Rule out uroabdomen by focused ultrasound of the bladder and peritoneal fluid analysis. Gallbladder evaluation by ultrasound may help rule out obstruction or partial obstruction of the common bile duct, mucocele, or GB rupture.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differentials for acute abdominal pain include (but are not limited to):</span> GDV, gastric dilation or food bloat (without volvulus), mesenteric volvulus, splenic torsion, Septic peritonitis, ruptured GI tract, ischemic GI tract, intussusception, uroabdomen, bile peritonitis, abdominal or retroperitoneal fluid accumulations, parvovirus enteritis, pyometra, prostatic abscess, pancreatitis, gastric ulceration with perforation, penetrating or blunt trauma
                    </Typography>
                  </Grid>              
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
