import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function RapidBacVet() {
  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>RapidBac Vet</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom>RapidBac Vet is a lateral flow immunoassay that is highly sensitive and specific for bacteriuria. It is a great step-down when culture and sensitivity testing is waived or when screening for bacteriuria is clinically helpful (prior to antibiotic treatment decision)</Typography>
                <Typography>Supporting Studies:</Typography>
                <ul>
                  <li>
                  Jacob et al. Diagnostic accuracy of a rapid immunoassay for point of-care detection of urinary tract infection in dogs. AJVR 2016.
                  </li>
                  <li>Byron. Evaluation of a Rapid Immunoassay for Point-of-Care Detection of Bacteria in Cat Urine (Abstract). ACVIM Forum 2017.</li>
                  <li>Grant et al. Diagnostic accuracy of a point-of-care test using voided urine samples for detection of bacteriuria in dogs with signs of lower urinary tract disease. JVIM 2021.</li>
                </ul>
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
