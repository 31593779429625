import React from 'react'
import { Grid, IconButton, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GiForeignBody from './GiForeignBody';
import GastricDilationVolvulus from './GastricDilationVolvulus';
import Splenectomy from './Splenectomy';
import Wounds from '../Emergency/WoundsTraumatic';
import AdditionalResources from './AdditionalResources';
import Ohe from './Ohe';
import Castration from './Castration';
import Dystocia from '../Reproduction/Dystocia';
import OphthalmicProcedures from './OphthalmicProcedures';
import Cystotomy from './Cystotomy';
import BrachycephalicAirway from './BrachycephalicAirway';
import PerinealHernia from './PerinealHernia';

export default function ClinPath() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto">
        <Button href="/clinical-notebook/anesthesia/checklist" variant="outlined">Anesthesia Checklist</Button>
      </Grid>
      <Grid item xs={12}>
        <BrachycephalicAirway />
        <Cystotomy />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Gastrointestinal System</Typography>
          </AccordionSummary>
          <AccordionDetails>
           <GastricDilationVolvulus />
            <GiForeignBody />
            <PerinealHernia />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Reproductive System</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Castration />
            <Dystocia />
            <Ohe />
          </AccordionDetails>
        </Accordion>
        <OphthalmicProcedures />
        <Splenectomy />
        <Wounds />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
