import React from 'react';

import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrachealCollapseRadiographs from '../Radiology/TrachealCollapseRads';

export default function TrachealCollapse() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Tracheal Collapse</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto" style={{paddingBottom: "20px"}}>
            <Button href="/quick-sheets/upper-airway-obstruction" variant="outlined">Upper Airway Obstruction Quick Sheet</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Self-perpetuating progressive problem that is multifactorial but largely due to weakening of the tracheal rings and laxity in the dorsal tracheal membrane especially in chondrodystrophic dogs
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Usually small breed dogs with a chronic progressive honking cough that worsens when excited or with exercise.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Chronic cough is usually related to respiratory disease; consider any combination of tracheobronchitis, tracheal collapse, left mainstem bronchial compression (due to left atrial enlargement); Acute cough is more common with pneumonia or pulmonary edema (secondary to heart failure); the presence of a heart murmur does not mean the cough is related to heart disease!
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> 
        </Typography>
        <ul>
          <li>
          Survey radiographs (also evaluate lower airways and size of heart; help rule out other disease; tracheal collapse is often dynamic and extrathoracic collapse may worsen on inspiration while intrathoracic collapse worsens on expiration)
          </li>
          <li>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Example Radiographs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TrachealCollapseRadiographs />
            </AccordionDetails>
          </Accordion> 
          </li>
          <li>
          Echocardiography to assess for and rule out pulmonary hypertension and illuminate the significance of a concurrent murmur (many small breed dogs with suspect tracheal collapse also have a MMVD).          </li>
          <li>
          Fluoroscopy - especially useful, but not widely available
          </li>
          <li>
          Tracheobronchoscopy
          </li>
          <li>
          Heartworm antigen testing
          </li>
          <li>
          Fecal testing (Baermann is seldom done but may help rule out lungworm)
          </li>
          <li>
          Urine antigen testing to MiraVista may help rule out fungal disease in select cases
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span>
        </Typography>
        <ul>
          <li>Medical management may include bronchodilator (theophylline), cough suppressants (cough tabs, butorphanol, hydrocodone), anti-inflammatory steroids, cerenia, weight loss, antibiotics (if indicated)</li>
          <li>Surgery, which some see as a salvage procedure, may not be a salvage procedure for THIS patient. Stents can be life-changing for the individual patient; potential complications can be discussed with the surgeon.</li>
        </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a href="https://www.acvs.org/small-animal/tracheal-collapse" target="_blank" rel="noopener noreferrer">Tracheal Collapse</a> from ACVS
          </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
