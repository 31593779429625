import React, { useState } from 'react';

import {Accordion, Grid, Paper, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Histo1 from './images/histoplasma1.jpg';
import HistoZoom from './images/histoplasmaZoom.jpg';
import Histo3 from './images/histoplasma2.jpg';

export default function Fungal() {
  const [refs, setRefs] = useState(false);

  return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Fungal</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Blastomycosis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      Blastomyces is a soil-dwelling fungus spanning across most of the eastern US. It often causes pulmonary infection, but can also cause disseminated infection. 
                      Skin lesions and regional lymphadenopathy are common and fungal organisms are easily found by FNA and cytology. 
                      Urine antigen testing is useful for diagnosis, treatment response, and relapse monitoring.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Foy et al.</Button> {refs ? " Serum and Urine Blastomyces Antigen Concentrations as Markers of Clinical Remission in Dogs Treated for Systemic Blastomycosis. JVIM 2014." : "" }</sup>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://www.cdc.gov/fungal/diseases/blastomycosis/causes.html" target="_blank" rel="noopener noreferrer">CDC Blastomycosis Maps</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://miravistalabs.com/" target="_blank" rel="noopener noreferrer">Mira Vista Labs</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952436" target="_blank" rel="noopener noreferrer">Blastomycosis</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>
             <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Coccidioidomycosis (Valley Fever)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Coccidioidomycosis or "Valley Fever" is caused by soil-dwelling fungus in the Southwest. Bone lesions are common in dogs and may appear similar to osteosarcoma cancer. Pulmonary infection or disseminated forms are possible.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://www.cdc.gov/fungal/diseases/coccidioidomycosis/maps.html" target="_blank" rel="noopener noreferrer">CDC Coccidioidomycosis Maps</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://miravistalabs.com/" target="_blank" rel="noopener noreferrer">Mira Vista Labs</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952695" target="_blank" rel="noopener noreferrer">Valley Fever in Dogs and Cats</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                </Grid>              
              </AccordionDetails>
             </Accordion>
             <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Histoplasmosis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Caused by a soil-dwelling fungus with a distribution spanning from the Northeast to Texas and the Midwest. Localized infection may affect the lungs or GI tract. GI infection with bowel wall thickening on ultrasound and overlapping signs with many common GI disorders (inflammatory bowel disease, protein losing enteropathy, colitis/enteritis). 
                    Disseminated infection affects multiple systems/organs. If skin, lymph nodes, or liver/spleen are aspirated or biopsied, fungal organisms may be seen and give definitive diagnosis. Fungal antigen testing (from MiraVista) is commonly done to rule out in endemic areas prior to steroid treatment for other more common chronic enteropathies.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://www.cdc.gov/fungal/diseases/histoplasmosis/maps.html" target="_blank" rel="noopener noreferrer">CDC Histoplasmosis Maps</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://miravistalabs.com/" target="_blank" rel="noopener noreferrer">Mira Vista Labs</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    Treatment: Often Itraconazole or fluconazole for 6 or more months (some will need for years or remainder of life). Urine antigen testing or serology may help guide treatment duration and monitor for relapse.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=10267110" target="_blank" rel="noopener noreferrer">Histoplasmosis in Dogs and Cats</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Histo1} alt="Histoplasma" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Histoplasma organisms are small with thick capsules</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={HistoZoom} alt="Histoplasma" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Zoomed image from previous</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Histo3} alt="Histoplasma" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Small cluster of Histopsma</Typography>
                  </Paper>
                  </Grid>
                </Grid>
              </AccordionDetails>
             </Accordion>
            </AccordionDetails>
          </Accordion>

  );
}
