import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Ple() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Protein Losing Enteropathy (PLE)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>PLE is a syndrome not a disease or diagnosis. Your work is not done once PLE is identified.</Typography>
            </Grid> <Grid item xs={12}>
            <Typography>Patients often present with weight loss, chronic diarrhea. Hypoalbuminemia and hypocalcemia are common. Ascites is common. Pleural and pericardial effusion are possible. Lymphangiectasia on ultrasound is supportive.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>After initial blood work and abdominal ultrasound, diagnostic considerations should include GI Panel (Texas A&M Gastrointestinal Lab), Histoplasma testing (MiraVista), and Vitamin D panel (MSU)</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>Treatment considerations should include: GI support (maropitant, capromorelin, metoclopramide, ondansetron), calcium supplementation (vit D supplementation also needed for many, ideally after Vit D panel), corticosteroids (start at or above 1mg/kg in severe cases), cyclosporine, Purina HA diet (lower insoluble fiber, better tolerated), clopidogrel, B12 supplementation</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
