import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AdditionalResources() {


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
          <Grid item xs={12}>
          <a href="https://www.aaha.org/anesthesia" target="_blank" rel="noopener noreferrer">2020 AAHA Anesthesia and Monitoring Guidelines for Dogs and Cats</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://catvets.com/guidelines/practice-guidelines/anesthesia-guidelines" target="_blank" rel="noopener noreferrer">2018 AAFP Feline Anesthesia Guidelines</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://vasg.org/index.htm" target="_blank" rel="noopener noreferrer">Veterinary Anesthesia & Analgesia Support Group</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://acvaa.org/veterinarians/guidelines/" target="_blank" rel="noopener noreferrer">ACVAA Guidelines and Position Statement Documents</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://wsava.org/global-guidelines/global-pain-council-guidelines/" target="_blank" rel="noopener noreferrer">WSAVA Pain Guidelines and Protocols</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://ivapm.org/" target="_blank" rel="noopener noreferrer">International Veterinary Academy of Pain Management</a>
          </Grid>
          <Grid item xs={12}>
          <a href="http://csu-cvmbs.colostate.edu/Documents/anesthesia-pain-management-pain-score-canine.pdf" target="_blank" rel="noopener noreferrer">CSU Canine Acute Pain Scale</a>
          </Grid>
          <Grid item xs={12}>
          <a href="http://csu-cvmbs.colostate.edu/Documents/anesthesia-pain-management-pain-score-feline.pdf" target="_blank" rel="noopener noreferrer">CSU Feline Acute Pain Scale</a>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
