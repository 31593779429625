import React from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function FluidTherapy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Fluid Therapy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto">
            <Button href="/calculators/canine-fluids" variant="outlined">Canine Fluids</Button>
          </Grid>
          <Grid item xs="auto">
            <Button href="/calculators/feline-fluids" variant="outlined">Feline Fluids</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>For hospitalized patients, always consider maintenance, replacement, and ongoing losses. For anesthetized patients, fluid administration helps prevent relative hypovolemia associated with vasodilatory drugs, preserves perfusion, and maintains patency of the IV catheter.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Replace peripheral IV catheters every 72-96 hours or when clinically indicated (damp, loosened, soiled, not patent). Flush q4h if not receiving continuous fluids. Normal saline is as effective as heparin for flushing peripheral IV catheters.</Typography>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Maintenance Rates</TableCell>
                <TableCell>Anesthetic Rates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Dogs</TableCell>
                <TableCell align="left">(132 x BW<sub>kg</sub><sup>0.75</sup>)/24 mL/hr OR 2-6 mL/kg/hr</TableCell>
                <TableCell align="left">Initially 5 mL/kg/hr; reduce by 25% q1h until maintenance reached</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Cats</TableCell>
                <TableCell align="left">(80 x BW<sub>kg</sub><sup>0.75</sup>)/24 mL/hr OR 2-3 mL/kg/hr</TableCell>
                <TableCell align="left">Initially 3 mL/kg/hr; reduce by 25% q1h until maintenance reached</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          <Typography style={{fontSize: ".8rem"}}>Table adapted from 2013 AAHA/AAFP Fluid Therapy Guidelines</Typography>

          </Grid>

          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://www.aaha.org/aaha-guidelines/fluid-therapy/fluid-therapy-guidelines/" target="_blank" rel="noopener noreferrer">2013 AAHA/AAFP Fluid Therapy Guidelines</a>
          </Typography>
          </Grid>


        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
