import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GDVSurgery from '../Notebook/Surgery/GDVSurgery';

class GdvQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Gastric Dilation and Volvulus (GDV)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span style={{fontWeight: '700'}}>Presentation:</span> unproductive retching, distended abdomen, painful on abdominal palpation, tachycardia, may be non ambulatory
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: '700'}}>Diagnostics:</span> right lateral radiograph, 3 view chest radiographs (14% have pneumonia, 4% have pulmonary nodule(s), 5% have cardiomegaly<sup>1</sup>), CBC, Chemistry, Lactate, PT/PTT, Blood Pressure, Continuous ECG
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Place 2 IV Catheters</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Give a Fluid Bolus.</span>
            <ul>
            <li>
            A fluid bolus of 10 to 20 mL/kg = {(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL.
            </li>
            <li>
            Continuous reassessment is key. Fluid resuscitate to acceptable BP and HR end points.
            </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Give Methadone or Fentanyl for pain.</span>
            <ul>
            <li>
            For fentanyl, 3-5 mcg/kg/hr after initial bolus = {(this.state.weightkg*(3)).toFixed(2)} mcg to {(this.state.weightkg*(5)).toFixed(2)} mcg = {(this.state.weightkg*(3)/50).toFixed(2)} mL to  {(this.state.weightkg*(5)/50).toFixed(2)} mL of 50 mcg/mL fentanyl.
            </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider a lidocaine CRI</span>
            <ul>
            <li>
            Start at 25 ug/kg/min = 1.5 mg/kg/hr {(this.state.weightkg*(1.5)).toFixed(2)} mg = {(this.state.weightkg*(1.5)/20).toFixed(2)} mL/hr of 2% (20 mg/mL) lidocaine.
            </li>
            <li>
            If ventricular arrhythmia, give 2 mg/kg lidocaine {(this.state.weightkg*(2)).toFixed(2)} mg = {(this.state.weightkg*(2)/20).toFixed(2)} mL of 2% (20 mg/mL) lidocaine IV slowly.
            </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Decompress the stomach</span>
          <ul>
          <li>
            Place a 16 or 18 ga catheter into the stomach over the area of greatest distention or tympany (find the biggest ping, clip and prep area, usually craniodorsal, often left side).  Press the abdominal body wall from the other side to keep the stomach from slipping away from your catheter.
          </li>
          <li>
            If surgery will be delayed, consider percutaneous T-fastener gastropexy for continuous decompression.<sup>2</sup>
          </li>
          <li>
          In some cases, orogastric tube placement may be performed prior to surgery. Take care not to damage compromised stomach tissue. Do not push hard against resistance. Staged decompression by orogastric tube placement under a separate anesthetic event (prior to surgery) may help delay surgical treatment and has not been associated with worse outcomes versus immediate surgical correction.
          </li>
          </ul>
          </Grid>
          <GDVSurgery />
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider continuous EKG post-operatively.</span>
            <ul>
            <li>
          If ventricular tachycardia persists despite fluids, lidocaine, and appropriate analgesia, consider sotalol at 1-2 mg/kg = {(this.state.weightkg*(1)).toFixed(1)} mg to {(this.state.weightkg*(2)).toFixed(1)} mg PO q12h (usually for 1 week post operatively).
            </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Monitor potassium post-operatively and supplement with IV fluids as needed.</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider continued monitoring of lactate every 12 hours.</span> Minimal improvement or increase should be concerning.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider postoperative antibiotics in select cases.</span>
            <ul>
            <li>
            Some recommend continuing a 1st generation cephalosporin for 2-3 days post-operatively.<sup>4</sup>
            </li>
            <li>
            Case-dependent escalation to Unasyn/Clavamox should be considered if significant gastric necrosis or pneumonia.
            </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Continue supportive care with gastroprotectants, prokinetics, antiemetics, and pain medications.</span>
            <ul>
            <li>
              Decreased gastric motility is common. Consider metoclopramide at 2 mg/kg/day = {(this.state.weightkg*(2)).toFixed(2)} mg/day = {(this.state.weightkg*(2)/120).toFixed(2)} mL/hr of 5 mg/mL metoclopramide.
            </li>
            <li>
              Early enteral nutrition is helpful and can be started 4-6 hours after recovery via NG tube.
            </li>
            <li>
              Avoid NSAID drugs.
            </li>
            </ul>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.acvs.org/small-animal/gastric-dilatation-volvulus" target="_blank" rel="noopener noreferrer">Gastric Dilation-Volvulus</a> <span style={{fontWeight: "500"}}>from ACVS</span>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Green et al. Preoperative thoracic radiographic findings in dogs presenting for gastric dilatation-volvulus (2000-2010): 101 cases. JVECC 2012.</p>

                <p><sup>2</sup>Fox-Alvarez et al. Evaluation of a novel technique involving ultrasound-guided, temporary, percutaneous gastropexy and gastrostomy catheter placement for providing sustained gastric decompression in dogs with gastric dilatation-volvulus. JAVMA 2019.</p>

                <p><sup>3</sup>Mackenzie et al. A retrospective study of factors influencing survival following surgery for gastric dilatation-volvulus syndrome in 306 dogs. JAAHA 2010.</p>

                <p><sup>4</sup>Rosselli. Updated Information on Gastric Dilatation and Volvulus and Gastropexy in Dogs. Vet Clin Small Anim 2021.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
        </Grid>


            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default GdvQS;
