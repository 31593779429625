import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import NeuroExam from './NeuroExam';
import CommonConditions from './CommonConditions';
import AdditionalResources from './AdditionalResources';
import ByLocalization from './ByLocalization';

export default function Neurology() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <NeuroExam />
        <CommonConditions />
        <ByLocalization />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
