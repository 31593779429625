import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function NerveSheathTumor() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Peripheral Nerve Sheath Tumor</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Unilateral forelimb lameness and muscle atrophy are the most common signs.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Brehm et al.</Button> {refs ? " A retrospective evaluation of 51 cases of peripheral nerve sheath tumors in the dog. JAAHA 1995." : "" }</sup> Palpate the armpit region in dogs with forelimb lameness to help assess for brachial plexus mass.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://vsso.org/peripheral-nerve-tumors" target="_blank" rel="noopener noreferrer">Peripheral Nerve Tumors</a> from the VSSO
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
