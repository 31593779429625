import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FineNeedleAspirate from './FineNeedleAspirate';

import HistopathPlasmacytoma from './images/histopathPlasmacytoma.jpg';
import CytologyPlasmacytoma from './images/cytologyPlasmacytoma.jpg';
import PeripheralBlood from './images/peripheralBlood.jpg'
import HemangiomaHisto from './images/hemangioma.jpg'

export default function DiagnosticTechniques() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Diagnostic Techniques</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FineNeedleAspirate />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Biopsy</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography>
                  A biopsy takes a larger piece of tissue from the tumor (incisional) or takes the entire tumor (excisional).  This allows better evaluation of the entire tumor than fine needle coring&mdash;which relies on a few cells falling off (exfoliating) into the needle.  Some tumor cells cannot be captured by coring with a needle.  Some tumors require histopathology to understand how they typically behave (and inform prognosis).  Additionally, a histopathology allows evaluation of tumor margins&mdash;whether or not we got all of the tumor or left some behind.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Plasmacytoma (cytology)</span></div>
                <img src={CytologyPlasmacytoma} style={{borderRadius:"50%", maxWidth: "96%"}} alt="cytology of plasmacytoma"/>
                <div>Here is a cytology sample of a plasmacytoma (obtained by fine needle coring). The round cells cluster together in sheets, but the architecture cannot be evaluated.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Plasmacytoma (histopathology)</span></div>
                <img src={HistopathPlasmacytoma} style={{borderRadius:"3%", maxWidth: "96%"}} alt="histology of plasmacytoma"/>
                <div>The architecture of the plasmacytoma can be evaluated on histopathology.</div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (cytology)</span></div>
                <img src={PeripheralBlood} style={{borderRadius:"50%", maxWidth: "96%"}} alt="peripheral blood"/>
                <div>Hemangiomas and hemangiosarcomas are vascular tumors. Fine needle coring will look like peripheral blood.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (histopathology)</span></div>
                <img src={HemangiomaHisto} style={{borderRadius:"3%", maxWidth: "96%"}} alt="histopath of hemangioma"/>
                <div>Notice that histopathology can show architecture and margins.</div>
                </Paper>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>BRAF</Typography>
            </AccordionSummary>
            <AccordionDetails>

            <ul>
            <li>
            Highly sensitive test for b-raf mutation associated with TCC/UC
            </li>
            <li>
            CADET BRAF-PLUS is 95% sensitive (CADET BRAF is 85% sensitive); &ge;99.5% specificity (no false positives)
            </li>
            <li>
            Requires 40mL of urine in a BRAF container (from Antech)
            </li>
            </ul>

            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
