import React from 'react'
import { Grid, IconButton } from '@mui/material';

import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

import ArrowBack from '@mui/icons-material/ArrowBack';


import AnesthesiaKeyPoints from './AnesthesiaKeyPoints';
import AnesthesiaChecklist from './AnesthesiaChecklistAccordion';
import CanineSedationProtocols from './CanineSedationProtocols';
import FelineSedationProtocols from './FelineSedationProtocols';
import DiseaseSpecificConsiderations from './DiseaseSpecificConsiderations';
import LocalBlocks from './LocalBlocks';
import Et from './EndotrachealTube';
import Phases from './Phases';
import Monitoring from './Monitoring';
import AdditionalResources from './AdditionalResources';

import NotebookNotes from '../NotebookNotes';



export default function Anesthesia() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto">
        <Button href="/calculators/emergency-drug-calculator" variant="outlined">Emergency Drugs</Button>
      </Grid>
      <Grid item xs="auto">
        <Button href="/calculators/dex-torb" variant="outlined">Dex/Torb (dog)</Button>
      </Grid>
      <Grid item xs="auto">
        <Button href="/calculators/feline-alfax" variant="outlined">Alfax IM (cat)</Button>
      </Grid>
      <Grid item xs={12}>
        <AnesthesiaKeyPoints/>
        <AnesthesiaChecklist/>
        <CanineSedationProtocols/>
        <FelineSedationProtocols/>
        <DiseaseSpecificConsiderations/>
        <LocalBlocks/>
        <Et/>
        <Phases/>
        <Monitoring/>
        <AdditionalResources/>
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
