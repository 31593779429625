import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GBMucocele from './GBMucocele';
import GBWallEdema from './GBWallEdemaAnaphylaxis';
import PyometraUterus from './PyometraUterus';


export default function AbdominalUltrasound() {
  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Abdominal Ultrasound</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Disease Specific Examples</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Gallbladder Wall Edema in Anaphylaxis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <GBWallEdema />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Gallbladder Mucocele</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <GBMucocele />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pyometra</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <PyometraUterus />
              </AccordionDetails>
            </Accordion>

            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
