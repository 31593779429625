import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PleuralEffusionRads from '../Radiology/PleuralEffusionRads';

export default function PleuralEffusion() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pleural Effusion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pleural-effusion" variant="outlined">Pleural Effusion & Thoracocentesis Quick Sheet</Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Thoracic Radiographs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PleuralEffusionRads />
                </AccordionDetails>
              </Accordion>
              </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
