import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Iliopsoas() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Iliopsoas Injury</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        Common, underdiagnosed cause of pelvic limb lameness. Palpate directly (runs from ventral vertebral bodies and cranioventral ilium to the lesser trochanter of the femur). Hip extension with abduction and hip extension with internal rotation may stretch iliopsoas and reveal pain. Attempting to pick the dog up from under the flanks may cause them to turn towards a painful iliopsoas.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.vet.cornell.edu/departments-centers-and-institutes/riney-canine-health-center/health-info/iliopsoas-injury" target="_blank" rel="noopener noreferrer">Iliopsoas injury</a> from Cornell University College of Veterinary Medicine
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
