import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


export default function CatAbscess() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Cat Bite Abscess</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs="auto" style={{paddingBottom: "20px"}}>
                    <Button href="/quick-sheets/abscess" variant="outlined">Cat Bite Abscess Quick Sheet</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Often seen in outdoor cats with or without history of fighting.</Typography>
                  <Typography>Be suspicious in any cat with a painful area on palpation or that has a fever.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography> Look for crust (scab) and painful swelling. Sedation for clip, clean, +/- lance and flush is often needed and will help confirm suspicion of an abscess.  FIV/FeLV test any at-risk cats.  Additional testing for systemic disease or endocrine disease may be warranted for some. X-ray images can rule out boney involvement or metal foreign bodies (shot with BB/pellet gun).
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Sedate to clip surrounding fur and lance and flush abscesses that have yet to rupture.
                  </li>
                  <li>
                  Consider Covenia (cefovecin).
                  </li>
                  <li>
                  Consider Onsior (robenacoxib).
                  </li>
                  <li>
                  Consider Cerenia and SC fluids in cats with elevated temperature or hyporexia.
                  </li>
                  <li>
                  An E-collar should be recommended if licking cannot be prevented and the cat should be kept indoors until healed.  Gabapentin may help facilitate indoor recovery.
                  </li>
                  <li>
                  FIV antibody testing should be performed in 60 days as testing at Day 1 will not show newly acquired infection.
                  </li>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
