import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/canineAvulsion.jpg';
import Rd2 from './images/canineAvulsionQuickFlap.jpg';
import Rd3 from './images/canineAvulsionFlap.jpg';

export default function Fcgs() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Canine Avulsion</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      Uncommon after dog fights or animal encounters (wild boars in my experience). Warn of oronasal fistulas and monitor for nasal discharge. Thorough sedation (with a dissociative such as ketamine and improvised mouth gag) or full anesthesia should be recommended to allow investigation and proper gingival flap creation/closure.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="Canine avulsion" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd2} alt="Gingival flap over removed canine" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>A quick closure under sedation. Many of these patients present with other wounds that also need to be addressed, so speed may be necessary if sedation is elected.</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd3} alt="Gingival flap over removed canine" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This patient was anesthetized which allowed thorough closure of the gingival flap.</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
