import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox, Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Glaucoma() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }

  const breeds = ['Afghan', 'Akita', 'Alaskan malamute', 'Basset hound', 'Beagle', 'Boston terrier', 'Bouvier des Flanders', 'Brittany', 'Bullmastiff', 'Cairn terrier', 'Chow chow', 
  'Cocker spaniel (English & American)', 'Dalmatian', 'Dandie Dinmont terrier', 'English springer spaniel', 'Flat-coated retriever', 'Fox Terrier', 'Giant schnauzer', 
  'Golden retriever', 'Great Dane', 'Greyhound', 'Irish setter', 'Italian greyhound', 'Keeshond', 'Leonberger', 'Maltese', 'Mastiff', 'Newfoundland', 'Norwegian elkhound', 
  'Old English Sheepdog', 'Petit Basset Griffon Vendeen', 'Poodle', 'Saluki', 'Samoyed', 'Shar-Pei', 'Shiba Inu', 'Siberian Husky', 'Welsh Springer Spaniel', 'Welsh Terrier'];

  return (
    <>
      <h1 style={{textAlign: "center"}}>Glaucoma</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} sx={{p:"10px"}}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Usually older pets; often present with pain, redness (episcleral injection), corneal edema and vascularization, and mydriasis with vision loss; the optic disc may appear cupped on fundic exam; confirm elevated intraocular pressure  (IOP) with tonometry</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700', paddingLeft: '10px'}}>Key Points:</Typography>
          <ul style={{marginTop: 0}}>
            <li>
            24-72 hours of very high IOP usually results in irreversible vision loss. Vision can be lost within 4 hours.
            </li>
            <li>
            Primary glaucoma is bilateral disease in dogs.
            </li>
            <li>
            When uveitis is present, expect low IOP. Normal IOP may indicate secondary glaucoma if signs of both uveitis and glaucoma.
            </li>
            <li>
            Medical therapy will usually fail, often within 1 year.
            </li>
            <li>
            Permanently blind eyes should be removed for patient comfort (may consider intravitreal gentamicin as alternative). Pain is variable with chronicity. Acute glaucoma is painful.
            </li>
            <li>
            Do not use Latanoprost if concerned for secondary glaucoma (as in lens luxation or secondary to uveitis).
            </li>
            <li>
            With anterior lens luxation (especially common in terriers), atropine can be used to attempt to dilate the pupil and displace the lens to the posterior chamber if immediate referral to an ophthalmologist is not possible.
            </li>
            <li>
            Cats often present with a blind, buphthalmic, nonpainful eye due to chronic secondary glaucoma. 
            </li>
            <li>
            Latanoprost does not work in cats.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700', paddingLeft: '10px'}}>Diagnostics:</Typography>
          <ul style={{marginTop: 0}}>
            <li>
            STT - Not usually performed in cats. Measured over 1 minute in dogs. Normal dogs have an STT of ≥15. 10-15 may suggest KCS. ≤10 confirms KCS.
            </li>
            <li>
            Fluorescein stain - if no uptake, does not rule out FHV-related corneal erosions in cats
            </li>
            <li>
            Tonometry -  Do not attempt if concerned for fragile or ruptured cornea. The lowest reading is most accurate. 10 - 20 mmHg is normal. It should always be ≤ 25 mmHg. 8 mmHg difference between eyes may be abnormal. ≥ 25 mmHg with vision loss is glaucoma. Low IOP is consistent with uveitis. Uveitis with normal IOP may suggest glaucoma.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sx={{m: '15px'}}>
          <Button href="https://vetnurseguide.com/eye-form" target="_blank" rel="noopener noreferrer" variant="outlined">Ophtho Exam Form</Button>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Place patient in E-collar right away</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700', paddingLeft: '10px'}}>For Primary or Secondary Glaucoma:</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Cosopt (Dorzolamide/Timolol)
          <ul style={{marginTop: 0}}>
            <li>
            Start at 1 drop q15 minutes for 1 hour
            </li>
            <li>
            Transition to 1 drop q8 hours
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Intravenous Mannitol 20%
          <ul style={{marginTop: 0}}>
            <li>
            To dehydrate the vitreous and pull fluid out of the globe for about 6 hours.
            </li>
            <li>
            1.5 g/kg = {(weightkg*(1.5)).toFixed(2)} g = {(weightkg*(1.5)/(0.2)).toFixed(2)} mL of (200 mg/mL, 20%) Mannitol IV over 15-20 minutes
            </li>
            <li>
            Must limit patient’s access to water
            </li>
            <li>
            Contraindicated if heart disease, DM, renal failure, uveitis (including hyphema)
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Neo/poly/dex 0.1% or Prednisolone acetate 1%
          <ul style={{marginTop: 0}}>
            <li>
            For inflammation and pressure-induced ischemia
            </li>
            <li>
            Start at 1 drop q6-8 hours
            </li>
            <li>
            Transition to 1 drop q12-24 hours
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Pain medication if needed
          <ul style={{marginTop: 0}}>
            <li>
            Consider Gabapentin at 10 mg/kg = {(weightkg*10).toFixed(1)} mg PO q8h
            </li>
            <li>
            With chronicity, glaucoma is usually not painful
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700', paddingLeft: '10px'}}>For uveitis-induced glaucoma:</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider addition of Dexamethasone at 0.1 mg/kg IV = {(weightkg*(0.1)/2).toFixed(2)} mL of 2 mg/mL Dexamethasone or {(weightkg*(0.1)/3).toFixed(2)} mL of Dexexamethasone-SP (Dex-SP) if you calculate Dex-SP at 3 mg/mL (this is debated for 4 mg/mL product which is equivalent to 3 mg/mL Dexamethasone)
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700', paddingLeft: '10px'}}>For acute primary glaucoma:</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider addition of Latanoprost
          <ul style={{marginTop: 0}}>
            <li>
            1 drop q15 minutes for first hour
            </li>
            <li>
            Transition to 1 drop q12 hours
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Start other eye on prophylactic medication (Timolol eye drops q12 hours) - This may delay onset by up to 3 years
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider addition of Dexamethasone at 0.1 mg/kg IV = {(weightkg*(0.1)/2).toFixed(2)} mL of 2 mg/mL Dexamethasone or {(weightkg*(0.1)/3).toFixed(2)} mL of Dexexamethasone-SP (Dex-SP) if you calculate Dex-SP at 3 mg/mL (this is debated for 4 mg/mL product which is equivalent to 3 mg/mL Dexamethasone)
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              Breeds Predisposed to primary glaucoma<sup>1</sup>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {breeds.map(breed => 
                  <Grid item xs={12} sm={6} md={4} key={breed}>
                    <p>{breed}</p>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sx={{padding: '10px'}}>
          <a href="https://ofa.org/diseases/eye-disease/blue-book/" target="_blank" rel="noopener noreferrer">OFA Blue Book</a> may be more up-to-date resource for breed-specific risks
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} sx={{padding: '10px'}}>
          <a href="https://www.acvo.org/common-conditions-1/2018/2/2/glaucoma" target="_blank" rel="noopener noreferrer">Glaucoma</a> <span style={{fontWeight: "500"}}>from ACVO</span>
        </Grid>
        <Grid item xs={12} sx={{padding: '10px'}}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=6097123" target="_blank" rel="noopener noreferrer">Glaucoma</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Maggs, Miller, and Ofri. Slatter's Fundamentals of Veterinary Ophthalmology, 4e. Saunders 2007.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>


        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
        <IconButton
          component={Link}
          size="large"
          color="primary"
          aria-label="back"
          aria-controls="back"
          aria-haspopup="false"
          to="/quick-sheets"
        >
          <ArrowBack />
        </IconButton>
      </Grid>
    </Grid>
  </>
)


};