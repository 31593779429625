import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import Hematology from './Hematology';
import Chemistry from './Chemistry';
import Urinalysis from './Urinalysis';
import Hemostasis from './Hemostasis';
import Cytology from './Cytology';


import AdditionalResources from './AdditionalResources';

export default function ClinPathChem() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Hematology />
        <Chemistry />
        <Urinalysis />
        <Hemostasis />
        <Cytology />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
