import * as React from 'react';
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ET() {
  return (

    <Accordion>

    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Endotracheal Tube Sizes</Typography>
    </AccordionSummary>
    <AccordionDetails>

<Accordion>
<AccordionSummary expandIcon={<ExpandMoreIcon />}>
  <Typography>Dogs</Typography>
</AccordionSummary>
<AccordionDetails>
<Grid container spacing={1}>
<Grid item xs={12}>
<TableContainer component={Paper}>
  <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">Weight (kg)</TableCell>
          <TableCell align="left">Size (mmid)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left">2</TableCell>
          <TableCell align="left">4.0 - 5.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">4</TableCell>
          <TableCell align="left">6.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">7</TableCell>
          <TableCell align="left">7.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">11</TableCell>
          <TableCell align="left">7.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">13</TableCell>
          <TableCell align="left">8.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">15</TableCell>
          <TableCell align="left">8.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">18</TableCell>
          <TableCell align="left">9.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">20</TableCell>
          <TableCell align="left">9.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">22</TableCell>
          <TableCell align="left">10.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">27</TableCell>
          <TableCell align="left">11.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">31</TableCell>
          <TableCell align="left">12.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">36</TableCell>
          <TableCell align="left">14.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">50 +</TableCell>
          <TableCell align="left">16.0 - 18.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
          <ul style={{padding: '0 1rem', margin: 0}}>
          <li>Palpate the patient's trachea or compare the distal end of the ET tube to the width of the patient's nasal septum.</li>
          <li>Length can be determined by measuring from the nose to the point of the shoulder (thoracic inlet).</li>
          <li>Go down in size for brachycephalic breeds</li>
          </ul>
      </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  </Grid>
  </Grid>
</AccordionDetails>
</Accordion>


<Accordion>
<AccordionSummary expandIcon={<ExpandMoreIcon />}>
<Typography>Cats</Typography>
</AccordionSummary>
<AccordionDetails>
<Grid container spacing={1}>
<Grid item xs={12}>
<TableContainer component={Paper}>
  <Table size="small">
<TableHead>
  <TableRow>
    <TableCell align="left">Weight (kg)</TableCell>
    <TableCell align="left">Size (mmid)</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  <TableRow>
    <TableCell align="left">1</TableCell>
    <TableCell align="left">3.0</TableCell>
  </TableRow>
  <TableRow>
    <TableCell align="left">2</TableCell>
    <TableCell align="left">3.5</TableCell>
  </TableRow>
  <TableRow>
    <TableCell align="left">3</TableCell>
    <TableCell align="left">4.0</TableCell>
  </TableRow>
  <TableRow>
    <TableCell align="left">4</TableCell>
    <TableCell align="left">4.5</TableCell>
  </TableRow>
  <TableRow>
    <TableCell align="left">5</TableCell>
    <TableCell align="left">5.0</TableCell>
  </TableRow>
  <TableRow>
    <TableCell colSpan={2}>
    <ul style={{padding: '0 1rem', margin: 0}}>
    <li>Palpate the patient's trachea or compare the distal end of the ET tube to the width of the patient's nasal septum.</li>
    <li>Length can be determined by measuring from the nose to the point of the shoulder (thoracic inlet).</li>
    </ul>
    </TableCell>
  </TableRow>
</TableBody>
</Table>
</TableContainer>
</Grid>
</Grid>
</AccordionDetails>
</Accordion>


</AccordionDetails>
</Accordion>

  );
}
