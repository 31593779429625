import React, { Component } from 'react';
import { Grid, InputAdornment, Divider, TextField, Checkbox, Button } from '@mui/material';

class AnaphylaxisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Allergic Reaction (Acute Hypersensitivity Reaction)</h1>
        <Grid container spacing={0} style={{textAlign: "left"}}>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Button href="/quick-sheets/anaphylaxis" variant="outlined">Anaphylaxis & Hypersensitivity Reactions Quick Sheet</Button>
          </Grid>
          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>



          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="epinephrine"
            inputProps={{ 'aria-label': 'epinephrine' }}
            />Consider <span style={{fontWeight: '700'}}>Epinephrine</span> at 0.01 mg/kg IM = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.01)).toFixed(2)} mL</span> of 1 mg/mL Epinephrine for this patient.<sup>1</sup>
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Maximum of 0.5 mg Epinephrine (0.5 mL of 1 mg/ml) for patients weighing &ge;40 kg
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="diphenhydramine"
            inputProps={{ 'aria-label': 'diphenhydramine' }}
            />Consider <span style={{fontWeight: '700'}}>Diphenhydramine</span> at 2 mg/kg IM = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/50).toFixed(2)} mL</span> of 50 mg/mL Diphenhydramine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider continuing PO at 2 - 4 mg/kg = {(this.state.weightkg*(2)).toFixed(1)} mg to {(this.state.weightkg*(4)).toFixed(1)} mg for this patient q8h.  25 mg diphenhydramine tablets are common over the counter (Benadryl or generic)
            </li>
            <li>
            Cetirizine or Hydroxyzine may be more effective.<sup>2</sup> Consider giving hydroxyzine (often cheaper than cetirizine and available in 10, 25, and 50 mg tablets) PO at 2 mg/kg = {(this.state.weightkg*(2)).toFixed(1)} mg for this patient q12h
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="diphenhydramine"
            inputProps={{ 'aria-label': 'diphenhydramine' }}
            />Consider <span style={{fontWeight: '700'}}>Famotidine</span> at 1 mg/kg SC or IV = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL Famotidine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider continuing PO at &ge;1 mg/kg = {(this.state.weightkg*(1)).toFixed(1)} mg for this patient q12h.
            </li>
            <li>
            Tablets are commonly 10 mg and 20 mg over the counter (Pepcid AC and Pepcid AC Maximum Strength)
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Dex SP"
            inputProps={{ 'aria-label': 'Dex SP' }}
            />Consider <span style={{fontWeight: '700'}}>Dexamethasone SP</span> at 0.15 to 0.3 mg/kg IV = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.15)/3).toFixed(2)} mL</span> to {(this.state.weightkg*(0.3)/3).toFixed(2)} mL of 3 mg/mL Dexamathasone (+ 1 mg/mL SP) for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Onset of action is several hours.
            </li>
            <li>
            Consider continuing PO Prednisone at 0.5 - 1 mg/kg = {(this.state.weightkg*(0.5)).toFixed(1)} mg to {(this.state.weightkg*(1)).toFixed(1)} mg for this patient q12h for 3 days then q24h for 3 days.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="cerenia"
            inputProps={{ 'aria-label': 'cerenia' }}
            />            Consider <span style={{fontWeight: '700'}}>Maropitant</span> at 1 mg/kg IV or SC = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL Maropitant for this patient.
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '500'}}>
            References:
            </Grid>
            <Grid item xs={12}>

              <p><sup>1</sup> Shumel et al. Anaphylaxis in dogs and cats. JVECC 2013.</p>

              <p><sup>2</sup> Banovic et al. Effect of diphenhydramine and cetirizine on immediate and late-phase cutaneous allergic reactions in healthy dogs: a randomized, double-blinded crossover study. Vet Dermatol 2020.</p>

            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>
        </Grid>
      </>
    )
  }


};

export default AnaphylaxisQS;
