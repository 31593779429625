import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Extractions() {

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Extractions</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <span style={{fontWeight: "700"}}>Strategically important teeth:</span> The canines (104, 204, 304, 404) and the carnassials (108, 208, 309, 409) should be saved if possible.
        </Grid>
        <Grid item xs={12}>
          <span style={{fontWeight: "700"}}>Tools:</span>  Fresh scalpel blade, Periosteal elevators, Dental elevators, luxators, a zombie instrument to protect gingival tissues from spinning drill bits, root tip picks, Monocryl to close
        </Grid>
        <Grid item xs={12}>
          <span style={{fontWeight: "700"}}>Gingival flaps:</span> envelope flap has most blood supply and least suturing; when flapping gingival tissue near upper canines, watch for the palatal artery on the mesial side; When creating a flap for extraction of the upper foruth premolar, watch for the papillary salivary duct near the distal root - could consider just making a mesial flap; can do Y-shaped flap off distal side of the lower canines; <span style={{fontWeight: "700"}}>Elevate flaps past the mucogingival junction - mucosa can stretch to 10x its length</span>.
        </Grid>
        <Grid item xs={12} paddingBottom="10px">
          Remove interseptal bone to help remove retained roots
        </Grid>
        </Grid>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Extraction Videos (YouTube)</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <a href="https://www.youtube.com/watch?v=xR7sCCAugeI" target="_blank" rel="noopener noreferrer">Maxillary Canine (Dog)</a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <a href="https://www.youtube.com/watch?v=ED0XINkQwbM" target="_blank" rel="noopener noreferrer">Maxillary M1 retained root (Dog)</a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <a href="https://www.youtube.com/watch?v=DrjeimmhUig" target="_blank" rel="noopener noreferrer">Mandibular Canine (Cat)</a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <a href="https://www.youtube.com/watch?v=UNNqwnHexyM" target="_blank" rel="noopener noreferrer">Mandibular Quadrant Extraction (Cat)</a>
              </Typography>
            </Grid>
          </Grid>
          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
      </Accordion>
    </>

  );
}
