import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RootSignature from './RootSignature';
import Haws from '../Ophthalmology/Haws';

export default function Horners() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Horner's Syndrome</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
            Characterized by ptosis (droopy upper eyelid), miosis (constricted pupil), enophthalmos (sunken eye), and protrusion of the third eyelid. This is due to loss of sympathetic innervation to the smooth muscles of the eye. Categorizing as pre-ganglionic or post-ganglionic can be accomplished with physostigmine or phenylephrine (often done by neurologists, you may not have these drops on your shelf). Aspects of your physical exam and initial diagnostics should attempt to localize the underlying cause if possible. About 50% (or more) of cases will be idiopathic; you will not find a cause, but they will resolve spontaneously.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Important aspects of your physical exam:
            </Typography>
            <ul>
              <li>
              Evaluate the globe for any sign of ocular trauma (uveitis, hyphema, periocular swelling or instability of bone)
              </li>
              <li>
              Retropulsion of the globe (masses or swellings behind the eye may limit retropulsion)
              </li>
              <li>
              Otoscopy exam
              </li>
              <ul>
                <li>
                Most will show vestibular signs as otitis media/interna must be present to cause Horner’s
                </li>
              </ul>
              <li>
              Evaluate for neck pain by palpation and range of motion (cervical spinal cord lesion may cause Horner’s)
              </li>
              <li>
              Watch for root signature or a two engine gait (associated with a C6-T2 lesion)
              </li>
              <ul><li><RootSignature/></li></ul>
              <li>
              Evaluate cutaneous trunci reflex (loss may indicate C8-T1 lesion)
              </li>
              <li>
              Palpate the armpit for masses/pain
              </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Initial diagnostics may include:
            </Typography>
            <ul>
              <li>
              Basic labs may include CBC, chemistry +/- UA
              </li>
              <li>
              3-view chest and neck radiographs (especially important to rule out a mediastinal mass)
              </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Bilateral disease is possible. Rule out Haw’s syndrome or dysautonomia (incredibly rare).
            </Typography>
            <Haws />
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951369" target="_blank" rel="noopener noreferrer">Horner's Syndrome in Cats and Dogs</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
