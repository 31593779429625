import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import demodex from './skinCytologyImages/demodex.gif';
import dss from './skinCytologyImages/dss.gif';

export default function DeepSS() {

  return (

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Deep Skin Scraping</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  This technique is often used to look for Demodex mites. These mites hang out deep in the skin around hair follicles and cause patchy, assymettrical hairloss that may progress to a generalized, symmetrical pattern (especially in puppies).
                </Grid>
                <Grid item xs={12}>
                  Black heads (comodones) or hair loss on the top of the feet (dorsal pedal alopecia) are good reasons to do a deep skin scrape. Demodex mites love comodones and are often associated with dorsal pedal alopecia. They also frequently present with hair loss around the eyes (periocular alopecia) or on the face. Little red bumps on the inside surface of puppy ears (papules on the concave surface of the pinna) may also appear with Demodex overgrowth.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Apply a few drops of mineral oil onto the site you will sample.</span>
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>2. Use the small end of a metal spatula to scrape with the direction of the hair until you see blood (capillary oozing).</span> This is not painful to your patient when performed correctly. Some use a scalpel blade and scrape perpendicular to the edge of the blade. This is not recommended, as it can easily cut a wiggly patient.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Deep Skin Scrape Technique</span></Typography>
                  <img src={dss} alt="dss" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>Scrape with the direction of the hair until you see capillary oozing (blood)</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Demodex at 100x</span></Typography>
                  <img src={demodex} alt="demodex" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>The classic cigar-shaped Demodex canis mite wiggling its stubby legs</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>3. Spread the sample onto a slide.</span> The oil, debris, and blood can be collected with the wide side of the spatula and spread onto the slide.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>4. Ideally, collect 3-5 samples from different sites.</span> This makes it more likely you will find the mite.  All of your samples can be combined on a single slide.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>5. Examine the slide under the microscope.</span>  No need to stain; this would just wash off the oil and mites.
                </Grid>
                </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>


  );
}
