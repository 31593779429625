import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Achilles() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Achilles Tendon Injury</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        Also know as the common calcaneal tendon, the Achilles tendon inserts on the calcaneus. May be acute or chronic with partial or acute rupture. With complete rupture, dogs and cats will have a "dropped" hock (plantigrade stance). You may be able to palpate the ruptured tendon. Radiographs may rule out other causes. Tendon ultrasound may be helpful.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Minor cases/partial ruptures may be treated with external support, but most will require surgery to reattach the tendon. Extended rest (6-12 weeks) and immobilization post-operatively are often necessary.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/achilles-tendon-injuries" target="_blank" rel="noopener noreferrer">Achilles’ Tendon Injuries</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
