import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }


  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="pet-name"
                  label="Name"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Drug (concentration)</TableCell>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Dose Range</TableCell>
              <TableCell colSpan={2} sx={{verticalAlign: "top", borderBottom: "none", paddingBottom: "0px"}}>Volume (mL)</TableCell>
            </TableRow>
            <TableRow sx={{borderTop:"none"}}>
              <TableCell align="left" sx={{paddingTop: "0px"}}>min</TableCell>
              <TableCell align="left" sx={{paddingTop: "0px"}}>max</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Dexmedetomidine (0.5 mg/mL)</TableCell>
            <TableCell align="left">0.005 - 0.010 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.005)/(0.5)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.01)/(0.5)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">+/- Ketamine (100 mg/mL)
            </TableCell>
            <TableCell align="left">1 - 2 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(1)/(100)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(2)/(100)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Butorphanol (10 mg/mL)</TableCell>
            <TableCell align="left">0.2 - 0.4 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.2)/(10)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.4)/(10)).toFixed(2) : ""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Reversal</TableCell>
          </TableRow>
            <TableRow>
              <TableCell align="left">Atipamezole (5 mg/mL)</TableCell>
              <TableCell align="left">0.05-0.1 mg/kg</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(0.05)/(5)).toFixed(2) : ""}</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(0.1)/(5)).toFixed(2) : ""}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    Cardiac patients should usually not be sedated with this protocol
    </li>
    <li>
    This combination will decrease heart rate, decrease cardiac output, and increase valvular regurgitation
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
