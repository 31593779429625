import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PneumoniaRads from '../Radiology/PneumoniaRads';

export default function Parvo() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pneumonia (Bacterial)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pneumonia" variant="outlined">Pneumonia Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Thoracic Radiographs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PneumoniaRads />
                </AccordionDetails>
              </Accordion>
              </Grid>
            <Grid item xs={12}>
            <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952113" target="_blank" rel="noopener noreferrer">Pneumonia Management in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
