import React from 'react';
import { Grid, Paper, } from '@mui/material';

import ElbowRlat from './images/orthopedic/forelimb/elbow_rLat.jpg';
import ElbowCrCd from './images/orthopedic/forelimb/elbow_CrCd.jpg';

export default function NormalStifleRads() {

  return (
          <>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Elbow Dysplasia</span></div>
          <img src={ElbowRlat} alt="Elbow dysplasia rads" style={{maxWidth: "96%"}}/>
          <img src={ElbowCrCd} alt="Elbow dysplasia rads" style={{maxWidth: "96%"}}/>
          <div>This case of elbow dysplasia is due to fragmented medial coronoid process with secondary osteoarthritis. Positioning could be better.</div>
          </Paper>
          </Grid>
          </>


  );
}
