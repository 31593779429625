import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { API } from "aws-amplify";
import {Link} from "react-router-dom"
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const sortByOptions = [
  {
    value: 'petName',
    label: 'Pet Name'
  },
  {
    value: 'updatedAt',
    label: 'Date Modified'
  },
  {
    value: 'createdAt',
    label: 'Date Created'
  }
];

const hospitalFilterOptions = [
  {
    value: 'inHospital',
    label: 'Hospitalized'
  },
  {
    value: 'allPatients',
    label: 'All'
  }
];


export default function Notes() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [expandedId, setExpandedId] = React.useState(-1);
  const [sortBy, setSortBy] = React.useState('petName');
  const [hospitalFilter, setHospitalFilter] = React.useState('inHospital')
  const [filteredSortedNotes, setFilteredSortedNotes] = useState([])
  const [searchEntry, setSearchEntry] = useState("")

  function handleSearch(e) {
    setSearchEntry(e.target.value);
    setFilteredSortedNotes(filterAndSortNotesThenSearch(hospitalFilter, sortBy, e.target.value));
  }

  function filterAndSortNotesThenSearch(f, s, search) {
    // take notes, filter them based on hospitalFilter
    let shallow_notes = notes
    if (f === 'inHospital') {
      shallow_notes = notes.filter(note => note.inHospital);
    }
    if (s==='petName') {
      shallow_notes.sort(function(a, b) {
        const nameA = a.petName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.petName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }
    else if (s==='updatedAt'){
      shallow_notes.sort(function(a, b) {
        const dateA = a.updatedAt;
        const dateB = b.updatedAt;
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }

        // dates must be equal
        return 0;
      });
    }
    else {
      //sort by date created
      shallow_notes.sort(function(a, b) {
        const dateA = a.createdAt; // ignore upper and lowercase
        const dateB = b.createdAt; // ignore upper and lowercase
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }

        // names must be equal
        return 0;
      });
    }
    if (search !== "") {
      // something in the search field
      shallow_notes = shallow_notes.filter(o => Object.keys(o).some(k => typeof o[k] === 'string' && k !== 'noteId' && k !== 'userId' && k !== 'admitDate' && o[k].toLowerCase().includes(search.toLowerCase())));
    }
    return shallow_notes
  };

  const handleExpandClick = (i) => {
    if (expandedId === -1 || expandedId !== i) {
      setExpandedId(i);
    } else {
      setExpandedId(-1);
    }
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value);
    setFilteredSortedNotes(filterAndSortNotesThenSearch(hospitalFilter, event.target.value, searchEntry))
  };

  const handleHospitalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHospitalFilter(event.target.value);
    setFilteredSortedNotes(filterAndSortNotesThenSearch(event.target.value, sortBy, searchEntry))
  };

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const notes = await loadNotes();
        notes.sort(function(a, b) {
          const nameA = a.petName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.petName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setNotes(notes);
        setFilteredSortedNotes(notes.filter(note => note.inHospital))
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadNotes() {
    return API.get("notes", "/notes");
  }

  function makeChecklist(stringChecklist) {
    const x = stringChecklist.split(']|[');
    let split_checklist=[];
    for (let i=0; i<x.length; i++) {
      const split_i = x[i].split('|');
      const e_item = split_i[0];
      const e_truth_string = split_i[1]
      split_checklist = split_checklist.concat([[e_item, e_truth_string]])
    }
    return (
      split_checklist.map(([x, y], i) =>
        <FormGroup key={x.concat(i.toString())}>
          <FormControlLabel sx={{color:'rgba(0,0,0,0.7)'}} control={<Checkbox sx={{p:0}} color="default" checked={y==='true' ? true : false}/>} label={x} />
        </FormGroup>
      )
    )
  }

  function renderNotesList(notes) {
    return (
      <Stack spacing={2}>
        <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="space-between" alignItems="center">
          <Grid item xs={4} sm={4} md={8}>
            <TextField
              inputProps={{
                spellCheck: false,
              }}
              id="search"
              value={searchEntry}
              label="Search"
              type="text"
              fullWidth
              onChange={(e) => handleSearch(e)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <TextField
            select
              id="hospitalFilter"
              value={hospitalFilter}
              label="Patients"
              fullWidth
              onChange={handleHospitalFilterChange}
            >
              {hospitalFilterOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <TextField
            select
              id="sortBy"
              value={sortBy}
              label="Sort By"
              fullWidth
              onChange={handleSortChange}
            >
              {sortByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Button size="large" variant="outlined" component={Link} to="/notes/new" color="inherit">New Note</Button>
        {filteredSortedNotes.map(({ noteId, petName, lastName, signalment, petWeight, chiefComplaint,
                      inHospital, admitDate, criticalNotes, healthHistory, problemList,
                      differentials, petDiagnostics, currentMedsTreatments, petPlan,
                      stringChecklist, createdAt, updatedAt},i) => (

          <Card elevation={3} key={noteId}>
            <CardHeader
              sx={{paddingBottom: 0}}
              action={
                <Stack direction='row'>
                  <IconButton aria-label="edit" component={Link} to={`/notes/${noteId}`} sx={{height: '60px', width: '60px'}}>
                    <EditIcon />
                  </IconButton>
                </ Stack>
              }
              title={lastName ? `${petName} ${lastName}` : `${petName}`}
              subheader={signalment}
              titleTypographyProps={{variant:'h5'}}
              subheaderTypographyProps={{variant:'h6' }}
            />
            <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
              {petWeight ?
              <Typography paragraph sx={{ lineHeight: 1, marginBottom:'5px', fontSize: '1.2rem' }}>
                {`${petWeight} (kg)`}
              </Typography>
              : ""}
              { problemList ?
                <>
                  <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Problem List</Divider>
                  <Typography sx={{ lineHeight: 1.5, marginBottom:'5px' }}>
                    {`${problemList}`}
                  </Typography>
                </>
                :
                <></>

              }
              { differentials ?
                <>
                  <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Differentials</Divider>
                  <Typography paragraph sx={{ lineHeight: 1.5, marginBottom:'5px' }}>
                    {`${differentials}`}
                  </Typography>
                </>
                :
                <></>
              }
              { criticalNotes ?
                <>
                  <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Critical Notes</Divider>
                  <Typography paragraph sx={{ lineHeight: 1.5, marginBottom:'5px' }}>
                    {`${criticalNotes}`}
                  </Typography>
                </>
                :
                <></>
              }
                            <Divider sx={{color: 'rgba(0,0,0,0.5)', marginBottom: '7px'}}/>
              {stringChecklist ?
              <>
                <div style={{ marginLeft: '10px'}}>
                  {makeChecklist(stringChecklist)}
                </div>
                <Divider sx={{color: 'rgba(0,0,0,0.5)', marginTop: '7px'}}/>
                </>
              : <></>}
              { inHospital?
                <FormGroup>
                  <FormControlLabel sx={{color:'rgba(0,0,0,0.7)', marginLeft: '0px', marginTop: '7px'}} control={<Checkbox sx={{p:0}} color="default" checked={inHospital}/>} label={'In Hospital'} />
                </FormGroup>
                :
                <></>
              }
            </CardContent>
            <CardActions disableSpacing sx={{ lineHeight: 1, padding: 0 }}>
              { admitDate ?
                <Typography sx={{ lineHeight: 1, marginLeft: '16px' }}>
                  Admittted: {new Date(admitDate).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                </Typography>
                :
                <></>
              }

              <ExpandMore
                expand={i === expandedId ? true : false}
                onClick={() => handleExpandClick(i)}
                aria-expanded={i === expandedId ? true : false}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={i === expandedId ? true : false} timeout="auto" unmountOnExit>
              <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                { healthHistory ?
                  <>
                    <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>History</Divider>
                    <Typography paragraph sx={{ lineHeight: 1, marginBottom:'5px' }}>
                      {`${healthHistory}`}
                    </Typography>
                  </>
                  :
                  <></>
                }
                { petDiagnostics ?
                  <>
                    <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Diagnositcs</Divider>
                    <Typography paragraph sx={{ lineHeight: 1, marginBottom:'5px' }}>
                      {petDiagnostics ? `${petDiagnostics}` : ""}
                    </Typography>
                  </>
                  :
                  <></>
                }
                { currentMedsTreatments ?
                  <>
                    <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Current Meds/Treatments</Divider>
                    <Typography paragraph sx={{ lineHeight: 1, marginBottom:'5px' }}>
                      {`${currentMedsTreatments}`}
                    </Typography>
                  </>
                  :
                  <></>
                }
                { petPlan ?
                  <>
                    <Divider sx={{color: 'rgba(0,0,0,0.5)', '&:before': {width: '0px'}, 'span':{paddingLeft:'0px'}}}>Plan</Divider>
                    <Typography paragraph sx={{ lineHeight: 1, marginBottom:'5px' }}>
                      {`${petPlan}`}
                    </Typography>
                  </>
                  :
                  <></>
                }
                <Divider sx={{color: 'rgba(0,0,0,0.5)', marginTop: '7px'}}/>
                <Typography>
                  Dated Modified: {new Date(updatedAt).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                </Typography>
                <Typography>
                  Date Created: {new Date(createdAt).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Stack>
    );
  }

  function renderLander() {
    return (
      <div>
        <h1>Login again</h1>
        <p>something happened</p>
      </div>
    );
  }

  function renderNotes() {
    return (
      <Container sx={{marginTop: '16px'}}>
        { isLoading
          ?
          <>
            <Typography variant="h3"><Skeleton animation="wave" /></Typography>
            <Typography variant="h1"><Skeleton animation="wave" /></Typography>
            <Typography variant="h1"><Skeleton animation="wave" /></Typography>
          </>
          :
          renderNotesList(notes)
        }
      </Container>
    );
  }

  return (
    <div>
      {isAuthenticated ? renderNotes() : renderLander()}
    </div>
  );
}
