import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

class ChfQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Congestive Heart Failure</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            <span style={{fontWeight: '700'}}>Presentation:</span> Respiratory distress with heart murmur, and signs of decreased perfusion (purple mucous membranes, tired, weak), may hear crackles on auscultation of lung fields, history of cough
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Ddx:</span> Tracheal collapse, Pneumonia, Feline Asthma, Pulmonary Hypertension, Anemia (with subsequent hypoxia), Trauma, Neoplasia, Other
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Decrease Stress"
            inputProps={{ 'aria-label': 'decrease stress' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen.</span>
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL to {(this.state.weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM for this patient.
            </li>
            <li>
            Place directly in oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in severely compromised patients.  Can give furosemide at presentation prior to diagnostics.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />3 view thoracic radiographs
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Consider DV instead of VD to reduce patient stress
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />CBC/Chemistry, 4dx (or HW test), SNAP Feline proBNP
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          Assess baseline renal values and electrolytes
          </li>
          <li>
           SNAP Feline proBNP may help rule out heart disease
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />IV catheter (if not already placed)
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            It is not indicated (and counterproductive) to give IV (or SC) fluids while on furosemide for treatment of pulmonary edema.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="decrease pulmonary edema"
            inputProps={{ 'aria-label': 'decrease pulmonary edema' }}
            /><span style={{fontWeight: '700'}}>Decrease pulmonary edema.</span>
          </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Furosemide at 1-4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/50).toFixed(2)} mL to {(this.state.weightkg*(4)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide IM or IV for this patient.
            </li>
            <li>
            If moderate, 2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide for this patient.
            </li>
            <li>
            If severe, 4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide for this patient.
            </li>
            <li>
            Repeat dose every 2-3 hours for 3 doses.
            </li>
            <li>
            Alternatively, may consider CRI at 0.1 to 1 mg/kg/hr = {(this.state.weightkg*(0.1)/50).toFixed(2)} mL/hr to {(this.state.weightkg*(1)/50).toFixed(2)} mL/hr of 50 mg/mL Furosemide for this patient.

            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              checked={this.state.checked}
              onChange={this.setChecked}
              color="primary"
              label="Emergency drug sheet printed"
              inputProps={{ 'aria-label': 'emergency drugs printed' }}
              /><span style={{fontWeight: '700'}}>Monitor respiratory rate.</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
              Normal is &le;30.  Once stable, can remove from oxygen and continue to monitor.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              checked={this.state.checked}
              onChange={this.setChecked}
              color="primary"
              label="Emergency drug sheet printed"
              inputProps={{ 'aria-label': 'emergency drugs printed' }}
              /><span style={{fontWeight: '700'}}>Start Pimobendan</span> once eating/able to tolerate oral medications.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            0.2 to 0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)).toFixed(2)} mg to {(this.state.weightkg*(.3)).toFixed(2)} mg</span> PO q12h
            </li>
            <li>
            Total daily dose of 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.5)).toFixed(2)} mg</span> can be split into different morning/evening dose.
            </li>
            <li>
            Vetmedin chewable tablets are available as: 1.25 mg, 2.5 mg, 5 mg, and 10 mg
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications for long-term management:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Furosemide (Salix)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 12 hours until otherwise directed.  This is a diuretic heart medication that may cause increased drinking and urination. Do not restrict water.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)).toFixed(2)} mg</span> q8-12h
            </li>
            <li>
            Furosemide (Salix) tablets are available as: 12.5 mg, 50 mg; generic 20 mg, 40 mg, 80 mg or 10 mg/mL solution
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Pimobendan (Vetmedin)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 12 hours until otherwise directed.  This is a heart medication and may be required for the rest of your pet's life.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            0.2 to 0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)).toFixed(2)} mg to {(this.state.weightkg*(.3)).toFixed(2)} mg</span>
            </li>
            <li>
            Total daily dose of 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.5)).toFixed(2)} mg</span> can be split into different morning/evening dose.
            </li>
            <li>
            Vetmedin chewable tablets are available as: 1.25 mg, 2.5 mg, 5 mg, and 10 mg
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Spironolactone (Aldactone)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours until otherwise directed.  This is a heart medication.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)).toFixed(2)} mg</span> q24h
            </li>
            <li>
            Available as 25 mg, 50 mg, 100 mg tablets
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Enalapril or Benazepril</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 12 hours until otherwise directed.  This is a heart medication.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
            0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.5)).toFixed(2)} mg</span> q12h
            </li>
            <li>
            Available as 2.5, 5, 10, 20, 40 mg tablets
            </li>
            <li>
            May now be considered optional
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Clopidogrel (Plavix) - FELINE</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give 1/4 tablet by mouth every 24 hours until otherwise directed.  This medication may reduce the risk of an abnormal blood clot due to your cat's heart disease.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
            Usually dosed at 18.75 mg per cat or 1/4 of one 75 mg tablet q24h.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Maropitant (Cerenia)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', paddingBottom: '0.8rem'}}>
              Give by mouth every 24 hours as needed to reduce nausea and improve appetite.  This medication has broad anti-inflammatory properties and may help your pet feel better.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Mirtazapine (Mirataz) transdermal ointment - FELINE</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Apply a thin strip onto inside of ear skin (length indicated on box) every 24 hours as needed to promote appetite.  This is a feel good drug and appetite stimulant.  Use a gloved finger. Apply 30-60 minutes before offering highly palatable foods.
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8501760" target="_blank" rel="noopener noreferrer">Congestive Heart Failure</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="http://cardiaceducationgroup.org/wp-content/uploads/2015/09/CEG_resp-rate_handout_FINAL.pdf" target="_blank" rel="noopener noreferrer">Resting Respiratory Rate</a> <span style={{fontWeight: "500"}}>from Cardiac Education Group</span>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Monitor your pet's breathing rate at home.  When your pet is resting or sleeping, the breathing rate should be &le;30 breaths per minute.  You will soon learn your pet's baseline breathing rate.  If this rate becomes consistently higher or you notice increase coughing or nostril flaring, prompt veterinary attention is necessary as this may indicate fluid in the lungs associated with congestive heart failure.  A journal or the Cardalis app may be helpful.
            </li>
            <li>
            Decrease activity at home.  Keep your pet calm and resting for the next few weeks.
            </li>
            <li>
            Do NOT offer salty treats.  An increase in dietary sodium can put your pet back in heart failure and back in the hospital.
            </li>
            <li>
            Your pet should always have free access to fresh water while on the diuretic medication (furosemide).  Do NOT restrict access to water.
            </li>
            <li>
            Schedule a follow up appointment for 7-10 days.  Recheck sooner if you have any concern or your pet is not doing well.  At this visit, we may recommend rechecking lab work, rechecking x-ray imaging of the chest, and adjusting medications.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>


            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default ChfQS;
