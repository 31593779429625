import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableBody, TableCell,TableHead, TableRow, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class IvddQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleSwitchChange(event) {
  this.setState({
    switchSevere: event.target.checked
  });
}

  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Intervertebral Disc Disease</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid item xs={12}>
        <span style={{fontWeight: '700'}}>Presentation:</span> Most suspect IVDD patients will present with back or neck pain with or without mild or moderate CP deficits and ataxia. More severe cases may be non-ambulatory with or without deep pain.
        </Grid>

        <Grid item xs={12}>
        <span style={{fontWeight: '700'}}>Ddx:</span> Spinal neoplasia, discospondylitis, spinal fracture/luxation, FCE, meningitis, other
        </Grid>

        <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Diagnostics:</span> Baseline labs including CBC/Chem/UA to assess systemic health; SNAP cPL; spinal radiographs; thoracic radiographs (met check); CT or MRI
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Mean % Recovery with Medial versus Surgical Management<sup>1</sup>
            </AccordionSummary>
            <AccordionDetails>
                <Paper>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{fontWeight: '700'}}>Clinical Signs</TableCell>
                        <TableCell align="left" style={{fontWeight: '700'}}>Medical Management (Mean % Recovery)</TableCell>
                        <TableCell align="left" style={{fontWeight: '700'}}>Surgical Management (Mean % Recovery)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow style={{backgroundColor: '#ffffcc'}}>
                        <TableCell align="left">Non-ambulatory paraparetic</TableCell>
                        <TableCell align="left">79%</TableCell>
                        <TableCell align="left">93%</TableCell>
                      </TableRow>
                      <TableRow style={{backgroundColor: '#ffe6cc'}}>
                        <TableCell align="left">Paraplegic with intact deep pain perception</TableCell>
                        <TableCell align="left">62%</TableCell>
                        <TableCell align="left">93%</TableCell>
                      </TableRow>
                      <TableRow style={{backgroundColor: '#ffcccc'}}>
                        <TableCell align="left">Paraplegic without intact deep pain perception</TableCell>
                        <TableCell align="left">10%</TableCell>
                        <TableCell align="left">61%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
          <Grid item>Less Severe</Grid>
          <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
          </Grid>
          <Grid item>More Severe</Grid>
        </Grid>
          {this.state.switchSevere ?
        <Grid container spacing={0}>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>CBC/Chem/UA</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          In some cases, i-STAT CHEM 8+ or similar, PCV/TS, and USG may be sufficient
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Thoracic Radigraphs (3 view)</span> for metastasis check
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>ECG and Blood Pressure</span> monitoring should be considered in cases of severe cervical myelopathy as neurogenic shock and cardiac arrhythmias are possible
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Spinal radiographs</span> if any suggestion of external trauma to the vertebral column.  May help rule out destructive neoplasia/infection or fracture/luxation.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>IV Catheter</span> and IV fluid therapy
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Dehydration leads to hypoperfusion of the spinal cord and makes patients worse candidates for anesthesia
          </li>
          <li>
          {(((this.state.weightkg**(0.75))*70)/(24)).toFixed(0)} mL/hr is the maintenance fluid rate (BW<sub>kg</sub><sup>0.75</sup>x70/24) for this patient. {(this.state.weightkg*(50)).toFixed(0)} mL is this patient's fluid deficit at 5% dehydration.  Consider {((((this.state.weightkg**(0.75))*70)/(24))+(this.state.weightkg*(0.05)*(1000)/(12))).toFixed(0)} mL/hr for the first 12 hours for patients at approximately 5% dehydration.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Analgesia</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Opioid analgesia is often preferred in hospital
          </li>
          <li>
          Be cautious of anti-inflammatory drugs (NSAIDs or steroids) as many primary or referring veterinarians have already given one of these medications.
          </li>
          <li>
          10 ug/kg Fentanyl = {(this.state.weightkg*(10)).toFixed(2)} ug or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(10)/50)).toFixed(2)} mL</span> of 50 ug/mL Fentanyl. This can be given as a bolus and then as an hourly CRI.  Dose may be increased incrementally to 50 ug/kg if needed or decreased stepwise to 2 ug/kg.  May combine with Ketamine and or Lidocaine.
          </li>
          <li>
          Fentanyl is advantageous due to its short elimination half life which allows for neuro re-evaluation.
          </li>
          <li>
          Another option, not to be administered concurrently with other opioids, is 0.1 mg/kg Hydromorphone = {(this.state.weightkg*(0.1)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.1)/2)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>2 mg/mL</span> Hydromorphone.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            /><span style={{fontWeight: '700'}}>Steroids are generally NOT considered beneficial</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Methylprednisolone sodium succinate (MPSS, Solumedrol) administered within the first 8 hours of the event may be neuroprotective in humans.<sup>7</sup>  In the few studies performed, this has not been associated with improved outcomes in dogs with IVDD.<sup>8,9</sup>
          </li>
          <li>
          In a retrospecitve study, prednisone was associated with lower quality of life and worse chance of successful outcome (more recurrence than NSAIDs).<sup>3</sup>
          </li>
          <li>
          Many primary or referring veterinarians have already given an NSAID or steroid.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>MRI (or CT when MRI is not available)</span>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Neurology/Surgery Consultation</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Time delay to decompressive surgery may affect outcome in certain populations of dogs with thoracolumbar disc extrusion.<sup>10</sup>  Timely referral is best in all patients that are unable to walk.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
          </Grid>
          <Grid item xs={12}>
            <span><a href="https://www.acvs.org/small-animal/intervertebral-disc-disease" target="_blank" rel="noopener noreferrer">IVDD handout</a> <span style={{fontWeight: "500"}}>from ACVS</span></span>
          </Grid>

        </Grid>
        :
        <Grid container spacing={0}>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>i-STAT 8+ or similar, PCV/TS, and USG</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider comprehensive Chemistry, CBC, and UA in older patients or those that may have other systemic disease
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Spinal radiographs</span>  to rule out destructive neoplasia/infection, fracture/luxation.  Should be performed if any suggestion of external trauma.
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          The ability of spinal radiographs to accurately identify the site of intervertebral disk extrusion is relative low with reports ranging from 35% to 61%.<sup>4-6</sup>
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Subcutaneous fluids</span> may be considered
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Dehydration leads to hypoperfusion of the spinal cord. Mild dehydration is very difficult to detect clinically without serial weights.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Immediate Analgesia</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Opioid analgesia is often preferred in hospital.
          </li>
          <li>
          Be cautious of anti-inflammatory drugs (NSAIDs or steroids) as many primary or referring veterinarians have already given one of these medications.  Many older patients may already be on an NSAID drug.
          </li>
          <li>
          0.3 mg/kg Butorphanol = {(this.state.weightkg*(0.3)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.3)/10)).toFixed(2)} mL</span> of 10 mg/mL Butorphanol.
          </li>
          <li>
          May improve sedation for diagnostic procedures in hospital.
          </li>
          <li>
          Another option, not to be administered concurrently with other opioids, is 20 ug/kg Buprenorphine = {(this.state.weightkg*(0.02)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.02)/0.3)).toFixed(2)} mL</span> of 0.3 mg/mL Buprenorphine which can be given IV, IM, SC or transmucosally.  Transmucosal buprenorphine can be administered by pet parents every 6-8 hours (although this can become expensive if the injectable form of the medication is prescribed).
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            />Consider injectable <span style={{fontWeight: '700'}}>NSAID analgesia</span><sup>2</sup>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          For otherwise healthy, euhydrated patients that have not alreday received NSAID or steroid medications.
          </li>
          <li>
          2.2 mg/kg carprofen (Rimadyl) = {(this.state.weightkg*(2.2)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(2.2)/50)).toFixed(2)} mL</span> of 50 mg/mL carprofen (Rimadyl) given SC.
          </li>
          <li>
          0.2 mg/kg meloxicam = {(this.state.weightkg*(0.2)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.2)/5)).toFixed(2)} mL</span> of 5 mg/mL meloxicam given SC may be a better choice for smaller patients that will go home with oral liquid meloxicam.  NOT to be administered concurrently with other NSAID drugs such as carprofen.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />In general, <span style={{fontWeight: '700'}}>Steroids are NOT considered beneficial</span> and should not be given
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Methylprednisolone sodium succinate (MPSS, Solumedrol) administered within the first 8 hours of the event may be neuroprotective in humans.<sup>7</sup>  In the few studies performed, this has not been associated with improved outcomes in dogs with IVDD.<sup>8,9</sup>
          </li>
          <li>
          In a retrospecitve study, prednisone was associated with lower quality of life and worse chance of successful outcome (more recurrence than NSAIDs).<sup>3</sup>
          </li>
          <li>
          Many primary or referring veterinarians have already given an NSAID or steroid.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            />Consider <span style={{fontWeight: '700'}}>Maropitant (Cerenia)</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Broadly anti-inflammatory, strong anti-nausea drug, may provide adjunct analgesia
          </li>
          <li>
          1 mg/kg = {(this.state.weightkg*(1)).toFixed(2)} mg or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(1)/10)).toFixed(2)} mL</span> of 10 mg/mL Maropitant given SC or IV.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Medications for outpatient care (CANINE):
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Carprofen (Rimadyl)</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            2.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(1)).toFixed(1)} mg</span> q12h
          </li>
          <li>
            4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(1)).toFixed(1)} mg</span> q24h
          </li>
          <li>
            Always round down your dose, can use 1/2 tablet increments
          </li>
          <li>
            Available as 25 mg, 75 mg, 100 mg
          </li>
          <li>
            Never combine with steroids or other NSAID drugs
          </li>
          <li>
            Also available as 50 mg/mL injectable solution: consider 2.2 to 4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(50)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(50)).toFixed(2)} mL</span> of 50 mg/mL injectable carprofen SC as first treatment.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Meloxicam</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 24 hours to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(1)).toFixed(2)} mg</span> or <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(1.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>1.5 mg/mL</span> oral solution q24h.
          </li>
          <li>
            0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(0.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>0.5 mg/mL</span> oral solution q24h.
          </li>
          <li>
            Always round down your dose
          </li>
          <li>
            Most commonly available as 1.5 mg/mL oral suspension
          </li>
          <li>
            Never combine with steroids or other NSAID drugs
          </li>
          <li>
            Also available as 5 mg/mL injectable solution: consider 0.1 to 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(5)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.2)/(5)).toFixed(2)} mL</span> of 5 mg/mL injectable meloxicam SC as first treatment.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Gabapentin</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            5-10 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(1)).toFixed(1)} mg</span> q8-12h
          </li>
          <li>
            Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
          </li>
          <li>
            Can round up dose and/or increase depending on patient response
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Maropitant (Cerenia)</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 24 hours.  This medication has broad anti-inflammatory properties and is also an anti-nausea medication.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Available as 16 mg, 24 mg, 60 mg, 160 mg tablets. Generally dosed &ge;1 mg/kg
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Amantadine</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 24 hours.  This is a pain medication.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            3-5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(3)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> q24h
          </li>
          <li>
            Available most commonly as 100 mg capsule. 10 mg/mL solution may be available
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Methocarbamol</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 8-12 hours.  This is a muscle relaxant. May cause sedation.
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            15-20 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(15)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(20)/(1)).toFixed(1)} mg</span> q8-12h
          </li>
          <li>
            Typically available as 500 mg tablets
          </li>
          <li>
            Lack of evidence; generally not considered standard therapy; likely over-used by general practioners
          </li>
          <li>
            Consider in cases with root signature (commonly presents as lameness or pain associated with thoracic limb)
          </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
          </Grid>
          <Grid item xs={12}>
            <a href="https://www.acvs.org/small-animal/intervertebral-disc-disease" target="_blank" rel="noopener noreferrer">IVDD handout</a> <span style={{fontWeight: "500"}}>from ACVS</span>
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4953012" target="_blank" rel="noopener noreferrer">IVDD handout</a> <span style={{fontWeight: "500"}}>from Veterinary partner</span>
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952768" target="_blank" rel="noopener noreferrer">Cervical (Neck) Disk Disease handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
              Strict rest for the next 4-6 weeks is the most important part of your pet's treatment plan. Avoid running, jumping, and playing. Allow short leash walks for elimination (bathroom habits) only for the first 3 or more weeks.
            </li>
            <li>
              Recheck promptly if your pet worsens or loses the ability to walk. Inability to walk may accompany inability to urinate which can become a life-threatening emergency.
            </li>
            <li>
              If your pet is not eating well, offer a wide variety of highly desirable foods multiple times each day. Elevating food and water dishes off the floor may help some pets with neck pain. You may need to offer food and water by hand to your pet, as they may not be able to or may not want to get up and move around.
            </li>
            <li>
              If your pet is eating well, reduce food by 25% during this period of strict rest to prevent weight gain. A prescription metabolic diet for weight loss may be beneficial.
            </li>
            </ul>
          </Grid>

        </Grid>

        }



        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup> Langerhuus and Miles. Proportion recovery and times to ambulation for non-ambulatory dogs with thoracolumbar disc extrusions treated with hemilaminectomy or conservative treatment: A systematic review and meta-analysis of case-series studies. The Veterinary Journal. 2017.</p>

                <p><sup>2</sup> Levine, et al. Evaluation of the Success of Medical Management for Presumptive Cervical Intervertebral Disk Herniation in Dogs. Vet Surg. 2007.</p>

                <p><sup>3</sup> Mann, et al. Recurrence rate of presumed thoracolumbar intervertebral disc disease in ambulatory dogs with spinal hyperpathia treated with anti-inflammatory drugs: 78 cases (1997-2000). JVECC. 2007.</p>

                <p><sup>4</sup> da Costa, et al. Diagnostic Imaging in Intervertebral Disc Disease. Fronteirs. 2020.</p>

                <p><sup>5</sup> Murakami, et al. Evaluation of the accuracy of neurologic data, survey radiographic results, or both for localization of the site of thoracolumbar intervertebral disk herniation in dogs. AJVR. 2014.</p>

                <p><sup>6</sup> Somerville, et al. Accuracy of Localization of Cervical Intervertebral Disk Extrusion or Protrusion Using Survey Radiography in Dogs. JAAHA. 2001.</p>

                <p><sup>7</sup> Hall et. al. Neuroprotection and acute spinal cord injury: a reappraisal. NeuroRX 2004.</p>

                <p><sup>8</sup> Culbert et. al. Complications associated with high-dose prednisolone sodium succinate therapy in dogs with neurological injury. JAAHA 1998.</p>

                <p><sup>9</sup> Boag and Drobatz. Complications of methylprednisolone sodium succinate in dachshunds with surgically treated intervertebral disc disease. JVECC 2001.</p>

                <p><sup>10</sup> Martin et. al. Same-day surgery may reduce the risk of losing pain perception in dogs with thoracolumbar disc extrusion. JSAP 2020.</p>


              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
        </Grid>


            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default IvddQS;
