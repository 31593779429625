import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function Seizures() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Seizures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/seizures" variant="outlined">Seizures Clinical Quick Sheet</Button>
            </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom>
            Seizures are due to abnormal electrical activity in the brain.
          </Typography>
          <Typography gutterBottom>
            Seizures prime the brain for more seizures.  In patients with multiple or severe seizures, it is usually suggested that pets stay hospitalized until they are seizure-free for 24 hours.
          </Typography>
          <Typography gutterBottom>
            Figuring out why the pet had a seizure is the most important aspect of preventing future seizures.
          </Typography>
          <Typography gutterBottom>
            Prolonged seizures (that last &ge; 5 minutes) carry the greatest risk of permanent damage.
          </Typography>
          <Typography>
          <a href="https://petparenthandouts.com/seizures" target="_blank" rel="noopener noreferrer">Seizures Handout</a> from Pet Parent Handouts
          </Typography>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
