import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Psyllium from './Psyllium';

export default function ChronicDiarrhea() {
    const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Chronic Diarrhea</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Diarrhea of 2 or more weeks duration is considered chronic. Should be classified as small bowel, large bowel, or mixed.</Typography>
          </Grid>

          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Small Bowel</TableCell>
                <TableCell>increased quantity, may contain melena, weight loss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Large Bowel</TableCell>
                <TableCell>increased frequency, straining (tenesmus) and urgency, mucus, frank blood, rarely see weight loss (except in Boxers with granulomatous colitis)</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Typography>Infectious or diet-responsive causes are more common in young animals. Neoplasia, IBD, and hyperthyroidism (feline) are more common in older animals.</Typography>
          </Grid>

          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Canine differentials</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Extra-GI</TableCell>
                <TableCell>hepatobiliary dz, pancreatitis, EPI, hypercalcemia, renal dz, hypoadrenocorticism</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Infectious</TableCell>
                <TableCell>helminthes, protozoa, bacterial, fungal</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Diet-responsive</TableCell>
                <TableCell>allergy, intolerance</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Inflammatory</TableCell>
                <TableCell>IBD, granulomatous colitis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Neoplastic</TableCell>
                <TableCell>carcinoma, lymphoma</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Other</TableCell>
                <TableCell>dysbiosis, idiopathic</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Feline Differentials</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Extra-GI</TableCell>
                <TableCell>hyperthyroidism, hepatobiliary dz, pancreatitis, EPI, hypercalcemia, renal dz</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Infectious</TableCell>
                <TableCell>helminthes, protozoa, bacterial, fungal (especially histoplasmosis in Texas)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Diet-reponsive</TableCell>
                <TableCell>allergy, intolerance, dysbiosis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Inflammatory</TableCell>
                <TableCell>IBD</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Neoplastic</TableCell>
                <TableCell>lymphoma, mast cell tumor, carcinoma</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Initial diagnostics should include CBC, chemistry, UA, T4 (especially older cats), baseline cortisol (for dogs, &ge;2.0 makes hypoadrenocorticism much less likely), fecal + Giardia.</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Abdominal ultrasound</span> is the most useful imaging modality for most patients, especially cats.  In 68% of dogs with chronic diarrhea, abdominal ultrasonography did not substantially contribute to determination of the diagnosis.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Leib et al.</Button> {refs ? " Diagnostic Utility of Abdominal Ultrasonography in Dogs with Chronic Diarrhea. JVIM. 2012." : "" }</sup> </Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Histoplasma antigen testing</span> from MiraVista (fungal testing lab) can rule out or in histoplasmosis. Urine sample is preference of some internists.</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Serum Folate and Cobalamin Testing</span> from TAMU GI Lab</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Folate</TableCell>
                <TableCell>Cobalamin</TableCell>
                <TableCell>Interpretation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>&uarr;</TableCell>
                <TableCell>normal to &darr;</TableCell>
                <TableCell>possible dysbiosis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&darr;</TableCell>
                <TableCell>normal</TableCell>
                <TableCell>proximal SI dz</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>normal</TableCell>
                <TableCell>&darr;</TableCell>
                <TableCell>distal SI dz, EPI, or dysbiosis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&darr;</TableCell>
                <TableCell>&darr;</TableCell>
                <TableCell>SI dz, EPI</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>normal</TableCell>
                <TableCell>normal</TableCell>
                <TableCell>does not rule out SI dz or EPI</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
          <Typography>
            <a href="https://vetmed.tamu.edu/gilab/research/cobalamin-information/" target="_blank" rel="noopener noreferrer">TAMU GI Lab Cobalamin Information</a>
          </Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Therapeutic trials fill in diagnostic gaps.</span> Consider GI diet, hydrolyzed protein diet, novel protein diet, or fiber supplementation (for large bowel diarrhea).</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Intestinal biopsies</span> should be performed before immunosuppressive therapy (ideally).  Consider the pros and cons of surgical biopsies versus endoscopic biopsies.</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Treatment considerations:</span> should be targeted and based on diagnosis when possible. Therapeutic trials with a stepwise approach is reasonable especially when a full diagnostic workup is not available.</Typography>
          <ul>
          <li>
          Consider a diet trial (or trials) for food responsive enteropathy. Expect improvement within 2 weeks (quicker than dermatology diet trial).
          </li>
          <li>
          Consider an antibiotic trial for antibiotic responsive enteropathy. It is not completely understood why some dogs respond, and the response is often short-lived. Younger large breed dogs (especially German Shepherds) respond most often.  Tylosin at 20 mg/kg PO q12h is first choice. Metronidazole at 10mg/kg PO q12h is second choice.  Expect response within 2 weeks. Treat for 6-8 weeks if favorable response.
          </li>
          <li>
          Consider immunosuppressive (ideally after ultrasound and/or biopsies). Prednisone is commonly used at 2mg/kg PO q24h. Cyclosporine at 5 mg/kg PO q24h is an alternative. Chlorambucil at 2-4 mg/m<sup>2</sup> is appropriate for some cats.
          </li>
          <li>
          Psyllium supplementation can fix large bowel diarrhea for some.
          </li>
          <li>
          <Psyllium />
          </li>
          <li>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Small Bowel Diarrhea Protocol (Mississippi State)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 1</TableCell>
                    <TableCell><span  style={{fontWeight: "700"}}>Fecal float:</span> If negative, give fenbendazole at 50 mg/kg PO q24h for 5 days, and <span  style={{fontWeight: "700"}}>start limited antigen or hydrolyzed diet trial.</span> If positive, treat rounds, hooks, whips with fenbendazole; coccidia with sulphadimethoxine (Albon), spirometra with praziquantel, environmental control as needed, repeat float in 10-14 days.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 2 (if diarrhea persists)</TableCell>
                    <TableCell>CBC, chem with lytes (if Albumin &le;1.8 mg/dL or significant dehydration, weight loss, anorexia: switch to thorough investigation). Continue current diet trial. Start probiotic. Start antibiotic trial (Tylosin 25 mg/kg PO q24h for 7 days).</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 3 (if diarrhea persists)</TableCell>
                    <TableCell>Send out GI Panel (TLI, folate, cobalamin, cortisol). Start cobalamin supplementation. Continue probiotic, antibiotic and current diet trial.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Weeks 4-6 (if diarrhea persists)</TableCell>
                    <TableCell>Rectal scrape (especially in areas where Pythium or Histoplasma are endemic). Continue diet trial. Change probiotic. Change antibiotic (Metronidazole 10 mg/kg PO 2-3x/day for 7 days). Start specialist-level workup if not resolved by end of week 6.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Selected scenarios</TableCell>
                    <TableCell>Consider gluten free diet trial in Irish Setters. Consider Low fat diet trial in Yorkshire Terriers. Consider PLE early in Norwegian Lundehund. Consider TLI and antibiotic-responsive early in German Shepherds. Consider cortisol early in Standard Poodles, Nova Scotia Duck Trolling Retrievers.</TableCell>
                  </TableRow>
                </TableBody>
                </Table>
              </TableContainer>
              <Typography style={{fontSize: ".8rem"}}>Table modified from: Bryan et al. Evaluation of a structured individualised protocol as a potential cost-effective diagnostic and therapeutic approach to chronic diarrhoea in the dog. Vet med and Sci. 2019.</Typography>
            </AccordionDetails>
          </Accordion>
          </li>
          <li>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Large Bowel Diarrhea Protocol (Mississippi State)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Weeks 1-2</TableCell>
                    <TableCell><span  style={{fontWeight: "700"}}>Fecal float:</span> If negative, give fenbendazole at 50 mg/kg PO q24h for 5 days, and <span  style={{fontWeight: "700"}}>start high fiber diet trial.</span> If positive, treat rounds, hooks, whips with fenbendazole; Coccidia with sulphadimethoxine (Albon), Spirometra with praziquantel, environmental control as needed, repeat float in 10-14 days. <span  style={{fontWeight: "700"}}>Fecal Gram stain:</span> If positive, treat Clostridium with metronidazole. <span  style={{fontWeight: "700"}}>Fecal wet mount or Giardia SNAP:</span> If positive, treat Giardia.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 3 (if diarrhea persists)</TableCell>
                    <TableCell>Start limited antigen diet trial. Start psyllium supplementation. Start probiotic trial. Repeat any previously positive fecal tests.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 4 (if diarrhea persists)</TableCell>
                    <TableCell>Continue current diet and fiber. Change probiotic.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 5 (if diarrhea persists)</TableCell>
                    <TableCell>Continue diet and fiber trials. Start antibiotic trial Start antibiotic trial (Tylosin 25 mg/kg PO q24h for 7-14 days).</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Week 6 (if diarrhea persists)</TableCell>
                    <TableCell>Continue diet and fiber trials. Rectal scrape. Fecal Transplant. Start specialist-level workup if not resolved by end of week 6.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight: "700"}}>Selected scenarios</TableCell>
                    <TableCell>Consider Enrofloxacin for 6 weeks in young Boxers, English Bulldogs, and French bulldogs that have failed initial standard large bowel diarrhea protocol.</TableCell>
                  </TableRow>
                </TableBody>
                </Table>
              </TableContainer>
              <Typography style={{fontSize: ".8rem"}}>Table modified from: Bryan et al. Evaluation of a structured individualised protocol as a potential cost-effective diagnostic and therapeutic approach to chronic diarrhoea in the dog. Vet med and Sci. 2019.</Typography>
            </AccordionDetails>
          </Accordion>
          </li>
          </ul>
          </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
