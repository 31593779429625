import React from 'react';
import { Grid, Paper, } from '@mui/material';

import hipLlatLuxation from './images/orthopedic/hindlimb/hip_Llat_luxation.jpg';
import hipVDLuxation from './images/orthopedic/hindlimb/hip_VD_luxation.jpg';
import pseudoarthrosisCHDVD from './images/orthopedic/hindlimb/pseudoarthrosisCHD_VD.jpg';

export default function HipLuxationRads() {

  return (
          <>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hip Luxation</span></div>
          <img src={hipLlatLuxation} alt="Hip Luxation" style={{maxWidth: "96%"}}/>
          <img src={hipVDLuxation} alt="Hip Luxation" style={{maxWidth: "96%"}}/>
          <div>Craniodorsal hip luxation is most common.</div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hip Luxation (Chronic) with Pseudoarthrosis</span></div>
          <img src={pseudoarthrosisCHDVD} alt="Pseudoarthrosis due to hip luxation" style={{maxWidth: "96%"}}/>
          <div>If hip luxation is not addressed, a painful pseudo-joint may form.</div>
          </Paper>
          </Grid>
          </>


  );
}
