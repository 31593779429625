import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Communication() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Key Points for Communication</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
        Dogs and cats get cancer. Cancer comes from a bad population of the body's own cells. Some cancers are benign, or cause no harm, but some are malignant and do cause harm.  It is important to keep in mind that cancer is an individual disease&mdash;even with all we know about different cancer types, cancer often behaves differently in  different individuals.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Cancer development is multifactorial and multistep. Many factors contribute to the development of cancer, including genetic factors, environmental factors, hormones, viruses, parasites, and chronic inflammation or infection. The body has many defense mechanisms to protect against cancer, so several steps are usually required for cancer to successfully establish itself and subsequently threaten overall health.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Knowing the type of cancer and characterizing it by "grade" and "stage" can help inform us how the cancer might behave and what we should or could do about it.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Cancer treatment options include chemotherapy, surgical oncology, and radiation therapy. Not all of these treatments are appropriate and available for every cancer, pet, family, or situation.  It is important to keep the goals and values of the family in mind before and after diagnosis.  We do not have to pursue a diagnosis for every mass.  If we do, and treatments are elected, it should be with an informed understanding of the range of possible outcomes and side effects.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/cancer" target="_blank" rel="noopener noreferrer">Cancer in Dogs Handout</a> from Pet Parent Handouts
        </Typography>
        </Grid>
      </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
