import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


export default function DiseaseSpecificConsiderations() {

const [brachyRefs, setBrachyRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="disease-specific-considerations-content"
          id="disease-specific-considerations-header"
        >
          <Typography>Disease Specific Considerations</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Brachycephalic</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
                Pre-oxygenate.
              </li>
              <li>
                Administer Cerenia (maropitant) the night before (PO 2 mg/kg) or shortly before (IV 1 mg/kg) premedication
              </li>
              <li>
                Be ready with smaller than expected ET tubes
              </li>
              <li>
                Consider famotidine (1.0 mg/kg IV or SC) and metoclopramide (0.5 mg/kg SC) with or before premedication<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setBrachyRefs(!brachyRefs)}>Costa et al.</Button> {brachyRefs ? "Postoperative regurgitation and respiratory complications in brachycephalic dogs undergoing airway surgery before and after implementation of a standardized perianesthetic protocol. JAVMA. 2020." : "" }</sup>
              </li>
              <li>
                Consider Dexamethasone SP (0.15 mg/kg IV) for patients undergoing airway surgery
              </li>
              <li>
                These patients may have higher vagal tone. Consider atropine (0.02 - 0.04 mg/kg IV) or glycopyrrolate (0.005 to 0.01 mg/kg IV)
              </li>
              <li>
                Delay extubation and extend post-operative monitoring
              </li>
              <li>
                Consider neosynephrine nasal drops
              </li>
              <li>
                Be ready to re-intubate with ET tube and propofol nearby
              </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Cardiac</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ul style={{padding: '0 1rem', margin: 0}}>
            <li>
            Avoid alpha-2 agonists
            </li>
            <li>
            Consider alfaxalone induction
            </li>
            <li>
            Consider etomidate
            </li>
            </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Diabetes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              Consider 1/2 morning meal and 1/2 dose insulin 2-3 hrs prior to anesthesia
              </li>
              <li>
              Ideally, anesthetic event should be early in the day to maintain routine
              </li>
              <li>
              Consider monitoring BG at induction and every 30 minutes
              </li>
              <li>
              Consider dextrose (bolus 1-2 mL/kg followed by 2.5% or 5% in IV fluids) if BG is under 100 mg/dL
              </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Head trauma</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              Avoid ketamine (which may increase ICP)
              </li>
              <li>
              Watch for vasopressor response (Cushing's reflex) which may include hypertension, bradycardia, and an irregular breathing pattern
              </li>
              <li>
              Maintain EtCO2 between 28 and 35
              </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Pediatric (&le;16 weeks)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ul style={{padding: '0 1rem', margin: 0}}>
                <li>
                Consider heat support
                </li>
                <li>
                Consider glucose support and monitor BG every 30 minutes
                </li>
                <li>
                Do not wean before anesthesia
                </li>
                <li>
                Cardiac output depends on heart rate (due to decreased stroke volume); HR should be &ge; 150 bpm
                </li>
                <li>
                May need positive pressure ventilation (hypercapnea is commmon due to high respiratory rate)
                </li>
                </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="abdominal-surgery-content"
              id="abdominal-surgery-header"
            >
              <Typography>Renal</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ul style={{padding: '0 1rem', margin: 0}}>
                <li>
                Consider IVF therapy prior to anesthesia to achieve euhydration
                </li>
                <li>
                Avoid/minimize anesthetic drugs that may decrease renal perfusion
                </li>
                <li>
                To maintain renal perfusion, consider fluid boluses, dobutamine, atropine, and/or glycopyrolate
                </li>
                <li>
                Avoid NSAIDs
                </li>
                </ul>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
