import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CommonDrugs from './FelineCommonDrugs';
import FelineSupplements from './FelineSupplements';
import ProblemBehaviors from './FelineBehaviorProblems';


export default function FelineBehavior() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Behavior</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CommonDrugs />
        <FelineSupplements />
        <ProblemBehaviors />
        </AccordionDetails>
      </Accordion>

  );
}
