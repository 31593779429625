import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import cataract from './images/cataract.jpg';
import nuclearSclerosis from './images/nuclearSclerosis.gif';

export default function Cataracts() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cataracts</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cataract</span></div>
          <img src={cataract} alt="cataract" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>A cataract is an opacity of the lens.  This cataract is complete and affects the entire lens, but this is not always the case.</div>
          </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
          <Typography>
          <span style={{fontWeight: "700"}}>Etiology:</span> inherited/genetic (most common cause in dogs), inflammatory (most common cause in cats), metabolic, secondary to trauma, nutritional, toxic, senile degeneration
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Nuclear Sclerosis</span></div>
          <img src={nuclearSclerosis} alt="nuclear sclerosis" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>With age, the lens becomes more dense.  We see this as a greying of the lens that usually starts around age 6-7 years in dogs.  Do NOT confuse this with a cataract.  Notice that there is still a complete tapetal reflection when a light is directed through the lens and bounces back off the fundus.  This technique, termed 'retroillumination,' can differentiate cataracts from nuclear sclerosis.  Keep your light source an arms length away from the tapetum.</div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography>
          <span style={{fontWeight: "700"}}>Four stages of cataract maturation:</span>
          </Typography>
          <ol>
          <li>Incipient
          </li>
          <li>Incomplete (Immature)
          </li>
          <li>Complete (Mature)
          </li>
          <li>Resorbing (Hypermature)
          </li>
          </ol>
          <Typography>
          Intumescent cataracts are a subcategory of complete or incomplete cataracts that are progressing rapidly (often seen in diabetic patients).
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          Cataracts can lead to loss of vision, lens-induced uveitis, glaucoma, and retinal detachments (due to inflammation)
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Medical management:</span> Use an ophthalmic NSAID (diclofenac, flurbiprofen or ketorolac) 1-2x/day life-long to prevent/control lens-induced uveitis.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Surgical treatment:</span> For some dogs, cataracts can be treated by surgically replacing the lens with a prosthetic.  This is done by boarded veterinary ophthalmologists.  It can restore vision to dogs that have complete cataracts.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="http://www.acvo.org/common-conditions-1/2018/3/1/cataracts" target="_blank" rel="noopener noreferrer">Cataracts Handout</a> from the ACVO
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
