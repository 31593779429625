import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import lymphomaNasal from './images/lymphomaNasal.jpg'


export default function LymphomaCanine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Lymphoma (Canine)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Lymphoma is commonly diagnosed at routine veterinary visits in apparently healthy dogs.  One or more lymph nodes (little filters that contain white blood cells) under your dogs skin may be bigger than they should be.  Cytology from the affected lymph node can help differentiate if the lymph node is just reacting to insult, infection, or injury, or if it is harboring cancer cells.
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Lymphoma (large lymphocytes)</span></Typography>
          <img src={lymphomaNasal} style={{borderRadius:"2%", maxWidth: "96%"}} alt="lymphoma cytology"/>
          <div>Large lymphocytes on cytology from FNA.  Histopathology confirmed nasal lymphoma. Comparing lymphocyte size to RBCs and neutrophils can be helpful in differentiating large lymphocytes.</div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        <Typography style={{fontWeight: "700"}}>
          The World Health Organization Staging:
        </Typography>
          <ul>
          <li>Stage I: Single lymph node (LN) or lymphoid tissue</li>
          <li>Stage II: Regional involvement of multiple LNs</li>
          <li>Stage III: Generalized LN involvement</li>
          <li>Stage IV: with involvement of liver or spleen</li>
          <li>Stage V: with involvement of blood or bone marrow</li>
          </ul>
            <Typography style={{fontWeight: "700"}}>Substage:</Typography>
          <ul>
          <li>a: absence of systemic signs</li>
          <li>b: presence of systemic signs</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: "700"}}>B-cell MST:</span> 10-12 months with Chemo (CHOP or similar over 19 weeks); 2-3 months with prednisone alone; 4-6 weeks with no treatment;
        </Typography>
        <Typography>
          <span style={{fontWeight: "700"}}>T-cell MST:</span> 5-9 months with Chemo; 4-6 weeks with no treatment;
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951455" target="_blank" rel="noopener noreferrer">Lymphoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
