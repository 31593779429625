import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AllergicDermatitis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Allergies (Allergic Dermatitis, Atopy)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
            Itch is additive.  Consider bites from fleas, skin mite overgrowth, bacterial overgrowth, yeast overgrowth, and dermatophytes (ringworm-type organisms) as compounding factors that contribute to itchy skin.  Pets that are clinical for food or environmental allergies should be on a regular maintenance plan to address these infectious contributors to itch.  This usually means an isoxasoline (Simparica, Bravecto, Nexgard, Credelio) flea medication to cover against skin mite overgrowth and maintenance bathing with a medicated shampoo to treat and prevent yeast and bacterial overgrowth.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Diagnostics:</span> First line diagnostics may include skin cytology, deep skin scraping, dermatophyte testing.  Bacterial culture (ideally from inside pustule or under lifted crust) may be considered in cases with increased suspicion of resistant bacterial infection.  Blood testing for food allergies is not recommended; consider an elimination diet trial in appropriate cases. Consider dermatology referral for further diagnostics or intradermal skin testing. Atopic dermatitis is a diagnosis of exclusion.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://bmcvetres.biomedcentral.com/articles/10.1186/s12917-015-0515-5" target="_blank" rel="noopener noreferrer">Hensel et al. Canine atopic dermatitis: detailed guidelines for diagnosis and allergen identification. BMC Vet Research. 2015.</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Treatment:</span> Consider miticidal treatment trial, topical shampoo to treat surface to superficial yeast and bacteria, and anti-pruritic.  Food allergies are less common than environmental allergies, but a strict diet trial (6-8 weeks, no treats, no flavored medications) can be considered for appropriate cases.  Allergen specific immunotherapy following intradermal skin testing can be considered after diagnosis of atopic dermatitis in certain cases.
          </Typography>
          <ul>
          <li>
          Douxo Chlorhexidine PS + Climbazole Shampoo 200 ml OR VetriMax Command Dog Shampoo OR Dechra MiconaHex Triz Shampoo: Bath up to twice each week in cool to room temperature water. A small amount (palmful) can be diluted in one pint of water to extend use. Focus on problem areas and gently massage into the coat. Let sit for 5-10 minutes before rinsing as contact time kills skin yeast and bacteria.
          </li>
          <li>
          Douxo (Sogeval) Chlorhexidine Mousse Dechra OR MiconaHex + Triz Spray OR Dechra TrizCHLOR 4 Wipes: Use between bathing to reduce yeast and bacterial overgrowth. Gently massage into the coat with the direction of fur and leave in (do not rinse).
          </li>
          <li>
          Simparica TRIO, Simparica, Credelio, Bravecto, Nexgard: To treat fleas and prevent Demodex mite overgrowth
          </li>
          <li>
          Antipruritics: Prednisone (not ideal for long-term use given adverse effects), oclacitinib (Apoquel), lockivetmab (Cytopoint), cyclosporine (Atopica)
          </li>
            <ul>
            <li>
            Aim for 0.6 mg/kg oclacitinib (Apoquel) once each day; consider twice-daily dosing in most extreme cases.
            </li>
            <li>
            Atopica is slower to act than Apoquel or Cytopoint. The slow onset makes it a poor choice for acute flares.
            </li>
            <li>
            Cytopoint may not provide relief for a full 4 weeks in some patients. It is a monoclonal antibody and may become less effective with subsequent administrations. Sometimes a Cytopoint break with switch to Apoquel will make it more effective again.
            </li>
            <li>
            Antihistamines (such as diphenhydramine/Benadryl) are generally not beneficial but may provide small and limited benefit in some dogs. Ideally, they should be given before a flare has occurred.
            </li>
          </ul>
          <li>
          Serologic tests vary substantially between laboratories and thus their immunotherapy recommendations are expected to vary substantially.  Allergen-specific intradermal tests are still the preferred method of identifying allergens for allergen-specific immunotherapy (ASIT). ASIT may take months to show benefit and should be continued for at least 1 year to determine efficacy.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://bmcvetres.biomedcentral.com/articles/10.1186/s12917-015-0514-6" target="_blank" rel="noopener noreferrer">Olivry et al. Treatment of canine atopic dermatitis: 2015 updated guidelines from the International Committee on Allergic Diseases of Animals (ICADA). BMC Vet Research. 2015.</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://petparenthandouts.com/canine-allergies" target="_blank" rel="noopener noreferrer">Canine Allergies Handout</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>

        </AccordionDetails>
      </Accordion>

  );
}
