import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TempTarsorrhaphyList from '../../QuickSheets/components/TempTarsorrhaphyList';

import tempTarsorrhaphyImg from './images/tempTarsorrhaphy.jpg';

export default function TempTarsorrhaphy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Temporary Tarsorrhaphy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
          <Typography>
            A temporary partial tarsorrhaphy is indicated in cases of proptosis if possible to save the globe.  It may also be indicated certain cases of corneal ulceration (such as when placing a collagen contact over the defect).
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
            <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Temporary Tarsorrhaphy</span></div>
            <img src={tempTarsorrhaphyImg} alt="drawing of temporary tarsorrhaphy" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <Typography>Entering and exiting at the Meibomian glands is most important.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <TempTarsorrhaphyList />
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
