import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/flailChestGif.gif';

export default function FlailChest() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Flail Chest</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Flail chest occurs following trauma when there are multiple rib fractures in multiple places that lead to a free segment of chest wall. This segment will move in with inspiration and out with expiration. It is painful but not usually a primary cause of difficulty breathing or hypoxia following trauma.
                    </Typography>
                    <Typography>
                    Stabilize the segment by laying the patient on that side (to limit outward movement on inhalation) or wrapping the chest. Surgical stabilization is usually not beneficial. Investigate the other injuries that the patient likely has.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="flail chest" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Notice the area of chest just behind the point of the elbow moves out while the abdomen moves in on inspiration.</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
