import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RetainedDeciduous from './RetainedDeciduous';
import Fcgs from './Fcgs';
import CanineAvulsion from './CanineAvulsion';
import CrownFracture from './CrownFracture';
import ToothRootAbscess from './ToothRootAbscess';
import DentigerousCyst from './DentigerousCyst';
import MandibularFractures from './MandibularFractures';
import Cups from './Cups';

export default function DentalProblems() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Dental Problems</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CrownFracture />
          <CanineAvulsion />
          <Cups />
          <DentigerousCyst />
          <Fcgs />
          <MandibularFractures />
          <RetainedDeciduous />
          <ToothRootAbscess />
        </AccordionDetails>
      </Accordion>

  );
}
