import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Sirs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>SIRS, MODS, & DIC</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: "700"}}>Systemic Inflammatory Response Syndrome (SIRS)</span> is characterized by clinical signs of systemic inflammation with or without infection. Infection and SIRS is sepsis. SIRS criteria include abnormal heart rate, tachpnea, abnormal temperature, and leukogram change&mdash;not all are deranged in SIRS; consider the individual patient and disease state.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Multiorgan Dysfunction Syndrome (MODS)</span> is characterized by dysfunction of 2 or more organs/organ systems in patients with SIRS</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Disseminated Intravascular Coagulation (DIC)</span> is characterized by abnormal coagulation due to massive inflammation. Initially, paitients are prothrobotic&mdash;clotting factors are consumed and a coagulopathy ensues</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
