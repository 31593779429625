import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Shock from './Shock';
import Sepsis from './Sepsis';
import Sirs from './Sirs';
import Hypertension from './Hypertension';
import Circi from './Circi';
import Taco from './Taco';
import Serotonin from './SerotoninSyndrome';
import AcuteAbdomen from './AcuteAbdomen';
import Hypernatremia from './Hypernatremia';

export default function SystemicConditions() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Systemic Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AcuteAbdomen />
          <Circi />
          <Hypernatremia />
          <Hypertension />
          <Sepsis />
          <Serotonin />
          <Shock />
          <Sirs />
          <Taco />
        </AccordionDetails>
      </Accordion>


  );
}
