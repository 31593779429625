import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hypernatremia() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Hypernatremia</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Hypernatremia (high sodium) may be acute or chronic and this will dictate how quickly it can be corrected.
                    </Typography>
                    <Typography gutterBottom>
                    Older dehydrated, hypotensive cats may present with profound hypernatremia. Prognosis is grave. Hope for the best, but expect the worst. Hypernatremia following high-sodium fluid administration in hospital may be acute.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    First, correct blood pressure (easier said than done). Then focus on dehydration correction over the next 24 hours as we start to correct free water deficit with D5W on a separate pump.
                    </Typography>
                    <ul>
                      <li>
                      If chronic, ideally want to match initial resuscitation fluid to the patient's sodium (or get close). 0.9% NaCl has 154 mEq/L Na+ and is close enough for many. Or add 7.2% hypertonic saline (1.232 mEq/mL) to achieve the desired mEq/L first.
                      </li>
                      <li>
                      LRS or similar is an appropriate replacement fluid for rehydration.
                      </li>
                      <li>
                      Access to drinking water should also be provided.
                      </li>
                    </ul>
                  </Grid>    
                  <Grid item xs={12}>
                    <Typography>
                    For chronic hypernatremia, the goal is to decrease sodium by 0.5 mEq/hr or we will cause life-ending cerebral edema. Recheck sodium every 2-8 hours depending on the problems and patient. Acute hypernatremia can be corrected more quickly than 0.5 mEq/hr. A quick-and-dirty trick is that 3.7 mL/kg/hr of D5W will decrease sodium by 1 mEq/hr (run D5W separately and subtract this fluid rate from crystalloid fluid rate). 
                    If initial Na+ is &gt;180, assume 190&mdash;rough math should illustrate this will take 60 hours (at least) to bring the sodium down to a more acceptable 160. Prepare pet parents that this may be a long, drawn-out challenge with a bad outcome.
                    </Typography>
                  </Grid>            
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
