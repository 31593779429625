import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Leptospirosis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Leptospirosis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/j.1939-1676.2010.0654.x" target="_blank" rel="noopener noreferrer">2010 ACVIM Small Animal Consensus Statement on Leptospirosis: Diagnosis, Epidemiology, Treatment, and Prevention</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Leptospirosis is a bacterium shed in the urine of wild mammals. It is especially common in Hawaii, but also found in many areas of the mainland US. The vaccine is considered core in many regions, but does not protect against all serovars.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> May present with liver or renal value elevations, fever, GI signs (commonly anorexia or vomiting). Elevation of renal and liver values should raise concern in the right patient population and geographic region. Glucosuria and thrombocytopenia are common. Conjunctivitis and uveitis are possible. Leptospiral pulmonary hemorrhage syndrome (LPHS) is recognized in human patients and reported most frequently in dogs in Europe.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Prevention:</span> 4-serovar vaccine appears to protect for 12 months, however certain serovars may cause leptospirosis even in vaccinated dogs.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Other causes of azotemia and elevated hepatic enzymes may include infection, toxin, CKD, hypoadrenocorticism, neoplasia, hepatopathy
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span>
        </Typography>
        <ul>
          <li>In-house tests are great but may be false negative and send out (MAT +/- PCR of urine and serum) testing should be recommended if leptospirosis is suspected. False positive possible after vaccination.</li>
          <li>
          MAT (microscopic agglutination test) is the gold standard test and recommended by the consensus statement. Can be used for convalescent titer 1-2 weeks later if needed for additional support.
          </li>
          <li>
          PCR of urine and serum may be helpful to support a diagnosis. Serum is most helpful in early infection; urine most helpful later in infection or to identify chronic carriers. Ideally, submit serum and urine with MAT sample.
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment & Followup:</span>
        </Typography>
        <ul>
          <li>
          Doxycycline 5 mg/kg PO q12h for 2 weeks is the consensus recommendation; IV administration is an expensive alternative
          </li>
          <li>
          Ampicillin 20 mg/kg IV q6h, with dose reduction for azotemic dogs if vomiting or other adverse reactions preclude doxycycline administration
          </li>
          <li>
          Renal replacement therapy may be life saving for dogs with anuric leptospirosis
          </li>
          <li>
          A urinary catheter may help contain urine (zoonotic risk) and measure output. Patients that are polyuric will need higher fluid rates.
          </li>
          <li>
          Treat other dogs in the household that may have been exposed (doxycycline, 5 mg/kg PO q12h for 14 days)
          </li>
          <li>
          Warn pet parents of zoonotic risk
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951453" target="_blank" rel="noopener noreferrer">Leptospirosis in Dogs</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
