import React from 'react';
import { Grid, Paper, Typography} from '@mui/material';

import osteosarcoma from './images/orthopedic/hindlimb/osteosarcoma.jpg';

export default function CrcldRadiographs() {

  return (
    <Grid item xs={12} md={4}>
    <Paper style={{textAlign: "center", margin: "auto"}}>
    <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Osteosarcoma</span></Typography>
    <img src={osteosarcoma} style={{borderRadius:"2%", maxWidth: "96%"}} alt="radiograph of osteosarcoma"/>
    <div>Pathologic fracture associated with osteosarcoma of the femur of a dog</div>
    </Paper>
    </Grid>


  );
}
