import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import Account from "./containers/Account";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import NotFound from "./containers/NotFound";

import ClinicalNotebook from "./containers/Notebook/NotebookMain";
import Anesthesia from "./containers/Notebook/Anesthesia/Anesthesia";
import AnesthesiaChecklistPage from "./containers/Notebook/Anesthesia/AnesthesiaChecklistPage"
import Behavior from "./containers/Notebook/Behavior/Behavior";
import Cardiology from "./containers/Notebook/Cardiology/Cardiology";
import ClinPath from "./containers/Notebook/ClinicalPathology/ClinicalPathology";
import Dentistry from "./containers/Notebook/Dentistry/Dentistry";
import Dermatology from "./containers/Notebook/Dermatology/Dermatology";
import Emergency from "./containers/Notebook/Emergency/Emergency";
import Euthanasia from "./containers/Notebook/Euthanasia/Euthanasia";
import InternalMed from "./containers/Notebook/InternalMedicine/InternalMedicine";
import Musculoskeletal from "./containers/Notebook/Musculoskeletal/Musculoskeletal";
import Neurology from "./containers/Notebook/Neurology/Neurology";
import Nutrition from "./containers/Notebook/Nutrition/Nutrition";
import Oncology from "./containers/Notebook/Oncology/Oncology";
import Ophthalmology from "./containers/Notebook/Ophthalmology/Ophthalmology";
import Radiology from "./containers/Notebook/Radiology/Radiology";
import Reproduction from "./containers/Notebook/Reproduction/Reproduction";
import Surgery from "./containers/Notebook/Surgery/Surgery";
import Wellness from "./containers/Notebook/Wellness/Wellness";

import QuickSheets from "./containers/QuickSheets/QuickSheets";
import AcuteVomiting from "./containers/QuickSheets/AcuteVomiting";
import AddisonianCrisis from "./containers/QuickSheets/AddisonianCrisis";
import Ahds from "./containers/QuickSheets/Ahds";
import Aki from "./containers/QuickSheets/Aki";
import Anaphylaxis from "./containers/QuickSheets/Anaphylaxis";
import BlockedCat from "./containers/QuickSheets/BlockedCat";
import Hbc from "./containers/QuickSheets/Hbc";
import Parvo from "./containers/QuickSheets/Parvo";
import Cpr from "./containers/QuickSheets/Cpr";
import Abscess from "./containers/QuickSheets/CatAbscess";
import Chf from "./containers/QuickSheets/Chf";
import Constipation from "./containers/QuickSheets/Constipation";
import CornealUlcer from "./containers/QuickSheets/CornealUlcer";
import Dka from "./containers/QuickSheets/Dka";
import Dystocia from "./containers/QuickSheets/Dystocia";
import Emesis from "./containers/QuickSheets/Emesis";
import Gdv from "./containers/QuickSheets/Gdv";
import Glaucoma from "./containers/QuickSheets/Glaucoma";
import HeadTrauma from "./containers/QuickSheets/HeadTrauma";
import HeatStroke from "./containers/QuickSheets/HeatStroke";
import Hemoabdomen from "./containers/QuickSheets/Hemoabdomen";
import HepaticLipidosis from "./containers/QuickSheets/HepaticLipidosis";
import Imha from "./containers/QuickSheets/Imha";
import Itp from "./containers/QuickSheets/Itp";
import Ivdd from "./containers/QuickSheets/Ivdd";
import JuvenileHypoglycemia from "./containers/QuickSheets/JuvenileHypoglycemia";
import Pancreatitis from "./containers/QuickSheets/Pancreatitis";
import PericardialEffusion from "./containers/QuickSheets/PericardialEffusion";
import PleuralEffusion from "./containers/QuickSheets/PleuralEffusion";
import Pneumonia from "./containers/QuickSheets/Pneumonia";
import Proptosis from "./containers/QuickSheets/Proptosis";
import Pyometra from "./containers/QuickSheets/Pyometra";
import RespiratoryDistress from "./containers/QuickSheets/RespiratoryDistress";
import Seizures from "./containers/QuickSheets/Seizures";
import UpperAirwayObstruction from "./containers/QuickSheets/UpperAirwayObstruction";
import SnakeBite from "./containers/QuickSheets/SnakeBite";
import Vestibular from "./containers/QuickSheets/Vestibular";
import Wounds from "./containers/QuickSheets/Wounds";

import Calculators from "./containers/Calculators/Calculators"
import EmergencyDrugCalculator from "./containers/Calculators/EmergencyDrugCalculator";
import AllergicReaction from "./containers/Calculators/AllergicReaction";
import EmesisCalculator from "./containers/Calculators/EmesisCalculator";
import DexTorbCalculator from "./containers/Calculators/DexTorbCalculatorPage";
import CanineAlfaxCalculator from "./containers/Calculators/CanineAlfaxCalculatorPage";
import AceOpioidCalculatorPage from "./containers/Calculators/AceOpioidCalculatorPage";
import FelineAlfaxCalculator from "./containers/Calculators/FelineAlfaxCalculatorPage";
import DktCalculator from "./containers/Calculators/DktCalculatorPage";
import CanineFluids from "./containers/Calculators/CanineFluids";
import FelineFluids from "./containers/Calculators/FelineFluids";

import Forms from "./containers/Forms/Forms";
import NeuroExamForm from "./containers/Forms/NeuroExamForm"
import ModifiedGlascow from "./containers/Forms/ModifiedGlascow"

import NewNote from "./containers/CaseNotes/NewNote";
import Notes from "./containers/CaseNotes/Notes";
import ListNotes from "./containers/CaseNotes/ListNotes";

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/account"
        element={
          <AuthenticatedRoute>
            <Account />
          </AuthenticatedRoute>
        }
      />
      {
        /* Clinical Notebook */
      }
      <Route
        path="/clinical-notebook"
        element={
          <AuthenticatedRoute>
            <ClinicalNotebook />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/anesthesia"
        element={
          <AuthenticatedRoute>
            <Anesthesia />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/behavior"
        element={
          <AuthenticatedRoute>
            <Behavior />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/cardiology"
        element={
          <AuthenticatedRoute>
            <Cardiology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/clinical-pathology"
        element={
          <AuthenticatedRoute>
            <ClinPath />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/dentistry"
        element={
          <AuthenticatedRoute>
            <Dentistry />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/dermatology"
        element={
          <AuthenticatedRoute>
            <Dermatology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/emergency"
        element={
          <AuthenticatedRoute>
            <Emergency />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/euthanasia"
        element={
          <AuthenticatedRoute>
            <Euthanasia />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/internal-medicine"
        element={
          <AuthenticatedRoute>
            <InternalMed />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/musculoskeletal"
        element={
          <AuthenticatedRoute>
            <Musculoskeletal />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/neurology"
        element={
          <AuthenticatedRoute>
            <Neurology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/nutrition"
        element={
          <AuthenticatedRoute>
            <Nutrition />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/oncology"
        element={
          <AuthenticatedRoute>
            <Oncology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/ophthalmology"
        element={
          <AuthenticatedRoute>
            <Ophthalmology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/radiology"
        element={
          <AuthenticatedRoute>
            <Radiology />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/reproduction"
        element={
          <AuthenticatedRoute>
            <Reproduction />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/surgery"
        element={
          <AuthenticatedRoute>
            <Surgery />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/wellness"
        element={
          <AuthenticatedRoute>
            <Wellness />
          </AuthenticatedRoute>
        }
      />
      {
        /* Quick Sheets */
      }
      <Route
        path="/quick-sheets"
        element={
          <AuthenticatedRoute>
            <QuickSheets />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/vomiting-diarrhea"
        element={
          <AuthenticatedRoute>
            <AcuteVomiting />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/addisonian-crisis"
        element={
          <AuthenticatedRoute>
            <AddisonianCrisis />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/ahds"
        element={
          <AuthenticatedRoute>
            <Ahds />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/aki"
        element={
          <AuthenticatedRoute>
            <Aki />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/anaphylaxis"
        element={
          <AuthenticatedRoute>
            <Anaphylaxis />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/blocked-cat"
        element={
          <AuthenticatedRoute>
            <BlockedCat />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/blunt-trauma"
        element={
          <AuthenticatedRoute>
            <Hbc />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/parvo"
        element={
          <AuthenticatedRoute>
            <Parvo />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/cpr"
        element={
          <AuthenticatedRoute>
            <Cpr />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/abscess"
        element={
          <AuthenticatedRoute>
            <Abscess />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/chf"
        element={
          <AuthenticatedRoute>
            <Chf />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/constipation"
        element={
          <AuthenticatedRoute>
            <Constipation />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/corneal-ulcer"
        element={
          <AuthenticatedRoute>
            <CornealUlcer />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/dka"
        element={
          <AuthenticatedRoute>
            <Dka />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/dystocia"
        element={
          <AuthenticatedRoute>
            <Dystocia />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/emesis"
        element={
          <AuthenticatedRoute>
            <Emesis />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/gdv"
        element={
          <AuthenticatedRoute>
            <Gdv />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/glaucoma"
        element={
          <AuthenticatedRoute>
            <Glaucoma />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/head-trauma"
        element={
          <AuthenticatedRoute>
            <HeadTrauma />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/heat-stroke"
        element={
          <AuthenticatedRoute>
            <HeatStroke />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/hemoabdomen"
        element={
          <AuthenticatedRoute>
            <Hemoabdomen />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/hepatic-lipidosis"
        element={
          <AuthenticatedRoute>
            <HepaticLipidosis />
          </AuthenticatedRoute>
        }
      />
      <Route
          path="/quick-sheets/imha"
          element={
            <AuthenticatedRoute>
              <Imha />
            </AuthenticatedRoute>
          }
        />
          <Route
        path="/quick-sheets/itp"
        element={
          <AuthenticatedRoute>
            <Itp />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/ivdd"
        element={
          <AuthenticatedRoute>
            <Ivdd />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/juvenile-hypoglycemia"
        element={
          <AuthenticatedRoute>
            <JuvenileHypoglycemia />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/pancreatitis"
        element={
          <AuthenticatedRoute>
            <Pancreatitis />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/pericardial-effusion"
        element={
          <AuthenticatedRoute>
            <PericardialEffusion />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/pleural-effusion"
        element={
          <AuthenticatedRoute>
            <PleuralEffusion />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/pneumonia"
        element={
          <AuthenticatedRoute>
            <Pneumonia />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/proptosis"
        element={
          <AuthenticatedRoute>
            <Proptosis />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/pyometra"
        element={
          <AuthenticatedRoute>
            <Pyometra />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/respiratory-distress"
        element={
          <AuthenticatedRoute>
            <RespiratoryDistress />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/seizures"
        element={
          <AuthenticatedRoute>
            <Seizures />
          </AuthenticatedRoute>
        }
      />
            <Route
        path="/quick-sheets/snake-bite"
        element={
          <AuthenticatedRoute>
            <SnakeBite />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/upper-airway-obstruction"
        element={
          <AuthenticatedRoute>
            <UpperAirwayObstruction />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/vestibular-disease"
        element={
          <AuthenticatedRoute>
            <Vestibular />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/quick-sheets/wounds"
        element={
          <AuthenticatedRoute>
            <Wounds />
          </AuthenticatedRoute>
        }
      />
      {
        /* Calculators */
      }
      <Route
        path="/calculators"
        element={
          <AuthenticatedRoute>
            <Calculators />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/emergency-drug-calculator"
        element={
          <AuthenticatedRoute>
            <EmergencyDrugCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/allergic-reaction"
        element={
          <AuthenticatedRoute>
            <AllergicReaction />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/emesis-calculator"
        element={
          <AuthenticatedRoute>
            <EmesisCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/dex-torb"
        element={
          <AuthenticatedRoute>
            <DexTorbCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/canine-alfax"
        element={
          <AuthenticatedRoute>
            <CanineAlfaxCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/canine-ace-opioid"
        element={
          <AuthenticatedRoute>
            <AceOpioidCalculatorPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/feline-alfax"
        element={
          <AuthenticatedRoute>
            <FelineAlfaxCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/dkt"
        element={
          <AuthenticatedRoute>
            <DktCalculator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/canine-fluids"
        element={
          <AuthenticatedRoute>
            <CanineFluids />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/feline-fluids"
        element={
          <AuthenticatedRoute>
            <FelineFluids />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/calculators/feline-fluids"
        element={
          <AuthenticatedRoute>
            <FelineFluids />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/clinical-notebook/anesthesia/checklist"
        element={
          <AuthenticatedRoute>
            <AnesthesiaChecklistPage />
          </AuthenticatedRoute>
        }
      />
      {
        /* Forms */
      }
      <Route
        path="/forms"
        element={
          <AuthenticatedRoute>
            <Forms />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/forms/neuro-exam"
        element={
          <AuthenticatedRoute>
            <NeuroExamForm />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/forms/modified-glascow"
        element={
          <AuthenticatedRoute>
            <ModifiedGlascow />
          </AuthenticatedRoute>
        }
      />
      {
        /* Case Notes */
      }
      <Route
        path="/notes"
        element={
          <AuthenticatedRoute>
            <ListNotes />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/notes/new"
        element={
          <AuthenticatedRoute>
            <NewNote />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/notes/:id"
        element={
          <AuthenticatedRoute>
            <Notes />
          </AuthenticatedRoute>
        }
      />
      {
        /* Finally, catch all unmatched routes */
      }
      <Route path="*" element={<NotFound />} />;
    </Routes>
  );
}
