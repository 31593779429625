import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import righting from './images/righting.gif';
import extensor from './images/extensor.gif';
import hopping from './images/hopping.gif';
import walking from './images/walking.gif';
import withdraw from './images/withdraw.gif';
import patellar from './images/patellar.gif';

export default function NeuroExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Neurologic Exam</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button href="/forms/neuro-exam" variant="outlined">Neuro Exam Form</Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>1. Observe the patient.</span>  What is the patient's mental status?  Is the patient alert and engaged, dull, or comatose?  How about the patient's posture?  Dose the patient seem hunched, have a head tilt, or falls over.  Is the patient ambulatory (can the patient more than 5 steps without falling)? A gait evaluation may be performed.  Assessing the patient's ability to urinate is important for many IVDD and spinal trauma cases.
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Gait evaluation</div>
          <img src={walking} alt="walking" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          Observe the patient as they walk away and then towards you.  Solid concrete footing is often best.  Listen for any scuffing of the nails, and look for abnormal nail wear.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>2. Cranial nerves may be assessed.</span> This may be done with specific nerve function tests such as the menace response, palpebral reflex, pupillary light reflex (PLR), and other cranial nerve tests.  In general, if the patient's eyes are central with normal symmetrical pupils, there is a bilateral menace response, the patient responds to voice, palpebral and lip simulation, and there is no gagging, trouble eating, or excessive drooling reported, then CNII through IX and XII are most likely in good working order.
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>3. Assess for conscious proprioception</span> Knuckling, hopping, extensor postural thrust, and wheelbarrowing can all be used to address this question.  Certain disorders that affect the spinal cord will affect proprioception.
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Righting response</div>
          <img src={righting} alt="righting" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          Flip all four paws.  Deficits should be repeatable. When recording: 2/2 is normal, 1/2 is reduced (slow), 0/2 is absent.
          </div>
          </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hopping</div>
          <img src={hopping} alt="hopping" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          The patient should hop nicely.  The patient should not drag the leg. Use minimal or no support under the chest.  Any deficits should be repeatable.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Extensor Postural Thrust</div>
          <img src={extensor} alt="extensor" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          The patient should walk backwards.  The patient should not drag the legs.  Any deficits should be repeatable.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>4. Spinal Reflexes:</span> Spinal reflexes happen without conscious input from the brain.  There are many that can be tested, but the withdraw reflexes and patellar reflex are the most reliable.  Normal reflexes are scored 2/2.  Weak or absent reflexes are scored 1/2 or 0/2 respectively.  With certain disorders, the reflexes are more pronounced; these can be scored 3/4 or even 4/4 if clonus is observed.  This is because certain nerves are actually inhibitory and tone down the response to the stimulus.
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Withdraw reflex</div>
          <img src={withdraw} alt="withdraw" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          Pinch between the toes.  Even animals that have no conscious motor function and no pain perception may have a withdraw reflex.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Patellar reflex</div>
          <img src={patellar} alt="patellar" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          <div>
          Palpate the patellar tendon just below the kneecap.  The larger side of a reflex hammer should be used to perform this test (pay no attention to the tool used in the video).
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>5. Does the patient have any increased sensitivity or react painfully to touch?</span> This is termed hyperesthesia.  Animals with spinal cord injuries may have paraspinal hyperesthesia.  Animals with intracranial disease (headaches, brain tumors, inflammation) may also demonstrate hyperesthesia.
          </Grid>
          <Grid item xs={12} md={4}>
            <span style={{fontWeight: "700"}}>6. Superficial and Deep Pain:</span> Patients that do not have motor function should be evaluated for superficial and deep pain perception.  This is generally performed with a hemostat applied to webbing of the toes (superficial pain) or used to squeeze the toe bone (deep pain).  With Intervertebral disc disease there is a progression from pain to loss of proprioception to loss of motor function to loss of superficial pain perception to loss of deep pain perception.  This has some prognostic value as well when considering surgical decompression of the spinal cord.
          </Grid>
        </Grid>

        </AccordionDetails>
      </Accordion>

  );
}
