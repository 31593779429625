import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ElectricalBurn() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Electric Shock (Chewed on Power Cord)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
              Commonly present after witness or suspected chewing of electrical cord. Especially with puppies and kittens, pet parents should limit access to electrical cords to prevent this.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Oral burns are common. Neurological signs and respiratory distress are possible. Cardiac arrhythmias are possible.
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Noncardiogenic pulmonary edema (NCPE) and respiratory distress may not be immediately obvious, and 24 hours of monitoring in hospital is suggested. Low volume resuscitation followed by balanced isotonic crystalloid fluid therapy should be considered. Oxygen therapy especially if concern for NCPE. Pain medication for burns. Treat cardiac arrhythmias if present. Pet parents should monitor for cataract formation after discharge.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
