import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CrcldRadiographs from '../Radiology/CrcldRads';

export default function Crcld() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cranial Cruciate Ligament Disease</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Cranial cruciate ligament (CCL) injury is a common condition that causes knee instability and pain. It is by far the most common cause of hindlimb lameness in the dog. Without surgical treatment, CCL injury speeds the progression of osteoarthritis.  The ACL is the analogous structure in the human.  Two important differences between CCL disease and an ACL injury are that: 1. The dog's knee joint is always on a sloped angle, and 2. The human ACL has better blood supply and can be repaired with surgery when torn.
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>
          Physical exam findings may include joint effusion on palpation, a failed sit test, positive cranial drawer, cranial tibial thrust, and a meniscal click on stifle ROM. Muscle loss at the thigh and medial buttress may be noticed with chronic injury.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>
          Initial diagnostics include stifle radiographs. Good stifle radiographs often require sedation. Stifle effusion or osteoarthritis support cranial cruciate disease. The tibia may appear to be displaced cranially in relation to the femur.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>
          Treatment may be surgical or medical. Many surgical techniques exist with the goal to add stability (as in extracapsular stabilization) or change the slope of the tibia. Slope-modifying techniques such as TPLO and CBLO are common. Medical management involves strict rest, pain medication, joint supplements, weight loss, and physical rehabilitation. 
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/ccl" target="_blank" rel="noopener noreferrer">Cranial Cruciate Ligament Injury Handout</a> from PetParentHandouts.com
        </Typography>
        <Typography>
        <a href="https://www.acvs.org/small-animal/cranial-cruciate-ligament-disease" target="_blank" rel="noopener noreferrer">Cranial Cruciate Ligament Disease Handout</a> from the American College of Veterinary Surgeons
        </Typography>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952244" target="_blank" rel="noopener noreferrer">Ruptured Cranial Cruciate Ligaments in Dogs</a> from the Veterinary Partner
        </Typography>
        </Grid>
        <CrcldRadiographs />
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
