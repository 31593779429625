import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DiabetesMellitus from './DiabetesMellitus';
import Hyperaldosteronism from './Hyperaldosteronism';
import Hyperadrenocorticism from './Hyperadrenocorticism';
import Hypoadrenocorticism from './Hypoadrenocorticism';

import Ahds from './AHDS';
import Constipation from './ConstipationFeline';
import ChronicDiarrhea from './ChronicDiarrhea';
import Parvovirus from './Parvovirus';
import Ple from './PLE';

import Imha from './IMHA';
import Itp from './ITP';

import PancCanine from './PancreatitisCanine';
import PancFeline from './PancreatitisFeline';

import FelineAsthma from './FelineAsthma';
import PleuralEffusion from '../Emergency/PleuralEffusion';
import PnuemoniaBacterial from './PneumoniaBacterial';

import HepaticLipidosis from './HepaticLipidosis';
import Hepatopathy from './Hepatopathy';

import Aki from './AcuteKidneyInjury';
import Ckd from './Ckd';
import Flutd from './FLUTD';
import Urolithiasis from './Urolithiasis';
import Hypothyroidism from './Hypothyroidism';
import Hyperthyroidism from './Hyperthyroidism';
import BrachycephalicAirway from '../Surgery/BrachycephalicAirway';
import Impa from './Impa';
import Incontinence from './Incontinence';
import Uti from './Uti';
import Prostatitis from './Prostatitis';
import TrachealCollapse from './TrachealCollapse';
import Larpar from './Larpar';
import Golpp from './Golpp';
import Triaditis from './Triaditis';
import PerianalFistula from '../Dermatology/PerianalFistula';
import UreteralObstruction from './UreteralObstruction';
import Leptospirosis from './Leptospirosis';

export default function IMBySystem() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Diseases By System</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: "0"}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Endocrine</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <DiabetesMellitus />
              <Hyperadrenocorticism />
              <Hyperaldosteronism />
              <Hyperthyroidism />
              <Hypoadrenocorticism />
              <Hypothyroidism />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Gastrointestinal</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Ahds />
              <Constipation />
              <ChronicDiarrhea />
              <Parvovirus />
              <Ple />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Hepatobiliary</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <HepaticLipidosis />
              <Hepatopathy />
              <Leptospirosis />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Hemolymphatic & Immune</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Imha />
              <Impa />
              <Itp />
              <PerianalFistula />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Pancreatic</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <PancCanine />
              <PancFeline />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Renal & Urinary</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Aki />
              <Prostatitis />
              <Ckd />
              <Flutd />
              <Incontinence />
              <Leptospirosis />
              <Uti />
              <UreteralObstruction />
              <Urolithiasis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Respiratory</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <FelineAsthma />
              <BrachycephalicAirway />
              <Golpp />
              <Larpar />
              <PleuralEffusion />
              <PnuemoniaBacterial />
              <TrachealCollapse />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
