import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ReverseSneeze() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Reverse Sneezing</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    These patients may present for coughing, choking, vomiting or perceived breathing difficulty. It is caused by any irritation of the soft palate. These dogs never collapse or are cyanotic, and signs have resolved by the time they are at the clinic.                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Choke, tracheal FB, other cause of hacking/retching
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Show the pet parent a few youtube videos of dogs reverse sneezing.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> No treatment needed. Have the pet parent attempt to video the next event to confirm. Pet parents can be instructed to massage the neck or blow in the nose in an attempt to elicit a swallow which may reposition the soft palate.
                    </Typography>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
