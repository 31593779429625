import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Shock() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Shock</Typography>
        </AccordionSummary>
        <AccordionDetails>
              <Typography>Energy (ATP) production does not meet energy demand usually due to inadequate oxygen delivery. Other definitions and many clasification schemes exist.</Typography>
            <ul>
              <li>
                Circulatory
              </li>
                <ul>
                  <li>
                    Hypovolemic (vasoconstrictive) - GI losses, hemorrhage
                  </li>
                  <li>
                    Distributive (vasodilated) - anaphylaxis, sepsis, hypoadrenocorticism
                  </li>
                  <li>
                    Cardiogenic - arrhythmias, systolic failure
                  </li>
                </ul>
              <li>
                Metabolic - sepsis, cyanide, hypoglycemia
              </li>
              <li>
                Hypoxic - anemia, hypoxemia
              </li>
            </ul>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Shock Considerations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Cats are more sensitive to fluid overload than dogs. Immediate treatment with oxygen and butorphanol (for sedation) may be helpful.
                 Initial evaluation and diagnostics should be directed towards ruling out cardiogenic shock/heart failure.
                  Once heart failure is ruled out, 5 mL/kg IV crystalloid bolus should be considered and can be repeated 2-3 times based on patient volume status.
                   If perfusion does not improve, consider 3-4 mL/kg 7.2% hypertonic saline
                    bolus<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Guidice et al.</Button> {refs ? " Limited fluid volume resuscitation (LFVR) in severe shock unresponsive to initial fluid challenge: A pilot study in 10 cats. Veterinary Anaesthesia and Analgesia 2018." : "" }</sup></Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
