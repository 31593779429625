import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BrokenClaw() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Broken Claw (Broken Nail)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: "700"}}>Signalment & Presentation:</span> Usually active dogs presenting with bleeding known claw injury or acute lameness. Often licking at the affected paw. Many will need to be sedated to further assess and treat.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: "700"}}>Ddx:</span> Traumatic claw injury is common. Look for erythema and swelling at the base of the claw which may be seen with bacterial infection, yeast overgrowth, embedded foreign bodies. Remember immune mediated conditions (such as lupoid onchodystrophy) and neoplasia may cause brittle broken claws.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Sedation is usually needed unless it's barely dangling on there. Remove the nail proximal to the break (often at the base). Bandage for about 12 hours. NSAID if not contraindicated. Antibiotics seldom warranted, but may be necessary if signs of infection. E collar.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
