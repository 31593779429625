import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FracturesForelimb() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Fractures</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
        Following emergency assessment and stabilization, you have a fracture to describe in your notes (or to a surgeon). Be sure to include:        
        </Typography>
        <ul>
          <li>
          Incomplete vs Complete
          </li>
          <li>
          Closed vs Open (open fractures may be graded)
          </li>
          <li>
          Configuration (Oblique, Spiral, Transverse, Complex - comminuted or segmental, Compression)
          </li>
          <li>
          Location 
          </li>
          <ul><li>Diaphyseal, Metaphyseal, Epiphyseal, Physeal</li>
          <li>Bone (include right or left)</li></ul>
          <li>Displacement</li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{fontWeight: "700"}}>
        Treatment Options:
        </Typography>
        <Typography>
        Goal is to guide the pet parent towards the most appropriate treatment option for the individual situation (including pet parent goals, abilities, and finances).
        </Typography>
        <ul>
          <li>
          Strict rest and pain medications - may be appropriate for minimally displaced greenstick fractures in young pets or certain non-weight bearing, single metatarsal/metacarpal fractures
          </li>
          <li>
          External coaptation (bandage, splint, or cast)
          </li>
          <li>
          Surgical repair
          </li>
          <li>
          Amputation (may be best option for high grade open fractures)
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/fractured-limbs" target="_blank" rel="noopener noreferrer">Fractured Limbs Handout</a> from the American College of Veterinary Surgeons
        </Typography>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952597" target="_blank" rel="noopener noreferrer">Fractures in Dogs and Cats Handout</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
