import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PerinealHernia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Perineal Hernia</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Perineal hernias occur when weakened pelvic diaphragm fails to prevent colon, small intestine, urinary bladder, or other abdominal contents from herniating into the space adjacent to the rectum.        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Often present for straining to urinate, straining to defecate, or pain. May or may not have an obvious swelling next to the anus. Some cases will have bilateral weakening and herniation. Usually a disease of older male (androgens may be a contributing factor in intact males) dogs.        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Other mass/swelling, abscess; Can usually be differentiated by palpation and digital rectal examination
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> Consider focused ultrasound, Consider abdominal radiographs especially if concern for constipation, Chemistry or point of care blood testing with electrolytes especially if concern for urinary obstruction.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span> Surgical correction is usually needed. If no abdominal organs are entrapped, consider medical management with stool softeners, enemas, dietary management (often high-fiber food). 
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/perineal-hernias" target="_blank" rel="noopener noreferrer">Perineal Hernias</a> from ACVS
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
