import React from "react";

import CanineAlfaxCalculator from './CanineAlfaxCalculator'

export default function x() {

  return (
    <>
      <h1>Canine Alfax (IM)</h1>
      <CanineAlfaxCalculator/>
  </>

  );
}
