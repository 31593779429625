import React from "react";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const Bu = styled(Button)`
  text-align: center;
  margin: auto;
  text-transform: none;
  color: inherit;
  font-family: 'Outfit';
`;

export default function Calculators() {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: '900px', margin: 'auto'}}>
      <Typography variant="h6">Emergency</Typography>
      <Bu href="/calculators/emergency-drug-calculator" variant="outlined" sx={{width: '360px'}}>Emergency Drugs</Bu>
      <Bu href="/calculators/allergic-reaction" variant="outlined" sx={{width: '360px'}}>Allergic Reaction</Bu>
      <Bu href="/calculators/emesis-calculator" variant="outlined" sx={{width: '360px'}}>Induction of Emesis</Bu>
      <Bu href="https://www.veterinarypartner.com/ChocToxCalc_vetPartner.html" target="_blank" rel="noopener noreferrer" variant="outlined" sx={{width: '360px'}}>VIN Chocolate Toxicity</Bu>
      <Typography variant="h6" gutterBottom component="div">Canine Sedation</Typography>
      <Grid container justifyContent="space-between" alignItems="center" sx={{maxWidth: '360px'}}>
        <Grid item xs="auto">
          <Bu href="/calculators/dex-torb" variant="outlined" sx={{width: '110px'}}>Dex/Torb</Bu>
        </Grid>
        <Grid item xs="auto">
          <Bu href="/calculators/canine-alfax" variant="outlined" sx={{width: '110px'}}>Alfax</Bu>
        </Grid>
        <Grid item xs="auto">
          <Bu href="/calculators/canine-ace-opioid" variant="outlined" sx={{width: '110px'}}>Ace/Opioid</Bu>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom component="div">Feline Sedation</Typography>
      <Grid container justifyContent="space-between" alignItems="center" sx={{maxWidth: '360px'}}>
        <Grid item xs="auto">
          <Bu href="/calculators/feline-alfax" variant="outlined" sx={{width: '170px'}}>Alfax (IM)</Bu>
        </Grid>
        <Grid item xs="auto">
          <Bu href="/calculators/dkt" variant="outlined" sx={{width: '170px'}}>DKT</Bu>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom component="div">Fluids</Typography>
      <Grid container justifyContent="space-between" alignItems="center" sx={{maxWidth: '360px'}}>
        <Grid item xs="auto">
          <Bu href="/calculators/canine-fluids" variant="outlined" sx={{width: '170px'}}>Canine Fluids</Bu>
        </Grid>
        <Grid item xs="auto">
          <Bu href="/calculators/feline-fluids" variant="outlined" sx={{width: '170px'}}>Feline Fluids</Bu>
        </Grid>
      </Grid>
    </Stack>
  );
}
