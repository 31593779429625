import React from "react";
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';

const Item = styled(Paper)`
  text-align: center;
  font-size: 1.2rem;
  font-family: "Spectral SC", serif;
`;

export default function NotFound() {
  return (
    <>
      <Item style={{textAlign: 'center'}} elevation={0}>This page does not exist</Item>
    </>
  );
}
