import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';

import TrachealCollapse1RLat from './images/trachealCollapseOpen.jpg';
import TrachealCollapse2RLat from './images/trachealCollapseClosed.jpg';

export default function TrachealCollapseRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Tracheal collapse may be dynamic and often changes as the patient breaths.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={TrachealCollapse1RLat} alt="tracheal collapse" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={TrachealCollapse2RLat} alt="tracheal collapse" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
