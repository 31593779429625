import React from 'react';

import {Accordion, Grid, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Thoracocentesis from './ThoracocentesisProcedure';

export default function Chylothorax() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Chylothorax</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto" style={{paddingBottom: "20px"}}>
                  <Button href="/quick-sheets/respiratory-distress" variant="outlined">Respiratory Distress Quick Sheet</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Chyle is the milky triglyceride-rich fluid taken up by lacteals in the digestive tract. It passes through the thoracic duct near the aorta and mixes into veins. Many underlying conditions can cause or be associated with chylothorax, so an extensive workup may be needed to reach a diagnosis of idiopathic chylothorax (which is common). Heartworm disease, cancer, and thoracic duct rupture are a few other common conditions associated with chylothorax.                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography >
                    <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Often present with respiratory distress and pleural effusion on focused ultrasound or radiographs.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Pyothorax or other pleural effusion.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Thoracocentesis reveals classic chylous effusion (triglycerides in fluid&gt;serum). Further diagnostics may include fluid cytology + culture and sensitivity, radiographs, ultrasound, echocardiography, and blood work that includes heartworm/FIV/FeLV testing. 
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span>
                    </Typography>
                    <Thoracocentesis />
                    <Typography>
                    Repeat thoracocentesis may be needed. Surgery is an option but may be controversial especially if idiopathic suspected. Rutin is a supplement that may help. Low-fat diets may help.                    
                    </Typography>
                  </Grid>
                  
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
