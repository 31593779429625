import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Bufo() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Bufo Toad (Cane Toad, Rhinella marina)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
              Common in Hawaii, Florida, South Texas. The venom is ingested or absorbed through mucous membranes.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Drooling, hyperemic (brick-red) mucous membranes, tremors, seizures, cardiac arrhythmias
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Oral decontaminaiton/lavage (rinse the mouth - caution not to cause aspiration); consider maropitant; treat seizures with benzodiazapines (midazolam or diazepam), atropine for bradyarrhythmias; propranolol for sinus tachyarrhthmias.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Reference:</span><br/>
            <sup>1</sup>Johnnides et al. Toad Intoxication in the Dog by Rhinella marina: The Clinical Syndrome and Current Treatment Recommendations. JAAHA 2016.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
