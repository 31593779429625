import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PyometraAUS from '../Radiology/PyometraUterus';
import PyometraRads from '../Radiology/PyometraRads';

export default function Pyometra() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pyometra</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pyometra" variant="outlined">Pyometra Quick Sheet</Button>
            </Grid>
            <Grid item xs={12}>
            <PyometraAUS />
            </Grid>
            <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pyometra Radiographs</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <PyometraRads />
              </AccordionDetails>
            </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
