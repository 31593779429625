import React from 'react';

export default function TempTarsorrhaphyList() {
  return (

          <ul>
          <li>
            Start at the lateral part of the eyelid. Place suture dorsally, exit along <span style={{fontWeight: '700'}}>Meibomian glands</span> of upper lid. Enter at Meibomian glands of lower lid. Exit ventrally. Repeat from ventral to dorsal to create a horizontal mattress suture. Usually 2-3 horizontal mattress sutures are needed and will leave a small opening at the medial canthus.
          </li>
          <li>
            <span style={{fontWeight: '700'}}>Entering and exiting at the Meibomian glands is most important.</span>
          </li>
          <li>
            Sutures should stay in place for at least 2-3 weeks (with recheck examinations more frequently that this). Swelling must completely resolve and scar tissue must form.
          </li>
          <li>
            In few cases, a lateral canthotomy will be needed to replace the globe behind the lids.
          </li>
          </ul>

    );
  }
