import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import rbjpg from './images/retrobulbarAbscess.jpg';

export default function RetrobulbarAbscess() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Retrobulbar Abscess</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Causes:</span> Usually some other underlying disease that makes these patients prone to infection (such as diabetes mellitus or hyperadrenocorticism).
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            Rule out retrobulbar neoplasia or foreign body. A CT is often a helpful diagnostic tool.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            May present with exophthalmos, mouth pain and hyporexia, chemosis with protrusion of the third eyelid, and fever.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> Be thorough and look for other underlying disease that set the patient up for this abscess. Following CT or with full disclosure of the important structures behind the eye (and potentially fatal consequences), it is possible to make a stab incision through the oral mucosa behind the last molar. This must be done under anesthesia. This can be further opened with blunt dissection by curved hemostats. Fluid should be collected for aerobic and anaerobic culture. The wound is left open to drain. Start broad spectrum empirical antibiotics (enrofloxacin and clindamycin or Unasyn) if you haven't already.
          </Typography>
          </Grid>   
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={rbjpg} alt="posterior lens luxation" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>Severe chemosis and protrusion of the third eyelid in a dog with a retrobulbar abscess. The globe was intact and could not be retropulsed. This dog was painful on attempt to open the mouth.</div>
          </Paper>
          </Grid>       
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
