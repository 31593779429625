import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Blurb() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Client Communication</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{fontWeight: "700"}}>
                    Avoid grain-free diets.
                    </Typography>
                    <Typography gutterBottom>
                    Many grain-free diets have been linked to a form of heart disease called dilated cardiomyopathy (DCM). Approximately one-third of dogs with DCM die suddenly with no obvious signs of disease.
                    </Typography>
                    <Typography style={{fontWeight: "700"}}>
                    Feed your pet high quality food appropriate for their life stage from a trusted pet food brand.
                    </Typography>
                    <Typography gutterBottom>
                    An AAFCO Nutritional Adequacy Statement that indicates the food is complete and balanced.
                    </Typography>
                    <Typography style={{fontWeight: "700"}}>
                    Monitor your pet's weight and body condition score (BCS) to ensure its food intake is appropriate.
                    </Typography>
                    <Typography gutterBottom>
                    Obesity has been linked to several health problems in dogs and cats. The nutritional requirements of individuals vary with breed, activity level, and life stage.
                    </Typography>
                    <Typography style={{fontWeight: "700"}}>
                    Treats should make up no more than 10% of your pets dietary calorie intake.
                    </Typography>
        </AccordionDetails>
      </Accordion>


  );
}
