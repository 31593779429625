import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AcetateTape from './AcetateTapeCytology';
import ClawFold from './ClawFoldCytology';

export default function YeastPododermatitis() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Yeast Paronychia & Pododermatitis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Commonly confused with or brushed off as allergic skin disease. This is a common cause of pedal pruritus and paw licking. Yeast are wimpy and easy to treat, and treatment may hugely reduce paw-licking and itch.</Typography>
                  <Typography>If salivary staining of paws or reported paw itch/licking, please do the diagnostics to rule out/in yeast overgrowth.  Every time you miss this, pets suffer and pet parents pay too much for Apoquel.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
                  <AcetateTape />
                  <ClawFold />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Topical azole-containing shampoos to consider include: Douxo Chlorhexidine PS + Climbazole Shampoo, VetriMax Command Dog Shampoo, Dechra MiconaHex Triz Shampoo.
                  </li>
                    <ul>
                    <li>
                    Bath up to twice each week in cool to room temperature water. A small amount (palmful) can be diluted in one pint of water to extend use. Focus on problem areas and gently massage into the coat. Let sit for 5-10 minutes before rinsing as contact time kills skin yeast and bacteria.
                    </li>
                    </ul>
                  <li>
                  Topical local therapy for between bathing: Douxo Chlorhexidine + Climbazole Mousse, Dechra MiconaHex + Triz Spray, Dechra TrizCHLOR 4 Wipes
                  </li>
                    <ul>
                    <li>Use between bathing to reduce yeast and bacterial overgrowth. Focus on problem areas and gently massage/wipe into the coat with the direction of fur and leave in (do not rinse).</li>
                    </ul>
                  <li>
                  Fluconazole: Give 2.5-5 mg/kg q24h for 2-4 weeks, follow with cytology to monitor for progress/resolution. Then use two consecutive days each week ("weekend pulse therapy") to keep yeast down.
                  </li>
                    <ul>
                    <li>A life-changing treatment for many pets!</li>
                    </ul>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
