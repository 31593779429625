import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import Tooth from './images/ToothAnatomy.jpg'
import Gingiva from './images/Gingiva.jpg'

export default function DentalAnatomy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Dental Formulas & Tooth Anatomy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Typography>
          <span style={{fontWeight: "700"}}>Puppies:</span> 2 x &#123;I3/3 C1/1 PM3/3&#125; = 28
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography>
          <span style={{fontWeight: "700"}}>Adult Dogs:</span> 2 x &#123;I3/3 C1/1 PM4/4 M2/3&#125; = 42
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography>
          <span style={{fontWeight: "700"}}>Kittens:</span> 2 x &#123;I3/3 C1/1 PM3/2&#125; = 26
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography>
          <span style={{fontWeight: "700"}}>Adult Cats:</span> 2 x &#123;I3/3 C1/1 PM3/2 M1/1&#125; = 30
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={Tooth} alt="tooth" style={{borderRadius:"8%", maxWidth: "96%"}}/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
          <ol>
          <li>Root</li><li>Apex and apical delta</li><li>Root canal</li><li>Periodontal ligament</li><li>Alveolar crest</li><li>Crown</li><li>Enamel</li><li>Pulp horn</li><li>Dentin</li><li>Pulp chamber
          </li>
          </ol>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={Gingiva} alt="gingiva" style={{borderRadius:"8%", maxWidth: "96%"}}/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
          <ol>
          <li>Enamel</li><li>Dentin</li><li>Pulp</li><li>Cervical bulge</li><li>Gingival sulcus</li><li>Free gingiva</li><li>Attached gingiva</li><li>Mucogingival junction</li><li>Mucosa</li><li>Alveolar bone</li><li>Cemento-enamel junction</li><li>Junctional epithelium</li><li>Connective tissue attachment</li><li>Cementum</li><li>Periodontal ligament</li>
          </ol>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
