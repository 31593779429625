import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function CanineBehaviorSupps() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Supplements & Pheromones</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Paper>
          <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">Alpha-Casozepine (Zylkene)</TableCell>
              <TableCell align="left">Non-specific anxiety (Beata et al 2007)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Calming Care probiotic (Purina)</TableCell>
              <TableCell align="left">Bifidobacterium longum has some encouraging evidence from human studies (Pinto-Sanchez 2017; Luna 2014)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">L-theanine (Solliquin)</TableCell>
              <TableCell align="left">Lessen stress, treat mild anxiety (Araujo et al 2010; Kimura et al 2007)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Dog Appeasing Pheromone</TableCell>
              <TableCell align="left">Diffusers, sprays, and collars are available;  may take 24-48 hours to diffuse through a room</TableCell>
            </TableRow>
          </TableBody>
          </Table>
        </Paper>
        </AccordionDetails>
      </Accordion>

  );
}
