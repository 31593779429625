import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function CommonBehaviorDrugsCanine() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Common Drugs</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>Drug (dose range)</TableCell>
            <TableCell align="center" colSpan={2}>Starting Dose Range (mg)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">min</TableCell>
            <TableCell align="center">max</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Alprazolam</span> (0.02 - 0.04 mg/kg PO as needed)</TableCell>
                <TableCell align="left">{(weightKg*(0.02)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(0.04)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Panic and panic disorder, short-term treatment of acute and severe anxiety (noise phobias, fireworks, thunderstorms, veterinary visits)
              </li>
              <li>
               Fluoxetine will &uarr;t<sub>1/2</sub>
              </li>
              <li>
               Tablets: 0.25, 0.5, 1, 2 mg
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Amitriptyline</span> (1 - 2 mg/kg PO q12h)</TableCell>
                <TableCell align="left">{(weightKg*(1)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(2)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Mild, relatively non-specific anxieties
              </li>
              <li>
               Tablets: 10, 25, 50, 75, 100, 150 mg
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Clomipramine</span> (1 - 3 mg/kg PO q12h)</TableCell>
                <TableCell align="left">{(weightKg*(1)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(3)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Anxiety, obsessive compulsive behaviors, separation anxiety
              </li>
              <li>
                Start at 1 mg/kg dose and increase by 1 mg/kg every 2 weeks to dose of 3 mg/kg
              </li>
              <li>
               Capsules (Anafranil): 25, 50, 75 mg
              </li>
              <li>
               Tablets (Clomicalm): 20, 40, 80 mg
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Dexmedetomidine oromucosal gel (Sileo)</span> (125 mcg/m<sup>2</sup>)</TableCell>
                <TableCell align="left" colSpan={2}>Follow dose chart on insert</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Labeled for noise aversion
              </li>
              <li>
                May be useful for other anxieties
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Fluoxetine</span> (0.5 - 1 mg/kg PO q12-24h)</TableCell>
                <TableCell align="left">{(weightKg*(0.5)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(1)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Outburst aggression & related anxieties, explosive forms of separation anxiety or obsessive compulsive disorders
              </li>
              <li>
                Must evaluate for at least 8 weeks
              </li>
              <li>
               Capsules (Prozac): 10, 20 mg
              </li>
              <li>
               Solution: 5 mg/mL
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Gabapentin</span> (5 - 10 mg/kg PO q12h)</TableCell>
                <TableCell align="left">{(weightKg*(5)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(20)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Decreases overall arousal and anxiety levels with few potential side effects
              </li>
              <li>
                Can dose as low as 2 mg/kg or as high as 20 mg/kg
              </li>
              <li>
               Capsules: 100, 300, 400 mg
              </li>
              <li>
               Liquid: 250 mg/5mL = 50 mg/mL
              </li>
              <li>
               Compounded tablets available for small dogs
              </li>
              </ul>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><span style={{fontWeight: '900'}}>Trazodone</span> (1.7 - 9.5 mg/kg PO q8-24h)</TableCell>
                <TableCell align="left">{(weightKg*(1.7)).toFixed(2)}</TableCell>
                <TableCell align="left">{(weightKg*(9.5)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="left" colSpan={3}>
              <ul>
              <li>
                Adjunct anxiolytic
              </li>
              <li>
                Commonly used for pre-visit pharmaceutical to reduce anxiety at veterinary visits
              </li>
              <li>
               Less abuse potential than alprazolam when prescribed for noise-phobias (thunderstorms, fireworks)
              </li>
              <li>
               Tablets: 50, 100 mg
              </li>
              </ul>
              </TableCell>
              </TableRow>

            </TableBody>




      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    This calculator is meant to double check your math.  It is not a substitute for calculating your patient's dose.  Always double check drug dosages and concentrations.  These rough dose ranges are based on clinical experience.
    </li>
    <li>
    Many of these drugs have side effects, reported adverse events, and drug interactions; be aware of these before use.
    </li>
    <li>
    Table modified from Overall Manual of Clinical Behavioral Medicine for Dogs and Cats (2013)
    </li>
    </ul>
    </Grid>
    </Grid>
    </AccordionDetails>
    </Accordion>

  );
}
