import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://petnutritionalliance.org/dog.php" target="_blank" rel="noopener noreferrer">Pet Nutrition Alliance Calorie Calculator</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://secure.balanceit.com/recipegenerator_ver4/index.php?rotator=EZ" target="_blank" rel="noopener noreferrer">BalanceIT</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.fda.gov/animal-veterinary/safety-health/recalls-withdrawals" target="_blank" rel="noopener noreferrer">FDA Recalls & Withdrawals</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://acvn.org/nutrition-resources/" target="_blank" rel="noopener noreferrer">ACVN Guidance Documents and Forms</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://vet.osu.edu/vmc/companion/our-services/nutrition-support-service/nutrition-consult-request" target="_blank" rel="noopener noreferrer">OSU Nutrition Consult Request</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/2021-aaha-nutrition-and-weight-management-guidelines/home/" target="_blank" rel="noopener noreferrer">2021 AAHA Nutrition and Weight Management Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://wsava.org/global-guidelines/global-nutrition-guidelines/" target="_blank" rel="noopener noreferrer">WSAVA Global Nutrition Guidelines</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
