import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import EruptionTimes from './EruptionTimes';
import DentalAnatomy from './DentalAnatomy';
import DentalProphy from './DentalProphy';
import DentalBlocks from '../Anesthesia/DentalBlocks';
import Extractions from './Extractions';
import Glossary from './Glossary';

import AdditionalResources from './AdditionalResources';
import DentalProblems from './DentalProblems';

export default function Dentistry() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <EruptionTimes />
        <DentalAnatomy />
        <DentalProphy />
        <DentalBlocks />
        <Extractions />
        <DentalProblems />
        <Glossary />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
