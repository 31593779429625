import React from 'react'
import { Grid, IconButton, Button } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import BySystem from './BySystem';
import EnvironmentalEmergencies from './EnvironmentalEmergencies';
import Toxicology from './Toxicology';
import EmergencyProcedures from './EmergencyProcedures';
import AdditionalResources from './AdditionalResources';
import SystemicConditions from './SystemicConditions';
import TherapeuticInterventions from './TherapeuticInterventions';

export default function Dentistry() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto">
        <Button href="/calculators/emergency-drug-calculator" variant="outlined">Emergency Drugs</Button>
      </Grid>
      <Grid item xs="auto">
        <Button href="/quick-sheets/cpr" variant="outlined">CPR</Button>
      </Grid>
      <Grid item xs="auto">
        <Button href="/quick-sheets" variant="outlined">Quick Sheets</Button>
      </Grid>
      <Grid item xs={12}>
        <BySystem />
        <SystemicConditions />
        <EnvironmentalEmergencies />
        <Toxicology />
        <EmergencyProcedures />
        <TherapeuticInterventions />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
