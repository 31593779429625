import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import Spherocytes from '../ClinicalPathology/images/spherocyte.jpg';
import agglutination from '../ClinicalPathology/images/agglutination.jpg';

export default function Imha() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Immune-Mediated Hemolytic Anemia (IMHA)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/imha" variant="outlined">IMHA Quick Sheet</Button>
            </Grid>
          </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> lethargy, pale gums, hyporexia, weakness</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            CBC/Chemistry (consider CBC with pathology review and reticulocyte count)
            </li>
            <li>
              PCV/TS
            </li>
            <li>
              Blood Smear
            </li>
            <li>
              Blood typing
            </li>
            <li>
            Urinalysis - may help differentiate bilirubinemia vs hemoglobinuria (which has discolored supernatant following centrifugation); consider urine culture (which can be trigger for IMHA)
            </li>
            <li>
            Saline slide agglutination test
            </li>
            <li>
            Heartworm test (especially dogs)
            </li>
            <li>
            Tick titer or SNAP 4Dx
            </li>
            <li>
            3-view thoracic radiographs
            </li>
            <li>
            AUS
            </li>
            <li>
            Consider abdominal radiograph to rule out zinc/metallic foreign body
            </li>
            <li>
            Bone marrow biopsy should be considered if non-regenerative anemia
            </li>
          </ul>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={agglutination} alt="curtled red blood cells on a glass slide" style={{maxWidth: "98%"}}/>
                <Typography>Positive saline agglutination test (mix 1 drop of blood with 4 drops of saline).</Typography>
                </Paper>
              </Grid>
          <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={Spherocytes} alt="spherocytes on blood smear" style={{maxWidth: "98%"}}/>
                <Typography>Sperocytes, anisocytosis, and polychromasia. Typical blood smear of a patient with IMHA.</Typography>
                </Paper>
              </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.15441" target="_blank" rel="noopener noreferrer">ACVIM consensus statement on the diagnosis of immune-mediated hemolytic anemia in dogs and cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.15463" target="_blank" rel="noopener noreferrer">ACVIM consensus statement on the treatment of immune-mediated hemolytic anemia in dogs</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951868" target="_blank" rel="noopener noreferrer">Immune Mediated Hemolytic Anemia (IMHA) in Dogs and Cats</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
