import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


class WoundsQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Wounds & Lacerations (Canine)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom><span style={{fontWeight: '700'}}>Presentation:</span> Many variables will determine better courses of action for specific wounds. Think about factors that will contribute to complications, and set expectations accordingly (&le; 6 hours since injury & superficial &rarr; more favorable for primary closure; puncture wounds are often best left open to allow drainage). Don't miss the neurologic, pulmonary, intra-abdominal, or orthopedic trauma&mdash;some superficial wounds just need to be covered while you perform proper assessment and stabilization.</Typography>
            <Typography><span style={{fontWeight: '700'}}>Key points for communication:</span></Typography>
            <ul>
            <li>Sedation or anesthesia is usually necessary to clip, clean, explore, and possibly debride or close the wound.
            </li>
            <li>The full extent of the injury may not be apparent for several days due to crush injury or loss of blood supply to affected tissue.
            </li>
            <li>Common sequelae of bite wounds and lacerations may include infection, abscessation, tissue necrosis, and eschar formation. If closed, complications also include dehiscence, seroma formation, and suture reaction.
            </li>
            <li>The wound will require follow up care. In best case scenarios, recheck is recommended in 3-4 days. If a bandage is placed, recheck in 24 hours or sooner if bandage shifts or becomes soiled.
            </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Place patient in hard plastic E-collar</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider immediate analgesia</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
          Consider Methadone at 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL</span> of 10 mg/mL Methadone IM for this patient.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider pre-surgical blood work to assess renal and liver health</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          This may also be beneficial if prescribing an NSAID.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider placing an IV catheter</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Appropriate sedation or anesthesia given the patient and the injury</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider aerobic +/- anaerobic culture</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Pull eyelids over the globe"
            inputProps={{ 'aria-label': 'pull eyelids over the globe' }}
            /><span style={{fontWeight: '700'}}>Clip, clean, explore, and consider primary closure or bandage</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Temporary partial Tarsorrhaphy"
            inputProps={{ 'aria-label': 'Temporary partial Tarsorrhaphy' }}
            /><span style={{fontWeight: '700'}}>Consider injectable NSAID</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Temporary partial Tarsorrhaphy"
            inputProps={{ 'aria-label': 'Temporary partial Tarsorrhaphy' }}
            /><span style={{fontWeight: '700'}}>Consider injectable antibiotic</span>
          </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Carprofen (Rimadyl)</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              2.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(1)).toFixed(1)} mg</span> q12h
            </li>
            <li>
              4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(1)).toFixed(1)} mg</span> q24h
            </li>
            <li>
              Always round down your dose, can use 1/2 tablet increments
            </li>
            <li>
              Available as 25 mg, 75 mg, 100 mg
            </li>
            <li>
              Never combine with steroids or other NSAID drugs
            </li>
            <li>
              Also available as 50 mg/mL injectable solution: consider 2.2 to 4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(50)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(50)).toFixed(2)} mL</span> of 50 mg/mL injectable carprofen SC as first treatment.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Meloxicam</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)).toFixed(2)} mg</span> or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.1))/(1.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>1.5 mg/mL</span> oral solution q24h.
            </li>
            <li>
              0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)).toFixed(2)} mg</span> or <span style={{backgroundColor: '#fff2ac'}}>{((this.state.weightkg*(0.1))/(0.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>0.5 mg/mL</span> oral solution q24h.
            </li>
            <li>
              Always round down your dose
            </li>
            <li>
              Most commonly available as 1.5 mg/mL oral suspension
            </li>
            <li>
              Never combine with steroids or other NSAID drugs
            </li>
            <li>
              Also available as 5 mg/mL injectable solution: consider 0.1 to 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(5)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.2)/(5)).toFixed(2)} mL</span> of 5 mg/mL injectable meloxicam SC as first treatment.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Gabapentin</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              5-10 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(1)).toFixed(1)} mg</span> q8-12h
            </li>
            <li>
              Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
            </li>
            <li>
              Can round up dose and/or increase depending on patient response
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Clavamox</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 12 hours.  This is an antibiotic. Give with food.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              16-20 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(16)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(20)/(1)).toFixed(1)} mg</span> q12h
            </li>
            <li>
              Available most commonly as 62.5 mg, 125 mg, 250 mg, or 375 mg chew tablets or 62.5 mg/mL solution
            </li>
            <li>
              Human generic Augmentin may be significantly cheaper and is available as 500/125 mg (625 mg Clavamox equivalent) tablets or 80 mg/mL (400mg/5mL) oral suspension (available in 50 mL bottle)
            </li>
            </ul>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=6028962" target="_blank" rel="noopener noreferrer">Wound Healing in Dogs and Cats</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=10042934&ind=325&objTypeID=1007" target="_blank" rel="noopener noreferrer">Wound Care for Pets</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default WoundsQS;
