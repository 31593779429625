import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function MandibularFractures() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Mandibular Fractures</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Mandibular fractures are common following trauma. Any cat that presents for a fall injury should be evaluated for a mandibular symphyseal fracture.
                    </Typography>
                    <Typography gutterBottom>
                    Usually present following known trauma. Mandibular instability can often be palpated if it is not obvious from visual examination (and misalignment). 
                    </Typography>
                    <Typography>
                    Some of these patients may also present with other facial fractures, head trauma, carpal hyperextension injuries (following a fall).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Palpation alone can help characterize mandibular fractures, but CT, radiographs (standard radiographs are challenging, dental radiographs are best). Dental plates can be placed extra-orally in more-lightly sedated patients (don’t usually put them in the mouth if there is any risk of plate damage) for assessment.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> Many of these should see a veterinary dentist familiar with how to approach jaw fractures (traditional implants and techniques are contraindicated for many and most non-dentists don’t carry temporary crown bridging material or do custom external skeletal fixation on the jaw). A temporary tape muzzle can be made on a sedated dog to keep the mouth closed/almost closed. A mandibular body fracture that starts dorsocaudal and runs obliquely to the ventrorostral direction will be most amenable to healing as forces of the jaw will compress the fracture line. Always consider esophageal feeding tube placement in these patients.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Wiring mandibular symphyseal fractures</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      <li>
                      Make a small incision on the ventral chin
                      </li>
                      <li>
                      Insert a 16G needle through the incision that passes just caudal to and on the lateral side of the canine
                      </li>
                      <li>
                      Pass a 0.8mm ortho wire from ventral to dorsal, and remove the needle
                      </li>
                      <li>
                      Reinsert the needle on the opposite side, and direct the wire dorsal to ventral down this needle; remove the needle
                      </li>
                      <li>
                      Pull the loop of wire tight behind the canine teeth. 
                      </li>
                      <li>
                      Reduce the fracture so that the lower jaw opposes the upper jaw appropriately.
                      </li>
                      <li>
                      Tighten and twist the wire; The twisted end can be buried under the skin surface
                      </li>
                      <li>
                      Close the skin incision
                      </li>
                      <li>
                      Wire may not need removal, but removal can be considered in 6-12 weeks based on palpation (not radiographs&mdash;there was always, and will always be, a lucency).
                      </li>
                    </ul>
                  </AccordionDetails>
                  </Accordion>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
