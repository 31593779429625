import React from 'react';
import { Grid, Paper, } from '@mui/material';

import NormalStifleLat from './images/orthopedic/hindlimb/stifle_Rlat_case2.jpg';
import NormalStifleCrCd from './images/orthopedic/hindlimb/stifle_CrCd_case2.jpg';

export default function NormalStifleRads() {

  return (
          <>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Normal Canine Stifle</span></div>
          <img src={NormalStifleLat} alt="Normal Stifle" style={{maxWidth: "96%"}}/>
          <img src={NormalStifleCrCd} alt="Normal Stifle" style={{maxWidth: "96%"}}/>
          <div>Unremarkable stifle radiographs</div>
          </Paper>
          </Grid>
          </>


  );
}
