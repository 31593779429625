import React, { useState } from 'react';
import { Button } from '@mui/material';

export default function BacteriuriaSubclinical() {

  const [ref2, setRef2] = useState(false);
  const [ref3, setRef3] = useState(false);

  return (

                <>
                  <li>
                  Some dogs and cats with bacteriuria will be asymptomatic.  The decision to treat, start prophylactic therapies, or do nothing is largely case depended.
                  </li>
                    <ul>
                    <li>
                    Of 101 healthy client-owned female dogs, 8.9% had subclinical bacteriuria. None required antimicrobial treatment during 3-month observation period.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>Wan et al.</Button> {ref2 ? " Prevalence and clinical outcome of subclinical bacteriuria in female dogs. JAVMA 2014." : "" }</sup>
                    </li>
                    <li>
                    Subclinical bacteriuria ranged from 10-13% in older (&ge;7 years), nonazotemic female cats tested on five occasions over three years. No antibiotics were given and subclinical bacteriuria was not adversely associated with survival.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef3(!ref3)}>White et al.</Button> {ref3 ? " Subclinical Bacteriuria in Older Cats and its Association with Survival. JVIM 2016." : "" }</sup>
                    </li>
                    </ul>
                </>
              
  );
}
