import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableBody, TableCell,TableHead, TableRow, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class DkaQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      dehydration: '8',
      switchSevere: false,
      shock: '0',
      fluidrate: '0',
      k: '0',
      kp: '40',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handledehydrationChange = this.handledehydrationChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleshockChange = this.handleshockChange.bind(this);
    this.handlefluidrateChange = this.handlefluidrateChange.bind(this);
    this.handlekChange = this.handlekChange.bind(this)
    this.handlekpChange = this.handlekpChange.bind(this)
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handledehydrationChange(event) {
    this.setState({
      dehydration: event.target.value,
    });
}
  handlekChange(event) {
    this.setState({
      k: event.target.value,
    });
}
  handlekpChange(event) {
    this.setState({
      kp: event.target.value,
    });
}
handlefluidrateChange(event) {
this.setState({
  fluidrate: event.target.value,
});
}
handleshockChange(event) {
  this.setState({
    shock: event.target.value,
  });
}
handleSwitchChange(event) {
  this.setState({
    switchSevere: event.target.checked
  })
}

  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Diabetic Ketoacidosis (DKA)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid container align="left">
        <Grid item xs={12}>
              <span style={{fontWeight: '700'}}>Presentation:</span> lethargy, mental, depression, anorexia, vomiting, diarrhea, weakness, +/- ketone breath, +/- history of diabetes or suspicion (PU/PD, polyphagia, weight loss)
              </Grid>

              <Grid item xs={12}>
              <span style={{fontWeight: '700'}}>Initial Diagnostics:</span> venous blood gas, blood glucose, electrolytes, lactate, and BUN/creatinine
              </Grid>

              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>

              <Grid item xs={12}>
              Consider <span style={{fontWeight: '700'}}>hyperglycemic hyperosmolar syndrome (HHS)</span> in patients with severe hyperglycemia.  These patients may have little to no serum ketones, severe hyperosmolality, and may need slower/more cautious correction of fluid volume status and blood glucose.  Hypernatremia may complicate and require more cautious correction.
              </Grid>

              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>

              <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
                <Grid item>Canine</Grid>
                <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
                </Grid>
                <Grid item>Feline</Grid>
              </Grid>
                {this.state.switchSevere ?
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
                    </Grid>

                    <Grid item xs={12}>
                    <ul>
                    <li>
                    Consider a 1/4 shock bolus of 15 mL/kg = {(this.state.weightkg*(15)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
                    </li>
                    <TextField
                      id="dehydration"
                      type="number" value={this.state.dehydration} onChange={this.handledehydrationChange}
                      label="% dehydration"
                      margin="normal"
                      variant="outlined"
                      InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>,}}
                      />
                    <li>
                    Maintenance rate (exponential formula, BW<sub>kg</sub><sup>0.75</sup>x130/24) = {((this.state.weightkg**(0.75))*130/24).toFixed(0)} mL/hr; Fluid deficit of {this.state.dehydration}% = {(this.state.weightkg*(this.state.dehydration)*10).toFixed(0)} mL
                    </li>
                    <li>
                    Subtract shock fluids given during initial stabilization
                    </li>
                    <TextField
                      id="shock"
                      type="number" value={this.state.shock} onChange={this.handleshockChange}
                      label="Shock fluids given"
                      margin="normal"
                      variant="outlined"
                      InputProps={{endAdornment: <InputAdornment position="end">mL</InputAdornment>,}}
                      />
                    <li>
                    If correcting {this.state.dehydration}% dehydration over 12-24 hours, appropriate fluid rate ranges from {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/24)).toFixed(0)} mL/hr to {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/12)).toFixed(0)} mL/hr
                    </li>
                    <li>
                    Account for and add additional losses (such as vomiting and diarrhea) to your fluid plan
                    </li>
                    </ul>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Apply a FreeStyle Libre 14</span>
                    </Grid>
                    <Grid item xs={12}>
                      <ul>
                        <li>
                          Measuring interstitial glucose reflects blood glucose and has been validated for DKA patients
                        </li>
                        <li>
                          This will be easier on your patient and staff as you monitor BG every 1-2 hours
                        </li>
                        <li>
                          This system allows easy glucose curve acquisition 1 week after discharge
                        </li>
                      </ul>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Manage electrolyte abnormalities</span>
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Potassium
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  Correct hypokalemia prior to insulin administration.  Start supplementation in animals in the normal reference range.
                                </li>
                                <li>
                                  Insulin will drive blood potassium down quickly. Check potassium again before starting insulin administration.
                                </li>
                              </ul>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">Serum K<sup>+</sup> (mEq/L)</TableCell>
                                      <TableCell align="left">Supplemental K<sup>+</sup> in fluids at maintenance rate</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="center">3.5 - 5</TableCell>
                                      <TableCell align="left">20</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">3.0 - 3.4</TableCell>
                                      <TableCell align="left">30</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">2.5 - 2.9</TableCell>
                                      <TableCell align="left">40</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">2 - 2.4</TableCell>
                                      <TableCell align="left">60</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">&le;1.9</TableCell>
                                      <TableCell align="left">80 - 100</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">Maximum</TableCell>
                                      <TableCell align="left">0.5 mEq K<sup>+</sup>/kg/hr</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Grid item xs={12}><span style={{textDecoration: 'underline'}}>K</span><sup>+</sup><sub>max</sub> <span style={{textDecoration: 'underline'}}>Calculator</span></Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <TextField
                                      id="fluidrate"
                                      type="number" value={this.state.fluidrate} onChange={this.handlefluidrateChange}
                                      label="Fluid Rate"
                                      margin="normal"
                                      variant="outlined"
                                      InputProps={{endAdornment: <InputAdornment position="end">mL/hr</InputAdornment>,}}
                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <TextField
                                        id="k"
                                        type="number" value={this.state.k} onChange={this.handlekChange}
                                        label="K+"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,}}
                                        />
                                  </Grid>
                                  <Grid item xs={12}>
                                    With {this.state.k} mEq/L supplemental K<sup>+</sup> at {this.state.fluidrate} mL/hr <span style={{backgroundColor: '#fff2ac'}}>you are administering {((this.state.k)/1000*(this.state.fluidrate)/(this.state.weightkg)).toFixed(2)} mEq K<sup>+</sup>/kg/hr</span>.  If this is greater than 0.5 mEq K<sup>+</sup>/kg/hr, you are in the danger zone.
                                  </Grid>
                                  <Grid item xs={12}>
                                  <ul>
                                  <li>
                                  Remember, many fluids already have some amount of K<sup>+</sup> (such as LRS and Phylyte)
                                  </li>
                                  <li>
                                  Consider using 2 fluid pumps with the second running at maintenance to correct electrolytes
                                  </li>
                                  </ul>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Phosphorus
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <ul>
                              <li>
                              Phosphorus follows Potassium
                              </li>
                              <li>
                              Consider supplementation after 4-6 hours of fluid therapy, often as you begin insulin
                              </li>
                              <li style={{fontWeight: '700'}}>
                              KPhos is incompatible with LRS and other calcium-containing fluids; phosphate can precipitate with calcium
                              </li>
                              <li>
                              Consider giving half the potassium supplementation as KPhos
                              </li>
                              <TextField
                                id="kp"
                                type="number" value={this.state.kp} onChange={this.handlekpChange}
                                label="K+"
                                margin="normal"
                                variant="outlined"
                                InputProps={{endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,}}
                                />
                              <li>
                              For {this.state.kp} mEq/L, add {((this.state.kp)/4).toFixed(2)} mL of 2 mEq/mL KCl and {((this.state.kp)/8.8).toFixed(2)} mL of 4.4 mEq/mL KPhos to 1L bag
                              </li>
                              <li>
                              This should be run at a maintenance rate of {((this.state.weightkg**(0.75))*130/24).toFixed(0)}
                              </li>
                              <li>
                              Alternatively, consider a CRI of 0.01 to 0.03 mmol/kg/hr = {(this.state.weightkg*(0.01)/3).toFixed(2)} to {(this.state.weightkg*(0.03)/3).toFixed(2)} mL/hr of 3 mmol/mL KPhos; higher rates may be needed if severe hypophosphatemia
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Magnesium
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <ul>
                              <li>
                              Consider in cases of refractory hypokalemia
                              </li>
                              <li>
                              If low, give 0.75 mEq/kg/day as CRI = {(this.state.weightkg*0.75/24/1.97).toFixed(2)} mL/hr of 20% Magnesium Chloride (1.97 mEq/mL)
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Turn off ketosis with insulin</span>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Glargine Protocol
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  At initial hospitalization, start 1 unit Glargine SC twice each day
                                </li>
                                <li>
                                  After 6 hours of fluid therapy and electrolyte correction, if BG &ge;250, give 1 unit Glargine IM
                                </li>
                                <li>
                                  Continue 1 unit Glargine IM q6h until BG &le;300
                                </li>
                                <li>
                                  Once BG &le;250, continue BID dosing of SC Glargine (1 unit); do not give additional doses IM
                                </li>
                                <li>
                                  If BG &le;180, supplement 2.5% Dextrose in fluids for a few hours until BG is rising
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Insulin CRI
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  1.1 to 2.2 U/kg = {(this.state.weightkg*1.1).toFixed(2)} to {(this.state.weightkg*2.2).toFixed(2)} U Insulin (Humalin R) in 250 mL bag of 0.9% NaCl
                                </li>
                                <li>
                                Check potassium again before starting insulin administration
                                </li>
                                <li>
                                Some clinicians prefer a lower dose of insulin to prevent hyperosmolality.  A 2010 paper in JVECCs supports use of the 2.2 U/kg dosing.  The aim is to decrease BG by about 50 mg/dL/hr.
                                </li>
                              </ul>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">BG (mg/dL)</TableCell>
                                      <TableCell align="left">Fluids</TableCell>
                                      <TableCell align="left">Fluid Rate (mL/hr)</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="center">&ge;250</TableCell>
                                      <TableCell align="left">0.9% NaCl</TableCell>
                                      <TableCell align="left">10</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">200-249</TableCell>
                                      <TableCell align="left">0.9% NaCl + 2.5% Dextrose</TableCell>
                                      <TableCell align="left">7</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">150-199</TableCell>
                                      <TableCell align="left">0.9% NaCl + 2.5% Dextrose</TableCell>
                                      <TableCell align="left">5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">100-149</TableCell>
                                      <TableCell align="left">0.9% NaCl + 5% Dextrose</TableCell>
                                      <TableCell align="left">5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">&le;99</TableCell>
                                      <TableCell align="left">0.9% NaCl + 5% Dextrose</TableCell>
                                      <TableCell align="left">0</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>

                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Intermittent IM insulin
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  0.25 U/kg insulin is often a good starting point; this may need to be reduced to 0.1 U/kg with the goal to decrease BG by 50-70 mg/dL/hr
                                </li>
                                <li>
                                Check potassium again before starting insulin administration
                                </li>
                              </ul>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">BG (mg/dL)</TableCell>
                                      <TableCell align="left">Regular insulin IM (Units)</TableCell>
                                      <TableCell align="left">Fluid Additives</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="center">&ge;500</TableCell>
                                      <TableCell align="left">{(this.state.weightkg*(0.25)+(1)).toFixed(1)}</TableCell>
                                      <TableCell align="left">KCl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">300-499</TableCell>
                                      <TableCell align="left">{(this.state.weightkg*(0.25)).toFixed(1)}</TableCell>
                                      <TableCell align="left">KCl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">100-299</TableCell>
                                      <TableCell align="left">{(this.state.weightkg*(0.25)-(1)).toFixed(1)>0 ? (this.state.weightkg*(0.25)-(1)).toFixed(1) : 0}</TableCell>
                                      <TableCell align="left">2.5% Dextrose + KCl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">61-99</TableCell>
                                      <TableCell align="left">0</TableCell>
                                      <TableCell align="left">2.5% Dextrose + KCl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center">&le;60</TableCell>
                                      <TableCell align="left" colSpan={2}>Bolus 0.5 mL/kg of 50% Dextrose ({(this.state.weightkg*(0.5)).toFixed(1)} mL) diluted 1:1 and alert DVM</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>

                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>



                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Investigate concurrent disorders</span>
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Urinary Tract Infection
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                  <Checkbox
                                  color="primary"
                                  /><span style={{fontWeight: '700'}}>Urinalysis</span>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                  <Checkbox
                                  color="primary"
                                  /><span style={{fontWeight: '700'}}>Urine Culture</span>
                                </Grid>
                              <ul>
                                <li>
                                  Urine culture should always be submitted.  Lack of inflammatory urine sediment does not rule out infection (urine is dilute and diabetics have impaired migration of WBCs)
                                </li>
                                <li>
                                  If indicated based on sediment, start antibiotic therapy while urine culture is pending.
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Pancreatitis
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Grid item xs={12} style={{textAlign: 'left'}}>
                                <Checkbox
                                color="primary"
                                /><span style={{fontWeight: '700'}}>Abdominal Ultrasound</span>
                              </Grid>
                              <Grid item xs={12} style={{textAlign: 'left'}}>
                                <Checkbox
                                color="primary"
                                /><span style={{fontWeight: '700'}}>fPL SNAP</span>
                              </Grid>
                              <ul>
                                <li>
                                  Pain relief
                                </li>
                                <li>
                                  Anti-emetics (such as maropitant and ondasetron)
                                </li>
                                <li>
                                  Gastric acid inhibitors (such as pantoprazole or omeprazole), especially if any continued vomiting
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Endocrine disease
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  Acromegaly, hyperthyroidism, and hyperadrenocorticism should all be considered.
                                </li>
                                <li>
                                  All may contribute to insulin resistance. Total T4 and abdominal ultrasound should be performed during initial hospital stay.
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Additional considerations
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <ul>
                                <li>
                                  Dental Disease - Significant periodontal disease may complicate control and prevent remission
                                </li>
                                <li>
                                  Obesity - High-fiber, low-carb food and a weight loss plan will improve control and increase the likelihood of remission
                                </li>
                                <li>
                                  Kidney disease - These patients may need a higher-carbohydrate renal diet and increased insulin dose
                                </li>
                                <li>
                                  Inflammatory bowel disease - These patients may need concurrent steroid therapy and an increased insulin dose
                                </li>
                              </ul>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Nutritional Support</span>
                    </Grid>
                    <Grid item xs={12}>
                      <ul>
                        <li>
                          Consider appetite stimulants or feeding tube
                        </li>
                        <li>
                          Enteral fluids may reduce the risk of fluid overload
                        </li>
                      </ul>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'left'}}>
                      <Checkbox
                      color="primary"
                      /><span style={{fontWeight: '700'}}>Transition to long-acting insulin</span>
                    </Grid>
                    <Grid item xs={12}>
                      <ul>
                        <li>
                          Generally started once eating or tolerating tube feeding
                        </li>
                        <li>
                          Based on ideal bodyweight, give 0.25 to 0.5 units/kg = {(this.state.weightkg/4).toFixed(1)} to {(this.state.weightkg/2).toFixed(1)} units glargine SC BID.
                        </li>
                        <li>
                          Some clinicians prefer ProZinc (40 U/mL) at 1 to 2 units/cat BID
                        </li>
                        <li>
                          10-15% of cats will may achieve remission; tight glycemic control may increase the chance of remission, but is unrealistic for most pet parents and unsafe for most patients
                        </li>
                      </ul>
                    </Grid>

                  </Grid>
              :
              <Grid container spacing={0}>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
                </Grid>

                <Grid item xs={12}>
                <ul>
                <li>
                Consider a 1/4 shock bolus of 22 mL/kg = {(this.state.weightkg*(22)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
                </li>
                <TextField
                  id="dehydration"
                  type="number" value={this.state.dehydration} onChange={this.handledehydrationChange}
                  label="% dehydration"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>,}}
                  />
                <li>
                Maintenance rate (exponential formula, BW<sub>kg</sub><sup>0.75</sup>x130/24) = {((this.state.weightkg**(0.75))*130/24).toFixed(0)} mL/hr; Fluid deficit of {this.state.dehydration}% = {(this.state.weightkg*(this.state.dehydration)*10).toFixed(0)} mL
                </li>
                <li>
                Subtract shock fluids given during initial stabilization
                </li>
                <TextField
                  id="shock"
                  type="number" value={this.state.shock} onChange={this.handleshockChange}
                  label="Shock fluids given"
                  margin="normal"
                  variant="outlined"
                  InputProps={{endAdornment: <InputAdornment position="end">mL</InputAdornment>,}}
                  />
                <li>
                If correcting {this.state.dehydration}% dehydration over 12-24 hours, appropriate fluid rate ranges from {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/24)).toFixed(0)} mL/hr to {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/12)).toFixed(0)} mL/hr
                </li>
                <li>
                Account for and add additional losses (such as vomiting and diarrhea) to your fluid plan
                </li>
                <li>
                If no concurrent heart disease, consider correcting {this.state.dehydration}% dehydration over 6 hours; appropriate fluid rate may approach {(((this.state.weightkg**(0.75))*130/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/6)).toFixed(0)} mL/hr
                </li>
                </ul>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Apply a FreeStyle Libre 14</span>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      Measuring interstitial glucose reflects blood glucose and has been validated for DKA patients
                    </li>
                    <li>
                      This will be easier on your patient and staff as you monitor BG every 1-2 hours
                    </li>
                    <li>
                      This system allows easy glucose curve acquisition 1 week after discharge
                    </li>
                  </ul>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Manage electrolyte abnormalities</span>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Potassium
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <ul>
                            <li>
                              Correct hypokalemia prior to insulin administration.  Start supplementation in animals in the normal reference range.
                            </li>
                            <li>
                              Insulin will drive blood potassium down quickly. Check potassium again before starting insulin administration.
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Serum K<sup>+</sup> (mEq/L)</TableCell>
                                  <TableCell align="left">Supplemental K<sup>+</sup> in fluids at maintenance rate</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">3.5 - 5</TableCell>
                                  <TableCell align="left">20</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">3.0 - 3.4</TableCell>
                                  <TableCell align="left">30</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">2.5 - 2.9</TableCell>
                                  <TableCell align="left">40</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">2 - 2.4</TableCell>
                                  <TableCell align="left">60</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">&le;1.9</TableCell>
                                  <TableCell align="left">80 - 100</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">Maximum</TableCell>
                                  <TableCell align="left">0.5 mEq K<sup>+</sup>/kg/hr</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid item xs={12}><span style={{textDecoration: 'underline'}}>K</span><sup>+</sup><sub>max</sub> <span style={{textDecoration: 'underline'}}>Calculator</span></Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <TextField
                                  id="fluidrate"
                                  type="number" value={this.state.fluidrate} onChange={this.handlefluidrateChange}
                                  label="Fluid Rate"
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{endAdornment: <InputAdornment position="end">mL/hr</InputAdornment>,}}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                  <TextField
                                    id="k"
                                    type="number" value={this.state.k} onChange={this.handlekChange}
                                    label="K+"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,}}
                                    />
                              </Grid>
                              <Grid item xs={12}>
                                With {this.state.k} mEq/L supplemental K<sup>+</sup> at {this.state.fluidrate} mL/hr <span style={{backgroundColor: '#fff2ac'}}>you are administering {((this.state.k)/1000*(this.state.fluidrate)/(this.state.weightkg)).toFixed(2)} mEq K<sup>+</sup>/kg/hr</span>.  If this is greater than 0.5 mEq K<sup>+</sup>/kg/hr, you are in the danger zone.
                              </Grid>
                              <Grid item xs={12}>
                              <ul>
                              <li>
                              Remember, many fluids already have some amount of K<sup>+</sup> (such as LRS and Phylyte)
                              </li>
                              <li>
                              Consider using 2 fluid pumps with the second running at maintenance to correct electrolytes
                              </li>
                              </ul>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Phosphorus
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <ul>
                          <li>
                          Phosphorus follows Potassium
                          </li>
                          <li>
                          Consider supplementation after 4-6 hours of fluid therapy, often as you begin insulin
                          </li>
                          <li style={{fontWeight: '700'}}>
                          KPhos is incompatible with LRS and other calcium-containing fluids; phosphate can precipitate with calcium
                          </li>
                          <li>
                          Consider giving half the potassium supplementation as KPhos
                          </li>
                          <TextField
                            id="kp"
                            type="number" value={this.state.kp} onChange={this.handlekpChange}
                            label="K+"
                            margin="normal"
                            variant="outlined"
                            InputProps={{endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,}}
                            />
                          <li>
                          For {this.state.kp} mEq/L, add {((this.state.kp)/4).toFixed(2)} mL of 2 mEq/mL KCl and {((this.state.kp)/8.8).toFixed(2)} mL of 4.4 mEq/mL KPhos to 1L bag
                          </li>
                          <li>
                          This should be run at a maintenance rate of {((this.state.weightkg**(0.75))*130/24).toFixed(0)}
                          </li>
                          <li>
                          Alternatively, consider a CRI of 0.01 to 0.03 mmol/kg/hr = {(this.state.weightkg*(0.01)/3).toFixed(2)} to {(this.state.weightkg*(0.03)/3).toFixed(2)} mL/hr of 3 mmol/mL KPhos; higher rates may be needed if severe hypophosphatemia
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                </Grid>
                <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Magnesium
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <ul>
                          <li>
                          Consider in cases of refractory hypokalemia
                          </li>
                          <li>
                          If low, give 0.75 mEq/kg/day as CRI = {(this.state.weightkg*0.75/24/1.97).toFixed(2)} mL/hr of 20% Magnesium Chloride (1.97 mEq/mL)
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Turn off ketosis with insulin</span>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Insulin CRI
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <ul>
                            <li>
                              2.2 U/kg = {(this.state.weightkg*2.2).toFixed(2)} U Insulin (Humalin R) in 250 mL bag of 0.9% NaCl
                            </li>
                            <li>
                            Check potassium again before starting insulin administration
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">BG (mg/dL)</TableCell>
                                  <TableCell align="left">Fluids</TableCell>
                                  <TableCell align="left">Fluid Rate (mL/hr)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">&ge;250</TableCell>
                                  <TableCell align="left">0.9% NaCl</TableCell>
                                  <TableCell align="left">10</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">200-249</TableCell>
                                  <TableCell align="left">0.9% NaCl + 2.5% Dextrose</TableCell>
                                  <TableCell align="left">7</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">150-199</TableCell>
                                  <TableCell align="left">0.9% NaCl + 2.5% Dextrose</TableCell>
                                  <TableCell align="left">5</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">100-149</TableCell>
                                  <TableCell align="left">0.9% NaCl + 5% Dextrose</TableCell>
                                  <TableCell align="left">5</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">&le;99</TableCell>
                                  <TableCell align="left">0.9% NaCl + 5% Dextrose</TableCell>
                                  <TableCell align="left">0</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Intermittent IM insulin
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <ul>
                            <li>
                              0.25 U/kg insulin is often a good starting point; this may need to be reduced to 0.1 U/kg with the goal to decrease BG by 50-70 mg/dL/hr
                            </li>
                            <li>
                            Check potassium again before starting insulin administration
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">BG (mg/dL)</TableCell>
                                  <TableCell align="left">Regular insulin IM (Units)</TableCell>
                                  <TableCell align="left">Fluid Additives</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">&ge;500</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.25)+(1)).toFixed(1)}</TableCell>
                                  <TableCell align="left">KCl</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">300-499</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.25)).toFixed(1)}</TableCell>
                                  <TableCell align="left">KCl</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">100-299</TableCell>
                                  <TableCell align="left">{(this.state.weightkg*(0.25)-(1)).toFixed(1)>0 ? (this.state.weightkg*(0.25)-(1)).toFixed(1) : 0}</TableCell>
                                  <TableCell align="left">2.5% Dextrose + KCl</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">61-99</TableCell>
                                  <TableCell align="left">0</TableCell>
                                  <TableCell align="left">2.5% Dextrose + KCl</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">&le;60</TableCell>
                                  <TableCell align="left" colSpan={2}>Bolus 0.5 mL/kg of 50% Dextrose ({(this.state.weightkg*(0.5)).toFixed(1)} mL) diluted 1:1 and alert DVM</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Investigate concurrent disorders</span>
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Urinary Tract Infection
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{textAlign: 'left'}}>
                              <Checkbox
                              color="primary"
                              /><span style={{fontWeight: '700'}}>Urinalysis</span>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left'}}>
                              <Checkbox
                              color="primary"
                              /><span style={{fontWeight: '700'}}>Urine Culture</span>
                            </Grid>
                          <ul>
                            <li>
                              Urine culture should always be submitted.  Lack of inflammatory urine sediment does not rule out infection (urine is dilute and diabetics have impaired migration of WBCs)
                            </li>
                            <li>
                              If indicated based on sediment, start antibiotic therapy while urine culture is pending.
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Pancreatitis
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <Checkbox
                            color="primary"
                            /><span style={{fontWeight: '700'}}>Abdominal Ultrasound</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <Checkbox
                            color="primary"
                            /><span style={{fontWeight: '700'}}>cPL SNAP</span>
                          </Grid>
                          <ul>
                            <li>
                              Pain relief
                            </li>
                            <li>
                              Anti-emetics (such as maropitant and ondasetron)
                            </li>
                            <li>
                              Gastric acid inhibitors (such as pantoprazole or omeprazole), especially if any continued vomiting
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Hyperadrenocorticism
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{textAlign: 'left'}}>
                              <Checkbox
                              color="primary"
                              /><span style={{fontWeight: '700'}}>Abdominal Ultrasound</span>
                            </Grid>
                            <ul>
                            <li>
                              In suspected cases, ACTH Stim Test is recommend once diabetes is better regulated (1-2 weeks). LDDST is often falsely negative with concurrent illness.
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Nutritional Support</span>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      Consider appetite stimulants or feeding tube
                    </li>
                    <li>
                      Enteral fluids may reduce the risk of fluid overload
                    </li>
                  </ul>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'left'}}>
                  <Checkbox
                  color="primary"
                  /><span style={{fontWeight: '700'}}>Transition to long-acting insulin</span>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      Generally started once eating
                    </li>
                    <li>
                      Based on ideal bodyweight, give 0.25 to 0.5 units/kg = {(this.state.weightkg/4).toFixed(1)} to {(this.state.weightkg/2).toFixed(1)} units Vetsulin (U-40) SC BID
                    </li>
                    <li>
                      Some clinicians prefer Humulin N (NPH)
                    </li>
                  </ul>
                </Grid>



              </Grid>

              }



              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>

              <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                Informational Handouts:
              </Grid>
              <Grid item xs={12}>
                <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952917" target="_blank" rel="noopener noreferrer">DKA in Dogs and Cats</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>

              <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                Discharge Note (Key Points):
              </Grid>
              <Grid item xs={12}>
                <ul>
                <li>
                  The goal of managing your pet's diabetes is to resolve the symptoms (increased thirst, urination, hunger, weight loss) which generally does NOT require tight blood glucose control.
                </li>
                <li>
                  Most diabetic dogs will develop cataracts.
                </li>
                <li>
                  Some diabetic cats will go into remission (about 10-15%).
                </li>
                <li>
                  Insulin should be refrigerated and open bottles discarded after 2 months.
                </li>
                <li>
                  Most insulins should be gently mixed by rolling.  Vetsulin must be shaken.
                </li>
                <li>
                  Do not give treats or snacks as these will complicate management of your pet's diabetes.
                </li>
                <li>
                  If your pet skips all or most of a meal, give only half the normal dose of insulin and call your veterinarian.
                </li>
                <li>
                  Do not make insulin adjustments without consulting with your veterinarian.
                </li>
                <li>
                  Know the signs of low blood sugar (hypoglycemia) which include vomiting, weakness, drunken walking, sleepiness, or seizures.  If concerned your pet is having a hypoglycemic event, offer food or give plain clear Karo syrup (about 1 teaspoon) onto the gums and seek immediate emergency veterinary care.
                </li>
                </ul>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{margin: '20px 0px'}}/>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12}>
                    References:
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <p><sup>1</sup> Macintire. Emergency Therapy of Diabetic Crisis: Insulin Overdose, Diabetic Ketoacidosis, and Hyperosmolar Coma. 1995. Vet Clinics of North America: Small Animal Practice.</p>

                      <p><sup>2</sup> Claus et al. Comparison of regular insulin infusion doses in critically ill diabetic cats: 29 cases (1999-2007). 2010. JVECC.</p>

                      <p><sup>3</sup> Marshall et al. Intramuscular glargine with or without concurrent subcutaneous administration for treatment of feline diabetic ketoacidosis. 2013. JVECC.</p>

                      <p><sup>4</sup> Zeugswetter et al. Glargine versus regular insulin protocol in feline diabetic ketoacidosis. 2021. JVECC.</p>


                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <p>
                  This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
                </p>
                <p>
                  All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
                </p>
              </Grid>
        </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default DkaQS;
