import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AllergicDermatitisCanine from './AllergicDermatitisCanine';
import AnalSacAbscess from './AnalSacAbscess';
import AuralHematoma from './AuralHematoma';
import BacterialPyoderma from './BacterialPyoderma';
import Demodicosis from './Demodicosis';
import JuvenileCellulitis from './JuvenileCellulitis';
import OtitisExterna from './OtitisExternaCanine';
import PemphigusFoliaceus from './PemphigusFoliaceus';
import PerianalFistula from './PerianalFistula';
import PyotraumaticDermatitis from './PyotraumaticDermatitis';
import DermatophytesCanine from './DermatophytesCanine';
import YeastPododermatitis from './YeastPododermatitis';
import Dle from './Dle';
import BrokenClaw from './BrokenClaw';

export default function DermCanine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Canine Skin Diseases</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Common Diseases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AllergicDermatitisCanine />
            <AuralHematoma />
            <BacterialPyoderma />
            <BrokenClaw />
            <OtitisExterna />
            <PyotraumaticDermatitis />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Infectious Diseases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AnalSacAbscess />
            <BacterialPyoderma />
            <Demodicosis />
            <DermatophytesCanine />
            <PyotraumaticDermatitis />
            <YeastPododermatitis />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Autoimmune Diseases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Dle />
            <JuvenileCellulitis />
            <PemphigusFoliaceus />
            <PerianalFistula />
          </AccordionDetails>
        </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
