import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Porcupine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Porcupine Quills</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
              Most quills can be removed with no further consequences, but there are several case reports describing quill migration which can be fatal. Quills can be challenging to detect by ultrasound, MRI, and CT.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> E-collar as soon as possible. Sedate or anesthetize for quill removal. Grab quills near the base with hemostat. May need to make small incision over embedded quills that are burried under the skin or mucous membranes. Quills are antimicrobial, so antibiotics are generally not indicated. Consider pain medications such as gabapentin and an NSIAD.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Case reports:</span><br/>
            <sup>1</sup><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5302205/" target="_blank" rel="noopener noreferrer">Flesher et al. Diagnosis and treatment of massive porcupine quill migration in a dog. Can Vet J 2017.</a><br/>
            <sup>2</sup>Guevara et al. Porcupine Quill Migration in the Thoracic Cavity of a German Shorthaired Pointer. JAAHA 2015.<br/>
            <sup>3</sup>Santiago Nucci and Liptak. The Diagnosis and Surgical Management of Intracardiac Quill Foreign Body in a Dog. JAAHA 2016.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
