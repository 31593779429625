import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Blurb() {

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pre-planning</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>Pheromones, essential oils (sparingly), soft lighting (for some), sound absorption (white noise or others); harp music; non-slip flooring; sanctuary spaces; room to gather</Grid>
        <Grid item xs={12}>preserve the bond throughout the appointment: keep the family together, carry out all steps in front of the parents;</Grid>
        <Grid item xs={12}>Negative factors: collecting payment just before the appointment; insufficient time/feeling rushed; minimal empathy expressed by the staff (they will remember how you made them feel); lack of rapport; seperated from their pet before or during; witiness their pet struggle or hurt in any way; sterile, cold environment; confused by the process (paranormal agonal breathing)</Grid>
        <Grid item xs={12}>Positive factors: adequate time to say goodbye - before during and after; feelings of safety and trust; pet at peace with minimal anxiety or pain; comfortable space to gather; well-informed about the process, able to ask questions; connected closely with their pet throughout; bereavement support offered; allowed to have as many friends and family as desired</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Oral sedatives</span> 1-2 hours beforehand:</Grid>
        <Grid item xs={12}>Dogs: gabapentin, trazadone, clonidine</Grid>
        <Grid item xs={12}>Cats: gabapentin, buprenorphine, trazadone</Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Client Communication</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>Warn before: eyes may not close, may paddle, agonal breaths, may poop and pee; talk them through the process</Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Sedation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>Sedatives (lighter plane of sleep than anesthetics): alpha 2s, benzodiazepines, opioids, phenothiazines;</Grid>
        <Grid item xs={12}>Anesthetics (true anesthesia at adequate doses): ketamine, tiletamine, alfaxalone, propofol</Grid>
        <Grid item xs={12}>Can give tiletamine and ketamine SC, may sting (low pH)</Grid>
        <Grid item xs={12}>Telazol (Tiletamine/Zolazepam) can be dosed at 9.9 to 13.2 mg/kg IM (up to 26.4 mg/kg) = low end about 1/2 mL of 100 mg/mL per 10 lbs</Grid>
        <Grid item xs={12}>Telazol may work a lot better if you add some Acepromazine</Grid>
        <Grid item xs={12}>Can add Atropine at 0.04 mg/kg with Telazol to control hypersalvation</Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Barbiturate Overdose Protocols</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>Barbiturates work directly on the brain to cease all life function; pentobarbital solution or version is most common; first elicits unconscious, then stops breathing, then stops the heart; considered painless</Grid>
        <Grid item xs={12}>IV and IP may be performed on conscious patients, others may not</Grid>
        <Grid item xs={12}>Can dilute thick Euthasol solution 50/50 with tap water to make it easier to push through a small needle; especially if giving IP</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Intravenous:</span> Often following equal dose Propofol; Give 1 mL per 10 lb body weight; onset of death is within 30 seconds</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Intracardiac:</span> fast and effective, no concern over BP; barbiturate travels immediately to brain; heart is just a large vessel in chest region (but often perceived as gruesome); requires skill; requires deep sedation and anesthesia</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Intrarenal:</span> fastand effective, can palpate kidney, no BP concerns; must isolate either kidney (ideal in small animals when kidney can be found)</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Intrahepatic:</span> simple technique for cats or small dogs; time to death is more variable (perfectly placed- time to death is 2 minutes; if liver is missed time to death is greatly extended</Grid>
        <Grid item xs={12}><span style={{fontWeight: "700"}}>Intraperitoneal:</span> use 3mL/10 lb; easy to perform, no sedation required when pure barbiturates are used; longer time to death, less appropriate for larger patients; good if no sedation or anesthesia is available; Beuthanasia-D and other combo drugs should not be given IP; may cause cardiac arrest if phenytoin sodium is absorbed before pentobarbital-induced unconsciousness</Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
      </>

  );
}
