import React from 'react';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function LymphomaCanine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Apocrine Gland Anal Sac Adenocarcinoma (AGASACA)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Apocrine Gland Anal Sac Adenocarcinoma (AGASACA) is a malignant tumor of the anal sac.  Dogs may have blood, pain, anal sac abscess, or trouble defecating. May have hypercalcemia on blood work or be found incidentally on digital rectal examination.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        High rate of metastasis with 50% spreading to regional lymph nodes (often sublumbar LNs) and others spreading to lungs, spleen, liver, or bone.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography><span style={{fontWeight: "700"}}>Diagnosis:</span> FNA for cytology should be considered, but is often not reliable at differentiating from benign perianal gland adenoma. Bloodwork to assess for hypercalcemia, thoracic radiographs, and abdominal ultrasound should be performed before surgery. Surgical excision for histopathology can confirm diagnosis.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
        <ul>
        <li>
        Surgical removal is usually recommended. Removal of associated enlarged LNs is also recommended. MST ranges from 16-18 months with surgery alone.
        </li>
        <li>
        Adjunct chemotherapy with carboplatin or toceranib phosphate (Palladia) is often recommended.  With surgery and carboplatin, MST is 17 and 31 months for patients with and without metastatic disease, respectively.
        </li>
        <li>
        Radiation protocols may also be considered.
        </li>
        <li>
        Adjunct NSAID administration is common.
        </li>
        <li>
        Bisphosphonate may be useful in cases with hypercalcemia of malignancy.
        </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=10370944" target="_blank" rel="noopener noreferrer">Anal Sac Tumors in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
