import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import IVDDRadiograph from './images/IVDD.jpg';
import RootSignature from './RootSignature';

export default function IntervertebralDiscDisease() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Intervertebral Disc Disease</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/ivdd" variant="outlined">IVDD Clinical Quick Sheet</Button>
            </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography gutterBottom>
              Chondrodystrophic breeds (think short stubby legs), most commonly Dachsunds, suffer from this disease, but it can be seen in any dog.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom>
              The clinical signs range from pain → loss of proprioception → loss of motor function → loss of superficial pain perception → loss of deep pain perception.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom>
              Pain may be as subtle as reluctance to go on the usual walk, reluctance to jump onto the couch, subtle lameness (limping), or holding up one forepaw.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom>
              For mild injuries where medical management is elected, rest is the most important component of the treatment plan.  This means strict rest in a small confined space with no jumping, playing, or running off leash.  Although the acute inflammation may improve within days, it generally takes 3-6 weeks of strict rest as we wait for protruded disk material to fibrose.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://www.acvs.org/small-animal/intervertebral-disc-disease" target="_blank" rel="noopener noreferrer">IVDD Handout</a> from the American College of Veterinary Surgeons
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
            <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Intervertebral Disc Disease</span></div>
            <img src={IVDDRadiograph} alt="ivdd radiograph" style={{maxWidth: "96%"}}/>
            <div>
            Notice the narrowing of the disc space at L2/L3.  The bridging underneath is termed "spondylosis deformans"&mdash;a common incidental finding that is NOT pathologic.
            </div>
            </Paper>
            </Grid>
            <Grid item xs={12}>
             <RootSignature />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
