import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

import TempTarsorrhaphyList from './components/TempTarsorrhaphyList';

class ProptosisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Proptosis (of the Globe)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: '700'}}>Presentation:</span> Eye appears to have popped out (really the eyelids are entrapped behind the globe).
            </Typography>
            <Typography>
            <span style={{fontWeight: '700'}}>Causes:</span> Often secondary to trauma; minimal trauma needed in brachycephalics
            </Typography>
            <Typography>
            <span style={{fontWeight: '700'}}>Considerations:</span> About 75% will be permanently blinded; less likely to save the globe if ruptured, hyphema present, or orbital bone fractured
            </Typography>
            <Typography>
            <span style={{fontWeight: '700'}}>Complications:</span> Tarsorrhaphy complications include corneal ulceration, loosening of sutures (which may require repeat procedure), strabismus (if medial rectus muscle torn), keratoconjunctivitis sicca (KCS, dry eye), repeat proptosis
            </Typography>
            <Typography>
            Some patients will need enucleation.
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="E collar and lube"
            inputProps={{ 'aria-label': 'E collar and lube' }}
            /><span style={{fontWeight: '700'}}>Place in hard plastic E-collar and apply copious sterile lube to eye</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Pain medication"
            inputProps={{ 'aria-label': 'Pain medication' }}
            /><span style={{fontWeight: '700'}}>Pain medication</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>An opioid that will compliment your anesthetic plan is preferred</li>
          <li>
          Consider Methadone at 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL</span> of 10 mg/mL Methadone IM for this patient.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place IV catheter</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Heavy sedation or anesthesia</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>Anesthesia is preferred as motion of the eyelids or head is not desired</li>
          <li>
          If anesthesia is not possible, sedation plan should include dissociative such as ketamine (often 2.5 mg/kg in combination with other anesthetic drugs).
          </li>
          <li>Be mindful of vagal response with traction on the optic nerve</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Pull eyelids over the globe"
            inputProps={{ 'aria-label': 'pull eyelids over the globe' }}
            /><span style={{fontWeight: '700'}}>Pull eyelids back over the globe</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            Usually done gently with a tissue forceps while applying counterpressure with the back (handle) of a scalpel handle or other clean flat surface
          </li>
          <li>
            Can use a series of stay sutures to pull the eyelids back over with tongue depressor or similar placed over the globe (and under the spanning stay sutures)
          </li>
          <li>
            Some will clip and clean the eyelids before replacing the globe. This can be challenging with eyelids entrapped behind the globe.  Never clean with anything that would damage the cornea.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Temporary partial Tarsorrhaphy"
            inputProps={{ 'aria-label': 'Temporary partial Tarsorrhaphy' }}
            /><span style={{fontWeight: '700'}}>Temporary Partial Tarsorrhaphy</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <TempTarsorrhaphyList />
          </Grid>


          <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://www.acvo.org/common-conditions-1/2018/2/2/prosthetics" target="_blank" rel="noopener noreferrer">Proptosis</a> <span style={{fontWeight: "500"}}>from ACVO</span>
            </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>Topical antibiotic (consider NeoPolyBac when ointment is possible (can hold tube under warm water to help liquify), consider ofloxacin solution if drops preferred)</li>
            <li>Topical atropine if uveitis or ulceration is apparent</li>
            <li>Oral antibiotic (consider Clavamox)</li>
            <li>Oral anti-inflammatory (often an NSAID if not contraindicated)</li>
            <li>Additional oral analgesics (gabapentin, codeine)</li>
            </ul>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default ProptosisQS;
