import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function Itp() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Immune-Mediated Thrombocytopenia (ITP)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/itp" variant="outlined">ITP Quick Sheet</Button>
            </Grid>
          </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>May be primary or secondary. Primary immune-mediated form is most common. Secondary form may be associated with vaccination, drug, cancer, or infection. Patient may have other immune-mediated disease.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Patients with ITP often present bleeding or with complication related to recent bleed. Some just feel "blah" and present with non-specific complaint.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            CBC/Chemistry (consider CBC with pathology review and reticulocyte count)
            </li>
            <ul><li>platelet count is usually &le;10K; clinical signs unlikely if platelet count &ge;50K</li>; severe when &le;35K</ul>
            <li>
              PCV/TS
            </li>
            <li>
              Blood Smear
            </li>
            <ul><li>with manual platelet count</li></ul>
            <li>
              Blood typing
            </li>
            <li>
            Urinalysis - do NOT collect by cystocentesis (bleeding risk)
            </li>
            <li>
            Heartworm test (especially dogs)
            </li>
            <li>
            Tick titer or SNAP 4Dx
            </li>
            <li>
            3-view thoracic radiographs
            </li>
            <li>
            AUS
            </li>
            <li>PT/PTT usually normal</li>
          </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
              Immunosuppressive steroid
            </li>
            <li>Adjunctive immunosupressive drug: Cyclosporine, Mycophenolate, Azathioprine</li>
            <li>Treatemnt often required for months with careful follow-up and gradual discontinuation. Some patients will be able to eventually get off medication; others will need medication for life; some will relapse.</li>
            <li>Doxycycline if any concern for tick-borne disease or inadequate testing to rule out</li>
            <li>Anti-emetics and gastroprotectants (many have GI signs if GI bleed)</li>
            <li>Melatonin recommended by some; Human intravenous immunoglobulins (IV IG); Vincristine</li>
          </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951884" target="_blank" rel="noopener noreferrer">Immune Mediated Thrombocytopenia</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
