import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DermCaninePyoderma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pyoderma</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <ul>
              <li>
              Pyoderma in the dog is assumed bacterial and generally Staphylococcal.  Confirm with impression or tape cytology (usually cocci and neutrophils on cytology).
              </li>
              <li>
              Ddx: Demodex, Dermatophytes, often (but not always) secondary to other underlying skin disorder
              </li>
              <li>
              Differentiate surface, superficial, and deep as this will dictate treatment choices and duration.  Surface and superficial pyoderma will often respond to topical therapies.  For superficial pyoderma, treatment is often needed for 3 or more weeks.  Deep pyoderma should be treated for 2 weeks beyond resolution (often 6 or more weeks).
              </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: "700"}}>Diagnostics:</span> Cytology should show neutrophils and usually shows cocci. Cytology may help rule out or in Malassezia (yeast). Skin scrapings may help rule out mites. Dermatophyte testing may help rule out ringworm.
            </Typography>
            <Typography>
              <span style={{fontWeight: "700"}}>Culture and sensitivity</span> especially important if:
            </Typography>
            <ul>
              <li>
              There is less than 50% reduction in extent of lesions within 2 weeks of appropriate systemic antimicrobial therapy
              </li>
              <li>
              There is emergence of new lesions (papules, pustules, collarettes) 2 weeks or more after the initiation of appropriate antimicrobial therapy
              </li>
              <li>
              There is presence of residual superficial bacterial folliculitis lesions after 6 weeks of appropriate systemic antimicrobial therapy together with the presence of cocci on cytology
              </li>
              <li>
              Intracellular rod-shaped bacteria are detected on cytology
              </li>
              <li>
              There is a prior history of multidrug-resistant infection in the dog or in a pet from the same household as the affected dog
              </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: "700"}}>Treatment:</span>
            </Typography>
            <ul>
              <li>
              First tier choices include: Clindamycin, First Generation Cephalosporins (cefalexin), Amoxicillin-clavulanate
              </li>
              <li>
              First or second tier choices include: Third Generation Cephalosporins (cefovecin/Convenia, cefpodoxime/Simplicef)
              </li>
              <li>
              Second tier choices include: Fluoroquinolones (enrofloxacin, marbofloxacin, pradofloxacin)
              </li>
              <li>
              Consider topical therapies alone or in combination with systemic antimicrobials to treat surface to superficial pyoderma
              </li>
                <ul>
                <li>
                Douxo Chlorhexidine PS + Climbazole Shampoo 200 ml OR VetriMax Command Dog Shampoo OR Dechra MiconaHex Triz Shampoo: Bath up to twice each week in cool to room temperature water. A small amount (palmful) can be diluted in one pint of water to extend use. Focus on problem areas and gently massage into the coat. Let sit for 5-10 minutes before rinsing as contact time kills skin yeast and bacteria.
                </li>
                <li>
                Douxo (Sogeval) Chlorhexidine Mousse Dechra OR MiconaHex + Triz Spray OR Dechra TrizCHLOR 4 Wipes: Use between bathing to reduce yeast and bacterial overgrowth. Gently massage into the coat with the direction of fur and leave in (do not rinse).
                </li>
                </ul>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/10.1111/vde.12118" target="_blank" rel="noopener noreferrer">Guidelines for the diagnosis and antimicrobial therapy of canine superficial bacterial folliculitis (Antimicrobial Guidelines Working Group of the International Society for Companion Animal Infectious Diseases). Vet Derm. 2014.</a>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951996" target="_blank" rel="noopener noreferrer">Pyoderma in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
