import React from 'react';
import { Grid, Paper, } from '@mui/material';

import HipDysplasiaOA from './images/orthopedic/hindlimb/hipDysplasia.jpg';
import distractedHips from './images/orthopedic/hindlimb/distractedHips.jpg';
import HipDysplasiaRLatcase2 from './images/orthopedic/hindlimb/HipDysplasia_RLat_case2.jpg';
import HipDysplasiVDcase2 from './images/orthopedic/hindlimb/HipDysplasia_VD_case2.jpg';

export default function ChdRadiographs() {

  return (
          <>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hip Dysplasia</span></div>
          <img src={HipDysplasiaOA} alt="hip dysplasia OA" style={{maxWidth: "96%"}}/>
          <div>
            As dogs with hip dysplasia age, they have pain associated with osteoarthritis.
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hip Laxity</span></div>
          <img src={distractedHips} alt="distracted hips" style={{maxWidth: "96%"}}/>
          <div>
            A special distractor is used for PennHIP radiographs.  The amount of hip laxity can be scored. Young dogs with hip dysplasia are painful because of stretch on muscles and nerves (associated with hip laxity).
          </div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hip Dysplasia</span></div>
          <img src={HipDysplasiVDcase2} alt="Hip Dysplasia VD" style={{maxWidth: "96%"}}/>
          <img src={HipDysplasiaRLatcase2} alt="Hip Dysplasia R Lat" style={{maxWidth: "96%"}}/>
          <div>Notice the shallow coverage of the femoral head by the acetabula and severe osteoarthritis.</div>
          </Paper>
          </Grid>
          </>


  );
}
