import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Serotonin() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Serotonin Syndrome</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            Drug-induced often following toxicity/accidental ingestion. 
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Depression, disorientation, mydriasis, hyperesthesia, tremors, seizures, GI signs, ataxia, weakness, coma, and death are possible.
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Cyproheptadine (1.1 mg/kg for dogs or 2-4 mg/kg for cats) PO or by rectum (crush and suspend) for 2 doses. Activated charcoal will prevent absorption of cyproheptadine.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
