import React from "react";
import { Divider, Grid, TextField, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import DeleteForever from '@mui/icons-material/DeleteForever';


export default function DynamicChecklist(props) {
  // const [checklistEntry, setChecklistEntry] = useState("");
  // const [checklist, setChecklist] = useState([]);

  function handleChecklistAdd(e) {
    e.preventDefault()
    props.setChecklist(props.checklist.concat([[props.checklistEntry, false]]));
    props.setChecklistEntry("");
  }

  function handleCheckboxChange(e, index) {
    let newChecklist = []
    for (let i=0; i < props.checklist.length; i++) {
      if (i===index) {
        newChecklist = newChecklist.concat([[props.checklist[i][0], !props.checklist[i][1]]])
      } else {
        newChecklist = newChecklist.concat([props.checklist[i]])
      }
    }
    props.setChecklist(newChecklist)
  }

  function handleChecklistDelete(e, index) {
    let newChecklist = []
    for (let i=0; i < props.checklist.length; i++) {
      if (i===index) {

      } else {
        newChecklist = newChecklist.concat([props.checklist[i]])
      }
    }
    props.setChecklist(newChecklist)
  }

  return (
    <>
      <Divider>Checklist</Divider>
      {props.checklist
       ?
       props.checklist.map((item, index) => (
         <Grid key={index} container spacing={{ xs: 0, md: 0 }} columns={{ xs: 6, sm: 8, md: 12 }} alignItems="center" justifyContent="flex-start">
           <Grid item xs={5} sm={7} md={11}>
             <FormGroup style={{marginTop: '0px', marginLeft: '16px'}}>
               <FormControlLabel control={<Checkbox checked={item[1]} onChange={(e) => handleCheckboxChange(e, index)} />} label={item[0]} />
             </FormGroup>
           </Grid>
           <Grid item xs={1} sm={1} md={1}>
             <IconButton
               onClick={(e) => handleChecklistDelete(e, index)}
               size="large"
               color="error"
               aria-label="delete from checklist"
               aria-controls="delete-from-checklist"
               aria-haspopup="false"
             >
               <DeleteForever />
             </IconButton>
           </Grid>
         </Grid>
       ))
       :
       <>
       </>
      }
      <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 6, sm: 8, md: 12 }} alignItems="center" justifyContent="flex-start">
        <Grid item xs={5} sm={7} md={11}>
          <TextField
            inputProps={{
              spellCheck: false,
            }}
            id="checklistEntry"
            value={props.checklistEntry}
            type="text"
            fullWidth
            onChange={(e) => props.setChecklistEntry(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? handleChecklistAdd(e)
                             // prevent the keys | and brackets, since we use them to create the checklistString
                             : e.key==="|" || e.key==="[" || e.key==="]" ? e.preventDefault()
                             : e.key )}

          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
        <IconButton
          onClick={handleChecklistAdd}
          size="large"
          color="primary"
          aria-label="add to checklist"
          aria-controls="add-to-checklist"
          aria-haspopup="false"
        >
          <AddCircle />
        </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}
