import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import DeepSS from './DeepSkinScraping';
import EarCytology from './EarCytology';
import FineNeedleAspirate from '../Oncology/FineNeedleAspirate';
import AcetateTape from './AcetateTapeCytology';
import ClawFold from './ClawFoldCytology';

import cocciNeutrophils from './skinCytologyImages/cocciNeutrophils.jpg';
import sss from './skinCytologyImages/sss.gif';
import sarcoptes from './skinCytologyImages/sarcoptesScabiei.gif';
import direct from './skinCytologyImages/direct.gif';

export default function DermExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Exam & Diagnostics</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Skin Type & Symmetry</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" style={{fontWeight: "700"}}>Haired Skin</TableCell>
                      <TableCell align="left">Folliculitis is by far the most common pattern affecting haired skin. First line diagnostics are often an appropriate starting point to rule out infection and other causes of disease. Consider lesions and symmetry as you evaluate.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" style={{fontWeight: "700"}}>Non-haired skin</TableCell>
                      <TableCell align="left">Think about autoimmune diseases or environmental (UV exposure, traumatic friction blisters) or oddopathy (split paw pad disease, others).</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" style={{fontWeight: "700"}}>Symmetrical</TableCell>
                      <TableCell align="left">Think inside to out. Endocrine disease, nutritional, autoimmune, allergy, some parasites.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" style={{fontWeight: "700"}}>Asymmetrical</TableCell>
                      <TableCell align="left">Think outside to in.</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Diagnostics</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <EarCytology />
          <AcetateTape />
          <ClawFold />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Direct Impression Cytology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  This technique is best for moist or wet skin lesions. You simply press the glass slide onto the skin you are trying to sample. A little wiggle or rotation of the slide can help capture more stuff.
                </Grid>
                <Grid item xs={12}>
                  Direct impression cytology is not appropriate for all body locations. Be cautious around lip folds, paws, or with wiggly patients; acetate tape cytology may be a better choice in these situations.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Carefully press the slide onto the site you are sampling.</span> These slides can break if you press too hard. Don't cut yourself or your patient.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>2. Gently wiggle or rotate the slide a little to collect more sample.</span> Then remove it from the skin. You should be left with a nice smear on the slide.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sample Collection</span></Typography>
                  <img src={direct} alt="direct" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cocci & Ruptured Inflammatory Cells</span></Typography>
                  <img src={cocciNeutrophils} alt="cocci neutrophils" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>Lots of cocci and busted inflammatory cells (strings of purple cotton candy are smeared nuclear material)</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>3. Stain the slide</span>.  There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>4. Evaluate the slide under the microscope</span>.  The 10x lens can be used to find an area rich with purple stuff. Put a drop of oil on the slide and move to the 100x lens to hunt bacteria (often cocci) and inflammatory cells (often neutrophils).
                </Grid>
                </Grid>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>

          <DeepSS />

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Superficial Skin Scraping</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  This technique is often used to look for Sarcoptes scabiei mites. These mites hang out on the surface and are hard to find. You may also find Otodectes (ear mites), Cheyletiella, or occasionally Notoedres.                </Grid>
                <Grid item xs={12}>
                  Sarcoptes scabiei mites are usually extremely itchy. Itchy dogs that scratch a lot will often be most affected on the lateral trunk and hocks (that joint below the knee).                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Apply some mineral oil onto the site you will sample.</span>
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>2. Use the broad end of a metal spatula to scrape large swaths of the patient.</span> The elusive scabies mite is hard to catch⁠—the more sample, the better.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>3. Spread the sample onto a slide.</span> The oil and debris can be collected onto the spatula and spread onto the slide as though you were buttering toast.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>4. Examine the slide under the microscope.</span> No need to stain; this would just wash off the oil and mites. Examine the entire sample at 100x. If you do find scabies mites, they are causing the itch. If you do not find scabies mites, you cannot rule them out⁠—these mites are hard to catch.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Superficial Skin Scrape Technique</span></Typography>
                  <img src={sss} alt="sss" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sarcoptes scabiei (scabies mite)</span></Typography>
                  <img src={sarcoptes} alt="sarcoptes" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dermatophyte (Ringworm) Testing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Dermatophytes are especially common in cats. Long-haired cats and dogs that live with long-haired cats should be sampled for dermatophytes if they present with a spot of hair loss. Lesions are often patchy and asymmetrical. You can examine the pet with a Wood's lamp (UV/black light), but only 50% of Microsporum canis will fluoresce.
                </Grid>
                <Grid item xs={12}>
                  PCR test panels may include Microsporum gypseum (may be nonpathogenic) or nonviable spores and should usually be paired with culture if positive. Negative PCR helps rule out dermatophytes as cause, since PCR is quite sensitive.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Use a clean forceps (tweezers or grabbers) to pluck some hairs from the edge of the spot with hair loss.</span> A fresh toothbrush can also be used to collect hairs for send-out fungal cultures.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>2. Add the hairs to the dermatophyte test media (DTM).</span> This is the little jar or plate with yellow Jell-O stuff in it. You may also want to add some hairs to a white top tube for send-out culture.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>3. Let the jar sit at room temperature and watch for a color change.</span> You can leave it for a couple of weeks, but most pathologic dermatophytes will turn the media red within the first 6 days.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>4. If the growth media turns red, a tape prep of the growth must be examined under the microscope.</span> Lots of other things can turn this media red, so you need to look for the dermatophytes to confirm that these caused the skin lesion.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Culture and Sensitivity</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Reasons to culture may include: failure to respond as expected to empirical antibiotic selection or treatment, nodules or draining tracts, rod-shaped bacteria are main offender on skin cytology, suspect resistance based on history, recent surgical implant.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <FineNeedleAspirate />

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Biopsy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Reasons to biopsy may include: lesions affect non-haired skin, suspect or wish to exclude neoplasia or autoimmune disease.
                </Grid>
                <Grid item xs={12}>
                  Do not scrub or prep the surface of the skin.
                </Grid>
                <Grid item xs={12}>
                  Do not sample center of an ulcer (epidermis will be missing).
                </Grid>
                <Grid item xs={12}>
                  Request a dermatopathologist and include history and sample description.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Elimination Diet Trial</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Used to exclude cutaneous adverse food reaction in cases of non-seasonal pruritic dermatitis
                </Grid>
                <Grid item xs={12}>
                  Ideally, performed for 8-12 weeks with no treats, flavored medications, or flavored chew toys.
                </Grid>
                <Grid item xs={12}>
                  At the end of the trial, challenge with the previously fed diet.  Monitor for relapse (hours to weeks).  Once relapse noted, go back to the selected diet (usually hydrolyzed protein source or prescription select protein).
                </Grid>
                <Grid item xs={12}>
                  Consult with a veterinarian nutritionist if home-cooked diet will be attempted.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>


          </AccordionDetails>
        </Accordion>


        </AccordionDetails>
      </Accordion>

  );
}
