import React from 'react';
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hematology() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Chemistry</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography style={{textDecoration: "underline"}}>Reference Ranges</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left">Dog</TableCell>
                        <TableCell align="left">Cat</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Glucose</TableCell>
                        <TableCell align="left">60-135 mg/dL</TableCell>
                        <TableCell align="left">65-131 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Cholesterol</TableCell>
                        <TableCell align="left">120-247 mg/dL</TableCell>
                        <TableCell align="left">56-161 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">BUN</TableCell>
                        <TableCell align="left">5-29 mg/dL</TableCell>
                        <TableCell align="left">19-33 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Creatinine</TableCell>
                        <TableCell align="left">0.3-2.0 mg/dL</TableCell>
                        <TableCell align="left">0.8-1.8 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Magnesium</TableCell>
                        <TableCell align="left">1.7-2.1 mg/dL</TableCell>
                        <TableCell align="left">1.7-2.3 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Calcium</TableCell>
                        <TableCell align="left">9.3-11.8 mg/dL</TableCell>
                        <TableCell align="left">8.4-11.8 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Phosphorus</TableCell>
                        <TableCell align="left">2.9-6.2 mg/dL</TableCell>
                        <TableCell align="left">3.8-7.5 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Albumin</TableCell>
                        <TableCell align="left">2.4-3.6 g/dL</TableCell>
                        <TableCell align="left">2.5-3.3 g/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Globulin</TableCell>
                        <TableCell align="left">1.7-3.8 g/dL</TableCell>
                        <TableCell align="left">2.3-3.8 g/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">ALT</TableCell>
                        <TableCell align="left">10-130 U/L</TableCell>
                        <TableCell align="left">26-84 U/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">ALP</TableCell>
                        <TableCell align="left">24-147 U/L</TableCell>
                        <TableCell align="left">20-109 U/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">AST</TableCell>
                        <TableCell align="left">12-105 U/L</TableCell>
                        <TableCell align="left">10-100 U/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">GGT</TableCell>
                        <TableCell align="left">0-25 U/L</TableCell>
                        <TableCell align="left">0-12 U/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">T. Bili.</TableCell>
                        <TableCell align="left">0-0.8 mg/dL</TableCell>
                        <TableCell align="left">0-0.6 mg/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Sodium</TableCell>
                        <TableCell align="left">139-147 mmol/L</TableCell>
                        <TableCell align="left">144-155 mmol/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Potassium</TableCell>
                        <TableCell align="left">3.3-4.6 mmol/L</TableCell>
                        <TableCell align="left">3.5-5.1 mmol/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Chloride</TableCell>
                        <TableCell align="left">107-116 mmol/L</TableCell>
                        <TableCell align="left">113-123 mmol/L</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">TCO<sub>2</sub></TableCell>
                        <TableCell align="left">21-28 mmol/L</TableCell>
                        <TableCell align="left">12-19 mmol/L</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
              <Typography variant="h6">
                <a href="https://eclinpath.com/chemistry/chemistry-quick-guide/" target="_blank" rel="noopener noreferrer">EClinPath Quick Test Interpretation</a>
              </Typography>
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
