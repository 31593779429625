import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BladderStoneRads from './BladderStoneRads';
import GdvRads from './GdvRads';
import GifbRads from './GifbRads';
import PyometraRads from './PyometraRads';


export default function AbdominalRads() {

  const [oneRefs, setOneRefs] = useState(false);
  const [twoRefs, setTwoRefs] = useState(false);
  const [threeRefs, setThreeRefs] = useState(false);
  const [fourRefs, setFourRefs] = useState(false);


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Abdominal Radiographs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Tips & Tricks</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                  <ul>
                  <li>
                  Always take the left lateral projection when taking abdominal radiographs to help investigate possible foreign body obstruction in dogs.  Gas will fill the pylorus and highlight many pyloric obstructions.
                  </li>
                  <li>
                  Take the left lateral projection first to put gas in the duodenum.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setOneRefs(!oneRefs)}>Hart and Berry.</Button> {oneRefs ? " Initial Influence of Right versus left lateral recumbency on the radiograph finding of duodenal gas on subsequent survey ventrodorsal projections of the canine abdomen. Vet Rad & Ultrasound. 2014." : "" }</sup>
                  </li>
                  <li>
                  Consider a pneumogastogram when concerned about gastric foreign material.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setTwoRefs(!twoRefs)}>Bowlus et al.</Button> {twoRefs ? " Clinical utility of pneumogastrography in dogs. JAAHA. 2005." : "" }</sup> Give 30-60 mL of non-caffeinated carbonated beverage (Sprite or similar).
                  </li>
                  <li>
                  A wooden spoon can be used to push feces-filled colon out of the way and uncover areas of GI tract that may be suspicious.
                  </li>
                  <li>
                  An exploratory laparotomy is just that.  No imaging modality is perfect. Always prepare clients for possible discrepancies or negative exploratory surgeries.
                  </li>
                    <ul>
                    <li>
                    Good agreement occurred between ultrasound reports and surgical reports in 64% of the animals, which supported the use of ultrasonography in potential surgical candidates. A major discrepancy was observed between the ultrasound report and surgical findings in 25% of the animals.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setThreeRefs(!threeRefs)}>Pastore et al.</Button> {threeRefs ? " Comparison of the results of abdominal ultrasonography and exploratory laparotomy in the dog and cat. JAAHA. 2007." : "" }</sup>
                    </li>
                    <li>
                    In cases of nontraumatic hemoabdomen sensitivity of AUS was 87.4%, 37.3%, and 31.3% for masses in the spleen, liver, and mesentery, respectively.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setFourRefs(!fourRefs)}>Cudney, Wayne, Rozanski.</Button> {fourRefs ? " Diagnostic utility of abdominal ultrasonography for evaluation of dogs with nontraumatic hemoabdomen: 94 cases (2014-2017). JAVMA. 2021." : "" }</sup>
                    </li>
                    </ul>
                  <li>
                    Pneumocolonogram can be considered if the colon cannot be easily traced and raises concern for dilated loops of small bowel.
                  </li>
                  </ul>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Disease Specific Examples</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Bladder Stones</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <BladderStoneRads />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Gastric Dilation and Volvulus (GDV)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <GdvRads />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Gastrointestinal Foreign Body</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <GifbRads />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pyometra</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <PyometraRads />
              </AccordionDetails>
            </Accordion>

            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
