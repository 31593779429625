import React, { useState } from 'react';
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import DentalBlocks from './DentalBlocks';
import RetrobulbarBlock from './RetrobulbarBlock';

export default function LocalBlocks() {

  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feline-sedation-content"
          id="feline-sedation-header"
        >
          <Typography>Local Blocks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="alfax-content"
              id="alfax-header"
            >
              <Typography>Common Doses and Considerations</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Drug</TableCell>
                  <TableCell align="left">Dose</TableCell>
                  <TableCell align="left">Onset</TableCell>
                  <TableCell align="left">Duration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Lidocaine</TableCell>
                  <TableCell align="left">2 mg/kg</TableCell>
                  <TableCell align="left">2-5 min max</TableCell>
                  <TableCell align="left">1-2 hours</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Bupivacaine</TableCell>
                  <TableCell align="left">1 (cat) to 2 (dog) mg/kg</TableCell>
                  <TableCell align="left">10-15 min</TableCell>
                  <TableCell align="left">4-6 hours</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Ropivacaine</TableCell>
                  <TableCell align="left">1.5 (cat) to 3 (dog) mg/kg</TableCell>
                  <TableCell align="left">10-20 min</TableCell>
                  <TableCell align="left">3-6 hours</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
            </Grid>
            <Grid item xs={12}>
            <ul style={{padding: '0 1rem', margin: 0}}>
            <li>
            IV Bupivacaine is highly toxic and may cause cardiovascular toxicity and cardiovascular collapse
            </li>
            <li>
            Always aspirate before injecting to avoid unintentional IV injection
            </li>
            <li>
            Ropivacaine has less cardiovascular effects than bupivacaine and has become cheap and available
            </li>
            <li>
            Toxic doses should be calculated for all local anesthetics and total amount administered should be below toxic dose
            </li>
            <li>
            Mixing local anesthetics (best of both worlds) is generally not recommend; There is plenty of evidence against and some evidence in favor of mixing lidocaine and bupivacaine
            </li>
            <li>
            Sterile water can and should be used to extend the volume of local anesthetics when appropriate;  Saline products may interfere with the action of local anesthetics
            </li>
            <li>
            Adding an opioid to a local anesthetic will extend the duration
            </li>
            <li>
            In dogs, administer about 0.5 to 1 mL per site
            </li>
            <li>
            In cats, administer about 0.2 to 0.3 mL per site
            </li>
            </ul>
            </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="alfax-content"
              id="alfax-header"
            >
              <Typography>Coccygeal epidural block</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              0.22 mg/kg Preservative Free Bupivacaine; use a long 22ga or long 25 ga needle
              </li>
              <li>
              Needle bevel forward, may or may not feel a "pop", air bubble (in syringe) should not compress (negative pressure expected)
              </li>
              <li>
              Useful in feline patients with urethral obstruction (blocked toms); for 5.6kg patient, dose is 0.25mL of 0.5% PF Bupivacaine (dilute with sterile water to 0.5mL)
              </li>
              <li>
              For blocked cats, this is effective in 70% of patients and provides better pain management<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRefs(!refs)}>Pratt et al.</Button> {refs ? "A prospective randomized, double-blinded clinical study evaluating the efficacy and safety of bupivacaine versus morphine-bupivacaine in caudal epidurals in cats with urethral obstruction. JVECC. 2018." : "" }</sup>
              </li>
              <li>
              Also useful for perineal and tail procedures
              </li>
              <li><a href="https://www.youtube.com/watch?v=KhAN4SavVzg" target="_blank" rel="noopener noreferrer">Coccygeal Block Video</a> from VETgirl</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <DentalBlocks/>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="alfax-content"
              id="alfax-header"
            >
              <Typography>Intraperitoneal (IP) Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              Dogs: Consider 8 mg/kg lidocaine or 2-4 mg/kg bupivacaine IP prior to closure
              </li>
              <li>
              Cats: Consider 6 mg/kg lidocaine or 1-2 mg/kg bupivacaine prior to closure
              </li>
              <li>
              Where incisional blocks will also be used, consider using 1/4 of the total dose for the incisional block<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRefs(!refs)}>Steagall et al.</Button> {refs ? "Intraperitoneal and incisional analgesia in small animals: simple, cost-effective techniques. JSAP. 2019." : "" }</sup>
              </li>
              <li><a href="https://youtu.be/76dwKuirqt0" target="_blank" rel="noopener noreferrer">Incisional and Intraperitoneal Anesthesia</a> from Faculté de médecine vétérinaire de l'Université de Montréal</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="dex-torb-content"
              id="dex-torb-header"
            >
              <Typography>Intratesticular Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              Use 2 mg/kg lidocaine.
              </li>
              <li>
              Inject with a 25 or 27 gauge needle placed from caudal pole towards spermatic cord, always aspirate before injecting, inject against firm backpressure while withdrawing the needle
              </li>
              <li>
              Testicle should be firm/turgid
              </li>
              <li><a href="https://youtu.be/VHfqoUPse-c" target="_blank" rel="noopener noreferrer">Intratesticular Anesthesia</a> from Faculté de médecine vétérinaire de l'Université de Montréal</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <RetrobulbarBlock />


        </AccordionDetails>
      </Accordion>

  );
}
