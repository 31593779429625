import React from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiabeticNeuropathy from './DiabeticNeuropathy';

export default function DiabetesMellitus() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Diabetes Mellitus</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Button href="/quick-sheets/DKA" variant="outlined">DKA Quick Sheet</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>Key Points:</Typography>
            <ul>
              <li>
              In most patients with Diabetes Mellitus (DM), immune system destruction of insulin-producing pancreatic beta cells causes hyperglycemia.
              </li>
              <li>
              Patients with DM are more susceptible to have pancreatitis and urinary tract infections.
              </li>
              <li>
              Treatment generally requires twice daily, subcutaneous insulin injections following a meal.
              </li>
              <li>
              A consistent routine is important.
              </li>
              <li>
              Goal is to control clinical signs (polyuria/polydipsia, polyphagia, weight loss).
              </li>
              <li>
              Watch for signs of hypoglycemia (weakness, lethargy, wobbly/drunken gait, seizures) and seek veterinary attention immediately if any concern for hypoglycemia.
              </li>
              <li>
              Warn pet parents of expected cataract formation.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://www.aaha.org/aaha-guidelines/diabetes-management/diabetes-management-home/" target="_blank" rel="noopener noreferrer">2018 AAHA Diabetes Management Guidelines for Dogs and Cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://journals.sagepub.com/doi/full/10.1177/1098612X15571880" target="_blank" rel="noopener noreferrer">ISFM Consensus - Diabetes Mellitus in Cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Be mindful of the Somogyi effect: insulin overdose leads to hypoglycemia which triggers hormonal response &rarr; rebound hyperglycemia.</Typography>
          </Grid>
          <DiabeticNeuropathy />
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951506" target="_blank" rel="noopener noreferrer">Diabetes Mellitus: Introduction</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://petparenthandouts.com/diabetes" target="_blank" rel="noopener noreferrer">Canine Diabetes</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
