import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Adipose100 from './images/adipose100x.jpg'
import Coring from './images/coring.gif'
import SlideSpray from './images/slideSpray.gif'
import Smear from './images/smear.gif'
import MCT from './images/mastCellTumor.jpg'
import PeripheralBlood from './images/peripheralBlood.jpg'

import MyCytologyFlowChart from '../ClinicalPathology/CytologyFlowChart.pdf';

export default function FineNeedleCoring() {

  return (

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Fine Needle Aspiration/Coring</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
              <Typography gutterBottom>
                This technique is often the first step when evaluating the cells that make up masses. It is easily performed in the veterinary hospital, and some cell types can be readily identified using Diff-Quik stain.  Slides can be sent out for special staining and evaluation by a pathologist.
              </Typography>
              <Typography gutterBottom>
                The goal of fine needle coring is to capture some of the cells that make up a tumor.  This can tell us more about how the tumor might behave and how we might approach surgical removal. Many types of cancer cells "exfoliate" well and will be captured by coring.  Certain cancer cells do not exfoliate well (those of mesenchymal origin as one example).
              </Typography>
              <Typography gutterBottom>
                A 22 gauge needle on a 3 mL syringe is often used.  Many will core without the syringe attached, then attach the syringe to force the sample onto a slide.  Using an 18 to 20 gauge needle on firm lesions and considering suction with a 6 mL syringe may improve sample collection from dense lesions.
              </Typography>
              <Typography gutterBottom>
                Stain one or two of the slides in the "clean" Diff-Quik. Even if planning on sending the samples out, at least one slide should be evaluated in house to make sure appropriate cells were captured.
              </Typography>
              <Typography gutterBottom>
                A lipoma is a common benign tumor of older dogs.  These are often soft, subcutaneous tumors that are freely movable or adhered to underlying tissue.  The range in size, but generally grow slowly.  Lipoma's are much more common than liposarcomas (potentially malignant fatty tumors), but the two cannot be differentiated by cytology alone.  It is not appropriate to diagnose a "lipoma" by palpation and gross appearance alone.  Tumors that change quickly, get bigger and smaller, or every appear red, ulcerated, or bleeding should be evaluated or re-evaluated by fine needle coring and cytology or surgically removed for histopatholgy.
              </Typography>
              <Button href={MyCytologyFlowChart} variant="outlined" target="_blank" rel="noreferrer">Cytology Flow Chart <PictureAsPdfIcon /></Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Fine Needle Coring</span></Typography>
                <img src={Coring} style={{borderRadius:"8%", maxWidth: "96%"}} alt="woodpecker technique for coring a mass"/>
                <div>Only a tiny sample of a much larger mass will be obtained.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slide transfer</span></Typography>
                <img src={SlideSpray} style={{borderRadius:"8%", maxWidth: "96%"}} alt="spraying the aspirate onto a slide"/>
                <div>Attach a syringe with the plunger back and then shoot the sample onto a slide.  This sample has a lot of blood, but many samples will produce a much smaller spray.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Smear thick samples</span></Typography>
                <img src={Smear} style={{borderRadius:"8%", maxWidth: "96%"}} alt="smearing the spray"/>
                <div>Thick samples will not dry well and will be too thick to evaluate under the microscope.  Smear these samples into a thin layer.</div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></Typography>
                <img src={MCT} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells under the microscope"/>
                <div>These mast cells can be easily identified.  This helps inform surgical planning.  They do not tell us what "grade" the tumor is&mdash;that requires histopathology.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Adipose (fat) cells</span></div>
                <img src={Adipose100} style={{borderRadius:"50%", maxWidth: "96%"}} alt="adipose cells under the microscope"/>
                <div>This sample contained only fat cells and is consistent with a lipoma.  Liposarcoma cannot be ruled out by cytology alone</div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Blood</span>
                <img src={PeripheralBlood} style={{borderRadius:"50%", maxWidth: "96%"}} alt="peripheral blood under the microscope"/>
                <div>Some highly vascular skin masses may not be appropriate for fine needle coring.  This sample is consistent with peripheral blood.  Histology is often needed to differentiate hemangiosarcoma from other more benign vascular skin tumors.</div>
                </Paper>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>


  );
}
