import React from 'react';
import Grid from '@mui/material/Grid';

export default function PEList() {

  return (
    <Grid item xs={12} style={{textAlign: 'left'}}>

    <ul>
    <li>
      Right side preferred as this decreases the risk of hitting the coronary artery
    </li>
    <li>
      Sterilely prepare the area between the 5th and 7th rib
    </li>
    <li>
      Local block the proposed site of entry with a small amount of lidocaine (ideally in intercostal muscle and SC space)
    </li>
    <li>
      Ultrasound guidance is best, but if unavailable, may use point of elbow at complete flexion
    </li>
    <li>
      Insert needle just cranial to rib as intercostal vessels run at caudal edge of ribs
    </li>
    <li>
      Advance needle into pericardial space (a long 14ga works well in big dogs). You may feel a scratching sensation if the needle contacts the heart.
    </li>
    <li>
      Attach to extension tubing and a 3-way stopcock to remove and quantify fluid until negative pressure
    </li>
    <li>
      If hemorrhagic, a small amount of fluid should be placed in a clotting tube and should NOT clot (defibrinated).  If it clots, the catheter may be in cardiac chamber and should be repositioned.
    </li>
    <li>
      Purple top tubes may be used for fluid analysis and cytology.  Culture specimens should be collected if an infectious cause is suspected.
    </li>
    </ul>
    </Grid>

  );
}
