import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Smoke() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Smoke Inhalation & Carbon Monoxide</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
              Usually present with known history of exposure from house fire or carbon monoxide. Carbon monoxide and hydrogen cyanide are major concerns following smoke inhalation. Carbon monoxide exposure can cause delayed neurological problems in people (including seizures) 
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> May worsen over first 24-36 hours. Respiratory, cardiac, and neurological effects are possible. Burns are uncommon. Consider corneal stain, flush and lubricate eyes.
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> SpO<sub>2</sub> will read falsely high as it cannot differentiate carbon monoxide. Thoracic radiographs are usually unremarkable initially.
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Oxygen therapy which may include oxygen tank (or comparable), intubation (for delivery of 100% O<sub>2</sub>) which may speed removal of carbon monoxide, high flow O<sub>2</sub> if available. Antibiotics are only indicated if bacterial pneumonia suspected. Consider saline nebulization. Consider terbutaline or albuterol (levalbuterol preferred in cats).
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
