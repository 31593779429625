import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Imha() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Immune Mediated Thrombocytopenia</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: '700'}}>Presentation: </span>
          Patients with ITP often present bleeding or with complication related to recent bleed. Some just feel "blah" and present with non-specific complaint.
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Ddx: </span>
          blood loss and/or platelet consumption (as in anticoagulant rodenticide cases), DIC, underlying cancer or tick-borne disease, concurrent IMHA (Evans syndrome)
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Key points for communication: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            May be primary/idiopathic (most common) or secondary
            </li>
            <li>
            Secondary form: rule out/in neoplasia, recent medication or vaccination, tick borne disease, other infectious disease
            </li>
            <li>
            Blood transfusions are a bandaid, not a definitive treatment. Immunosuppressive therapy is a cornerstone of treatment.
            </li>
            <li>
            Some patients will do well, some patients will be in and out of the hospital and require multiple blood transfusions, 
            and some patients will do poorly despite aggressive therapy. Often described as about ⅓, ⅓, ⅓.
            In the more challenging cases, this will be expensive and emotionally exhausting for some pet parents.
            </li>
          </ul>
        <Typography>
          <span style={{fontWeight: '700'}}>Diagnostics: </span><span style={{backgroundColor: '#ff726f'}}>DO NOT attempt jugular venipuncture or cystocentesis (risk of bleeding).</span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            CBC/Chemistry (consider CBC with pathology review and reticulocyte count)
            </li>
            <ul><li>platelet count is usually &le;10K; clinical signs unlikely if platelet count &ge;50K</li>; severe when &le;35K</ul>
            <li>
              PCV/TS
            </li>
            <li>
              Blood Smear
            </li>
            <ul><li>with manual platelet count</li></ul>
            <li>
              Blood typing
            </li>
            <li>
            Urinalysis - do NOT collect by cystocentesis (bleeding risk)
            </li>
            <li>
            Heartworm test (especially dogs)
            </li>
            <li>
            Tick titer or SNAP 4Dx
            </li>
            <li>
            3-view thoracic radiographs
            </li>
            <li>
            AUS
            </li>
            <li>PT/PTT are usually normal</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700'}}>Treatment: </Typography>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider a blood transfusion</span> for patients who are clinically affected by their anemia (typically with PCV&le;15-20)
          <ul style={{marginTop: 0}}>
           <li>
            10-15 mL/kg packed RBCs = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(10)).toFixed(2)} mL to {(weightkg*(15)).toFixed(0)} mL</span>
            </li>
            <li>
            20 mL/kg fresh whole blood = {(weightkg*(20)).toFixed(0)} mL
            </li>
            <li>Fresh whole blood is best if bleeding</li>
            <li>
            IV fluids will make many patients feel better and will improve perfusion even if PCV appears to decrease with fluids
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Start steroids for immunosuppression</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider 0.3 mg/kg Dexamethasone = {(weightkg*(0.3)/2).toFixed(2)} mL of 2 mg/mL Dexamethasone or {(weightkg*(0.3)/3).toFixed(2)} mL of Dexexamethasone-SP (Dex-SP) if you calculate Dex-SP at 3 mg/mL (this is debated for 4 mg/mL product which is equivalent to 3 mg/mL Dexamethasone)
            </li>
            <li>
            Transition to oral prednisone or prednisolone once eating. 2 mg/kg PO pred = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2)).toFixed(1)} mg</span>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Start adjunct immunosuppressive agent</span>
          <ul style={{marginTop: 0}}>
            <li>Most common options include cyclosporine, azathioprine, and mycophenolate.</li>
            <li>
            Consider Cyclosporine at 5 mg/kg = {(weightkg*(5)).toFixed(1)} mg PO q12h. Keep in freezer to reduce GI upset. Available in 10 mg, 25 mg, 50 mg, and 100 mg capsules (Atopica) and as 100 mg/mL solution.
            </li>
            <li>
            Consider Azathioprine at 2 mg/kg = {(weightkg*(2)).toFixed(1)} mg PO q24h. Transition to every other day with dose reduction in many cases. Wear gloves (cytotoxic).
            </li>
            <li>
            Consider Mycophenalyate at 8 mg/kg = {(weightkg*(8)).toFixed(1)} mg PO q12h.
            </li>
            <li>
              Human IV IG (intravenous immunoglobulin) can be considered in refractory cases.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider Vincristine in severe cases</span>
          <ul style={{marginTop: 0}}>
            <li>
            May lead to or worsen leukopenia
            </li>
            <li>
            Often need medical oncologist for safe chemotherapy handling
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Start Doxycycline if any concern for tick-borne disease</span>
          <ul style={{marginTop: 0}}>
            <li>
            Lack of adequate testing and/or possible tick exposure may be reason enough for empirical treatment.
            </li>
            <li>
            Consider Doxycycline at 5 mg/kg PO q12h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5)).toFixed(1)} mg</span>.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider gastrointestinal support and anti-emetics</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider maropitant (Cerenia) at 1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)/10).toFixed(1)} mL</span> of 10 mg/mL maropitant IV.
            </li>
            <li>
            Consider sending home maropitant tablets as bleeding and many of the immunosuppressive drugs can cause GI upset.
            </li>
            <li>Consider omeprazole (or pantoprazole)</li>
            <li>Consider sucralfate</li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951884" target="_blank" rel="noopener noreferrer">Immune-Mediated Thrombocytopenia handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Discharge Note (Key Points):
        </Grid>
        <Grid item xs={12}>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Recheck in 5-7 days; recheck sooner if worsening.
            </li>
            <li>
            Severely restrict activity until directed otherwise.
            </li>
            <li>
            Monitor for increased weakness or lethargy, difficulty breathing, or loss of appetite.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p style={{paddingLeft: '1ch'}}>O'Marra et al. Treatment and predictors of outcome in dogs with immune-mediated thrombocytopenia. JAVMA 2011.</p>
                <p style={{paddingLeft: '1ch'}}>Putsche et al. Primary Immune-mediated Thrombocytopenia in 30 Dogs (1997–2003). JAAHA 2008.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>


        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};