import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Triaditis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Triaditis (Feline)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
        Triaditis in cats is inflammation of the gastrointestinal tract (often termed IBD), pancreas, and hepatobiliary system (often termed inflammatory liver disease, cholangitis, or cholangiohepatitis).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> May be any combination and sequelae of gastrointestinal signs with variable severity.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> CBC/Chemistry, total T4, Abdominal imaging (ultrasound generally superior to radiographs if available), Centesis or biopsies (endoscopic or surgical) may be considered; fPLI, fPL and Texas A&M GI Panel (cobalamin and folate)
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span> 
        </Typography>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pancreatitis</TableCell>
                <TableCell>Hepatobiliary Inflammation</TableCell>
                <TableCell>IBD</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Opioids, Maropitant</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Maropitant, Ondansetron</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Consider antibiotics in cases of neutrophilic and mixed cholangitis, reactive hepatitis, and bile duct obstruction. Active bacterial infection is most common in these cases.</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Consider immunomodulatory steroids in cases of culture negative lymphocytic dominant cholangitis</TableCell>
                <TableCell>Consider steroids +/- Chlorambucil in moderate to severe cases of lymphocytic-plasmacytic enteritis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Vit K (0.5 to 1.5 mg/kg SC, IM, q12h)</TableCell>
                <TableCell>Cobalamin 0.25 to 5 mL cyanocobalamin SC q14days; Folate (0.25 mg/kg)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>SAMe (Denamarin)</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Surgery is indicated in cases of bile duct obstruction</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
            </Table>
            <Typography style={{fontSize: "0.8rem"}}>Table reference: Simpson. Pancreatitis and triaditis in cats: causes and treatment. JSAP 2015.</Typography>
          </TableContainer>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
