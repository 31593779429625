import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Mct1 from './images/mastCellTumor.jpg'
import Mct2 from './images/Mct2.jpg'
import Mct3 from './images/Mct4.jpg'

export default function MastCellTumor() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Mast Cell Tumor (Canine)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
          Mast cell tumors are common skin tumors of dogs.  They are often locally aggressive, grow and shrink, get red or "angry", ulcerate and bleed, or cause local tissue swelling.  They send out inflammatory signals that may make dogs feel unwell or lose their appetite.  Surgical removal and histopathology are necessary to "grade" mast cell tumors and understand how they might behave in the individual patient.  For low grade tumors, surgery may be curative.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/mast-cell-tumors" target="_blank" rel="noopener noreferrer">Mast Cell Tumors Handout</a> <span style={{fontWeight: "500"}}>from the ACVS</span>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952018" target="_blank" rel="noopener noreferrer">Mast Cell Tumors Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
          <img src={Mct1} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
          <div>Mast cells are round cells with granules.</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
          <img src={Mct2} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
          <div>Not all mast cell granules stain this well.</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
          <img src={Mct3} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Histologic grade is most helpful to determine prognosis. Grade 1 and 2 are more favorable.  Grade three has metastatic rate of 55-96%.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Surgical excision with 2-3 cm margins is the recommended treatment for most.  Consider radiation therapy, electrochemotherapy, chemotherapy for tumors with incomplete excision and grade 3 tumors.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Hydroxizine at 2 mg/kg every 12 hours can be considered over diphenhydramine for medical management of cutaneous MCT.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Perioperative prednisone may reduce tumor size and improve margins achieved with surgical excision.
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
