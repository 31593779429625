import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LymeDisease() {

  return (
    <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography>Lyme Disease (Lyme borreliosis)</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Most common in the Northeast, Wisconsin, and Minnesota, Lyme disease is a tick-borne disease caused by the bacterium Borrelia burgdorferi usually passed to dogs by ticks in the Ixodes genus.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a href="https://www.cdc.gov/lyme/datasurveillance/lyme-disease-maps.html" target="_blank" rel="noopener noreferrer">CDC Lyme Disease Map</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Prevention:</span> Good tick control and monitoring/prompt removal of ticks. Vaccination is controversial.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Clinical Signs:</span> May include joint pain, swelling, lameness, fever, proteinuria (glomerulonephritis).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Diagnostics:</span> SNAP 4Dx plus and AccuPlex4 are common screening tests, but diagnosis will usually require further testing such as the C6 antibody (Quant C<sub>6</sub> from IDEXX) test.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> Clinically ill dogs with a hight suspicion of Lyme and confirmatory testing pending should start treatment (usually Doxycycline at 10 mg/kg q24h for 30 days initially). Proteinuric dogs may need treatment for immune-complex glomerulonephritis (mycophenalate +/- prednisolone). Seropositive dogs that are not clinically ill or proteinuric usually do not require treatment (this is somewhat controversial).
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h6">
          <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.15085" target="_blank" rel="noopener noreferrer">2018 ACVIM consensus update on Lyme borreliosis in dogs and cats</a>
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952009" target="_blank" rel="noopener noreferrer">Lyme Disease in Dogs</a> from Veterinary Partner
          </Typography>
        </Grid>
      </Grid>
    </AccordionDetails>
   </Accordion>

  );
}
