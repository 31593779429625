import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CSection from '../Surgery/CSection';

export default function Dystocia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Dystocia & C-section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/dystocia" variant="outlined">Dystocia & C-section Quick Sheet</Button>
            </Grid>
            <Grid item xs={12}>
              <CSection />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
