import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DietDCM from './DietDCM';
import Heartworm from './Heartworm';

export default function CardioAHD() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Acquired Heart Diseases</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Myxomatous Mitral Valve Disease (MMVD, Chronic Valvular Heart Disease)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="h6">
                <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.15488" target="_blank" rel="noopener noreferrer">ACVIM Consensus - MMVD in Dogs</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>The mitral valve prevents backflow of blood from the left ventricle (the chamber that pumps blood through the body) to the left atrium (the chamber that pumps blood to the left ventricle). This valve often gets a bit leaky with age, and the leak is heard as a heart murmur.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>This is really common in older dogs, especially small breeds, but most of these dogs never develop clinical signs associated with their leaky valves.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              The leak causes a decrease in function, and the heart often makes structural changes (with some chambers getting bigger) to compensate for this loss of function.  There are medications (including Pimobendan) that will help dogs live longer if the structural changes are great enough.  Ideally, this is evaluated by echocardiography.  In the absence of an echo, thoracic radiographs for vertebral heart score (VHS) and vertebral left atrial size (VLAS) can help determine if treatment with Pimobendan would benefit the dog.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
                <a href="http://cardiaceducationgroup.org/wp-content/uploads/2016/12/CEG_Recommendations_EPIC_121316.pdf" target="_blank" rel="noopener noreferrer">The EPIC Trial: Pimobendan in Preclinical Myxomatous Mitral Valve Disease. December 2016. Cardiac Education Group.</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952593" target="_blank" rel="noopener noreferrer">Heart Murmurs in Dogs and Cats</a> from Veterinary Partner
              </Typography>
              <Typography>
              <a href="https://petparenthandouts.com/vhd" target="_blank" rel="noopener noreferrer">Valvular Heart Disease Handout</a>from PetParentHandouts.com
              </Typography>
              <Typography>
              <a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/cardiology-CVDHalfBooklet.pdf" target="_blank" rel="noopener noreferrer">Chronic Degenerative Valve Disease Handout</a> from Texas A&M Veterinary Medical Teaching Hospital
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dilated Cardiomyopathy (DCM)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>In dogs with dilated cardiomyopathy (DCM), the heart gets bigger to make up for lost pumping ability. This happens when the heart muscle gets weak or has irregular electrical activity. It often leads to heart failure, abnormal heart beats, and sudden death.  Severe DCM can cause a soft murmur and sound like MMVD.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>Certain breeds are at increased risk of developing DCM including the Doberman, Great Dane, boxer, and many giant-breed dogs.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952598" target="_blank" rel="noopener noreferrer">Dilated Cardiomyopathy in Dogs and Cats</a> from Veterinary Partner
              </Typography>
              <Typography>
              <a href="https://petparenthandouts.com/dcm" target="_blank" rel="noopener noreferrer">Dilated Cardiomyopathy Handout</a>from PetParentHandouts.com
              </Typography>
              <Typography>
              <a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/cardiology-DCMHalfBooklet.pdf" target="_blank" rel="noopener noreferrer">Dilated Cardiomyopathy Handout</a> from Texas A&M Veterinary Medical Teaching Hospital
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <DietDCM />
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Hypertrophic Cardiomyopathy (HCM)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="h6">
                <a href="https://onlinelibrary.wiley.com/doi/10.1111/jvim.15745" target="_blank" rel="noopener noreferrer">ACVIM Consensus - Cardiomyopathies in Cats</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>HCM is the most common form of heart disease in cats (60-70%). The heart muscle thickens inwards, and this affects heart function and blood flow.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>Because the heart muscle thickens inwards in cats with HCM, thoracic radiographs (x-ray images) are not a good way to evaluate the structural changes. Echocardiography is the best way to evaluate the structure and function of the heart in cats with this disease. The NT-proBNP biomarker may also be helpful.  In asymptomatic patients, the send-out test is most useful. You will get a number back and can monitor this over time; &ge;60% change is a true change in state.  In the ER setting, the negative test is most helpful to help rule out significant heart disease prior to treating for primary respiratory disease (such as feline asthma).  If positive, it may or may not be heart disease causing the clinical signs, so these cats should get an echo. NT-proBNP is cleared by the kidney; false positives on the SNAP test can occur if creatinine is ≥2.8 due to renal insufficiency or prerenal azotemia.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8661009" target="_blank" rel="noopener noreferrer">Hypertrophic Cardiomyopathy in Cats</a> from Veterinary Partner
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Heartworm />
        </AccordionDetails>
      </Accordion>

  );
}
