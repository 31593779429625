import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ElbowDysplasiaRads from '../Radiology/ElbowDysplasiaRads';

export default function Osteoarthritis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Osteoarthritis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        Osteoarthritis (OA) is a condition involving all components of the affected joints and their surrounding structures. It involves joint cartilage, underlying bone, and joint fluid. Obesity increases joint stress and inflammatory signaling molecules.
        </Typography>
        </Grid>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Radiographs will show boney changes and help monitor progression of osteoarthritis.</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ElbowDysplasiaRads />
        </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
            <Typography>Treatment must be multimodal with the aim to slow the progression of OA, relieve pain, and maintain comfortable joint function and activity.</Typography>
            <Typography style={{fontWeight: "700"}}>Potential Treatments:</Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Diet (such as a prescription joint diet with a weight loss/management plan)
            </li>
            <li>
              Pain medications including anti-inflammatory drugs
            </li>
            <li>
              Adequan injections
            </li>
            <li>
              Physical rehabilitation
            </li>
            <li>Joint supplements (EPA/omega-3 fatty acids, glucosamine, green lip mussel, others)</li>
            <li>Acupuncture, LASER, shock wave therapy</li>
            <li>Stem cell therapy</li>
          </ul>
          </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/osteoarthritis" target="_blank" rel="noopener noreferrer">Osteoarthritis</a> from Pet Parent Handouts
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
