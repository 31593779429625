import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AceOpioidCalculator from '../../Calculators/AceOpioidCalculator';

export default function AceOpioid() {
  return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ace-opioid-content"
            id="ace-opioid-header"
          >
            <Typography>Acepromazine + Opioid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AceOpioidCalculator/>
          </AccordionDetails>
        </Accordion>
  );
}
