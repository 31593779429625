import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function EruptionTimes() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Tooth Eruption & Approximate Age</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Dogs</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Approximate Age</TableCell>
                      <TableCell align="left">Teeth</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">2 - 4 weeks</TableCell>
                      <TableCell align="left">No noticeable tooth growth</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3 - 4 weeks</TableCell>
                      <TableCell align="left">Deciduous canines coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4 - 6 weeks</TableCell>
                      <TableCell align="left">Deciduous incisors and PMs coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">8 weeks</TableCell>
                      <TableCell align="left">All deciduous teeth present</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3.5 - 4 months</TableCell>
                      <TableCell align="left">No noticeable permanent tooth growth</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4 - 5 months</TableCell>
                      <TableCell align="left">Permanent incisors are coming in; some growth of PMs and Ms</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5-7 months</TableCell>
                      <TableCell align="left">Permanent canines, PMs and Ms are coming in; all permanent teeth erupted by 7 months</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Typography style={{fontSize: ".8rem"}}>Table adapted from the 1996 Animal Sheltering "How To" Series from The Humane Society of the United States</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Cats</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Approximate Age</TableCell>
                      <TableCell align="left">Teeth</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">2 - 4 weeks</TableCell>
                      <TableCell align="left">Deciduous incisors coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3 - 4 weeks</TableCell>
                      <TableCell align="left">Deciduous canines coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4 - 6 weeks</TableCell>
                      <TableCell align="left">Deciduous PMs coming in on lower jaw</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">8 weeks</TableCell>
                      <TableCell align="left">All deciduous teeth present</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3.5 - 4 months</TableCell>
                      <TableCell align="left">Permanent incisors coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4 - 5 months</TableCell>
                      <TableCell align="left">Permanent canines, PMs and Ms are coming in</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5-7 months</TableCell>
                      <TableCell align="left">All permanent teeth erupted by 6 months</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Typography style={{fontSize: ".8rem"}}>Table adapted from the 1996 Animal Sheltering "How To" Series from The Humane Society of the United States</Typography>

          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
