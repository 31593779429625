import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import KeyPoints from './KeyPoints';
import DietDCM from '../Cardiology/DietDCM';
import AdditionalResources from './AdditionalResources';
import FelineNutritionalSupport from './FelineNutritionalSupport';
import Psyllium from '../InternalMedicine/Psyllium';
import CalorieCalcAccordion from './CalorieCalcAccordion';

export default function Dentistry() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <KeyPoints />
        <CalorieCalcAccordion />
        <DietDCM />
        <FelineNutritionalSupport />
        <Psyllium />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
