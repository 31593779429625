import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Mmm() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Masticatory Muscle Myositis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
              Masticatory muscle myositis (MMM) often presents as pain on opening of the jaw with eventual fibrosis of the jaw in closed position (trismus).
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Diagnosis is ususally with Type IIM antibody titer, but some may require muscle biopsy (especially if have received steroids).
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Treat with immunosuppressive steroids (usually for several months, then taper once/if symptoms resolve). May need a liquid diet or feeding tube.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952111" target="_blank" rel="noopener noreferrer">Masticatory Myositis (Eosinophilic Myositis) in Dogs</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
