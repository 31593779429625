import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import GBWallEdema from './images/GBWallEdema.jpg';

export default function GBWall() {

  return (

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
          <Typography>
          Gallbladder wall edema can be seen in cases of anaphylaxis.  Anaphylaxis is not the only thing that will cause gallbladder wall edema.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={GBWallEdema} alt="Gallbladder wall edema" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
