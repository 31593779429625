import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ACTHStim from '../Notebook/InternalMedicine/ACTHStimTest';

export default function Imha() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Addisonian Crisis</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: '700'}}>Presentation: </span>
          Often present with GI signs, lethargy, weakness, few present in addisonian crisis with hypovolemic shock. May see bradycardia in a dehydrated patient. Atypical Addison's disease (normonatremic, normokalemic hypoadrenocorticism) is becoming less “atypical” and recent studies suggest it may represent as many as 44% of cases<sup>1</sup>. More common in Portugese Water Dogs, Poodles, and Westies. Rare in cats.
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Ddx: </span>
          Primary GI disease; whipworms (Trichuris); PLE, Acute Kidney Injury, megaesophagus, GI hemorrhage
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Diagnostics: </span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
              CBC - lack of stress leukogram (normal is not normal in a sick pet); anemia (or hemoconcentration if dehydrated); normal or increased eosinophils (weird in sick pets without parasitism)
            </li>
            <li>
              Chemistry - hyperkalemia and hyponatremia (low Na:K ratio), hypoalbuinemia, hypocholesterolemia, azotemia
            </li>
            <li>
              UA - poor urine concentration (often in a dehydrated patient)
            </li>
            <li>
              Baseline cortisol - rule out test; if &ge;2 ug/dL, then 99% certain this is not HOC <sup>2, 3</sup>
            </li>
            <li>
              <ACTHStim />
            </li>
            <li>
              Blood pressure - often low
            </li>
            <li>
              Thoracic radiographs may show microcardia
            </li>
            <li>
              AUS - usually shows small adrenal glands
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider a fluid bolus of 10 mL/kg = {(weightkg*(10)).toFixed(0)} mL over 10 to 20 minutes. Reassess and repeat if needed.
            </li>
            <li>
            Shift to rehydration plan over 6 hours. See <Link to="/calculators/canine-fluids">Canine Fluids Calculator</Link>.
            </li>
            <li>
            Do not attempt to bring Na+ up too quickly, NormR, Phylyte, or LRS are good fluid choices; Na between 130 and 160 can be replaced quickly (and can use 0.9% NaCl) ; if Na &le;130 (and chronicity suspected), must replace slowly - pick a fluid within 10 mEq of patients Na+ value or make one; Na+ should not move up more than 0.5 mEq/hr (12 mEq in 24h)
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>If hypoglycemia, give a dextrose bolus</span>
          <ul style={{marginTop: 0}}>
            <li>
            Give 0.25 to 0.5 mL/kg = {(weightkg*(0.25)).toFixed(2)} mL to {(weightkg*(0.5)).toFixed(2)} mL of 50% dextrose IV diluted 1:2 with sterile saline
            </li>
            <li>
            Some will routinely give 2.5% dextrose with fluid plan which will help shift potassium into cells
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>If severe hyperkalemia, give calcium gluconate</span>
          <ul style={{marginTop: 0}}>
            <li>
            Calcium gluconate counteracts the effect of hyperkalemia at the sino-atrial node. Give 0.5 to 1.5 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(2)} mL to {(weightkg*(1.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate I.V. over 5-10 minutes while monitoring EKG
            </li>
            <li>
            Insulin is rarely needed for hyperkalemia in HOC cases
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>If severe hyperkalemia, consider a beta agonist</span>
          <ul style={{marginTop: 0}}>
            <li>
            Terbutaline at 0.01 mg/kg IV, IM, or SC
            </li>
            <li>
            Levalbuterol by inhaler
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Perform ACTH stimulation test</span>
          <ul style={{marginTop: 0}}>
            <li>
            Ideally, before giving steroids
            </li>
            <li>
            Single dose of dexamethasone will not interfere with the test if needed
            </li>
            <li>
            <ACTHStim />
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Start glucocorticoid replacement (steroid)</span>
          <ul style={{marginTop: 0}}>
            <li>
            Dexamethasone 0.1 to 0.2 mg/kg = {(weightkg*(0.1)/2).toFixed(2)} mL to {(weightkg*(0.2)/2).toFixed(2)} mL of 2 mg/mL Dexamethasone or {(weightkg*(0.1)/3).toFixed(2)} mL to {(weightkg*(0.2)/3).toFixed(2)} mL of Dexexamethasone-SP (Dex-SP) if you calculate Dex-SP at 3 mg/mL (this is debated for 4 mg/mL product which is equivalent to 3 mg/mL Dexamethasone)
            </li>
            <li>
            Prednisone (physiological dose) 0.1 to 0.2 mg/kg = {(weightkg*(0.1)).toFixed(2)} mg to {(weightkg*(0.2)).toFixed(2)} mg
            </li>
              <ul>
                <li>
                Increase during times of stress (2-5x)
                </li>
                <li>
                Some start at 0.4 to 0.6 mg/kg/day for first week (these are sick stressed patients) = {(weightkg*(0.4)).toFixed(2)} mg to {(weightkg*(0.6)).toFixed(2)} mg
                </li>
              </ul>
            <li>
            Use prednisolone for cats (often at 0.3 mg/kg/day; hypoadrenocorticism is rare in cats)
            </li>
            <li>About half of patients on fludrocortisone can eventually be tapered off of prednisone; all patients on DOCP need prednisone daily.</li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Start mineralocorticoid replacement once euhydrated</span>
          <ul style={{marginTop: 0}}>
            <li>
            Not needed initially if normonatremic/normokalemic “atypical” form
            </li>
            <li>Options include Fludrocortisone or DOCP</li>
            <li>
            Fludrocortisone is a daily pill
            </li>
            <li>
            DOCP is a monthly injection (usual choice). Start at 1.5 mg/kg SC recheck Na+ and K+ in 21 days; Recheck Na+ and K+ at day 28: if normal, decrease dose by 10% and add 3 days to dose interval. Once there is a slightly low Na+ or slightly high K+, go back to previous dose and interval that maintained normal
            </li>
            <ul>
              <li>
              Start DOCP once euhydrated. Some clinicians start with Fludrocortisone until ACTH stim test results are back and HOC diagnosis is confirmed.
              </li>
            </ul>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider GI support</span>
          <ul style={{marginTop: 0}}>
            <li>
            Cerenia 1 mg/kg = {(weightkg*(1)).toFixed(2)} mg
            </li>
            <li>
            Consider pantoprazole or omeprazole if GI bleeding
            </li>
            <li>
            Consider sucralfate
            </li>
            <li>
            Consider fenbendazole (Panacur) (3 days 50 mg/kg) if any concern for intestinal parasites or whipworms
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951484" target="_blank" rel="noopener noreferrer">Addison's Disease</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <a href="https://petparenthandouts.com/addisons-disease" target="_blank" rel="noopener noreferrer">Addison's Disease</a> <span style={{fontWeight: "500"}}>from Pet Parent Handouts</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Reagan et al. Machine learning algorithm as a diagnostic tool for hypoadrenocorticism in dogs. Domest Anim Endocrinol 2020.</p>
                <p><sup>2</sup>Gold et al. Evaluation of Basal Serum or Plasma Cortisol Concentrations for theDiagnosis of Hypoadrenocorticism in Dogs. JVIM 2016.</p>
                <p><sup>3</sup> Lennon et al. Use of basal serum or plasma cortisol concentrations to rule out a diagnosis of hypoadrenocorticism in dogs: 123 cases (2000–2005). JAVMA 2007.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};