import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HypertrophicOsteodystrophy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hypertrophic Osteodystrophy (HOD)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
        HOD is a condition of young, growing, large-breed dogs that causes pain and limping. Lameness is common and sometimes the metaphyses are obviously warm, swollen, and painful on palpation. Fever is common. Severity ranges from mild to life-limiting.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Radiographs show a classic "double physeal line".
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
        The disease is usually self-limiting and resolves with time. Rest and consider NSAID and gabapentin. Some will consider steroids (not with the NSAID!). Check that patient is on an appropriate large-breed puppy diet with measured amount on a feeding schedule.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=9655798" target="_blank" rel="noopener noreferrer">Hypertrophic Osteodystrophy (HOD) in Dogs</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
