import React from "react";

import AceOpioidCalculator from './AceOpioidCalculator'

export default function x() {

  return (
    <>
      <h1>Acepromazine & Opioid</h1>
      <AceOpioidCalculator/>
  </>

  );
}
