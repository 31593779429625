import React, { useState } from 'react';
import { Grid, Button, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UrineCocci from './images/struviteCocciUA.jpg';
import Struvite from './images/struvite.jpg';
import calciumOxD from './images/calciumOxalateDihydrate.jpg';
import calciumOxM from './images/calciumOxalateMonohydrate.jpg';
import bilirubin from './images/bilirubin.jpg';
import cystine from './images/cystine.jpg';
import BacteriuriaSubclinical from './BacteriuriaSubclinical';
import RapidBacVet from './RapidBacVet';

export default function Urinalysis() {

  const [refs, setRefs] = useState(false);

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Urinalysis</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ul>
                  <li>
                  A stained urine preparation greatly increases (from 40% to 95%) the positive predictive value of microscopic detection of bacteriuria.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Swenson et al.</Button> {refs ? " Evaluation of modified Wright-staining of urine sediment as a method for accurate detection of bacteriuria in dogs. JAVMA 2004." : "" }</sup>
                  </li>
                  <ul>
                    <li>
                    <a href="https://eclinpath.com/urinalysis/urine-procedure-videos/" target="_blank" rel="noopener noreferrer">EClinPath - Making a line smear</a> video shows proper technique.
                    </li>
                  </ul>
                  <li><RapidBacVet /></li>
                  <li>
                  Acidic urine with rods: think E. coli (most common pathogen in dogs and cats). Alkaline urine with cocci: think Staphylococcus.
                  </li>
                  <li>
                  UTIs are uncommon in symptomatic cats under 10 years old (&le;5% incidence); Feline idiopathic cystitis (FIC) is most common.
                  </li>
                  <li>
                  Additional diagnostics will help rule out concurrent or contributing disease in patients with suspected UTIs. Blood work can help screen for hyperthyroidism, FIV/FeLV, renal failure, diabetes mellitus, hyperadrenocorticism. Radiographs can help show many uroliths and show bladder position (a caudally positioned urinary bladder can contribute to incontinence in female dogs). Focused bladder ultrasound can show stones (including cystine and urate), cystitis, masses (such as TCC).  Full abdominal ultrasound can support pyelonephritis, prostatitis, and uterine abnormalities.
                  </li>
                  <BacteriuriaSubclinical />
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={UrineCocci} alt="urine slide with cocci and struvites" style={{maxWidth: "98%"}}/>
                <Typography>Staphylococcus produce urease which increases urine pH. The solubility of struvite crystals decreases in alkaline urine.  Thus struvite crystals are commonly seen with Staphylococcus infection and high urine pH.</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Urine Crystals</Typography>
                </AccordionSummary>
                <AccordionDetails>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={Struvite} alt="struvites" style={{maxWidth: "98%"}}/>
                <Typography>Struvite (Magnesium ammonium phosphate)</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={calciumOxD} alt="calcium oxalate dihydrate" style={{maxWidth: "98%"}}/>
                <Typography>Calcium Oxalate Dihydrate</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={bilirubin} alt="bilirubin crystals" style={{maxWidth: "98%"}}/>
                <Typography>Bilirubin</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={calciumOxM} alt="Calcium Oxalate Monohydrate" style={{maxWidth: "98%"}}/>
                <Typography>Calcium Oxalate Monohydrate</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={cystine} alt="cystine" style={{maxWidth: "98%"}}/>
                <Typography>Cystine</Typography>
                </Paper>
              </Grid>
              </Grid>
              </AccordionDetails>
              </Accordion>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://eclinpath.com/urinalysis/cell-quick-quide/" target="_blank" rel="noopener noreferrer">EClinPath Cell Quick Guide</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://eclinpath.com/urinalysis/crystal-quick-guide/" target="_blank" rel="noopener noreferrer">EClinPath Crystal Quick Guide</a>
              </Typography>
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
