import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import CardioExam from './CardioExam';
import AcquiredHeartDiseases from './AcquiredHeartDiseases';
import CongenitalHeartDiseases from './CongenitalHeartDiseases';
import HeartFailure from './HeartFailure';
import PericardialEffusion from './PericardialEffusion';
import PulmonaryHypertension from './PulmonaryHypertension';

import AdditionalResources from './AdditionalResources';

export default function Cardiology() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <CardioExam />
        <AcquiredHeartDiseases />
        <CongenitalHeartDiseases />
        <HeartFailure />
        <PericardialEffusion />
        <PulmonaryHypertension />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
