import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Aki() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Acute Kidney Injury (AKI)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/aki" variant="outlined">AKI Quick Sheet</Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
