import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FourQuadrantTap() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Four Quadrant Tap</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
            Used for low-volume or suspected peritoneal effusion.
            </li>
            <li>
            Tap 4 areas just around the umbilicus (usually with a 3 mL syringe and 22 Ga needle). If there is no fluid recovered, consider removing the syringe (insert 22 Ga needle and watch hub for fluid droplet) and gently wiggle the belly.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

  );
}
