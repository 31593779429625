import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JaundiceFeline from './JaundiceFeline';

export default function Hepatopathy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hepatopathy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Denamarin is a great drug, but not if it puts off a liver workup!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Nonspecific liver injury may be secondary to toxin ingestion (e.g. sago palm, blue green algae, xylitol), drugs, infection (fungal, leptospirosis or other bacterial), metabolic disease (as in copper hepatopathy), neoplasia, or autoimmune disease.  Pancreatitis is a common cause of cholangitis and cholagniohepatitis in cats as the common bile duct and pancreatic duct share an opening.  Liver shunts should be considered in certain patients.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Diagnostic workup of elevated liver values usually includes ultrasound. FNA of liver is only high yield if suspect histoplasmosis or gives lymphoma diagnosis.  Surgical biopsy is common (for histopathology, aerobic and anaerobic culture, heavy metal/Cu<sup>++</sup>).  Many will do PT/PTT and platelet count (&le; 50K/uL considered high risk) before liver FNA or biopsy, but BMBT may be more useful as most complications happen to dogs with normal coagulation times.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Treatment considerations early in the investigation of underlying cause should include: Denamarin (S-Adenosylmethionine (SAMe) and Silybin) or N-acetylcystine (NAC); vitamin K (especially if cholestasis); vitamin E; appetite stimulants and GI support (maropitant, omeprazole) as needed; pain medications as indicated (especially if concurrent pancreatitis); when possible, antibiotics should be reserved until liver samples obtained; when possible, steroids or other immunosuppressive drugs should be reserved until liver samples obtained. Ursodiol reduces viscosity of bile and improves flow. Ursodiol has historically been avoided in cases of obstructive cholestasis, but it is useful as an antioxidant with anti-inflammatory properties.  Copper chelators and copper restricted diets are useful in cases of copper-associated chronic hepatopathy.</Typography>
          </Grid>
          <Grid item xs={12}>
          <JaundiceFeline />
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://eclinpath.com/chemistry/liver/" target="_blank" rel="noopener noreferrer">eClinPath - Liver - Laboratory detection</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Consider cholesterol, glucose, albumin, BUN, clotting times (coagulation factors are made by liver, cholestasis may decrease vit K absorption).</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.15467" target="_blank" rel="noopener noreferrer">ACVIM Consensus - Chronic Hepatitis in Dogs</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Be mindful that progressive liver failure may show decrease in ALP and ALT over time, so these laboratory findings are not always indicative of improvement.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=9758237" target="_blank" rel="noopener noreferrer">Hepatitis in Dogs</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951913" target="_blank" rel="noopener noreferrer">Cholangitis (Cholangiohepatitis) in cats</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951913" target="_blank" rel="noopener noreferrer">Hepatic Lipidosis in Cats</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
