import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Taco() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>TACO & TRALI</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: "700"}}>Transfusion-associated Circulatory Overload (TACO)</span> happens when plasma or whole blood are given to certain patients, especially sick patients with heart disease or hypothermia and colloid oncotic pressure leads to circulatory overload. This is less likely with pRBCs which have low colloid oncotic pressure and low sodium.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <span style={{fontWeight: "700"}}>Transfusion-related Acute Lung Injury (TRALI)</span> is characterized by hypoxemia and permeability-related noncardiogenic pulmonary edema. Fever is often present. Acute lung injury within 6 hours of a transfusion without other underlying cause of acute lung injury. Treatment is supportive.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Both TACO and TRALI must be differentiated from anaphylaxis and sepsis.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{fontSize: "0.8rem"}}><span style={{fontWeight: "700"}}>Reference:</span><br/>
            <sup>1</sup>Odunayo et al. Association of Veterinary Hematology and Transfusion Medicine (AVHTM) transfusion reaction small animal consensus statement (TRACS). Part 3: Diagnosis and treatment. JVECC 2021.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
