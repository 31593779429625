import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import CatAbscess from './CatAbscess';
import PemphigusFoliaceus from './PemphigusFoliaceus';
import OtitisExternaFeline from './OtitisExternaFeline';
import plasmaCellPododermatitis from './skinLesionImages/plasmaCellPododermatitis.jpg'

export default function DermFeline() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Skin Diseases</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>By Location/Pattern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Miliary Dermatitis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                <li>
                Consider dermatophytes; rule in/out with culture
                </li>
                <li>
                Consider parasites; rule in/out with skin scraping or treatment trial (Advantage Multi, Revolution Plus)
                </li>
                  <ul>
                    <li>Negative scrape does not rule out Demodex gatoi</li>
                    <li>Consider lime sulfur treatment trial</li>
                  </ul>
                <li>
                Consider bacterial; rule in/out with cytology
                </li>
                  <ul>
                    <li>Eosinophils may suggest pareasites or allergies</li>
                    <li>Acantholytic keratinocytes may suggest pemphigus foliaceus (confirm with skin biopsy)</li>
                  </ul>
                <li>
                If the cat is still pruritic after treating secondary infections and parasites, consider a strict diet trial for 8-12 weeks to r/o food allergy.  Blood testing for food allergens is not recommended and experts in human and veterinary medicine have written position statements against this practice.
                </li>
                <li>
                If the cat is still pruritic and concurrent systemic illness has been ruled out (especially in older patients), consider allergen testing or medical management for atopy.  Remember, atopy is a diagnosis of exclusion. Itch is additive (treat the treatable first).  Referral to a dermatologist may be helpful for allergen testing.
                </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Self-induced alopecia</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ul>
              <li>
              Consider dermatophytes; rule in/out with culture
              </li>
              <li>
              Consider parasites; rule in/out with skin scraping or treatment trial (Advantage Multi, Revolution Plus)
              </li>
                <ul>
                  <li>Negative scrape does not rule out Demodex gatoi</li>
                  <li>Consider lime sulfur treatment trial</li>
                </ul>
              <li>
              Consider bacterial; rule in/out with cytology
              </li>
                <ul>
                  <li>Eosinophils may suggest pareasites or allergies</li>
                  <li>Acantholytic keratinocytes may suggest pemphigus foliaceus (confirm with skin biopsy)</li>
                </ul>
              <li>
              Consider endocrine disease, paraneoplastic disease, bladder pain (as with feline idiopathic cystitis), or abdominal pain.  Abdominal ultrasound, blood work, and urinalysis should be performed.
              </li>
              <li>
              If the cat is still pruritic after treating secondary infections and parasites, consider a strict diet trial for 8-12 weeks to r/o food allergy.  Blood testing for food allergens is not recommended and experts in human and veterinary medicine have written position statements against this practice.
              </li>
              <li>
              If the cat is still pruritic and concurrent systemic illness has been ruled out (especially in older patients), consider allergen testing or medical management for atopy.  Remember, atopy is a diagnosis of exclusion. Itch is additive (treat the treatable first).  Referral to a dermatologist may be helpful for allergen testing.
              </li>
              <li>
              Behavioral overgrooming is thought less likely than previously touted.  Don't start these cats on anti-depressants without working them up for underlying disease!
              </li>
              </ul>

              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Cervicofacial (Head and Neck) Dermatitis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ul>
              <li>
              Consider dermatophytes; rule in/out with culture
              </li>
              <li>
              Consider parasites; rule in/out with skin scraping or treatment trial (Advantage Multi, Revolution Plus)
              </li>
                <ul>
                  <li>Negative scrape does not rule out Demodex gatoi</li>
                  <li>Consider lime sulfur treatment trial</li>
                </ul>
              <li>
              Consider bacterial; rule in/out with cytology
              </li>
                <ul>
                  <li>Eosinophils may suggest pareasites or allergies</li>
                  <li>Acantholytic keratinocytes may suggest pemphigus foliaceus (confirm with skin biopsy)</li>
                </ul>
              <li>
              Consider Herpesvirus or Calicivirus
              </li>
              <li>
              Consider Crypotcoccus or Sporotrichosis
              </li>
              <li>
              If the cat is still pruritic after treating secondary infections and parasites, consider a strict diet trial for 8-12 weeks to r/o food allergy.  Blood testing for food allergens is not recommended and experts in human and veterinary medicine have written position statements against this practice.
              </li>
              <li>
              If the cat is still pruritic and concurrent systemic illness has been ruled out (especially in older patients), consider allergen testing or medical management for atopy.  Remember, atopy is a diagnosis of exclusion. Itch is additive (treat the treatable first).  Referral to a dermatologist may be helpful for allergen testing.
              </li>
              </ul>

              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Eosinophilic</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ul>
              <li>
              Consider dermatophytes; rule in/out with culture
              </li>
              <li>
              Consider parasites; rule in/out with skin scraping or treatment trial (Advantage Multi, Revolution Plus)
              </li>
                <ul>
                  <li>Negative scrape does not rule out Demodex gatoi</li>
                  <li>Consider lime sulfur treatment trial</li>
                </ul>
              <li>
              Consider bacterial; rule in/out with cytology
              </li>
                <ul>
                  <li>Eosinophils may suggest pareasites or allergies</li>
                  <li>Acantholytic keratinocytes may suggest pemphigus foliaceus (confirm with skin biopsy)</li>
                </ul>
              <li>
              Consider an antibiotic treatment trial for pyoderma (often see eosinophilic lesions)
              </li>
              <ul>
              <li>
              Eosinophilic granulomas may be sterile or infectious
              </li>
              </ul>
              <li>
              If the cat is still pruritic after treating secondary infections and parasites, consider a strict diet trial for 8-12 weeks to r/o food allergy.  Blood testing for food allergens is not recommended and experts in human and veterinary medicine have written position statements against this practice.
              </li>
              <li>
              If the cat is still pruritic and concurrent systemic illness has been ruled out (especially in older patients), consider allergen testing or medical management for atopy.  Remember, atopy is a diagnosis of exclusion. Itch is additive (treat the treatable first).  Referral to a dermatologist may be helpful for allergen testing.
              </li>
              </ul>
              </AccordionDetails>
            </Accordion>
            <OtitisExternaFeline/>

      </AccordionDetails>
    </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>By Category</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Allergy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
              <li>
              Always rule out dermatophytes, parasites, and infectious agents first. Atopic dermatitis is a diagnosis of exclusion. Itch is additive (treat the treatable first).
              </li>
              <li>
              Fleas are the most common cause of allergic dermatitis in cats.  Cats with skin disease should be on Advantage Multi or Revolution Plus.  All animals in the house should be on good adulticidal flea prevention.  Keep cats indoors to prevent fleas outside from causing atopy flares.
              </li>
              <li>
              Consider a diet trial (ideally with a prescription hydrolyzed protein diet) to help rule out food allergy.  Blood testing for food allergens is not recommended and experts in human and veterinary medicine have written position statements against this practice.
              </li>
              <li>
              Consider referral to a dermatologist for intradermal skin testing, especially for clients interested in allergen specific immunoterapy.
              </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Autoimmune</Typography>
            </AccordionSummary>
              <AccordionDetails>
              <PemphigusFoliaceus />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Plasma Cell Pododermatitis (Pillow Foot)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <ul>
                <li>
                Unknown cause, but some suspect immune-mediated
                </li>
                <li>
                Presents as swelling of one or several footpads often with white striations, crusts, or ulcers.  Will usually cause pain or lameness.
                </li>
                <li>
                Consider foreign body or trauma if only one paw affected. Biopsy is required for diagnosis and to rule out neoplasia or eosinophilic granuloma.
                </li>
                <li>
                Treatment:
                </li>
                  <ul>
                  <li>
                  Often responds to immunosuppressive glucocorticoids (such as prednisolone). Taper to lowest effective dose.
                  </li>
                  <li>
                  Some may respond to doxycycline (10 mg/kg PO q24h).  Doxycycline may cause esophageal strictures in cats; avoid pills/use liquid; chase with water or food.
                  </li>
                  </ul>
                </ul>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Plasma Cell Pododermatitis</span></Typography>
                <img src={plasmaCellPododermatitis} alt="plasma cell pododermatitis" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
                </AccordionDetails>
              </Accordion>

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Infectious</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CatAbscess />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Dermatophytosis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Dermatophytes are exceptionally common in cats and often can cause pruritus, alopecia, and secondary bacterial skin disease.  No one skin lesion is pathognomonic for dermatophytosis.
                  </li>
                  <li>
                  Woods lamp evaluation may show fluorescence, but this is true for less than 50% of Micosporum canis strains.  Do not rely on Wood's lamp evaluation to rule out dermatophytosis.
                  </li>
                  <li>
                  Treatments:
                  </li>
                  <ul>
                  <li>
                  Itrafungol (itraconazole oral solution): Give 5 mg/kg once daily on alternative weeks for 3 treatment cycles.
                  </li>
                  <li>
                  Alternative (but stinky): Lime sulfur bathing twice weekly; treat for 1 month beyond complete resolution
                  </li>
                    <ul>
                    <li>
                    Towel bathing technique: get two plastic bins, use one for a sopping wet lime sulfur-soaked towel. Thorough wipe down of complete pet (including head/face). Transfer to other bin and use a dry towel to pat off excess.
                    </li>
                    </ul>
                  </ul>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>
                    <a href="https://www.maddiesfund.org/how-animal-shelters-are-beating-ringworm.htm" target="_blank" rel="noopener noreferrer">Maddies Fund - Beating Ringworm</a>
                  </Typography>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <OtitisExternaFeline/>
            </AccordionDetails>
          </Accordion>

          </AccordionDetails>
        </Accordion>


        </AccordionDetails>
      </Accordion>

  );
}
