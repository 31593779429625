import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function CardioCHD() {

  const [refs, setRefs] = useState(false);
  const [ref2, setRef2] = useState(false);

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Congenital Heart Diseases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{paddingBottom: "20px"}}>
            <Grid item xs={12}>
            <Paper>
              <Table padding="none" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{fontWeight: "700"}}>Defect</TableCell>
                    <TableCell align="center" style={{fontWeight: "700"}}>Murmur Location</TableCell>
                    <TableCell align="center" style={{fontWeight: "700"}}>Murmur Timing</TableCell>
                    <TableCell align="center" style={{fontWeight: "700"}}>Pulses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">PDA</TableCell>
                    <TableCell align="center">left basilar</TableCell>
                    <TableCell align="center">continuous</TableCell>
                    <TableCell align="center">bounding (low diastolic pressure)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">PS</TableCell>
                    <TableCell align="center">left basilar</TableCell>
                    <TableCell align="center">systolic</TableCell>
                    <TableCell align="center">normal</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">SAS</TableCell>
                    <TableCell align="center">left basilar</TableCell>
                    <TableCell align="center">systolic</TableCell>
                    <TableCell align="center">normal to &darr;</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
              </Paper>
              </Grid>
            </Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Patent Ductus Arteriosus (PDA)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>The ductus arteriosus is a normal blood vessel in puppies before they are born. It should close at or shortly after birth. When it doesn't close, blood is shunted down this extra path and makes the heart work harder.</Typography>
              <Typography>On physical exam, you may notice a left basilar continuous murmur and bounding pulses (due to low diastolic pressure with normal or high systolic pressure).  The patient may have pink mucous membranes in cranial parts of the body, but cyanotic mucous membranes in the caudal parts of the body (vulva/prepuce, nailbeds).</Typography>
              <Typography><span  style={{fontWeight: "700"}}>Diagnosis:</span> Polycythemia may be present on PCV or CBC due to chronic hypoxia. This will worsen oxygen delivery.  Echocardiography is most helpful for early diagnosis after auscultation of a fitting murmur.  Thoracic radiographs may show changes including cardiomegaly.</Typography>
              <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Intravascular coil occlusion or surgical occlusion via thoracotomy are two options.  Without treatment, most will die of pulmonary edema due to CHF within 1 year.  In rare cases of reverse PDAs, surgical correction is not indicated.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/PDA.pdf" target="_blank" rel="noopener noreferrer">Patent Ductus Arteriosus Handout</a> from Texas A&M University
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8604573" target="_blank" rel="noopener noreferrer">Patent Ductus Arteriosus in Dogs and Cats</a> from Veterinary Partner
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Pulmonic Stenosis (PS)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>As blood is pumped out of the right side of the heart towards the lungs, it encounters resistance due to thickened, fused valve leaflets or abnormal narrowing. This can often be heard as a murmur in the left axillary (armpit) region near the heart base.</Typography>
              <Typography>On physical exam, you may notice a left basilar systolic murmur.</Typography>
              <Typography><span  style={{fontWeight: "700"}}>Diagnosis:</span> EKG may support right ventricular enlargement which can help differentiate PS from subaortic stenosis.  Since PS often causes concentric hypertrophy of the right ventricle, the heart may appear normal on radiographs.  Echocardiography is best and will help determine severity of PS. Prognosis depends on severity.</Typography>
              <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Balloon valvuloplasty or surgical repair is indicated in patients with severe PS.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/PS.pdf" target="_blank" rel="noopener noreferrer">Pulmonic Stenosis Handout</a> from Texas A&M University
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952783" target="_blank" rel="noopener noreferrer">Pulmonic Stenosis in Dogs and Cats</a> from Veterinary Partner
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Subaortic Stenosis (SAS)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>As blood is pumped out of the left side of the heart towards the body, it encounters resistance due to abnormal narrowing. This can often be heard as a murmur in the left axillary (armpit) region near the heart base.</Typography>
              <Typography>On physical exam, you may notice a left basilar systolic murmur and decreased pulses</Typography>
              <Typography><span  style={{fontWeight: "700"}}>Diagnosis:</span> EKG and radiographs are often normal.  Echocardiography is best and will help determine severity.</Typography>
              <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Balloon valvuloplasty, surgical repair, medical management (usually with atenolol) and exercise restriction are all treatment options.  Outcomes with medical management are similar to those with balloon valvuloplasty with median survival times of 56 to 55 months, respectively.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Meurs et al.</Button> {refs ? " Survival times in dogs with severe subvalvular aortic stenosis treated with balloon valvuloplasty or atenolol. JAVMA 2005." : "" }</sup></Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952497" target="_blank" rel="noopener noreferrer">Subaortic Stenosis in Dogs and Cats</a> from Veterinary Partner
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Other Congenital Diseases</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Tetralogy of Fallot</span>: Combination of VSD, overriding aorta, RVH, PS. This may appear as a dilated MPA with right ventricular enlargement on radiographs.  Expect polycythemia if significant right-to-left shunting.  Most patients die before age 5.  Keeshonds are an at-risk breed.</Typography>
              <Typography>
              <a href="https://www.mayoclinic.org/diseases-conditions/tetralogy-of-fallot/symptoms-causes/syc-20353477" target="_blank" rel="noopener noreferrer">Tetralogy of Fallot</a> from Mayo Clinic (Human)
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Tricuspid Valve Dysplasia</span>: You may hear a right midheart systolic murmur in moderate to severe cases.  Femoral pulses may be reduced. PVCs may be present.  Treatment considerations include valve replacement or reconstruction, but balloon valvuloplasty may be more common with goal to treat PS or tricuspid valve stenosis if present and reduce severity of disease.</Typography>
              <Typography>
              <a href="https://www.cvcavets.com/tricuspid-valve-dysplasia/" target="_blank" rel="noopener noreferrer">Tricuspid Valve Dysplasia in Dogs</a> from CVCA Cardiac Care for Pets
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Ventricular Septal Defects</span>: May hear low grade systolic murmur at right sternal border in cases of large VSDs. Expect high grade murmur in cases of small VSDs.  LAE and LVE may be present with large VSDs. Enlarged MPA, venous distension, +/- pulmonary edema may be present.  Small VSDs are not expected to impact length or quality of life but do increase the risk of endocarditis. Large defects lead to CHF by 5-7 years, but can be repaired surgically (which often leads to normal quality of life).  Cats can get VSDs.  Perimembranous (vs muscular) is most common.  Small perimembranous defects &rarr; over circulation in the lungs &rarr; left-sided heart enlargement.</Typography>
              <Typography>
              <a href="https://www.cdc.gov/ncbddd/heartdefects/ventricularseptaldefect.html" target="_blank" rel="noopener noreferrer">Facts about Ventricular Septal Defect</a> from CDC (Human)
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Dexter or sinister Cor Triatriatum</span></Typography>
              <Typography>
              <a href="https://www.youtube.com/watch?v=zCj2k1bb2Qg&ab_channel=JohnsonFrancis%2CMBBS%2CMD%2CDM" target="_blank" rel="noopener noreferrer">Cor Triatriatum</a> from Dr. Johnson Francis on YouTube (Human)
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Atrial Septal Defects</span>: Can get relative pulmonic stenosis and a left basilar murmur.  Seen more in standard poodles and boxers.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography><span style={{fontWeight: "700"}}>Mitral Valve Dysplasia</span>: Very rare. More common in English Bull Terriers.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>Chompoosan et al.</Button> {ref2 ? " Epidemiology of heart disease in English Bull Terriers and echocardiographic characteristics of mitral valve abnormalities. JSAP 2022.": ""}</sup></Typography>
              </Grid>

            </Grid>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
