import React from "react";
import { Button, CircularProgress } from '@mui/material';

export default function LoaderButton({
  isLoading,
  disabled = false,
  ...props
}) {
  return (
    <Button
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <CircularProgress size={24} />}
      {!isLoading && props.children}
    </Button>
  );
}
