import React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <Stack spacing={2} sx={{maxWidth: '900px', margin: 'auto'}}>
      <Typography variant="h1" sx={{fontFamily: "Outfit", textAlign: 'center', fontWeight: '900', mt: 4, mb: 6, fontSize: '4.8rem'}}>Privacy Policy</Typography>
      <Typography>
      Last updated: January 15, 2023
      </Typography>
      <Typography>
      This Privacy Policy describes Our policies and procedures on the collection, 
      use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </Typography>
      <Typography>
      We use Your Personal data to provide and improve the Service. By using the Service, 
      You agree to the collection and use of information in accordance with this Privacy Policy.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Interpretation and Definitions</Typography>
      <Typography variant='h2' sx={{fontSize: '2.5rem'}}>Interpretation</Typography>
      <Typography>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. 
        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </Typography>
      <Typography variant='h2' sx={{fontSize: '2.5rem'}}>Definitions</Typography>
      <Typography>
        For the purposes of these Privacy Policy:
      </Typography>
      <ul>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Account</span> means a unique account created for You to access our Service or parts of our Service.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Business</span>, for the purpose of the CCPA (California Consumer Privacy Act), refers 
          to the Company as the legal entity that collects Consumers’ personal information and determines the purposes and means of the processing 
          of Consumers’ personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines 
          the purposes and means of the processing of consumers’ personal information, that does business in the State of California.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) 
          refers to Dogs Cats Med Notebook, LLC. For the purpose of the GDPR, the Company is the Data Controller.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Consumer</span>, for the purpose of the CCPA (California Consumer Privacy Act), 
          means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in 
          the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the 
          USA for a temporary or transitory purpose.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Cookies</span> are small files that are placed on Your computer, 
          mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Country</span> refers to: Texas, United States.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Data Controller</span>, for the purposes of the GDPR (General Data Protection Regulation), 
          refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Device</span> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Do Not Track</span> (DNT) is a concept that has been promoted 
          by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry 
          to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Personal Data</span> is any information that relates to an identified or identifiable individual. 
          For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online 
          identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. 
          For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being 
          associated with, or could reasonably be linked, directly or indirectly, with You.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Sale</span>, for the purpose of the CCPA (California Consumer Privacy Act), 
          means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, 
          in writing, or by electronic or other means, a Consumer’s personal information to another business or a third party for monetary 
          or other valuable consideration.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Service</span> refers to the Website.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Service Provider</span> means any natural or legal person who processes 
          the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, 
          to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how 
          the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Third-party Social Media Service</span> means any services or content (including data, 
          information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Usage Data</span> refers to data collected automatically, either generated 
          by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>Website</span> refers to dogscatsmedicine.com, accessible 
          from <Link to="/" style={{textDecoration: 'none'}}>https://dogscatsmedicne.com</Link>
          </Typography>
        </li>
        <li>
          <Typography><span style={{fontWeight: '900'}}>You</span> means the individual accessing or using the Service, or the company, or other legal entity 
          on behalf of which such individual is accessing or using the Service, as applicable. Under GDPR (General Data Protection Regulation), You 
          can be referred to as the Data Subject or as the User as you are the individual using the Service.
          </Typography>
        </li>
      </ul>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Collecting and Using Your Personal Data</Typography>
      <Typography variant='h2' sx={{fontSize: '2.5rem'}}>Types of Data Collected</Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Personal Data</Typography>
      <Typography>
      While using Our Service, We may ask You to provide Us with certain personally 
      identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </Typography>
      <ul>
        <li>
          Email address
        </li>
        <li>
          First and last name
        </li>
        <li>
          Usage data
        </li>
      </ul>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Usage Data</Typography>
      <Typography>
      Usage Data is collected automatically when using the Service.
      </Typography>
      <Typography>
      Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, 
      browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those 
      pages, unique device identifiers and other diagnostic data.
      </Typography>
      <Typography>
      When You access the Service by or through a mobile device, We may collect certain information automatically, including, but 
      not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your 
      mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      </Typography>
      <Typography>
      We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
      </Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Tracking Technologies and Cookies</Typography>
      <Typography>
      We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. 
      Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. 
      The technologies We use may include:
      </Typography>
      <ul>
        <li>
          <span style={{fontWeight: '900'}}>Cookies or Browser Cookies.</span> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
        </li>
        <li>
          <span style={{fontWeight: '900'}}>Web Beacons.</span> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
        </li>
      </ul>
      <Typography>
      Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
      </Typography>
      <Typography>
      We use Session Cookies for the purpose set out below:
      </Typography>
      <ul>
        <li>
        <span style={{fontWeight: '900'}}>Necessary / Essential Cookies</span><br/>
        Type: Session Cookies<br/>
        Administered by: Us<br/>
        Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
        </li>
      </ul>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Use of Your Personal Data</Typography>
      <Typography>
      The Company may use Personal Data for the following purposes:
      </Typography>
      <ul>
        <li>
        To provide and maintain our Service, including to monitor the usage of our Service.
        </li>
        <li>
        To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
        </li>
        <li>
        For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
        </li>
        <li>
        To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
        </li>
        <li>
        To manage Your requests: To attend and manage Your requests to Us.
        </li>
        <li>
        For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
        </li>
        <li>
        For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
        </li>
      </ul>
      <Typography>
      We may share Your personal information in the following situations:
      </Typography>
      <ul>
        <li>
        With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You.
        </li>
        <li>
        For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
        </li>
        <li>
        With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
        </li>
        <li>
        With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
        </li>
        <li>
        With Your consent: We may disclose Your personal information for any other purpose with Your consent.
        </li>
      </ul>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Retention of Your Personal Data</Typography>
      <Typography>
      The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      </Typography>
      <Typography>
      The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Transfer of Your Personal Data</Typography>
      <Typography>
      Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
      </Typography>
      <Typography>
      Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
      </Typography>
      <Typography>
      The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Disclosure of Your Personal Data</Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Business Transactions</Typography>
      <Typography>
      If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
      </Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Law enforcement</Typography>
      <Typography>
      Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
      </Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Other legal requirements</Typography>
      <Typography>
      The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
      </Typography>
      <ul>
        <li>
        Comply with a legal obligation
        </li>
        <li>
        Protect and defend the rights or property of the Company
        </li>
        <li>
        Prevent or investigate possible wrongdoing in connection with the Service
        </li>
        <li>
        Protect the personal safety of Users of the Service or the public
        </li>
        <li>
        Protect against legal liability
        </li>
      </ul>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Security of Your Personal Data</Typography>
      <Typography>
      The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Detailed Information on the Processing of Your Personal Data</Typography>
      <Typography>
      The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.
      </Typography>
      <Typography variant='h2' sx={{fontSize: '2.5rem'}}>Payments</Typography>
      <Typography>
      We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).
      </Typography>
      <Typography>
      We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
      </Typography>
      <Typography variant='h3' sx={{fontSize: '1.5rem'}}>Stripe</Typography>
      <Typography>
      Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/us/privacy</a>
      </Typography>
      
     
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Links to Other Websites</Typography>
      <Typography>
      Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
      </Typography>
      <Typography>
      We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Changes to this Privacy Policy</Typography>
      <Typography>
      We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
      </Typography>
      <Typography>
      We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
      </Typography>
      <Typography>
      You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </Typography>
      <Typography variant='h1' sx={{fontSize: '2.5rem', fontWeight: '900'}}>Contact Us</Typography>
      <Typography>
      If you have any questions about this Privacy Policy, You can contact us by email: info@dogscatsmedicine.com
      </Typography>
      <Button component={Link} to="/" size="large" sx={{fontFamily: "Outfit", textAlign: "center", marginBottom: '30px', fontSize: '1.1rem', textTransform: "none"}}>Home</Button>
    </Stack>
  );
}