import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import AdditionalResources from './AdditionalResources';
import Vaccines from './Vaccines';
import LifeStages from './LifeStages';
import Heartworm from '../Cardiology/Heartworm';
import Fecal from './Fecal';

export default function Wellness() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Vaccines />
        <LifeStages />
        <Heartworm />
        <Fecal />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
