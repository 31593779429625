import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Foxtails() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Foxtails</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            Highly regional, these little grass bits can get lodged in dog ear canals, eyes, noses, or under the skin (commonly interdigital). They can be difficult to find and remove. May present as nasal foreign body or interdigital draining tract.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Sedation or general anesthesia may be needed for attempted removal. Warn pet parents that these can be challenging to find and remove. Topical anesthetic (proparacaine) may help if lodged in the nose.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
