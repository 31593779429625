import React, { useState } from 'react';
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import InferiorAlveolarBlock from './images/InferiorAlveolarBlock.gif'
import CaudalMaxillaryBlock from './images/caudalMaxillaryBlock.gif'
import InfraorbitalBlock from './images/infraorbitalBlock.gif'
import MajorPalatineBlock from './images/majorPalatineBlock.gif'
import MiddleMetalBlock from './images/mandibularBlock.gif'
import MandibularMidlineBlock from './images/mandibularMidlineBlock.gif'
import MaxillaryMidlineBlock from './images/maxillaryMidlineBlock.gif'

export default function DentalBlocks() {

  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dental-blocks-content"
          id="dental-blocks"
        >
          <Typography>Dental Blocks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="alfax-content"
              id="alfax-header"
            >
              <Typography>Bupivacaine + Buprenorphine Dosing Table</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
              Bupivacaine has an 8-10 hour duration when administered alone.  The duration of effect can be extended to 24-96 hours when combined with buprenorphine<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRefs(!refs)}>Snyder et al.</Button> {refs ? "Effects of Buprenorphine Added to Bupivacaine Infraorbital Nerve Blocks on Isoflurane Minimum Alveolar Concentration Using a Model for Acute Dental/Oral Surgical Pain in Dogs. JVD. 2016." : "" }</sup>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Lean BW (kg)</TableCell>
                      <TableCell align="left">Volume ml/site</TableCell>
                      <TableCell align="left">Buprenorphine (ml of 0.3mg/ml)</TableCell>
                      <TableCell align="left">0.5% Bupivacaine (ml)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">&#60;10</TableCell>
                      <TableCell align="left">0.1 - 0.15</TableCell>
                      <TableCell align="left">0.05</TableCell>
                      <TableCell align="left">0.15 - 0.65</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">10 - 15</TableCell>
                      <TableCell align="left">0.2 - 0.25</TableCell>
                      <TableCell align="left">0.10</TableCell>
                      <TableCell align="left">0.3 - 0.9</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">15 - 20</TableCell>
                      <TableCell align="left">0.2 - 0.25</TableCell>
                      <TableCell align="left">0.15</TableCell>
                      <TableCell align="left">0.45 - 1.3</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&#62;20kg</TableCell>
                      <TableCell align="left">0.3 - 0.4</TableCell>
                      <TableCell align="left">0.2</TableCell>
                      <TableCell align="left">0.6 - 1.8</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography>
              *Always aspirate before administration to ensure you are not administering IV
              </Typography>
            </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Inferior Alveolar Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={InferiorAlveolarBlock} alt="inferior alveolar block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks all teeth of the mandible.  Can be approached intraorally or extraorally.  With the intraoral approach, aim for the caudal end of the angular process of the mandible and insert the needle approximately half this distance from the lingual side of the last molar.  The needle is often not inserted far enough.  This block should be placed against the periosteum over the mandibular foramen and not in the surrounding soft tissues.
              </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Self-induced tongue and cheek trauma are a risk of this block.  The intraoral approach can reduce the risk.  If using the extraoral approach from the ventral mandible, take care to place the needle against the mandible to avoid the lingual nerve in the soft tissues medial to the desired block site.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Infraorbital Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={InfraorbitalBlock} alt="infraorbital block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks the buccal side of the maxillary molars, premolars, canines, and incisors.  Combine with the palatine block for complete ipsilateral desensitization.  Insert into the mucosa above the second premolar.  Ideally, use a 27 ga long dental needle for mesocephalic dogs and 30 ga short dental needle for brachycephalics and cats.  Insert to level of distal root of fourth premolar to reach the maxillary foramen.  Hold digital pressure over the infraorbital foramen for 15 seconds, and this block should provide analgesia to the fourth premolar and molars of mesocephalic dogs.  This is less necessary with brachycephalics and cats.  This is safer than the maxillary block, assuming you do not insert your needle too far and puncture the globe.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Maxillary Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={CaudalMaxillaryBlock} alt="caudal maxillary block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks the entire ipsilateral side.  Insert the needle caudal to the last molar and direct it rostrodorsally toward the maxillary foramen.  Do not use in cats.  The infraorbital block may be a safer alternative.  The maxillary midline block should be added to help block the maxillary incisors.  The maxillary block can be performed extraorally with the needle inserted just rostromedially the frontal process of the zygomatic arch.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Middle Mental Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={MiddleMetalBlock} alt="middle metal block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks the mandibular canine and incisor teeth.  Should be combined with the mandibular midline block to block crossover superficial nerves when extracting incisors.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Major Palatine Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={MajorPalatineBlock} alt="major palatine block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Located at the level of the maxillary fourth premolar halfway between midline of the hard palate and the maxillary arcade.  Approach from the contralateral side, insert needle from ipsilateral side palatal to the third premolar. Provides analgesia to the palatal side of the molars and premolars.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Maxillary Midline Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={MaxillaryMidlineBlock} alt="maxillary midline block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks crossover superficial nerves to the rostral maxilla.  Use with the infraorbital block when extracting incisors.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Mandibular Midline Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={MandibularMidlineBlock} alt="mandibular midline block" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography>
              Blocks crossover superficial nerves to the rostral mandible.  Use with the mental block when extracting incisors.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Local or Field Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
              <Typography>
              Commonly performed in veterinary dentistry.  Just block around the individual tooth you are going to extract.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>



        </AccordionDetails>
      </Accordion>

  );
}
