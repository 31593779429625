import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PancCanine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pancreatitis (Canine)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pancreatitis" variant="outlined">Pancreatitis Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Inflammation of the pancreas. May be acute or chronic.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Nonspecific signs include partial or complete anorexia, vomiting, lethargy, diarrhea. Dehydration or fever may be appreciated on examination. Abdominal pain may or may not be recognized on examination.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
            <li>
              Abdominal Radiographs - only helpful to rule out other disease, not sensitive or specific for pancreatitis
              </li>
              <li>
              Abdominal Ultrasound - in acute cases, the pancreas may be hypoechoic, heterogenous, enlarged, have a hyperechoic surrounding mesentery (steatitis), and focal effusion; normal appearance does not rule out pancreatitis
              </li>
              <li>
              CBC - may see inflammatory leukogram; thrombocytopenia in severe cases with DIC
              </li>
              <li>
              Chemistry - may have elevated ALT, AST, T Bili, azotemia if dehydrated, hypoglycemia has been associated with a poor outcome, electrolyte disturbances
              </li>
              <li>
              Spec cPL - Useful in many clinical scenarios and more sensitive and specific compared to other serum markers for diagnosing histopathologic lesions of pancreatitis in dogs<sup>1</sup>
              </li>
              <li>
              SNAP cPL - if “normal”, consider other causes for clinical signs; “normal” result means unlikely to have pancreatitis, “abnormal” might have pancreatitis or a Spec cPL in equivocal range. Valuable tool to exclude or include pancreatitis in emergency setting.
              </li>
              <li>
              Cytology - only if fluid accumulation
              </li>
              <li>
              Histopathology (rarely performed) is the gold-standard test
              </li>
              <li>
              Clotting times (PT/PTT) if concern for DIC
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
            <ul>
            <li>
              Treat inciting cause or concurrent disease based on individual patient
              </li>
              <li>
              Fluid Rehydration
              </li>
              <li>
              Antiemetics and gastrointestinal prokinetics
              </li>
              <li>
              Pain Management
              </li>
              <li>
              Appetite Stimulants
              </li>
              <li>
              Nutritional Support
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952412" target="_blank" rel="noopener noreferrer">Pancreatitis in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
