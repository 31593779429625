import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CalorieCalc from '../../Calculators/CalorieCalculator';

export default function CalorieCalcAccordion() {
  return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="dex-torb-content"
            id="dex-torb-header"
          >
            <Typography>Calorie Calculators</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CalorieCalc/>
          </AccordionDetails>
        </Accordion>
  );
}
