import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import Yeast4 from './skinCytologyImages/4yeast.jpg';
import Cocci4Yeast2 from './skinCytologyImages/4cocci2yeast.jpg';
import Cocci4Rods2 from './skinCytologyImages/4cocci2rods.jpg';
import Rods4Cocci1Yeast1 from './skinCytologyImages/4rods1cocci1yeast.jpg';
import Rods4 from './skinCytologyImages/4rods.jpg';
import otodectes from './skinCytologyImages/otodectes.jpg';
import earRoll from './skinCytologyImages/earRoll.gif';

export default function EarCytology() {

  return (

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Ear Cytology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>1. Note color, odor, and relative amount of waxy debris in the ears.</span>  Brown waxy debris is most frequently seen with cocci bacteria, Malassezia (yeast), and ear mites, whereas Yellow to white color discharge may be associated with Pseudomonas or other rod-shaped bacteria.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>2.Note if the ear canal is narrowed, swollen, or red</span>. This indicates inflammation of the external canal, and may complicate visualization of the canal, sample collection, and treatment.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>3.Gently collect a sample of ear debris from each ear.</span>  This is best done with a cotton tipped applicator inserted gently into the external canal.  Ears are painful, and inflamed ear canals are easily traumatized.  Do not put your safety or your patients safety at risk.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>4.Roll the cotton tips onto a glass slide</span>.  The samples are easiest to evaluate if you make two straight lines on the glass slide.  When you hold the frosted portion of the slide towards your body, the sample from the right ear should be rolled away from you on the right side of the glass slide.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slide Preparation</span></Typography>
                  <img src={earRoll} alt="ear roll" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>Rolling two straight lines makes it easier to evaluate quickly.</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Yeast at 1000x</span></Typography>
                <img src={Yeast4} alt="yeast4" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>4+ yeast</Typography>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>5.Stain the slide</span>. There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontWeight: "700"}}>6.Evaluate the slide under the microscope</span>. Mites can be found with the 10x objective lens.  Yeast, cocci, and rods should be hunted at 1000x with a drop of immersion oil on the slide.  The number of yeast and cocci are assigned from 1+ to 4+.
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" style={{fontWeight: "700"}}>0</TableCell>
                          <TableCell align="left">No bacteria/yeast/inflammatory cells present.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" style={{fontWeight: "700"}}>1+</TableCell>
                          <TableCell align="left">Occasional cells present. Must scan slide carefully.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" style={{fontWeight: "700"}}>2+</TableCell>
                          <TableCell align="left">Cells present in low numbers. Detectable rapidly without difficulties.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" style={{fontWeight: "700"}}>3+</TableCell>
                          <TableCell align="left">Present in larger numbers.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" style={{fontWeight: "700"}}>4+</TableCell>
                          <TableCell align="left">Massive amounts of cells present.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Typography style={{fontSize: "0.7em"}}>Budach et al. Reproducibility of a semiquantitative method to assess cutaneous cytology. Vet Derm. 2012.</Typography>
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cocci and Yeast 1000x</span></Typography>
                <img src={Cocci4Yeast2} alt="cocci4yeast2" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>4+ cocci, 2+ yeast</Typography>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mixed Bacteria at 1000x</span></Typography>
                <img src={Cocci4Rods2} alt="cocci4rods2" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>4+ cocci, 2+ rods</Typography>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Rods at 1000x</span></Typography>
                <img src={Rods4} alt="rods4" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>3+ rods</Typography>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Rods, Cocci, & Yeast at 1000x</span></Typography>
                <img src={Rods4Cocci1Yeast1} alt="rods4cocci1yeast1" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>4+ rods, 1+ cocci, 1+ yeast</Typography>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Ear Mite at 100x</span></Typography>
                <img src={otodectes} alt="otodectes" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                <Typography>Otodectes</Typography>
                </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

  );
}
