import React from 'react'
import { Grid, IconButton, Button } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import KeyPoints from './Communication';
import DiagnosticTechniques from './DiagnosticTechniques';
import CommonCancers from './CommonCancers';
import AdditionalResources from './AdditionalResources';

export default function Oncology() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto">
        <Button href="https://vetnurseguide.com/tumor-map" target="_blank" rel="noopener noreferrer" variant="outlined">Canine Tumor Map</Button>
      </Grid>
      <Grid item xs={12}>
        <KeyPoints />
        <DiagnosticTechniques />
        <CommonCancers />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
