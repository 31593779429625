import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GrossHistiocytoma from './images/grossHistiocytoma.jpg'
import Histiocyte40 from './images/histiocytoma40x.JPG'
import Histiocyte100 from './images/histiocytoma100x.jpg'

export default function Histiocytoma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Histiocytoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
          A histiocytoma is a common benign skin tumor of young dogs.  These masses usually go away on their own within 3 months.  They usually appear hairless, red, and raised.  Some relatively bad cancers can appear similar&mdash;including mast cell tumors.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952066" target="_blank" rel="noopener noreferrer">Histiocytoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Histiocytoma</span></div>
          <img src={GrossHistiocytoma} style={{borderRadius:"50%", maxWidth: "96%"}} alt="an erythematous dermal exophytic mass with a button like appearance"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Histiocytes at 40x</span></div>
          <img src={Histiocyte40} style={{borderRadius:"50%", maxWidth: "96%"}} alt="histiocytes at 40x"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Histiocytes at 100x</span></div>
          <img src={Histiocyte100} style={{borderRadius:"50%", maxWidth: "96%"}} alt="histiocytes at 100x"/>
          </Paper>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
