import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ElbowDysplasiaRads from '../Radiology/ElbowDysplasiaRads';

export default function ElbowDysplasia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Elbow Dysplasia</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Elbow dysplasia is a blanket term that covers several disorders affecting the elbow.  Pain may be elicited on extension of the elbow or when pressure is placed over the coronoid processes of the ulna.  Fragmented medial coronoid process is the most common problem, but several other conditions can cause pain and eventual osteoarthritis.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/canine-elbow-dysplasia" target="_blank" rel="noopener noreferrer">Elbow Dysplasia Handout</a> from the American College of Veterinary Surgeons
        </Typography>
        </Grid>
        <ElbowDysplasiaRads />
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
