import React, { Component } from 'react';
import { Grid, Divider, TextField, Paper, Table, TableBody, TableCell,TableHead, TableRow } from '@mui/material';

class BlankQS extends Component {

  render() {
    return (
          <>
            <h1 className="calcH1">Neurologic Examination</h1>

            <Grid container spacing={0} style={{textAlign: "left"}}>

                <TextField
                id="date"
                type="date"
                margin="normal"
                />

              <Grid item xs={12}>
               <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="weightlbs"
                  label="Weight"
                  margin="normal"
                  variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                <TextField
                  label="Neurologic History (Onset, Duration, Progression)"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                  label="Current Medications & Previous Treatments"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>

                <Grid item xs={12}>
                <TextField
                  label="Mental Status (Alert, Obtunded, Comatose)"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  label="Posture (head Tilt, Tremors, Falling, Circling)"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  label="Gait (Paresis/Paralysis, Ataxia)"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  label="Motor (yes/no, left/right/bilateral)"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  label="Able to Urinate"
                  fullWidth
                  multiline
                  margin="normal"
                />
                </Grid>
              </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>


                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table size="small" padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Left</TableCell>
                              <TableCell align="center">Proprioception</TableCell>
                              <TableCell align="center">Right</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Knuckling (front)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Knuckling (rear)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Hopping (front)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Hopping (rear)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Extensor Postural Thrust
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Wheelbarrowing (visual)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Wheelbarrowing (non-visual)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                  </Grid>

                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table size="small" padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Left</TableCell>
                              <TableCell align="center">Cranial Nerves</TableCell>
                              <TableCell align="center">Right</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Vision & Menace (II)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Pupil Size (II, III)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              PLR (II, III)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Palpebral (V, VII)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Strabismus (VIII)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Physiologic Nystagmus (VIII)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Spontaneous Nystagmus (VIII)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Mastication (V)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Face Sensation (V)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Pinnae Sensation (VII)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Facial Muscles (VII)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Swallowing (IX, X)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{margin: '20px 0px'}}/>
                </Grid>


                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table size="small" padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Left</TableCell>
                              <TableCell align="center">Spinal Reflexes</TableCell>
                              <TableCell align="center">Right</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Biceps (C6-C8)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Triceps (C7-T1)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Extensor Carpi (C7-T1)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Forelimb Withdraw (C6-T2)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Patellar (L4-L6)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Cranial Tibial (L6-L7)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Gastrocnemius (L6-S1)
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Hindlimb Withdraw (L5-S1)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Perineal (S1-S2)

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "4rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Panniculus

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "4rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Cross Extensor

                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Tail Tone

                              </TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "2rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Anal Tone

                              </TableCell>

                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                  </Grid>

                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table size="small" padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center">Superficial Pain</TableCell>
                              <TableCell align="center"></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell align="center">Left</TableCell>
                              <TableCell align="center" sx={{fontWeight: "300"}}>Do NOT test if motor present</TableCell>
                              <TableCell align="center">Right</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Forelimbs
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Hindlimbs
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center">Deep Pain</TableCell>
                              <TableCell align="center"></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell align="center">Left</TableCell>
                              <TableCell align="center" sx={{fontWeight: "300"}}>Do NOT test if superficial pain present</TableCell>
                              <TableCell align="center">Right</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Forelimbs
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              Hindlimbs
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                hiddenLabel
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                      <TextField
                        label="Hyperesthesia"
                        fullWidth
                        multiline
                        margin="normal"
                      />
                      <TextField
                        label="Muscle Atrophy"
                        fullWidth
                        multiline
                        margin="normal"
                      />
                      <Paper>
                        <Table size="small" padding="none">

                          <TableHead>
                            <TableRow>
                              <TableCell align="center" colSpan={2}>Muscle Tone</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                label="LF"
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                label="RF"
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="center">
                                <TextField
                                  label="LR"
                                  variant="filled"
                                  size="small"
                                  sx={{width: "5rem"}}
                                />
                                </TableCell>
                                <TableCell align="center">
                                <TextField
                                  label="RR"
                                  variant="filled"
                                  size="small"
                                  sx={{width: "5rem"}}
                                />
                                </TableCell>
                              </TableRow>
                          </TableBody>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" colSpan={2}>Root Signature</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <TextField
                                label="LF"
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              <TableCell align="center">
                              <TextField
                                label="RF"
                                variant="filled"
                                size="small"
                                sx={{width: "5rem"}}
                              />
                              </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="center">
                                <TextField
                                  label="LR"
                                  variant="filled"
                                  size="small"
                                  sx={{width: "5rem"}}
                                />
                                </TableCell>
                                <TableCell align="center">
                                <TextField
                                  label="RR"
                                  variant="filled"
                                  size="small"
                                  sx={{width: "5rem"}}
                                />
                                </TableCell>
                              </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{margin: '20px 0px'}}/>
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                      label="Neuroanatomical Localization"
                      fullWidth
                      multiline
                      margin="normal"
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                      label="Differential Diagnosis"
                      fullWidth
                      multiline
                      margin="normal"
                    />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField
                      label="Diagnostic Plan"
                      fullWidth
                      multiline
                      margin="normal"
                    />
                    </Grid>
                </Grid>

          </>

    )
  }


};

export default BlankQS;
