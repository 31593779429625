import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton";
import { Typography, Skeleton, Container, Stack, TextField } from '@mui/material';

export default function NotebookNotes() {
  const [notebookNote, setNotebookNote] = useState("");
  const [origNote, setOrigNote] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  const [note, setNote] = useState(null);
  const sectionId = useLocation().pathname.split("/").slice(-1)[0];


  function validateForm() {
    return notebookNote !== origNote;
  }

  // mixed with old stuff starts here
  useEffect(() => {
    function loadNote() {
      return API.get("notes", `/clinical-notebook/${sectionId}`);
    }

    async function onLoad() {
      try {
        const note = await loadNote();
        const { notebookNote } = note;

        if (notebookNote) {
          setNotebookNote(notebookNote);
          setOrigNote(notebookNote);
        }
        setNote(note);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false)
    }

    onLoad();
  }, [sectionId]);

  function saveNote(note) {
    return API.put("notes", `/clinical-notebook/${sectionId}`, {
      body: note
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await saveNote({
        notebookNote,
      });
      setOrigNote(notebookNote);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <Container maxWidth={false} sx={{paddingTop: '16px', paddingRight: '0px', paddingLeft: '0px', marginLeft: '16px'}}>
    { isLoading
      ?
      <>
        <Typography variant="h3"><Skeleton animation="wave" /></Typography>
        <Typography variant="h3"><Skeleton animation="wave" /></Typography>
      </>
      :
      note && (
        <Stack spacing={2} justifyContent="center" component="form" onSubmit={handleSubmit}>
          <TextField
            inputProps={{
              spellCheck: false,
            }}
            multiline
            id="notearea"
            value={notebookNote}
            label="Notes"
            type="textarea"
            onChange={(e) => setNotebookNote(e.target.value)}
          />
          <LoaderButton type="submit" variant="contained" isLoading={isLoading} disabled={!validateForm()}>
            Save
          </LoaderButton>
        </Stack>
    )}
    </Container>
  );
}
