import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Antimicrobials from '../InternalMedicine/Antimicrobials';
import Fluids from '../InternalMedicine/FluidTherapy';
import Oxygen from './Oxygen';
import BloodProducts from './BloodProducts';
import FelineNutritionalSupport from '../Nutrition/FelineNutritionalSupport';

export default function TherapeuticInterventions() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Therapeutic Interventions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Antimicrobials />
          <BloodProducts />
          <FelineNutritionalSupport />
          <Fluids />
          <Oxygen />
        </AccordionDetails>
      </Accordion>


  );
}
