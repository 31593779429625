import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import oncology from './Notebook/images/onco.jpg';
import behavior from './Notebook/images/behavior.jpg';

const FillPicDiv = styled('div')`
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  display: block;
  margin: auto;
  align-items: center;
`;

const FillPicImg = styled('img')`
  width: 100%;
  max-width: 600px;
`;

function Home() {
  const [expanded, setExpanded] = React.useState(false);
  const { isAuthenticated, isSubscribed, isErrored } = useAppContext()

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  function renderUserIssue() {
    return (
      <Stack sx={{maxWidth: '1200px', textAlign: 'center'}}>
        <Typography variant="h3" sx={{fontFamily: "Outfit"}}>Network Error</Typography>
        <Typography sx={{lineHeight: '2rem'}}>
          We've run into an error that looks like it could be network related.<br/>
          Please check your internet connection and attempt to logout and login again.<br/>
          If this error persists or you would like additional help, please email us at:<br/>help@dogscatsmedicine.com<br/>
        </Typography>
      </Stack>
      
    );
  }

  function renderHome() {
    return (
      <Stack spacing={3} alignItems="center">
        <Button href="/clinical-notebook" variant="outlined" size='large' sx={{width: '300px', color: 'inherit', mt: '20px'}}>The Notebook</Button>
        <Button href="/quick-sheets" variant="outlined" size='large' sx={{width: '300px', color: 'inherit'}}>Quick Sheets</Button>
        <Button href="/notes" variant="outlined" size='large' sx={{width: '300px', color: 'inherit'}}>Case Notes</Button>
        <Button href="/forms" variant="outlined" size='large' sx={{width: '300px', color: 'inherit'}}>Forms</Button>
        <Button href="/calculators" variant="outlined" size='large' sx={{width: '300px', color: 'inherit'}}>Calculators</Button>
        <FillPicDiv>
          <FillPicImg src={oncology} alt={"dog image"}/>
        </FillPicDiv>
        <Typography></Typography>
      </Stack>
    );
  }

  function renderSubscribeOption() {
    return (
      <Stack spacing={2} sx={{textAlign: 'center', alignItems: 'center'}} >
        <Typography variant='h4' sx={{mt: '20px', fontFamily: "Outfit"}}>Ready to Subscribe?</Typography>
        <FillPicDiv>
          <FillPicImg src={oncology} alt={"dog image"}/>
        </FillPicDiv>
        <Button href="/signup/?auth=true" variant="contained" size='large'>Yes, let's go!</Button>
      </Stack>
    );
  }

  function renderWelcome() {
    return (
      <Stack sx={{maxWidth: '1200px', alignItems: 'center', margin: 'auto'}}>
        <Typography sx={{fontFamily: "Paytone One", fontSize: "4.3rem", letterSpacing: "-1px", mt: '-10px', lineHeight: '1'}}>WELCOME, FRIEND</Typography>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.5rem', mt: '10px'}}>Looking for clinical decision support, problem-specific quick sheets and checklists, case notes, topic notes, drug & fluid calculators?</Typography>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.5rem', mt: '10px'}}>You've found it all, and the next 200 users get founder's pricing!</Typography>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.5rem'}}><span style={{fontSize: "2.3rem"}}>$19.<sup>99</sup>/mo</span><br/>(or $219<sup>89</sup>/yr)</Typography>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.25rem'}}>Well this sounds like the cat's pajamas!</Typography>
        <Button href="/signup" variant="contained" sx={{fontFamily: "Outfit", fontSize: '1.1rem', m: '10px', textTransform: "none"}}>Sign me up!</Button>
        <FillPicDiv>
          <FillPicImg src={oncology} alt={"dog"}/>
        </FillPicDiv>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.5rem', mt: '10px'}}>Dogs Cats Medicine is a clinical tool that will grow with your veterinary career.  It's helpful for case-based learning, as a quick reference on a challenging case, and can be used for storing topic notes.  You can access it on any of your devices that connect to the internet. If you try it and don't love it, we'll give you your money back.</Typography>
        <Button href="/signup" variant="contained" sx={{fontFamily: "Outfit", fontSize: '1.1rem', m: '10px', textTransform: "none"}}>I'm ready. Let's go!</Button>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" sx={{mt: 2}}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"I wish I had this tool earlier in my  career! The Quick Sheets are amazing!"</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"Using the fluid calculators and sedation calculators every day in the clinic, and they're awesome!"</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"Awesome resources! Thank you!"</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"So glad I found this site!"</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"Every veterinarian should have this in their toolkit!"</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{width:"100%", height:"100%"}}>
              <Typography sx={{p: 8, fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', mt: '10px'}}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br/>"As a fourth year student, this has become the best way to collect and learn from my cases!"</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Button href="/signup" variant="contained" sx={{fontFamily: "Outfit", fontSize: '1.1rem', m: '10px', textTransform: "none", mt: 4, mb: 0}}>Sign up meow!</Button>
        <FillPicDiv>
          <FillPicImg src={behavior} alt={"cat image"}/>
        </FillPicDiv>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{mt: 5}}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{fontSize: "1.4rem"}}>
              The Notebook
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontSize: "1.2rem"}}>
              A clinical notebook including sections on 
              <span style={{fontWeight: "700"}}> anesthesia, behavior, cardiology, clinical pathology, dentistry, dermatology, emergency, euthanasia,
               internal medicine, musculoskeletal, neurology, nutrition, oncology, ophthalmology, radiology, reproduction, surgery, and wellness</span>. 
               This can be a quick reference during clinical practice. Create notes within each topic and reference them later (from any device). 
               Learn something cool about a new anesthetic drug&mdash;add it to your personal anesthesia section of the notebook!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{fontSize: "1.4rem"}}>
              Quick Sheets
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontSize: "1.2rem"}}>
              Thirty-eight Quick Sheets with common conditions ranging from <span style={{fontWeight: "700"}}>Seizures & Status Epilepticus</span> to <span style={{fontWeight: "700"}}>Anaphylaxis & Hypersensitivity Reactions</span>. These Quick Sheets give step-by-step clinical guidance, include relevant evidence, and double check your drug dosages.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{fontSize: "1.4rem"}}>
              Case Notes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontSize: "1.2rem"}}>
              Perfect for students or career veterinarians interested in case-based learning. Organize your thoughts. Collect interesting cases. Sort and search easily. Filter to hospitalized patients to help organize your notes for end-of-shift rounds.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{fontSize: "1.4rem"}}>
              Forms
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontSize: "1.2rem"}}>
              Anesthesia safety checklist, neuro exam form, ophtho exam form, tumor map&mdash;this section has forms that might simplify your practice.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{fontSize: "1.4rem"}}>
              Calculators
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontSize: "1.2rem"}}>
              Fluids calculators that are useful every day. Calculators for sedation drug protocols, emergency drugs, and emesis induction will save you time and prevent errors.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Button href="/signup" variant="contained" sx={{fontFamily: "Outfit", fontSize: '1.1rem', m: '10px', textTransform: "none", mt: 3, mb: 0}}>Pug Yeah! (Sign up)</Button>
        <Typography sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.2rem', m: '50px 0 20px 0'}}>info@dogscatsmedicine.com</Typography>
        <Button component={Link} to="/terms" size="large" sx={{fontFamily: "Outfit", textAlign: "center", marginBottom: '30px', fontSize: '1.1rem', textTransform: "none"}}>Terms & Conditions</Button>
      </Stack>
    );
  } 

  if (!isAuthenticated && !isSubscribed) {
    return (renderWelcome());
  } else if (isAuthenticated && isErrored) {
    return(renderUserIssue());
  } else if (isAuthenticated && !isSubscribed) {
    // render a subscribe call to action
    return (renderSubscribeOption());
  } else if (isAuthenticated && isSubscribed) {
    return (renderHome());
  }

}

export default Home;
