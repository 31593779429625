import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioDCMDiet() {

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Diet-Associated Dilated Cardiomyopathy (DCM)</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>Feeding grain-free, boutique, exotic-protein, vegan, vegetarian, and home-cooked diets has been associated with DCM in breeds that are not usually at risk.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>Diets to Avoid: The top brands associated with DCM in this FDA update include Acana, Zignature, Taste of the Wild, 4Health, Earthborn Holistic, Blue Buffalo, Nature’s Domain, Fromm, Merrick, California Natural, Natural Balance, Orijen, Nature’s Variety, NutriSource, Nutro, and Rachael Ray Nutrish. Many of these are premium diets (and relatively expensive). There is a lot of money in pet food (it's about 70% of the entire veterinary industry). Many of our pets are on these diets, and many pet parents are bonded to these brands.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>Diets to Recommend: Hill's, Purina, and Royal Canin (Iams and Eukaneuba are from the same manufacturer) are the three big names in pet food. They do most of the research on pet nutrition and do AAFCO feeding trials with their own diets. For pet parents who wish to avoid these brands, a nutrition consult (with a boarded veterinary nutritionist, such as those offered by The Ohio State University) can be recommended. For pet parents who wish to home-cook their pet's meals, Balance IT may be helpful.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/diet-associated-dcm" target="_blank" rel="noopener noreferrer">Diet-Associated Dilated Cardiomyopathy Handout</a> from PetParentHandouts.com
        </Typography>
        <Typography>
        <a href="https://www.fda.gov/animal-veterinary/news-events/fda-investigation-potential-link-between-certain-diets-and-canine-dilated-cardiomyopathy" target="_blank" rel="noopener noreferrer">FDA Update</a> from June 27, 2019
        </Typography>
        </Grid>
        <Grid item xs={12}>

        </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>

  );
}
