import React, {useState} from 'react';

import {Accordion, Grid, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ArterialThrombosis() {
  const [refs, setRefs] = useState(false);

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Arterial Thrombosis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Usually due to thrombus formation (not embolism like cats).
                    </Typography>
                    <Typography>
                    Diseases that lead to hypercoagulability include protein-losing disease, hypothyroidism, hyperadrenocorticism, diabetes mellitus, cancer, endocarditis
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> More variable presentation than feline ATE; usually present with paraparesis or monoparesis which may be acute or chronic and may have IVDD-like presentation. Pulses may be weak in affected limbs.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> IVDD, FCE, or other cause of hind end paresis to paralysis
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Blood glucose in the affected limb compared to peripheral is often enough to confirm the diagnosis (will be lower in affected limb). A difference of &ge;30 mg/dL in cats and &ge;16 mg/dL in dogs is highly sensitive (100%) and specific (90% in cats, 100% in 
                    dogs).<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Klainbart et al.</Button> {refs ? " Peripheral and Central Venous Blood Glucose Concentratons in Dogs and Cats with Acute Arterial Thromboembolism. JVIM 2014." : "" }</sup> Doppler can demonstrate peripheral pulselessness. CPK and AST are often high (usually not needed to confirm). 
                    Initial blood work may point towards other systemic disease that put patient in hypercoagulable state. Abdominal ultrasound is helpful to visualize and characterize the thrombus. Chest radiographs may help rule out neoplasia. UA may rule out proteinuria.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> Start with pain management and IV fluids (to improve perfusion). Treatment may include warfarin and clopidogrel. Identify and treat other underlying diseases if present. Call your closest interventional radiology team to discuss further/advanced treatment in the right cases.
                    </Typography>
                  </Grid>
                  
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
