import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Achilles from './Achilles';
import Osteosarcoma from '../Oncology/Osteosarcoma';
import Fractures from './FracturesForelimb';
import HipLux from './HipLuxation';
import HipDysplasia from './HipDysplasia';
import Crcld from './Crcld';
import PatLux from './PatellarLux';
import AsepticNecrosis from './AsepticNecrosis';
import FibroticMyopathy from './FibroticMyopathy';
import Iliopsoas from './Iliopsoas';
import Panosteitis from './Panosteitis';
import HypertrophicOsteodystrophy from './HypertrophicOsteodystrophy';

export default function Hindlimb() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hindlimb</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Achilles />
          <AsepticNecrosis />
          <Crcld />
          <FibroticMyopathy />
          <Fractures />
          <HipDysplasia />
          <HipLux />
          <HypertrophicOsteodystrophy />
          <Iliopsoas />
          <Osteosarcoma />
          <Panosteitis />
          <PatLux />
        </AccordionDetails>
      </Accordion>

  );
}
