import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Impa() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Immune-Mediated Polyarthritis (IMPA)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        IMPA is characterized by painful swollen joints. IMPA can be erosive or nonerosive, primary or secondary. In the erosive form, there is also destruction of the bone around the joints.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Differential diagnoses include osteoarthritis, neoplasia, trauma, infection (tick-borne and bacterial most common).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> CBC with reticulocyte count, Chemistry + CPK; UA +/- urine culture; tick panel; radiographs of affected joints; consider thoracic radiographs and abdominal ultrasound; Consider arthrocentesis for joint fluid analysis, cytology, and culture.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span> Stop any medications that may have been potential trigger. Immunosuppressive steroids are mainstay of treatment. Consider doxycyline trial if any concern for tick-borne disease or inadequate testing to rule out. An adjunct immunosuppressive is often started at initial treatment (consider cyclosporine). Additional pain medication (avoid NSAIDs as steroids may be needed even if not started initially). 
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
