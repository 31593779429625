import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HipLuxationRads from '../Radiology/HipLuxationRads';

export default function HipLuxation() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hip Luxation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Hip dislocation is common following trauma.  The head of the femur is usually displaced craniodorsally (up and forward), and the greater trochanter of the femur can be palpated forming a straight line with the ischium and wing of the ilium.
        </Typography>
        <Typography>Radiographs will confirm your diagnosis and help rule out pelvic or other hind limb fractures.</Typography>
        </Grid>
        <HipLuxationRads />
        <Grid item xs={12}>
          <Typography>Closed reduction may be reasonable to attempt if no obvious hip dysplasia, acetabular or femur fractures, and the injury was within the past 48 hours.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Ehmer slings have an unacceptably low success rate and high complication rate and are usually not a good option.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Schlag et al.</Button> {refs ? " Analysis of outcomes following treatment of craniodorsal hip luxation with closed reduction and Ehmer sling application in dogs. JAVMA 2019." : "" }</sup></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Several surgical options exist and each have benefits and risks that should be discussed with the surgeon. Toggle pin, total hip replacement, and FHO are most common.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/hip-luxation" target="_blank" rel="noopener noreferrer">Hip Luxation Handout</a> from the American College of Veterinary Surgeons
        </Typography>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952410" target="_blank" rel="noopener noreferrer">Hip Dislocation Handout</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
