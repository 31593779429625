import React, {useState} from 'react';

import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Psyllium from './Psyllium';
import BacteriuriaSubclinical from '../ClinicalPathology/BacteriuriaSubclinical';
import Urinalysis from '../ClinicalPathology/Urinalysis';
import Prostatitis from './Prostatitis';

export default function Uti() {
  const [refs, setRefs] = useState(false);
  const [refs2, setRefs2] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Urinary Tract Infection (UTI)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> UTIs generally occur from GI bacteria that cross the perineum, set up shop around and in external genitalia, and then invade the urethra and bladder. Female dogs with recessed vulvas are at greater risk.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Often show frequent urination (pollakiuria), straining (stranguria), bloody urine (hematuria), and inappropriate urination.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Vaginitis (especially common in puppies) can present similarly and UTI can be ruled out by urinalysis with culture and sensitivity. Urolithiasis, bladder cancer, incontinence, or other systemic diseases causing polyuria/polydipsia should be ruled out.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> 
        </Typography>
        <ul>
          <li><Urinalysis /></li>
          <ul><li>(with collection by cystocentesis)</li></ul>
          <li>
          Urine culture and sensitivity
          </li>
          <li>
          CBC/Chemistry - assess renal function, rule out other systemic diseases
          </li>
          <li>
          Focused bladder ultrasound or abdominal ultrasound (look for stones, masses, ectopic ureters)
          </li>
          <li>
          Abdominal radiographs (to rule out radiopaque stones and show position of the female bladder)
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment & Followup:</span> 
        </Typography>
        <ul>
          <li>
          <span style={{fontWeight: "700"}}>Simple UTI:</span> Patient should improve within 2 days and clinical signs should resolve. Historically treated with 7 or more days of antibiotics, however, a 3-5 day course may be effective.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Weese et al.</Button> {refs ? " International Society for Companion Animal Infectious Diseases (ISCAID) guidelines for the diagnosis and management of bacterial urinary tract infections in dogs and cats. The Veterinary Journal 2019." : "" }</sup> NSAID (if not contraindicated) to decrease inflammation.
          </li>
          <ul>
            <li>Amoxicillin is a good first choice.</li>
            <li>Fluoroquinolones (enrofloxacin, marbofloxacin, etc), and third generation cephalosporins (cefpodoxime, please DO NOT use Convenia for many reasons), and nitrofurantoin should be reserved for when amoxicillin (+/- clavulanic acid) and TMS are not appropriate based on culture and sensitivity or patient factors.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs2(!refs2)}>Weese et al.</Button> {refs2 ? " International Society for Companion Animal Infectious Diseases (ISCAID) guidelines for the diagnosis and management of bacterial urinary tract infections in dogs and cats. The Veterinary Journal 2019." : "" }</sup></li>
          </ul>
          <li>
          <span style={{fontWeight: "700"}}>Complicated UTI:</span> Treatment should be guided by culture and sensitivity; often require treatment for 2-6 weeks; often culture at the beginning of treatment, when considering stopping treatment, and 5-7 days after stopping treatment
          </li>
          <ul>
            <li>UTI is complicated if recent treatment with antibiotics, stones/masses/anatomic/neurologic/functional problem, underlying systemic disease, not neutered, relapse/reinfection, pyelonephritis or prostatitis</li>
            <li><Prostatitis /></li>
          </ul>
        </ul>
        <Typography>
        <span style={{fontWeight: "700"}}>Additional Things That May Help:</span> 
        </Typography>
        <Typography>
        N-Acetyl Cysteine (NAC)
        </Typography>
        <ul>
          <li>NAC is a mucolytic that may disrupt biofilm (created by some E. coli); Give 70 mg/kg PO q8h; this is a common human oral supplement often in capsules or powder</li>
        </ul>
        <Typography>
        Cranberry extract
        </Typography>
        <ul>
          <li>Proanthocyanidins bind PapG pili found in some strains of E. coli; questionable/no benefit especially if not one of these strains</li>
        </ul>
        <Typography>
        D-mannose
        </Typography>
        <ul>
          <li>Sugar that may prevent bacterial adherence with some benefit shown in people and rodents</li>
        </ul>
        <Typography>
        Probiotics and Prebiotics
        </Typography>
        <ul>
          <li><Psyllium /></li>
        </ul>
        <Typography>
        <span style={{fontWeight: "700"}}>Asymptomatic Bacteriuria:</span> 
        </Typography>
        <ul>
          <BacteriuriaSubclinical />
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
