import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class EmesisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    })
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Induction of Emesis (& Decontamination)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
          <Grid item xs={12}>
          <Typography>ASPCA Animal Poison Control: <a href="tel:8884264435">(888) 426-4435</a></Typography>
          <Typography>Pet Poison Helpline: <a href="tel:8884264435">(855) 764-7661</a></Typography>
          </Grid>
          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>

          <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
            <Grid item>Canine</Grid>
            <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
            </Grid>
            <Grid item>Feline</Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          {this.state.switchSevere ?
            <Grid container spacing={0}>

            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Cats often present after witnessed dietary indiscretion (string, small object) or they present for vomiting and a gastric foreign body is identified (hair ties, metallic things). Recent known or suspected lily ingestion is another common reason to induce emesis.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Emesis contraindicated:</span> Avoid inducing emesis if altered mentation, absent swallow reflex, strong alkaloid or petroleum ingestion suspected. Sharp and pointy objects should usually not be recovered with emesis (consider endoscopy or surgery). Do not induce emesis in pets that are already vomiting (except in certain cases where more forceful vomiting may produce a gastric foreign body).</Typography>

            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Warn pet parents that we cannot successfully induce emesis in all feline patients. If not contraindicated, induce emesis.</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
          Consider 7-10 ug/kg Dexmedetomdine = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(7)/500).toFixed(2)} mL to {(this.state.weightkg*(10)/500).toFixed(2)} mL</span> of 0.5 mg/mL Dexmedetomdine IM for this patient.
          </li>
          <li>
          Alternatively, consider 0.1 mg/kg Hydromorphone = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/2).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>2 mg/mL</span> Hydromorphone SC for this patient.
          </li>
          <li>
          Do NOT combine opioid (such as hydromorphone) with dexmedetomidine.
          </li>
          <li>
          Wait at least 10-15 minutes. Reported time range to first emesis is as long as 42.5 minutes for some cats that receive dexmedetomidine.  Some practitioners will gently massage the belly or spin the patient on a stool if tolerated; the benefit of this has not been studied.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider reversal with atipamezole or naloxone.</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider maropitant.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL maropitant SC for this patient.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider activated charcoal.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 g/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)).toFixed(0)} g</span> for this patient. For 10% ToxiBan suspensions, this is 10 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)).toFixed(0)} mL</span> for this patient.
          </li>
          <li>
          If giving with sorbitol, only give as first dose. IV fluids are recommended if giving sorbitol. Check baseline sodium of patient and sodium 4-6 hours after giving sorbitol.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider IV fluid therapy.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Fluid diuresis helps renal elimination of toxins and maintains renal blood flow.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider Cholestyramine to interrupt enterohepatic recirculation.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Cholestyramine is an anion exchange resin that binds lipoproteins (cholesterol) and bile acids.
          </li>
          <li>
          Commonly recommend with NSAID toxicity.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider N-acetylcysteine (Acetylcystine, NAC) as a liver protectant.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          IV route is preferred (over PO) when injectable product is available.
          </li>
          <li>
          Denamarin may be an alternative for some. The use of both is redundant.
          </li>
          <li>
          Commonly recommend with acetaminophen toxicity.
          </li>
          </ul>
          </Grid>
          </Grid>
          :
          <Grid container spacing={0}>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Dogs often present after witnessed ingestion of potentially obstructive items or they present for vomiting and a gastric foreign body is identified. Recent known or suspected toxin ingestion is another common reason to induce emesis.  If ingestion occurred within 2 hours, this is a reasonable time window to induce emesis. Toxins that delay gastric emptying (including chocolate) may be recovered with emesis well after 2 hours.</Typography>

          <Typography><span style={{fontWeight: '700'}}>Emesis contraindicated:</span> Avoid inducing emesis if altered mentation, absent swallow reflex, strong alkaloid or petroleum ingestion suspected. Sharp and pointy objects should usually not be recovered with emesis (consider endoscopy, surgery, or, in select cases, bulk feeding with high fiber). Do not induce emesis in pets that are already vomiting (except in certain cases where more forceful vomiting may produce a gastric foreign body)</Typography>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>If not contraindicated, induce emesis.</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
          Consider 0.03 mg/kg Apomorphine = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.03)/3).toFixed(2)} mL</span> of <span style={{backgroundColor: '#fff2ac'}}>3 mg/mL</span> Apomorphine or <span style={{backgroundColor: '#ffd0ac'}}>{(this.state.weightkg*(0.03)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ffd0ac'}}>1 mg/mL</span> Apomorphine IV or IM for this patient.
          </li>
          <li>
          Alternatively, consider Ropinirole eyedrops (Clevor, see dose chart on package insert). These can be applied by the pet parent in cases where the patient is challenging to handle/restrain.
          </li>
          <li>
          Wait at least 10-15 minutes. Repeat dose if needed.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />Gastric lavage may be considered in few cases in patients too symptomatic to induce emesis.
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          These patients are usually already comatose. Endotracheal intubation is necessary. Use a large orogastric tube and 10mL/kg lukewarm (cold in cases of bread yeast) water for repeat lavage cycles.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider maropitant.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL maropitant SC for this patient.
          </li>
          <li>
          Rarely contraindicated. Do not give in cases of grape ingestion treated as outpatients as maropitant will mask vomiting associated with AKI.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider activated charcoal.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 g/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)).toFixed(0)} g</span> for this patient. For 10% ToxiBan suspensions, this is 10 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)).toFixed(0)} mL</span> for this patient.
          </li>
          <li>
          If giving with sorbitol, only give as first dose. IV fluids are recommended if giving sorbitol. Check baseline sodium of patient and sodium 4-6 hours after giving sorbitol.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider IV fluid therapy.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Fluid diuresis helps renal elimination of toxins and maintains renal blood flow.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider Cholestyramine to interrupt enterohepatic recirculation.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Cholestyramine is an anion exchange resin that binds lipoproteins (cholesterol) and bile acids.
          </li>
          <li>
          Commonly recommend with cholecalciferol and NSAID toxicity.
          </li>
          <li>
          Must make sure the cholestyramine product does not contain xylitol.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider N-acetylcysteine (Acetylcystine, NAC) as a liver protectant.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          IV route is preferred (over PO) when injectable product is available.
          </li>
          <li>
          Denamarin may be an alternative for some. The use of both is redundant.
          </li>
          <li>
          Commonly recommend with acetaminophen, xylitol, and sago palm toxicity.
          </li>
          </ul>
          </Grid>
          </Grid>        }

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p>Nystrom et al. Assessment of hydromorphone and dexmedetomidine for emesis induction in cats. JVECC 2017.</p>

                    <p>Thawley et al. Assessment of dexmedetomidine and other agents for emesis induction in cats: 43 cases (2009-2014). JAVMA 2015.</p>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default EmesisQS;
