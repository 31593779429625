import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PeripheralBlood from './images/peripheralBlood.jpg';
import HemangiomaHisto from './images/hemangioma.jpg'

export default function Hemangioma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hemangioma & Hemangiosarcoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
          Bleeding skin masses in dogs are often hemangiomas or hemangiosarcomas.  Sampling with a needle (fine needle aspiration/coring) is usually not helpful since these masses are full of blood.  Surgical biopsy for histopathology is needed to differentiate the more benign hemangioma from the more malignant hemangiosarcoma.  Hemangiosarcomas can spread to the spleen or heart base.  These skin tumor are thought to be induce by UV-radiation from sunbathing and often affect light-pigmented or sparsely-haired areas.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951909" target="_blank" rel="noopener noreferrer">Hemangiosarcoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (cytology)</span></div>
          <img src={PeripheralBlood} style={{borderRadius:"50%", maxWidth: "96%"}} alt="peripheral blood"/>
          <div>Hemangiomas and hemangiosarcomas are vascular tumors. Fine needle coring will look like peripheral blood.</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (histopathology)</span></div>
          <img src={HemangiomaHisto} style={{borderRadius:"50%", maxWidth: "96%"}} alt="histopath of a hemangioma"/>
          <div>Notice that histopathology can show architecture and margins.</div>
          </Paper>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
