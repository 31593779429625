import React, {useState} from 'react';

import { Button, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BrachycephalicAirway() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Brachycephalic Airway Syndrome</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          This is a combination of problems including an elongated soft palate, everted laryngeal saccules and stenotic nares common in short faced dogs (and cats). These dogs often have stertorous breathing and exercise intolerance. Increased turbulent airflow can cause acute swelling (and life threatening airway obstruction) or chronic fibrosis of these tissues and worsen breathing trouble.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Early surgical consultation and correction is recommended for short-faced dogs with moderate to severe problems.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Norwich Terriers have upper airway abnormalities (often with normal physical exams) that put them at risk for similar anesthetic challenges as brachycephalic breeds.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Johnson et al.</Button> {refs ? " Upper Airway Obsruction in Norwich Terriers: 16 Cases. JVIM 2013." : "" }</sup>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/brachycephalic-syndrome" target="_blank" rel="noopener noreferrer">Brachycephalic Syndrome</a> from ACVS
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951534" target="_blank" rel="noopener noreferrer">Brachycephalic Airway Obstruction Syndrome in Flat-Faced Dogs</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
