import React, { Component } from 'react';
import { Grid, InputAdornment, Divider, TextField, Checkbox, Button } from '@mui/material';

class EmesisCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  render() {
    return (
      <>
        <Grid container spacing={0} style={{textAlign: "left"}}>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Button href="/quick-sheets/emesis" variant="outlined">Induction of Emesis Quick Sheet</Button>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="epinephrine"
            inputProps={{ 'aria-label': 'epinephrine' }}
            />For dogs, consider <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.03)/3).toFixed(2)} mL</span> of <span style={{backgroundColor: '#fff2ac'}}>3 mg/mL</span> Apomorphine or <span style={{backgroundColor: '#ffd0ac'}}>{(this.state.weightkg*(0.03)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ffd0ac'}}>1 mg/mL</span> Apomorphine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            May re-dose once if no vomiting in first 10-15 minutes.
            </li>
            <li>
            Ropinirole (Clover) ophthalmic solution is the first FDA-approved veterinary emetic.  Mean time to emesis is 12 minutes.  Consider this alternative when available.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="epinephrine"
            inputProps={{ 'aria-label': 'epinephrine' }}
            />For cats, consider <span style={{fontWeight: '700'}}>Dexmedetomidine</span> at 7 ug/kg IM = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.014)).toFixed(2)} mL</span> of 0.05 mg/mL Dexmedetomidine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider reversal with atipamezole if overly sedate or bradycardic
            </li>
            </ul>
            </Grid>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="cerenia"
            inputProps={{ 'aria-label': 'cerenia' }}
            />            Consider <span style={{fontWeight: '700'}}>Maropitant</span> at 1 mg/kg IV or SC = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL Maropitant for this patient.
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '500'}}>
            Avoid Emesis:
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Caustic, sharp objects, petroleum ingested
            </li>
            <li>
            Situations with increased risk of aspiration pneumonia
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
            <p style={{fontSize: '0.8rem'}}>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p style={{fontSize: '0.8rem'}}>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>
        </Grid>
      </>
    )
  }


};

export default EmesisCalculator;
