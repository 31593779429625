import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function PreVisitChill() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" rowSpan={2}>Drug (dose range)</TableCell>
            <TableCell align="center" colSpan={2}>Dose Range (mg)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">min</TableCell>
            <TableCell align="center">max</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>




        <TableRow>
          <TableCell align="left"><span style={{fontWeight: '900'}}>Gabapentin</span> (20-25 mg/kg the evening before and 1-2 hours prior to visit)</TableCell>
          <TableCell align="center">{(weightKg*(10)).toFixed(2)}</TableCell>
          <TableCell align="center">{(weightKg*(25)).toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
         Liquid: 250 mg/5mL = 50 mg/mL; Capsules: 100 mg, 300 mg; Compounded tablets available for small dogs
        </li>
        </ul>
        </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left"><span style={{fontWeight: '900'}}>Melatonin</span></TableCell>
          <TableCell align="left" colSpan={2}>
          <ul>
            <li>
              Small dogs: 1 mg PO for 1-2 hours before appointment
            </li>
            <li>
              Medium dogs: 3 mg PO for 1-2 hours before appointment
            </li>
            <li>
              Large dogs: 5 mg PO for 1-2 hours before appointment
            </li>
          </ul>  
          </TableCell>     
        </TableRow>

        <TableRow>
          <TableCell align="left"><span style={{fontWeight: '900'}}>Acepromazine</span> (0.025 - 0.05 mg/kg oral transmucosal 30 minutes before appointment)</TableCell>
          <TableCell align="center">{(weightKg*(0.025)).toFixed(2)} mg = {(weightKg*(0.025)/10).toFixed(3)} mL of 10 mg/mL Acepromazine</TableCell>
          <TableCell align="center">{(weightKg*(0.05)).toFixed(2)} mg = {(weightKg*(0.05)/10).toFixed(3)} mL of 10 mg/mL Acepromazine</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
          10 mg/mL product can be diluted to 1 mg/mL for smaller patients
        </li>
        <li>
          This protocol is intended for profoundly fearful, aggressive, but otherwise systemically healthy dogs.
        </li>
        </ul>
        </TableCell>
        </TableRow>


        </TableBody>




      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    This calculator is meant to double check your math.  It is not a substitute for calculating your patient's dose.  Always double check drug dosages and concentrations.
    </li>
    <li>Individual patient considerations may make this protocol unsafe/contraindicated.</li>
    <li>Be prepared to monitor patient for 4-6+ hours.</li>
    <li>Reference: Costa et al. Chill Protocol to Manage Aggressive & Fearful Dogs. Clinician's Brief 2019.</li>
    </ul>
    </Grid>
    </Grid>
    </>

  );
}
