import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ACTHStim from './ACTHStimTest';

export default function Hyperadrenocorticism() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hyperadrenocorticism (Cushing's Disease)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Abnormally high production of cortisol by the adrenal glands. May be pituitary dependent (about 85% of cases) or adrenal dependent. About half of adrenal tumors are malignant, so differentiating is important.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> polyuria/polydipsia, polyphagia, panting, pot-bellied, thin-skinned, poor hair coat, muscle weakness, systemic hypertension</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Routine Laboratory Findings:</span> elevated liver values, poorly concentrated urine</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
              <li>
              Low Dose Dexamethasone Suppression Test
              </li>
              <li>
              ACTH Stimulation Test is most useful in patients with concurrent disease such as Diabetes Mellitus.  Ideally it is performed 7-14 days after initial diagnosis of DM once DM is better regulated.
              </li>
              <li>
              <ACTHStim />
              </li>
              <li>
              Abdominal Ultrasound
              </li>
              <li>
              Urine Cortisol/Creatinine Ratio - highly sensitive when done from first-catch urine collected at home, ideally 2 or more days after veterinary visit. Pool sample from 2-3 days.  False positives common. Negative result rules out hyperadrenocorticism.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.12192" target="_blank" rel="noopener noreferrer">Diagnosis of Spontaneous Canine Hyperadrenocorticism: 2012 ACVIM Consensus Statement (Small Animal)</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Medical management with Trilostane. Surgery should be considered in cases of adrenal tumors.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Monitoring Trilostane Therapy:</Typography>
            <ul>
              <li>
              There is controversy around Trilostane monitoring.
              </li>
              <li>
              If using the ACTH stimulation test to monitor, measure 4 hours post Trilostane dosing. Pre and post should be between 2-7 ug/dL. If post stim dosing &ge;7ug/dl, consider increasing dose by 25-50%.
              </li>
              <li>
              In patients that are doing well clinically, consider measuring baseline cortisol. If 1.3 to 2.9 ug/dL, maintain same dose. If &le; 1.3 ug/dL, recommend ACTH stim. If 3.0 - 5.5 ug/dL, consider ACTH stim. If &ge; 5.6 ug/dL, increase dose.
              </li>
              <li>
              Clinical signs trump all else. If signs persist and ACTH stim test is within 2-7 ug/dL, consider dividing dose and giving BID (duration of SID dose may bee too short).
              </li>
              <li>
              Adrenal necrosis is possible, monitor electrolytes (especially if feeling unwell) for signs of hyperkalemia
              </li>
              <li>
              If feeling ill and electrolytes remain normal, stop Trilostane and give prednisone at 0.5 mg/kg q24h for 2-3 days. Consider a dose reduction by 50%.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Cats with hyperadrenocorticism</span> may have skin fragility syndrome and a plantigrade stance. They are rarely PU/PD except if concurrent Diabetes Mellitus.  They will not have increased ALP.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951495" target="_blank" rel="noopener noreferrer">Cushing's Syndrome (Hyperadrenocorticism): Description</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://petparenthandouts.com/cushings-syndrome" target="_blank" rel="noopener noreferrer">Cushing’s Syndrome</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
