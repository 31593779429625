import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class EmesisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    })
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Pleural Effusion & Thoracocentesis</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>

          <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
            <Grid item>Canine</Grid>
            <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
            </Grid>
            <Grid item>Feline</Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          {this.state.switchSevere ?
            <Grid container spacing={0}>

            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Often present in some degree of respiratory distress with tachypnea and hyperpnea. Thoracic ultrasound can quickly identify pleural effusion in many cases on the way to or once in an oxygen cage.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Underlying causes:</span></Typography>
            <ul>
              <li>Heart Disease</li>
              <li>Neoplasia: Lymphoma, Thymoma, Other</li>
              <li>Feline Infectious Peritonitis (FIP)</li>
              <li>Pyothorax (usually secondary to trauma)</li>
              <li>Other less common causes include: pancreatitis, PPDH, uremia, lung lobe torsion, idiopathic chylothorax</li>
            </ul>
            <Typography>
              <span style={{fontWeight: '700'}}>Diagnostics: </span>
            </Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>Thoracocentesis: collect for fluid analysis, +/- cytology, +/- aerobic & anaerobic culture, +/- RT-PCR FCoV RNA (FIP)</li>
                <li>CBC/Chemistry</li>
                <li>SNAP FIV/FeLV/HW</li>
                <li>SNAP BNP - can use pleural fluid (as rule out test - high sensitivity, lower specificity)</li>
                <li>Echocardiogram</li>
                <li>Thoracic radiographs - repeat after thoracocentesis to better evaluate lungs and mediastinum</li>
                <li>CT</li>
              </ul>
            </Grid>
            
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Decrease Stress"
            inputProps={{ 'aria-label': 'decrease stress' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen</span>
          </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL to {(this.state.weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM for this patient.
            </li>
            <li>
            Place directly in oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in severely compromised patients.  Can give furosemide at presentation prior to diagnostics, especially if hear gallop or murmur. Furosemide is more helpful with pulmonary edema than pleural effusion.
            </li>
            </ul>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider additional sedation prior to thoracocentesis</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
            Midazolam/Alfaxalone/Opioid combinations are safest
            </li>
            <li>
            Avoid alpha-2 drugs (dexmedetomidine)
            </li>
            </ul>
            </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Thoracocentesis</span>
          </Grid>
          <Grid item xs={12}>
            <ol>
            <li>
            Clip and prep one or both sides of the chest.
            </li>
            <li>
            Prepare a butterfly needle, three-way stopcock, 12-20 mL syringe, and container for collection. It's best if a helper works the syringe plunger and stopcock.
            </li>
            <li>
            Using sterile technique, confidently advance the needle into the pleural space, ideally while or just after selecting an insertion point by ultrasound. Usually around the 7th or 8th intercostal space near the point of the elbow. Go just cranial to the rib (blood vessels on caudal border of rib).
            </li>
            <li>Carefully redirect your needle as needed; you may wish to have your helper save a couple milliliters of fluid in the syringe when emptying to flush back through the needle if you experience a potential occlusion/clog.</li>
            <li>Be patient and use the ultrasound as needed. It is not uncommon to remove a few hundred milliliters of fluid. Just 10mL/kg can make a big difference for some patients.  You may need to tap both sides or multiple sites. Compromised patients may quickly become more fractious once they have the added lung capacity.</li>
            </ol>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Perform in-hospital fluid analysis prior to sending out</span>
          </Grid>
          <ul>
          <li>Gross description</li>
          <li>Specific Gravity, TS, Rivalta's Test (supports exudate, sensitive but not specific for FIP)</li>
          <li>Cytology of spun sample</li>
          <li>SNAP BNP of effusion - rule out test for heart disease (highly sensitive, not very specific)</li>
          <li>Triglyceride and cholesterol of fluid if concern for chylous effusion (high triglyceride, usually &ge;300 mg/dL; cholesterol to triglyceride level &lt;1)</li>
          </ul>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Repeat thoracic radiographs</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Significant fluid often obscures mediastinal mass and limits evaluation of lung parenchyma</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>If pyothorax, start broad spectrum antibiotic</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider Ampicillin/Unasyn & Enrofloxacin</li>
          <li>Consider transition to Pradofloxacin (Veraflox) if culture supports use of fluoroquinolone</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider prednisolone if high degree of suspicion of lymphoma and fitting with pet parent's goals</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Thymoma may be more common in older cats and surgical treatment can be curative</li>
          <li>Consider oncology referral for CT and FNA of chest mass in appropriate cases</li>
          <li>Prednisolone may limit ability to obtain diagnosis in future if diagnostic samples not already collected</li>
          </ul>
          </Grid>
          </Grid>
          :
          <Grid container spacing={0}>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Often present in some degree of respiratory distress with tachypnea and hyperpnea. Thoracic ultrasound can quickly identify pleural effusion in many cases on the way to or once in an oxygen cage.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Underlying causes:</span></Typography>

            <ul>
              <li>Pyothorax</li>
              <li>Pericardial Effusion, DCM, Right-sided heart failure, Heartworm disease</li>
              <li>Mediastinal mass</li>
              <li>Neoplasia</li>
              <li>Hemothorax due to coagulopathy or neoplasia</li>
              <li>Idiopathic chylothorax</li>
              <li>Other less common causes include: Diaphragmatic Hernia, Lung lobe torsion, Pulmonary Thromboembolism, Pancreatitis (usually mild, transient effusion)</li>
            </ul>
            <Typography>
              <span style={{fontWeight: '700'}}>Diagnostics: </span>
            </Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>PT/PTT</li>
                <li>Platelet count</li>
                <li>Thoracocentesis: collect for fluid analysis, +/- cytology, +/- aerobic & anaerobic culture</li>
                <li>CBC/Chemistry</li>
                <li>Heartworm Test</li>
                <li>Echocardiogram</li>
                <li>Thoracic radiographs - repeat after thoracocentesis to better evaluate lungs and mediastinum</li>
                <li>CT</li>
              </ul>
            </Grid>
            
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Decrease Stress"
            inputProps={{ 'aria-label': 'decrease stress' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen</span>
          </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL to {(this.state.weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM for this patient.
            </li>
            <li>
            Place directly in oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in severely compromised patients.
            </li>
            </ul>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Rule out coagulopathy (e.g. secondary to anti-coagulant rodenticide) and thrombocytopenia</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>If hemothorax, consider fresh whole blood or fresh frozen plasma (FFP) transfusion. Further investigate and treat the underlying cause.</li>
            </ul>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider additional sedation prior to thoracocentesis (often not needed)</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
            Midazolam/Alfaxalone/Opioid combinations are safest
            </li>
            <li>
            Avoid alpha-2 drugs (dexmedetomidine)
            </li>
            </ul>
            </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Thoracocentesis</span>
          </Grid>
          <Grid item xs={12}>
            <ol>
            <li>
            Clip and prep one or both sides of the chest. You can often drain both sides from one insertion. Consider local anesthetic (often causes delay and stinging sensation may add to patient stress).
            </li>
            <li>
            Prepare a needle, over the needle catheter, or butterfly catheter (based on patient size), three-way stopcock, 30-60 mL syringe, and container for collection. It's best if a helper works the syringe plunger and stopcock. Active suction may be used for large volumes in larger patients.
            </li>
            <li>
            Using sterile technique, confidently advance the needle into the pleural space, ideally while or just after selecting an insertion point by ultrasound. Usually around the 7th or 8th intercostal space near the point of the elbow. Go just cranial to the rib (blood vessels on caudal border of rib).
            </li>
            <li>Carefully redirect your needle as needed; you may wish to have your helper save a couple milliliters of fluid in the syringe when emptying to flush back through the needle if you experience a potential occlusion/clog.</li>
            <li>Be patient and use the ultrasound as needed. Just 10mL/kg can make a big difference for some patients.  You may need to tap both sides or multiple sites.</li>
            </ol>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Perform in-hospital fluid analysis prior to sending out</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Gross description</li>
          <li>Specific Gravity, TS</li>
          <li>PCV/TS if hemorrhagic</li>
          <li>Cytology of spun sample</li>
          <li>Triglyceride and cholesterol of fluid if concern for chylous effusion (high triglyceride, usually &ge;300 mg/dL; cholesterol to triglyceride level &lt;1)</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Repeat thoracic radiographs</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Significant fluid often obscures mediastinal mass and limits evaluation of lung parenchyma</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>If pyothorax, start broad spectrum antibiotic</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider Ampicillin/Unasyn & Enrofloxacin</li>
          </ul>
          </Grid>
          </Grid>
          
                  }

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
           </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p>Mellanby and Herrtage. Canine pleural and mediastinal effusions: a retrospective study of 81 cases. JSAP 2002.</p>
                    <p>Davies and Forrester. Pleural effusion in cats: 82 cases (1987 to 1995). JSAP 1996.</p>
                    <p>Dominguez Ruiz et al. Characterization of and factors associated with causes of pleural effusion in cats. JAVMA 2018.</p>
                    <p>Hezzell et al. Differentiation of Cardiac from Noncardiac Pleural Effusions in Cats using Second-Generation Quantitative and Point-of-Care NT-proBNP Measurements. JVIM 2016.</p>
                    <p>Hassdenteufel et al. Assessment of circulating N-terminal pro B-type natriuretic peptide concentration to differentiate between cardiac from noncardiac causes of pleural effusion in cats. JVECC 2013.</p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default EmesisQS;
