import React, {useState} from 'react';

import {Accordion, Grid, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AorticThromboembolism() {
  const [refs, setRefs] = useState(false);
  const [refs2, setRefs2] = useState(false);

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Aortic Thromboembolism (Feline)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Feline Aortic Thromboembolism (ATE) occurs when a clot forms and then gets lodged at a narrowing, often the aortic bifurcation (saddle thrombus).                    </Typography>
                    <Typography gutterBottom>
                    Underlying cardiac disease (often HCM), hyperthyroidism, or neoplasia (pulmonary carcinoma commonly) make ATE more common.                    
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Usually present with acute-onset, severe hind limb paresis to paralysis and and profound pain (causing combination of thrashing, vocalizing, and open-mouth breathing). Occasionally only one limb will be affected and occasionally this will be a forelimb. Most cases are in middle-age to older cats that do not have a known history of other/underlying disease.
                    </Typography>
                    <Typography gutterBottom>
                    Pain, paralysis, weak to absent pulses, cold & pale paw pads are often enough to make a presumptive diagnosis as you begin to discuss the terrible (usually) prognosis and humane euthanasia as one option.
                    </Typography>
                    <Typography gutterBottom>
                    Listen for heart murmur or gallop (many will be normal). Some will present with CHF and may have audible pulmonary crackles. Some with pleural effusion may have decreased lung sounds.
                    </Typography>
                    <Typography >
                    Rectal temperature may be low.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> IVDD, FCE, or other cause of hind end paresis to paralysis
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Blood glucose in the affected limb compared to peripheral is often enough to confirm the diagnosis (will be lower in affected limb). A difference of &ge;30 mg/dL in cats and &ge;16 mg/dL in dogs is highly sensitive (100%) and specific (90% in cats, 100% in 
                    dogs).<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Klainbart et al.</Button> {refs ? " Peripheral and Central Venous Blood Glucose Concentratons in Dogs and Cats with Acute Arterial Thromboembolism. JVIM 2014." : "" }</sup> Doppler can demonstrate peripheral pulselessness. CPK and AST are often high (usually not needed to confirm).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> Initial treatment may include oxygen and opioid. An IV catheter should be placed if possible. Thorough discussion must be had before any further decision to treat. Euthanasia is reasonable and often elected.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>If pet parent decides to treat and support further:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                        The patient will need the support of multiple DVMs in a 24-hour facility for several days with continuous reassessment. Many will take weeks to months to fully recover (if ever do). Some will require limb amputation. Long-term survival is poor, usually due to other (often heart) diseases.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography>
                      <span style={{fontWeight: "700"}}>Further Diagnostics:</span>
                      </Typography>
                      <ul>
                      <li>
                      Chest radiographs to rule out pulmonary carcinoma, pulmonary edema, and pleural effusion
                      </li>
                      <li>
                      Complete blood work as we will now be monitoring for reperfusion injury
                      </li>
                      <li>
                      T4
                      </li>
                      <li>
                      Urinalysis
                      </li>
                      <li>
                      Echocardiogram
                      </li>
                      <li>
                      Consider abdominal ultrasound
                      </li>
                      </ul>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography>
                      <span style={{fontWeight: "700"}}>Further Treatments:</span>
                      </Typography>
                      <ul>
                      <li>
                      For those who are all in, consider calling and discussing with your closest specialty center that offers interventional radiology. New treatments are in development and they are likely better equipped than you are to make an attempt at this.
                      </li>
                      <li>
                      Continue opioid analgesia
                      </li>
                      <li>
                      If concurrent CHF, consider furosemide (this may decrease perfusion).
                      </li>
                      <li>
                      Consider cautious IV fluid therapy if no evidence of CHF
                      </li>
                      <ul><li>NG tube may be used to provide enteral water and nutrition</li></ul>
                      <li>
                      Monitor electrolytes and renal values (reperfusion injury may cause acute hyperkalemia, monitor q8h initially)
                      </li>
                      <li>
                      Thrombolytics are generally not recommended as some studies show worse outcomes (and they are not available at most clinics). Unfractionated or low molecular weight heparin may be considered by some (usually not by you if you haven’t done this before).
                      </li>
                      <li>
                      Start clopidogrel<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs2(!refs2)}>Hogan et al.</Button> {refs2 ? " Secondary prevention of cardiogenic arterial thromboembolism in the cat: the double-blind, randomized, positive-controlled feline arterial thromboembolism; clopidogrel vs. aspirin trial (FAT CAT). J Vet Cardio 2015." : "" }</sup>
                      </li>
                      <li>
                      Good nursing care with range of motion exercises
                      </li>
                      <li>
                      Once discharged, will still need rechecks every few days to reassess and monitor recovery
                      </li>
                      </ul>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                  </Accordion>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
