import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import FelineEarPolyp from './skinLesionImages/FelineEarPolyp.jpg'

export default function OtitisFeline() {

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Otitis Externa/Ear Infection (Feline)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
        <ul>
        <li>
        Investigate underlying causes including foreign body, polyp, underlying hypersensitivity disorders (especially if recurrent)
        </li>
        <li>
        Cats have exposed nerve axons of their sympathetic trunk due to anatomic differences from dogs. These nerves are more sensitive to damage with ear flushing procedures, especially if otitis media or a damaged tympanic membrane
        </li>
        <li>
        Treat with Tresaderm (thiabendazole, dexamethasone, neomycin sulfate solution): Apply a small squeeze (5 drops) to affected ear twice daily for 1 week until recheck appointment.
        </li>
        <li>
        Consider short tapering course of oral prednisolone liquid to decrease inflammation and pruritus and restore epithelial migration.
        </li>
        </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Feline Ear Polyp</span></Typography>
        <img src={FelineEarPolyp} alt="A polyp in the external ear canal" style={{borderRadius:"1%", maxWidth: "96%"}}/>
        <Typography>Cytology or histopathology may help differentiate a benign polyp from neoplasia.</Typography>
        </Paper>
        </Grid>
      </AccordionDetails>
    </Accordion>

  );
}
