import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Wobbler() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Atlantoaxial Instability</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
            Most common in young small breed dogs presenting with ataxia, tetraparesis, +/- neck pain. Some may have seizure-like events with vocalization with opisthotonus. Take care during examination (and radiographs) as cervical ventroflexion can worsen signs or cause death.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Carefully positioned radiographs can be diagnostic, but may be best done by a neurology team.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Surgery should be recommended if neurologic deficits. External splinting (clamshell) for 6+ weeks may be an appropriate conservative option for some.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://www.acvs.org/small-animal/atlantoaxial-instability" target="_blank" rel="noopener noreferrer">Atlantoaxial Instability</a> from ACVS
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
