import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Exam from './Exam';
import Canine from './Canine';
import Feline from './Feline';
import AdditionalResources from './AdditionalResources';

import dermHxForm from './DermatologyHistoryForm.pdf';


export default function Derm() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto" paddingBottom="10px">
        <Button href={dermHxForm} variant="outlined" target="_blank" rel="noreferrer">Dermatology History Form <PictureAsPdfIcon /></Button>
      </Grid>
      <Grid item xs={12}>
        <Exam />
        <Canine />
        <Feline />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
