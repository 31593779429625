import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableContainer, TableBody, TableCell,TableHead, TableRow, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class EmesisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleSwitchChange(event) {
    this.setState({
      switchSevere: event.target.checked
    })
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Acute Kidney Injury (AKI)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>

          <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
            <Grid item>Canine</Grid>
            <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
            </Grid>
            <Grid item>Feline</Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          {this.state.switchSevere ?
            <Grid container spacing={0}>

            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Often present following known toxin ingestion, vomiting, lethargy, or anorexia, or young males with urethral obstruction. Consider acute on chronic kidney disease in middle-aged to older cats (AKI and CKD are interconnected syndromes).<sup>1</sup> Consider hydration status and keep in mind azotemia can be prerenal, renal, or postrenal.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Underlying causes:</span></Typography>

            <ul style={{marginTop: 0, marginBottom: 0}}>
              <li>Toxin</li>
              <ul>
                <li>Ethylene glycol (EG)</li>
                <li>Lilium plant</li>
                <li>Drugs: NSAIDs most commonly, human drugs in house (chemo, psoriasis cream/calcipotriene), aminoglycosides (amikacin), ACE inhibitors</li>
                <li>Heavy metal (Zinc)</li>
              </ul>
              <li>Ischemia</li>
              <ul>
                <li>Hypotension</li>
                <li>Anesthesia</li>
                <li>Throboembolism/infarction</li>
              </ul>
              <li>Obstruction</li>
              <ul>
                <li>Ureteral obstruction (calculi, mucous plug, blood calculi/clot, stricture)</li>
                <li>Urethral obstruction</li>
                <li>neoplasia (TCC)</li>
              </ul>
              <li>Neoplasia</li>
              <ul>
                <li>Lymphoma most commonly; less commonly adenocarcinoma, sarcoma, nephroblastoma</li>
              </ul>
              <li>Infection</li>
              <ul>
                <li>Bacterial pyelonephritis</li>
                <li>FIP</li>
              </ul>
              <li>Sepsis</li>
            </ul>
            <Typography>
              <span style={{fontWeight: '700'}}>Diagnostics: </span>
            </Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>
                Blood pressure (rule out hypertension; systolic &ge;180 mmHg is pretty convincing; 140-160 mmHg may be normal in a stressed patient, but may be real if evidence of end organ damage; hypotension can precipitate AKI)
                </li>
                <li>
                  PCV/TS - can help monitor hydration
                </li>
                <li>
                  CBC (nonregenerative anemia common with CKD, but not expected with AKI)
                </li>
                <li>
                  Chemistry: Azotemia (consider prerenal, renal, and postrenal causes which may all contribute); Hyperphosphatemia; Ca++ is normal or low normal (except in cases where hypercalcemia caused AKI); Hyperkalemia (Hypokalemia more common with CKD)
                </li>
                <li>
                  Urinalysis: low USG; glucosuria possible with tubular damage; Bacteria, casts, inflammatory cells possible; C2H2CaO5 crystals support EG toxicity;
                </li>
                <li>
                  Urine culture should be recommended (even if sediment is boring) to rule out pyelonephritis
                </li>
                <li>
                  T4 in older cats
                </li>
                <li>
                  Abdominal ultrasound
                </li>
                <li>
                 Abdominal radiographs
                </li>
                <li>Consider renal aspirate or biopsy (rare), ethylene glycol tests (limitations of many)</li>
              </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: '700'}}>IRIS AKI Grading: </span>
            </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">AKI Grade</TableCell>
                      <TableCell align="left">Creatinine (mg/dL)</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">I</TableCell>
                      <TableCell align="left">&lt;1.6</TableCell>
                      <TableCell align="left">Nonazotemic AKI: a. Documented AKI; b. Progressive nonazotemic increase in creat. w/in 48h (&ge;0.3 mg/dL); c. Oliguria (&lt;1 ml/kg/h) or anuria over 6h</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">II</TableCell>
                      <TableCell align="left">1.7-2.5</TableCell>
                      <TableCell align="left">Mild AKI; a. Documented AKI with static or progressive azotemia; b. Progressive azotemia w/in 48h (&ge;0.3 mg/dL increase in creat.), or volume responsiveness; c. Measured oliguria or anuria over 6h</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">III</TableCell>
                      <TableCell align="left">2.6-50</TableCell>
                      <TableCell align="left" rowSpan={3}>Moderate to Severe AKI; a. Documented AKI and increasing severities of azotemia and functional renal failure</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">IV</TableCell>
                      <TableCell align="left">5.1-10.0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">V</TableCell>
                      <TableCell align="left">&gt;10.0</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography style={{fontSize: ".8rem"}}>Table adapted from 2016 IRIS Grading of acute kidney injury<sup>2</sup></Typography>

            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Typography>
          <span style={{backgroundColor: '#ff726f'}}>Avoid drugs that rely on renal clearance or reduce their dose.</span>
        </Typography>

            <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV fluids"
          inputProps={{ 'aria-label': 'IV fluids' }}
          /><span style={{fontWeight: '700'}}>Address severe hyperkalemia and arrhythmias if present</span>
        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <ul>
          <li>
          Calcium gluconate counteracts the effect of hyperkalemia at the sino-atrial node. Give 0.5 to 1.5 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1.5)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV over 5-10 minutes while monitoring EKG
          </li>
          <li>
          Dextrose & Insulin will shift plasma K<sup>+</sup> into cells. Give 0.5 U/kg = {(this.state.weightkg*(0.5)).toFixed(1)} U regular insulin IV and 2 mL/U of insulin = {(this.state.weightkg*(1)).toFixed(1)} mL 50% Dextrose IV (dilute to 25%). Add 2 mL/U of Insulin = {(this.state.weightkg*(1)).toFixed(1)} mL 50% Dextrose to 1 L fluid bag. Check BG in 2-3 hours.
          </li>
          <li>
          Terbutaline will shift K<sup>+</sup> into cells and is used instead of Dextrose & Insulin by some clinicians. Give 0.01 mg/kg = {(this.state.weightkg*(0.01)).toFixed(2)} mL Terbutaline (1 mg/mL) IV slowly over 5-10 minutes. Inhaled Levalbuterol may have a similar effect.
          </li>
          </ul>
          </Grid>
        </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Start fluid rehydration</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
            Correct dehydration within 4-6 hours for most patients.
          </li>
          <li>
            See <Link to="/calculators/feline-fluids">Feline Fluids Calculator</Link>
          </li>
          <li>
            IV fluid therapy is the cornerstone of treatment for most AKIs. Prepare pet parents for &ge;3 days of hospitalization in most cases.
          </li>
          <li>
            Monitor patient weight every 6-12 hours. Watch for signs of fluid overload (especially common if oliguria/anuria). Overhydration worsens AKI and contributes to mortality. Watch for chemosis, nasal discharge, tachypnea, effusion/edema/gelatinous skin. If CVP is available, CVP &gt;10 cmH<sub>2</sub>O is associated with volume overload.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place urinary catheter if underlying cause is urethral obstruction (see <Link to="/quick-sheets/blocked-cat">Blocked Cat Quick Sheet</Link>)</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>May be useful in select cases to monitor urine production; focused bladder ultrasound and measurement is an alternative</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider starting broad spectrum antibiotic (unless toxin suspected)</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider Ampicillin/Unasyn & Enrofloxacin if concern for pyelonephritis - keep de-escalation in mind if culture supports</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Treat Ethylene Glycol toxicity early if suspected</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider fomepizole (if within 3 hours of ingestion) or ethanol (old-school, only if early and fomepizole not available)</li>
          <li>Dialysis must be recommended for most as diagnosis is usually delayed</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider referral for Renal Replacement Therapy (RRT; e.g. Dialysis) and Renal Transplant in appropriate cases when fitting with pet parent goals</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Prepare pet parents that not all cats are candidates</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider referral for SUBS (Subcutanous Ureteral Bypass) immediately if any suspicion of ureteral obstruction</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Dissolution should not be attempted in cats with obstructive upper urinary tract uroliths. Hydroneprhosis and hydroureter proximal to an obsatructive lesion are sufficient to diagnose ureteral obstruction. Ureteral strictures are common (25% of feline cases)<sup>3</sup></li>
          <li>Culture urine and start antibiotics early</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>GI support and anti-emetics</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 mg/kg = {(this.state.weightkg*(1)/10).toFixed(2)} mL of 10 mg/mL maropitant IV for this patient.
          </li>
          <li>
          Consider metoclopramide, ondansetron, pantoprazole, omeprazole, sucralfate
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider analgesia</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
            Especially if uncomfortable or painful on palpation of kidneys or abdomen
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Treat oliguria/anuria with diuretics</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
            <li>Once rehydrated, urine production should be &ge;2 mL/kg/day if central venous pressure is normal</li>
            <li>Diuretic trial may be attempted to stimulate urine production, but will not increase GFR</li>
          <li>
            Consider Furosemide at 1 to 2 mg/kg IV followed by a CRI of 0.25 to 1 mg/kg/hr for up to 6 hours. Should see improvement within 1/2 hour of bolus or not likely to help. Furosemide can worsen aminoglycoside toxicity.
          </li>
          <li>
            Consider Mannitol at 0.5 to 1 g/kg = {(this.state.weightkg*(0.5)).toFixed(2)} g to {(this.state.weightkg*(1)).toFixed(2)} g = {(this.state.weightkg*(0.5)/(0.2)).toFixed(2)} mL to {(this.state.weightkg*(1)/(0.2)).toFixed(2)} mL of 20% (200 mg/mL) Mannitol. If urine production improves, consider a 1 to 2 mg/kg/min CRI. Mannitol is contraindicated if any concern for volume overload. Do not give in cases of EG toxicity.
          </li>
          <li>Do NOT give Dopamine. Fenoldopam can be considered if available.</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider nutritional support</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
            Capromorelin and/or mirtazapine; nasoesophageal tube placement
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Continue Monitoring</span>
          </Grid>
          <Grid item xs={12}>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>
                  Urine production
                </li>
                <li>
                  Body weight and other signs of over-hydration
                </li>
                <li>
                  PCV/TS
                </li>
                <li>
                  Blood Pressure
                </li>
                <li>
                  Central Venous Pressure (CVP) if available
                </li>
                <li>
                  Creatinine and BUN - Improvement of creatinine by 0.3 mg/dL is progress
                </li>
                <li>
                  Patient attitude and appetite
                </li>
                <li>
                  Treat until renal values plateau and patient feels well. In some cases, it may take weeks to months to see full extent that kidney values are able to improve (discharge and recheck renal values every 1-2 weeks once feeling well and numbers plateau).
                </li>
              </ul>
            </Grid>
          </Grid>
          :
          <Grid container spacing={0}>

<Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Often present for vomiting, lethargy, or anorexia or with urethral obstruction (and stones). Consider acute on chronic kidney disease in middle-aged to older patients (AKI and CKD are interconnected syndromes).<sup>1</sup> Consider hydration status and keep in mind azotemia can be prerenal, renal, or postrenal.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Underlying causes:</span></Typography>

            <ul style={{marginTop: 0, marginBottom: 0}}>
              <li>Toxin</li>
              <ul>
                <li>Ethylene glycol (EG)</li>
                <li>Grapes, Raisins, Currants</li>
                <li>Vitamin D</li>
                <li>Drugs: NSAIDs most commonly, human drugs in house (chemo, psoriasis cream/calcipotriene), aminoglycosides (amikacin), ACE inhibitors</li>
                <li>Heavy metal (Zinc)</li>
              </ul>
              <li>Ischemia</li>
              <ul>
                <li>Hypotension</li>
                <li>Anesthesia</li>
                <li>Throboembolism/infarction</li>
              </ul>
              <li>Obstruction</li>
              <ul>
                <li>Ureteral obstruction (calculi, mucous plug, blood calculi/clot, stricture)</li>
                <li>Urethral obstruction</li>
                <li>Neoplasia (TCC)</li>
              </ul>
              <li>Neoplasia</li>
              <li>Infection</li>
              <ul>
                <li>Bacterial pyelonephritis</li>
                <li>Leptospirosis</li>
              </ul>
              <li>Sepsis</li>
            </ul>
            <Typography>
              <span style={{fontWeight: '700'}}>Diagnostics: </span>
            </Typography>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>
                Blood pressure (rule out hypertension; systolic &ge;180 is pretty convincing; 140-160 may be normal in a stressed patient, but may be real if evidence of end organ damage; hypotension can precipitate AKI)
                </li>
                <li>
                  PCV/TS - can help monitor hydration
                </li>
                <li>
                  Blood Smear
                </li>
                <li>
                  CBC (nonregenerative anemia common with CKD, but not expected with AKI)
                </li>
                <li>
                  Chemistry: Azotemia (consider prerenal, renal, and postrenal causes which may all contribute); Hyperphosphatemia; Ca++ is normal or low normal (except in cases where hypercalcemia caused AKI); Hyperkalemia (Hypokalemia more common with CKD)
                </li>
                <li>
                  Urinalysis: low USG; glucosuria possible with tubular damage; Bacteria, casts, inflammatory cells possible; C2H2CaO5 crystals support EG toxicity;
                </li>
                <li>
                  Urine culture should be recommended (even if sediment is boring) to rule out pyelonephritis
                </li>
                <li>
                  Abdominal ultrasound
                </li>
                <li>
                 Abdominal radiographs
                </li>
                <li>Consider Leptospirosis (point of care, serology, PCR), baseline cortisol or ACTH stim (to rule out hypoadrenocorticism), Lyme or other tick-borne disease testing, renal aspirate or biopsy (rare), ethylene glycol tests (limitations of many)</li>
              </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight: '700'}}>IRIS AKI Grading: </span>
            </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">AKI Grade</TableCell>
                      <TableCell align="left">Creatinine (mg/dL)</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">I</TableCell>
                      <TableCell align="left">&lt;1.6</TableCell>
                      <TableCell align="left">Nonazotemic AKI: a. Documented AKI; b. Progressive nonazotemic increase in creat. w/in 48h (&ge;0.3 mg/dL); c. Oliguria (&lt;1 ml/kg/h) or anuria over 6h</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">II</TableCell>
                      <TableCell align="left">1.7-2.5</TableCell>
                      <TableCell align="left">Mild AKI; a. Documented AKI with static or progressive azotemia; b. Progressive azotemia w/in 48h (&ge;0.3 mg/dL increase in creat.), or volume responsiveness; c. Measured oliguria or anuria over 6h</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">III</TableCell>
                      <TableCell align="left">2.6-50</TableCell>
                      <TableCell align="left" rowSpan={3}>Moderate to Severe AKI; a. Documented AKI and increasing severities of azotemia and functional renal failure</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">IV</TableCell>
                      <TableCell align="left">5.1-10.0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">V</TableCell>
                      <TableCell align="left">&gt;10.0</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography style={{fontSize: ".8rem"}}>Table adapted from 2016 IRIS Grading of acute kidney injury<sup>2</sup></Typography>

            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Typography>
          <span style={{backgroundColor: '#ff726f'}}>Avoid drugs that rely on renal clearance or reduce their dose.</span>
        </Typography>

            <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV fluids"
          inputProps={{ 'aria-label': 'IV fluids' }}
          /><span style={{fontWeight: '700'}}>Address severe hyperkalemia and arrhythmias if present</span>
        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <ul>
          <li>
          Calcium gluconate counteracts the effect of hyperkalemia at the sino-atrial node. Give 0.5 to 1.5 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1.5)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV over 5-10 minutes while monitoring EKG
          </li>
          <li>
          Dextrose & Insulin will shift plasma K<sup>+</sup> into cells. Give 0.5 U/kg = {(this.state.weightkg*(0.5)).toFixed(1)} U regular insulin IV and 2 mL/U of insulin = {(this.state.weightkg*(1)).toFixed(1)} mL 50% Dextrose IV (dilute to 25%). Add 2 mL/U of Insulin = {(this.state.weightkg*(1)).toFixed(1)} mL 50% Dextrose to 1 L fluid bag. Check BG in 2-3 hours.
          </li>
          <li>
          Terbutaline will shift K<sup>+</sup> into cells and is used instead of Dextrose & Insulin by some clinicians. Give 0.01 mg/kg = {(this.state.weightkg*(0.01)).toFixed(2)} mL Terbutaline (1 mg/mL) IV slowly over 5-10 minutes. Inhaled Levalbuterol may have a similar effect.
          </li>
          </ul>
          </Grid>
        </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Start fluid rehydration</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
            Correct dehydration within 4-6 hours for most patients.
          </li>
          <li>
            See <Link to="/calculators/canine-fluids">Canine Fluids Calculator</Link>
          </li>
          <li>
            IV fluid therapy is the cornerstone of treatment for most AKIs. Prepare pet parents for &ge;3 days of hospitalization in most cases.
          </li>
          <li>
            Monitor patient weight every 6-12 hours. Watch for signs of fluid overload (especially common if oliguria/anuria). Overhydration worsens AKI and contributes to mortality. Watch for chemosis, nasal discharge, tachypnea, effusion/edema/gelatinous skin. If CVP is available, CVP &gt;10 cmH<sub>2</sub>O is associated with volume overload.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place urinary catheter if underlying cause is urethral obstruction</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>May be useful in select cases to monitor urine production; focused bladder ultrasound and measurement is an alternative</li>
          <li>May be useful for infectious disease control in cases of Leptospirosis (risk of zoonosis)</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider starting broad spectrum antibiotic (unless toxin suspected)</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider Ampicillin/Unasyn & Enrofloxacin if concern for pyelonephritis - keep de-escalation in mind if culture supports</li>
          <li>Consider Ampicillin (22mg/kg IV q6h) or Doxycycline (5 mg/kg q12h) if Leptospirosis suspected. Continue Doxycycline PO for 2-3 weeks.</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Treat Ethylene Glycol toxicity early if suspected</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Consider fomepizole (if within 3 hours of ingestion) or ethanol (old-school, only if early and fomepizole not available)</li>
          <li>Dialysis must be recommended for most as diagnosis is usually delayed</li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider referral for Renal Replacement Therapy (RRT; e.g. Dialysis) and Renal Transplant in appropriate cases when fitting with pet parent goals</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Prepare pet parents that canine kidney transplant is often not available and not all dogs are candidates</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider referral for ureteral stent placement and/or extracorporeal shockwave lithotripsy immediately if any suspicion of ureteral obstruction</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>Dissolution should not be attempted in dogs with obstructive upper urinary tract uroliths. Hydroneprhosis and hydroureter proximal to an obsatructive lesion are sufficient to diagnose ureteral obstruction.<sup>3</sup></li>
          <li>Culture urine and start antibiotics early. Dogs with ureteral obstruction have a high incidence of UTI and pyonephrosis<sup>3</sup></li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>GI support and anti-emetics</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Give 1 mg/kg = {(this.state.weightkg*(1)/10).toFixed(2)} mL of 10 mg/mL maropitant IV for this patient.
          </li>
          <li>
          Consider metoclopramide, ondansetron, pantoprazole, omeprazole, sucralfate
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider analgesia</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
            Especially if uncomfortable or painful on palpation of kidneys or abdomen
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Treat oliguria/anuria with diuretics</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
            <li>Once rehydrated, urine production should be &ge;2 mL/kg/day if central venous pressure is normal.</li>
            <li>Diuretic trial may be attempted to stimulate urine production, but will not increase GFR.</li>
          <li>
            Consider Furosemide at 1-2 mg/kg IV followed by a CRI of 1 mg/kg/hr for up to 6 hours. Should see improvement within 1/2 hour of bolus or not likely to help. Furosemide can worsen aminoglycoside toxicity.
          </li>
          <li>
            Consider Mannitol at 0.25 to 0.5 g/kg = {(this.state.weightkg*(0.25)).toFixed(2)} g to {(this.state.weightkg*(0.5)).toFixed(2)} g = {(this.state.weightkg*(0.25)/(0.2)).toFixed(2)} mL to {(this.state.weightkg*(0.5)/(0.2)).toFixed(2)} mL of 20% (200 mg/mL) Mannitol. Mannitol is contraindicated if any concern for volume overload. Do not give in cases of EG toxicity.
          </li>
          <li>Consider Diltiazem if Leptospirosis is likely.<sup>4</sup></li>
          <li>Do NOT give Dopamine. Fenoldopam can be considered if available.</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider nutritional support</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
            Capromorelin and/or mirtazapine; nasoesophageal tube placement
          </li>
          </ul>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Continue Monitoring</span>
          </Grid>
          <Grid item xs={12}>
            <ul style={{marginTop: 0, marginBottom: 0}}>
                <li>
                  Urine production
                </li>
                <li>
                  Body weight and other signs of over-hydration
                </li>
                <li>
                  PCV/TS
                </li>
                <li>
                  Blood Pressure
                </li>
                <li>
                  Central Venous Pressure (CVP) if available
                </li>
                <li>
                  Creatinine and BUN - Improvement of creatinine by 0.3 mg/dL is progress
                </li>
                <li>
                  Patient attitude and appetite
                </li>
                <li>
                  Treat until renal values plateau and patient feels well. In some cases, it may take weeks to months to see full extent that kidney values are able to improve (discharge and recheck renal values every 1-2 weeks once feeling well and numbers plateau).
                </li>
              </ul>
            </Grid>
          </Grid>
          
                  }

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952107" target="_blank" rel="noopener noreferrer">Kidney Dialysis: Is it for your Pet?</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951840" target="_blank" rel="noopener noreferrer">Kidney Transplants for Cats and Dogs</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>

          <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
           </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p><sup>1</sup>Lakhmir et al. Acute Kidney Injury and Chronic Kidney Disease as Interconnected Syndromes. NEJM 2014.</p>
                    <p><sup>2</sup>Cowgill. Grading of acute kidney injury (2016). International Renal Interest Society 2016.</p>
                    <p><sup>3</sup>Lulich et al. ACVIM Small Animal Consensus Recommendations on the Treatmentand Prevention of Uroliths in Dogs and Cats. JVIM 2016.</p>
                    <p><sup>4</sup>Mathews et al. Evaluation of adding diltiazem therapy to standard treatment of acute renal failure caused by leptospirosis: 18 dogs. JVECC 2007.</p>
                    <p>Monaghan, Nolan, Labato. Feline Acute Kidney Injury: 1. Pathophysiology, etiology and etiology-specific management considerations. JFMS 2012.</p>
                    <p>Monaghan, Nolan, Labato. Feline Acute Kidney Injury: 2. Approach to diagnosis, treatment and prognosis. JFMS 2012.</p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default EmesisQS;
