import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Psyllium() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Psyllium (Fiber Source)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Generic from major retailers, NOW, plain unflavored Metamucil</Typography>
            <Typography>Must ensure the produce contains NO Xylitol</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Infectious or diet-responsive causes are more common in young animals. Neoplasia, IBD, and hyperthyroidism (feline) are more common in older animals.</Typography>
          </Grid>

          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Fiber Dosing Chart</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Small Dogs and Cats</TableCell>
                <TableCell>1/2 to 1 teaspoon 2x/day with meals</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Medium Dogs</TableCell>
                <TableCell>1-2 teaspoons 2x/day with meals</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Large Dogs</TableCell>
                <TableCell>1/2 to 1 Tablespoon 2x/day with meals</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Titrate up or down to effect.  Some large dogs may need 2 Tablespoons 2x/day.</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
          <Typography>Psyllium is a fiber source.  After 7 days diarrhea should be gone.  If partial or incomplete response, consider diet change.  Elimination diets are often low in fiber and many need added psyllium.  Consider decreasing certain antigens (protein antigens are usually not the culprit), decreasing fat, or increasing fiber.</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography>There is absolutely no reason to give antibiotics in cases of acute diarrhea, even severe acute diarrhea (excluding cases with concern for sepsis).</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography>Feeding improves GI barrier function.  In general, it is not recommended to withhold food.</Typography>
          </Grid>

          <Grid item xs={12}>
          <Typography style={{fontSize: ".8rem"}}>Reference: Purina Gastrointestinal Roundtable Event - March 31st, 2022. With: Valerie Parker, Linda Toresson, Stefan Unterer, Jorg Steiner, Jan Suchodolski</Typography>
          </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
