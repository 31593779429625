import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function AnesthesiaKeyPoints() {

  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feline-sedation-content"
          id="feline-sedation-header"
        >
          <Typography>Key Points for Communication</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{fontWeight: "700"}}>
        Pre-anesthetic Tests
        </Typography>
        <Typography gutterBottom>
        In addition to a physical examination, pre-anesthetic workup may include blood work, urinalysis, ECG (measuring the electrical activity of the heart), genetic tests for drug sensitivities, and testing for bleeding disorders.  The individual plan is tailored to the individual patient, and these tests help reduce the risks associated with anesthesia.
        </Typography>
        <Typography style={{fontWeight: "700"}}>
        Fasting (withholding food):
        </Typography>
        <Typography gutterBottom>
        For most planned anesthetic events, it is beneficial to withhold food for 6-12 hours before the event.  This is to reduce the risk of aspiration pneumonia (food or regurgitated material going down the airway).  This is often associated with nausea and vomiting due to the side-effects of anesthetic drugs.  New drugs (maropitant, Cerenia) can decrease nausea, reduce the risk of aspiration pneumonia, and have other benefits.  In most cases, water should NOT be withheld.  We want our patients well hydrated for surgery.  Animals with certain conditions and very young animals should not be fasted.
        </Typography>
        <Typography style={{fontWeight: "700"}}>
        Risk of Death
        </Typography>
        <Typography gutterBottom>
        The risks associated with anesthesia are lowered by careful planning and good monitoring, but serious risks still exist.  About 1 in 1000 cats and 1 in 2000 dogs that undergo anesthesia may have complications that lead to death.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Matthews et al.</Button> {refs ? " Factors associated with anesthetic-related death in dogs and cats in primary care veterinary hospitals. JAVMA. Mar 2017." : "" }</sup>
        </Typography>
        <Typography style={{fontWeight: "700"}}>
        Handouts for Pet Parents
        </Typography>
        <Typography><a href="https://petparenthandouts.com/anesthesia" target="_blank" rel="noopener noreferrer">Canine Anesthesia</a> from Pet Parent Handouts</Typography>
        <Typography><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=110707&id=9650698" target="_blank" rel="noopener noreferrer">How Does Anesthesia Work?</a> from Veterinary Partner</Typography>
        </AccordionDetails>
      </Accordion>

  );
}
