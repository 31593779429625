import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Cystotomy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cystotomy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <Typography>Cystotomy is indicated for removal of uroliths in cats and dogs when dissolution is not an option or in order to prevent obstruction/re-obstruction.</Typography>
              <ul>
                <li>During patient preparation, flush the prepuce or vulva/vaginal vault with saline followed by 1:40 dilution of povidone-iodine (betadine) solution. This will allow you to drape in the region and place or manipulate a urinary catheter.</li>
                <li>Make an incision from the umbilicus to the pubis.</li>
                  <ul>
                  <li>In male dogs, a parapreputial incision is often necessary for adequate exposure. You will have to address (ligate or cauterize) preputial vessels in the subcutis. If returning to midline from your parapreputial incision, which some surgeons prefer, identify and transect the preputialis muscle. Tag the preputialis muscle with suture to identify it later.</li>
                  <li>Alternatively, a paramedian approach has been described (off midline)</li>
                  </ul>
                <li>Separate the bladder from the abdomen with moistened laparotomy sponges.</li>
                <li>Place stay sutures (3-0) on the ventral apex and neck of the bladder to help manipulate it while causing minimal trauma. In a moment, additional stay sutures may be placed at each side of your incision to help open the lumen.</li>
                <li>Using a new (not your skin blade) scalpel blade (usually a 15 or 11 blade), make a stab incision into the bladder lumen. Extend this incision with Metzenbaum scissors. Avoid obvious vessels.</li>
                <li>Use suction to evacuate urine from the bladder.</li>
                <li>A bulb syringe can be used to flush and float stones out of the bladder. A cystotomy spoon may be used to retrieve stones, but any tools will irritate the bladder mucosa.</li>
                <ul><li>Save stones for analysis (free at University of Minnesota Urolith Center) and culture (can crush and submit with urine sample).</li></ul>
                <li>Thoroughly flush normograde and retrograde to ensure you have removed all stones. Some will pass a catheter both retrograde and then normograde. Can "feel" for stones as you pass the catheter back and forth.</li>
                <ul><li>Be thorough. You do not want to have to go back in after closure because you missed a small stone in the trigone!</li></ul>
                <li>Many suggest collecting a piece of bladder wall to submit for culture (can include in same tube as urine sample and crushed stone) if concern for UTI. Trim this small piece of tissue from along your incision.</li>
                <li>Close the bladder with 3-0 or 4-0 Monocryl suture. The bladder heals quickly and Monocryl performs better than PDS in acidic environments.</li>
                <ul>
                  <li>Single-layer closure with a simple continuous pattern is usually sufficient.</li>
                  <li>If a two-layer closure is necessary (thin or leaky bladder), consider simple continuous followed by Cushing (or Connell) or Cushing followed by Lembert.</li>
                </ul>
                <li>Leak test by filling with retrograde catheter. Add sutures if leaks detected.</li>
                 <ul><li>Some surgeons do not leak test.</li></ul>
                <li>Local lavage and suction before removing laparotomy sponges.</li>
                <li>Peritoneal lavage after replacing bladder in abdomen.</li>
                <li>Consider omental or serosal patch if needed, or simply wrap with omentum.</li>
                <li>Routine closure. Reattach ends of preputialis muscle if transected.</li>
                <li>Take a post-operative radiograph to ensure complete removal of all stones.</li>
              </ul>
              <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Postoperative Considerations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <ul>
                <li>Continue antibiotics if indicated (escalate or de-escalate based on culture results).</li>
                <li>Continued urethral catheterization is seldom recommended/warranted.</li>
                <li>Continue IV fluid therapy for diuresis.</li>
                <li>Expect bloody urine for a few days in up to 50% of cases; NOT frank hemorrhage.</li>
              </ul>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
