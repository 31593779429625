import React, { useState } from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import GIFBLlat1 from './images/GIFBLlat1.jpg';
import GIFBRlat1 from './images/GIFBRlat1.jpg';
import GIFBVD1 from './images/GIFBVD1.jpg';
import GIFBLlat2 from './images/GIFBLlat2.jpg';
import GIFBRlat2 from './images/GIFBRlat2.jpg';
import GIFBVD2 from './images/GIFBVD2.jpg';

export default function GifbRadiographs() {

  const [refs, setRefs] = useState(false);
  const [ref2, setRef2] = useState(false);


  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Criteria for small intestinal foreign body on radiographs include:
          </Typography>
          <ul>
          <li>
          Segmental dilation or a mix population of bowel (two populations of small intestine)
          </li>
          <li>
          Stacking of the GI track or tight "hair-pin" turns
          </li>
          <li>
          Decreased serosal detail or mesenteric stranding with peritoneal effusion (not pathognomonic)
          </li>
          <li>
          Always take both lateral projections.  In one study, taking multiple lateral projections increased sensitivity from 50% to 70% for mechanical obstructions in canine patients compared to taking only the single right lateral projection.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Ciasca et al.</Button> {refs ? " Does measurement of small intestinal diameter increase diagnostic accuracy of radiography in dogs with suspected intestinal obstruction? Vet Rad Ultrasound. 2013." : "" }</sup>
          </li>
          <li>
          Contrast agents can help in certain cases, but barium should never be given if concern for perforation. Abdominal ultrasound is preferred and has greater sensitivity than radiographs for detection of GI FB obstruction. Contrast agents should not be given if ultrasound will be pursued.
          </li>
          <li>
          In one study of vomiting dogs, radiography produced a definitive result (obstructed or not obstructed) in 58/82 (70%) of dogs; ultrasonography produced a definitive result in 80/82 (97%) of dogs.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>Sharma et al.</Button> {ref2 ? " Comparison of radiography and ultrasonography for diagnosing small-intestinal mechanical obstruction in vomiting dogs. Vet Rad Ultrasound. 2011." : "" }</sup>
          </li>
          <li>
          Consider repeat radiographs after fasting and medical therapy for 6-24 hours.
          </li>
          <li>
          Consider a pneumogastogram with 60 mL (less in patients under 5kg) of carbonated, noncaffeinated beverage PO. Do not perform if ultrasound pursued as gas shadowing from the stomach will result.
          </li>
          <li>
          Consider a pneumocolongram with 1-3 mL/kg air per rectum by red rubber if any question on which structure is the colon.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Case 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography>
                The stomach is mildly distended by gas and fluid. Left lateral view shows ill-defined soft tissue opaque material in the area of the pylorus. Intestinal plication is evidenced by the curved, irregular gas bubbles. There is mild segmental dilation of small intestine. Serosal detail is slightly reduced.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <img src={GIFBRlat1} alt="abdominal radiograph of patient with cloth gastric and small intestinal foreign body" style={{maxWidth: "96%"}}/>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <img src={GIFBVD1} alt="abdominal radiograph of patient with cloth gastric and small intestinal foreign body" style={{maxWidth: "96%"}}/>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <img src={GIFBLlat1} alt="abdominal radiograph of patient with cloth gastric and small intestinal foreign body" style={{maxWidth: "96%"}}/>
              </Paper>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Case 2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                <Typography>
                Case 2: Severe small intestinal dilation in a feline with a suspected mechanical obstruction.
                </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={GIFBRlat2} alt="abdominal radiograph of patient with small intestinal foreign body" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={GIFBVD2} alt="abdominal radiograph of patient with small intestinal foreign body" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={GIFBLlat2} alt="abdominal radiograph of patient with small intestinal foreign body" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
  );
}
