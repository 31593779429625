import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper, MenuItem } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [opioid, setOpioid] = useState("butorphanol_10");
  const [drugThree, setDrugThree] = useState("midazolam_5")

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="pet-name"
                  label="Name"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Drug (concentration)</TableCell>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Dose Range</TableCell>
              <TableCell colSpan={2} sx={{verticalAlign: "top", borderBottom: "none", paddingBottom: "0px"}}>Volume (mL)</TableCell>
            </TableRow>
            <TableRow sx={{borderTop:"none"}}>
              <TableCell align="left" sx={{paddingTop: "0px"}}>min</TableCell>
              <TableCell align="left" sx={{paddingTop: "0px"}}>max</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Alfaxalone (10 mg/mL)</TableCell>
            <TableCell align="left">2 - 3 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(2)/(10)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(3)/(10)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Opioid</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              <TextField
                id="select-opioid"
                select
                value={opioid}
                label=""
                size="small"
                onChange={(e) => setOpioid(e.target.value)}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={"butorphanol_10"}>
                  Butorphanol (10 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_2"}>
                  Hydromorphone (2 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_10"}>
                  Hydromorphone (10 mg/mL)
                </MenuItem>
                <MenuItem value={"methadone_10"}>
                  Methadone (10 mg/mL)
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">{(opioid==="butorphanol_10") ? "0.2 - 0.4 mg/kg"
                                    :(opioid==="hydromorphone_2") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="hydromorphone_10") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="methadone_10") ? "0.1 - 0.2 mg/kg"
                                    : ""}
            </TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.1)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    : ""}</TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.4)/10).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.2)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                  : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Midazolam or Dexmedetomidine</TableCell>
          </TableRow>
            <TableRow>
              <TableCell align="left">
                <TextField
                  id="select-drugThree"
                  select
                  value={drugThree}
                  label=""
                  size="small"
                  onChange={(e) => setDrugThree(e.target.value)}
                  variant="standard"
                  sx={{ml:0.5}}
                  inputProps={{sx: {fontSize: '0.875rem', p:0}}}
                >
                  <MenuItem value={"midazolam_5"}>
                    Midazolam (5 mg/mL)
                  </MenuItem>
                  <MenuItem value={"dexmedetomidine"}>
                    Dexmedetomidine (0.5 mg/mL)
                  </MenuItem>
                </TextField>
              </TableCell>
              <TableCell align="left">{(drugThree==="midazolam_5") ? "0.2 mg/kg"
                                      :(drugThree==="dexmedetomidine") ? "2-5 mcg/kg"
                                      : ""}
              </TableCell>
              <TableCell align="left">{(weightKg && drugThree==="midazolam_5") ? (weightKg*(.2)/5).toFixed(2)
                                      :(weightKg && drugThree==="dexmedetomidine") ? (weightKg*(0.002)/0.5).toFixed(2)
                                      : ""}</TableCell>
              <TableCell align="left">{(weightKg && drugThree==="dexmedetomidine") ? (weightKg*(0.005)/0.5).toFixed(2)
                                    : ""}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    IM Alfaxalone is off-label
    </li>
    <li>
    Maximum volume per injection site is 0.5 mL/kg
    </li>
    <li>
    Always be prepared to intubate
    </li>
    <li>
    Twitching, tremors, hyper-responsiveness to stimuli may be seen
    </li>
    <li>
    If used, dexmedetomidine can be reversed with an equal volume of atipamezole (Antisedan) IM
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
