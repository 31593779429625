import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export default function PreVisitGabaTraz() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>Drug (dose range)</TableCell>
            <TableCell align="center" colSpan={2}>Dose Range (mg)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">min</TableCell>
            <TableCell align="center">max</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>


        <TableRow>
          <TableCell align="center"><span style={{fontWeight: '900'}}>Trazodone</span> (5-7 mg/kg PO 1-2 hours prior to visit)</TableCell>
          <TableCell align="left">{(weightKg*(5)).toFixed(1)}</TableCell>
          <TableCell align="left">{(weightKg*(7)).toFixed(1)}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
          Generally dose closer to 5 mg/kg for larger dogs and 7 mg/kg for smaller dogs
        </li>
        <li>
         Tablets: 50, 100 mg
        </li>
        </ul>
        </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="center"><span style={{fontWeight: '900'}}>Gabapentin</span> (10-25 mg/kg 1-2 hours prior to visit)</TableCell>
          <TableCell align="left">{(weightKg*(10)).toFixed(2)}</TableCell>
          <TableCell align="left">{(weightKg*(25)).toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell align="left" colSpan={3}>
        <ul>
        <li>
          Decreases overall arousal and anxiety levels with few potential side effects
        </li>
        <li>
          Start the night before the visit if possible
        </li>
        <li>
         Liquid: 250 mg/5mL = 50 mg/mL; Capsules: 100 mg, 300 mg; Compounded tablets available for small dogs
        </li>
        </ul>
        </TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    This calculator is meant to double check your math.  It is not a substitute for calculating your patient's dose.  Always double check drug dosages and concentrations.
    </li>
    </ul>
    </Grid>
    </Grid>
    </>

  );
}
