import React, {useState} from 'react';

import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Urinalysis from '../ClinicalPathology/Urinalysis';

export default function Prostatitis() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Bacterial Prostatitis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Prostatitis should be assumed in any intact male dog with a urinary tract infection. Remember to look for stones and/or bladder/prostatic cancer which will complicate this.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Often present with UTI signs, but if unnoticed, may present with vomiting, anorexia, abdominal pain, and may progress to sepsis.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> 
        </Typography>
        <ul>
          <li><Urinalysis /></li>
          <ul><li>(with collection by cystocentesis)</li></ul>
          <li>
          Urine culture and sensitivity
          </li>
          <li>
          CBC/Chemistry - assess renal function, rule out other systemic diseases, assess white blood cell count
          </li>
          <li>
          Focused bladder ultrasound or abdominal ultrasound (look for stones, masses, ectopic ureters, prostate size and mineralization)
          </li>
          <li>
          Abdominal radiographs (to rule out radiopaque stones and evaluate prostate size/mineralization)
          </li>
          <ul><li>Intact dogs are unlikely to have prostatic neoplasia if no mineralization (whereas neutered dogs with mineralization very likely have prostatic neoplasia).<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Bradbury et al.</Button> {refs ? " Relationship Between Prostatomegaly, Prostatic Mineralization, and Cytologic Diagnosis. Vet Rad & Ultrasound 2009." : "" }</sup></li></ul>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment & Followup:</span> Must pick an antibiotic that penetrates the blood-prostate barrier. Enrofloxacin is usually the first choice. Some will require treatment for 4-8 weeks. Castration should be recommended. Recheck as a complicated UTI.
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
