import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FhvKeratoconjunctivitis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Herpesvirus Keratoconjunctivitis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
          Feline conjunctivitis and keratitis are often associated with or due to herpesvirus 1 (FHV).  Herpesvirus can cause partial thickness erosions not detected with fluorescein stain (Rose bengal may show these erosions).  For this reason, you should not use steroid-containing drops or ointments in most cases of feline conjunctivitis.  Erythromycin should be your preferred ointment for feline conjunctivitis and simple corneal ulcers.  Polymyxin B and Neomycin have been associated with anaphylaxis in cats and should not be prescribed.  Erythromycin provides coverage against Chlamydophila felis and Mycoplasma and common secondary bacteria.
        </Typography>
        <Typography>
          Inflammation of the cornea (keratitis) is unique to herpesvirus infection and helps differentiate it from Chlamydia infection.
        </Typography>
        <Typography>
          Conjunctival tissue or the third eyelid can become permanently adhered or scarred to the cornea itself.  This is termed 'symblepharon.'
        </Typography>
        <Typography>
        <a href="http://www.acvo.org/common-conditions-1/2018/2/2/herpes" target="_blank" rel="noopener noreferrer">Feline Ocular Herpes Handout</a> from the ACVO
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
