import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import NormalStifleRads from './NormalStifle';
import CrcldRads from './CrcldRads';

export default function StifleRadiographs() {

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Stifle Radiographs</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
        <Typography>
        Stifle radiograph positioning: Lateral stifle should have 90-degree angles at stifle and hock. Fabellas and femoral condyles should be superimposed. The craniocaudal view should only include one leg (not both) to properly center the beam over the stifle. The patella (if anatomically normal) should be positioned in the groove and the fabellas should be bisected.
        </Typography>
        </Grid>
        <NormalStifleRads />
        <CrcldRads />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
