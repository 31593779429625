import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import EarCytology from './EarCytology';

export default function OtitisExterna() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Otitis Externa/Ear Infection (Canine)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
          Waxy debris in the ear canal is a food source for bacteria and yeast overgrowth.  These are usually normal skin bacteria and yeast, but when they overgrow, they cause inflammation of the ear canal.  With inflammation, the normal migration of skin cells up and out of the L-shaped canal (termed "epithelial migration") is disrupted and slowed.  This leads to more waxy debris&mdash;more food for the growing party of yeast and/or bacteria.  If left untreated, the ear drum will be eroded and bacteria will set up shop in the middle ear.  This will make the infection harder to treat.  Rod-shaped bacteria (often Pseudomonas) are resistant to many of the medications we use to treat ear infections and can complicate the infection.  If the inflammation persists, the ear canal calcifies and is permanently narrowed.  End stage ears often need surgery (TECABO) to improve comfort.
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "700"}}>Predisposing Factors</TableCell>
                  <TableCell align="left">Conformation, Moisture, Iatrogenic and pet parent errors</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "700"}}>Primary Causes</TableCell>
                  <TableCell align="left">Allergy, Parasites, Cornification defects, Endocrinopathy, Immune-mediated (as in juvenile cellulitis), Foreign body, Trauma, Mass, Mucous production as in Primary Secretory Otitis Media</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "700"}}>Perpetuating Factors</TableCell>
                  <TableCell align="left">Bacteria, Yeast, Ongoing canal pathology (failure of epithelial migration, hyperplasia, stenosis), Otitis media</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography style={{fontSize: "0.7em"}}>August. Otitis externa: a disease of multifactorial etiology. Vet Clin NA Small Anim Pract. 1988.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Otoscopy, ear cytology, culture of external canal is almost never helpful, CT is helpful to assess middle ear</Typography>
        <EarCytology />
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span>
        </Typography>
        <ul>
        <li>
        Topical ear canal flush with Triz EDTA (works synergistically with antimicrobials).
        </li>
          <ul>
          <li>
          Consider Dechra TrizULTRA + Keto Flush for Dogs, Cats & Horses
          </li>
          <li>
          During treatment of infection: Use this ear flush 1-2 times each week.  Fill canal with fluid or apply with a saturated cotton ball and gently massage ear base.  Do not stick q-tips or similar into the ear.
          </li>
          <li>
          For maintenance flushing after treatment of infection: Use this ear flush every 1-2 weeks or after any swimming or bathing to clear debris and prevent yeast overgrowth.  Fill canal with fluid or apply with a saturated cotton ball and gently massage ear base.  Do not stick q-tips or similar into the ear.
          </li>
          </ul>
        <li>
        Ear medications:
        </li>
          <ul>
          <li>
          Consider Claro for dogs with yeast and/or cocci.  Swimming and bathing may reduce effectiveness.  Treats infection for 28 days. Recheck in 3-4 weeks to ensure resolution. Do not use maintenance flush during treatment course; instead, transition to maintenance flushing after resolution of infection confirmed by cytology.
          </li>
          <li>
          Consider Mometamax for dogs amenable to daily administration. Put 4-8 drops (a small squeeze) in affected ear once each day for 7 days until recheck appointment.  Effective for many infections with cocci and yeast.  Consider twice daily dosing for certain cases.
          </li>
          <li>
          Consider Posatex for dogs with yellow malodorous suppurative discharge and rods on cytology. Put 4-8 drops (a small squeeze) in affected ear once each day for 7 days until recheck appointment.  Pseudomonas rods tend to be harder to treat than and may be associated with chronic otitis or otitis media.  Treatment may be required for weeks to months.  Refer early if not improving.
          </li>
          </ul>
        <li>
        Systemic glucocorticoids:
        </li>
          <ul>
          <li>
          Will reduce pain and inflammation and help restore normal epithelial migration.
          </li>
          <li>
          Give 0.5 to 1 mg/kg by mouth every 12 hours for 4 days.  Then give by mouth every 24 hours for 4 days.  Then give by mouth every other day for 4 doses.  This is a steroid medication. May cause increased drinking, peeing, panting, hunger.  Do not combine with NSAID pain drugs.
          </li>
          </ul>
        <li>
        Systemic antimicrobials:
        </li>
          <ul>
          <li>
          May be considered based on middle ear/bulla culture. Continue 1-2 weeks beyond resolution.
          </li>
          <li>
          Will not treat the stuff in the wax in the external canal; topicals are best for this.
          </li>
          </ul>
        <li>
        Total Ear Canal Ablation and Bulla Osteotomy (TECABO)
        </li>
          <ul>
          <li>
          Appropriate for end-stage ears with ossified canals
          </li>
          <li>
          Painful cases of chronic otitis externa and media that are too difficult to continue to treat or manage medically are often better treated with TECABO
          </li>
          </ul>
        <li>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Ear Gemisch Recipe for select cases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
            <li>
            This is off-label for select cases that are refractory or end-stage but cannot see a dermatologist or go for TECABO. Do not use this if it will delay referral.
            </li>
            <li>
            10 mL Baytril, 10 mL Dex SP, 10 mL saline OR Triz EDTA 4 oz + 10 mL 100 mg/mL Baytril + 4 mL Dex SP. Administer in affected ear(s) twice each day for 10 days then every 2-3 days for maintenance.
            </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/ear-infections" target="_blank" rel="noopener noreferrer">Ear Infections Handout</a> <span style={{fontWeight: "500"}}>from PetParentHandouts.com</span>
        </Typography>
        <Typography>
        <a href="https://www.acvs.org/small-animal/otitis-externa" target="_blank" rel="noopener noreferrer">Otitis Externa Handout</a> <span style={{fontWeight: "500"}}>from the American College of Veterinary Surgeons</span>
        </Typography>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952082" target="_blank" rel="noopener noreferrer">Total Ear Canal Ablation and Bulla Osteotomy Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
