import React, { Component } from 'react';

import EmesisCalculatorContent from './EmesisCalculatorContent';

class EmesisCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Induction of Emesis</h1>
        <EmesisCalculatorContent />
      </>
    )
  }


};

export default EmesisCalculator;
