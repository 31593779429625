import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';

export default function MusculoskeletalDrugDoses() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [drugThree, setDrugThree] = useState("carprofen")

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }


  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Drug</TableCell>
              <TableCell align="left">Dose Range</TableCell>
              <TableCell align="left">Patient Dose</TableCell>
              <TableCell align="left">Frequency</TableCell>
              <TableCell align="left">Common Dosage Forms</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        <TableRow>
              <TableCell align="left">
                <TextField
                  id="select-drugThree"
                  select
                  value={drugThree}
                  label=""
                  size="small"
                  onChange={(e) => setDrugThree(e.target.value)}
                  variant="standard"
                  sx={{ml:0.5}}
                  inputProps={{sx: {fontSize: '0.875rem', p:0}}}
                >
                  <MenuItem value={"carprofen"}>
                    Carprofen
                  </MenuItem>
                  <MenuItem value={"meloxicam_15"}>
                    Meloxicam (1.5 mg/mL)
                  </MenuItem>
                  <MenuItem value={"meloxicam_05"}>
                    Meloxicam (0.5 mg/mL)
                  </MenuItem>
                </TextField>
              </TableCell>
              <TableCell align="left">{(drugThree==="carprofen") ? "2.2 mg/kg"
                                      :(drugThree==="meloxicam_15") ? "0.1 mg/kg"
                                      :(drugThree==="meloxicam_05") ? "0.1 mg/kg"
                                      : ""}
              </TableCell>
              <TableCell align="left">{(weightKg && drugThree==="carprofen") ? (weightKg*(2.2)).toFixed(2)+" mg"
                                      :(weightKg && drugThree==="meloxicam_15") ? (weightKg*(0.1)/1.5).toFixed(2)+" mL"
                                      :(weightKg && drugThree==="meloxicam_05") ? (weightKg*(0.1)/0.5).toFixed(2)+" mL"
                                      : ""}</TableCell>
              <TableCell align="left">{(weightKg && drugThree==="carprofen") ? "q12h"
                                      :(weightKg && drugThree==="meloxicam_15") ? "q24h"
                                      :(weightKg && drugThree==="meloxicam_05") ? "q24h"
                                      : ""}</TableCell>
              <TableCell align="left">{(weightKg && drugThree==="carprofen") ? "always round down; 25, 75, 100 mg scored tablets"
                                      :(weightKg && drugThree==="meloxicam_15") ? "always round down"
                                      :(weightKg && drugThree==="meloxicam_05") ? "always round down"
                                      : ""}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell align="left">Gabapentin</TableCell>
            <TableCell align="left">5-10 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(5)).toFixed(1)+" to "+(weightKg*(10)).toFixed(1)+" mg" : ""}</TableCell>
            <TableCell align="left">q8h</TableCell>
            <TableCell align="left">50 mg/mL solution, 100, 300, 600 mg capsules</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Amantadine</TableCell>
            <TableCell align="left">3-5 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(3)).toFixed(1)+" to "+(weightKg*(5)).toFixed(1)+" mg" : ""}</TableCell>
            <TableCell align="left">q24h</TableCell>
            <TableCell align="left">10 mg/mL solution, 100 mg tablets, 100 mg capsules</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>

  );
}
