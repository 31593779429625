import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Lipoma from './Lipoma';
import MastCellTumor from './MastCellTumorCanine';
import Hemangioma from './Hemangioma';
import Histiocytoma from './Histiocytoma';
import SebaceousAdenoma from './SebaceousAdenoma';

export default function SkinTumorsCanine() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Skin Tumors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Hemangioma />
          <Histiocytoma />
          <Lipoma />
          <MastCellTumor />
          <SebaceousAdenoma />
        </AccordionDetails>
      </Accordion>

  );
}
