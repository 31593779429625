import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HemoabKeyPoints from '../Notebook/Emergency/HemoabdomenKeyPoints';


class HemoabdomenQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Hemoabdomen</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Weak with pale mucous membranes, +/- distended abdomen, FF on aFAST</Typography>
            <Typography><span style={{fontWeight: '700'}}>Causes:</span> Ruptured hemangiosarcoma, rupture of other benign or malignant mass, blunt trauma, ruptured vessel secondary to splenic torsion or GDV, coagulopathy, anaphylaxis (and subsequent coagulopathy), post-surgical complication</Typography>

            <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span> FF on aFAST, diagnostic abdominocentesis will produce a hemorrhagic effusion with a PCV &ge;10% (usually will not clot; if clots, think acute hemorrhage),  Gallbladder wall edema should increase suspicion of anaphylaxis in the right patient, 3-view thoracic radiographs, abdominal ultrasound, CBC, chemistry, PT/PTT, blood type, ECG, blood pressure</Typography>

            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontWeight: '700'}}>Non-traumatic Hemoabdomen Key Points:</Typography>
              <HemoabKeyPoints />
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              label="IV catheter"
              inputProps={{ 'aria-label': 'IV catheter' }}
              /><span style={{fontWeight: '700'}}>Place IV catheter</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              label="label"
              inputProps={{ 'aria-label': 'label' }}
              /><span style={{fontWeight: '700'}}>Consider low volume resuscitation</span>
            </Grid>


            <Grid item xs={12}>
            <ul>
            <li>
            Consider crystalloid bolus at 10-20 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL</span> over 20-30 minutes.
            </li>
            <li>
            Consider autotransfusion if goals not met with crystalloid boluses.
            </li>
            <li>
            Prior to surgery, consider pRBCs at 10-15 mL/kg in patients with PCV &le;25%.
            </li>
            <li>
            If hemostatic derangements and concern for anaphylaxis, consider Fresh Frozen Plasma at 10 mL/kg over 2 hours.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              label="Abdominal Wrap"
              inputProps={{ 'aria-label': 'Abdominal Wrap' }}
              />Consider abdominal wrap
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="thoracic radiographs"
            inputProps={{ 'aria-label': 'thoracic radiographs' }}
            /><span style={{fontWeight: '700'}}>3-view thoracic radiographs</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="AUS"
            inputProps={{ 'aria-label': 'AUS' }}
            />Consider Abdominal Ultrasound
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Especially in large deep-chested dogs (where cranial liver is challenging to image), ultrasound findings may not match gross findings. A recent study suggests relatively poor sensitivity to detect liver and mesenteric masses.<sup>4</sup>
          </li>
          <li>
          Consider finances and time delay to surgery when recommending full abdominal ultrasound with a radiologist.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>In cases with suspected splenic mass, prepare for exploratory laparotomy</span>
          </Grid>



            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Yunnan Baiyao</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', paddingBottom: '10px'}}>
              Consider 1-2 capsules by mouth 1-2 times each day.  Small, red "emergency pill" in the center of foil packaging is given when significant shock or hemorrhage.
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102894&id=10105282#:~:text=Yunnan%20Baiyao%20is%20usually%20available,instead%20of%20the%20regular%20capsule." target="_blank" rel="noopener noreferrer">Yunnan Baiyao Handout</a> from Veterinary Partner
              </Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>I'm-Yunity (Coriolus versicolor)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', paddingBottom: '10px'}}>
              Consider 50 mg/kg twice each day with food.<sup>5</sup>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=4951872" target="_blank" rel="noopener noreferrer">Splenic Masses in Dogs (Splenectomy)</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>
            <Grid item xs={12}>
              <a href="https://www.acvs.org/small-animal/splenic-masses" target="_blank" rel="noopener noreferrer">Splenic Masses</a> <span style={{fontWeight: "500"}}> from ACVS</span>
            </Grid>
            <Grid item xs={12}>
              <a href="https://vsso.org/splenic-hsacanine" target="_blank" rel="noopener noreferrer">Splenic HSA - Canine</a> <span style={{fontWeight: "500"}}> from VSSO (target audience is veterinarians)</span>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p><sup>1</sup>Schick et al. Development and validation of a hemangiosarcoma likelihood prediction model in dogs presenting with spontaneous hemoabdomen: The HeLP score. JVECC 2019.</p>
                    <p><sup>2</sup>Aronsohn et al. Prognosis for Acute Nontraumatic Hemoperitoneum in the Dog: A Retrospective Analysis of 60 Cases (2003–2006). JAAHA 2009.</p>
                    <p><sup>3</sup>Hammond et al. Prevalence of hemangiosarcoma in anemic dogs with a splenic mass and hemoperitoneum requiring a transfusion: 71 cases (2003–2005) JAVMA 2008.</p>
                    <p><sup>4</sup>Cudney et al. Diagnostic utility of abdominal ultrasonography for evaluation of dogs with nontraumatic hemoabdomen: 94 cases (2014–2017). JAVMA 2021.</p>
                    <p><sup>5</sup>Cimino Brown and Reetz. Single Agent Polysaccharopeptide Delays Metastases and Improves Survival in Naturally Occurring Hemangiosarcoma. Evidence-Based Complimentary and Alternative Medicine 2012.</p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default HemoabdomenQS;
