import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Oxygen() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Oxygen</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Key points:</Typography>
            <ul>
              <li>
                Flow by oxygen or an oxygen tank/cage are appropriate for most patients with any evidence of respiratory distress at presentation. See <a href="/quick-sheets/pleural-effusion">Respiratory Distress Quick Sheet</a> for immediate management suggestions.
              </li>
              <li>
                For larger patients or when an oxygen tank is not available, cosider oxygen delivery via nasal catheter.
              </li>
              <ul>
                <li>Measure tube length to medial canthus of the eye. A 10 Fr multifenestrated catheter may improve comfort over an 8 Fr red rubber.<sup>2</sup></li>
                <li>
                  0.1 L/kg/min is a good starting point (some recommend 50-100 mL/kg/min) through a single nasal catheter and may acheive 27-50% FiO<sub>2</sub>.<sup>1,2</sup> Always use a humidifier.
                </li>
                <li>
                  Increased flow rates will dry the mucous membranes and may increase aerophagia. If more oxygen is needed, place a second nasal catheter (from a separate source, with separate humidifier).
                </li>
                <li>
                  Always use an E-collar.
                </li>
                <li>
                  Can be used for cats that require more oxygen support (use a 5-8 Fr).
                </li>
              </ul>
              <li>
                High-flow nasal oxygen is a great option (when available) for patients failing to improve or normalize with traditional oxygen delivery.
              </li>
              <li>
                Using a surgical ventilator or manual bagging of the patient are not alternatives to mechanical ventilation, but may be appropriate for short-term use in certain patients when a ventilator is not available.
              </li>
              <li>
                Oxygen toxicity is possible when providing &ge;40% oxygen for prolonged periods.
              </li>
            </ul>
          </Grid>
          

          <Grid item xs={12} style={{fontSize: "0.8rem"}}>
          <Typography style={{fontWeight: "700"}}>References:</Typography>
          <Typography>
            <sup>1</sup>Dunphy et al. Comparison of unilateral versus bilateral nasal catheters for oxygen administration in dogs. JVECC 2002.
          </Typography>
          <Typography>
            <sup>2</sup>Jagodich et al. Comparison of high flow nasal cannula oxygen administration to traditional nasal cannula oxygen therapy in healthy dogs. JVECC 2017.
          </Typography>
          </Grid>


        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
