import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Antimicrobials() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Antimicrobials</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Pick the right drug for the right pathogen that penetrates the right tissue and is right for your patient and their pet parent.</Typography>
            <Typography>  Ideally, your thoughtful approach includes culture and sensitivity testing to best direct selection.  Consider an "escalation" vs "de-escalation" strategy. If you choose a de-escalation strategy, don't forget to de-escalate when culture results support this. Remember the "90-60 Rule": that 90% of the time, susceptible infections respond to the antimicrobial, while 60% of the time "resistant" infections respond to the antimicrobial (even though tagged "resistant").<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Doern et al.</Button> {refs ? " The Clinical Predictive Value (or Lack Thereof) of the Results of In Vitro Antimicrobial Susceptibility Tests. J Clin Microbiol. Sep 2011." : "" }</sup> Susceptibility testing happens in vitro. The goal of antimicrobial therapy is to reduce the pathogen load to the point that the body can clear the infection.</Typography>
            <Typography>Think about source control, immune status of the patient, and disease process and progression.</Typography>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>Class</TableCell>
                <TableCell>Time or Conc. Dependent</TableCell>
                <TableCell>Common Drugs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Aminoglycosides</TableCell>
                <TableCell>Concentration</TableCell>
                <TableCell>Amikacin, Gentamicin</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Beta-Lactams</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Cephalosporins, Penicillins (Amoxi, Amoxi-clav, Ampicillin, Ampicilin/Sulbactam)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Chloramphenicol</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Chloramphenicol</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Fluoroquinolones</TableCell>
                <TableCell>Concentration</TableCell>
                <TableCell>Enrofloxacin, Marbo-, Prado-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Lincosamides</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Clindamycin</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Macrolides</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Azithromycin</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Potentiated Sulfas</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Sulfadiazine/trimethoprim (TMS)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: "700"}}>Tetracyclines</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Doxycycline</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>

          <Grid item xs={12}>
          <Typography style={{fontWeight: "700"}}>Further Reading:</Typography>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/vec.12842" target="_blank" rel="noopener noreferrer">Stewart and Allen. Antibiotic use in critical illness. JVECC. April 2019.</a>
          </Typography>
          </Grid>


        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
