import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Incontinence() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Incontinence (Urinary)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Micturition is complex. Dogs with urinary incontinence may have a range of symptoms and severity and different pet parents will have different goals when it comes to treating and preventing “accidents”. In female dogs, bladder position in the abdomen and urethral length will contribute to continence.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> These patients often present for inappropriate urination or suspected urinary tract infection (UTI). A focused history may reveal that the urination is accidental while they are sleeping or only sometimes “slips” out in the house and surprises everyone, patient included. Physical exam should include neurologic examination, rectal palpation, abdominal palpation (to feel bladder if possible), and visual exam of the perivulvar region (look for recessed vulva with perivulvar dermatitis). Vaginal palpation (be sterile) may be important for some if concern for vestibulovaginal septal remnant.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> UTIs can cause incontinence, but are also common in dogs with incontinence. Infection, urolithiasis, idiopathic cystitis (feline), cancer, kidney disease, other systemic disease causing polyuria and polydipsia may all contribute or present as an incontinence issue.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Underlying Disorders Contributing to Incontinence:</span> 
        </Typography>
        <ul>
          <li>
          Ectopic ureters (usually young female dogs)
          </li>
          <li>
          Urethral sphincter mechanism incompetence (UMSI)
          </li>
          <ul>
            <li>Usually presents years after OHE</li>
            <li>May be hormone responsive or not</li>
          </ul>
          <li>
          Paradoxical incontinence - overflow incontinence if obstructed or partially obstructed (usually due to urolithiasis)
          </li>
          <li>
          Neurologic UMN bladder or LMN bladder
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> Ultrasound commonly used to rule out ectopic ureter; abdominal radiographs are high-value as they rule out radiopaque stones and show positioning of bladder/urethral length (components of incontinence); Urinalysis by cystocentesis with culture and sensitivity; blood work (at least CBC/Chemistry)
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span> 
        </Typography>
        <ul>
          <li>Ectopic ureters</li>
          <ul>
            <li>Laser ablation or surgical transposition  (50% will still have incontinence issues, but many will improve)</li>
          </ul>
          <li>
            USMI
          </li>
          <ul>
            <li>Estriol - may be sole therapy (helps about 80%) or used with PPA</li>
            <li>Phenylpropanolamine (PPA) - may be sole therapy; monitor blood pressure</li>
          </ul>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
