import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import uveitisGif from './images/uveitisGif.gif';

export default function Uveitis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Uveitis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={uveitisGif} alt="uveitis" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>Aqueous flare highlighted with improvised slit beam (smallest circle of direct ophthalmoscope split in two).</div>
          </Paper>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          Uveitis may be anterior, posterior, or both (panuveitis). There are many potential underlying causes and a diagnostic workup is usually quite extensive and tailored to the individual patient. Some breeds are predisposed to idiopathic anterior uveitis.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          Common causes in dogs: Trauma, lens-induced, other immune-mediated disease, cancer, hyperlipidemia, idiopathic (in certain breeds), infectious (think tick-borne, protozoal, fungal, parasitic, other). This is not an exhaustive list.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          Common causes in cats: Trauma, cancer, lense induced (especially if lense capsule rupture follow cat claw), infections (FIV, FeLV, FIP, Toxoplasmosis, Bartonella, fungal, other)
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> Treat underlying cause. For the eye itself, treatment may include topical pred acetate (if no corneal ulceration) and systemic anti-inflammatory.
          </Typography>
          </Grid>          
          <Grid item xs={12}>
          <Typography>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=9116587" target="_blank" rel="noopener noreferrer">Uveitis in Dogs and Cats</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
