import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function PatellarLuxation() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Patellar Luxation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Patellar luxation, where the kneecap sits permanently or temporarily out of place, is a common reason for hindlimb lameness.  It pulls boney structures out of place over time and contributes to other orthopedic conditions affecting the hip and knee.
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Grade</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">I</TableCell>
                <TableCell align="left">Patella can be pushed out of groove, but then returns on own</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">II</TableCell>
                <TableCell align="left">Occasionally out of groove, can pop it back in</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">III</TableCell>
                <TableCell align="left">Usually out of groove, can push it back in but it usually falls out again</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">IV</TableCell>
                <TableCell align="left">Stuck out of groove and cannot be pushed back in</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Patellar luxation is most common in small breed dogs. It is usually medial, but can be lateral. Your orthopedic evaluation should include palpation and manipulation of the patella, and dogs with patellar luxation should have thorough stifle evaluation as they are more likely to have cranial cruciate disease.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
        Patients with grade I luxations that are asymptomatic can often be managed conservatively with rest and medications when painful or lame. For grades II - IV, surgical correction should be considered. Four surgical procedures can be considered (usually in combination): deepening the trochlear groove, moving the tibial tuberosity over, loosening the soft tissue on the tight side (usually the medial side), tightening the soft tissue on the loose side.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/patellar-luxations" target="_blank" rel="noopener noreferrer">Patellar Luxations Handout</a> from the American College of Veterinary Surgeons
        </Typography>

        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952566" target="_blank" rel="noopener noreferrer">Medial Luxating Patella in Dogs Handout</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
