import * as React from 'react';
import { TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FetalAge() {
  return (

    <Accordion>

    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Approximating Fetal Age</Typography>
    </AccordionSummary>
    <AccordionDetails>

      <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Radiographic Aging</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Structure</TableCell>
                <TableCell align="center">Mean (Day)</TableCell>
                <TableCell align="center">Range (Days)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Spherical uterine swellings</TableCell>
                <TableCell align="center">35</TableCell>
                <TableCell align="center">31 - 38</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Ovoid uterine swellings</TableCell>
                <TableCell align="center">41</TableCell>
                <TableCell align="center">38 - 44</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Skull mineralization</TableCell>
                <TableCell align="center">45</TableCell>
                <TableCell align="center">43 - 46</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Scapula, humerus, femur</TableCell>
                <TableCell align="center">48</TableCell>
                <TableCell align="center">46 - 51</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Radius, ulna, tibia</TableCell>
                <TableCell align="center">52</TableCell>
                <TableCell align="center">50 - 53</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Pelvis and all ribs</TableCell>
                <TableCell align="center">54</TableCell>
                <TableCell align="center">53 - 59</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Coccygeal vertebrae, fibula, calcaneus, distal extremities</TableCell>
                <TableCell align="center">61</TableCell>
                <TableCell align="center">55 - 64</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Teeth</TableCell>
                <TableCell align="center">61</TableCell>
                <TableCell align="center">58 - 63</TableCell>
              </TableRow>
            </TableBody>
          </Table>
      </TableContainer>
      <Typography>Reference: Lopate. Estimation of gestational age and assessment of canine fetal maturation using radiology and ultrasonography: A review. Theriogenology. 2008.</Typography>
      </AccordionDetails>
      </Accordion>

      <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Ultrasonographic Aging</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Feature</TableCell>
              <TableCell align="center">Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Heartbeat</TableCell>
              <TableCell align="center">25 - 26</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Bladder</TableCell>
              <TableCell align="center">35 - 39</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Stomach</TableCell>
              <TableCell align="center">36 - 39</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Lungs more hyperechoic than liver</TableCell>
              <TableCell align="center">38 - 42</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Liver more hyperechoic than other abdominal organs</TableCell>
              <TableCell align="center">39 - 47</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Eyes and Kidney</TableCell>
              <TableCell align="center">39 - 47</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Intestinal peristalsis</TableCell>
              <TableCell align="center">62 - 64</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography>Reference: Lopate. Estimation of gestational age and assessment of canine fetal maturation using radiology and ultrasonography: A review. Theriogenology. 2008.</Typography>
      </AccordionDetails>
      </Accordion>


</AccordionDetails>
</Accordion>

  );
}
