import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BrachialPlexus() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Brachial Plexus Avulsion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
            Usually present following trauma +/- shoulder luxation. If pain perception is present, partial or complete return to function is possible. Absent pain perception means return to function is unlikely.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Self-mutilation (associated with paresthesia) or failure to return to function may warrant amputation of the limb.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=11112576" target="_blank" rel="noopener noreferrer">Brachial Plexus Avulsion in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
