import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.acvs.org/" target="_blank" rel="noopener noreferrer">American College of Veterinary Surgeons</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aspcapro.org/training-spayneuter-alliance-training/spayneuter-library" target="_blank" rel="noopener noreferrer">ASPCA Spay/Neuter Mentorship Materials</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.youtube.com/playlist?list=PLH6HdpinGr_ayR6hGTRtgGtJ9l7_DX57f" target="_blank" rel="noopener noreferrer">ASPCA Spay/Neuter YouTube Playlist</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
