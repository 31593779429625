import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import FelineAsthmaRLat from './images/felineAsthma_RLat.jpg';
import FelineAsthmaLLat from './images/felineAsthma_LLat.jpg';
import FelineAsthmaVD from './images/felineAsthma_VD.jpg';

export default function ChfRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Feline asthma radiographs show bronchial pattern with "donuts and railroad tracks".
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={FelineAsthmaRLat} alt="thoracic radiograph of patient with feline asthma" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={FelineAsthmaVD} alt="thoracic radiograph of patient with feline asthma" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={FelineAsthmaLLat} alt="thoracic radiograph of patient with feline asthma" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
