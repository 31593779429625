import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DystociaUltrasound from './dialogs/DystociaUltrasound';
import CSection from '../Notebook/Surgery/CSection';

class DystociaQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Dystocia & C-section (Canine)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}  style={{textDecoration: 'underline'}}>
              Dystocia
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12}>
           Breeders often do not know the conception date (ovulation often lags behind onset of standing heat). Average length of pregnancy is 63 days, but actual conception may be up to 5 days after last breeding. Gestation length &ge; 68 days with no known conception date may be concerning.
           </Grid>
           <Grid item xs={12}>
           Causes of maternal dystocia include, but are not limited to, primary and secondary uterine inertia and obstruction. Malpresentation is the most common cause of fetal dystocia.
           </Grid>
           <Grid item xs={12}>
           Fetal and neonatal cardiac output is almost entirely dependent on heart rate.  Fetal heart rate should be &ge;180 bpm; &le; 160 bpm is a surgical emergency.<sup>1</sup>
           </Grid>
           <Grid item xs={12}>
           <span style={{fontWeight: '700'}}>Clues supporting dystocia:</span>
           <ul>
            <li><span style={{fontWeight: '700'}}>Green vulvar discharge</span> (uteroverdin, signifies placental separation) &ge; 10 min before first puppy is delivered or any bloody/foul discharge</li>
            <li><span style={{fontWeight: '700'}}>&ge; 24 hours since Stage I</span> (or since a recorded temperature drop to 98-99F)</li>
            <li><span style={{fontWeight: '700'}}>Persistent straining for 20-60 minutes</span> without delivery</li>
            <li><span style={{fontWeight: '700'}}>Mild or intermittent contractions for 2-3 hours without delivery of a neonate</span></li>
            <li><span style={{fontWeight: '700'}}>&ge; 1 hour between delivery</span> of subsequent neonates</li>
            <li>Puppy or sac protruding from vulva for &ge; 15 minutes</li>
            <li>Palpation of a mispositioned fetus in the birth canal</li>
            <li>A weak or absent Ferguson reflex may support uterine inertia</li>
            <li><span style={{fontWeight: '700'}}>Fetal heart rate &le; 180 bpm</span> is a sign of fetal distress; <span style={{fontWeight: '700'}}>&le; 160 bpm is a surgical emergency</span></li>
            <li><span style={{fontWeight: '700'}}>Any sign of fetal or maternal distress</span> - DO NOT DELAY SURGERY</li>
            </ul>
          </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Vaginal Examination</span>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Point of Care Ultrasound</span>
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li><span style={{fontWeight: '700'}}>Fetal heart rate &le; 180 bpm</span> is a sign of fetal distress; <span style={{fontWeight: '700'}}>&le; 160 bpm is a surgical emergency</span></li>
        <li>Intestinal peristalsis can usually be appreciated by 62-64 days and may help support that fetuses are ready for delivery</li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <DystociaUltrasound/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>i-STAT CHEM 8+</span> or similar, <span style={{fontWeight: '700'}}>CBC/PCV/TS</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Attention to blood glucose and iCa<sup>+</sup> in cases of suspected uterine inertia</Typography>

            </AccordionSummary>
            <AccordionDetails>
            <ul>
            <li>
            If hypoglycemia, consider 0.5 to 1 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1)).toFixed(2)} mL of 50% dextrose diluted 1:2 or 1:4 prior to administration.  Repeat as needed. May be followed with 2.5% or 5% dextrose in IV fluids.
            </li>
            <li>
            If hypocalcemia, consider 50-150 mg/kg = {(this.state.weightkg*(50)/(100)).toFixed(2)} mL to {(this.state.weightkg*(150)/(100)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV (give slowly)
            </li>
            </ul>
            </AccordionDetails>
            </Accordion>
            </li>


        <li>Consider urine dipstick to assess for ketones in cases of possible pregnancy toxemia; Serum ketones may also be detected using urine dipstick; beta-hydroxybutyrate is not detected on dipsticks
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Abdominal Radiographs</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        May help confirm pregnancy and rule out pseudocyesis (false pregnancy), determine number and size of expected neonates, or confirm that all fetuses have been delivered
        </li>
        <li>
        Can help rule out or diagnose fetal oversize and malposition
        </li>
        <li>
        Fetal dentition becomes apparent at 58-63 days and may help support that fetuses are ready for delivery
        </li>
        </ul>
        </Grid>

      </Grid>
      </AccordionDetails>
      </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid item xs={12} style={{fontWeight: '700'}}>
            Calcium Gluconate and Oxytocin
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
        <Grid container spacing={1}>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider Calcium gluconate administration</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Administer to increase the strength of myometrial contractions
        </li>
        <li>
        Consider in cases of secondary uterine inertia or documented hypocalcemia
        </li>
        <li>
        Often dosed at 22 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(22)/(100)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV (give slowly) or SC for this patient
        </li>
        <li>
        If hypocalcemia is found, consider 50-150 mg/kg = {(this.state.weightkg*(50)/(100)).toFixed(2)} mL to {(this.state.weightkg*(150)/(100)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate IV (give slowly)
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider Oxytocin administration</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Administer to increase the frequency of myometrial contractions
        </li>
        <li>
        Likely overused; Causes placental separation, constriction of umbilical vessels, subsequent fetal hypoxemia, and <span style={{fontWeight: '700'}}>contributes to fetal stress</span>; Recheck fetal HR after administration; Risk of uterine rupture if fetal dystocia or maternal obstruction
        </li>
        <li>
        If confirmed dystocia and no Ferguson reflex, go directly to c-section; there will be no response to Oxytocin
        </li>
        <li>
        Big doses are bad; give 0.25 to 2.0 Units (often 10 U/mL) per dam (smaller dose for smaller dams) SC or IM, can repeat dose once in 30-60 minutes if not contraindicated and no signs of fetal stress or maternal distress
        </li>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12}>
          <CSection />
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12} style={{fontWeight: '700'}}>
              Neonatal Resuscitation
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
          <Grid container spacing={1}>

        <Grid item xs={12}>
        <ul>
        <li>
        Do not swing neonates
        </li>
        <li>
        Rub vigorously to stimulate
        </li>
        <li>
        Provide oxygen
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Reverse any opioid drugs
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Only use in cases where opioid was given to the dam
        </li>
        <li>
        Give 0.1 mg/kg = 0.25 mL/kg of naloxone (0.4 mg/mL)
        </li>
        <li>
        Can give as drop under tongue, titrate to effect. IV is preferred; subcutaneous or intramuscular are acceptable
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Doxapram administration is controversial and many clinicians feel strongly that it should not be used routinely; it will increase oxygen consumption and decrease cerebral and myocardial oxygen.
        </Grid>

        </Grid>
        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
        Medications to consider sending home following c-section:
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
        *Consider the risks/benefits of any medications and possible effects on the neonates especially if nursing
        </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <span style={{textDecoration: 'underline'}}>Gabapentin</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
        <ul>
        <li>
          5-10 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(1)).toFixed(1)} mg</span> q8-12h
        </li>
        <li>
          Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
        </li>
          Can round up dose and/or increase depending on patient response
        </ul>
        </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <span style={{textDecoration: 'underline'}}>Non-steroidal anti-inflammatory drug (NSAID)</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          Be mindful of the risk to neonatal health if nursing.  Frequently prescribed, often as single injectable dose; limited evidence to support safety in nursing neonates.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <span style={{textDecoration: 'underline'}}>Antibiotic</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          Rarely indicated if ideal surgical setting and peritoneal contamination was avoided. Further research is needed to identify risk factors associated with post surgery metritis and bacterial infection.<sup>3</sup>
        </Grid>


        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.uwsheltermedicine.com/library/resources/canine-guide-to-raising-orphan-puppies" target="_blank" rel="noopener noreferrer">Canine: Guide to Raising Unweaned Puppies</a> <span style={{fontWeight: "500"}}>from University of Wisconsin</span>
        </Grid>

        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951456" target="_blank" rel="noopener noreferrer">Orphan Puppy and Kitten Care</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Discharge Note (Key Points):
        </Grid>
        <Grid item xs={12}>
          <ul>
          <li>
            Vaginal discharge may be normal for next 4-6 weeks.  Recheck promptly if this discharge has a foul smell or if your pet is feeling unwell in any way.
          </li>
          <li>
            A high quality puppy food should be fed to the mother while nursing puppies.
          </li>
          <li>
            Your dog has undergone major abdominal surgery and needs close supervision and strict rest to limit the risk of complications.
          </li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Smith. Guide to Emergency Interception During Parturition in the Dog and Cat. Vet Clin Small Anim 2012.</p>

                <p><sup>2</sup>Goericke-Pesch et al. Bacteriological findings in the canine uterus during Caesarean section performed due to dystocia and their correlation to puppy mortality at the time of parturition. Reprod Dom Anim 2018.</p>

                <p><sup>3</sup>Goericke-Pesch et al. Bacteriological findings in the canine uterus during Caesarean section performed due to dystocia and their correlation to puppy mortality at the time of parturition. Reprod Dom Anim 2018.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
        </Grid>


            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default DystociaQS;
