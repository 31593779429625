import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Psyllium from '../Notebook/InternalMedicine/Psyllium';

export default function Constipation() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Constipation (Feline)</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Sometimes passing firm small feces or unsuccessful attempts seen by pet parent; many present for vomiting, reduced appetite, and lethargy; often dehydrated; on physical exam, can palpate firm, impacted feces in the colon</Typography>
          <Typography><span style={{fontWeight: '700'}}>Ddx:</span> Lower urinary tract disease, colitis, other anorectal disease</Typography>
          <Typography><span style={{fontWeight: '700'}}>Underlying causes:</span>  Idiopathic megacolon; obstruction; neurologic disease; dehydration (often secondary to CKD or other systemic disease); certain drugs; previous pelvic fracture; environment/behavior/pain</Typography>
          <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span></Typography>
          <ul  style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Abdominal Radiographs - confirm and assess severity
            </li>
            <li>
            CBC/Chemistry
            </li>
            <li>
            UA
            </li>
            <li>
            T4
            </li>
            <li>
            FIV/FeLV if unknown
            </li>
            <li>
            Abdominal ultrasound - if any concern for masses
            </li>
            <li>
            Colonoscopy - if any concern for strictures, diverticula, or masses; contrast studies or pneumocolonogram are infrequently performed alternatives
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>IV fluid resuscitation</span>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            See <Link to={'/calculators/feline-fluids'}>Feline Fluids Calculator</Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Consider PEG solution (GoLYTELEY or CoLyte)</span>
          <Typography sx={{pl: '20px'}}>via NG tube at 6-10 mL/kg/hr {weightkg ? `= ${(weightkg*6).toFixed(2)} to ${(weightkg*10).toFixed(2)} mL/hr` : "" } for 6-24 hours (average dose of 80 mL/kg)<sup>1</sup></Typography>
          <ul style={{marginTop: 0}}>
            <li>
            Median time to significant defecation was 8 hours (range 5-24 hours)
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Consider Enema</span>
          <ul style={{marginTop: 0}}>
            <li>
            Warm water enema - 5-10 mL/kg {weightkg ? `= ${(weightkg*5).toFixed(0)} to ${(weightkg*10).toFixed(0)} mL` : "" }
            </li>
            <ul style={{marginTop: 0}}>
              <li>
              Can cause vomiting; best done under anesthesia (with ET tube in place) or given as several small enemas slowly
              </li>
              <li>
              Can add water-soluble lubricant
              </li>
            </ul>
            <li>
            Fleet (phosphate) enemas are toxic to cats; these are available OTC for humans and sometimes attempted by unknowing pet parents
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Occasionally, careful manual extraction</span> under general anesthesia after fluid rehydration
          <ul style={{marginTop: 0}}>
            <li>
            This can usually be avoided if PEG solution administered by NG tube
            </li>
            <li>
            Constipation is a sign of cellular dehydration regardless of underlying cause; do NOT pull feces from colon without rehydrating first
            </li>
            <li>
            May require repeating the procedure if not able to reach full extent of impacted feces
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Surgery (such as subtotal colectomy)</span> should be a last resort for intractable megacolon
          <ul style={{marginTop: 0}}>
            <li>
            Subtotal colectomy was associated with long survival times and a high rate of owner satisfaction<sup>2</sup>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
        Medications to consider:
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Psyllium />
          <ul style={{marginTop: '5px'}}>
            <li>
            Also found in Hill’s Biome Gastrointestinal and Royal Canin Gastrointestinal Fiber Response prescription diets
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography sx={{textDecoration: 'underline', paddingLeft: '12px'}}>MiraLAX (polyethylene glycol 3350)</Typography>
          <Typography sx={{paddingLeft: '12px'}}>1/8 to 1/4 teaspoon q12h titrated to effect from low end</Typography>
          <Typography sx={{paddingLeft: '12px'}}>Lactulose is an alternative at 0.5 mL/kg q8-12h</Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography sx={{textDecoration: 'underline', paddingLeft: '12px'}}>Cisapride (compounded)</Typography>
          <Typography sx={{paddingLeft: '12px'}}>0.5 mg/kg {weightkg ? `= ${(weightkg*0.5).toFixed(2)} mg` : ""} PO q8-12h</Typography>
          <Typography sx={{paddingLeft: '12px'}}>Prokinetic 5-HT<sub>4</sub> agonist</Typography>
          <Typography sx={{paddingLeft: '12px'}}>Started for cases of idiopathic megacolon once constipation has resolved</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handout:
        </Grid>
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951509#In%20Summary" target="_blank" rel="noopener noreferrer">Constipation and Megacolon in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Garr and Gaunt. Constipation resolution with administration of polyethylene-glycol solution in cats. JVIM 2010.</p>

                <p><sup>2</sup>Grossman et al. Evaluation of outcomes following subtotal colectomy for the treatment of idiopathic megacolon in cats. JAVMA 2021.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
        <IconButton
          component={Link}
          size="large"
          color="primary"
          aria-label="back"
          aria-controls="back"
          aria-haspopup="false"
          to="/quick-sheets"
        >
          <ArrowBack />
        </IconButton>
      </Grid>
    </Grid>
  </>
  )
};