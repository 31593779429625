import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Ckd() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Chronic Kidney Disease</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Chronic Kidney Disease (CKD) may affect 30-40% of cats over 10 years age; it is much less common in dogs.  Chronicity generally means &ge; 3 months. Azotemia alone does not mean chronic kidney disease. It is important to look for pre-renal (e.g. dehydration) and post-renal causes or more treatable/reversible conditions (pyelonephritis, renal urolithiasis).  In chronic kidney disease, the functional unit of the kidney (the neprhon) has been replaced by fibrous scar tissue.  The damage is irreversible and adaptive mechanisms may actually worsen or accelerate kidney damage.  Our goal is to slow progression of disease.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Comorbidities (such as heart disease, hyperthyroidism, and hyperaldosteronism in cats) should be investigated.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://journals.sagepub.com/doi/full/10.1177/1098612X16631234" target="_blank" rel="noopener noreferrer">ISFM Consensus - Feline CKD</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Creatinine, BUN, SDMA, Calcium, Phosphorus, Potassium, Urinalysis (for USG), Urine Protein to Creatinine Ratio (UPCR), Urine Culture, Blood pressure (for hypertension), and PCV (for anemia) should all be monitored every 1-6 months depending on the patient.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Ultrasonography to evaluate the kidneys and help rule out pyelonephritis and renal urolithiasis should be considered.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Treatment recommendations are based on IRIS staging guidelines and tailored to the individual patient.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="http://www.iris-kidney.com/guidelines/recommendations.html" target="_blank" rel="noopener noreferrer">IRIS Treatment Recommendations for CKD</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>Problem</TableCell>
                <TableCell>Management considerations</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Dehydration</TableCell>
                <TableCell align="left">Fresh water always, IV fluid therapy, SC fluid therapy (often 75-150 mL q1-3 days at home)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Hypertension</TableCell>
                <TableCell align="left">Amlodipine, ACEIs (ACEI monotherapy not effective for most hypertensive cats), Telmisartan</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Proteinuria</TableCell>
                <TableCell align="left">ACEI, prescription renal diet</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Hyperphosphatemia</TableCell>
                <TableCell align="left">Prescription renal diet, phosphate binder (Aluminum Hydroxide, Calcium Carbonate)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Hypokalemia</TableCell>
                <TableCell align="left">Oral supplementation with potassium gluconate or poassium citrate</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Anemia</TableCell>
                <TableCell align="left">Erythrocyte-stimulating agents (recombinant human epoetin alfa, darbepoetin)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="left">Feeding tube interventions, dialysis, and renal transplantation may be appropriate for some, but not most, patients.</TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951452" target="_blank" rel="noopener noreferrer">CKD in Dogs and Cats</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://petparenthandouts.com/ckd" target="_blank" rel="noopener noreferrer">CKD (feline)</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
