import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import FetalAge from './FetalAge';
import Dystocia from './Dystocia';
import Pyometra from './Pyometra';
import AdditionalResources from './AdditionalResources';
import Ohe from '../Surgery/Ohe';
import Castration from '../Surgery/Castration';

export default function Repro() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <FetalAge />
        <Castration />
        <Dystocia />
        <Ohe />
        <Pyometra />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
