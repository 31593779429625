import React, { useState } from 'react';
import { Button } from '@mui/material';

export default function HemoabKeyPoints() {
  const [refs, setRefs] = useState(false);
  const [ref2, setRef2] = useState(false);
  const [ref3, setRef3] = useState(false);

  return (
            <ul>
            <li>Of dogs with nontraumatic hemoabdomen, a bleeding splenic mass is the most likely (86% in recent study).<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>1. Schick et al.</Button> {refs ? " Development and validation of a hemangiosarcoma likelihood prediction model in dogs presenting with spontaneous hemoabdomen: The HeLP score. JVECC 2019." : "" }</sup> This is especially true in large-breed dogs.</li>
            <li>
              66-76% of bleeding splenic masses are malignant cancer (92% of these are hemangiosarcoma) and have already spread.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>2, 3</Button> {ref2 ? " 2. Aronsohn et al. Prognosis for Acute Nontraumatic Hemoperitoneum in the Dog: A Retrospective Analysis of 60 Cases (2003–2006). JAAHA 2009. & 3. Hammond et al. Prevalence of hemangiosarcoma in anemic dogs with a splenic mass and hemoperitoneum requiring a transfusion: 71 cases (2003–2005) JAVMA 2008." : "" }</sup>
            </li>
            <li>
              In cases of hemangiosarcoma, MST with splenectomy alone is 1-3 months. Chemotherapy (usually doxorubicin) may increase survival time by a few months.
            </li>
            <li>
              Prognosis is much better in cases of benign splenic lesions and surgery can be curative.
            </li>
            <li>
              Of dogs that undergo exploratory laparotomy and splenectomy, 63% of dogs with hemangiosarcoma survive to discharge, 87% of dogs with hematoma survive to discharge.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef3(!ref3)}>3. Hammond et al.</Button> {ref3 ? " Prevalence of hemangiosarcoma in anemic dogs with a splenic mass and hemoperitoneum requiring a transfusion: 71 cases (2003–2005) JAVMA 2008." : "" }</sup>
            </li>
            </ul>
  );
}
