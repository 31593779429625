import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../lib/errorLib";
import DynamicChecklist from "../../components/DynamicChecklist";
import { Container, Stack, TextField, Grid, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { API } from "aws-amplify";

export default function NewNote() {
  const [petName, setPetName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signalment, setSignalment] = useState("");
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [inHospital, setInHospital] = useState(true);
  const [petWeight, setPetWeight] = useState(null);
  const [admitDate, setAdmitDate] = useState(null);
  const [criticalNotes, setCriticalNotes] = useState("");
  const [healthHistory, setHealthHistory] = useState("");
  const [problemList, setProblemList] = useState("");
  const [differentials, setDifferentials] = useState("");
  const [petDiagnostics, setPetDiagnostics] = useState("");
  const [currentMedsTreatments, setCurrentMedsTreatments] = useState("");
  const [petPlan, setPetPlan] = useState("");
  const [checklist, setChecklist] = useState([]);
  const [checklistEntry, setChecklistEntry] = useState("");


  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return petName.length > 0 && signalment.length > 0;
  }

  function checklistToString(checklist) {
    // [[ string, bool ],[ string, bool ]...]
    // becomes 'string|bool]|[string|bool'
    // pipes replace commas, drop start and end brackets
    let a = []
    for (let i=0; i < checklist.length; i++) {
      a = a.concat(checklist[i].join('|'));
    }
    return a.join(']|[');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let stringChecklist = checklistToString(checklist);
    if (checklistEntry.trim().length !== 0) {
      stringChecklist = checklistToString(checklist.concat([[checklistEntry, false]]));
    }
    setIsLoading(true);

    try {
      await createNote({ petName,
                         lastName,
                         signalment,
                         petWeight,
                         chiefComplaint,
                         inHospital,
                         admitDate,
                         criticalNotes,
                         healthHistory,
                         problemList,
                         differentials,
                         petDiagnostics,
                         currentMedsTreatments,
                         petPlan,
                         stringChecklist,
                       });
      navigate("/notes");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Container sx={{paddingBottom: '16px'}}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="flex-start">
          <Grid item xs={4} sm={4} md={5}>
            <TextField
              required
              inputProps={{
                spellCheck: false,
              }}
              id="petName"
              value={petName}
              label="Pet Name"
              type="text"
              fullWidth
              onChange={(e) => setPetName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={5}>
            <TextField
              inputProps={{
                spellCheck: false,
              }}
              id="lastName"
              value={lastName}
              label="Last Name"
              type="text"
              fullWidth
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={5}>
            <TextField
              id="petWeight"
              value={petWeight ? petWeight : ""}
              label="Weight (kg)"
              type="number"
              fullWidth
              onChange={(e) => setPetWeight(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={5}>
            <DateTimePicker
               renderInput={(params) => <TextField fullWidth {...params} />}
               label="Admit Date & Time"
               value={admitDate}
               onChange={(newAdmitDate) => {
                 setAdmitDate(newAdmitDate);
               }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={inHospital} onChange={(e) => setInHospital(e.target.checked)} inputProps={{ 'aria-label': 'in hosptial'}} />} label="In Hospital" />
            </FormGroup>
          </Grid>
        </Grid>
        <TextField
          inputProps={{
            spellCheck: false,
            sx: {},
          }}
          id="signalment"
          value={signalment}
          label="Signalment"
          type="text"
          required
          onChange={(e) => setSignalment(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="chiefComplaint"
          value={chiefComplaint}
          label="Chief Complaint"
          type="textarea"
          onChange={(e) => setChiefComplaint(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="criticalNotes"
          value={criticalNotes}
          label="Critical Notes"
          type="textarea"
          onChange={(e) => setCriticalNotes(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="healthHistory"
          value={healthHistory}
          label="History"
          type="textarea"
          onChange={(e) => setHealthHistory(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="problemList"
          value={problemList}
          label="Problem List"
          type="textarea"
          onChange={(e) => setProblemList(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="differentials"
          value={differentials}
          label="Differentials"
          type="textarea"
          onChange={(e) => setDifferentials(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="diagnostics"
          value={petDiagnostics}
          label="Diagnostics"
          type="textarea"
          onChange={(e) => setPetDiagnostics(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="currentMedsTreatments"
          value={currentMedsTreatments}
          label="Current Medications & Treatments"
          type="textarea"
          onChange={(e) => setCurrentMedsTreatments(e.target.value)}
        />
        <TextField
          inputProps={{
            spellCheck: false,
          }}
          multiline
          id="plan"
          value={petPlan}
          label="Plan"
          type="textarea"
          onChange={(e) => setPetPlan(e.target.value)}
        />

        <DynamicChecklist checklist={checklist} setChecklist={setChecklist} checklistEntry={checklistEntry} setChecklistEntry={setChecklistEntry}/>

        <LoaderButton type="submit" variant="contained" isLoading={isLoading} disabled={!validateForm()}>
          Create
        </LoaderButton>
      </Stack>
    </Container>
    </LocalizationProvider>

  );
}
