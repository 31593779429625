import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DysphoriaAceDose from '../../Calculators/DysphoriaAceDose';


export default function PhasesAnesthesia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Phases of Anesthesia</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Pre-anesthesia</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              This phase includes the initial history, physical examination, laboratory tests. Any medical problems should be addressed. About 15 minutes before induction, pre-anesthetic drugs are given to reduce anxiety, sedate the patient, and provide additional pain relief. These drugs also reduce the amount of anesthetic drugs needed, which helps minimize side effects and improves the recovery phase.
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>ASA Physical Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">I</TableCell>
                    <TableCell align="left">normal healthy patient</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">II</TableCell>
                    <TableCell align="left">mild systemic disease</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">III</TableCell>
                    <TableCell align="left">severe systemic disease</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">IV</TableCell>
                    <TableCell align="left">severe systemic disease that is a constant threat to life</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">V</TableCell>
                    <TableCell align="left">moribund, will not survive without operation</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">E</TableCell>
                    <TableCell align="left">added to ASA PS in emergency surgeries when delaying treatment would add significant increase in threat to life or body part</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </AccordionDetails>
            </Accordion>

            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Induction</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            This phase includes intubation, where an endotracheal tube is placed to protect the airway and allow the use of anesthetic gases. At induction, injectable drugs (often propofol or alfaxalone) or inhalants are used until the patient is relaxed enough to allow intubation. These drugs should be given slowly to effect. Secure the endotracheal tube in place by tying it around the jaw or behind the ears. The cuff should be inflated only to the point that anesthetic gas does not escape around it (listen at the mouth). Overinflating the cuff on the endotracheal tube will put more pressure on the fragile cells that line the trachea and will lead to additional tracheitis (inflammation of the trachea) that may persist after anesthesia. Always listen to ensure both lungs have good lung sounds when the patient is given a breath. Over advancing the endotracheal tube can lead to ventilation of only one lung. Many of the monitoring devices can be hooked up at this phase.
            </Typography>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Induction Agent</TableCell>
                    <TableCell align="left">Dose (mg/kg)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Propofol</TableCell>
                    <TableCell align="left">2-6</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Alfaxalone</TableCell>
                    <TableCell align="left">1-4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Etomidiate</TableCell>
                    <TableCell align="left">1-3</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Ketamine + Midazolam</TableCell>
                    <TableCell align="left">2-5 + 0.1-0.4</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Maintenance</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            During this phase, inhalants (anesthetic gas) or injectable drugs (as in Total Intravenous Anesthesia or TIVA) are used to keep the patient unconscious. Monitoring is key. A table of the Minimum Alveolar Concentration (MAC) for several anesthetic gasses has been included below. This concentration, at sea level, prevents 50% of patients from making concious movement. Many factors will influence the required concentration in an individual patient, so careful monitoring and frequent reassessment are key.
            </Typography>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Inhalant</TableCell>
                    <TableCell align="left">MAC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Isoflurane</TableCell>
                    <TableCell align="left">1.3</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Sevoflurane</TableCell>
                    <TableCell align="left">2.34</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Recovery</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            During this phase, the patient returns to consciousness. Continued monitoring is key to a successful recovery. Patients may suddenly show signs of pain or dysphoria. Dysphoric patients may vocalize and thrash. Many of the drugs that treat pain will not treat dysphoria. Tranquilizers or sedatives may be needed if the patient is dysphoric. Patients may be affected by the anesthetic drugs for 12 or more hours after recovery. They should be confined to a safe space with supervision to prevent self-injury.  Acepromazine, dexmedetomidine, propofol, or alfaxalone may be administered to dysphoric patients.
            </Typography>
            <DysphoriaAceDose/>
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
