import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';

import NotebookNotes from '../NotebookNotes';

import Learning from './Learning';
import CanineBehavior from './CanineBehavior';
import FelineBehavior from './FelineBehavior';
import AdditionalResources from './AdditionalResources';
import PreVisitDrugs from './PreVisitDrugs';

export default function Behavior() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Learning/>
        <CanineBehavior />
        <FelineBehavior />
        <PreVisitDrugs />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
