import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableBody, TableCell,TableHead, TableRow } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Psyllium from '../Notebook/InternalMedicine/Psyllium';

class AHDSQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      dehydration: '8',
      shock: '0',
      fluidrate: '0',
      k: '0',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handledehydrationChange = this.handledehydrationChange.bind(this);
    this.handleshockChange = this.handleshockChange.bind(this);
    this.handlefluidrateChange = this.handlefluidrateChange.bind(this);
    this.handlekChange = this.handlekChange.bind(this)
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handledehydrationChange(event) {
    this.setState({
      dehydration: event.target.value,
    });
}
  handlekChange(event) {
    this.setState({
      k: event.target.value,
    });
}

handlefluidrateChange(event) {
this.setState({
  fluidrate: event.target.value,
});
}
handleshockChange(event) {
  this.setState({
    shock: event.target.value,
  });
}


  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Acute Hemorrhagic Diarrhea Syndrome (AHDS, HGE)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom><span style={{fontWeight: '700'}}>Presentation:</span> Perfuse bloody diarrhea with a PCV &ge;60%. Total protein is usually within the reference range. Dehydration is common. AHDS was previously termed hemorrhagic gastroenteritis (HGE).</Typography>
            <Typography><span style={{fontWeight: '700'}}>Key points for communication:</span></Typography>
            <ul>
            <li>AHDS is a diagnosis of exclusion. Rule out parvoviral enteritis, intestinal foreign body, metabolic derangements, endocrine disease, and parasitism.
            </li>
            <li>Treatment is symptomatic and may require 1-2 days of hospitalization for IV fluid therapy and GI support.
            </li>
            <li>Antibiotics have fallen out of favor and are usually only indicated if degenerative left shift or concern for sepsis. Antibiotics may cause further dysbiosis in most cases of acute diarrhea.
            </li>
            </ul>
            <Typography><span style={{fontWeight: '700'}}>Initial diagnostics may include:</span></Typography>
            <ul>
            <li>Parvo SNAP
            </li>
            <li>CBC, Chemistry (+ electrolytes)
            </li>
            <li>PVC/TS
            </li>
            <li>Abdominal imaging (3v abdominal radiographs or abdominal ultrasound)
            </li>
            <li>Fecal ova +/- Giardia
            </li>
            <li>Cortisol (baseline &ge;2-2.5 rules out hypoadrenocorticism)
            </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
            </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Consider a fluid bolus of 10 to 20 mL/kg = {(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed.
            </li>
            <TextField
              id="dehydration"
              type="number" value={this.state.dehydration} onChange={this.handledehydrationChange}
              label="% dehydration"
              margin="normal"
              variant="outlined"
              InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>,}}
              />
            <li>
            Maintenance rate (exponential formula) = {((this.state.weightkg**(0.75))*132/24).toFixed(0)} mL/hr; Fluid deficit of {this.state.dehydration}% = {(this.state.weightkg*(this.state.dehydration)*10).toFixed(0)} mL
            </li>
            <li>
            Subtract shock fluids given during initial stabilization
            </li>
            <TextField
              id="shock"
              type="number" value={this.state.shock} onChange={this.handleshockChange}
              label="Shock fluids given"
              margin="normal"
              variant="outlined"
              InputProps={{endAdornment: <InputAdornment position="end">mL</InputAdornment>,}}
              />
            <li>
            If correcting {this.state.dehydration}% dehydration over 12-24 hours, appropriate fluid rate ranges from {(((this.state.weightkg**(0.75))*70/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/24)).toFixed(0)} mL/hr to {(((this.state.weightkg**(0.75))*70/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/12)).toFixed(0)} mL/hr
            </li>
            <li>
            Account for and add additional losses (such as vomiting and diarrhea) to your fluid plan
            </li>
            <li>
            If no concurrent heart disease, consider correcting {this.state.dehydration}% dehydration over 6 hours; appropriate fluid rate may approach {(((this.state.weightkg**(0.75))*70/24)+((this.state.weightkg*(((this.state.dehydration)*10))-this.state.shock)/6)).toFixed(0)} mL/hr
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Manage electrolyte abnormalities</span>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Potassium
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Serum K<sup>+</sup> (mEq/L)</TableCell>
                              <TableCell align="left">mEq/L at maintenance rate</TableCell>
                              <TableCell align="left">Alternatively, mEq/kg/hr</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">3.5 - 5</TableCell>
                              <TableCell align="left">20</TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">3.0 - 3.4</TableCell>
                              <TableCell align="left">30</TableCell>
                              <TableCell align="left">0.1 - 0.15</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">2.5 - 2.9</TableCell>
                              <TableCell align="left">40</TableCell>
                              <TableCell align="left">0.2 - 0.25</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">2 - 2.4</TableCell>
                              <TableCell align="left">60</TableCell>
                              <TableCell align="left">0.3 - 0.35</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">&le;1.9</TableCell>
                              <TableCell align="left">80 - 100</TableCell>
                              <TableCell align="left">0.4 - 0.5</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">Maximum</TableCell>
                              <TableCell align="left">0.5 mEq K<sup>+</sup>/kg/hr</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span style={{textDecoration: 'underline'}}>K</span><sup>+</sup><sub>max</sub> <span style={{textDecoration: 'underline'}}>Calculator</span>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Grid item xs={12}>
                            <TextField
                              id="fluidrate"
                              type="number" value={this.state.fluidrate} onChange={this.handlefluidrateChange}
                              label="Fluid Rate"
                              margin="normal"
                              variant="outlined"
                              InputProps={{endAdornment: <InputAdornment position="end">mL/hr</InputAdornment>,}}
                              />
                          </Grid>
                          <Grid item xs={12}>
                              <TextField
                                id="k"
                                type="number" value={this.state.k} onChange={this.handlekChange}
                                label="K+"
                                margin="normal"
                                variant="outlined"
                                InputProps={{endAdornment: <InputAdornment position="end">mEq/L</InputAdornment>,}}
                                />
                          </Grid>
                          <Grid item xs={12}>
                            With {this.state.k} mEq/L supplemental K<sup>+</sup> at {this.state.fluidrate} mL/hr <span style={{backgroundColor: '#fff2ac'}}>you are administering {((this.state.k)/1000*(this.state.fluidrate)/(this.state.weightkg)).toFixed(2)} mEq K<sup>+</sup>/kg/hr</span>.  If this is greater than 0.5 mEq K<sup>+</sup>/kg/hr, you are in the danger zone.
                          </Grid>
                          <Grid item xs={12}>
                          <ul>
                          <li>
                          Remember, many fluids already have some amount of K<sup>+</sup> (such as LRS and Phylyte)
                          </li>
                          <li>
                          Consider using 2 fluid pumps with the second running at maintenance to correct electrolytes
                          </li>
                          </ul>
                          </Grid>
                      </AccordionDetails>
                    </Accordion>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              label="Anesthesia"
              inputProps={{ 'aria-label': 'Anesthesia' }}
              /><span style={{fontWeight: '700'}}>Consider maropitant.</span>
            </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Give 1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL maropitant IV for this patient.
            </li>
            </ul>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider fiber and probiotics once eating</span>
          </Grid>

          <Grid item xs={12}>
            <Psyllium />
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>Pectin is another common prebiotic/soluble fiber source that supports intestinal flora. It can be used in addition to psyllium.
          </li>
          <li>Many canine-specific probiotics are available as powders or capsules.
          </li>
          <li>Pumpkin is not a great fiber source (not much concentrated fiber, limited benefit relative to psyllium).
          </li>
          <li>Sweet potato is not a fiber source; it's more a source of carbohydrates.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Antibiotics are generally only indicated if concern for SIRS/Sepsis</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider Amoxicillin and/or Metronidazole in select cases.
          </li>
          <li>
          This Quick Sheet does not include guidance on treating SIRS/sepsis. Do not delay antibiotic administration if any concern for sepsis.
          </li>
          </ul>
          </Grid>


            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Fenbendazole (Panacur)</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours to treat intestinal parasites.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              50 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(50)/(1)).toFixed(1)} mg</span> q12h
            </li>
            <li>
              Often 100 mg/mL suspension or as granules (22.2%, 222 mg/g)
            </li>
            </ul>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=4952857" target="_blank" rel="noopener noreferrer">Acute Hemorrhagic Diarrhea Syndrome (AHDS or HGE)</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item xs={12}>
                  References:
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <p>Unterer et al. Treatment of Aseptic Dogs with Hemorrhagic Gastroenteritis with Amoxicillin/Clavulanic Acid: A Prospective Blinded Study. JVIM 2011..</p>
                    <p>Dupont et al. A retrospective study of 237 dogs hospitalized with suspected acute hemorrhagic diarrhea syndrome: Disease severity, treatment, and outcome. JVIM 2021.</p>
                    <p>Langlois et al. Metronidazole treatment of acute diarrhea in dogs: A randomized double blinded placebo-controlled clinical trial. JVIM 2019.</p>
                    <p>Pilla et al. Effects of metronidazole on the fecal microbiome and metabolome in healthy dogs. JVIM 2020.</p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default AHDSQS;
