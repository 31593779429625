import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FelineAlfaxCalculator from '../../Calculators/FelineAlfaxCalculator';

export default function FelineAlfax() {
  return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="feline-alfax-content"
            id="feline-alfax-header"
          >
            <Typography>Alfaxalone combination</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FelineAlfaxCalculator/>
          </AccordionDetails>
        </Accordion>
  );
}
