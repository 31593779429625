import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper, MenuItem } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [adult, setAdult] = useState(true);
  const [ace, setAce] = useState("ace_10");
  const [opioid, setOpioid] = useState("butorphanol_10");


  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  function handleAdultChange(event) {
    setAdult(event.target.value)
  }


  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="pet-name"
                  label="Name"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
            id="select-adult-feline"
            select
            value={adult}
            label=""
            margin="normal"
            size="small"
            onChange={handleAdultChange}
          >
            <MenuItem value={true}>
              Adult
            </MenuItem>
            <MenuItem value={false}>
              Pediatric (&le;6 mo)
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Drug (concentration)</TableCell>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Dose Range</TableCell>
              <TableCell colSpan={2} sx={{verticalAlign: "top", borderBottom: "none", paddingBottom: "0px"}}>Volume (mL)</TableCell>
            </TableRow>
            <TableRow sx={{borderTop:"none"}}>
              <TableCell align="left" sx={{paddingTop: "0px"}}>min</TableCell>
              <TableCell align="left" sx={{paddingTop: "0px"}}>max</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          {adult
            ?
          <TableRow>
            <TableCell align="left">
              <TextField
                id="select-ace"
                select
                value={ace}
                label=""
                size="small"
                onChange={(e) => setAce(e.target.value)}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={"ace_10"}>
                  Acepromazine (10 mg/mL)
                </MenuItem>
                <MenuItem value={"ace_one"}>
                  DILUTE Acepromazine (1 mg/mL)
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">{(ace==="ace_10") ? "0.02 - 0.05 mg/kg"
                                    :(ace==="ace_one") ? "0.02 - 0.05 mg/kg"
                                    : ""}
            </TableCell>
            <TableCell align="left">{(weightKg && ace==="ace_10") ? (weightKg*(0.02)/10).toFixed(2)
                                    :(weightKg && ace==="ace_one") ? (weightKg*(0.02)/1).toFixed(2)
                                    : ""}</TableCell>
            <TableCell align="left">{(weightKg && ace==="ace_10") ? (weightKg*(0.05)/10).toFixed(2)
                                    :(weightKg && ace==="ace_one") ? (weightKg*(0.05)/1).toFixed(2)
                                  : ""}</TableCell>
          </TableRow>
          :
          <TableRow>
            <TableCell align="left">
              <TextField
                id="select-ace"
                select
                value={ace}
                label=""
                size="small"
                onChange={(e) => setAce(e.target.value)}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={"ace_10"}>
                  Acepromazine (10 mg/mL)
                </MenuItem>
                <MenuItem value={"ace_one"}>
                  DILUTE Acepromazine (1 mg/mL)
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">{(ace==="ace_10") ? "0.025 mg/kg"
                                    :(ace==="ace_one") ? "0.025 mg/kg"
                                    : ""}
            </TableCell>
            <TableCell align="left" colSpan={2}>{(weightKg && ace==="ace_10") ? (weightKg*(0.025)/10).toFixed(3)
                                    :(weightKg && ace==="ace_one") ? (weightKg*(0.025)/1).toFixed(2)
                                    : ""}</TableCell>
          </TableRow>
        }
        {adult
        ?
          <TableRow>
            <TableCell align="left">
              <TextField
                id="select-opioid"
                select
                value={opioid}
                label=""
                size="small"
                onChange={(e) => setOpioid(e.target.value)}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={"butorphanol_10"}>
                  Butorphanol (10 mg/mL)
                </MenuItem>
                <MenuItem value={"buprenorphine"}>
                  Buprenorphine (0.3 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_2"}>
                  Hydromorphone (2 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_10"}>
                  Hydromorphone (10 mg/mL)
                </MenuItem>
                <MenuItem value={"methadone_10"}>
                  Methadone (10 mg/mL)
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">{(opioid==="butorphanol_10") ? "0.2 - 0.4 mg/kg"
                                    :(opioid==="buprenorphine") ? "0.01 - 0.02 mg/kg"
                                    :(opioid==="hydromorphone_2") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="hydromorphone_10") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="methadone_10") ? "0.1 - 0.2 mg/kg"
                                    : ""}
            </TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="buprenorphine") ? (weightKg*(0.01)/0.3).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.1)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    : ""}</TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.4)/10).toFixed(2)
                                    :(weightKg && opioid==="buprenorphine") ? (weightKg*(0.02)/0.3).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.2)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                  : ""}</TableCell>
          </TableRow>
          :
          <TableRow>
            <TableCell align="left">
              <TextField
                id="select-opioid"
                select
                value={opioid}
                label=""
                size="small"
                onChange={(e) => setOpioid(e.target.value)}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={"butorphanol_10"}>
                  Butorphanol (10 mg/mL)
                </MenuItem>
                <MenuItem value={"buprenorphine"}>
                  Buprenorphine (0.3 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_2"}>
                  Hydromorphone (2 mg/mL)
                </MenuItem>
                <MenuItem value={"hydromorphone_10"}>
                  Hydromorphone (10 mg/mL)
                </MenuItem>
                <MenuItem value={"methadone_10"}>
                  Methadone (10 mg/mL)
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">{(opioid==="butorphanol_10") ? "0.2 - 0.3 mg/kg"
                                    :(opioid==="buprenorphine") ? "0.01 - 0.02 mg/kg"
                                    :(opioid==="hydromorphone_2") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="hydromorphone_10") ? "0.1 - 0.2 mg/kg"
                                    :(opioid==="methadone_10") ? "0.1 - 0.2 mg/kg"
                                    : ""}
            </TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="buprenorphine") ? (weightKg*(0.01)/0.3).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.1)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.1)/10).toFixed(2)
                                    : ""}</TableCell>
            <TableCell align="left">{(weightKg && opioid==="butorphanol_10") ? (weightKg*(0.3)/10).toFixed(2)
                                    :(weightKg && opioid==="buprenorphine") ? (weightKg*(0.02)/0.3).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_2") ? (weightKg*(0.2)/2).toFixed(2)
                                    :(weightKg && opioid==="hydromorphone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                    :(weightKg && opioid==="methadone_10") ? (weightKg*(0.2)/10).toFixed(2)
                                  : ""}</TableCell>
          </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    Can combine in one syringe
    </li>
    <li>
    Always be prepared to intubate
    </li>
    <li>
    Do not use acepromazine in stressed or fractious pets (vasodilatory effect may cause profound hypotension)
    </li>
    <li>
    Do not use if suspicion of subaortic stenosis (left basilar murmur)
    </li>
    <li>
    Boxers, sighthounds and ABCB1-positive (MDR1) dogs may require lower doses of acepromazine
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
