import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JaundiceFeline from '../Notebook/InternalMedicine/JaundiceFeline';

export default function HepaticLipidosis() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Hepatic Lipidosis</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> inappetence, weight loss, gastrointestinal signs; some have weakness, cervical ventroflexion, ptyalism; about 70-83% present with jaundice<sup>1, 2</sup></Typography>
          </Grid>

          <Grid item xs={12}>
            <JaundiceFeline />
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Key Points:</span></Typography>
          <ul>
            <li>
            Hepatic lipidosis is often secondary to something else - often pancreatitis, cholangiohepatitis, or gastrointestinal disease (IBD, lymphoma), “triaditis” is the combination (pancreatitis, cholangiohepatitis, IBD); some will have diabetes mellitus, hyperthyroidism, cardiomyopathy, or other systemic disease
            </li>
            <li>
            Full recovery may take 2-3 months
            </li>
            <li>
            Decrease in total bilirubin by 50% within 7-10 days is associated with survival<sup>1</sup>
            </li>
            <li>
            Decreasing serum beta-hydroxybutyrate in hospital is associated with survival<sup>2</sup> (monitor serum ketones)
            </li>
            <li>
            Reported mortality rates are variable with one recent study suggesting 38% mortality<sup>2</sup>
            </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span></Typography>
          <ul>
            <li>
              CBC
            </li>
            <li>
            Chemistry - ALP increase&gt;ALT increase, think lipidosis
            </li>
              <ul>
                <li>
                Monitor K+, Phos, and Mg2+ daily - all will go low with refeeding syndrome (hypophosphatemia is the classic)
                </li>
              </ul>
            <li>
              Serum Ketones
            </li>
            <li>
            Blood smear
            </li>
            <li>
            UA - ketones often present (increased ketone body formation in catabolic state with insulin resistance and decreased glucose tolerance)
            </li>
            <li>
            AUS - important to rule out EHBO and neoplasia early in treatment course
            </li>
            <li>
            FeLV/FIV - associated with feline hemotropic mycoplasma and lymphoma cancer
            </li>
            <li>
            fPL - pancreatitis is a leading cause of hepatic lipidosis
            </li>
            <li>
            Liver aspirate - can help rule out lymphoma cancer
            </li>
          </ul>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Fluid therapy</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
            <li>
            See <Link to={'/calculators/feline-fluids'}>Feline Fluids Calculator</Link>
            </li>
            <li>
            Avoid Dextrose (insulin resistance and glucose intolerance leads to hyperglycemia)
            </li>
            <li>
            Supplement K+, Mg, and Phosphorus as needed
            </li>
            <li>
            If hypophosphatemia, consider a CRI of 0.01 to 0.03 mmol/kg/hr = {(weightkg*(.01)/3).toFixed(2)} to {(weightkg*(.03)/3).toFixed(2)} mL of 3 mmol/mL KPhos; higher rates may be needed if severe hypophosphatemia
            </li>
            <li>
            If hypomagnesemia, give 0.75 mEq/kg/day as CRI = {(weightkg*0.75/24/1.97).toFixed(2)} mL/hr of 20% Magnesium Chloride (1.97 mEq/mL)
            </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Antiemetics"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Antiemetics and gastrointestinal prokinetics</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Maropitant (Cerenia, 10 mg/mL for injection or tablets PO)</TableCell>
                  <TableCell align="left">Antiemetic, NK<sub>1</sub>R antagonist</TableCell>
                  <TableCell align="left">1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span> IV or SC q24h, or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span> PO</TableCell>
                  <TableCell align="left">May also provide visceral analgesia<sup>3</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Ondansetron (2 mg/mL solution; 4 mg and 8 mg tablets available)</TableCell>
                  <TableCell align="left">Antiemetic, 5HT<sub>3</sub> antagonist</TableCell>
                  <TableCell align="left">0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3/2)).toFixed(2)} mL</span> IV or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3)).toFixed(1)} mg</span> PO q8-12h</TableCell>
                  <TableCell align="left">Can use in conjunction with maropitant</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Metoclopramide (5 mg/mL solution; 5 mg and 10 mg tablets available)</TableCell>
                  <TableCell align="left">Prokinetic, dopamine antagonist at low doses</TableCell>
                  <TableCell align="left">2 mg/kg/day CRI = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2/5/24)).toFixed(2)} mL/hr</span><br/>0.2 - 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2/5)).toFixed(2)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5/5)).toFixed(2)} mL</span> SC<br/>or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2)).toFixed(1)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO TID</TableCell>
                  <TableCell align="left">Some advise against as dopamine antagonism may decrease perfusion of the pancreas; weak anti-emetic; contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cisapride (compounded)</TableCell>
                  <TableCell align="left">Prokinetic, 5HT< sub>4</sub> agonist</TableCell>
                  <TableCell align="left">0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO q8-12h </TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Erythromycin</TableCell>
                  <TableCell align="left">Prokinetic</TableCell>
                  <TableCell align="left">0.5 to 1 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span></TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>   
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Pain management"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Pain management</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Methadone (10 mg/mL)</TableCell>
                  <TableCell align="left">0.2 - 0.3 mg/kg IM or IV q4-8 hours = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2/10)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3/10)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Fentanyl (0.05 mg/mL)</TableCell>
                  <TableCell align="left">3-5 ug/kg bolus followed by 3-5 ug/kg/hr = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(3/50)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5/50)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Buprenorphine (0.3 mg/mL)</TableCell>
                  <TableCell align="left">0.02 mg/kg IV = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.02/0.3)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Maropitant<sup>3</sup> (10 mg/mL)</TableCell>
                  <TableCell align="left">1 mg/kg IV, SC q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Gabapentin</TableCell>
                  <TableCell align="left">10-20 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(10)).toFixed(0)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(20)).toFixed(0)} mg</span></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Nutritional support"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Early enteral nutrition</span>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={{fontWeight: '700'}}>Oral assisted feeding</TableCell>
                  <TableCell align="left">Treatments should be done with cat out of the kennel and from behind (not approaching from the face); 1 mL is oral capacity of an adult cat, so feed 1 mL or less at a time; If done incorrectly, this will create food aversion</TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left" style={{fontWeight: '700'}}>Nasogastric (NG) or nasoesophageal (NE) feeding tube</TableCell>
                  <TableCell align="left">3-5 Fr; can be uncomfortable and disrupt normal eating; limited to liquid diets<br/>            
                  <a href="https://www.youtube.com/watch?v=6Uemr4UaA_w&ab_channel=VetLogic" target="_blank" rel="noopener noreferrer">Placeing a naso-oesophageal tube and feeding (YouTube)</a> from VetLogic
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left" style={{fontWeight: '700'}}>Esophagostomy feeding tube (E-tube)</TableCell>
                  <TableCell align="left">10-16 Fr; great option; may need to delay placement for 1-2 days based on patient<br/>Do NOT use mouth gag during placment<br/>
                  <a href="https://www.youtube.com/watch?v=qF14Jfajkhw&ab_channel=MILAInternational%2CInc" target="_blank" rel="noopener noreferrer">MILA Retrograde E Tube Placement (YouTube)</a> from MILA International<br/>
                  <a href="https://www.youtube.com/watch?v=ddJ3jtRIqYw&ab_channel=SherinShah" target="_blank" rel="noopener noreferrer">Esophagostomy Tube Placement in Cats (YouTube)</a> from Sherin Shah<br/>
                  <a href="https://www.youtube.com/watch?v=n1jLCXTII6I&ab_channel=atDove" target="_blank" rel="noopener noreferrer">Esophagostomy Tube Placement in a Cat (YouTube)</a> from atDove<br/>
                  <a href="https://www.youtube.com/watch?v=h0km9T35rIw&t=371s&ab_channel=JorgensenLaboratories%2CInc." target="_blank" rel="noopener noreferrer">Esophagostomy Tube Insertion Device (J1083)</a> from Jorgensen Laboratories
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            High protein, low carbohydrate is best; eating anything is better than eating nothing             
            </li>
            <li>Start at 1/4 to 1/3 RER and increase by 1/4 to 1/3 each day</li>
          </ul>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">¼ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅓ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">½ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/2).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅔ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*2/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">¾ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*3/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">RER = 70 x (kg)^0.75</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))).toFixed(0)} kcal/day</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Appetite stimulants"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Consider appetite stimulant</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Mirtazapine</TableCell>
                  <TableCell align="left">¼ of a 7.5 mg tablet (1.875 mg) PO; 5HT<sub>3</sub> antagonist (so may make ondansetron less effective); transdermal (Mirataz) is alternative to tablets</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Capromorelin (Elura, 20 mg/mL)</TableCell>
                  <TableCell align="left">2 mg/kg PO q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2)/20).toFixed(1)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Gabapentin<sup>4</sup></TableCell>
                  <TableCell align="left">5 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5)).toFixed(0)} mg</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cyproheptadine</TableCell>
                  <TableCell align="left">Not great for critically ill cats, tastes bad, can reverse adverse effects of mirtazapine; 1 to 4 mg/cat PO q24h</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Appetite stimulants"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Start supplements early</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Vitamin K (10 mg/mL)</TableCell>
                  <TableCell align="left">0.5 to 1 mg/kg q12h SC = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)/10).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)/10).toFixed(2)} mL</span></TableCell>
                  <TableCell align="left">Especially important to start early if considering E-tube or liver aspirates</TableCell>
                </TableRow>
                <TableRow>
                 <TableCell align="left">Vit B complex supplementation</TableCell>
                  <TableCell align="left">1-2 mL/L in IV fluid bag</TableCell>
                  <TableCell align="left">Especially if neck ventroflexion with normal electrolytes (thiamine deficiency suspected)</TableCell>
                </TableRow>
                <TableRow>
                 <TableCell align="left">B12 (Cyanocobalamin)</TableCell>
                  <TableCell align="left">250 ug SC q7 days <span style={{backgroundColor: '#fff2ac'}}>0.25mL</span> of 1000ug/mL Vitamin B<sub>12</sub> Injection</TableCell>
                  <TableCell align="left">Needed for lipoprotein synthesis</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">L-carnitine</TableCell>
                  <TableCell align="left">250 to 400 mg PO q24h</TableCell>
                  <TableCell align="left">Carnitor or generic available as 100mg/mL or 330 mg tablets; can be compounded; important for fatty acid metabolism</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">N-acetylcysteine (20%)</TableCell>
                  <TableCell align="left">140 mg/kg once then 70 mg/kg q6h for 6 doses<br/>
                   140 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(140)/200).toFixed(0)} mL</span><br/>
                   70 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(70)/200).toFixed(0)} mL</span><br/>
                   </TableCell>
                  <TableCell align="left">Thiol donor; SAMe (in Denamarin) is an alternative, BID divided dosing preferred</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Taurine</TableCell>
                  <TableCell align="left">250-500 mg/day PO</TableCell>
                  <TableCell align="left">Hepatoprotective, improves bile flow</TableCell>
                </TableRow>
                <TableRow>
                 <TableCell align="left">Fish oil (Omega 3 FAs)</TableCell>
                  <TableCell align="left">2000 mg/day PO</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
                <TableRow>
                 <TableCell align="left">Vitamin E</TableCell>
                  <TableCell align="left">10 IU/kg PO q24h <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(10)).toFixed(0)} IU</span></TableCell>
                  <TableCell align="left">Antioxidant</TableCell>
                </TableRow>
                <TableRow>
                 <TableCell align="left">Ursodeoxycholic acid (Ursodiol)</TableCell>
                  <TableCell align="left">10-15 mg/kg PO q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(10)).toFixed(0)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(15)).toFixed(0)} mg</span> WITH FOOD</TableCell>
                  <TableCell align="left">Hepatoprotective; contraindicated in cases of EHBO</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider antibiotics</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
            <li>
            Beneficial if acute neutrophilic cholangitis or sepsis are suspected
            </li>
            <li>
            Consider amoxicillin/clavulanate +/- metronidazole
            </li>
            <li>
            Consider pradofloxacin (Veraflox) if concern for Mycoplasma haemofelis
            </li>
          </ul>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handout:
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951913" target="_blank" rel="noopener noreferrer">Hepatic Lipidosis (Fatty Liver Disease) in Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item xs={12}>
                References:
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <p><sup>1</sup>Center. Feline Hepatic Lipidosis. Vet Clin Small Anim 2005.</p>

                  <p><sup>2</sup>Kuzi. Prognostic markers in feline hepatic lipidosis: a retrospective study of 71 cats. Vet Record 2017.</p>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>

          <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};