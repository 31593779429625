import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox, Switch, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBack from '@mui/icons-material/ArrowBack';

export default function RespiratoryDistress() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  const [switchCat, setSwitchCat] =useState(false)
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  function handleSwitchChange(event) {
    setSwitchCat(event.target.checked)
  }
  return (
    <>
      <h1 style={{textAlign: "center", marginTop: ".25em", marginBottom: ".1em"}}>Respiratory Distress</h1>
      <h2 style={{textAlign: "center", marginTop: ".15em", marginBottom: ".25em"}}>(Oxygen Dependent at Presentation)</h2>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
        <Grid item xs={12}>
          <TextField
            id="standard-textarea"
            label="Name"
            multiline
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>
            </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
          <Grid item>Canine</Grid>
          <Grid item><Switch checked={switchCat} onChange={handleSwitchChange} name="Cat" />
          </Grid>
          <Grid item>Feline</Grid>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.2)/10).toFixed(2)} to {(weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM.
            </li>
            <li>
            Place directly in the oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in compromised patients.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <span style={{fontWeight: '700'}}>Ddx: <sup>1</sup></span>
        <Grid item xs={12}>
          <ul>
            <li>
            Upper airway disease
            </li>
            <li>
            Lower airway disease
            </li>
            <li>
            Pulmonary parenchymal disease
            </li>
            <li>
            Pleural space disease
            </li>
            <li>
            Thoracic wall injury
            </li>
            <li>
            Abdominal distension
            </li>
            <li>
            Pulmonary thromboembolism
            </li>
            <li>
            Look-alike causes
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: '700'}}>Key points for communication: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Discuss most likely causes include diseases within or around the lungs. Diagnostics will be stepwise to minimize patient stress and reduce risk of respiratory arrest.
            </li>
            {switchCat ? 
            <>
              <li>
              If loud murmur, gallop, or history suggestive of CHF, discuss heart disease in cats. See <Link to={'/quick-sheets/chf'}>CHF Quick Sheet</Link>.
              </li>
              <li>
              If appropriate, discuss history and signalment may be fitting with feline asthma, radiographs will help support and rule out other diseases.
              </li>
              <li>
              If history of thoracic or blunt trauma, discuss that extent of injuries may not be immediately apparent, may take days to fully develop.
              </li>
              <li>
              Discuss CPR in cats. Practice with the pet parent’s goals and values in mind in the event of respiratory arrest.
              </li>
            </>
            :
            <>
              <li>
              If loud murmur or history suggestive of CHF, discuss heart disease and congestive heart failure in dogs. See <Link to={'/quick-sheets/chf'}>CHF Quick Sheet</Link>.
              </li>
              <li>
              If appropriate, discuss history and signalment may be fitting with upper airway obstruction. See <Link to={'/quick-sheets/upper-airway-obstruction'}>Upper Airway Obstruction Quick Sheet</Link>.
              </li>
              <li>
              If appropriate, discuss history and signs may be fitting with pneumonia. See <Link to={'/quick-sheets/pneumonia'}>Pneumonia Quick Sheet</Link>.
              </li>
              <li>
              If history of thoracic or blunt trauma or reason for non-cardiogenic pulmonary edema, discuss that extent of injuries may not be immediately apparent; may take days to fully develop.
              </li>
              <li>
              Discuss CPR in dogs and cats. Practice with the pet parent’s goals and values in mind in the event of respiratory arrest.
              </li>
            </>
            }
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />3 view chest radiographs
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />tFAST
          {switchCat ?
          <></>
          :
            <ul style={{marginTop: 0}}>
              <li>
              If pleural effusion, see <Link to={'/quick-sheets/pleural-effusion'}>Pleural Effusion Quick Sheet</Link>
              </li>
            </ul>
          }
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />IVC
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />CBC/Chemistry
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />PCV/TS and Blood Smear if concern for anemia {switchCat ? "" : "or thrombocytopenia"}
        </Grid>
        {switchCat ?
        <>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            />SNAP FeLV/FIV/HW
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            />SNAP Feline proBNP
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            />Consider T4 with Chemistry in older patients
          </Grid>
        </>
        :
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Heartworm Test
        </Grid>
        }
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              Reference:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup> Drobatz et al. Textbook of Small Animal Emergency Medicine. Wiley-Blackwell 2018.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};