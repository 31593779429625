import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import ulcerSimple from './images/UlcerSimple.jpg';
import indolentUlcer from './images/indolentUlcer.jpg';
import cornealPerforation from './images/cornealPerforation.jpg';
import sequestrum from './images/sequestrum.jpg';
import descemetocele from './images/descemetocele.jpg';

import FhvKeratoconjunctivitis from './FelineHerpesConjunctivitis';

export default function CornealUlcer() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Corneal Ulcer</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/corneal-ulcer" variant="outlined">Corneal Ulcer Quick Sheet</Button>
            </Grid>
          </Grid>
        <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
        <Typography>
          A corneal ulcer involves damage to the surface of the eye. Rubbing at the eye, blinking, squinting, or tearing may be reported. Corneal ulcers are extremely painful. Mainstays of treatment include a hard plastic cone (to prevent further self-trauma to the eye), pain medications, and topical ophthalmic antibiotics to prevent infection.  Although most corneal ulcers heal within 7 days with proper treatment, these pets should have recheck examinations to ensure progress and resolution without complications.
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Superficial Corneal Ulcer</span></div>
          <img src={ulcerSimple} alt="superficial corneal ulcer" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          </Paper>
        </Grid>


        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Indolent Ulcer</span></div>
          <img src={indolentUlcer} alt="indolent ulcer" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>In the case of an indolent ulcer, loose flaps of tissue at the surface of the eye prevent normal healing. This type of ulcer is seen more commonly in older dogs and Boxers.</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography>
          Sterile cotton swabs can be used to gently removed the loose flaps (after proparacaine of course).  Diamond burr debridement is often recommended where available.  Terramycin ointment is a good choice as it inhibits MMPs (matrix metalloproteinases).
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Descemetocele</span></div>
          <img src={descemetocele} alt="descemetocele" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>When an ulcer is very deep, it reaches Descemet membrane, a thin bottom layer that does not take up stain.  Notice that only the ring of corneal stroma (and tear film) take up stain.</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography>
          Referral to an ophthalmologist should be recommended for this descemetocele.  This eye is very fragile.
        </Typography>
        <Typography>
          For deep or infected ulcers, consider tobramycin and ofloxacin ophthalmic drops.  Melting (infected) ulcers should also be treated with serum eye drops every 1-2 hours for 36 hours and then every 4-6 hours for 5-7 days.  Doxyccline at 10 mg/kg PO every 24 hours for 10-14 days will also help inhibit harmful collagenases.
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sequestrum</span></div>
          <img src={sequestrum} alt="sequestrum" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>This is a common complication in cats.  The brown tissue is dead.  Sometimes it will fall off on its own.  Sometimes it will need to be surgically removed.  Sometimes it results in a deep defect and a fragile eye. <a href="http://www.acvo.org/common-conditions-1/2018/2/23/corneal-sequestrum" target="_blank" rel="noopener noreferrer">Sequestrum Handout</a> from ACVO</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Corneal Perforation</span></div>
          <img src={cornealPerforation} alt="corneal perforation" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>Notice the small central defect in the cornea.  The fluorescein stain is being pushed out by the fluid from the anterior chamber leaking out of a small puncture.  This is called the 'Seidel Test.'</div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          For deep or infected ulcers, consider tobramycin and ofloxacin ophthalmic drops.  Melting (infected) ulcers should also be treated with serum eye drops every 1-2 hours for 36 hours and then every 4-6 hours for 5-7 days.  Doxycycline at 10 mg/kg PO every 24 hours for 10-14 days will also help inhibit harmful collagenases.
        </Typography>
        <FhvKeratoconjunctivitis />
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/corneal-ulcers" target="_blank" rel="noopener noreferrer">Corneal Ulcer Handout</a> from PetParentHandouts.com
        </Typography>
        <Typography>
        <a href="http://www.acvo.org/common-conditions-1/2018/2/2/corneal-ulcer" target="_blank" rel="noopener noreferrer">Corneal Ulcer Handout</a> from ACVO
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
