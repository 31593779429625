import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MusculoskeletalDrugDoses from '../../Calculators/MusculoskeletalDrugDoses';
import MusculoskeletalDrugDosesFeline from '../../Calculators/MusculoskeletalDrugDosesFeline';

export default function CommonDrugs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Oral Drugs & Doses</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{fontWeight: "700"}}>Canine:</Typography>
          <MusculoskeletalDrugDoses />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{fontWeight: "700"}}>Feline:</Typography>
          <MusculoskeletalDrugDosesFeline />
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
