import React from 'react';
import { Grid, Paper, } from '@mui/material';

import CrcldRlat from './images/orthopedic/hindlimb/stifle_Rlat.jpg';

export default function CrcldRadiographs() {

  return (
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cranial Cruciate Ligament Disease (suspected)</span></div>
          <img src={CrcldRlat} alt="joint effusion in right stifle" style={{maxWidth: "96%"}}/>
          <div>
            Notice effusion in the right stifle joint (decreasing the size and sharpness of the infrapatellar fat pad), cranial positioning of the tibia in relation to the femur, and osteophytes.
          </div>
          </Paper>
          </Grid>


  );
}
