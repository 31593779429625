import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableBody, TableCell,TableHead, TableRow } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class BlockedCatQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Blocked Cat</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Presentation:</span> Straining to urinate (often reported by pet parents as constipated), yowling, extreme lethargy and vomiting; bladder will be distended and firm on palpation; typically male cats sometimes associated with environmental stressor; bradycardia and collapse due to hyperkalemia; hypothermia; azotemia and subsequent nausea/vomiting
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textDecoration: 'underline'}}>
          Diagnostics
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="chem"
          /><span style={{fontWeight: '700'}}>i-STAT CHEM 8+</span> or similar for K<sup>+</sup> and renal values
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="chem"
          /><span style={{fontWeight: '700'}}>Urinalysis</span> (can collect at u-cath placement, see notes below on decompressive cystocentesis)
        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <ul>
          <li>
          A young male cat will almost never have a UTI at presentation.  Incidence of UTI in cats under 10-years-old is quite low, while FIC is much more common.
          </li>
          </ul>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="chem"
          /><span style={{fontWeight: '700'}}>EKG</span> - especially if K<sup>+</sup>&ge;8 or cardiac irregularities on auscultation
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="chem"
          /><span style={{fontWeight: '700'}}>Radiographs (or focused bladder ultrasound)</span> to help rule out cystic calculi. Consider lateral film after placement of urinary catheter.
        </Grid>

        <Grid item xs={12} style={{textDecoration: 'underline'}}>
          Treatments
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="chem"
          /><span style={{fontWeight: '700'}}>Place IV catheter</span>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV fluids"
          inputProps={{ 'aria-label': 'IV fluids' }}
          /><span style={{fontWeight: '700'}}>IV Fluid Resuscitation</span>
        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <ul>
          <li>
          1/4 shock dose is 15 ml/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(15)).toFixed(0)} mL</span>
          </li>
          <li>
          LRS is preferred as this will correct acidosis more quickly than 0.9% NaCl.<sup>1</sup>  LRS only has 4 mEq K+ (physiologic is 20 mEq), so this is not reason to use 0.9% NaCl.
          </li>
          </ul>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV fluids"
          inputProps={{ 'aria-label': 'IV fluids' }}
          /><span style={{fontWeight: '700'}}>Address severe hyperkalemia and arrhythmias if present</span>
        </Grid>

        <Grid container align="left">
          <Grid item xs={12}>
          <ul>
          <li>
          Calcium gluconate counteracts the effect of hyperkalemia at the sino-atrial node. Give 0.5 to 1.5 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>10%</span> Calcium gluconate I.V. over 5-10 minutes while monitoring EKG
          </li>
          <li>
          Dextrose & Insulin will shift plasma K<sup>+</sup> into cells. Give 0.5 U/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.5)).toFixed(1)} U regular insulin</span> I.V. and 2 mL/U of insulin = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)).toFixed(1)} mL</span> 50% Dextrose I.V. (Dilute to 25%). Add 2 mL/U of Insulin = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)).toFixed(1)} mL</span> 50% Dextrose to 1 L fluid bag. Check BG in 2-3 hours.
          </li>
          <li>
          Terbutaline will shift K<sup>+</sup> into cells and is used instead of Dextrose & Insulin by some clinicians. Give 0.01 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.01)).toFixed(2)} mL</span> Terbutaline (1 mg/mL) I.V. slowly over 5-10 minutes. Inhaled levalbuterol may have a similar effect (albuterol can be used in a pinch but racemic albuterol can cause airway inflammation in cats<sup>2</sup>).
          </li>
          </ul>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV fluids"
          inputProps={{ 'aria-label': 'IV fluids' }}
          /><span style={{fontWeight: '500'}}>Heat support if temperature &le;98F</span>
        </Grid>

          <Accordion>
          <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="sedation"
          />Heavy sedation or anesthesia
          </Grid>
          </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={0}>
        <Grid item xs={12}>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Ket/Midz +/- Bup (I.M.)
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Drug (concentration; dose)</TableCell>
                <TableCell align="left">Volume (mL)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Midazolam (5 mg/mL; 0.3 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.3)/(5)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Ketamine (100 mg/mL; 5 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(5)/(100)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Buprenorphine (0.3 mg/mL; 0.02 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.02)/(0.3)).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </Paper>
          </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12}>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Ace/Bup (I.M.)
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Drug (concentration; dose)</TableCell>
                <TableCell align="left">Volume (mL)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Acepromazine (<span style={{backgroundColor: '#ff726f'}}>1 mg/mL</span>; 0.03 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.03)/(1)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Buprenorphine (0.3 mg/mL; 0.02 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.02)/(0.3)).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </Paper>
          </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12}>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Alfax/Torb/Midaz (I.M.)
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Drug (concentration; dose)</TableCell>
                <TableCell align="left">Volume (mL)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Alfaxalone (10 mg/mL; 2 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(2)/(10)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Butorphanol (10 mg/mL; 0.1 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.1)/(10)).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Midazolam (5 mg/mL; 0.05 mg/kg)</TableCell>
                <TableCell align="left">{(this.state.weightkg*(0.05)/(5)).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </Paper>
          </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Additonal Propofol or Alfaxalone can be used for induction
        </li>
        <li>
        1 - 4 mg/kg Propofol (10 mg/mL) or Alfaxalone (10 mg/mL) = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL to {(this.state.weightkg*(4)/10).toFixed(2)} mL</span>
        </li>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="decompressive cysto"
          />Consider decompressive cystocentesis
        </Grid>
        </AccordionSummary>
        <AccordionDetails>

        <Grid item xs={12} style={{textAlign: 'left'}}>
        <ul>
        <li>
          Decompressive cystocentesis at presentation or prior to catheter placement is considered by some clinicians to be helpful.
        </li>
        <li>
        Many feline patients with UO present with abdominal effusion; the incidence of abdominal effusion following decompressive cystocentesis is higher (from 33% at presentation to 49% following single cystocentesis).<sup>3</sup>  This same study suggests that there is minimal risk of clinically relevant complications associated with decompressive cystocentesis.
        </li>
        </ul>
        </Grid>
        </AccordionDetails>
        </Accordion>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="rectal exam"
          />Digital rectal exam to feel for stones, neoplasia, other abnormalities
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="clip and prep"
          />Clip and prep the perineum
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="U cath placement"
          />Consider a coccygeal epidural block with Preservative Free Bupivacaine 0.5% (5mg/mL), 0.22 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.22)/5).toFixed(2)} mL</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
        <ul>
        <li>
          <a href="https://www.youtube.com/watch?v=KhAN4SavVzg" target="_blank" rel="noopener noreferrer"  className="content-link">YouTube VETgirl Video: Coccygeal Block</a>
        </li>
        <li>
          2018 Pratt paper shows epidural was effective in 70%, took median time of 2 minutes, better pain management.<sup>4</sup>
        </li>
        </ul>
        </Grid>
        <Accordion>
        <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="U cath placement"
          />Place a urinary catheter
        </Grid>
        </AccordionSummary>
        <AccordionDetails>

        <Grid item xs={12} style={{textAlign: 'left'}}>
        <ul>
        <li>
          Slippery Sam style preferred.  3.5F polyvinyl catheter is alternative and is associated with lower short-term recurrence of obstruction compared to larger 5F.<sup>5</sup> In some challenging cases, a semi-rigid polypropylene open end tom cat catheter may be used for the initial unblock, but this should be replaced with a softer catheter that will not further traumatize the urethra and bladder.
        </li>
        <li>
          Fully extrude the penis. Attempt to gently massage any lodged grit at the tip. Grasp the extruded penis at the base and pull caudally and dorsally to straighten the urethra.  Some clinicians prefer to shift their grip to the prepuce once the catheter reaches the base of the penis.
        </li>
        <li>
          Sterile saline or sterile saline mixed with sterile lubricant can be used to pulse flush the urethra as you advance the urinary catheter.  Lidocaine can be added to this flush. Be patient. Retrograde hydropulsion&mdash;where digital pressure is applied to the urethra per rectum and then released while flushing through the catheter&mdash;may be helpful in some challenging cases.
        </li>
        <li>
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '500'}}>
          Consider flushing urinary bladder with sterile saline
        </Grid>
        </AccordionSummary>
        <AccordionDetails>

        <Grid item xs={12} style={{textAlign: 'left'}}>
        <ul>
        <li>
          Bladder lavage may have no significant effect on recurrence, duration of catheter retention, or duration of hospitalization.<sup>6</sup>
        </li>
        </ul>
        </Grid>
        </AccordionDetails>
        </Accordion>
        </li>
        <li>
          Catheter should be sutured to prepuce and taped to tail.  A closed collection system should be kept clean (and off the ground). Hard E-collar is necessary to prevent premature removal by patient.
        </li>
        </ul>
        </Grid>
        </AccordionDetails>
        </Accordion>



        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          checked={this.state.checked}
          onChange={this.setChecked}
          color="primary"
          label="hospitalize"
          /><span style={{fontWeight: '700'}}>Plan on 1-3 days minimum in hospital.</span>
        </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Duration of catheterization is typically 24-48 hours based on difficulty unblocking, appearance of urine in line/bag, and resolution of azotemia. There is no association between re-obstruction and duration of catheterization beyond 24 hours.<sup>7</sup>
          </li>
          <li>
          Buprenorphine 0.3 mg/mL I.V.: 0.01 to 0.03 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.01)/0.3).toFixed(2)} mL to {(this.state.weightkg*(.03)/0.3).toFixed(2)} mL</span>
          </li>
          <li>
          Maropitant (Cerenia) 10 mg/mL I.V.: 1 mg/kg I.V. = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span>
          </li>
          <li>
          I.V. Fluid Therapy: LRS preferred over 0.9% NaCl; typically start at 35-50 mL/hr. Quantify urine output every 2-6 hours and adjust fluid rate accordingly. Be aware of post-obstructive diuresis; some patients will require significantly higher fluid rates.
          </li>
          </ul>
          </Grid>


          <Accordion>
          <AccordionSummary style={{padding: '0px 16px 0px 0px'}} expandIcon={<ExpandMoreIcon />}>
          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="decompressive cysto"
            />Collect for urine culture at removal of urinary catheter. Empirical antibiotics are generally not recommended.
          </Grid>
          </AccordionSummary>
          <AccordionDetails>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Empirical antibiotic administration is not indicated.<sup>8</sup>  If finances allow for multiple urine cultures, consider submitting the sample collected at presentation and a second sample collected from the urinary catheter line (after alcohol wiping the port) just prior to removal of the catheter.  If finances are limited, consider a single sample just prior to removal of the port or from a sample collected by cystocentesis at a follow up appointment 7 days post-discharge.
          </li>
          <li>
            Some clinicians will not routinely submit urine for culture on the front end if no evidence of bacteriuria on urine sediment.  In these cases, urine sediment should be evaluated daily and urine submitted for culture if any suggestion of bacteriuria.
          </li>
          </ul>
          </Grid>
          </AccordionDetails>
          </Accordion>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />After removal of urinary catheter, patient should be urinating on his own and eating.
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Medications for long-term management:
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Buprenorphine, 0.3 mg/mL</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give into the mouth onto the gums every 6-12 hours to reduce pain.  Absorbed through the gums.  May cause sedation, wobbly walking, or odd behavior.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          0.01 to 0.03 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.01)/0.3).toFixed(2)} mL to {(this.state.weightkg*(.03)/0.3).toFixed(2)} mL</span>
          </li>
          <li>
          Use with acepromazine at first sign of difficult urination and may be able to prevent recurrence/hospitalization if patient is able to urinate on their own.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Acepromazine, 10 mg tablet</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            At first sign of difficult urination, give 1/4 tablet by mouth every 8 hours for 3 days. Then give 1/4 tablet by mouth every 12 hours for 3 days.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            Alpha antagonist (overlapping MOA with Prazosin, so avoid using both) that may promote urethral muscle relaxation
          </li>
          <li>
            The 2010 Cooper paper lends some evidence for acepromazine/buprenorphine combination therapy.<sup>9</sup>
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Gabapentin</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 8 hours for 5-7 days.  Give 1-2 hours before veterinary visits or other potentially stressful events.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Anxiolytic, pain medication, mild appetite stimulant
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Amitriptyline, 10 mg tablet</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give 1/2 tablet by mouth every evening. This is an anti-depressant that helps promote bladder relaxation. Do not discontinue abruptly.  If any sign of difficult urination, increase dose to 1 full tablet.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            May be appropriate for some cats that are easily medicated
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Maropitant (Cerenia)</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            At first sign of difficult urination, give by mouth every 24 hours for 4-7 days.  This medication has broad anti-inflammatory properties and may help your pet feel better.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            No data and off-label, but theoretically a great application of a very safe drug
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Dantrolene, 25 mg capsules</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            At first sign of difficult urination, give by mouth every 12 hours for 5-7 days.  This drug may help prevent skeletal muscle spasm of the urethra that would cause your cat to become blocked.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            0.5 to 2 mg/kg = {(this.state.weightkg*(0.5)/25).toFixed(2)} to {(this.state.weightkg*(2)/25).toFixed(2)} of a 25 mg capsule PO q12h
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Adequan</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give {(this.state.weightkg*(5)/100).toFixed(2)} mL subcutaneously (under the skin) twice each week for 4 weeks. Then once each week for 4 weeks. Then once every 4 weeks.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Glycosaminoglycans (GAGs) have a role in treatment of human women with cystitis.  GAGs are important for covering the bladder epithelium.  May work similarly to Pentosan (Elmiron) which is labeled for humans with cystitis.
          </li>
          <li>
            This is off-label with no sufficient evidence
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>NSAIDs</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Do not give Onsior (Robenacoxib) or Meloxicam to these cats which often have some degree of renal compromise.
          </li>
          <li>
            There is no COX2 in the feline uroepithelium.<sup>10</sup>
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Prazosin</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          Prazosin may help relax smooth muscle associated with internal urethral sphincter.
          </li>
          <li>
          This medication is falling out of favor. A recent study showed prazosin to increase the rate of recurrent urethral obstruction.<sup>11</sup>
          </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951735" target="_blank" rel="noopener noreferrer">Urinary Blockage in Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951488" target="_blank" rel="noopener noreferrer">Idiopathic Cystitis in Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Discharge Note (Key Points):
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
            Most cats that have experienced urethral obstruction are at increased risk for recurrence of this life-threatening condition.  Any signs of urinary tract problems are an emergency.  These may include: bloody urine, straining to urinate, urinating outside the litter box, licking the urinary opening, crying/yowling, loss of appetite, vomiting.  Recheck promptly if your cat is showing any of these signs or you are concerned.
          </li>
          <li>
            A prescription urinary diet (wet food is best) is one of the most important parts of your cat's management and may help prevent recurrence of this problem.
          </li>
          <li>
            Reducing environmental stress, vertical climbing areas/perches, Feliway pheromone diffusers, and other stress-reducing supplements may help prevent recurrence of this problem.
          </li>
          <li>
            We have recommended sending out a urine culture. If this was approved, we will call with the results when they are available from the lab.  Your cat may need antibiotic treatment and/or recheck evaluation if there is evidence of a urinary tract infection.
          </li>
          </ul>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '500'}}>
          References:
          </Grid>
          </AccordionSummary>
          <AccordionDetails>
          <Grid item xs={12}>
            <p><sup>1</sup> Cunha, et al. Renal and cardiorespiratory effects of treatment with lactated Ringer’s solution or physiologic saline (0.9% NaCl) solution in cats with experimentally induced urethral obstruction. AJVR 2010. </p>
            
            <p><sup>2</sup> Reinero et al. Enantiomer-specific effects of albuterol on airway inflammation in healthy and asthmatic cats. Int Arch Allergy Immunol 2009.</p>

            <p><sup>3</sup> Gerken, Cooper, et al. Association of abdominal effusion with a single decompressive cystocentesis prior to catheterization in male cats with urethral obstruction. JVECC 2020.</p>

            <p><sup>4</sup> Pratt, et al. A prospective randomized, double-blinded clinical study evaluating the eefficacy and safety of bupivacaine versus morphine-bupivacaine in caudal epidurals in cats with urethral obstruction. JVECC 2020.</p>

            <p><sup>5</sup> Hetrick, et al. Initial treatment factors associated with feline urethral obstruction recurrence rate: 192 cases (2004–2010). JAVMA 2013.</p>

            <p><sup>6</sup> Dorsey, et al. Effect of urinary bladder lavage on in-hospital recurrence of urethral obstruction and durations of urinary catheter retention and hospitalization for male cats. JAVMA 2019.</p>

            <p><sup>7</sup> Hetrick, Davidow. Initial treatment factors associated with feline urethral obstruction recurrence rate: 192 cases (2004-2010). JAVMA 2013.</p>

            <p><sup>8</sup> Cooper, et al. Incidence of bacteriuria at presentation and resulting from urinary catheterization in feline urethral obstruction. JVECC 2019.</p>

            <p><sup>9</sup> Cooper, et al. A protocol for managing urethral obstruction in male cats without urethral catheterization. JAVMA 2010.</p>

            <p><sup>10</sup> Singer, et al. ACVIM Forum 2013.</p>

            <p><sup>11</sup> Conway, et al. Prazosin administration increases the rate of recurrent urethral obstruction in cats: 388 cases. JAVMA 2022.</p>


          </Grid>
          </AccordionDetails>
          </Accordion>

          <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default BlockedCatQS;
