import React from 'react';
import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import pleuralEffusionLlat from './images/pleuralEffusionLlat.jpg';
import pleuralEffusionRlat from './images/pleuralEffusionRlat.jpg';
import pleuralEffusionDV from './images/pleuralEffusionDV.jpg';
import pleuralEffusionLlat2 from './images/pleuralEffusionLlat2.jpg';
import pleuralEffusionRlat2 from './images/pleuralEffusionRlat2.jpg';
import pleuralEffusionVD2 from './images/pleuralEffusionVD2.jpg';

export default function ChfRadiographs() {

  return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
        <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Case 1 (Canine)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionRlat} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionDV} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionLlat} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Case 2 (Feline)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionRlat2} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionVD2} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={pleuralEffusionLlat2} alt="thoracic radiograph of patient with pleural effusion" style={{maxWidth: "96%"}}/>
                </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>


  );
}
