import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Haws() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Haws Syndrome (Bilateral protrusion of the third eyelids)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
          Bilateral protrusion of the third eyelids or “Haw’s syndrome” is often associated with diarrhea or gastrointestinal signs especially in young cats. It usually resolves on its own. It must be differentiated from bilateral Horner’s syndrome (which should present with miosis and other classic signs) and dysautonomia (which will also affect pupillary light response).
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
