import React from 'react'
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LifeStages() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Life Stage Guidelines</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>DOGS</TableCell>
            <TableCell align="center">Puppy</TableCell>
            <TableCell align="center">Adult</TableCell>
            <TableCell align="center">Senior</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Fecal</TableCell>
            <TableCell align="center">+</TableCell>
            <TableCell align="center">+</TableCell>
            <TableCell align="center">+</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Heartworm</TableCell>
            <TableCell align="center">N/A</TableCell>
            <TableCell align="center">+</TableCell>
            <TableCell align="center">+</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">CBC/Chem/UA</TableCell>
            <TableCell align="center">+/-</TableCell>
            <TableCell align="center">+/-</TableCell>
            <TableCell align="center">+</TableCell>
          </TableRow>

        </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      <Grid item xs={12}>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CATS</TableCell>
            <TableCell align="center">Kitten</TableCell>
                <TableCell align="center">Adult</TableCell>
                <TableCell align="center">Mature</TableCell>
                <TableCell align="center">Senior</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Fecal</TableCell>
                <TableCell align="center">+</TableCell>
                <TableCell align="center">+</TableCell>
                <TableCell align="center">+</TableCell>
                <TableCell align="center">+</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Retroviruses</TableCell>
                <TableCell align="center">+</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+/-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">CBC/Chem/UA</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+</TableCell>
                <TableCell align="center">+</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Thyroid</TableCell>
                <TableCell align="center">N/A</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Blood Pressure</TableCell>
                <TableCell align="center">N/A</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">+</TableCell>
              </TableRow>

            </TableBody>
      </Table>
      </TableContainer>
      </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
