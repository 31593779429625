import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function CardioExam() {

  const [refs, setRefs] = useState(false);
  const [ref2, setRef2] = useState(false);
  const [ref3, setRef3] = useState(false);
  const [ref4, setRef4] = useState(false);
  const [ref5, setRef5] = useState(false);



  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cardio Exam</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>History & Physical Exam Tips & Tricks</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography gutterBottom>PDA is more common in females</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Acquired chronic valvular degeneration is more common in small breed dogs</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Myocardial failure is more common in large breed dogs</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>History findings common in patients with CHF include: coughing, breathing change, exercise intolerance, abdominal distension, rapid labored breathing while asleep</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Coughing is a common clinical sign in dogs with CHF; coughing is more commonly associated with respiratory disease in cats</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>A jugular pulse extending greater than 1/3 distance up neck is abnormal.
              It may indicate tricuspid regurgitation, poorly right ventricular compliance (as in right ventricular hypertrophy following pulmonic stenosis).
              Hepatojugular reflux: compress cranial abdomen and look for change in jugular pulse, elevated right atrial (RA) pressures or &darr; RA compliance will distend jugular veins</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Hypokinetic pulse: think low cardiac output (as in subaortic stenosis)</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Hyperkinetic pulse: think wide pulse pressure (as in anemia, left-to-right shunting PDA, significant aortic regurgitation)</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Variable or unequal pulses (vary in strength): think tachyarrhythmias like atrial fibrillation; variable filling &rarr; variable stroke volume </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Pulse deficits: think premature depolarization. You must feel and listen at the same time. An EKG is needed to determine the origin (atrial or ventricular).</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Mitral valve prolapse may be heard as a systolic click even if no murmur is present</Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Auscultation and Murmurs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography>
                A murmur is often heard when there is turbulent blood flow. This often happens if a leaky heart valve lets some blood flow backwards&mdash;against the normal current&mdash;because it doesn't quite close like it should.  Murmurs are graded, one through six, using the criteria listed in the table below.
                </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Grade</TableCell>
                          <TableCell align="left">Criteria</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">I</TableCell>
                          <TableCell align="left">only heard by experienced listener</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">II</TableCell>
                          <TableCell align="left">soft murmer heard only in one valve location</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">III</TableCell>
                          <TableCell align="left">easily heard; radiates but has identifiable PMI (point of maximum intensity)</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">IV</TableCell>
                          <TableCell align="left">easily heard anywhere on thorax; without palpable thrill; difficult to locate the PMI</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">V</TableCell>
                          <TableCell align="left">loud murmur anywhere in chest with palpable thrill (you can feel the vibration)</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">VI</TableCell>
                          <TableCell align="left">very loud murmur with thrill; can be heard without touching stethoscope to chest wall</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                <p><span style={{fontWeight: "700"}}>Pitch:</span> high, low, musical, rumbling, whooping</p>
                <p><span style={{fontWeight: "700"}}>Quality:</span> plateau, crescendo ("ejection murmurs" as with SAS or PS), decrescendo, crescendo-decrescendo</p>
                <p>Innocent murmurs in puppies and kittens should: 1. be soft (&le; grade 3); 2. resolve by 16 wks age; 3. get softer over time</p>
                <p><span style={{fontWeight: "700"}}>Mitral valve regurgitation</span> is extremely common (especially in small breed dogs and cats). The grade of murmur does not correspond to the severity of disease. An echocardiogram or thoracic radiographs are needed to look for structural changes to the heart. Mitral regurgitation can be caused by endocardiosis, endocarditis, volume overload, or valve dysplasia.</p>
                <p><span style={{fontWeight: "700"}}>Left heart base murmurs</span> may be subaortic stenosis (SAS) or pulmonic stenosis (PS). The grade correlates better to severity of disease</p>
                <p>A Ventricular Septal Defect (VSD) often produces a <span style={{fontWeight: "700"}}>loud basilar systolic murmur</span>.  Small defects create loud murmurs which have better prognosis than large defects (which often produce soft murmurs).</p>
                <p>Atrioventricular (AV) valve prolapse causes <span style={{fontWeight: "700"}}>systolic clicks</span>.  Clicks are louder and more easily heard than gallops. Clicks are often only appreciated in early stages of valve disease as the valves prolapses and then snaps to its normal position.</p>
                <p><span style={{fontWeight: "700"}}>Gallops</span> are s3 and s4 heart sounds.  These are not normal in dogs or cats. s3 is at beginning of diastole (blood hits eccentrically dilated ventricle. s3 may be heard with Dilated Cardiomyopathy (DCM) or cases of severe Patent Ductus Arteriosus (PDA). s4 is heard when atria contract.  It may be heard if the left ventricle cannot relax, or in cases of hypertrophic cardiomyopathy (HCM).  Unlike systolic clicks, gallops are generally associated with bad heart disease.</p>
                <p><span style={{fontWeight: "700"}}>Right systolic murmurs:</span> Think Tricuspid Valve Dysplasia (TVD) if the patient is young (Labs are prone to TVD). Right systolic murmurs may also be associated with a Ventricular Septal Defect (VSD).  If heard in an older dog, think endocardiosis</p>
                </Grid>
                <Grid item xs={12}>
                <Paper>
                  <Table padding="none">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Congenital Defect</TableCell>
                        <TableCell align="center">Location</TableCell>
                        <TableCell align="center">Timing</TableCell>
                        <TableCell align="center">Pulses</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">PDA</TableCell>
                        <TableCell align="center">left basilar</TableCell>
                        <TableCell align="center">continuous</TableCell>
                        <TableCell align="center">bounding (low diastolic pressure)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">PS</TableCell>
                        <TableCell align="center">left basilar</TableCell>
                        <TableCell align="center">systolic</TableCell>
                        <TableCell align="center">normal</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">SAS</TableCell>
                        <TableCell align="center">left basilar</TableCell>
                        <TableCell align="center">systolic</TableCell>
                        <TableCell align="center">normal to &darr;</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>EKG</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography gutterBottom>Remember, you do not have EKG fingers. If you hear an irregular rhythm, you do not know this is a sinus arrhythmia. You should do an EKG.  Many dogs have a regularly irregular rhythm, and many of these are respiratory sinus arrhythmias.  Please don't write "respiratory sinus arrhythmia" in your physical exam if you have not confirmed this by EKG.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>An electrocardiograph (ECG, EKG) measures the electrical activity of the heart muscle. It is this electrical activity that causes the heart to beat.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>Ideally, the patient should be in right lateral recumbency. "White on Right; Smoke over Fire," can be used to remember lead placement. The white lead ("White") goes on the right forelimb, often on the right axillary skin fold flap (near the armpit). The red lead ("Fire") is placed on the left flank fold (that flap of skin just in front of the leg). The black lead ("Smoke") goes on the left forelimb in the same fashion as the white lead. If the patient is trembling, moving the leads distally (down the legs) can help reduce motion artifact.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography gutterBottom>A sinus arrhythmia is never normal in a cat</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography ><span style={{fontWeight: "700"}}>How to evaluate an EKG:</span>            </Typography>
                <ol>
                <li>
                What is the heart rate?
                </li>
                  <ul>
                  <li>Can measure average and instantaneous</li>
                  <li>"Pen x 10": A bic pen with the cap on is 150mm (30 big boxes) and represents 6 seconds when paper speed is 25mm/sec. Count beats within the length of a bic pen and multiply by 10 for heart rate.</li>
                  </ul>
                <li>
                Is it sinus? (Is there a P for every QRS and a QRS for every P)
                </li>
                <ul>
                <li>If irregular, is it regularly irregular or is there no pattern</li>
                </ul>
                <li>
                Evaluate the intervals and amplitudes.
                </li>
                <li>
                Evaluate for criteria fitting chamber enlargement or conduction delays.
                </li>
                <li>
                Consider any other irregularities.
                </li>
                </ol>
                </Grid>
                <Grid item xs={12}>
                <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">APC</TableCell>
                      <TableCell align="left">VPC</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">have p waves (sometimes)</TableCell>
                      <TableCell align="left">lack p waves</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">often upright and narrow</TableCell>
                      <TableCell align="left">often wide and bizarre</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">sometimes have overdrive suppression</TableCell>
                      <TableCell align="left">AV dissociation</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">non-compensatory pause</TableCell>
                      <TableCell align="left">compensatory pause</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                <Typography><span style={{fontWeight: "700"}}>What causes APCs?</span></Typography>
                <ol>
                <li>
                Acquired large atria (most common, especially DCM dogs in A fib)
                </li>
                <li>
                Inflammation of atrial myocardium (myocarditis, systemic infl. mediators, pain)
                </li>
                <li>
                Born with large atria (giant breeds)
                </li>
                <li>
                Abnormal conduction tissue (Cavies; accessory pathways)
                </li>
                <li>
                Toxin (rare, eg caffeine)
                </li>
                </ol>
                </Grid>
                <Grid item xs={12}>
                <Typography><span style={{fontWeight: "700"}}>What causes VPCs?</span></Typography>
                <ol>
                <li>
                Cardiac disease
                </li>
                <li>
                Changes in autonomic tone
                </li>
                <li>
                Cancer
                </li>
                <li>
                Toxins
                </li>
                <li>
                Electrolyte abnormalities (&darr;K<sup>+</sup>; &darr;Mg<sup>2+</sup>, cannot correct K<sup>+</sup> until correct Mg<sup>2+</sup>; need Mg<sup>2+</sup> and ATP to put K<sup>+</sup> into cells)
                </li>
                <li>
                Ischemia
                </li>
                <li>
                Infection/Inflammation
                </li>
                </ol>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Blood Pressure & Pulse</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>Blood pressure can be taken by a blood pressure machine or Doppler ultrasound. Systolic blood pressure in a calm pet should be below 160 mmHg. It may approach 170 mmHg in normo-tensive cats in hospital. Multiple readings higher than this in a relatively calm pet suggests hypertension. This is usually the first test performed to limit stress-induced hypertension.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>Dogs with palpable metatarsal pulses can still be hypotensive.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Ateca, Reineke, Drobatz.</Button> {refs ? " Evaluation of the relationship between peripheral pulse palpation and Doppler systolic blood pressure in dogs presenting to an emergency service. JVECC 2018." : "" }</sup></Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>In patients presenting to the emergency room with signs of shock, the utility of an initial blood pressure is questioned by some clinicians.  Patients in compensated shock may be volume deplete and vasoconstricted. They may still have poor blood flow. Blood pressure may still be acquired, but should not slow resuscitation efforts.</Typography>
            </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Thoracic Radiographs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography gutterBottom>X-ray images of the heart can be used to measure heart size relative to vertebrae (vertebral heart score/VHS and vertebral left atrial size/VLAS) and look for evidence of chamber enlargement. With valvular heart disease, the heart gets bigger to make up for loss of function.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>Poad et al.</Button> {ref2 ? " Utility of radiographic measurements to predict echocardiographic left heart enlargement in dogs with preclinical myxomatous mitral valve disease. JVIM 2020.": ""}</sup></Typography>
            <Typography gutterBottom>In dogs with a &ge;3/6 murmur and VHS of &ge;11.5 or an increase in of 0.5 vertebral bodies in 6 months where echo is unavailable, start pimobendan at 0.25 to 0.3 mg/kg q12h<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef3(!ref3)}>Boswood et al.</Button> {ref3 ? " Effect of Pimobendan in Dogs with Preclinical Myxomatous Mitral Valve Disease and Cardiomegaly: The EPIC Study-A Randomized Clinical Trial. JVIM 2016" : ""}</sup></Typography>
            <Typography gutterBottom>Vertebral Left Atrial Size (VLAS) is an additional useful radiographic measurement to guide pimobendan initiation (as it may help differentiate stage B1 from B2 dogs). VLAS&ge;2.9 is highly specific for dogs with LA:Ao&ge;1.6.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef4(!ref4)}>Poad et al.</Button> {ref4 ? " Utility of radiographic measurements to predict echocardiographic left heart enlargement in dogs with preclinical myxomatous mitral valve disease. JVIM 2020.": ""}</sup> VLAS of 2.5 showed 100% sensitivity for identification of dogs with stage B2 MMVD.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef5(!ref5)}>Mikawa et al.</Button> {ref5 ? " Use of vertebral left atrial size for staging of dogs with myxomatous valve disease. J Vet Cardio 2020" : ""}</sup> Thus VLAS between 2.5 and 2.9 should be evaluated by echocardiography to determine if they have reached stage B2 prior to starting pimobendan.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              <a href="http://media.news.health.ufl.edu/misc/vetmed/gvi/DogBreeds/index.html" target="_blank" rel="noopener noreferrer">UF Normal Thorax Breed Comparison</a>
            </Typography>
            </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Biomarkers & Blood Tests</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography gutterBottom><span style={{fontWeight: "700"}}>Troponin I</span> is an injury marker released from cardiac myocytes following death.</Typography>
            <Typography><span style={{fontWeight: "700"}}>NT-proBNP</span> is a hormone released mostly from the left ventricle in response to wall stress and stretch&mdash;it is an appropriate test for cats with suspected left-sided failure when pet parents decline an echo.  The quantitative test must be sent to the lab and can help track progression of heart disease.  The SNAP (patient-side) test is useful for ruling out heart disease before treating suspected respiratory disease in cats. NT-proBNP is cleared by the kidney; false positives can occur if creatinine is &ge;2.8 due to renal insufficiency or prerenal azotemia.</Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
