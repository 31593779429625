import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


export default function Vestibular() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Vestibular Disease</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
                    id="weightlbs"
                    type="number" value={weightlbs} onChange={handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
          </Grid>

          <Grid item xs={12}>
                  <TextField
                            id="weightkg"
                            type="number" value={weightkg} onChange={handlekgChange}
                            label="Weight (kg)"
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                          />
          </Grid>

        </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Head tilt, nystagmus, vestibular ataxia (often falling to one side), vestibular strabismus (ventrolateral with head elevation)</Typography>
          
          <Typography style={{marginTop: '10px'}}><span style={{fontWeight: '700'}}>Differentiate Peripheral from Central:</span> Signs suggestive of <span style={{fontWeight: '700'}}>central vestibular disease</span> (or paradoxical vestibular disease) include: mentation change, proprioceptive deficits, cranial nerve deficits beyond CN 7 or Horner’s Syndrome. Head tremors (cerebellum affected) or circling may also suggest central vestibular disease although some with peripheral disease will circle.</Typography>

          <Typography style={{marginTop: '10px'}}><span style={{fontWeight: '700'}}>Acute Idiopathic Peripheral Vestibular Disease</span> is common, but other causes must be ruled out.</Typography>
          
          <Typography style={{marginTop: '10px'}}><span style={{fontWeight: '700'}}>Ddx:</span> Inflammatory causes (otitis interna, PSOM, GME), masses (polyps, neoplasia), toxic (anything that penetrates the tympanic membrane, metronidazole, aminoglycosides, furosemide), metabolic or vascular (hypothyroidism, PLE or PLN, hyperadrenocorticism, hypertension), head trauma, infectious, idiopathic</Typography>

          <Typography>
            <span style={{fontWeight: '700'}}>Diagnostics: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
              <li>
              CBC
              </li>
              <li>
              Chemistry
              </li>
              <li>
              T4
              </li>
              <li>
              Blood Pressure
              </li>
            </ul>
        </Grid>
        <Typography>
          <span style={{fontWeight: '700'}}>Key points for communication: </span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Dogs (and cats) with idiopathic vestibular disease usually show improvement within 1-3 days and are normal or almost normal within 1-2 weeks.
            </li>
            <li>
            Monitoring (usually at home) and anti-nausea medication are often all that is needed
            </li>
          </ul>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography>For treatment of suspected <span style={{fontWeight: '700'}}>Acute Idiopathic Peripheral Vestibular Disease</span>: </Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider 1 mg/kg = {(weightkg*(0.1)).toFixed(2)} mL of maropitant (Cerenia, 10 mg/mL) SC
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider subcutaneous fluids or IV fluid support if hospitalized
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider meclizine at 12.5 to 50 mg/dog or 6.24 to 12.5 mg/cat PO q24h for a few days
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          />Consider clopidogrel if underlying reason for cerebrovascular accident
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951444" target="_blank" rel="noopener noreferrer">Vestibular Disease handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
        <IconButton
          component={Link}
          size="large"
          color="primary"
          aria-label="back"
          aria-controls="back"
          aria-haspopup="false"
          to="/quick-sheets"
        >
          <ArrowBack />
        </IconButton>
        </Grid>
      </Grid>
    </>
  )


};