import React from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

import minorProcedurePdf from '../Notebook/Anesthesia/MinorProcedureAnesthesiaSheet.pdf';

const Bu = styled(Button)`
  text-align: center;
  margin: auto;
  text-transform: none;
  color: inherit;
  font-family: 'Outfit';
`;

export default function Calculators() {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: '900px', margin: 'auto'}}>
      <Typography variant="h6" sx={{fontSize: '1.3rem', mt: 2}}>Anesthesia</Typography>
      <Grid container justifyContent="center" alignItems="center" sx={{maxWidth: '600px'}}>
        <Grid item xs="auto"  sx={{mb: 2, mr: 1}}>
          <Bu href="/clinical-notebook/anesthesia/checklist" variant="outlined" sx={{width: '280px'}}>Safety Checklist</Bu>
        </Grid>
        <Grid item xs="auto" sx={{mb: 2, mr: 1}}>
          <Bu href={minorProcedurePdf} variant="outlined" target="_blank" rel="noreferrer">Minor Procedure Monitoring Sheet <PictureAsPdfIcon /></Bu>
        </Grid>
      </Grid>
      <Typography variant="h6">Neurology</Typography>
      <Grid container justifyContent="center" alignItems="center" sx={{maxWidth: '600px'}}>
        <Grid item xs="auto" sx={{mb: 2, mr: 1}}>
          <Bu href="/forms/neuro-exam" variant="outlined" sx={{width: '280px'}}>Neuro Exam Form</Bu>
        </Grid>
        <Grid item xs="auto" sx={{mb: 2, mr: 1}}>
          <Bu href="/forms/modified-glascow" variant="outlined" sx={{width: '280px'}}>Modified Glascow Coma Scale</Bu>
        </Grid>
      </Grid>
      <Typography variant="h6">Oncology</Typography>
      <Bu href="https://vetnurseguide.com/tumor-map" target="_blank" rel="noopener noreferrer" variant="outlined" sx={{width: '280px'}}>Canine Tumor Map</Bu>
      <Typography variant="h6">Ophthalmology</Typography>
      <Bu href="https://vetnurseguide.com/eye-form" target="_blank" rel="noopener noreferrer" variant="outlined" sx={{width: '280px'}}>Ophtho Exam Form</Bu>
    </Stack>
  );
}
