import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import PyometraAUS from './images/pyo-ultrasound.jpg';

export default function PyoUterus() {

  return (

        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={4}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
                <img src={PyometraAUS} alt="pyometra on ultrasound" style={{maxWidth: "98%"}}/>
                <Typography>Dilated uterine horns often have "Mickey Mouse Ears"-appearance near or just dorsal to the urinary bladder.</Typography>
                </Paper>
            </Grid>

        </Grid>


  );
}
