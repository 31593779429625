import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FelineAlfax from './FelineAlfax';
import Dkt from './Dkt';

export default function FelineSedationProtocols() {
  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feline-sedation-content"
          id="feline-sedation-header"
        >
          <Typography>Feline Sedation Protocols</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FelineAlfax/>
          <Dkt/>
        </AccordionDetails>
      </Accordion>

  );
}
