import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cataracts from './Cataracts';
import CornealUlcer from './CornealUlcer';
import Kcs from './Kcs';
import Glaucoma from './Glaucoma';
import FhvKeratoconjunctivitis from './FelineHerpesConjunctivitis';
import Proptosis from './Proptosis';
import LensLux from './LensLuxation';
import Uveitis from './Uveitis';
import CherryEye from './CherryEye';
import RetrobulbarAbscess from './RetrobulbarAbscess';

export default function CommonConditions() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Cataracts />
          <CherryEye />
          <CornealUlcer />
          <FhvKeratoconjunctivitis />
          <Glaucoma />
          <Kcs />
          <LensLux />
          <Proptosis />
          <RetrobulbarAbscess />
          <Uveitis />
        </AccordionDetails>
      </Accordion>

  );
}
