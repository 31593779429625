import React from "react";

import FelineAlfaxCalculator from './FelineAlfaxCalculator'

export default function x() {

  return (
    <>
      <h1>Feline Alfax (IM)</h1>
      <FelineAlfaxCalculator/>
  </>

  );
}
