import React from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BladderStoneRads from '../Radiology/BladderStoneRads';
import Cystotomy from '../Surgery/Cystotomy';
import Urinalysis from '../ClinicalPathology/Urinalysis';


export default function Urolithiasis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Urolithiasis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Urolithiasis is often a multifactorial disease affected by age, gender, neuter status, breed, metabolism, UTI, urine pH, diet, and other systemic disease. Stones are found incidentally or when a patient becomes obstructed.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Canine urethral obstruction is often secondary to urolithiasis. Retrograde hydropulsion under anesthesia is often necessary to relieve the obstruction. Treat hyperkalemic patients similar to how you would treat a blocked cat. 
              Decompressive cystocentesis can delay anesthesia in compromised, azotemic, hyeprkalemic patients if needed or can be used to evacuate bladder prior to retrohydropulsion attempts. Use the largest urinary catheter that will fit. 
              For flush, mix sterile lubricant (up to 50/50) with sterile saline in a 60mL syringe. Using sterile gloves, occlude the tip of the penis around your catheter as you flush (some will use dry gauze to make gripping the distal penis easier). 
              If needed, an assistant can occlude the proximal urethra per rectum with a gloved finger while you flush (to dilate the urethra) and the release digital pressure to attempt to blast stones back to the bladder.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Urinalysis />
          </Grid>
          <Grid item xs={12}>
          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Radiographs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BladderStoneRads />
              </AccordionDetails>
            </Accordion>
           </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.14559" target="_blank" rel="noopener noreferrer">ACVIM Small Animal Consensus Recommendations on the Treatment and Prevention of Uroliths in Dogs and Cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Struvite</TableCell>
                <TableCell>Calcium Oxalate</TableCell>
                <TableCell>Cystine</TableCell>
                <TableCell>Urate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             <TableRow>
                <TableCell>Signalment</TableCell>
                <TableCell>female dogs&gt;male</TableCell>
                <TableCell>small breed dogs; long-haired cats; male dogs&gt;female</TableCell>
                <TableCell>male&gt;female; intact males</TableCell>
                <TableCell>male&gt;female; Dalmations, Bulldogs, Weimaraners</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Chemistry</TableCell>
                <TableCell></TableCell>
                <TableCell>hypercalcemia or hyperadrenocorticism predispose</TableCell>
                <TableCell></TableCell>
                <TableCell>May be concern for liver shunt</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Urinalysis</TableCell>
                <TableCell>Alkaline; if UTI, think Staph (produces urease)</TableCell>
                <TableCell>Acidic; &lt;50% have CaOx crystals</TableCell>
                <TableCell>Acidic</TableCell>
                <TableCell>Acidic</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Radiographs</TableCell>
                <TableCell>large, smooth, radiodense</TableCell>
                <TableCell>small, round, irregular, radiodense</TableCell>
                <TableCell>round, smooth, radiolucent to variably radiodense</TableCell>
                <TableCell>round, smooth, radiolucent</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dissolution</TableCell>
                <TableCell>Diet that is acidifying, low protein, low Mg, low P; consider DL-Methionine (acidifier); Antibiotic if UTI</TableCell>
                <TableCell>Not possible</TableCell>
                <TableCell>Possible with low-protein, alkalinizing, diuresing</TableCell>
                <TableCell>Possible with low purine, alkalinizing, diuresing and allopurinol</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prevention</TableCell>
                <TableCell>Urinary diet; monitor/treat UTIs</TableCell>
                <TableCell>Urinary diet; consider thiazide; consider potassium citrate</TableCell>
                <TableCell>Diet; Neuter intact males; consider potassium citrate</TableCell>
                <TableCell>Low purine diet; Allopurinol</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>Minn. Urolith Tx</TableCell>
                <TableCell><Button href="https://urolithcenter.org/recommendations/11?locale=en" target="_blank" rel="noopener noreferrer">Canine</Button></TableCell>
                <TableCell><Button href="https://urolithcenter.org/recommendations/1?locale=en" target="_blank" rel="noopener noreferrer">Canine</Button></TableCell>
                <TableCell><Button href="https://urolithcenter.org/recommendations/5?locale=en" target="_blank" rel="noopener noreferrer">Canine</Button></TableCell>
                <TableCell><Button href="https://urolithcenter.org/recommendations/14?locale=en" target="_blank" rel="noopener noreferrer">Canine</Button></TableCell>
              </TableRow>
              <TableRow>
               <TableCell><Button href="https://urolithcenter.org/recommendations/26?locale=en" target="_blank" rel="noopener noreferrer">Feline</Button></TableCell>
               <TableCell><Button href="https://urolithcenter.org/recommendations/16?locale=en" target="_blank" rel="noopener noreferrer">Feline</Button></TableCell>
               <TableCell><Button href="https://urolithcenter.org/recommendations/20?locale=en" target="_blank" rel="noopener noreferrer">Feline</Button></TableCell>
               <TableCell><Button href="https://urolithcenter.org/recommendations/29?locale=en" target="_blank" rel="noopener noreferrer">Feline</Button></TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontWeight: "700"}}>Treatment Options:</Typography>
            <ul>
              <li>
                <Cystotomy />
              </li>
              <li>Dissolution</li>
              <li>Lithotripsy</li>
              <li>Voiding urohydropulsion (for very small stones)</li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.acvs.org/small-animal/urinary-stones" target="_blank" rel="noopener noreferrer">Urinary Stones</a> from ACVS
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
