import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/retainedDeciduous.jpg';
import Rd2 from './images/retainedDeciduous2.jpg';

export default function RetainedDeciduous() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Retained Deciduous Teeth</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      A common problem in young growing puppies. Retained teeth usually require surgical extraction with some much more firmly anchored than others. Be prepared for anesthesia and have dental radiographs ready.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952450" target="_blank" rel="noopener noreferrer">Retained Baby Teeth in Dogs and Cats Need Surgical Extraction</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="Retained deciduous canine" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Don't kid yourself! This baby tooth will not fall out on its own!</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd2} alt="Retained deciduous canine" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This retained deciduous canine has caused the adult tooth to come in way out of position.</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
