import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/brokenTooth.jpg';

export default function CrownFracture() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Broken Tooth (Crown Fracture)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                    Broken teeth are painful for dogs and cats. If the pulp cavity of the tooth is exposed, extraction or root canal therapy (less common) is necessary. Even if the overt signs of pain have subsided, broken teeth will continue to cause pain.
                    </Typography>
                    <Typography>
                    Chewing on hard things often results in broken teeth. Dogs love to chew; some over-do it. Rocks, bones, antlers, hard plastic toys, and ice cubes can all result in broken teeth.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="Broken tooth" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This patient presented for a large firm swelling in front of the eye. Appropriate antibiotics and anti-inflammatory pain medications were started leading up to the dental procedure. Intraoral radiographs confirmed a tooth root abscess and the affected tooth was removed.</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
