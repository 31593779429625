import React from 'react';
import { Grid, Paper } from '@mui/material';

import felineBaldderStonesVD from './images/felineBladderStones_VD.jpg';
import felineBladderStonesRlat from './images/felineBladderStones_Rlat.jpg';
import canineBladderStonesRlat from './images/canineBladderStones_Rlat.jpg';
import canineBladderStonesVD from './images/canineBladderStones_VD.jpg';

export default function PyometraRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={6}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={felineBladderStonesRlat} alt="abdominal radiograph with bladder stones" style={{maxWidth: "96%"}}/>
          <img src={felineBaldderStonesVD} alt="abdominal radiograph with bladder stones" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={canineBladderStonesRlat} alt="abdominal radiograph with bladder stones" style={{maxWidth: "96%"}}/>
          <img src={canineBladderStonesVD} alt="abdominal radiograph with bladder stones" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>Cystine and urate stones may not be radiopaque.</li>
              <li>Always include the urethra. An additional lateral view with hind limbs pulled forward may help expose stones in the urethra.</li>
            </ul>
          </Grid>

        </Grid>


  );
}
