import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GifbRads from '../Radiology/GifbRads';

export default function GIFB() {
   const [refs, setRefs] = useState(false);
   const [ref2, setRef2] = useState(false);
   const [ref3, setRef3] = useState(false);


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Gastrointestinal Foreign Body</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Gastrointestinal foreign body (FB) obstruction is common in dogs and cats with acute (and sometimes chronic) vomiting and/or diarrhea. An exploratory laparotomy should be considered in any case with high suspicion of mechanical obstruction due to FB ingestion.  If the FB limited to the stomach and other criteria make induction of emesis safe, this should be considered (even in patients already vomiting).
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Key Points for Communication</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            An exploratory laparotomy is often the best way to see what and where the problem is in a patient suspected to have a foreign body mechanical obstruction.  Sometimes we are surprised by what we find even following abdominal ultrasound.
            </li>
            <li>
            It is possible that the foreign material has passed or moved into the colon at the time of surgery.  In these lucky cases, the material should be left or produced per rectum.  Incising the colon is a big deal and seldom indicated.  Even material stuck at the ileocolic junction can often be milked into the colon and allowed to pass.
            </li>
            <li>
            A "negative" exploratory laparotomy is always possible, and pet parents should be prepared for this ahead of surgery.  In these cases, it is sometimes warranted to take intestinal biopsy samples for histopathology.
            </li>
            <li>
            Linear foreign bodies have increased risk of perforation and peritonitis which will complicate surgery and recovery.
            </li>
            <li>
            Aspiration pneumonia can be a life-threatening complication following vomiting and may not be immediately obvious on thoracic radiographs.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Diagnostics</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Chemistry may show hypochloremic metabolic alkalosis. Potassium and sodium may also be low.
            </li>
            <li>
            CBC should show a stress/inflammatory leukogram with neutrophila and lymphopenia.  Cortisol testing should be considered, especially in patients lacking a stress leukogram.
            </li>
            <li>
            SNAP cPL should be considered as a rule-out test in cases where abdominal ultrasound is not available. An "abnormal" result is expected in most patients with vomiting and is of little clinical value. A "normal" result means pancreatitis is unlikely and probably not a contributing factor to the clinical signs.
            </li>
            <li>
            <GifbRads />
            </li>
            <li>
            Ultrasound is highly accurate for diagnosing mechanical foreign body obstruction in dogs and cats. It is limited in its ability to see through gas (especially in the stomach).  It is advantageous in that it also allows for evaluation of the pancreas, small intestinal wall layering, and other abdominal organs.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Preoperative Considerations</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Fast (do not feed) for 8-12 hours (4-8 hours or less for pediatric patients).
            </li>
            <li>
            Rehydration and electrolyte imbalance correction should be performed as soon as possible and ideally before anesthesia and surgery.
            </li>
            <li>
            Consider perioperative antibiotics. Often 22 mg/kg cefazolin before induction and then every 90 minutes until 2 hours post-recovery in healthy patients without perforation, contamination, or sepsis.
            </li>
            <li>
            Treat esophagitis and aspiration pneumonia when present.
            </li>
            <li>
            Maropitant should be administered prior to induction if not done within the past 24 hours.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Gastrotomy</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Prior to surgery, consider inducing emesis or endoscopic retrieval in appropriate cases.
            </li>
            <li>
            Make a xiphoid to pubis incision.
            </li>
            <li>
            Remove falciform fat.
            </li>
            <li>
            Completely explore the abdomen including palpation of all bowel.
            </li>
            <li>
            Pack off the stomach with moistened laparotomy sponges. Most gastrotomies are clean-contaminated or contaminated.
            </li>
            <li>
            Place one or two stay sutures at a point cranial or cranial and caudal to your planned incision.
            </li>
              <ul>
              <li>
              Babcock forceps are an alternative.
              </li>
              <li>
              Additional stay sutures can be used to open the gastric lumen (place to the sides of the incision and provide traction perpendicular to the incision).
              </li>
              </ul>
            <li>
            Make a stab incision in the ventral body of the stomach between the greater and lesser curvatures.
            </li>
              <ul>
              <li>
              Use a fresh scalpel blade (NOT the same blade you used for your skin incision).
              </li>
              <li>
              Some surgeons prefer to extend the incision with Metzenbaum scissors.
              </li>
              <li>
              Stay away from the pylorus as closure of this area can contribute to outflow obstruction.
              </li>
              <li>
              The stomach will have two distinct layers which are easily separated: 1. Mucosa, 2. Submucosa and Serosa
              </li>
              </ul>
            <li>
            Use suction to carefully remove liquid contents and minimize contamination.
            </li>
            <li>
            Use an instrument (NOT your fingers) to retrieve gastric contents/foreign bodies to minimize contamination. Set these tools aside (do not use during closure).
            </li>
            <li>
            Close the gastric mucosa with Monocryl (2-0 to 3-0 in a dog; 3-0 to 4-0 in a small dog or cat) on a taper needle in a simple continuous pattern. Consider 2-layer closure with an inverting pattern such as a Cushing pattern. Double layer suture closure is stronger than stapled techniques and reinforcement with a Cushing suture improves resistance to leakage.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Duffy et al.</Button> {refs ? " Evaluation of staple line reinforcement after partial gastrectomy closure in an ex vivo canine model. Vet Surg 2021." : "" }</sup>
            </li>
              <ul>
              <li>
              PDS or Biosyn are preferred by some surgeons.
              </li>
              <li>
              PDS is affected by acidic environments and will not as long as expected in the gastric lumen.
              </li>
              </ul>
            <li>
            Close the submucosa and serosa with Monocryl (2-0 to 3-0 in a dog; 3-0 to 4-0 in a small dog or cat) on a taper needle in a simple continuous pattern.
            </li>
            <li>
            Set aside contaminated tools, change gloves if contaminated, remove laparotomy sponges.
            </li>
            <li>
            Flush the abdomen with 250 to 300 mL/kg of warmed isotonic crystalloid.  Any less will likely make NO difference.
            </li>
            <li>
            Consider a feeding tube (usually NG tube) in any patient with prolonged anorexia or expected post-recovery anorexia. Confirm placement by palpation during surgery.
            </li>
            <li>
            Routine closure of the abdomen.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Enterotomy</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Make a xiphoid to pubis incision.
            </li>
            <li>
            Remove falciform fat.
            </li>
            <li>
            Completely explore the abdomen including palpation of all bowel.
            </li>
            <li>
            Pack off the affected bowel with moistened laparotomy sponges. Most enterotomies are clean-contaminated or contaminated.
            </li>
            <li>
            Using your fingers, milk luminal contents away from the affected site.
            </li>
            <li>
            Use Doyen forceps, bobby pins, Penrose drain, or an assistant's fingers to hold contents out of the way.
            </li>
              <ul>
              <li>
              Doyen forceps must be used appropriately with intestine placed centrally in (NOT at the tip of) the clamp.
              </li>
              <li>
              An assistant's fingers usually fatigue and contribute to increased contamination.
              </li>
              </ul>
            <li>
            Make a longitudinal incision along the antimesenteric border just distal (aboral) to the foreign body and affected small intestine.
            </li>
            <li>
            Gently milk the foreign body through the incision without tearing intestine.
            </li>
            <li>
            Use moistened sponges or swabs to wipe up leakage and replace any contaminated sponges.
            </li>
            <li>
            Close the incision with Monocryl (3-0 to 4-0 in a dog; 4-0 to 5-0 in a small dog or cat) on a taper needle in a simple continuous pattern.  This is usually closed longitudinally (with your incision). Sutures should be placed 3-4mm apart and 3-4mm from the edge of the incision.
            </li>
              <ul>
              <li>
              The submucosa is the holding layer. Go full thickness. You are not accurate enough to risk a missing this layer which is often 2mm away from intestinal lumen.
              </li>
              <li>
              PDS or Biosyn are appropriate alternatives. PDS is less flexible and harder to work with.
              </li>
              <li>
              If your repair fails, it will be within 3-5 days. Monocryl will last beyond this and is stronger than the tissue you are using it in.
              </li>
              <li>
              Simple interrupted can be considered but will have more knots and thus more irritation and more opportunities for knot failure.
              </li>
              </ul>
            <li>
            Consider leak testing your closure by adding a reasonable amount of sterile saline and creating gentle pressure with your fingers.  Reinforce any weak spots in your suture line.
            </li>
              <ul>
              <li>
              This is not a test for future dehiscence. This is a test of your primary closure of the SI.
              </li>
              <li>
                Gentle probing with an instrument (interoperative surgeon probe inspection) may be superior to saline infusion leak testing.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef2(!ref2)}>Culbertson et al.</Button> {ref2 ? " Intraoperative surgeon probe inspection compared to leak testing for detecting gaps in canine jejunal continuous anastomoses: A cadaveric study. Vet Surg 2021." : "" }</sup>
              </li>
              </ul>
            <li>
            Patch over your incision with omentum.  Omentum can be carefully tacked in place with 2 simple interrupted sutures next to your incision.  Avoid circumferential wrapping as this may stricture.
            </li>
            <li>
            Consider serosal patching if high risk of delayed healing or dehiscence.
            </li>
              <ul>
              <li>
              This is done by suturing an area of healthy jejunum over your incision to create a reinforcing adhesion.
              </li>
              </ul>
            <li>
            Close any mesenteric defects (if created) to prevent future entrapment of SI.
            </li>
            <li>
            Set aside contaminated tools, change gloves if contaminated, remove laparotomy sponges.
            </li>
            <li>
            Flush the abdomen with 250 to 300 mL/kg of warmed isotonic crystalloid.  Any less will likely make NO difference.
            </li>
            <li>
            Consider a feeding tube (usually NG tube) in any patient with prolonged anorexia or expected post-recovery anorexia. Confirm placement by palpation during surgery.
            </li>
            <li>
            Routine closure of the abdomen.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Resection & Anastomosis</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ul>
              <li>
              Make a xiphoid to pubis incision.
              </li>
              <li>
              Remove falciform fat.
              </li>
              <li>
              Completely explore the abdomen including palpation of all bowel.
              </li>
              <li>
              Identify the area of bowel to be resected and visualize the vascular supply (jejunal arcadial vessels). Ligate the vessels that supply bowel that will be removed.  You may need to ligate mesenteric fat which includes terminal arcadial vessels.
              </li>
                <ul>
                <li>
                segements of jejunum should be considered in units based on the arcadial vessels that provide blood supply.  Sections must be removed in units to preserve blood supply to remaining SI.
                </li>
                </ul>
              <li>
              Pack off the affected bowel with moistened laparotomy sponges. Most enterotomies are clean-contaminated or contaminated.
              </li>
              <li>
              Using your fingers, milk luminal contents away from the affected site.
              </li>
              <li>
              Use Doyen forceps, bobby pins, Penrose drain, or an assistant's fingers to hold contents out of the way.
              </li>
                <ul>
                <li>
                Doyen forceps must be used appropriately with intestine placed centrally in (NOT at the tip of) the clamp.
                </li>
                <li>
                An assistant's fingers usually fatigue and contribute to increased contamination.
                </li>
                </ul>
              <li>
              Place crushing forceps within your Doyen forceps. This is generally done on a 30 degree angle preserving more mesenteric and less anti-mesenteric small intestine (to increase blood supply to these ends and maximize luminal diameter following end-to-end anastomosis).
              </li>
              <li>
              Cut between your Doyen and crushing forceps on this 30 degree angle.
              </li>
              <li>
              Use moistened sponges or swabs to wipe up leakage and replace any contaminated sponges.
              </li>
              <li>
              For mismatched small intestinal diameter (which is common with orad dilation), make a longitudinal incision in the antimesenteric side of the smaller bowel to allow for apposition of more closely matched openings.
              </li>
              <li>
              Close the incision with Monocryl (3-0 in a dog; 4-0 in a small dog or cat) on a taper needle in two runs of simple continuous&mdash;from mesenteric to antimesenteric border and from antimesenteric border to mesenteric border. Start with two suture packs and tie your starting knots to ensure appropriate apposition. Once you've completed a simple continuous run, tie off to the starting tail of the opposite suture line.
              </li>
                <ul>
                <li>
                PDS or Biosyn are appropriate alternatives. PDS is less flexible and may be harder to work with.
                </li>
                <li>
                Simple interrupted can be considered but will have more knots and thus more irritation and more opportunities for knot failure.  This may take 30 or more sutures in a medium dog.
                </li>
                </ul>
              <li>
              Consider leak testing your closure by adding a reasonable amount of sterile saline and creating gentle pressure with your fingers.  Reinforce any weak spots in your suture line.
              </li>
                <ul>
                <li>
                This is not a test for future dehiscence. This is a test of your primary closure of the SI. Some surgeons do not leak test.
                </li>
                <li>
                Gentle probing with an instrument (interoperative surgeon probe inspection) may be superior to saline infusion leak testing.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRef3(!ref3)}>Culbertson et al.</Button> {ref3 ? " Intraoperative surgeon probe inspection compared to leak testing for detecting gaps in canine jejunal continuous anastomoses: A cadaveric study. Vet Surg 2021." : "" }</sup>
                </li>
                </ul>
              <li>
              Close the mesentery.
              </li>
              <li>
              Patch over your incision with omentum.  Omentum can be carefully tacked in place with 2 simple interrupted sutures next to your incision.  Avoid circumferential wrapping as this may stricture.
              </li>
              <li>
              Consider serosal patching if high risk of delayed healing or dehiscence.
              </li>
                <ul>
                <li>
                This is done by suturing an area of healthy jejunum over your incision to create a reinforcing adhesion.
                </li>
                </ul>
              <li>
              Close the mesentery to prevent future entrapment of SI.
              </li>
              <li>
              Set aside contaminated tools, change gloves if contaminated, remove laparotomy sponges.
              </li>
              <li>
              Flush the abdomen with 250 to 300 mL/kg of warmed isotonic crystalloid.  Any less will likely make NO difference.
              </li>
              <li>
              Consider a feeding tube (usually NG tube) in any patient with prolonged anorexia or expected post-recovery anorexia. Confirm placement by palpation during surgery.
              </li>
              <li>
              Consider a JP drain with drainage end along entire ventrum in select cases.
              </li>
                <ul>
                <li>
                Rarely indicated
                </li>
                <li>
                In certain cases, they may help remove excess fluid, bacteria, toxins, and debris.
                </li>
                <li>
                Arguments against include the increased risk of complications including ascending infection, early occlusion, mechanical irritation and inflammation, and contribution to hypoproteinemia/electrolyte loss.
                </li>
                </ul>
              <li>
              Consider placing a central line especially in cases of septic peritonitis.
              </li>
              <li>
              Routine closure of the abdomen.
              </li>
            </ul>



          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Postoperative Considerations</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Continue GI supportive medications and analgesia.
            </li>
            <li>
            Once recovered and able to eat, offer small amounts of wet food.  An NG tube facilitates delivery of early enteral nutrition.
            </li>
            <li>
            Continue appropriate antibiotics in cases with septic peritonitis, aspiration pneumonia, or contamination.
            </li>
            <li>
            Incisions should usually be covered with a bandage while in hospital to minimize risk of hospital-acquired infection.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
