import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SebaceousAdenoma from './images/sebaceousAdenoma.jpg';
import SebaceousCells from './images/sebaceousCells.jpg';

export default function Sebaceous() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Sebaceous Gland Tumors</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Sebaceous gland tumors grow outward (exophytic), are hairless (alopecic), and have a cauliflower-like surface.  They may become traumatized (by scratching or rubbing), but they are otherwise slow growing benign tumors of older dogs.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952215" target="_blank" rel="noopener noreferrer">Benign Sebaceous Gland Tumors Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sebaceous Gland Tumor</span></div>
          <img src={SebaceousAdenoma} style={{borderRadius:"8%", maxWidth: "96%"}} alt="raised mass with rough surface"/>
          <div>Dermal, exophytic, alopecic mass with a cauliflower-like appearance&mdash;consistent with a sebaceous gland tumor</div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sebaceous Cells</span></div>
          <img src={SebaceousCells} style={{borderRadius:"50%", maxWidth: "96%"}} alt="sebaceous cells under the microscope"/>
          <div>Sebaceous cells make oils</div>
          </Paper>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
