import React, { useState } from 'react';
import { Grid, Paper, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LensLuxgif from './images/lensLux.gif';

export default function LensLux() {

  const [Ref1, setRef1] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Lens Luxation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Causes:</span> Often a primary problem in young terrier breeds. May be secondary to uveitis (this is often the case in cats with lens luxations; many will need a uveitis workup), glaucoma, trauma, or cataracts. Lens luxation or subluxation may lead to uveitis or glaucoma and anterior luxation often makes tonometry less accurate&mdash;figuring out which came first can be a challenge.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            Terrier breeds are known for lens luxation due to a genetic mutation. These often present acutely painful with squinting. If one eye is affected, the other eye is often soon-to-be affected.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            Anterior lens luxation will require emergency treatment since it will cause corneal edema, pain, and often glaucoma.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> If already in the posterior chamber, consider latanoprost (a miotic) eye drops q12h. If in the anterior chamber, offer referral (intracapsular lens extraction may be recommended) or consider
           couching (trans-corneal reduction)<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "0.8rem"}} onClick={()=>setRef1(!Ref1)}>Montgomery et al.</Button> {Ref1 ? "Trans-corneal reduction of anterior lens luxation in dogs with lens instability: a retrospective study of 19 dogs (2010-2013). Vet Ophthalmol. 2014." : "" }</sup>. 
           If attempting couching, dilate with tropicamide first, thoroughly lubricate, and use a cotton tip applicator.  Mannitol may help reduce the size of the vitreous and make a bit of space and should be considered if concern for glaucoma. Once successful, start latanaprost drops to keep lens in posterior chamber. Many of these cases will also benefit from topical anti-inflammatory (usually topical steroid if not corneal ulcer) and dorzolamide (prevent or treat the glaucoma). Systemic pain medications should be considered (NSAID, gabapentin).
          </Typography>
          </Grid>   
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={LensLuxgif} alt="posterior lens luxation" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>Posterior lens luxation. Notice the aphakic crescent.</div>
          </Paper>
          </Grid>       
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
