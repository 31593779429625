import React, { useState } from 'react';
import { Grid, Checkbox, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function GDVSurgery() {
  const [refs, setRefs] = useState(false);

  return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12} style={{fontWeight: '700'}}>
              GDV Surgery (Gastric Derotation and Gastropexy)
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
          <Grid container spacing={1}>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />
          For induction, consider ketamine/valium or alfaxalone. Minimize propofol (contributes to hypotension).
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />
          Perioperative antibiotics (usually cefazolin 22mg/kg IV before and every 90 minutes during surgery). Consider escalation to Unasyn if gastric necrosis or pneumonia.
          </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />
          For most torsions, while standing on the dog’s right side, pull up and over from the far side and push down on the near side to de-rotate the stomach.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Pass orogastric tube if not done already.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Completely explore the abdomen.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Devitalized areas of stomach may need to be removed or inverted into the stomach (gastric invagination).
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Use a continuous Lembert pattern to suture devitalized tissue into the gastric lumen.
        </li>
        <li>
        Monocryl is superior to PDS in acidic environments.
        </li>
        </ul>
        </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Right-sided incisional gastropexy is one of several gastropexy techniques.
        </Grid>
        <Grid item xs={12}>
        <ul>
          <li>Make an incision in the seromuscular layer of the gastric antrum (area between the body and pylorus).</li>
        <li>
        Make a dorsoventral incision on the right lateral body wall of equal length which will oppose the incision on the gastric antrum . Cut through the transversus abdominis muscle. This incision should be caudal to the last rib and about a palm from your laparotomy incision.
        </li>
        <ul><li>The incision in the stomach can be "stamped" onto the peritoneum to help orient the body wall incision</li>
        <li>Some prefer a horizontal incision</li></ul>
        <li>
          Using 2-0 PDS in a continuous pattern, starting at the most dorsal part of your incision and suturing ventrally along the cranial portion, suture the stomach to the body wall. Finish your simple continuous run from ventral to dorsal along the caudal margin.
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider splenectomy.
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        If splenic torsion
        </li>
        <li>
        If significant loss of blood supply (from thrombosis) or necrosis
        </li>
        <li>
        Associated with increased mortality<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>3. Mackenzie et al.</Button> {refs ? " A retrospective study of factors influencing survival following surgery for gastric dilatation-volvulus syndrome in 306 dogs. JAAHA 2010." : "" }</sup>
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider NG tube placement.
        </Grid>

        </Grid>
        </AccordionDetails>
        </Accordion>
          );
        }
