import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FelineAsthmaRads from '../Radiology/FelineAsthmaRads';

export default function FelineAsthma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Asthma (Feline)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Allergic lower airway disease often associated with environmental allergens. Chronic bronchitis can cause neutrophilic inflammation in some.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> cough, tachypnea, wheeze, may present for emergency care as difficulty breathing, may notice predominantly expiratory difficulty with effort or grunt</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
              <li>
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Thoracic Radiographs</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <FelineAsthmaRads />
              </AccordionDetails>
              </Accordion>
              </li>
              <li>
              Consider heartworm antigen testing
              </li>
              <li>
              CBC, chemistry, T4 (if older), UA, and FIV/FeLV testing are good practice
              </li>
              <li>
              Consider Fecal Baermann to rule out lungworm
              </li>
              <li>
              The SNAP proBNP (patient-side) test is useful for ruling out heart disease before treating suspected respiratory disease in cats. NT-proBNP is cleared by the kidney; false positives can occur, especially if creatinine is &ge;2.8 due to renal insufficiency or prerenal azotemia.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Acute Treatment:</Typography>
            <ul>
              <li>
              Oxygen supplementation
              </li>
              <li>
              Butorphanol 0.2 to 0.3 mg/kg IM
              </li>
              <li>
              Consider albuterol (some internists prefer levalbuterol). 90 mcg inhaled, by Aerokat chamber.
              </li>
              <li>
              Consider terbutaline 0.01 mg/kg IM or SC.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Long-term management:</Typography>
            <ul>
              <li>
              Environmental changes may help minimize irritants. Keep indoors, avoid smoke, aerosols, dusty cat litter, and add HEPA air filters.
              </li>
              <li>
              Glucocorticoids: Consider prednisolone tapered to lowest effective dose. Consider Fluticasone (can start with prednisolone) 110 mcg inhaled, by Aerokat chamber.
              </li>
              <li>
              Bronchodilators: Consider Terbutaline at 0.1 to 0.2 mg/kg PO q8-12h as needed.  Consider theophylline for long-term use.
              </li>
            </ul>
          </Grid>

          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951536" target="_blank" rel="noopener noreferrer">Asthma in Cats</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
