import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function OrthoExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Orthopedic Exam</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          A good orthopedic exam considers the signalment and history and generally follows a thorough physical exam. Orthopedic examination includes <span style={{fontWeight: "700"}}>posture and gait evaluation, standing palpation, and recumbent palpation on both sides</span>. Develop a routine that includes palpation of the muscles, joints, bones, neck, back, and tail.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Gait evaluation should be done in an area with good traction (often not your clinic floor). Navigation over a curb or stairs can be helpful to show mild ataxia or lameness. Ataxia is a neurologic problem that falls into 3 categories (proprioceptive, vestibular, cerebellar) and is distinct from wobbly walking; if you cannot categorize it, it may be better to call it stumbling or wobbly walking. Cats should be observed in a safe space and still may be reluctant to walk&mdash;in this case, look at posture and watch for offloading on the affected limb.
        </Typography>
        <Typography gutterBottom>
          Cat lameness is often scored as mild, moderate, or severe. Dog lameness can be score similarly or with a numerical scale.
        </Typography>
        <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Grade</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">1</TableCell>
                <TableCell align="left">Sound at the walk, but weight shifting and mild lameness noted at trot</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">2</TableCell>
                <TableCell align="left">Mild weight-bearing lameness noted with the trained eye</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">3</TableCell>
                <TableCell align="left">Weight-bearing lameness, typically with distinct “head bob”</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">4</TableCell>
                <TableCell align="left">Significant weight-bearing lameness</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">5</TableCell>
                <TableCell align="left">Toe-touching lameness</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">6</TableCell>
                <TableCell align="left">Non–weight-bearing lameness</TableCell>
              </TableRow>
            </TableBody>
            </Table>
            <Typography sx={{fontSize: "0.7rem"}}>Carr and Dycus. Canine Gait Analysis. Today's Vet Practice 2016.</Typography>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Standing palpation usually starts at the unaffected limbs, back, and neck, and finishes with the affected limb. Careful evaluation of the claws, nail beds, paw pads, and interdigital spaces should be included in your exam (an interdigital kukui nut may cause severe lameness). Palpate bone, muscle, and joints, and compare sides for symmetry. Evaluate range of motion of joints and end-feel. Goniometry can be used to measure range of motion of individual joints.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Recumbent examination should be performed in both lateral recumbencies. Start with unaffected limbs and work from the toes proximally. Evaluate thorough palpation and range of motion.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{fontWeight: "700"}}>
        Specific manipulations:
        </Typography>
        <ul>
          <li>
            Elbow:
          </li>
          <ul>
            <li>Hyperextension may cause pain if elbow dysplasia such as ununited anconeal process</li>
            <li>Rotation with digital pressure on medial elbow may cause pain if elbow dysplasia such as fragmented medial coronoid process</li>
          </ul>
          <li>
            Shoulder:
          </li>
          <ul>
            <li>
              Dogs with osteochondritis dissecans (OCD) may show pain on felxion and extension. Dogs with supraspinatus tendonopathy may show pain on flexion.
            </li>
          </ul>
          <li>
            Stifle:
          </li>
          <ul>
            <li>Patella luxation - Test in both directions.</li>
            <li>Cranial drawer - May require sedation to do well; positive cranial drawer supports cruciate ligament rupture.</li>
            <li>Tibial thrust - Cranial tibial thrust supports cruciate ligament rupture.</li>
            <li>Canine patients willing to sit on command may be asked for a sit test. Incomplete flexion of the stifle or tarsus may indicate stifle or tarsal pain. A normal sit with pelvic limb lameness may help localize the problem to the hip.</li>
          </ul>
          <li>
            Hip:
          </li>
          <ul>
            <li>Craniodorsal hip luxation often shifts landmarks (iliac crest, ischiatic tuberosity, greater trochanter) into a straight line (these normally form a triangle).</li>
            <li>Ortolani sign supports hip laxity (often requires sedation to do well).</li>
          </ul>
          <li>
            Lumbosacral:
          </li>
          <ul>
            <li>Palpate region on dorsal back and perform a rectal examination with palpation from ventral aspect looking for pain.</li>
          </ul>
          <li>
            Iliopsoas:
          </li>
          <ul><li>Common, underdiagnosed cause of pelvic limb lameness. Palpate directly (runs from ventral vertebral bodies and cranioventral ilium to the lesser trochanter of the femur). Hip extension with abduction and hip extension with internal rotation may stretch iliopsoas and reveal pain.</li></ul>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
