import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import styled from '@emotion/styled';

const Header = styled('h1')`
  font-family: "Spectral SC", serif;
  font-size: 1.2rem;
`;

export default function ButtonAppBar() {
  const { isAuthenticated, isSubscribed, isErrored,  userHasAuthenticated, userHasSubscribed, userHasErrored } = useAppContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  let title = location.pathname.replace(/[/-]/g, " ")
  const split_location = location.pathname.split('/')
  if ( split_location.length === 2 ) {
    title = split_location[1].replace(/[-]/g, " ")
  }
  else if (split_location.length === 3 && split_location[1] === 'clinical-notebook') {
    title = split_location[2].replace(/[-]/g, " ")
  }
  else if (split_location.length === 3 && split_location[1] === 'notes') {
    title = 'note'
  }
  if (location.pathname === "/") {
    title = "Dogs Cats Medicine"
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    userHasSubscribed(false);
    userHasErrored(false);
    handleCloseMenu();
    navigate("/login");
  }

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: '16px'}}>
      <AppBar position="static" sx={{ color: "#282c34", backgroundColor: "white" }} >
        <Toolbar>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            { location.pathname.slice(0,7) === "/signup" ? <Header style={{margin: "auto", textAlign: "center"}}>Dogs Cats Medicine</Header> :
              isAuthenticated ? <Header>{title}</Header> :
              <Header>Dogs Cats Medicine</Header>  }
          </Typography>
          {  location.pathname.slice(0,7) === "/signup" ? null :
             isErrored || (isAuthenticated && !isSubscribed) ? 
              <Button onClick={handleLogout} color="inherit">Logout</Button>
              :
              isAuthenticated ?
                <Box>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem component={Link} to="/clinical-notebook" onClick={handleCloseMenu}>The Notebook</MenuItem>
                    <MenuItem component={Link} to="/quick-sheets" onClick={handleCloseMenu}>Quick Sheets</MenuItem>
                    <MenuItem component={Link} to="/notes" onClick={handleCloseMenu}>Case Notes</MenuItem>
                    <MenuItem component={Link} to="/forms"  onClick={handleCloseMenu}>Forms</MenuItem>
                    <MenuItem component={Link} to="/calculators" onClick={handleCloseMenu}>Calculators</MenuItem>
                    <MenuItem component={Link} to="/account" onClick={handleCloseMenu}>Account</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              :
                (location.pathname === "/login" ?
                  <Button component={Link} to="/signup" color="inherit">Signup</Button>
                :
                location.pathname === "/" ?
                  <Button component={Link} to="/login" color="inherit">Login</Button>
                :
                location.pathname === "/signup" ?
                  <></>
                :
                  <Button component={Link} to="/" color="inherit">Home</Button>
                )
              }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
