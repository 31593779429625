import React from "react";

import DexTorbCalculator from './DexTorbCalculator'

export default function x() {

  return (
    <>
      <h1>Canine Dex/Torb</h1>
      <DexTorbCalculator/>
  </>

  );
}
