import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OsteosarcomaRad from '../Radiology/OsteosarcomaRad';

export default function Osteosarcoma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Osteosarcoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
            Bone tumors in dogs often cause mixed destruction and proliferation of bone.  This is seen on x-ray images.  These tumors are extremely painful and these dogs are often limping.  Appendicular osteosarcoma usually occurs away from the elbow or towards the knee (in the proximal humerus or distal radius or in the distal femur or proximal tibia).
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Diagnostics:</span> Radiographs of painful site are often sufficient
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Ddx:</span> Boney infection (osteomyelitis); consider Valley Fever (Coccidioidomycosis) in dogs from Arizona (and the Southwest United States); Hemangiosarcoma; Chondrosarcoma; Other cancer
          </Typography>
          </Grid>
          <OsteosarcomaRad />
          <Grid item xs={12}>
          <Typography>
          <a href="https://www.acvs.org/small-animal/bone-tumors" target="_blank" rel="noopener noreferrer">Bone Tumors Handout</a> <span style={{fontWeight: "500"}}>from the ACVS</span>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Treatment:</span> Pain management, limb amputation, limb-sparing techniques, radiation therapy, chemotherapy; Bisphosphonates (such as Zoledronate) may be used to help with pain
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>MST:</span> Amputation improves MST with MST 101 to 175 days for amputation alone (VSSO.org).  Carboplatin following amputation MST is 10-12 months.
          </Typography>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
