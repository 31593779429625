import React from 'react';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function InjectionSiteSarcomaFeline() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Injection Site Sarcoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Injection site sarcomas can develop after any injection, but have been associated with certain vaccines.  Keep in mind that tumor development is multifactorial and multistep, and the benefit of appropriate vaccination generally outweighs the risk of these tumors. A small lump may be present after vaccination, but if this lump hangs out for three or more months, ever gets larger than two centimeters, or continues to increase in size one month after the vaccination, then cat parents should notify the veterinary team immediately.<sup>1</sup> A lump with any of these characteristics may need to be removed, a task much more easily performed early in the disease process.  This is the reason to vaccinate cats as far distally as possible on the limb or tail.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951408" target="_blank" rel="noopener noreferrer">Injection Site Sarcoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography style={{fontSize: ".8rem"}}><sup>1</sup>2013 AAFP Feline Vaccination Advisory Panel Report. Journal of Feline Medicine and Surgery. (2013) 15, 785-808.</Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
