import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Thoracocentesis() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Thoracocentesis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    In cases of suspected pneumothorax, thoracocentesis technique described below should be modified for a dorsal tap with the expectation of air. The needle can be directed towards the body wall (from perpendicular) as you aspirate to avoid lung laceration during re-expansion.                  
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ol>
                      <li>
                      Clip and prep one or both sides of the chest. You can often drain both sides from one insertion. Consider local anesthetic (often causes delay and stinging sensation may add to patient stress).
                      </li>
                      <li>
                      Prepare a needle, over the needle catheter, or butterfly catheter (based on patient size), three-way stopcock, 30-60 mL syringe, and container for collection. It's best if a helper works the syringe plunger and stopcock. Active suction may be used for large volumes in larger patients.
                      </li>
                      <li>
                      Using sterile technique, confidently advance the needle into the pleural space, ideally while or just after selecting an insertion point by ultrasound. Usually around the 7th or 8th intercostal space near the point of the elbow. Go just cranial to the rib (blood vessels on caudal border of rib).
                      </li>
                      <li>
                      Carefully redirect your needle as needed; you may wish to have your helper save a couple milliliters of fluid in the syringe when emptying to flush back through the needle if you experience a potential occlusion/clog.
                      </li>
                      <li>
                      Be patient and use the ultrasound as needed. Just 10mL/kg can make a big difference for some patients. You may need to tap both sides or multiple sites.
                      </li>
                    </ol>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
