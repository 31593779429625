import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import PneumoniaLlat from './images/PneumoniaLlat.jpg';
import PneumoniaRlat from './images/PneumoniaRlat.jpg';
import PneumoniaVD from './images/PneumoniaVD.jpg';

export default function ChfRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Characteristics of pneumonia include: alveolar pulmonary infiltrates that can be limited to cranioventral lung lobe(s) or be more patchy or multifocal. Right middle lung lobe is most commonly affected followed by right cranial then left cranial.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={PneumoniaRlat} alt="thoracic radiograph of patient with penumonia" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={PneumoniaVD} alt="thoracic radiograph of patient with pneumonia" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={PneumoniaLlat} alt="thoracic radiograph of patient with pneumonia" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
