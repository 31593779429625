import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function MusculoskeletalDrugDosesFeline() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }


  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Drug</TableCell>
              <TableCell align="left">Dose Range</TableCell>
              <TableCell align="left">Patient Dose</TableCell>
              <TableCell align="left">Frequency</TableCell>
              <TableCell align="left">Common Dosage Forms</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        <TableRow>
              <TableCell align="left">
                Onsior (6 mg tablet)
              </TableCell>
              <TableCell>1.0-2.4 mg/kg</TableCell>
              <TableCell align="left">{(weightKg>6) ? "12 mg"
                                      : "6 mg"}
              </TableCell>
              <TableCell>q24h</TableCell>
              <TableCell>6 mg tablets (This information is for PO dosing, not injectable product)</TableCell>
            </TableRow>
            <TableRow>
            <TableCell align="left">Gabapentin</TableCell>
            <TableCell align="left">5-10 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(5)).toFixed(1)+" to "+(weightKg*(10)).toFixed(1)+" mg" : ""}</TableCell>
            <TableCell align="left">q8h</TableCell>
            <TableCell align="left">50 mg/mL solution, 100 mg capsules</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Buprenorphine (0.3 mg/mL)</TableCell>
            <TableCell align="left">0.015-0.03 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.015)/0.3).toFixed(1)+" to "+(weightKg*(0.03)/0.3).toFixed(1)+" mL" : ""}</TableCell>
            <TableCell align="left">q6-12h</TableCell>
            <TableCell align="left">Given transmucosally (absorbed through gums)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Amantadine</TableCell>
            <TableCell align="left">3-5 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(3)).toFixed(1)+" to "+(weightKg*(5)).toFixed(1)+" mg" : ""}</TableCell>
            <TableCell align="left">q24h</TableCell>
            <TableCell align="left">10 mg/mL solution</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
    </li>
    <li>
    Do not use NSAID drugs in puppies and kittens that do not yet have fully developed kidneys/renal function.
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
