import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Osteochondrosis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Osteochondrosis (OC) and Osteochondritis Dissecans (OCD)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          OC/OCD commonly causes lameness in young dogs and growing puppies. Focal abnormal thickening of cartillage with eventual necrosis of the physis and flap formation. May occur in the shoulder, elbow, tarsus, or stifle, but shoulder is most common.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Diagnostics include shoulder radiographs, arthroscopy, or advanced imaging. Radiographs often show flattening of the humeral head on the lateral view.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Arthorscopic removal of the cartilage flap is the gold standard treatment. Medical management may allow progression to severe osteoarthritis (these dogs usually present young).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=10018196" target="_blank" rel="noopener noreferrer">Osteochondritis Dissecans (OCD) in Dogs</a> from Veterinary Partner
        </Typography>
        <Typography>
        <a href="https://www.acvs.org/small-animal/osteochondrosis-of-the-shoulder" target="_blank" rel="noopener noreferrer">Osteochondrosis of the Shoulder</a> from ACVS
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
