import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function SnakeBite() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Snake Bite (Pit Viper Envenomation)</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
          <Grid item xs={12}>
          <Typography gutterBottom><span style={{fontWeight: '700'}}>Presentation:</span>  Often present with bruising, bleeding, bleeding puncture wounds and local swelling; Often a known bite from a rattlesnake, copperhead, or water moccasin</Typography>

          <Typography gutterBottom><span style={{fontWeight: '700'}}>Differential diagnoses:</span> Acute hypersensitivity reactions (which usually present with facial swelling that is not painful, bruised, or bleeding)</Typography>
          <Typography><span style={{fontWeight: '700'}}>Key Points:</span></Typography>
          <ul>
            <li>Severity of bite is challenging to predict</li>
            <li>Antivenin is the most important part of the treatment plan</li>
          </ul>
          <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span></Typography>
          <ul>
            <li>Do NOT price pet parents out of antivenin&mdash;the most important part of this treatment plan. A blood smear and patient evaluation may be sufficient to guide clinical decisions.</li>
            <li>Blood smear - evaluate % echinocytes and platelet count</li>
            <li>CBC</li>
            <li>PT/PTT</li>
            <li>Chemistry</li>
            <li>Urinalysis - NOT by cystocentesis (risk of hemorrhage), may see pigmenturia with severe envenomation</li>
          </ul>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Administer pain medication</span>
          </Grid>
          <Grid item xs={12}>
        <ul>
        <li>Consider methadone, hydro, fentanyl, buprenorphine (in mild to moderate cases)</li>
        <li>
        0.2 mg/kg methadone = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.2)/10).toFixed(2)} mL</span> of 10 mg/mL methadone IM for this patient.
        </li>
        <li>Avoid morphine (histamine release)</li>
        <li>Avoid NSAIDs</li>
        </ul>
        </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Place IV Catheter and start IV fluids</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Antivenin Administration</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>The most important part of your treatment plan, especially in moderate to severe cases</li>
              <li>Usually add 1 vial to small bag of replacement fluids and give over 30-60 minutes</li>
              <li>Average case requires 1-2 vials</li>
              <li>Recheck blood smear (echinocytes and platelets) and PT/PTT in 2-4 hours</li>
              <li>Smaller patients often need more antivenin</li>
              <li>Monitor for allergic reactions (as you would with blood products)</li>
              <ul><li>Premedication with diphenhydramine IM still done by some; value to prevent secondary allergic reactions in these cases is debated</li>
              <li>Cats are more likely to have hypersensitivity reaction or anaphylaxis; give antivenin more slowly to cats</li></ul>
            </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />Do NOT give corticosteroids
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />NSAIDs are not recommended as they increase the risk of AKI and GI ulceration.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />Prophylactic antibiotics are NOT recommended<sup>1</sup>
          </Grid>
          <ul><li>Do recommend antibiotics in wounds that become necrotic or when abscesses form.  Ideally, following culture to best direct therapy.</li></ul>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Continue monitoring</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>Best case scenario discharged in 12 hours</li>
              <li>Hemodynamically stable and bleeding has stopped</li>
              <li>Pain well controlled, transitioned to oral medication</li>
              <li>Normal PT/PTT and platelets</li>
              <li>Echinocytes mostly resolved</li>
              <li>Swelling not progressing, maybe improving (will sometimes take days to weeks to resolve)</li>
              <li>If patient is worsening or not improving, consider giving more antivenin</li>
            </ul>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Medications to consider:
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Gabapentin</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              10-20 mg/kg = {(weightkg*(10)/(1)).toFixed(1)} mg to {(weightkg*(20)/(1)).toFixed(1)} mg q8-12h
            </li>
            <li>
              Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
            </li>
            <li>
              Can round up dose and/or increase depending on patient response
            </li>
            </ul>
            </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
            Wounds associated with snake bites can require extensive follow up care. Even if appears to be improving, recheck with a veterinarian within 2-3 days. Recheck sooner if worsening.
            </li>
            <li>
            Swelling may take several days or a couple weeks to improve, but should not be getting worse.  Recheck if worsening swelling or obvious discharge or bleeding from bite site.
            </li>
            <li>
            Serious complications can be life threatening. Recheck immediately if not eating, vomiting, or worsening in any way.
            </li>
            </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item xs={12}>
                References:
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <p><sup>1</sup>Carr et al. Prospective evaluation of the incidence of wound infection in rattlesnake envenomation in dogs. JVECC 2015.</p>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>

          <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};