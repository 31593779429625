import React, { useState } from "react";
import { Grid, TextField, InputAdornment, Paper, Checkbox, Typography } from '@mui/material';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="pet-name"
                  label="Patient"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
                  id="procedure"
                  label="Procedure"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="anesthetist-name"
                  label="Anesthetist"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
                  id="surgeon-name"
                  label="Surgeon"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
        <TextField
        id="date"
        type="date"
        defaultValue=""
        margin="normal"
        size="small"
        />
        </Grid>
      </Grid>
    <Grid container spacing={0} alignItems="stretch">
    <Grid item xs={12} lg={4}>
      <Paper>
      <Paper>
      <h2>Sign in pre-induction</h2>
      </Paper>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={0} sx={{pl: 2}}>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Patient Confirmed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Responsible vet contacted</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Procedure confirmed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Site confirmed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Positioning confirmed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Theatre Ready</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Timing agreed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
      </Grid>
      </Paper>
    </Grid>
    <Grid item xs={12} lg={4}>
      <Paper>
      <Paper>
      <h2>Time out pre-procedure</h2>
      </Paper>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={0} sx={{pl: 2}}>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Introductions (names and roles)</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12}>
        <Typography sx={{fontWeight: '600'}}>Surgeon Confirms:</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '400'}}>Patient ID</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '400'}}>Site</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '400'}}>Procedure</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '400'}}>Responsible vet</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '400'}}>Antibiotics given</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{pt: 0, pb:0}}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Discuss anticipated problems/complications</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Radiographs displayed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Eyes lubed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Number of Gauze</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
                  id="gauze"
                  margin="none"
                  size="small"
                  multiline
                />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
      </Grid>
      </Paper>
    </Grid>
    <Grid item xs={12} lg={4}>
      <Paper>
      <Paper>
      <h2>Sign out</h2>
      </Paper>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={0} sx={{pl: 2}}>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Number of Gauze</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
                  id="gauze"
                  margin="none"
                  size="small"
                  multiline
                />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Analgesia post-op plan discussed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>List of post-op concerns written/discussed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Kennel Prepared</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Responsible vet and/or nurse informed</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
        <Grid item xs={12} md={3}>
        <Typography sx={{fontWeight: '600'}}>Bladder Checked</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />Yes
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />No
        </Grid>
        <Grid item xs={12} sm={3}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />N/A
        </Grid>
      </Grid>
      </Paper>
    </Grid>
    <Grid item xs={12}>
    <Typography sx={{fontWeight: '600'}}>Notes</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{pr: 2}}>
      <TextField
              id="notes"
              margin="dense"
              type="textarea"
              multiline
              fullWidth
            />
    </Grid>
    </Grid>

    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
      Modified from: Menound et al. Development and Implementation of a Perianesthetic Safety Checklist in a Veterinary University Small Animal Teaching Hospital. Front Vet Sci. 2018.
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
