import React from "react";
import { Grid } from '@mui/material';
import TopicButton from '../../components/TopicButton';

import anesthesia from './images/anesthesia.jpg';
import behavior from './images/behavior.jpg';
import cardio from './images/cardio.jpg';
import clinpath from './images/clinpath.jpg';
import dentistry from './images/dentistry.jpg';
import derm from './images/derm.jpg';
import emergency from './images/emergency.jpg';
import euth from './images/euth.jpg';
import im from './images/im.jpg';
import musculoskeletal from './images/musculoskeletal.jpg';
import neuro from './images/neuro.jpg';
import nutrition from './images/nutrition.jpg';
import onco from './images/onco.jpg';
import ophtho from './images/ophtho.jpg';
import wellness from './images/wellness.jpg';
import radiology from './images/radiology.jpg';
import repro from './images/repro.jpg';
import surgery from './images/surgery.jpg';

function NotebookMain() {
  return (
      <Grid container justifyContent="center" spacing={2} sx={{marginTop: '16px'}}>
        <Grid item>
          <TopicButton route="/clinical-notebook/anesthesia" topic="Anesthesia" pic={anesthesia}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/behavior" topic="Behavior" pic={behavior}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/cardiology" topic="Cardiology" pic={cardio}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/clinical-pathology" topic="Clinical Pathology" pic={clinpath}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/dentistry" topic="Dentistry" pic={dentistry}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/dermatology" topic="Dermatology" pic={derm}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/emergency" topic="Emergency" pic={emergency}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/euthanasia" topic="Euthanasia" pic={euth}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/internal-medicine" topic="Internal Medicine" pic={im}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/musculoskeletal" topic="Musculoskeletal" pic={musculoskeletal}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/neurology" topic="Neurology" pic={neuro}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/nutrition" topic="Nutrition" pic={nutrition}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/oncology" topic="Oncology" pic={onco}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/ophthalmology" topic="Ophthalmology" pic={ophtho}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/radiology" topic="Radiology" pic={radiology}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/reproduction" topic="Reproduction" pic={repro}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/surgery" topic="Surgery" pic={surgery}/>
        </Grid>
        <Grid item>
          <TopicButton route="/clinical-notebook/wellness" topic="Wellness" pic={wellness}/>
        </Grid>
      </Grid>
  );
}

export default NotebookMain;
