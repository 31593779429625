import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import CHFLlat1 from './images/CHFLlat1.jpg';
import CHFRlat1 from './images/CHFRlat1.jpg';
import CHFVD1 from './images/CHFVD1.jpg';

export default function ChfRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Classic criteria for CHF on radiographs include: <span style={{fontWeight: "700"}}>1. pulmonary edema (interstitial/alveolar pattern, often perihilar), 2. left atrial enlargement, and 3. pulmonary venous distension</span>, although cases of acute ruptured chordae tendineae may not show all three signs.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={CHFRlat1} alt="thoracic radiograph of patient with CHF" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={CHFVD1} alt="thoracic radiograph of patient with CHF" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={CHFLlat1} alt="thoracic radiograph of patient with CHF" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
