import React, { useState } from 'react';
import { Grid, InputAdornment, TextField, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

export default function CatNutritionalSupport() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        
          <Grid item xs={12} style={{textAlign: 'left'}}>
          <Typography style={{fontWeight: '700'}}>Antiemetics and gastrointestinal prokinetics</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Maropitant (Cerenia, 10 mg/mL for injection or tablets PO)</TableCell>
                  <TableCell align="left">Antiemetic, NK<sub>1</sub>R antagonist</TableCell>
                  <TableCell align="left">1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span> IV or SC q24h, or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span> PO</TableCell>
                  <TableCell align="left">May also provide visceral analgesia<sup>3</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Ondansetron (2 mg/mL solution; 4 mg and 8 mg tablets available)</TableCell>
                  <TableCell align="left">Antiemetic, 5HT<sub>3</sub> antagonist</TableCell>
                  <TableCell align="left">0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3/2)).toFixed(2)} mL</span> IV or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3)).toFixed(1)} mg</span> PO q8-12h</TableCell>
                  <TableCell align="left">Can use in conjunction with maropitant</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Metoclopramide (5 mg/mL solution; 5 mg and 10 mg tablets available)</TableCell>
                  <TableCell align="left">Prokinetic, dopamine antagonist at low doses</TableCell>
                  <TableCell align="left">2 mg/kg/day CRI = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2/5/24)).toFixed(2)} mL/hr</span><br/>0.2 - 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2/5)).toFixed(2)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5/5)).toFixed(2)} mL</span> SC<br/>or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2)).toFixed(1)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO TID</TableCell>
                  <TableCell align="left">Some advise against as dopamine antagonism may decrease perfusion of the pancreas; weak anti-emetic; contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cisapride (compounded)</TableCell>
                  <TableCell align="left">Prokinetic, 5HT< sub>4</sub> agonist</TableCell>
                  <TableCell align="left">0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO q8-12h </TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Erythromycin</TableCell>
                  <TableCell align="left">Prokinetic</TableCell>
                  <TableCell align="left">0.5 to 1 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span></TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>   
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Typography style={{fontWeight: '700'}}>Appetite stimulants</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Mirtazapine</TableCell>
                  <TableCell align="left">¼ of a 7.5 mg tablet (1.875 mg) PO; 5HT<sub>3</sub> antagonist (so may make ondansetron less effective); transdermal (Mirataz) is alternative to tablets</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Capromorelin (Elura, 20 mg/mL)</TableCell>
                  <TableCell align="left">2 mg/kg PO q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2)/20).toFixed(1)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Gabapentin<sup>4</sup></TableCell>
                  <TableCell align="left">5 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5)).toFixed(0)} mg</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cyproheptadine</TableCell>
                  <TableCell align="left">Not great for critically ill cats, tastes bad, can reverse adverse effects of mirtazapine; 1 to 4 mg/cat PO q24h</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Typography style={{fontWeight: '700'}}>Feeding Tubes</Typography>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={{fontWeight: '700'}}>Oral assisted feeding</TableCell>
                  <TableCell align="left">Treatments should be done with cat out of the kennel and from behind (not approaching from the face); 1 mL is oral capacity of an adult cat, so feed 1 mL or less at a time; If done incorrectly, this will create food aversion</TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left" style={{fontWeight: '700'}}>Nasogastric (NG) or nasoesophageal (NE) feeding tube</TableCell>
                  <TableCell align="left">3-5 Fr; can be uncomfortable and disrupt normal eating; limited to liquid diets<br/>            
                  <a href="https://www.youtube.com/watch?v=6Uemr4UaA_w&ab_channel=VetLogic" target="_blank" rel="noopener noreferrer">Placeing a naso-oesophageal tube and feeding (YouTube)</a> from VetLogic
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left" style={{fontWeight: '700'}}>Esophagostomy feeding tube (E-tube)</TableCell>
                  <TableCell align="left">10-16 Fr; great option; may need to delay placement for 1-2 days based on patient<br/>Do NOT use mouth gag during placment<br/>
                  <a href="https://www.youtube.com/watch?v=qF14Jfajkhw&ab_channel=MILAInternational%2CInc" target="_blank" rel="noopener noreferrer">MILA Retrograde E Tube Placement (YouTube)</a> from MILA International<br/>
                  <a href="https://www.youtube.com/watch?v=ddJ3jtRIqYw&ab_channel=SherinShah" target="_blank" rel="noopener noreferrer">Esophagostomy Tube Placement in Cats (YouTube)</a> from Sherin Shah<br/>
                  <a href="https://www.youtube.com/watch?v=n1jLCXTII6I&ab_channel=atDove" target="_blank" rel="noopener noreferrer">Esophagostomy Tube Placement in a Cat (YouTube)</a> from atDove<br/>
                  <a href="https://www.youtube.com/watch?v=h0km9T35rIw&t=371s&ab_channel=JorgensenLaboratories%2CInc." target="_blank" rel="noopener noreferrer">Esophagostomy Tube Insertion Device (J1083)</a> from Jorgensen Laboratories
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            High protein, low carbohydrate is best; eating anything is better than eating nothing             
            </li>
            <li>Start at 1/4 to 1/3 RER and increase by 1/4 to 1/3 each day</li>
          </ul>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">¼ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅓ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">½ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/2).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅔ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*2/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">¾ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*3/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">RER = 70 x (kg)^0.75</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))).toFixed(0)} kcal/day</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>



          <Grid item xs={12}>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>
      </Grid>
    </>
  )


};