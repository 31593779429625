import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EmesisProcedure from './EmesisProcedure';
import TracheostomyProcedure from './TracheostomyProcedure';
import Pericardiocentesis from './Pericardiocentesis';
import Thoracocentesis from './ThoracocentesisProcedure';
import FourQuadrantTap from './FourQuadrantTap';


export default function EmergencyProcedures() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Emergency Procedures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FourQuadrantTap />
          <EmesisProcedure />
          <Pericardiocentesis />
          <Thoracocentesis />
          <TracheostomyProcedure />
        </AccordionDetails>
      </Accordion>


  );
}
