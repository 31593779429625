import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox, Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CornealUlcer() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Corneal Ulcer</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: '700'}}>Presentation: </span>
          Red, squinting, tearing, eye discharge, signs of eye pain/discomfort
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Ddx: </span>
          Keratoconjunctivitis sicca (KCS, dry eye), conjunctivitis, conjunctival foreign body, glaucoma
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Key Points: </span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: "10px"}}>
            <li>
            Have KCS in mind in cases of central corneal ulcers especially in breeds predisposed to KCS or with lagophthalmos. In cases of KCS start optimmune or tacrolimus (with treatment of ulcer).
            </li>
            <li>
            Be mindful of Pannus (Chronic Superficial Keratitis) in German Shepherd Dogs and eosinophilic keratitis in cats - both of which are often treated with steroids.
            </li>
            <li>
            For indolent ulcers, consider cotton-tipped debridement followed by diamond burr debridement or grid keratotomy and allow 2 weeks for healing.
            </li>
            <li>
            In cats, Conjunctivitis is usually due to FHV-1 and/or Chlamydophila felis. Feline Herpes Virus 1 (FHV-1) causes corneal ulcers and erosions. Partial thickness corneal erosions will not stain with fluorescein (must use rose bengal). Do not use steroids in cases suspected to have FHV-related keratoconjunctivitis. Antiviral drops (such as Cidofovir), oral famciclovir, and erythromycin (if ulceration) are best.
            </li>
            <li>
            In cats with corneal ulceration, monitor for development of corneal sequestra (dark brown to black pigment).
            </li>
            <li>
            In cats, medications with neomycin or polymyxin B have been reported to cause anaphylaxis and death (rarely) and should be avoided. Do NOT prescribe NeoPolyBac or Terramycin (oxytetracycline with polymyxin B). Erythromycin is a better choice for coverage against Chlamydophila felis and Mycoplasma.
            </li>
            <li>
            Recheck within 7 days. Sooner if worsening redness, swelling, discharge, or pawing at the eye/rubbing the face. “When the superficial ulcer does not heal in 7 days, change the diagnosis, not the antibiotic.”<sup>1</sup>
            </li>
          </ul>
          <Typography>
          <span style={{fontWeight: '700'}}>Diagnostics: </span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: "10px"}}>
            <li>
            STT - Not usually performed in cats. Measured over 1 minute in dogs. Normal dogs have an STT of ≥15. 10-15 may suggest KCS. ≤10 confirms KCS.
            </li>
            <li>
            Fluorescein stain - if no uptake, does not rule out FHV-related corneal erosions in cats.
            </li>
            <li>
            Tonometry -  Do not attempt if concerned for fragile or ruptured cornea. The lowest reading is most accurate. 10 - 20 mmHg is normal. It should always be ≤ 25 mmHg. 8 mmHg difference between eyes may be abnormal. ≥ 25 mmHg with vision loss is glaucoma. Low IOP is consistent with uveitis. Uveitis with normal IOP may suggest glaucoma.
            </li>
            <li>
            Chemistry panel that includes renal and liver values should be offered prior to prescribing NSAID drugs.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sx={{mt: '15px', mb: '15px'}}>
          <Button href="https://vetnurseguide.com/eye-form" target="_blank" rel="noopener noreferrer" variant="outlined">Ophtho Exam Form</Button>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Place patient in E-collar right away</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} sx={{m: '15px'}}>
          <Typography style={{fontWeight: '700'}}>
          For simple superficial corneal ulcers, consider:
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          NeoPolyBac ointment (DOGS)
          </Typography>
          <Typography>
          Put a small bead into the affected eye 3 times each day for 7-10 days.  Recheck promptly if worsening redness, swelling, discharge, or pawing at the eye/rubbing the face.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Erythromycin (CATS)
          </Typography>
          <Typography>
          Put a small bead into the affected eye 3 times each day for 7-10 days.  Recheck promptly if worsening redness, swelling, discharge, or pawing at the eye/rubbing the face.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Atropine
          </Typography>
          <Typography>
          Can apply once in hospital. A single application is usually sufficient.
          </Typography>
          <Typography>
          Use in cases with reflex uveitis (miosis suggests this even if anterior chamber appears clear)
          </Typography>
          <Typography>
          Atropine is a mydriatic that also causes paralysis of the ciliary body (pain relief for uveitis, reflex uveitis with corneal ulceration) and lasts 24 hours to 14 days. It can cause KCS, worsen glaucoma, and cause hypersalivation (especially in cats).
          </Typography>
          <Typography>
          <span style={{textDecoration: 'underline'}}>NSAID</span> - carprofen or meloxicam for dogs; robenacoxib (Onsior) for cats
          </Typography>
          <Typography>
          2.2 mg/kg (carprofen) = {(weightkg*2.2).toFixed(1)} mg (round down) PO q12h
          </Typography>
          <Typography>
          0.1 mg/kg (meloxicam) = {(weightkg*(0.1/1.5)).toFixed(2)} mL of 1.5 mg/mL suspension PO q24h
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Gabapentin
          </Typography>
          <Typography>
          10 mg/kg = {(weightkg*10).toFixed(1)} mg PO q8h
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{m: '15px'}}>
          <Typography style={{fontWeight: '700'}}>
          For deep, infected, or melting corneal ulcers, consider:
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Ofloxacin and Tobramycin drops (DOGS)
          </Typography>
          <Typography>
          This combination works well for many deep, infected ulcers. Frequency of administration is based on severity. Wait at least 5 minutes between different drops; ideally stagger administration times.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Ciprofloxacin ophthalmic drops (CATS or DOGS)
          </Typography>
          <Typography>
          Frequency of administration is based on severity.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Serum drops
          </Typography>
          <Typography>
          Have an anti-collagenase effect.
          </Typography>
          <Typography>
          Should be used q1-2 hours for first 36 hours then q4-6 hours for total of 5-7 days.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Atropine
          </Typography>
          <Typography>
          Use up to every 12 hours to keep pupil dilated.
          </Typography>
          <Typography>
          Use in cases with reflex uveitis (miosis suggests this even if anterior chamber appears clear), descemetoceles, melting ulcers, corneal perforations (do not use ointment if perforated)
          </Typography>
          <Typography>
          Atropine is a mydriatic that also causes paralysis of the ciliary body (pain relief for uveitis, reflex uveitis with corneal ulceration) and lasts 24 hours to 14 days. It can cause KCS, worsen glaucoma, and cause hypersalivation (especially in cats).
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Doxycycline
          </Typography>
          <Typography>
          10 mg/kg  = {(weightkg*10).toFixed(1)} PO q24h for 14 days for anti-collagenase properties
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Hyaluronic Acid
          </Typography>
          <Typography>
          Optixcare Eye Lube Plus or Remend Corneal Repair Gel
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Oral broad-spectrum antibiotic
          </Typography>
          <Typography>
          Clavamox may be a good empirical choice
          </Typography>
          <Typography>
          <span style={{textDecoration: 'underline'}}>NSAID</span> - carprofen or meloxicam for dogs; robenacoxib (Onsior) for cats
          </Typography>
          <Typography>
          2.2 mg/kg (carprofen) = {(weightkg*2.2).toFixed(1)} mg (round down) PO q12h
          </Typography>
          <Typography>
          0.1 mg/kg (meloxicam) = {(weightkg*(0.1/1.5)).toFixed(2)} mL of 1.5 mg/mL suspension PO q24h
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Gabapentin
          </Typography>
          <Typography>
          10 mg/kg = {(weightkg*10).toFixed(1)} mg PO q8h
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{m: '15px'}}>
          <Typography style={{fontWeight: '700'}}>
          For indolent ulcers (canine-specific), consider:
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Terramycin (Oxytetracycline with Polymyxin B) ointment
          </Typography>
          <Typography>
          Put a small bead into the affected eye 4 times each day for 14 days.  Recheck promptly if worsening redness, swelling, discharge, or pawing at the eye/rubbing the face.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Serum drops
          </Typography>
          <Typography>
          Have an anti-collagenase effect.
          </Typography>
          <Typography>
          Should be used q1-2 hours for first 36 hours then q4-6 hours for total of 5-7 days.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Atropine
          </Typography>
          <Typography>
          Can apply once in hospital. A single application is usually sufficient.
          </Typography>
          <Typography>
          Use in cases with reflex uveitis (miosis suggests this even if anterior chamber appears clear)
          </Typography>
          <Typography>
          Atropine is a mydriatic that also causes paralysis of the ciliary body (pain relief for uveitis, reflex uveitis with corneal ulceration) and lasts 24 hours to 14 days. It can cause KCS, worsen glaucoma.
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          NSAID
          </Typography>
          <Typography>
          2.2 mg/kg (carprofen) = {(weightkg*2.2).toFixed(1)} mg (round down) PO q12h
          </Typography>
          <Typography>
          0.1 mg/kg (meloxicam) = {(weightkg*(0.1/1.5)).toFixed(2)} mL of 1.5 mg/mL suspension PO q24h
          </Typography>
          <Typography style={{textDecoration: 'underline'}}>
          Gabapentin
          </Typography>
          <Typography>
          10 mg/kg = {(weightkg*10).toFixed(1)} mg PO q8h
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        <Grid item xs={12}>
          <a href="https://petparenthandouts.com/corneal-ulcers" target="_blank" rel="noopener noreferrer">Corneal Ulcers</a> <span style={{fontWeight: "500"}}>from Pet Parent Handouts</span>
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.acvo.org/common-conditions-1/2018/2/2/corneal-ulcer" target="_blank" rel="noopener noreferrer">Corneal Ulcers</a> <span style={{fontWeight: "500"}}>from ACVO</span>
        </Grid>
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951434" target="_blank" rel="noopener noreferrer">Corneal Ulcers</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup><sup>1</sup>Maggs, Miller, and Ofri. Slatter's Fundamentals of Veterinary Ophthalmology, 4e. Saunders 2007.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};