import React, { useState, useEffect } from "react";
import styled from '@emotion/styled';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import LoaderButton from "../components/LoaderButton";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { getUser } from "../lib/userLib";
import oncology from './Notebook/images/onco.jpg';
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";


const FillPicDiv = styled('div')`
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  display: block;
  margin: auto;
  align-items: center;
`;

const FillPicImg = styled('img')`
  width: 100%;
`;

function Account() {
  const [user, setUser] = (useState({userEmail: "", userFirstName: ""}))
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    const user = await getUser();
    setUser(user);
  }

  async function handleManageSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      let session = ""
      session = await API.post("notes", "/create-customer-session", {body: user.stripeCustomerId} )
      window.location.href = session.url
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    return true
  }

  return (
    <Stack spacing={2} justifyContent='center' alignItems='center' sx={{maxWidth: '900px', margin: 'auto'}}>
      <Typography variant="h1" sx={{fontFamily: "Outfit", textAlign: 'center', fontWeight: '900', mt: 4, mb: 6, fontSize: '2.5rem'}}>
        Welcome back, {user.userFirstName}
      </Typography>
      <TextField
        disabled={true}
        id="email"
        type="email"
        value={user.userEmail}
        label="Email"
        sx={{width: '300px'}}
      />
      <Typography  sx={{fontFamily: "Outfit", textAlign: 'center', fontSize: '1.2rem'}}>
        If you're having an account related issue please email:
      </Typography>
      <Typography  sx={{fontFamily: "Outfit", textAlign: 'center', fontSize: '1.2rem'}}>
        info@dogscatsmedicine.com
      </Typography>
      <Typography  sx={{fontFamily: "Outfit", textAlign: 'center', fontSize: '1.2rem'}}>
        Also feel free to call or text the person working on this page, David:
      </Typography>
      <Typography  sx={{fontFamily: "Outfit", textAlign: 'center', fontSize: '1.2rem'}}>
        262-203-6872
      </Typography>
      <LoaderButton 
        type="submit" 
        variant="contained" 
        isLoading={isLoading}
        disabled={!(user.stripeCustomerId)}
        onClick={handleManageSubmit}
        style={{marginTop: '10px', fontFamily: "Outfit", textAlign: 'center', fontSize: '1.1rem', textTransform: "none"}}>
        Manage Subscription
      </LoaderButton>
      <Typography  sx={{fontFamily: "Outfit", textAlign: 'center', fontSize: '1rem'}}>
        DogsCatsMedicine.com partners with Stripe for simplified subscriptions.
      </Typography>
      <FillPicDiv>
        <FillPicImg src={oncology} alt={"dog image"}/>
      </FillPicDiv>
      <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
        <Grid item>
          <Button component={Link} to="/terms" size="large" sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.1rem', textTransform: "none"}}>Terms & Conditions</Button>
        </Grid>
        <Grid item>
          <Button component={Link} to="/privacy" size="large" sx={{fontFamily: "Outfit", textAlign: "center", fontSize: '1.1rem', textTransform: "none"}}>Privacy Policy</Button>
        </Grid>
        <Grid item>
          <Typography sx={{fontFamily: "Outfit", fontSize: '1.1rem'}}>info@dogscatsmedicine.com</Typography>
        </Grid>
      </Grid>
      
    </Stack>
  );
}

export default Account;
