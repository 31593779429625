import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hypothyroidism() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hyperthyroidism</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              The cause is not well understood, but hyperthyroidism affects many older cats&mdash;often these are skinny cats that may or may not have a heart murmur, kidney disease, and hypertension. Hyperthyroidism occurs in about 10% of US cats over 10 years old.
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Weight loss, increased appetite, GI signs (including vomiting), hyperactivity</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Key points for diagnosis:</Typography>
            <ul>
              <li>
              Elevated total T4 (which is included on many senior panels and should be offered to all senior cats).
              </li>
              <li>
              Many hyperthyroid older cats will have concurrent disease (renal disease, heart disease, other disease) which may decrease total T4.
              </li>
              <li>
              Older cats should not have a total T4 in the upper half of the reference range, so this should increase your clinical suspicion and may warrant further testing (with free T4 by equilibrium dialysis).
              </li>
              <li>
              Always include total T4 measurement with free T4&emdash;if total T4 is in the lower half of the reference range, but free T4 is elevated, your patient is not hyperthyroid.
              </li>
              <li>
              Check blood pressure, kidney values, and urinalysis in older cats.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Treatment options:</Typography>
            <ul>
              <li>
              Medical (methimazole pill, liquid, or transdermal)
              </li>
              <li>
                Radioactive Iodine
              </li>
              <li>
              Surgical thyroidectomy
              </li>
              <li>
                Diet (Hill's y/d; only works if no other food and indoor only)
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography>Treatment will unmask azotemia in some patients. Watch for improvement in weight/body condition, hair coat, and behavioral changes.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>For cats on methimazole, warn of adverse effects which include facial pruritus and recheck total T4 in 2-3 weeks (4 weeks if transdermal elected).</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://journals.sagepub.com/doi/full/10.1177/1098612X16643252" target="_blank" rel="noopener noreferrer">2016 AAFP Guidelines for the Management of Feline Hyperthyroidism</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951398" target="_blank" rel="noopener noreferrer">Signs, Symptoms, and Diagnosis of Hyperthyroidism in Cats</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://petparenthandouts.com/hyperthyroidism" target="_blank" rel="noopener noreferrer">Hyperthyroidism</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
