import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import ChfRads from '../Radiology/ChfRads';

export default function CardioHeartFail() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Heart Failure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/chf" variant="outlined">CHF Clinical Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>Think about circulatory failure vs myocardial failure. Congestive heart failure (backwards) is more common than low output (forwards) failure.  Pulmonary edema is a sign of left sided heart failure.  Right sided heart failure often causes pleural or peritoneal effusion.  Pleural effusion in the cat may be due to left or right sided failure. Pericardial effusion in the cat is heart failure until proven otherwise.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Thoracic Radiographs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChfRads />
              </AccordionDetails>
            </Accordion>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8501760" target="_blank" rel="noopener noreferrer">Congestive Heart Failure in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
