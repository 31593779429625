import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.acvs.org/" target="_blank" rel="noopener noreferrer">American College of Veterinary Surgeons</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://ivapm.org/" target="_blank" rel="noopener noreferrer">International Veterinary Academy of Pain Management (IVAPM)</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/2022-aaha-pain-management-guidelines-for-dogs-and-cats/home/" target="_blank" rel="noopener noreferrer">2022 AAHA Pain Management Guidelines for Dogs and Cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://curacore.org/vet/" target="_blank" rel="noopener noreferrer">CuraCore Evidence-Based Integrative Medicine</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://wsava.org/global-guidelines/global-pain-council-guidelines/" target="_blank" rel="noopener noreferrer">WSAVA Global Pain Council Guidelines</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
