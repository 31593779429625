import React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ProptosisImage from './images/proptosis.jpg';

import TempTarsorrhaphy from './TempTarsorrhaphy';


export default function Proptosis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Proptosis (of the Globe)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto" style={{paddingBottom: "20px"}}>
            <Button href="/quick-sheets/proptosis" variant="outlined">Proptosis Clinical Quick Sheet</Button>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Proptosis of the Globe</span></div>
            <img src={ProptosisImage} alt="dry eye" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>The eyelids have become entrapped behind the eye.</div>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography>
            Dogs with proptosis of the globe are unable to blink because the eyelids are trapped behind the globe.  Brachycephalic breeds are at increased risk due to their shallow orbital socket.
          </Typography>
          <Typography>
          <span style={{fontWeight: "700"}}>Treatment:</span> If the globe can be saved, temporary partial tarsorrhaphy should be performed under anesthesia. If the globe is ruptured, there is hyphema, or orbital bones are fractured, enucleation should be considered.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <TempTarsorrhaphy />
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
