import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import DleNose from './skinLesionImages/DleNose.jpg';


export default function Dle() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Discoid Lupus Erythematosus</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Usually starts as area of depigmentation on nasal planum that progresses to loss of normal texture and then may become ulcerated and crusted. The nasal planum may be sensitive to sunlight.</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={DleNose} alt="Ulcerated nasal planum" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This nose has lost its normal cobblestone texture.</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12}><Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Biopsy is most helpful</Typography></Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Systemic corticosteroids are usually not needed. 
                  </li>
                  <li>
                  Topical steroid for the nasal planum, consider sunscreen (when outside); consider topical tacrolimus
                  </li>
                  <li>
                  Vitamin E supplementation; fish oil supplementation
                  </li>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
