import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import styled from '@emotion/styled';
import { getUser } from "../lib/userLib";

const StepperStack = styled(Stack)`
  padding: 30px 10px;
  max-width: 500px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
`;


export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isSubscribed, userHasAuthenticated, userHasSubscribed } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fields, handleFieldChange] = useFormFields({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const [canceled, setCanceled] = useState(false);
  const [success, setSuccess] = useState(false);
  const special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSession(id) {
    const session = API.post("notes", "/subcheck", {body: {stripeCheckoutId: id}} );
    return session;
  }

  function updateUser(session) {
    const userUpdate = API.put("notes", "/users", {
      body: {stripeEmail: session.customer_details.email, paymentStatus: session.payment_status, stripeCustomerId: session.customer}
    });
    return userUpdate
  }

  async function onLoad() {
    try {
      // should only see activeStep 2 for subscribe
      // check the search parameters - if "canceled" is true, update the user and call to action for subscribe or email for discount maybe?
      // unauthenticated wild west
      if (isAuthenticated) {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.get("canceled") === "true") {
          setCanceled(true)
        } else if (searchParams.get("success") === "true") {
          setSuccess(true)
          const user = await getUser();
          const session = await getSession(user.stripeCheckoutId);
          // TODO: should check this customer doesn't have existing Id or subscription we're about to overwrite if they manually went to path with search params
          await updateUser(session)
          session.payment_status === 'paid' ? userHasSubscribed(true) : userHasSubscribed(false)
          navigate("/clinical-notebook")
        } else if (searchParams.get("auth") === "true") {
          setActiveStep(2)
        }
      }
      return
    }
    catch(e) {
      console.log(e);
      return
    }
  }

  function failsCheck() {
    if (special.test(fields.password) && fields.password.length > 7 &&
        fields.password.search(/[a-z]/) !== -1 && fields.password.search(/[A-Z]/) !== -1 && fields.password.search(/[0-9]/) !== -1) {
      return false
    }
    return true
  }

  function failsMatch() {
    if (fields.password && fields.confirmPassword.length >= fields.password.length && fields.password !== fields.confirmPassword) {
      return true
    }
    return false
  }

  function validateForm() {
    return (
      fields.firstName.length > 0 &&
      fields.lastName.length > 0 &&
      fields.email.length > 4 &&
      fields.password.length > 7 &&
      fields.password === fields.confirmPassword  &&
      special.test(fields.password) &&
      fields.password.search(/[A-Z]/) !== -1 &&
      fields.password.search(/[a-z]/) !== -1 &&
      fields.password.search(/[0-9]/) !== -1
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setActiveStep(1);
    } catch (e) {
      if (e.toString().includes("Username should be an email.")) {
        setEmailError(true)
      } else {
        onError(e);
      }
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
      await API.post("notes", "/users", {body: {userEmail: fields.email, userFirstName: fields.firstName, userLastName: fields.lastName , paymentStatus: 'unpaid', stripeEmail: false} });
      setActiveStep(2);
      userHasAuthenticated(true);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleStripeSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      let session = ""
      session = await API.post("notes", "/create-checkout-session", {body: 'price_1LIZbIAdkem6682860dMKhWC'} )
      await API.put("notes", "/users", {body: {stripeCheckoutId: `${session.id}`, paymentStatus: 'unpaid'} });
      window.location.href = session.url
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    return true
  }

  function renderSuccess() {
    // get user from dynamo db
    // check payment status with stripe checkout id
    // update user status to reflect paid
    // redirect to auth and paid home or notebook page
    return (
      <StepperStack>
        <Typography sx={{margin: 'auto', fontFamily: "Spectral SC"}} variant="h5">Thank you!</Typography>
        <CircularProgress sx={{margin: '40px auto'}} size={48}></CircularProgress>
        <Typography sx={{margin: 'auto', fontFamily: "Roboto", textAlign: "center", fontWeight: '400'}} variant="p">Completing account setup. Expect to be redirected momentarily.</Typography>
      </StepperStack>
    )
  }

  function renderCanceled() {
    return (
      <StepperStack>
        <Stepper activeStep={2} orientation="vertical">
          <Step>
            <StepLabel>
              { // could get user data from table
              }
              <Typography>Account Info</Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              { // does a user even need to confirm email to possibly see this?
              }
              <Typography>Confirm Email</Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography>Payment Canceled</Typography>
            </StepLabel>
            <StepContent>
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleStripeSubmit}
              >
                <Typography sx={{mt: 1}}>So you're not sold on a Dogs Cats Medicine subscription yet? We're aiming to build a set of veterinary tools that are purr-fect for everyone, but we understand if the timing of another subscription cost isn't great for you.</Typography>
                <Typography sx={{mt: 1}}>If you believe you should have a scholarship or trial account, please reach out to us by emailing: info@dogscatsmedicine.com</Typography>
                <Typography sx={{mt: 1}}>It's not too late to subscribe if you change your mind.</Typography>
                <LoaderButton 
                  type="submit" 
                  variant="contained" 
                  isLoading={isLoading}
                  fullWidth
                  style={{marginTop: '10px'}}>
                  Subscribe & Pay
                </LoaderButton>
                <Typography sx={{fontSize: '.9rem', mt: 1}}>DogsCatsMedicine.com partners with Stripe for simplified billing.</Typography>
              </Stack>
            </StepContent>
          </Step>
        </Stepper>
      </StepperStack>
    );
  }

  function renderForm() {
    return (
      <StepperStack>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              {fields.email && activeStep > 0 ?
              <Typography>Welcome, {fields.firstName}!</Typography>
              : null
              }
            </StepLabel>
            <StepContent>
              <Stack
                  component="form"
                  spacing={2}
                  onSubmit={handleSubmit}
              > 
                <TextField
                  id="firstName"
                  type="text"
                  autoFocus
                  value={fields.firstName}
                  label="First Name"
                  onChange={handleFieldChange}
                />
                <TextField
                  id="lastName"
                  type="text"
                  value={fields.lastName}
                  label="Last Name"
                  onChange={handleFieldChange}
                />
                <TextField
                  id="email"
                  type="email"
                  value={fields.email}
                  label="Email"
                  onChange={handleFieldChange}
                />
                <TextField
                  id="password"
                  type="password"
                  value={fields.password}
                  autoComplete="off"
                  label="Password"
                  onChange={handleFieldChange}
                />
                <TextField
                  id="confirmPassword"
                  type="password"
                  value={fields.confirmPassword}
                  autoComplete="off"
                  label="Confirm Password"
                  onChange={handleFieldChange}
                />
                { failsCheck() ?
                  <Alert severity="info">
                    Password must be at least eight characters long and contain at least one number, special character, uppercase letter, and lowercase letter.
                  </Alert>
                  :
                  <>
                  </>
                }
                { failsMatch() ?
                  <Alert severity="warning">
                    "Password" and "Confirm Password" do not match.
                  </Alert>
                  :
                  <>
                  </>
                }
                { emailError ?
                  <Alert severity="error">
                    Username should be an email.
                  </Alert>
                  :
                  <>
                  </>
                }
                <LoaderButton type="submit" variant="outlined" isLoading={isLoading} disabled={!validateForm()} fullWidth>
                  Signup
                </LoaderButton>
              </Stack>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography>Confirm Email</Typography>
            </StepLabel>
            <StepContent>
              <Stack
                component="form"
                spacing={2}
                onSubmit={handleConfirmationSubmit}
              >
                <TextField
                  id="confirmationCode"
                  type="tel"
                  autoFocus
                  fullWidth
                  value={fields.confirmationCode}
                  label="Confirmation Code"
                  helperText = {`Please enter the confirmation code from your email ${fields.email}.`}
                  onChange={handleFieldChange}
                />
                <LoaderButton fullWidth type="submit" variant="outlined" isLoading={isLoading} disabled={!validateConfirmationForm()}>
                  Verify
                </LoaderButton>
              </Stack>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography>{activeStep === 2 ? "Last Step!" : "Subscribe and Pay through Stripe"}</Typography>
            </StepLabel>
            <StepContent>
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleStripeSubmit}
              >
                <LoaderButton 
                  type="submit" 
                  variant="contained" 
                  isLoading={isLoading}
                  fullWidth
                  disabled={isSubscribed}
                  style={{marginTop: '10px'}}>
                  Subscribe & Pay
                </LoaderButton>
                <Typography sx={{fontSize: '.9rem', mt: 1}}>DogsCatsMedicine.com partners with Stripe for simplified billing.</Typography>
              </Stack>
            </StepContent>
          </Step>
        </Stepper>
      </StepperStack>
    );
  }

  if (canceled){
    return (
    renderCanceled()
    );
  } else if (success) {
    return (
      renderSuccess()
    );
  } else {
    return (
      renderForm()
    );
  }
}