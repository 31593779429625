import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Myasthenia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Myasthenia Gravis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
             Myasthenia gravis is a disease of the neuromuscular junction caused by a problem with acetylcholine receptors that causes weakness. It may be acquired (most common) or congenital, generalized, focal, or fulminating.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Diagnosis is by acetylcholine receptor antibody test (highly sensitive and specific) sent to the Comparative Neuromuscular Lab at the University of California-San Diego. Chest radiographs are a must to rule out cancer, a thymus mass, megaesophagus, and aspiration pneumonia.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Many debate the best treatment. Pyridostigmine is usually the first choice. Manage concurrent megaesophagus and treat aspiration peumonia if present.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Many will go into remission, but overall mortality at 1 year is 40-60%.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951980" target="_blank" rel="noopener noreferrer">Myasthenia Gravis in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
