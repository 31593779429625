import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FelineAsthmaRads from './FelineAsthmaRads';
import CHFRads from './ChfRads';
import PleuralEffusionRads from './PleuralEffusionRads';
import PneumoniaRads from './PneumoniaRads';
import TrachealCollapseRadiographs from './TrachealCollapseRads';

export default function ThoracicRads() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Thoracic Radiographs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Tips & Tricks</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                  <ul>
                  <li>
                  Take radiographs on inspiration (for most cases). Expiration, underexposure, geriatric patients, and obese patients may all appear to have mild interstitial pattern.
                  </li>
                    <ul>
                    <li>
                    Radiographs taken during expiration may highlight pulmonary bulla or better detect pneumothorax (these problems are more radiolucent, so making the pulmonary parenchyma appear more radiopaque helps highlight them).
                    </li>
                    </ul>
                  <li>
                  Thoracic radiographs are one of the most useful diagnostics for patients with cough.
                  </li>
                  <ul>
                  <li>
                  Chronic cough in small breed dogs (even those with murmurs) is more often due to respiratory disease or mainstem bronchial compression (often due to heart enlargement) than heart failure (a third differential, which usually presents as acute cough)
                  </li>
                  <li>
                  Congestive heart failure patients should have pulmonary edema (interstitial/alveolar pattern, often perihilar), left atrial enlargement, and pulmonary venous distension, although cases of acute ruptured chordae tendineae may not show all three signs.
                  </li>
                  <li>
                  Auscultation does not rule out or sufficiently diagnose pulmonary edema regardless of the presence of crackles. Crackles may also be heard with pulmonary fibrosis, cancer, infectious disease (including pneumonia), bronchitis, or pulmonary thromboembolism. Crackles are an inspiratory sound caused by the opening of tiny airways with fluid in or around them, but are not specific to severe pulmonary edema.
                  </li>
                  </ul>
                  </ul>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Disease-specific examples</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Asthma (Feline)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <FelineAsthmaRads />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Congestive Heart Failure (CHF)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <CHFRads />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pleural Effusion</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <PleuralEffusionRads />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pneumonia (Bacterial)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <PneumoniaRads />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Tracheal Collapse</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <TrachealCollapseRadiographs />
              </AccordionDetails>
            </Accordion>

            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
