import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import Tonometry from './images/Tonometry.gif';


export default function Glaucoma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Glaucoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/glaucoma" variant="outlined">Glaucoma Quick Sheet</Button>
            </Grid>
          </Grid>
        <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700"}}>Glaucoma is the most common cause of blindness in animals.</span> Increased intraocular pressure (IOP) damages the retina and optic nerve.  These eyes are often painful and red.
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Tonometry</span></div>
          <img src={Tonometry} alt="tonometry" style={{borderRadius:"3%", maxWidth: "96%"}}/>
          <div>The lowest reading is most accurate. 10 - 20 mmHg is normal. It should always be ≤ 25 mmHg. 8 mmHg difference between eyes may be abnormal. ≥ 25 mmHg with vision loss is glaucoma.  Proper patient restraint is important, as applying pressure to the neck can artificially increase IOP readings.</div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          With inflammation in the eye (uveitis) the IOP is often low.  If the inflammatory cells clog the place where normal intraocular fluid drains, this can cause increased IOP.
        </Grid>
        <Grid item xs={12}>
        <span style={{fontWeight: "700"}}>Treatment:</span>
        <ul>
        <li>
        Latanaprost is contraindicated in most cases of secondary glaucoma (secondary to uveitis) and anterior lens luxation (causes miosis), not effective in cats (lack receptor); use with caution. Latanaprost is most helpful in cases of primary glaucoma in dogs.  In cases of primary glaucoma, treat the other eye with timolol 0.5% at q12h as well as this will delay onset.
        </li>
        <li>
        Dorzolemide (or Cosopt: Dorzolamid/Timolol) q15 minutes for 1 hour then 3x/day for long-term management
        </li>
        <li>
        Consider Neo/poly/dex 0.1% or prednisolone acetate 1% q6-8h in the ER setting then q12-24h to decrease inflammation associated with pressure induced ischema (when not contraindicated by surface disease).
        </li>
        <li>
        Mannitol at 1.5 g/kg IV over 15-20 minutes will dehydrate the vitreous and give relief for 5-6 hours. Limit water. Contraindicated if heart disease, diabetes melitus, renal failure, or uveitis
        </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="http://www.acvo.org/common-conditions-1/2018/2/2/glaucoma" target="_blank" rel="noopener noreferrer">Glaucoma Handout</a> from the ACVO
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
