import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioPH() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pulmonary Hypertension</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography variant="h6">
              <a href="https://onlinelibrary.wiley.com/doi/10.1111/jvim.15725" target="_blank" rel="noopener noreferrer">ACVIM Consensus - PH in Dogs</a>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Pulmonary Hypertension (PH) is a clinical syndrome (not a specific disorder. Underlying causes include any cause of increased pulmonary blood flow, increased pulmonary vascular resistance, or increased pulmonary venous pressure.  This includes pulmonary fibrosis, PTE, Heartworm disease, Mitral valve disease, PDA, Tracheal collapse, Heart base tumors</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Findings suggestive of PH: Syncope (often with exertion or activity) without other cause, respiratory distress at rest or following activity/exercise, right-sided heart failure, tachypnea, hyperpnea, cyanotic or pale</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>With ultrasound, you may notice flattening of the interventricular septum (Mushroom view), left atrial enlargement (Mercedes Benz sign view: Normal LA:Ao is about 1.5:1; with PH may be 2:1; ), pulmonary artery enlargement, right atrial enlargement, enlargement of the caudal vena cava</Typography>
            </Grid>
          </Grid>

        </AccordionDetails>
      </Accordion>

  );
}
