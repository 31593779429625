import React from 'react';
import { Grid, TableContainer, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import BloodProductsCalculatorContent from '../../Calculators/BloodProductsCalculatorContent';

export default function BloodProducts() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Blood Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Key points:</Typography>
            <ul>
              <li>
                If not symptomatic, you likely do not need to give blood&mdash;regardless of PCV.
              </li>
              <li>
                Be mindful of what you are trying to replace. Packed red blood cells (pRBCs) contain red blood cells; fresh whole blood contains plasma and platelets (transfused platelets are short-lived); fresh frozen plasma contains coagulation factors.
              </li>
              <li>
                Warm RBCs to room temperature. Warm plasma to &lt;98&deg;F in a water bath (can double bag to help prevent breakage of the bag).
              </li>
              <li>
                Aim to completely use the product within 4 hours once thawed/warmed. For remaining blood returned to fridge, use within 24 hours once spiked (for multiple transfusions or other patients). Fresh frozen plasma can be thawed, 4 hour aliquots drawn up, and the remainder kept in the fridge for 24 hours in cases that require longer CRIs. Positioning and mixing frequency of pRBCs is irrelevant until day 28<sup>1</sup>.
              </li>
              <li>
                Always use a blood filter and approved pump (or just hang the product). Canine blood products should not be given via syringe pump<sup>2</sup>. Feline blood products can be given via syringe pump<sup>3</sup>. 
              </li>
              <li>
                Assuming no ongoing loss, it takes 22.5 mL/kg of FFP to raise albumin by 0.5 g/dL<sup>4</sup>; Snow et al showed no difference in serum albumin pre- and post-transfusion<sup>5</sup>. With this in mind, its hard to justify giving plasma to raise albumin.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <BloodProductsCalculatorContent />
          </Grid>
          <Grid item xs={12}>
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Transfusion Reactions:</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid item xs={12}>
            <ul>
              <li>
                Routine use of antihistamine for premedication is not recommended. Overall occurrence of transfusion reactions was not significantly altered with premedication<sup>6</sup>.
              </li>
              <li>
                Temperature increase of 1.8&deg;F over baseline and over 102.5&deg;F is reason to stop the transfusion reassess the patient, the blood product, and a plasma sample from the patient (for hemolysis). The goal is to differentiate an acute hemolytic transfusion reaction (AHTR)&mdash;in which case, you should should not restart the transfusion&mdash;from a febrile non-hemolytic transfusion reaction (FNHTR)&mdash;in which case you can continue the transfusion at the same or slower rate.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Reaction<sup>7</sup></TableCell>
                  <TableCell align="left">Incidence</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Febrile Non-hemolytic Transfusion Reaction (FNHTR)</TableCell>
                <TableCell align="left">Dogs: 1.3 to 24.2%; Cats: 3.7 to 22.9%</TableCell>
                <TableCell align="left">Occur due to donor WBC or platelet Ag-Ab reactions or transfer for proinflammatory mediators within 4 hours. Must rule out other causes of temperature increase.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Transfusion Associated Dyspnea</TableCell>
                <TableCell align="left">Dogs 2 to 6.3%; Cats: 0.4 to 7.4%</TableCell>
                <TableCell align="left">Acute respiratory distress within 24 hours where TACO, TRALI, allergic reaction, and underlying pulmonary disease have been ruled out.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Transfusion Associated Circulatory Overload (TACO)</TableCell>
                <TableCell align="left">Dogs: 4.7%; Cats: 3%</TableCell>
                <TableCell align="left">Increase in blood volume causes hydrostatic pulmonary edema within 6 hours. Often respond positively to furosemide.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Transfusion-Related Acute Lung Injury (TRALI)</TableCell>
                <TableCell align="left">Dogs: 3.7%</TableCell>
                <TableCell align="left">Noncardiogenic pulmonary edema within 6 hours with no other risk factor for Acute Respiratory Distress Syndrome (ARDS).</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Allergic Reaction</TableCell>
                <TableCell align="left">Dogs: 0 to 6.6%; Cats: 0.4 to 6.9%</TableCell>
                <TableCell align="left">Includes hives, vomiting, and anaphylaxis. Treat as you would allergic reaction or anaphylaxis.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Acute Hemolytic Transfusion Reactions (AHTR)</TableCell>
                <TableCell align="left">Dogs: 0 to 6.3%; Cats: 0 to 3.7%</TableCell>
                <TableCell align="left">Characterized by acute hemolysis within 24 hours.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="left">Less common reactions include: Transfusion-Transmitted Infection, Citrate Toxicity/Hypocalcemia, Hypotensive Transfusion Reactions, Posttransfusional Purpura, and a few others</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
          </AccordionDetails>
          </Accordion>
          </Grid>
        <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
          <Typography>References:</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>1</sup>Aumann et al. The effect of position and frequency of mixing on canine packed red blood cell units during storage. JVECC 2021.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>2</sup>McDevitt et al. Influence of transfusion technique on survival of autologous red blood cells in the dog. JVECC 2011.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>3</sup>Heikes et al. Effect of syringe and aggregate filter administration on survival of transfused autologous fresh feline red blood cells. JVECC 2014.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>4</sup>Davidow. Transfusion Medicine in Small Animals. Vet Clin Small Anim 2013.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>5</sup>Snow et al. Trends in plasma transfusion at a veterinary teaching hospital: 308 patients (1996-1998 and 2006-2008). JVECC 2010.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>6</sup>Bruce et al.Effect of premedication and other factors on the occurrence of acute transfusion reactions in dogs. JVECC 2015.
          </Typography>
          <Typography style={{fontSize: "0.8rem"}}>
            <sup>7</sup>Davidow et al. Association of Veterinary Hematology and Transfusion Medicine (AVHTM) Transfusion Reaction Small Animal Consensus Statement (TRACS). Part 1: Definitions and clinical signs. JVECC 2020.
          </Typography>
          </AccordionDetails>
        </Accordion>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
