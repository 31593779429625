import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import ElbowDysplasiaRads from './ElbowDysplasiaRads';

export default function StifleRadiographs() {

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Elbow Radiographs</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
        <Typography>
        Elbow radiograph positioning: For craniocaudal view, center the olecranon in the supratrochlear foramen. The lateral view should have the elbow at a 90-degree angle with the condyles of the humerus superimposed.
        </Typography>
        </Grid>
        <ElbowDysplasiaRads />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
