import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Wobbler() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Wobbler Syndrome (Cervical Spondylomyelopathy)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
            A degenerative disease that causes progressive spinal cord compression, often in large or giant breed dogs. Para to tetraparesis. Often show a "two-engine" gait.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Diagnosis is usually by MRI.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              Surgery should be recommended if static or progressive.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=10944486" target="_blank" rel="noopener noreferrer">Wobbler Syndrome in Dogs</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
