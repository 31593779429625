import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FelineBehaviorProbs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Problem Behaviors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Inappropriate Urination</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>Implement litterbox best practices.</span> There should be 1 more box than number of cats.  Boxes should be scooped when used, at least daily.  Litter should be completely changed at least every 2-3 days.  Boxes should be 1.5x the length of the cat.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>Rule out medical causes</span> CBC, Chem, UA (+/- urine culture), Fecal, T4 with additional diagnostics as indicated.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>4 categories to focus on if behavioral:</span>
                <ul>
                <li>Substrate preference</li>
                <li>Substrate aversion</li>
                <li>Location preference</li>
                <li>Location aversion</li>
                </ul>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Overgrooming</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              More often associated with underlying medical problem, but may become a behavioral pattern secondary to a medical problem.  Rule out endocrine disease, allery, FLUTD/UTI/FIC, GI parasites, undiagnosed pain, or other medical causes.
              </Typography>
            </AccordionDetails>
          </Accordion>



        </AccordionDetails>
      </Accordion>

  );
}
