import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Ohe() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Ovariohysterectomy (OHE, Spay, Neuter)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>OHE timing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                Breed-associated competing risks make this an individual decision that should be considered based on the patient. <a href="https://www.aaha.org/aaha-guidelines/life-stage-canine-2019/life-stage-canine-2019/" target="_blank" rel="noopener noreferrer">AAHA 2019 Canine Life Stage Guidelines</a> offer some guidance on <a href="https://www.aaha.org/aaha-guidelines/life-stage-canine-2019/spay-and-neuter-timing/" target="_blank" rel="noopener noreferrer">proposed ages for sterilization</a>. In general, for dogs expected to weigh less than 20kg when fully grown, spay prior to first heat (before 5-6 months old). For dogs expected to weigh more than 20kg, spay at 5-15 months old. Spaying early (before first heat cycle) reduces the risk of breast cancer and helps prevent unwanted litters. Spaying after the first heat cycle (and once at mature weight) may decrease the risk of certain other cancers and musculoskeletal problems and may decrease the risk of urinary incontinence, but is associated with higher risk of breast cancer.
              </Typography>
              <Typography>
                Hart et. al. published <a href="https://www.frontiersin.org/articles/10.3389/fvets.2020.00388/full" target="_blank" rel="noopener noreferrer">Assisting Decision-Making on Age of Neutering for 35 Breeds of Dogs: Associated Joint Disorders, Cancers, and Urinary Incontinence</a> in 2020 which offers breed-specific suggestions for neutering ages to avoid long-term health risks.
              </Typography>
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>OHE Procedure</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <ul>
                <li>
                Expressing the bladder just prior to surgical prep may help keep bladder and ureters out of the way during surgery.
                </li>
                <li>
                Prep for xiphoid to pubis incision&mdash;even if you anticipate small incision, plan for complications (such as hemorrhage) which may require extending the incision.
                </li>
                <li>
                The caudal abdomen (umbilicus to pubis) can be divided into thirds. For adult dogs (&gt;6 months old that have had one or more heat cycles), the ovaries live more cranial&mdash;the initial incision should be over the cranial 1/3 of the caudal abdomen. For cats (of all ages) and puppies, the ovaries are generally more caudal and your incision should be over the middle 1/3 of the caudal abdomen.
                </li>
                <li>
                Metzenbaum scissors may help you undermine subcutaneous fat and identify the linea alba.
                </li>
                <li>
                Lift the linea alba with Adson-Brown tissue forceps to make a tent. Enter the abdominal cavity with a small stab incision keeping your scalpel blade oriented parallel to and sharp side away from the patient so as not to cut into spleen, bladder, or intestine below.
                </li>
                  <ul>
                  <li>
                  Mayo scissors (not metzenbaum scissors) should be used to extend the incision in the linea alba.
                  </li>
                  </ul>
                <li>
                  OHE hook can be gently passed down the right lateral body wall to find the uterus. If not finding the uterus, look or reach (digitally) under the urinary bladder. The uterus should have a clear bifurcation (at the body), lateral striations, and a uterine artery parallel to each horn.
                </li>
                <li>
                  The suspensory ligament can usually be broken with digital traction. To do this, pull the ovary tight, palpate cranially along the taught band of tissue (avoid nearby ovarian vessels that converge with the suspensory ligament at the ovary), and strum caudolaterally with your index finger. Some will cut the suspensory with a scalpel blade if able to visualize it. Some will leave it if adequate exposure.
                </li>
                <li>
                  Even if surrounded by fat, the ovary can usally be palpated. Make a window in the mesometrium that allows you to Clamp just cranial to the ovary.
                </li>
                <li>
                  Ligate or double ligate the ovarian artery and vein complex at least 1 cm cranial to your clamp.
                </li>
                  <ul>
                  <li>
                  There are many acceptable techniques. Some prefer to double ligate with an encircling ligature placed cranially and then a modified transfixing ligature placed closer to clamp and ovary.
                  </li>
                  <li>
                  Some prefer a miller's knot.
                  </li>
                  <li>
                  In cats or dogs with small uteri, two encircling ligatures may be sufficient (usually do not attempt a transfixing ligature).
                  </li>
                  <li>
                    If less than 15kg, 3-0 PDS may be acceptable. If 15-25kg, 2-0 PDS is a good choice. If &gt;25kg, use 0 PDS.
                  </li>
                  </ul>
                <li>
                  Place a small clamp on the suspensory ligament just caudal to your knot(s) to hold on to the pedicle as you cut away the ovary and uterine horn (usually with a scalpel blade). Check to ensure no hemorrhage even when tension is released.
                </li>
                <li>Tear or ligate and cut the broad ligament (mesometrium) from the uterine horn towards the cervix. If especially fatty or obvious vessels, place one encircling ligature around it prior to tearing or cutting it away.</li>
                <li>Ligate the uterine body with an encircling ligature near the cervix and a transfixing ligature just cranial to this. In cats or small dogs, two encircling ligatures may be preferred. If large, ligate the uterine arteries (one on each side) individually instead of placing a transfixing ligature.</li>
                <li>
                  Check for hemorrhage prior to closure.
                </li>
              </ul>
            </Grid>
          </Grid>

          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>OHE Complications</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Potential complications include hemorrhage, ovarian remnant syndrome, ureteral ligation, seroma, infection, suture reaction, dehiscence.
            </li>
            <li>
            Hemorrhage: If noted during the surgical procedure, extend your incision, find the source (usually a pedicle can be found by tracing up from the mesoduodenum or mesocolon). Clamp and ligate the pedicle or bleeder. A miller's knot, especially if thrown over a clamp and then slid over the bleeder, can be useful to tie off deep or friable tissue. Post-operatively, low body temperature, tachycardia, and pale gums should raise concern for hemorrhage.
            </li>
            <li>
              Ovarian remnant syndrome can usually be prevented by confidently clamping cranial to the entire ovary (don't leave any ovary behind). Inspect the uterus and ovaries after removal to ensure you have removed two complete ovaries.
            </li>
            <li>
              Ureteral ligation is more likely in cats and may occur if ureters hidden in fatty tissue are ligated with the uterine body or in ligatures placed very deep on an ovarian pedicle.
            </li>
            <li>
              Seroma formation may occur within a few days post operatively due to dead space created between the body wall and skin. Seromas are soft, fluid-filled, and not painful on palpation. Heat or pain should raise suspicion of infection. They may be prevented by closing dead space (tacking subcutaneous tissue to deeper fascia or linea alba during subcutaneous tissue apposition). Because they are sterile, they do not warrant the use of antibiotics. Needle drainage risks introducing infection, and is usually contraindicated. Conservative management may include at home monitoring and strict confinement (to limit motion of the area).
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
