import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cataracts from './Cataracts';
import CornealUlcer from './CornealUlcer';
import Kcs from './Kcs';
import Glaucoma from './Glaucoma';
import FhvKeratoconjunctivitis from './FelineHerpesConjunctivitis';
import Proptosis from './Proptosis';
import LensLux from './LensLuxation';
import Uveitis from './Uveitis';
import CherryEye from './CherryEye';
import Horners from '../Neurology/Horners';
import Haws from './Haws';

export default function ClinicalSigns() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Clinical Signs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Cataracts />
          <CherryEye />
          <Haws />
          <Horners />
          <LensLux />
          <Proptosis />
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Red Eye</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CornealUlcer />
            <FhvKeratoconjunctivitis />
            <Glaucoma />
            <Kcs />
            <Uveitis />
          </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
