import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PreVisitGabaTraz from './PreVisitGabaTraz';
import PreVisitChill from './PreVisitChill';
import PreVisitCat from './PreVisitCat';

export default function PreVisitDrugs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pre-visit Drugs for Stressed Pets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Trazodone/Gabapentin for Dogs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PreVisitGabaTraz />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Gabapentin/Melatonin/Acepromazine for Dogs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PreVisitChill />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Gabapentin for Cats</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PreVisitCat />
            </AccordionDetails>
          </Accordion>



        </AccordionDetails>
      </Accordion>

  );
}
