import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function PancFeline() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pancreatitis (Feline)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pancreatitis" variant="outlined">Pancreatitis Quick Sheet</Button>
            </Grid>
          </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Inflammation of the pancreas. May be acute or chronic.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> lethargy, anorexia, vomiting, weight loss, diarrhea, dyspnea, abdominal pain is only apparent in 10-30%</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
              <li>
              Abdominal Ultrasound
              </li>
              <li>
              fPL is highly specific.
              </li>
              <li>
              Serum amylase is neither sensitive nor specific.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6">
            <a href="https://onlinelibrary.wiley.com/doi/10.1111/jvim.16053" target="_blank" rel="noopener noreferrer">ACVIM consensus statement on pancreatitis in cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951457" target="_blank" rel="noopener noreferrer">Pancreatitis in Cats</a> from Veterinary Partner
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
