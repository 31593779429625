import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hypothyroidism() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hypothyroidism</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
            Dogs with hypothyroidism may act tired, gain weight, have dull hair coats or lose hair.  Low thyroid hormone may be detected on routine blood work in older dogs, but this does not always mean they have true hypothyroidism. Thyroid hormone will go down with age in normal dogs, and sick dogs often have low thyroid hormone production (euthyroid sick syndrome). 
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Dermatologic signs, obesity, lethargy; many less common signs with reported association</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Routine Laboratory Findings:</span> On fasted blood samples, may have hypercholesterolemia and hypertriglyceridemia (means little if not fasted). Some will have mild, non-regenerative anemia.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
              <li>
              Total T4 can be used to rule out hypothyroidism, but should not be used to make a diagnosis.
              </li>
              <li>
              If possibility of concurrent illness, best to use combination of total T4, free T4, and TSH.
              </li>
              <li>
              Once start treatment, monitor for resolution of clinical signs (may take months to see weight loss and dermatologic improvement).
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Levothyroxine - 0.02 mg/kg q12h (max of 0.8 mg total q12h for large dogs)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Monitoring response to treatment:</Typography>
            <ul>
              <li>
              Improvement of clinical signs is expected over weeks to months.
              </li>
              <li>
              Recheck total T4 in 4 weeks; take sample 4-6 hours post pill.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951353" target="_blank" rel="noopener noreferrer">Hypothyroidism</a> from Veterinary Partner
          </Typography>
          <Typography>
            <a href="https://petparenthandouts.com/hypothyroidism" target="_blank" rel="noopener noreferrer">Hypothyroidism</a> from Pet Parent Handouts
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
