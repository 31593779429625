import React from 'react';
import { Grid, Checkbox, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CSection() {

  return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12} style={{fontWeight: '700'}}>
              C-section
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
          <Grid container spacing={1}>

        <Grid item xs={12}>
        <ul>
        <li>
        Minimize time from decision to delivery and from induction of anesthesia to delivery<sup>2</sup>
        </li>
        <li>
        Minimize time with dam in dorsal recumbency prior to delivery as pressure on the caudal vena cava will decrease venous return and contribute to fetal hypoxia and stress.  Clipping and much of the surgical prep may be performed in lateral recumbency prior to induction of anesthesia.
        </li>
        <li>
        Avoid medications that will negatively affect fetal and neonatal health.  Propofol or Alfaxalone may be used for induction of anesthesia without premedication. Opioid analgesia is used by many; if given prior to delivery, the effects on the fetuses may be reversed by naloxone.
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Preoxygenate, clip and prep in lateral recumbency
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider short-acting opioid that can be reversed (with naloxone) in neonates after delivery.  Alternatively, administer opioid once puppies are delivered.
        </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Induction of anesthesia with propofol or alfaxalone
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider atropine
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider local block of skin at site of incision
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider opioid epidural if it will not delay surgery
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Avoid long-acting anesthetics that may prevent dam from using hind limbs post-operatively
        </li>
        </ul>
        </Grid>


        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Maintain on the lowest possible concentration of inhalant anesthetic
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Some clinicians will maintain with intermittent propofol injections until all puppies are delivered and only provide oxygen (no anesthetic gas) through ET tube
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Consider opioid for dam (if not already administered) after delivery of last puppy
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />Can consider ovariohysterectomy of the dam if requested; Dam will still produce milk even if spayed
        </Grid>

        </Grid>
        </AccordionDetails>
        </Accordion>
          );
        }
