import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Parvovirus from './Parvovirus';
import PnuemoniaBacterial from './PneumoniaBacterial';
import Fungal from './Fungal';
import LymeDisease from './LymeDisease';
import Uti from './Uti';
import Prostatitis from './Prostatitis';
import Leptospirosis from './Leptospirosis';

export default function IMInfectious() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Infectious Diseases</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: "0"}}>
          
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Bacterial</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Prostatitis />
              <Leptospirosis />
              <PnuemoniaBacterial />
              <Uti />
            </AccordionDetails>
          </Accordion>
          <Fungal />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Tick Borne</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Anaplasmosis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Anaplasma is a tick-borne disease that may make dogs sick or cause thrombocytopenia.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://www.cdc.gov/ticks/tickbornediseases/overview.html" target="_blank" rel="noopener noreferrer">CDC Tickborne Diseases Maps</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <span style={{fontWeight: "700"}}>Prevention:</span> Good tick control and monitoring/prompt removal of ticks.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Clinical Signs:</span> May include fever, lethargy, lameness, and lymphadenopathy. Thrombocytopenia is common.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Commonly detected on 4Dx or similar screening test. PCR should be used to confirm current infection in appropriate cases.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> If clinical or confirmed by PCR, consider treatment with doxycycline (often 5-10 mg/kg q12h for 2-3 weeks).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>
                    <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=9494666" target="_blank" rel="noopener noreferrer">Positive Snap Tests for Ehrlichia and Anaplasma</a>&mdash;This handout from Veterinary Partner helps explain why positive screening tests don't always warrant treatment and may warrant further (PCR) testing.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=6191808" target="_blank" rel="noopener noreferrer">Anaplasmosis</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
             </Accordion>
             <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Ehrlichia</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Ehrlichia is a tick-borne disease that may make dogs sick or cause thrombocytopenia.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://www.cdc.gov/ticks/tickbornediseases/overview.html" target="_blank" rel="noopener noreferrer">CDC Tickborne Diseases Maps</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <span style={{fontWeight: "700"}}>Prevention:</span> Good tick control and monitoring/prompt removal of ticks.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Clinical Signs:</span> May include fever, lethargy, lameness, lymphadenopathy, splenomegaly. Immune-complex glomerulonephritis and proteinuria are possible. Thrombocytopenia is common.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Commonly detected on 4Dx or similar screening test. PCR or antibody titers should be used to confirm current infection in appropriate cases.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span> If clinical or confirmed by PCR/antibody titers, consider treatment with doxycycline (often 10 mg/kg q24h for 4 weeks).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography variant="h6">
                    <a href="https://onlinelibrary.wiley.com/doi/pdf/10.1111/j.1939-1676.2002.tb02374.x" target="_blank" rel="noopener noreferrer">2002 ACVIM Consensus Statement on Ehrlichial Disease</a>
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>
                    <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=9494666" target="_blank" rel="noopener noreferrer">Positive Snap Tests for Ehrlichia and Anaplasma</a>&mdash;This handout from Veterinary Partner helps explain why positive screening tests don't always warrant treatment and may warrant further (PCR) testing.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=4952341&ind=97&objTypeID=1007" target="_blank" rel="noopener noreferrer">Ehrlichia Infection in Dogs</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
             </Accordion>
              <LymeDisease />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Viral</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <Parvovirus />
            </AccordionDetails>
          </Accordion>
          
        </AccordionDetails>
      </Accordion>

  );
}
