import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import microfilaria from './images/microfilaria.gif';


export default function Heartworm() {

  return (


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Heartworm Disease</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="h6">
                <a href="https://www.heartwormsociety.org/veterinary-resources/american-heartworm-society-guidelines" target="_blank" rel="noopener noreferrer">AHS - Heartworm Guidelines</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>Dogs should be tested for heartworm disease every year. This is the current recommendation of the American Heartworm Society. Heartworm antigen blood testing looks for evidence of adult female worms in the dogs heart. Microfilaria testing looks for baby parasites in the dog's blood.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>A single delayed or skipped dose of heartworm preventative medication can lead to infection. The preventative medications target the young parasites in the blood. Once these reach a certain age, the monthly preventative medications will not kill them. Dogs that miss doses should be retested 7-9 months after the missed dose.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>Caval Syndrome: sudden onset of severe lethargy and weakness accompanied by hemoglobinemia and hemoglobinuria</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>Occult infection: immune mediated destruction of microfilaria; the infection is prepatent; the adult heartworms are of the same sex; the worm burden is low and the sexes are physically separated</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>In cats, the heartworm burden is lower than the dog.  Worms live about 2 years (not 5-7 as in dog). Worms are smaller size. Cough and dyspnea is the usual presenting complaint. Diagnose with IFA for antibodies against microfilarial antigens, ELISA for antibodies against adult antigens (if negative, cat is almost certainly negative) and ELISA for adult antigens (if positive, cat is positive).  Echocardiography may be useful.  Radiographs may help with prognosis. Cats with eosinophilic lung disease may be treated with steroids.  We worry about prednisolone causing diabetes in cats.  Cats hearts get stiff when diseased. Mineralocorticoids, especially depomedrol, vastly expands blood volume, and diseased hearts will easily tip into heart failure.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://www.heartwormtoolkit.com/" target="_blank" rel="noopener noreferrer">Heartworm Treatment Calculator</a> from American Heartworm Society
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://www.heartwormsociety.org/veterinary-resources/incidence-maps" target="_blank" rel="noopener noreferrer">Heartworm Incidence Maps</a> from American Heartworm Society
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://www.heartwormsociety.org/handout" target="_blank" rel="noopener noreferrer">Heartworm Treatment Handout</a> from American Heartworm Society
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951469" target="_blank" rel="noopener noreferrer">Heartworm Disease in Dogs</a> from Veterinary Partner
              </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Microfilaria</Typography>
              <img src={microfilaria} style={{borderRadius:"2%", maxWidth: "96%"}} alt="microfilaria"/> <Typography>The baby parasites in the blood</Typography>
              </Paper>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>

  );
}
