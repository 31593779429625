import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hypertension() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hypertension</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            Common diseases associated with hypertension include CKD, AKI, hyperthyroidism (cats), hyperadrenocorticism, diabetes mellitus, pheochromocytoma, hyperaldosteronism. If systolic blood pressure is &ge;180 with severe or progressive brain or eye damage, start emergency therapy&mdash;ususally with amlodipine and an ACE inhibitor (such as benazepril). In cats, it is common to start sole therapy with amlodipine and give every 2-3 hours until target (such as 150-180 systolic) is acheived.
            </Typography>
            </Grid>
            <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/10.1111/jvim.15331" target="_blank" rel="noopener noreferrer">ACVIM consensus statement: Guidelines for the identification, evaluation, and management of systemic hypertension in dogs and cats</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://journals.sagepub.com/doi/10.1177/1098612X17693500?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed" target="_blank" rel="noopener noreferrer">ISFM Consensus Guidelines on the Diagnosis and Management of Hypertension in Cats</a>
          </Typography>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
