import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.13841" target="_blank" rel="noopener noreferrer">2015 ACVIM Small Animal Consensus Statement on Seizure Management in Dogs</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="http://www.neurovideos.vet.cornell.edu/search.aspx" target="_blank" rel="noopener noreferrer">Dr. de Lahunta's Neuro Videos</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://vetneuromuscular.ucsd.edu/faq.html" target="_blank" rel="noopener noreferrer">UCSD School of Medicine Comparative Neuromuscular Lab</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
