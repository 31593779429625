import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SplenicTumors from '../Oncology/SplenicTumors';

export default function Hemoab() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hemoabdomen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/hemoabdomen" variant="outlined">Hemoabdomen Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Differential Diagnoses:</Typography>
            <ul>
              <li>
              <SplenicTumors />
              </li>
              <li>
              Other bleeding benign or malignant mass (hemangiosarcoma, hematoma, nodular hyperplasia, hepatic abscess, splenic infarction, hepatocellular carcinoma, hepatoma, splenic histiocytic sarcoma, adrenal tumor, omental tumor)
              </li>
              <li>
              Blunt Trauma
              </li>
              <li>
              Coagulopathy
              </li>
              <li>
              Anaphylaxis
              </li>
              <li>
              Splenic Torsion
              </li>
              <li>
              GDV
              </li>
            </ul>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
