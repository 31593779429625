import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DiabeticNeuropathy() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Diabetic Neuropathy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
          Cats with persistently high blood glucose due to diabetes mellitus may have a plantigrade stance, hind limb weakness, depressed patellar reflexes, and poor postural reactions.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Kramek et al.</Button> {refs ? " Neuropathy associated with diabetes mellitus in the cat. JAVMA 1984." : "" }</sup> This is due to axonal degradation affection the longest peripheral nerves (sciatic nerves) first. 
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
