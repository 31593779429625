import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Sepsis() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Sepsis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>"<span style={{fontWeight: "700"}}>Sepsis</span> is defined as life-threatening organ dysfunction caused by a dysregulated host response to infection...In lay terms, sepsis is a life-threatening condition that arises when the body's response to an infection injures its own tissues and organs...<span style={{fontWeight: "700"}}>Septic shock</span> is a subset of sepsis in which underlying circulatory and cellular/metabolic abnormalities are profound enough to substantially increase mortality."<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Singer et al.</Button> {refs ? " The Third International Consensus Definitions for Sepsis and Septic Shock. JAMA 2016." : "" }</sup></Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6">
              <a href="https://www.sccm.org/Clinical-Resources/Guidelines/Guidelines/Surviving-Sepsis-Guidelines-2021" target="_blank" rel="noopener noreferrer">Surviving Sepsis Campaign Guidelines 2021</a>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom><span style={{fontWeight: "700"}}>Consider risk factors and look for source of infection.</span> History suggestive of possible infection, intact (un-neutered), on immunosuppressive drugs or endocrinopathy patient (DM, hyperadrenocorticism)
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Common sources:</span>
            </Typography>
            <ul>
              <li>
                Pyelonephritis
              </li>
              <li>
                Pyometra or prostatic infection
              </li>
              <li>
                Septic peritonitis
              </li>
              <li>
                Bacterial pneumonia
              </li>
            </ul>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Source control (as soon as possible). Antibiotics within 1 hour. Fluid resuscitation immediately if shock. If hypoglycemia, supplement dextrose (avoid hyperglycemia). Norepinephrine if needed once volume restored to target MAP of 65 mmHg. Consider NG tube for nutritional support.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
