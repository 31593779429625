import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hyperaldosteronism() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hyperaldosteronism (Primary)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Primary hyperaldosteronism (or Conn’s syndrome) is caused by excess secretion of mineralocorticoids (mostly aldosterone) which may cause hypertension +/- hypokalemia. Hyperaldosteronism is not always primary; it may be a normal physiologic, secondary response to cardiac or renal disease. Primary hyperaldosteronism is usually caused by adrenocortical adenoma or carcinoma.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> PUPD, weight loss, heart murmur or dysrhythmia; weakness; may look like CKD cats (and may be misdiagnosed). Hypokalemia and mild azotemia are common on initial blood work. Hypophosphatemia may be present and not fitting with CKD diagnosis (which usually causes progressive hyperphosphatemia). May have concurrent diabetes mellitus.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span>
        </Typography>
        <ul>
          <li>
          Aldosterone renin ratio is the gold standard test, but not readily available (and hard to run)
          </li>
          <li>
          Plasma aldosterone concentration should be measured in suspected cases
          </li>
          <li>
          Ultrasound imaging of adrenals lends support
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment & Followup:</span> 
        </Typography>
        <ul>
          <li>
          Goal is to control hypokalemia (with potassium supplementation) and hypertension (with amlodipine)
          </li>
          <li>
          Spironolactone (usually 1-2 mg/kg PO q12h) as aldosterone antagonist
          </li>
          <li>
          Referral for possible surgery may be considered in select cases
          </li>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
