import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Glossary() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Glossary</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Chevron sign</span> &mdash; Normal lucency at the apex of a root - should not have any mushroom cloud effect
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Complicated Crown Fracture</span> &mdash; fracture of the crown that involves the root
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Dentigerous cyst</span> &mdash; forms because a tooth and its inner enamel epithelium are left unerrupted - an osmotic gradient forms and pulls fluid into a sac creating a destructive cyst
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Dilacerated root</span> &mdash; these roots are more parallel or convergent
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Epulis</span> &mdash; proliferative growth of the gingiva; nonspecific; benign or malignant; odontogenic fibromas include the fibromatous epulis and ossifying epulis - firm gingival masses from gingival connective tissue or the periodontal ligament. Acanthomatous ameloblastomas are benign but locally invasive and usually affect the rostral mandible.
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Fused roots</span> &mdash; when roots are fused; an incidental finding
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Gemination tooth</span> &mdash; twinning of the tooth; an incidental finding
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Radicular cyst</span> &mdash; formed around the apex of a tooth after stimulation and proliferation of the epithelial rests in the periodontal ligament; the lining of the cyst must be removed (with a curette) at the time of extraction.
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Supernumerary root</span> &mdash; an extra root on a tooth - gives a good reason to take radiographs before extraction
          </Grid>
          <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Uncomplicated Crown Fracture</span> &mdash; fracture of the crown that does not involve the root
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
