import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ElbowDysplasia from './ElbowDysplasia';
import Fractures from './FracturesForelimb';
import Osteosarcoma from '../Oncology/Osteosarcoma';
import Osteochondrosis from './Osteochondrosis';
import Supraspinatus from './Supraspinatus';
import BicepsTenosynovitis from './BicepsTenosynovitis';
import BrachialPlexus from '../Neurology/BrachialPlexus';
import RootSignature from '../Neurology/RootSignature';
import Panosteitis from './Panosteitis';
import HypertrophicOsteodystrophy from './HypertrophicOsteodystrophy';
import Infraspinatus from './InfraspinatusMyopathy';
import NerveSheathTumor from '../Oncology/NerveSheathTumor';

export default function Forelimb() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Forelimb</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BicepsTenosynovitis />
          <BrachialPlexus />
          <ElbowDysplasia />
          <Fractures />
          <HypertrophicOsteodystrophy />
          <Infraspinatus />
          <Osteochondrosis />
          <Osteosarcoma />
          <Panosteitis />
          <NerveSheathTumor />
          <RootSignature />
          <Supraspinatus />
        </AccordionDetails>
      </Accordion>

  );
}
