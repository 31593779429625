import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Cups() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Canine ulcerative paradental stomatitis (CUPS)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Presentation is similar to feline chronic gingivostomatitis with inflammation and ulceration of the tongue and gingiva especially where there is contact to teeth and plaque. 
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    Some may have reduced appetite, drooling, and severe halitosis.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    Treatment ranges based on severity. Dental cleaning followed by strict daily brushing and dental diets may help some. Some will require antibiotics, pain medications,  immunosuppressives, and anti-inflammatories. In advanced cases that fail more conservative management, full-mouth extractions should be considered.
                    </Typography>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
