import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Switch } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class SeizuresQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      switchSevere: true,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleSwitchChange(event) {
  this.setState({
    switchSevere: event.target.checked
  });
}

  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Seizures & Status Epilepticus</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid container align="left">
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
          <Grid item>Seizures</Grid>
          <Grid item><Switch checked={this.state.switchSevere} onChange={this.handleSwitchChange} name="Severe" />
          </Grid>
          <Grid item>Status Epilepticus</Grid>
        </Grid>
          {this.state.switchSevere ?
            <Grid container spacing={0}>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Place IV catheter</span> as soon as possible
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Check blood glucose</span> from front leg or ear (NOT back leg)
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            If &le; 60, give 0.5 to 1 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1)).toFixed(2)} mL of 50% dextrose IV diluted 1:2 with sterile saline
            </li>
            <li>
            If no IV catheter, give dextrose (undiluted) onto the gums
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Give 0.25 mg/kg midazolam (5 mg/mL) = {(this.state.weightkg*(0.25)/5).toFixed(2)} mL IV</span>
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            If no IV catheter, give IM or IN (intranasally)
            </li>
            <li>
            Repeat dose in 1 minute if still seizing
            </li>
            <li>
            Patients on phenobarbital may need 2 mg/kg = {(this.state.weightkg*(2)/5).toFixed(2)} mL midazolam (5 mg/mL)
            </li>
            <li>
            Diazepam is an alternative; Diazepam is light sensitive, binds to plastic, and should NOT be given IM
            </li>
            <li>
            If you find yourself giving a second dose, you should be considering a longer-term anti-epileptic (Levetiracetam or Phenobarbital); If you find yourself giving a third dose, you should be considering a midazolam CRI
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Collect samples</span> for CBC/EPOC (or similar)/Liver Panel/Urinalysis and Phenobarbital Levels
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Serum separator tubes will affect phenobarbital measurement; use a red or green top tube instead
            </li>
            <li>
            Consider Ethylene Glycol test in appropriate cases
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Consider 60 mg/kg Levetiracetam (100 mg/mL) = {(this.state.weightkg*(60)/100).toFixed(2)} mL IV slowly</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Consider 4 mg/kg Phenobarbital <span style={{backgroundColor: '#ff726f'}}>(65 mg/mL)</span> = {(this.state.weightkg*(4)/(65)).toFixed(2)} mL IV; consider loading with sequential doses to total 20 mg/kg/day</span>
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Account for oral maintenance dose and injectable when loading
            </li>
            <li>
            Subsequent doses are generally given every 4 hours, but may be given every 30-60 minutes in certain cases (that are having more seizures)
            </li>
            <li>
            This should be a first choice over Levetiracetam if patient is already on Phenobarbital
            </li>
            <li>
            This is generally considered as an alternative (not adjunct) to Levetiracetam in status epilepticus patients, but may be an adjunct in certain patients
            </li>
            </ul>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Consider midazolam (5 mg/mL) CRI at 0.5 mg/kg/hr = {(this.state.weightkg*(0.5)/5).toFixed(2)} mL/hr</span>
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Increase to 0.75 mg/kg/hr = {(this.state.weightkg*(0.75)/5).toFixed(2)} mL/hr and then 1 mg/kg/hr = {(this.state.weightkg*(1)/5).toFixed(2)} mL/hr if breakthrough seizures
            </li>
            <li>
            Can give for 24 hours
            </li>
            <li>
            Once seizure free, reduce dose by 25% every 6 hours
            </li>
            <li>
            Diazepam is an alternative; it is light sensitive and binds to plastic
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>A small facial or eye twitch may be a residual seizure and should be treated</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Propofol is needed for &le; 5% of SE patients and should be a last resort as it only stops the motor movements (not the brain activity)</span>
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Give 1-8 mg/kg = {(this.state.weightkg*(1)/10).toFixed(2)} mL to {(this.state.weightkg*(8)/10).toFixed(2)} mL IV for induction followed by 0.1 mg/kg/hr = {(this.state.weightkg*(0.1)/10).toFixed(2)} mL/hr titrated up to effect for a maximum of 6 hours
            </li>
            <li>
            Most patients will breathe on their own
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              indeterminate
              color="default"
              /><span style={{fontWeight: '700'}}>The need for gas anesthesia is exceptionally rare</span>
            </Grid>
            </Grid>
        :
        <Grid container spacing={0}>

          <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Presentation:</span> May present in post ictal period or with no residual signs. Primary epilepsy is a differential in young dogs (&le; 5-years-old); metabolic or intracranial causes are more common in dogs &ge; 5-years-old and cats of any age.  Consider liver shunts, infection, and structural causes in young dogs.
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider IV catheter</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider checking blood glucose</span> from front leg or ear (NOT back leg)
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          If &le; 60, give 0.5 to 1 mL/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mL to {(this.state.weightkg*(1)).toFixed(2)} mL of 50% dextrose IV diluted 1:2 with sterile saline
          </li>
          <li>
          If no IV catheter, give dextrose (undiluted) onto the gums
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            />In event of seizure, <span style={{fontWeight: '700'}}>Give 0.25 mg/kg midazolam (5 mg/mL) = {(this.state.weightkg*(0.25)/5).toFixed(2)} mL IV</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          If no IV catheter, give IM or IN (intranasally)
          </li>
          <li>
          Repeat dose in 1 minute if still seizing
          </li>
          <li>
          Patients on phenobarbital may need 2 mg/kg = {(this.state.weightkg*(2)/5).toFixed(2)} mL midazolam (5 mg/mL)
          </li>
          <li>
          Diazepam is an alternative; it is light sensitive, binds to plastic, and should NOT be given IM
          </li>
          </ul>
          </Grid>


          <Grid item xs={12} style={{textDecoration: 'underline'}}>
            Diagnostics
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>CBC/Chemistry</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Urinalysis</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Blood Pressure</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Bile Acids</span> if indicated
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Infectious disease titers</span> if indicated
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Referral for CSF evaluation and MRI</span>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            /><span style={{fontWeight: '700'}}>Consider hospitalization until seizure free for 24 hours</span> for patients that have had multiple or prolonged seizures
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
            Many patients will present after their first witnessed seizure and often do not need maintenance antiepileptic medication after the first event
            </li>
            <li>
            Consider starting a maintenance antiepileptic drug if prolonged seizure, cluster seizures, status epilepticus, repeat seizures of increasing frequency, or based on individual patient
            </li>
            </ul>
          </Grid>


                          <Grid item xs={12}>
                            <Divider style={{margin: '20px 0px'}}/>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider style={{margin: '20px 0px'}}/>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                          Medications for long-term management:
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <span style={{textDecoration: 'underline'}}>Levetiracetam (Keppra)</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            Give by mouth every 8 hours to reduce seizure frequency and severity.
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                          <ul>
                          <li>
                            20 mg/kg starting dose = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(20)/(1)).toFixed(2)} mg</span> every 8 hours for this patient.
                          </li>
                          <li>
                            Relatively safe with few adverse effects
                          </li>
                          <li>
                            Extended release tablets (Levetiracetam ER) are available in 500 and 750 mg (cannot split). 30 mg/kg starting dose = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(30)/(1)).toFixed(2)} mg</span> every 12 hours for this patient.
                          </li>
                          <li>
                            Increase dose or frequency if needed
                          </li>
                          <li>
                            May be a "honeymoon" period for several months where seizures appear well controlled and then worsen
                          </li>
                          </ul>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <span style={{textDecoration: 'underline'}}>Phenobarbital</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            Give by mouth every 12 hours to reduce seizure frequency and severity.
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                          <ul>
                          <li>
                            2-3 mg/kg dose = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/(1)).toFixed(2)} to {(this.state.weightkg*(3)/(1)).toFixed(2)}</span> mg for this patient.
                          </li>
                          <li>
                            To load, consider giving 4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4)/(1)).toFixed(2)}</span> sequentially to reach 20 mg/kg/day loading dose (combined IV and oral dose).
                          </li>
                          </ul>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <span style={{textDecoration: 'underline'}}>Potassium Bromide (KBr)</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            Give by mouth every 24 hours to reduce seizure frequency and severity.
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                          <ul>
                          <li>
                            Several loading protocols
                          </li>
                          <li>
                            Relatively long half life compared to other anti-epileptic drugs
                          </li>
                          <li>
                            Inexpensive relative to other antiepileptic drugs
                          </li>
                          <li>
                            Do NOT use in cats
                          </li>
                          </ul>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <span style={{textDecoration: 'underline'}}>Midazolam</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            In the event of a seizure, squirt up your pets nose.  Seek emergency veterinary care immediately.
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                          <ul>
                          <li>
                            Best if sent home in glass vial with atomization device
                          </li>
                          <li>
                            Not indicated in most situations
                          </li>
                          </ul>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            <span style={{textDecoration: 'underline'}}>Chlorazepate</span>
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                            In the event of cluster seizures, give by mouth every 8 hours for 3 days.
                          </Grid>
                          <Grid item xs={12} style={{textAlign: 'left'}}>
                          <ul>
                          <li>
                            Round up from 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.5)).toFixed(2)} mg</span> for this patient.
                          </li>
                          <li>
                            Limited evidence
                          </li>
                          </ul>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider style={{margin: '20px 0px'}}/>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                            Informational Handouts:
                          </Grid>
                          <Grid item xs={12}>
                            <span className="LinkBlue"><a href="https://petparenthandouts.com/seizures" target="_blank" rel="noopener noreferrer">Seizures</a> <span style={{fontWeight: "500"}}>from Pet Parent Handouts</span></span>
                          </Grid>
                          <Grid item xs={12}>
                            <span className="LinkBlue"><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951440" target="_blank" rel="noopener noreferrer">Seizure Disorders in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></span>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider style={{margin: '20px 0px'}}/>
                          </Grid>

                          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
                            Discharge Note (Key Points):
                          </Grid>
                          <Grid item xs={12}>
                            <ul>
                            <li>
                              It is important to keep a journal of seizure activity with date, time, and length of seizure.  Capturing video of the events may be helpful.
                            </li>
                            <li>
                              Take precautions to protect your pet from hurting itself.  Avoid stairs. Pad hard surfaces and kennels.
                            </li>
                            <li>
                              Protect yourself from being bitten.  Do not attempt to put anything in your pet's mouth.
                            </li>
                            </ul>
                          </Grid>

        </Grid>

        }

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup> Podell et al. 2015 ACVIM Small Animal Consensus Statement on Seizure Management in Dogs. J Vet Int Med 2016.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
        </Grid>
        </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default SeizuresQS;
