import React, { useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table, Divider, Link } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MushroomImg from './images/mushrooms-andrew-ridley-unsplash.jpg';
import PsilocybinImg from './images/magic-mushrooms-marcus-loke-unsplash.jpg';
import AmanitaImg from './images/AmanitaPhalloides.jpg';

export default function EmergenciesToxicological() {
  const [refs, setRefs] = useState(false);
  const [ref2, setRef2] = useState(false);
  const [ref3, setRef3] = useState(false);
  const [ref4, setRef4] = useState(false);


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Toxicological Emergencies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto">
              <Button href="/quick-sheets/emesis" variant="outlined">Induction of Emesis Quick Sheet</Button>
            </Grid>
            <Grid item xs={12}>
                <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Albuterol</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Toxicity common when dogs chew up albuterol inhaler.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Tachycardia, Tachypnea, Lethargy, Vomiting, PU/PD, Hyperthermia, Hypotension, Ataxia, Tremoring, Mydriasis<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRefs(!refs)}>1</Button> {refs ? "Meroni et al. Retrospective evaluation of albuterol inhalant exposure in dogs:36cases(2007–2017). JVECC 2019." : "" }</sup></Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Hypokalemia, Hypophosphatemia, Hyperglycemia, Hyperlactatemia</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> </Typography>
                  <ul>
                    <li>
                      Hospitalization to monitor heart rate, blood pressure, potassium, and phosphorus. Consider continous EKG.
                    </li>
                    <li>
                      Supplement potassium and phosphorus as needed. Phosphorus is not compatible with calcium-containing fluids (such as LRS).
                    </li>
                    <li>
                      If fluid bolus given (for hypotension), be mindful of potassium in fluids and do not exceed K<sub>max</sub>. In rare cases, severe hypotension may lead to pulmonary edema. Severe hypotension may be treated with phenylephrine or dopamine (but this is uncommon).
                    </li>
                    <li>
                      Maropitant should be considered to prevent vomiting.
                    </li>
                    <li>
                      If tachycardia, consider propranolol (0.02 to 0.06 mg/kg slowly to effect, higher doses may be needed for some).
                    </li>
                    <li>Acepromazine may worsen hypotension; consider butorphanol +/- midazolam if agitated.</li>
                    <li>Consider methocarbamol for tremors.</li>
                    <li>Duration of treatment is often less than 24 hours, but may be up to 72 hours.</li>
                    <li>For outpatient treatment (not ideal for most), consider SC fluids, oral potassium supplementation for 3 days, and atenolol for 3 days.</li>
                  </ul>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Amphetamines</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Include Adderall, Vyvanse, methamphetamines, ecstasy. With XR products, onset of signs can be delayed by 4-6 hours.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Commonly see agitation, tremors, odd behavior, vocalization, hallucinations, tachycardia, hypertension, hyperthermia. May see serotonin syndrome.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> May be positive on drug screens. Cross reaction on human drug tests is possible.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> </Typography>
                  <ul>
                    <li>
                      Acepromazine as needed for sedation. Generally start at 0.05 mg/kg and give every 10 minutes and titrate up to 1 mg/kg as needed to control signs.
                    </li>
                    <li>
                      If serotonin syndrome: Cyproheptadine at 1.1 mg/kg PO or by rectum (crush and suspend) for 2 doses
                    </li>
                    <li>
                      Avoid benzodiazepines (such as midazolam and diazepam). Use levetiracetam (Keppra) to control seizures if needed.
                    </li>
                    <li>
                      If tachycardia (and normotensive), consider propranolol (0.02 to 0.1 mg/kg to effect). If hypertensive, give more acepromazine. If hyptensive or hypovolemic, correct with fluids. Esmolol is an alternative to propranolol.
                    </li>
                  </ul>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Baclofen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                    Commonly prescribed human muscle relaxant.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Flacid paralysis of skeletal muscles, often see weakness, vocalization, loss of gag reflex, tremors, seizures, eventual coma and death. High risk for aspiration.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Consider maropitant, diazepam/midazolam, acepromazine, cyproheptadine (if serotonin syndrome), methocarbamol (for tremors), diphenhydramine (may help with dystonic reactions&mdash;involentary muscle contractions)</Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Chocolate</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Theobromine (a methylxanthine) and caffeine are toxic.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button href="https://www.veterinarypartner.com/ChocToxCalc_vetPartner.html" target="_blank" rel="noopener noreferrer" variant="outlined">VIN Chocolate Toxicity Calculator</Button>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> vomiting, diarrhea, hyperactivity, tremors, tachycardia and VPCs, seizures, death</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Chocolate tends to pull fluid into the GI tract, so monitor for hypernatremia (especially at doses &ge;100 mg/kg where activated charcoal is indicated)</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Most will present shortly after known ingestion and emesis should be recommended. Activated charcoal is only indicated at doses &ge;100 mg/kg when sodium can be monitored. Treatment should include hospitalization with IV fluid diuresis (2x maintenance for most) and monitoring of HR, BP, ECG, and for CNS signs. Consider maropitant (Cerenia) for nausea/vomiting. Consider acepromazine (start at 0.025 mg/kg IV) for hyperactivity. Consider methocarbamol for tremors. Consider Midazolam and/or Levetiracetam for seizures. Propranolol can be used for tachyarrhythmias but may increase risk of hypertension; esmolol, atenolol, or metoprolol may be best if BP is elevated. Start at low end of 0.02 to 0.06 mg/kg propranolol IV (dilute and give slowly while watching ECG). Lidocaine can be considered for ventricular tachyarrhythmias not responding to beta blockers.
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Garlic & Onion (Allium)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Cause hemolysis, heinz body formation, methemoglobinemia, and anemia. Cats are more sensitive than dogs. Onset of signs in 3-5 days.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" rowSpan={2}>Garlic</TableCell>
                            <TableCell align="left">Dog</TableCell>
                            <TableCell align="left">10 g/kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Cat</TableCell>
                            <TableCell align="left">1 g/kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" rowSpan={2}>Onion</TableCell>
                            <TableCell align="left">Dog</TableCell>
                            <TableCell align="left">15 g/kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Cat</TableCell>
                            <TableCell align="left">5 g/kg</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Decontaminate and consider activated charcoal if recent ingestion (common with garlic/onion butters). Monitor CBC and blood smear for 5 days. Monitor urine color and output. Monitor renal values if pigmenturia. If already experiencing anemia and methemoglobinemia, consider oxygen, blood transfusions, IV fluid therapy, maropitant, N-acetylcystine and ascorbic acid (vitamin C, 20-30 mg/kg PO, IM or IV q8h for 3 days).
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontSize: "0.8rem"}}>Reference: Paul, Lisa. ASPCA: Top Pet Toxicity Calls to ASPCA Animal Poison Control Center. Webinar 2021.</span>
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Grapes & Raisins</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Grapes and raisins are associated with acute kidney injury in dogs
                  </li>
                  <li>
                  Tartaric acid is thought to play a role in the toxicity<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRefs(!refs)}>1</Button> {refs ? "Unique sensitivity of dogs to tartaric acid and implications for toxicity of grapes. (2021). Letters to the Editor, Journal of the American Veterinary Medical Association, 258(7), 704-707." : "" }</sup>
                  </li>
                  <li>
                  The Risk threshold is thought to be about 1 grape per 10 pounds. Outpatient monitoring can be considered if confident exposure was less than 1 grape per 10 pounds.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Clinical signs typically develop within 24 hours but may be up to 48 hours. Vomiting, anorexia, polyuria/polydipsia, and lethargy are common in affected dogs.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Elevated creatinine, BUN, calcium, and phosphorus are common. Isosthenuria, proteinuria, and glucosuria are common.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography gutterBottom><span style={{fontWeight: "700"}}>Treatment:</span> Most will present shortly after known ingestion and emesis should be recommended. Treatment should include hospitalization with IV fluid diuresis (2-3x maintenance for most, monitor ins and outs to ensure urine production matches output), monitoring of renal values daily (compare to baseline). Consider maropitant (Cerenia) for nausea/vomiting. Activated charcoal is only indicated if ingestion of large amount.
                  </Typography>
                  <Typography>If treating on an outpatient basis (with any degree of concern for &ge;1 grape/10 lbs BW), <span style={{fontWeight: "700"}}>do not give Cerenia</span> as this will mask vomiting associated with AKI and may delay treatment.</Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Lily (Lilium)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Cats may develop renal failure even after minimal exposure to pollen. All parts of the plant are toxic. All lilies (Genus: Lilium) and daylilies (Genus: Hemerocallis) are toxic. Peruvian lilies, which tend to have wider more twisty leaves and slightly different petal shape, are commonly mistaken for true lilies and do not cause renal failure.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Clinical signs typically develop within 48 hours. Vomiting, hypersalivation, polyuria followed by dehydration and anuria, and death may result.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Elevated creatinine (often more extreme than elevation of BUN), BUN, isosthenuria, proteinuria, and glucosuria, and casts (in urine sediment evaluation) are common.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Many will present shortly after known ingestion or exposure and emesis should be recommended. Pollen should be removed from fur even if this requires sedation. Tear-free baby shampoo and a toothbrush can be used around the face to remove stubborn pollen. Treatment should include hospitalization with IV fluid diuresis (2x maintenance for most, monitor ins and outs to ensure urine production matches output), monitoring of renal values daily (compare to baseline). Consider maropitant (Cerenia) for nausea/vomiting. Activated charcoal is only indicated if ingestion of large amount and cat is amenable.
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Mushroom & Mycotoxin (Compost)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  <span style={{fontWeight: "700"}}>Tremorgenic mycotoxins</span> are often ingested with compost or moldy food trash. They are lipophilic, rapidly absorbed, and primarily excreted with bile. Differential diagnoses (especially in cases of unknown ingestion) should include snail bait (metaldehyde), insecticides (pyrethroids, bifenthrin/ant bait), bromethalin (neurotoxic rodenticide), THC, and other neurotoxins.
                  </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{textAlign: "center", margin: "auto"}}>
                    <img src={MushroomImg} alt="various mushrooms" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                    <Typography>The FaceBook group <a href="https://www.facebook.com/groups/144798092849300" target="_blank" rel="noopener noreferrer">Poisons Help; Emergency Identification For Mushrooms & Plants</a> can be a helpful resource when photos of the mushroom are available.</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Absorption is quick, so clinical signs are often seen within 30 minutes of ingestion and may include restlessness, panting, hypersalivation, tremors, seizures, and hyperresponsiveness to stimuli.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Usually none. Rule out hypoglycemia and hypocalcemia.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Many will present shortly after known ingestion or exposure and emesis should be recommended. Treatment should include hospitalization (24-48 hours for most) with IV fluids and symptomatic care. Activated charcoal with sorbitol is recommended if able to safely administer.  Profoundly affected dogs may benefit from gastric lavage and administration of activated charcoal with sorbitol via the orogastric tube. Consider maropitant (Cerenia) for nausea/vomiting. Methocarbamol and midazolam (or diazepam) should be used to control tremors and seizures. Severe cases may be affected for 4-5 days.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>
                  <span style={{fontWeight: "700"}}>Amantida phalloides (death cap) mushrooms</span> cause liver and renal failure and death. Dogs and cats are susceptible.
                  </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{textAlign: "center", margin: "auto"}}>
                    <Typography style={{fontWeight: "700"}}>Amanita phalloides (Death Cap Mushrooms)</Typography>
                    <img src={AmanitaImg} alt="Amanita phalloides mushrooms" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                    <Typography  variant="caption">Archenzo. <i>Amanita phalloides. Piacenza's mountains</i>. <a href="https://en.wikipedia.org/wiki/Amanita_phalloides#/media/File:Amanita_phalloides_1.JPG" target="_blank" rel="noopener noreferrer">Hosted on Wikipedia.</a> Published under the following license: <a href="http://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="noopener noreferrer">CC BY-SA 3.0</a>.</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Clinical signs are delayed by 6-12 hours and then usually start with vomiting, abdominal pain, and diarrhea prior to signs of liver and renal failure (often 36-48 hours following ingestion).</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Significant elevations of liver enzymes and bilirubin are present 36-48 hours after ingestion. Hypoglycemia, coagulopathy, and other sequela of liver failure may be present. Renal failure may also be present. Amanitins can be detected in urine for up to 72-96 hours.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Historically, mortality rate is high once in liver failure. Some will present shortly after known ingestion and decontamination should be recommended. Treatment for liver (+/- renal) failure in affected cases. Octreotide and percutaneous biliary drainage as described in the Modified Santa Cruz Protocol for Amatoxin Mushroom Poisoning should be considered and may improve outcomes.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{margin: '20px 0px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>
                  <span style={{fontWeight: "700"}}>Psilocybin and psilocin</span> are found in "magic mushrooms" that people use recreationally as hallucinogens. Dogs often present after known ingestion.
                  </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{textAlign: "center", margin: "auto"}}>
                    <img src={PsilocybinImg} alt="magic mushrooms" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                    <Typography>Psilocybin and Psilocin are toxins in groups of mushrooms that people use recreationally as hallucinogens.</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Vomiting, altered mentation, ataxia, aggression, vocalization, dilated pupils, nystagmus, tachycardia, weakness, seizures, elevated temperature</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Usually none</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Induce emesis following recent reported or suspected ingestion if safe to do so. Treatment is supportive and may include cyproheptadine, acepromazine, midazolam (or diazepam), IV fluids, and monitoring of temperature and heart rate.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item xs={12}>
                        References:
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12}>
                          <p>Peterson. Mycotoxins. In <i>Small Animal Toxicology, 3rd</i>. Elsevier Saunders, pp. 677-682.</p>
                          <p>Puschner. Mushrooms. In <i>Small Animal Toxicology, 3rd</i>. Elsevier Saunders, pp. 677-682.</p>
                          <p>Goupil et al. Clinical recovery of 5 dogs from amatoxin mushroom poisoning using an adapted Santa Cruz protocol for people. JVECC 2021.</p>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>NSAIDs & Acetaminophen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Medication</TableCell>
                            <TableCell align="left">Dose (mg/kg)</TableCell>
                            <TableCell align="left">Concern</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" rowSpan={3}>Ibuprofen</TableCell>
                            <TableCell align="left">&ge;50 mg/kg DOGS; Any dose in CATS</TableCell>
                            <TableCell align="left">Gastrointestinal Ulceration</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;125 mg/kg DOGS; &ge;20 mg/kg CATS</TableCell>
                            <TableCell align="left">Renal toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;400 mg/kg DOGS</TableCell>
                            <TableCell align="left">CNS toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" rowSpan={3}>Carprofen</TableCell>
                            <TableCell align="left">&ge;20 mg/kg DOGS; &ge;4 mg/kg CATS</TableCell>
                            <TableCell align="left">Gastrointestinal Ulceration</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;40 mg/kg DOGS; &ge;8 mg/kg CATS</TableCell>
                            <TableCell align="left">Renal toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;40 mg/kg; &ge;100mg/kg</TableCell>
                            <TableCell align="left">Liver toxicity (idiosyncratic and not dose dependent, but may see ALT and ALP elevations ranging from mild at 40 mg/kg to significant at 100 mg/kg)</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Meloxicam</TableCell>
                            <TableCell align="left">Narrow margin of safety (especially in cats)<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRef2(!ref2)}>1</Button> {ref2 ? "Talcott et al. Nonsteroidal Antiinflammatories. Small Animal Toxicology 2013." : "" }</sup></TableCell>
                            <TableCell align="left">GI upset/ulceration and renal toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" rowSpan={2}>Naproxen</TableCell>
                            <TableCell align="left">&ge;1 - 7 mg/kg</TableCell>
                            <TableCell align="left">GI upset, GI ulceration</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;13-15</TableCell>
                            <TableCell align="left">Renal toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" rowSpan={3}>Acetaminophen</TableCell>
                            <TableCell align="left">Any dose</TableCell>
                            <TableCell align="left">GI upset, KCS in DOGS; Methemoglobinemia (and rarely liver toxicity) in CATS</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;100 DOGS</TableCell>
                            <TableCell align="left">Liver toxicity</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;200 mg/kg DOGS </TableCell>
                            <TableCell align="left">Methemoglobinemia</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Ibuprofen:</span> Present following known ingestion or due to GI signs; may present with AKI</Typography>
                  <ul>
                  <li><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> If present early, monitor renal values for 48 hours; at high doses (&ge;400 mg/kg), monitor acid base status.</li>
                  <li><span style={{fontWeight: "700"}}>Treatment:</span> Many will present shortly after known ingestion or exposure and emesis should be recommended if within 1-2 hours. Activated charcoal. GI protectants (omeprazole, sucralfate) for 14 days. Cholestyramine only indicated at really high levels. Fluid therapy at 2x maintenance if not contraindicated based on individual patient. Treat AKI accordingly. See <Link to='/quick-sheets/aki'>Acute Kidney Injury Quick Sheet</Link>. Cases with CNS signs may benefit from naloxone, intralipids, and therapeutic plasma exchange.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Carprofen:</span> Present following known ingestion or due to GI signs; may present with AKI or liver toxicity</Typography>
                  <ul>
                  <li><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> If present early, monitor renal values for 48 hours.</li>
                  <li><span style={{fontWeight: "700"}}>Treatment:</span> Treat similar to ibuprofen. Liver protectants (NAC or Denamarin) based on dose and concern.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Meloxicam:</span> Patients often present following known ingestion or due to GI signs; may present with AKI</Typography>
                  <ul>
                  <li><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> If patient presents early, monitor renal values for 48 hours.</li>
                  <li><span style={{fontWeight: "700"}}>Treatment:</span> Treat similar to ibuprofen.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Naproxen:</span> Long, up to 72-hour half life in dogs; GI effects should present themselves within 24 hours; Renal effects should present themselves withing 48 hours<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRef3(!ref3)}>2</Button> {ref3 ? "DeClementi. Toxicology Brief: Naproxen toxicosis in dogs. dvm360 2013." : "" }</sup></Typography>
                  <ul>
                  <li><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Monitor GI (may see BUN elevation with GI ulceration), renal values, and liver values.</li>
                  <li><span style={{fontWeight: "700"}}>Treatment:</span>  Similar to other NSAIDS, but treatment duration and monitoring may need to be longer. Consider early referral for apheresis (Therapeutic Plasma Exchange, TPE) in cases with concern for renal toxicity.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Acetaminophen:</span> Patients often present following known ingestion or due to GI signs, liver damage, or methemoglobinemia</Typography>
                  <ul>
                    <li>Can be used therapeutically for dogs as part of multi-modal pain management in select cases. For most, safer options exist.</li>
                  <li><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Monitor liver values. Methemoglobinemia can be monitored by placing drop of blood on white paper and comparing brown to that of control blood. Monitor STT q24h for 48h in dogs (especially small dogs).</li>
                  <li><span style={{fontWeight: "700"}}>Treatment:</span> Many will present shortly after known ingestion or exposure and emesis should be recommended if within 2 hours in dogs; emesis is not usually recommended for cats (absorption is within 30 minutes). Activated charcoal (only if within 30 minutes in the CAT). Fluid therapy is recommended. N-Acetylcysteine (or Denamarin) is recommended. Ascorbic acid (Vitamin C) at 30 mg/kg PO or by injection q6-12h may be helpful if methemoglobinemia.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontSize: "0.8rem"}}>Reference: Paul, Lisa. ASPCA: Top Pet Toxicity Calls to ASPCA Animal Poison Control Center. Webinar 2021.</span>
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Permethrin</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Toxicity often seen in cats when canine flea & tick applied to the cat
                  </li>
                  <li>
                  Can be seen in dogs (or cats) with ingestion of insecticides (ant bait, roach bait&mdash;large quantities from the bag, not small plastic traps)
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Muscle tremors, seizures, hyperesthesia, hypersalivation, death. Clinical signs may persist for 2-3 days, but can consider PO dosing of methocarbamol if patient can safely swallow.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography gutterBottom><span style={{fontWeight: "700"}}>Treatment:</span> Decontaminate (topical flea/tick) by bathing with Dawn dish soap or similar. Methocarbamol at 55 to 220 mg/kg IV to effect. In cases of insecticide ingestion, consider an enema (10-15 mL/kg). Midazolam for seizures. IV fluid therapy and maropitant. Intralipids can be considered but will also decrease methocarbamol. Monitor temperature and cool as needed.
                  </Typography>
                  <Typography>A methocarbamol CRI has been described (in cats): subtract the initial dose from total daily dose of 330 mg/kg/day and administer the remainder as a CRI.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1", fontSize: "1rem"}} onClick={()=>setRef4(!ref4)}>1</Button> {ref4 ? "Draper et al. Methocarbamol CRI for Sympomatic Treatment of Pyrethroid Intoxication: A Report of Three Cases" : "" }</sup></Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Rodenticides (Rat Bait)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Anticoagulant Rodenticides</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                    Include warfarin, diphacinone, bromadiolone, and others. Cause internal bleeding within 4-7 days.
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Patients present after known ingestion or present days later for signs related to coagulopathy and bleeding.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Elevation of PT once affected (in 48-72 hours post exposure)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Treatment:</span> </Typography>
                    <ul>
                      <li>
                        Vitamin K1 (1.5-2.5 mg/kg PO q12h with food for 30 days; give SC initially if not eating and hospitalized) with PT check 60-72 hours after last dose; alternatively, check PT at 60-72 hours after ingestion to help rule out anticoagulant rodenticide exposure.
                      </li>
                      <li>
                        If PT is already elevated, monitor in hospital for 12-24 hours.
                      </li>
                      <li>
                        If bleeding, given fresh frozen plasma or fresh whole blood to replace clotting factors.
                      </li>
                    </ul>
                    </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Bromethalin</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                    Neurotoxic to dogs and cats; causes paralytic syndrome at lower doses and convulsant syndrome at higher doses including tremors, seizures, hyperthermia, and death within 4-36 hours. In cats, signs present 2-7 days after ingestion of lethal dose. In dogs, sublethal dose ingestion (&le;2.5 mg/kg) will cause signs within 24-86 hours.
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Aggressive decontamination (induce emesis if within 4 hours), IV fluid therapy, and activated charcoal (based on ingested dose and age of patient; up to 3 doses/day for 2 days). Monitor sodium.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                  <Typography><span style={{fontSize: "0.8rem"}}>Reference: Paul, Lisa. ASPCA: Top Pet Toxicity Calls to ASPCA Animal Poison Control Center. Webinar 2021.</span>
                  </Typography>
                  </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Colecalciferol (Vitamin D) Rodenticides</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    1-4 days for signs to develop
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> vomiting, anorexia, PU/PD, weakness, depression</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> hypercalcemia, hyperphosphatemia, seconary AKI; monitor BUN, creatinine, calcium, phosphorus for 4 days</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Emesis; activated charcoal; Cholestyramine (300-500 mg/kg PO q8h for 4 days; always ensure NO Xylitol); feed low calcium diet (consider macoroni and lean ground beef; Hill's k/d may be alternative)</Typography>
                      <ul>
                        <li>If hypercalcemia: IV fluid diuresis; consider furosemide (2.5 to 4.5 mg/kg PO q6-8h or CRI at 0.3 mg/kg/hr); Prednisone at (1-3 mg/kg/day); Aluminum Hydroxide (30-90 mg/kg/day divided with meals); consider pamidronate; will need weeks of monitoring BUN, creatinine, phosphorus, and calcium&mdash;initially daily, then every 2-3 days, then weekly.</li>
                      </ul>
                    </Grid>
                    <Grid item xs={12}>
                  <Typography><span style={{fontSize: "0.8rem"}}>Reference: Paul, Lisa. ASPCA: Top Pet Toxicity Calls to ASPCA Animal Poison Control Center. Webinar 2021.</span>
                  </Typography>
                  </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Unknown Rodenticides</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                    Must treat and monitor as if it could have been any of the anticoagulant, bromethalin, or cholecalciferol baits! Do not guess at which it may have been&mdash;there is too much variation and overlap in appearance of bait blocks.
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Monitoring/Treatment:</span></Typography>
                    <ul>
                      <li>
                        Decontamination with emesis and charcoal if within 4 hours. Consider maropitant prior to charcoal.
                      </li>
                      <li>
                        Vitamin K1 (1.5-2.5 mg/kg PO q12h with food for 30 days) with PT check 60-72 hours after last dose; alternatively, check PT at 60-72 hours after ingestion to help rule out anticoagulant rodenticide exposure.
                      </li>
                      <li>
                        Monitor BUN, creatinine, phosphorus, and calcium for 4 days to rule out cholecalciferol. Fast patient for 4-6 hours prior to blood work to minimize post-prandial increase in phosphorus.
                      </li>
                      <li>
                        Monitor poop for passage of colored bait.
                      </li>
                    </ul>
                    </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Aldicarb (Tres Pasitos)</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                    Not approved for household use in the United States, but may be brought in from Mexico. 
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Inhibits acetylcholinesterase and causes difficulty breathing, GI signs, seizures, and death</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Exceptionally toxic. Most die if symptomatic. Consider atropine and supportive care.</Typography>
                    </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Sago Palm (Cycad) Ingestion</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Cause liver toxicity, neurotoxicity, and gastrointestinal disease in dogs. Clinical signs within 24 hours; lab work abnormalities often within 72 hours post ingesiton.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> GI signs, mentation change, tremors, seizures, head pressing, jaundice</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Liver value elevation, thrombocytopenia, PT elevation</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> IV fluids with dextrose, nutritional support, NAC, vitamin E, vitamin K, plasma if PT/PTT prolongation and hemorrhage, gastroprotectants (PPIs), antibiotics (such as third generation cephalosporin) only if concern for infeciton or sepsis</Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>THC</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Toxicity is common even in dogs with no known possible exposure. 
                  </li>
                  <li>
                  Concurrent chocolate toxicity is common.
                  </li>
                  <li>
                  Be suspicious in any dog with bradycardia, altered mentation, ataxia, increased sensitivity to motion or sound, and urine dribbling. In cases of bradycardia and altered mentation without known history of THC exposure, rule out hypertension and Cushing's reflex as you may see with GME/GMU cases.
                  </li>
                  <li>
                    Human urine drug screening test can confirm suspicion, but can see false negatives as these were not designed to measure the metabolites seen in dogs.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Ataxia, bradycardia, altered mentation/dull/depressed, increased sensitivity to motion or sound, hyperesthesia, dribbling urine</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography gutterBottom><span style={{fontWeight: "700"}}>Treatment:</span> Decontamination if not mentally altered (usually pet is mentally altered). Antiemetic (maropitant) if mentally altered to prevent vomiting and aspiration risk. Supportive care with IV fluid therapy and time (minimize stimulation). Intralipids may be considered in severe cases that are comatose or close to comatose.
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Xylitol Ingestion</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>
                  Dogs may develop vomiting, hypoglycemia, lethargy, ataxia, tremors, seizures, elevated liver values, changes in potassium and phosphorus, and coagulopathies.
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Dose (mg/kg)</TableCell>
                            <TableCell align="left">Concern</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">50-100</TableCell>
                            <TableCell align="left">Gastrointestinal Signs</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">100-200</TableCell>
                            <TableCell align="left">Hypoglycemia Possible</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;200</TableCell>
                            <TableCell align="left">Hypoglycemia Expected</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&ge;500</TableCell>
                            <TableCell align="left">Hepatotoxicity</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> Absorption is quick. Some dogs will vomit prior to presentation. Lethargy, ataxia, tremors, and seizures are possible with hypoglycemia. If ALT &ge;700, monitor clotting times for coagulopathy. Some dogs will develop liver failure despite never showing hypoglycemia.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Lab work abnormalities:</span> Hypoglycemia, elevated liver values, coagulopathy, possible potassium and phosphorus derangements.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Many will present shortly after known ingestion or exposure and emesis should be recommended. If BG &le;60 and symptomatic, give 0.5 to 1 mL/kg 50% dextrose (diluted 1:2 with sterile saline). Treatment should include hospitalization with IV fluids and 5% dextrose supplementation (supports liver), monitoring of liver values (with bilirubin) at 12 and 24 hours and then daily until ALT improving. Start NAC or Denamarin for hepatoprotection. Start vitamin K1 and/or consider plasma transfusion if coagulopathy suspected. Consider maropitant (Cerenia) for nausea/vomiting. Activated charcoal is not indicated (it does not absorb xylitol). Supplement potassium and phosphorus as needed.
                  </Typography>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>


  );
}
