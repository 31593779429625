import React, {useState} from 'react'
import { Grid, Paper, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import toxocara from './images/Toxocara.JPG';
import ancylostoma from './images/Ancylostoma.JPG';
import trichuris from './images/Trichuris.JPG';
import isospora from './images/Isospora.JPG';
import tapeworm from './images/tapeworms.jpg';

export default function Fecal() {
  const [refs, setRefs] = useState(false);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Fecal Ova Testing</Typography>
      </AccordionSummary>
      <AccordionDetails>
            <Typography><span style={{fontWeight: "700"}}>Depending on health and lifestyle, pets should be tested for intestinal parasites twice each year.<sup>1</sup></span></Typography>
            <Typography>Puppies and kittens should be tested 4 times in their first year of life.</Typography>

            <Typography><span style={{fontWeight: "700"}}>Broad spectrum intestinal parasite prevention should be used year round.</span></Typography>

            <Typography><span style={{fontWeight: "700"}}>Dog and cat poop can transmit disease to humans.</span></Typography>
            <Typography>Adult dogs and cats may show no signs of infection, so testing apparently healthy pets is still important.</Typography>

            <Typography><span style={{fontWeight: "700"}}>Fecal centrifugation is superior to other flotation techniques.<sup>2</sup></span></Typography>
            <Typography>This is the technique that should be used to look for hookworm, roundworm, and whipworm eggs.</Typography>

            <Typography><span style={{fontWeight: "700"}}>50 mg/kg fenbendazole every 24 hours for 3-5 days</span> treats most intestinal parasites (including Giardia; excluding Coccidia and Tapeworms). This is a good empirical treatment for many cases of diarrhea especially for dogs in high risk areas that have not been on regular prevention</Typography>
            <Typography><span style={{fontWeight: "700"}}>Coccidia can be treated with 50mg/kg sulfadimethoxine (Albon) for 5 days</span>. Ponazuril (50 mg/kg q24h for 3 days) may be more effective.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Lister et al.</Button> {refs ? " Use of ponazuril paste to treat coccidiosis in shelter-housed cats and dogs. Vet Parasitol 2014." : "" }</sup></Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Roundworms</span></Typography>
              <img src={toxocara} style={{borderRadius:"5%", maxWidth: "96%"}} alt="toxocara ova"/>
              <Typography>Toxocara canis ova.  Zoonotic (can be transmitted to humans).  Children are at increased risk of ingestion of the eggs and subsequent infection.  May cause visceral larva migrans and ocular larva migrans. <a href="https://www.cdc.gov/dpdx/toxocariasis/index.html" target="_blank" rel="noopener noreferrer">The CDC has additional information here.</a>
              </Typography></Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hookworms</span></Typography>
              <img src={ancylostoma} style={{borderRadius:"5%", maxWidth: "96%"}} alt="ancylostoma ova"/>
              <Typography>Ancylostoma caninum ova. Zoonotic (can be transmitted to humans).  Penetrate the and may cause cutaneous larva migrans in humans. <a href="https://www.cdc.gov/parasites/zoonotichookworm/biology.html" target="_blank" rel="noopener noreferrer">The CDC has additional information here.</a>
              </Typography></Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Whipworms</span></Typography>
              <img src={trichuris} style={{borderRadius:"5%", maxWidth: "96%"}} alt="trichurus ova"/>
              <Typography>Trichuris vulpis ova. Not considered zoonotic (little risk of transmission to humans).
              </Typography></Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Coccidia</span></Typography>
              <img src={isospora} style={{borderRadius:"5%", maxWidth: "96%"}} alt="isospora ova"/>
              <Typography>Cystoisospora (Isospora) canis. Not zoonotic (cannot be transmitted to humans).
              </Typography>
              <Typography>Treat with 5 days 50mg/kg sulfadimethoxine (Albon).
              </Typography>
              </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Tapeworm (Dipylidium)</span></Typography>
              <img src={tapeworm} style={{borderRadius:"5%", maxWidth: "96%"}} alt="isospora ova"/>
              <Typography>Tapeworm proglottids often look like grains of rice on the fur, near the anus, or on fabric at home. Requires flea intermediate host to be ingested by dog or cat
              </Typography>
              <Typography>Treat with praziquantel or combination products containing praziquantel.
              </Typography>
              </Paper>
              </Grid>

            </Grid>

            <h3><a href="https://capcvet.org/guidelines/general-guidelines/" target="_blank" rel="noopener noreferrer">Companion Animal Parasite Council General Guidelines</a></h3>

            <p style={{fontSize: ".8rem"}}><sup>1</sup>Companion Animal Parasite Council (CPAC). <a href="https://capcvet.org" target="_blank" rel="noopener noreferrer">capcvet.org</a>. 2020.</p>
            <p style={{fontSize: ".8rem"}}><sup>2</sup>Dryden et al. Comparison of common fecal flotation techniques for the recovery of parasite eggs and oocysts. Vet Ther 2005.</p>
      </AccordionDetails>
    </Accordion>
  );
}
