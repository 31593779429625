import React, { useState, useEffect } from "react";
import AppBar from './components/AppBar';
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { getUser } from "./lib/userLib";
import { Auth } from "aws-amplify";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isSubscribed, userHasSubscribed] = useState(false);
  const [isErrored, userHasErrored] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      const user = await getUser();
      const paid_status = user.paymentStatus;
      if (paid_status  === 'paid' || paid_status === 'free') {
        userHasSubscribed(true);
      } else if (paid_status === 'error') {
        userHasErrored(true);
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isSubscribed, userHasSubscribed, isErrored, userHasErrored }}>
          <AppBar />
          <ScrollToTop />
          <Routes />
        </AppContext.Provider>
      </>
    )
  );
}

export default App;
