import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

import PericardiocentesisList from '../Notebook/Emergency/PericardiocentesisList';

class PericardialEffusionQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Pericardial Effusion</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            <span style={{fontWeight: '700'}}>Presentation:</span> Collapse, pale, tachycardia, soft heart sounds, weak pulses, vomiting, ascites
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Causes:</span> Neoplastic (by far most common), Idiopathic (20%), Infectious, Hypoalbuminemia, PPDH, Atrial rupture, Uremia, Coagulopathy, Pericardial cysts, Foreign Body
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Diagnostics:</span> Echocardiogram (never use the right short axis views alone to diagnose, as you can mistake right ventricle for pericardial effusion); Radiographs are less sensitive than echo (but are useful metastasis check after pericardiocentesis); EKG may show electrical alternans; Fluid cytology can be considered if no mass on echo or fluid is atypical
            </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Decrease Stress"
            inputProps={{ 'aria-label': 'decrease stress' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL to {(this.state.weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM for this patient.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV fluids"
            inputProps={{ 'aria-label': 'IV fluids' }}
            /><span style={{fontWeight: '700'}}>IV Catheter and Fluid Bolus</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Fluid bolus of 10 to 20 ml/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL</span> Reassess and repeat.
          </li>
          <li>
          Will help reverse cardiac tamponade
          </li>
          </ul>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            indeterminate
            color="default"
            />Diruetic drugs (furosemide) are contraindicated and will further decrease preload
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            /><span style={{fontWeight: '700'}}>ECG and Lidocaine dose</span>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            Continuous ECG attached to watch for ventricular arrhythmias during and after pericardiocentesis
          </li>
          <li>
            Prepare a dose of 2% lidocaine in case of ventricular arrhythmias during the procedure (2 mg/kg canine; 0.5 to 1 mg/kg feline)
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />Echocardiogram
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            Identification of mass is easiest prior to pericardiocentesis, but this should not delay treatment in unstable patients
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            /><span style={{fontWeight: '700'}}>Pericardiocentesis</span>
          </Grid>
          <PericardiocentesisList />



            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', paddingBottom: '10px'}}>
            <a href="https://onlinelibrary.wiley.com/doi/10.1111/vec.12529" target="_blank" rel="noopener noreferrer">Murphy et al. Use of Yunnan Baiyao and epsilon aminocaproic acid in dogs with right atrial masses and pericardial effusion. JVECC 2016.</a>
            <Typography>Yunnan Baiyao +/- Epsiolon Aminocaproic Acid does not significantly delay recurrence or improve survival in dogs with RA masses and PE.</Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Yunnan Baiyao</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', paddingBottom: '10px'}}>
              Consider 1-2 capsules by mouth 1-2 times each day.  Small, red "emergency pill" in the center of foil packaging is given when significant shock or hemorrhage.
              <Typography>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102894&id=10105282#:~:text=Yunnan%20Baiyao%20is%20usually%20available,instead%20of%20the%20regular%20capsule." target="_blank" rel="noopener noreferrer">Yunnan Baiyao Handout</a> from Veterinary Partner
              </Typography>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Aminocaproic Acid</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8 hours.
            </Grid>



            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://www.cvcavets.com/pericardial-effusion/" target="_blank" rel="noopener noreferrer">Pericardial Effusion</a> <span style={{fontWeight: "500"}}>from CVCA Cardiac Care for Pets</span>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951909" target="_blank" rel="noopener noreferrer">Hemangiosarcoma is Blood or Skin Cancer</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
            </Typography>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default PericardialEffusionQS;
