import React from 'react';
import { Grid, Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import retrobulbarBlockImg from './images/retrobulbarBlock.jpg'

export default function RetrobulbarBlock() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="retrobulbar-content"
          id="retrobulbar-header"
        >
          <Typography>Retrobulbar Block</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ul style={{padding: '0 1rem', margin: 0}}>
              <li>
              10 degree bend in a 1 and 1/2" 22ga needle
              </li>
              <li>
              Contraindicated in cases of suspected cancer or fungal infection
              </li>
              <li>
              A post-exposure block may be more easily performed if less experienced
              </li>
              <li>
              Do not attempt in cats (difficult to position, less retrobulbar space than dogs)
              </li>
              <li>
              Eyelids may be blocked separately
              </li>
              </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Retrobulbar Block Needle Placement</span></div>
                <img src={retrobulbarBlockImg} alt="drawing of retrobulbar block needle placement" style={{borderRadius:"3%", maxWidth: "96%"}}/>
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

  );
}
