import React, { useState } from "react";
import { Stack, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { getUser } from "../lib/userLib";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import styled from '@emotion/styled';

const StyledStack = styled(Stack)`
  padding: 30px 10px;
  max-width: 400px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
`;


export default function Login() {
  const { userHasAuthenticated, userHasSubscribed, userHasErrored } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      const user = await getUser();
      user.paymentStatus === 'paid' || user.paymentStatus === 'free' ? userHasSubscribed(true) : userHasSubscribed(false);
      user.paymentStatus === 'error' ? userHasErrored(true) : userHasErrored(false);
      userHasAuthenticated(true);
    } catch (e) {
      if (e.toString().includes("Incorrect username or password.")) {
        setLoginError(true)
      } else {
        onError(e);
      }
      setIsLoading(false);
    }
  }

  return (
    <StyledStack
      component="form"
      spacing={2}
      onSubmit={handleSubmit}
    >
      <TextField
        id="email"
        type="email"
        autoFocus
        value={fields.email}
        label="Email"
        onChange={handleFieldChange}
      />
      <TextField
        id="password"
        type="password"
        value={fields.password}
        autoComplete="off"
        label="Password"
        onChange={handleFieldChange}
      />
      <LoaderButton type="submit" variant="outlined" isLoading={isLoading} disabled={!validateForm()}>
        Login
      </LoaderButton>
      { loginError ?
        <Alert severity="error">
          Incorrect username or password.
        </Alert>
        :
        <>
        </>
      }
      <Typography component={Link} to="/reset-password" sx={{fontSize:'0.9rem', textAlign: 'center', paddingTop: '40px'}}>Reset Password?</Typography>
    </StyledStack>
  );
}
