import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/journal/14764431#pane-01cbe741-499a-4611-874e-1061f1f4679e11" target="_blank" rel="noopener noreferrer">Journal of Veterinary Emergency and Critical Care</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://recoverinitiative.org/cpr-guidelines/current-recover-guideline/" target="_blank" rel="noopener noreferrer">RECOVER Veterinary CPR Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://avhtm.org/i4a/pages/index.cfm?pageid=1" target="_blank" rel="noopener noreferrer">Association of Veterinary Hematology and Transfusion Medicine</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
