import React, {useState} from 'react';

import {Accordion, Grid, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Thoracocentesis from './ThoracocentesisProcedure';

export default function Pneumothorax() {
  const [refs, setRefs] = useState(false);

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Pneumothorax</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto" style={{paddingBottom: "20px"}}>
                  <Button href="/quick-sheets/respiratory-distress" variant="outlined">Respiratory Distress Quick Sheet</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Air around the lungs may be secondary to trauma, iatrogenic, or spontaneous. An open pneumothorax occurs when there is a penetrating wound through the chest wall. A tension pneumothorax is when air can enter but not escape (one-way valve concept) leading to increased trapped air around the lungs.                    
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Will present with breathing difficulty and may have paradoxical abdominal motion with abdominal effort to breath.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Other causes of respiratory distress.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Diagnostics:</span> Patients with increased respiratory effort but decreased lung sounds should be suspect. A blind dorsal tap is indicated if high suspicion of pneumothorax. Ultrasound or radiographs may confirm diagnosis. On focused lung ultrasound, absence of a sliding sign or presence of a stratosphere (barcode) sign indicate pleural 
                    air.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Bhoil et al.</Button> {refs ? " Signs and lines in lung ultrasound. J Ultrason 2021." : "" }</sup>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: "700"}}>Treatment:</span>
                    </Typography>
                    <Thoracocentesis />
                    <Typography gutterBottom>
                    Depending on the underlying cause (especially if spontaneous/not traumatic) prepare the pet parent for further diagnostics which may include CT and surgical exploration of the chest. Autologous blood patching may be an option, but should be attempted in a facility that can also provide thoracic surgery if needed.
                    </Typography>
                    <Typography>
                    Supportive care with oxygen and monitoring may be sufficient for traumatic pneumothorax that respond well to initial thoracocentesis.
                    </Typography>
                  </Grid>
                  
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
