import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import AKs from './skinCytologyImages/acantholyticKeratinocytes.jpg';

export default function PemphigusFoliaceus() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Pemphigus Foliaceus</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Presents as pustules which rupture to form crusts. Lesions are usually in the same stage.  Often starts on the face, nasal planum, ear pinnae, and footpads and lesions appear before pruritus.</Typography>
                  <Typography>May be triggered by vaccination or certain drugs.</Typography>
                  <Typography>Skin lesions appear before pruritus and secondary infection.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Differential Diagnosis:</span> Superficial pyoderma (usually see lesions in different stages such as papules, pustules, epidermal collarettes, and crusts), dermatophytosis</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Cytology of intact pustule shows nondegenerate neutrophils and acantholytic keratinocytes. Culture from intact pustule should be sterile. Dermatophyte culture should be negative. Biopsy and histopathology of intact pustule may be useful to confirm.</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Acantholytic Keratinocytes</span></Typography>
                  <img src={AKs} alt="Acantholytic keratinocytes" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Acantholytic keratinocytes are typical in pemphigus foliaceus. If collected from an intact pustule, expect nondegenerative neutrophils and NO bacteria. Acantholytic keratinocytes are not pathognomonic for pemphigus foliaceus, and are also present with chronic inflammation associated with pyoderma.  Notice degenerative neutrophils and bacteria in this image (from an impression smear of a lifted crust in a patient with deep pyoderma, NOT pemphigus).</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Consider referral to a dermatologist early if suspect pemphigus foliaceus.
                  </li>
                  <li>
                  Immunosuppressive steroids are usually required and only about 1/5 of dogs achieve complete remission.
                  </li>
                  <li>
                  Consider azathioprine or chlorambucil if not responding to steroids in confirmed cases.
                  </li>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
