import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';


export default function Learning() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Learning</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={1}>
        <Grid item xs={12} style={{fontWeight: "700", textDecoration: "underline"}}>
        Types of Learning:
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Operant Conditioning:</span> A form of associative learning in which behaviors that are reinforced are more likely to be repeated and behaviors that are punished are less likely to be repeated in similar contexts.
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Classical Conditioning:</span> A form of associative learning in which one event predicts another event that produces a response.  A click from a clicker precedes a treat.
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Habituation:</span> Learning not to respond to a stimulus when no association is applied to the stimulus.
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Sensitization:</span> Becoming more reactive to a harmless stimuli each time it is presented. Can lead to hyperexcitability and fear responses.
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{fontWeight: "700", textDecoration: "underline"}}>
        Contingency & Contiguity:
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Contingency:</span> The extent to which one event predicts another.
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Contiguity:</span> The closeness of two events. Can be spatial or temporal.
            </Grid>
            <Grid item xs={12}>
            Training is most effective when contingency and contiguity are high. If a cue will be followed by a reward, the cue should immediately precede the reward, and initially, the cue should always be followed by the reward.
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{fontWeight: "700", textDecoration: "underline"}}>
        Reinforcement & Punishment:
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Positive Reinforcement:</span> Adding something pleasant
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Negative Reinforcement:</span> Removing something unpleasant
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Positive Punishment:</span> Adding something unpleasant
            </Grid>
            <Grid item xs={12} md={6}>
            <span style={{fontWeight: "700"}}>Negative Punishment:</span> Removing something pleasant
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
