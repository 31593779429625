import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CatNutritionalSupport from '../../Calculators/CatNutritionalSupport';

export default function FelineNutritionalSupport() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Nutritional Support</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <CatNutritionalSupport />
          </Grid>
          <Grid item xs={12}>
            <Typography>        
              <a href="https://journals.sagepub.com/doi/full/10.1177/%EF%BB%BF1098612X221106353" target="_blank" rel="noopener noreferrer">2022 ISFM Consensus Guidelines on Management of the Inappetent Hospitalised Cat</a>
            </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
