import React from 'react'
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Vaccines() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Vaccines</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Principles of Vaccination</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
          Maternally derived antibodies (MDA) last approximately 16 weeks and prevent IgG
          production by puppies and kittens.  Most vaccine protocols should <span style={{fontWeight: "700"}}>start at 6-8 weeks*
          and booster every 3-4 weeks until &ge; 16 weeks</span>.<br />
          *Rabies starts at 12-16 weeks where required by law.<br />
          *FeLV should be started at 8-12 weeks.<br />
          *Lepto, Lyme, & Influenza should start at 12 weeks to prevent hyperimmune response
        </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Adverse Reactions</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
        <Typography>
        <span style={{fontWeight: "700"}}>Type 1 hypersensitivity reaction/Anaphylaxis:</span> occur
        within 24 hours; facial edema, pruritus, wheals in dogs; GI and respiratory signs in cats; can progress
        to anaphylaxis;
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography>
        <span style={{fontWeight: "700"}}>Type 4 hypersensitivity reaction:</span> Cell-mediated; take &ge;12 hours to months; includes granuloma, cutaneous vasculitis, vaccine-associated hypertrophic osteopathy
        </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography>
        <span style={{fontWeight: "700"}}>Feline Injection Site Sarcomas:</span> related to chronic inflammation; associated with any poke; this is the reason we try to vaccinate distal limb or tail in cats; biopsy should be considered for any vaccine-site mass that persists after 3 months, is larger than 2 cm, or grows 1 month after injection
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Core Vaccines</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dogs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Canine Distemper Virus:</span> CDV; transmitted by respiratory secretions and urine; incubation period of 1-4 weeks, shedding at 1 week; 50% are subclinical; <u>Clinical Signs:</u> respiratory &rarr; GI &rarr; Neuro; old dog encephalitis, enamel hypoplasia, hyperkeratosis, CNS signs
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Adenovirus (type 1):</span> CAV1; transmitted by saliva, feces, urine, respiratory secretions; incubation period of 4-9 days; shed in urine for 6-9 months; <u>Clinical Signs:</u> fever, lethargy, inappetence, vasculitis, acute hepatic necrosis, glomerulonephritis, CNS, respiratory, acute death; vaccinate with CAV2
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Canine Parvovirus:</span> CPV2; highly resistant, highly contagious, environmentally stable; transmitted in feces, by direct oral contact, or by fomites; incubation period of 3-10 days with fecal shedding from 3 days (before clinical signs) to 4 weeks; <u>Clinical Signs:</u> myocarditis, GI sloughing, dehydration, septicemia; diagnose with Fecal ELISA or PCR
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Rabies:</span> direct transmission from saliva or neural tissue; high-risk species: skunk, bat, coyote, fox, raccoon; behavior changes and CNS signs; diagnosed by IFA of neural tissue; state and local laws dictate vaccination and quarantine; NOT core in Hawaii (Rabies-free)
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Cats</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Feline Herpesvirus-1:</span> FHV-1; common cause of respiratory disease; almost all cats exposed; oral/nasal transmission; incubation period of 2-5 days with 1-3 weeks of shedding; 80% lifelong carriers, 50% of these have recurrent signs exasperated by stress; <u>Clinical Signs:</u> mild to severe URD; ulcerative keratitis, dendritic ulcers are pathognomonic; vaccination limits severity of disease
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Calicivirus:</span> many strains; environmentally stable; oral/nasal; incubation period is 3-4 days with shedding for 2-4 weeks; <u>Clinical Signs:</u> oral ulcers, URD, lameness and joint swelling, lymphoplasmacytic gingivitis stomatitis; vaccine can cause lameness and mild UR disease
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Panleukopenia:</span> Feline Parvovirus; almost all exposed, most are subclinical; highly resistant; transmitted by feces, fomites, or in utero; incubation period of 7-10 days; <u>Clinical Signs:</u> cerebellar hypoplasia if infected in utero or &le; 2 weeks, sudden death, fading kitten, enteritis, fever, depression, anorexia; can diagnose with canine antigen ELISA SNAP (but usually only shed for 1-2 days)
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Rabies:</span> direct transmission from saliva or neural tissue; high-risk species: skunk, bat, coyote, fox, raccoon; behavior changes and CNS signs; diagnosed by IFA of neural tissue; state and local laws dictate vaccination and quarantine; NOT core in Hawaii (Rabies-free)
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Non-Core Vaccines</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dogs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Infectious Tracheobronchitis:</span> Includes Parainfluenza, Bordetella bronchiseptica, adenovirus 1 and 2, influenza (CIV); cannot vaccinate against Mycoplasmas, Strep canis, herpesvirus, pneumovirus, respiratory pneumovirus; <u>CIV A H3N8 and H3N2:</u> no cross protection from vaccine; hard to test for; revaccinate annually
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Leptospirosis:</span> many serovars; maintained in renal tubules, excreted in urine; wildlife reservoirs; can penetrate intact mucous membranes <u>Clinical Signs:</u> often asymptomatic and undiagnosed, liver or kidney disease, uveitis; zoonotic; <br/>Core vaccine in many areas (including Hawaii)
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Lyme Disease:</span> Borrelia burgdorferi; transmitted by bite of Ixodes tick after &ge; 12 hours feeding; <u>Clinical Signs:</u> joint pain, lameness, fever, anorexia, lethargy, 2-5 months after exposure; ELISA Ab test indicates exposure, western blot confirms; high seroprevalence, rare disease
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Rattlesnake:</span> toxoid; protects against some pit vipers; lasts about 6 months
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Cats</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Feline Leukemia Virus:</span> FeLV; transmitted in utero or by intimate contact; suspected in any sick cat; <u>Clinical Signs:</u> weight loss, depression, poor BCS, immunosuppression, infection, neoplasia, anemia; diagnose with ELISA for p27 antigen, confirm with IFA; <br/>Core vaccine in kittens (2 doses); then every 1-2 years depending on risk (time spent outdoors)
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>FIV:</span> transmitted by bite wounds; vaccine not available in US at this time
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>FIP:</span> Coronavirus ubiquitous; vaccine efficacy questionable
              </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
              <Typography>
              <span style={{fontWeight: "700"}}>Bordetella and Chlamydiophila:</span> limited benefit for cats in catteries
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>


      </AccordionDetails>
    </Accordion>
  );
}
