import React from "react";

import AnesthesiaChecklist from './AnesthesiaChecklist.js'

export default function AnesthesiaChecklistPage() {

  return (
    <>
      <h1>Anesthesia Checklist</h1>
      <AnesthesiaChecklist/>
  </>

  );
}
