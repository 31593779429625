import React, { useState } from "react";
import { Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { getUser } from "../lib/userLib";
import styled from '@emotion/styled';

const StyledStack = styled(Stack)`
  padding: 30px 10px;
  max-width: 400px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
`;


export default function ResetPassword() {
  const navigate = useNavigate();
  const { userHasAuthenticated, userHasSubscribed } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);


  function validateForm() {
    return fields.email.length > 0;
  }

  function validateConfirmationForm() {
    return (
      fields.confirmationCode.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true)
    } catch (e) {
      onError(e);
    }
    setIsSendingCode(false);
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.confirmationCode, fields.password);
      await Auth.signIn(fields.email, fields.password);
      const user = await getUser();
      user.paymentStatus === 'paid' ? userHasSubscribed(true) : userHasSubscribed(false);
      userHasAuthenticated(true);
      user.paymentStatus === 'paid' ? navigate("/clinical-notebook") : navigate("/subscribe");
    } catch (e) {
      onError(e);
      setIsConfirming(false);
    }
  }

  function renderConfirmationForm() {
    return(
      <StyledStack
        component="form"
        spacing={2}
        onSubmit={handleConfirmationSubmit}
      >
        <Typography>Check your email ({fields.email}) for the confirmation code.</Typography>
        <TextField
          id="confirmationCode"
          type="tel"
          autoFocus
          value={fields.confirmationCode}
          label="Confirmation Code"
          helperText = "Please enter the cofirmation code from your email."
          onChange={handleFieldChange}
        />
        <TextField
          id="password"
          type="password"
          value={fields.password}
          autoComplete="off"
          label="New Password"
          onChange={handleFieldChange}
        />
        <TextField
          id="confirmPassword"
          type="password"
          value={fields.confirmPassword}
          autoComplete="off"
          label="Confirm Password"
          onChange={handleFieldChange}
        />
        <LoaderButton type="submit" variant="outlined" isLoading={isConfirming} disabled={!validateConfirmationForm()}>
          Confirm & Login
        </LoaderButton>
      </StyledStack>
    );
  }

  function renderForm() {
    return (
      <StyledStack
        component="form"
        spacing={2}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextField
          id="email"
          type="email"
          autoFocus
          value={fields.email}
          label="Email"
          onChange={handleFieldChange}
        />
        <LoaderButton type="submit" variant="outlined" isLoading={isSendingCode} disabled={!validateForm()}>
          Send Confirmation
        </LoaderButton>
      </StyledStack>
    );
  }

  return (
    <>
      {!codeSent
        ? renderForm()
        : renderConfirmationForm()}
    </>
  );
}
