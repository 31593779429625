import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ACTHStim() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>ACTH Stimulation Test Protocol</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Cosyntropin is available in 0.25 mg/mL or 250 mcg/mL vial</Typography>
            <ul>
              <li>
              Reconstitute with 1 mL of 0.9% NaCl
              </li>
              <li>
              Divide into 0.2 mL aliquots pre-drawn into 1cc syringes
              </li>
              <li>
              Store in the back of the freezer (not the door)
              </li>
              <li>
              Bag that contains the syringes should be labeled with concentration (250 mcg/mL), amount (0.2 mL per syringe), and date
              </li>
              <li>
              Each 0.2 mL aliquot contains 50 mcg of cosyntropin; round up calculated dose to nearest 0.2 mL
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
          <Typography>Ideally, patient is fasted for 8 hours prior to test</Typography>
          </Grid>
          <Grid item xs={12}>
          <ol>
            <li>
            Collect a "PRE" sample of &ge;0.5 mL serum. Label "ACTH PRE".
            </li>
            <li>
            Give 5 mcg/kg IV.
            </li>
            <li>
            Wait 1 hour.
            </li>
            <li>
            Collect a "POST" sample of &ge;0.5 mL serum. Label "ACTH POST".
            </li>
          </ol>
          </Grid>
          <Grid item xs={12}>
          <Typography>In cases of suspected hypoadrenocorticism (HOC, Addison’s disease), glucocorticoid treatment is often started while waiting on results.  If both PRE and POST results are &le;2 mcg/dL, this is consistent with hypoadrenocorticism.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>This test is sometimes used to investigate hyperadrenocorticism (HAC, Cushing’s disease). &ge;22 mcg/dL supports HAC in dogs.</Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
