import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Anaphylaxis from './Anaphylaxis';
import BluntTrauma from './BluntTrauma';
import Bufo from './Bufo';
import ElectricalBurn from './ElectricalBurn';
import Foxtails from './Foxtails';
import Fiberglass from './Fiberglass';
import HeatStroke from './HeatStroke';
import PorcupineQuills from './Porcupine';
import Smoke from './SmokeInhalation';
import SnakeBite from './SnakeBite';

export default function EmergenciesBySystem() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Environmental Emergencies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Anaphylaxis />
          <BluntTrauma />
          <Bufo />
          <ElectricalBurn />
          <Fiberglass />
          <Foxtails />
          <HeatStroke />
          <PorcupineQuills />
          <Smoke />
          <SnakeBite />
        </AccordionDetails>
      </Accordion>


  );
}
