import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ElbowRads from './ElbowRads';

import HipRads from './HipRads';
import StifleRads from './StifleRads';
import OsteosarcomaRad from './OsteosarcomaRad';

export default function ThoracicRads() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Orthopedic Radiographs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Tips & Tricks</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                  <ul>
                  <li>
                    Try to localize the pain before taking radiographs.
                  </li>
                  <li>
                    Stifle disease is more common than hip disease.
                  </li>
                  <li>
                    It is preferable to take comparison radiographs of the other limb separately (do not include both forelimbs or distal hindlimbs in single shot). If taken in a single shot, the beam will be off-angle. You want the beam perpendicular to the area of interest to improve clarity.
                  </li>
                  <li>
                    Include a x-ray calibration ball (orthosphere) at the level of the area of interest in orthopedic radiographs when possible.
                  </li>
                  </ul>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
           <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Forelimb</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ElbowRads />
            </AccordionDetails>
          </Accordion>
          <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Hindlimb</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HipRads />
                <StifleRads />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>Osteosarcoma</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <OsteosarcomaRad />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
           </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}