import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import auralHematoma from './skinLesionImages/auralHematoma.jpg';


export default function AuralHematoma() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Aural Hematoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Typography>
            The external ear pinna can fill with blood when the skin separates from the cartilage.  This is usually secondary to the shaking and scratching that comes with allergic skin disease and ear infection.  Warn clients that the ear may become scarred and deformed (cauliflower ear).
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Diagnostics:</span> Always do ear cytology and treat the underlying otitis externa (most common cause by far) accordingly.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Medical treatment:</span> Treat the underlying otitis externa.  Most will resolve within 1 week with corticosteroids and treatment of underlying ear infection.  Give 0.5 mg/kg prednisone PO q12h for 1-2 weeks. Taper to q24h for 1 week once resolved, then q48h for 1 week.  If not resolved in 2 weeks, reassess and consider surgical correction.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: "700"}}>Surgical treatment:</span> Treat the underlying otitis externa.  Consider placing a teat cannula in the concave pinna or making a surgical incision along the concave skin and then suturing the skin to cartilage to close the space and prevent re-accumulation of fluid.  The patient must wear an e-collar to prevent self-trauma or premature removal of cannula or sutures.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={auralHematoma} alt="aural hematoma" style={{borderRadius:"2%", maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <a href="https://www.acvs.org/small-animal/aural-hematoma" target="_blank" rel="noopener noreferrer">Aural Hematoma Handout</a> <span style={{fontWeight: "500"}}>from the American College of Veterinary Surgeons</span>
          </Typography>
          <Typography>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951446" target="_blank" rel="noopener noreferrer">Aural Hematoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
