import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import HeadTrauma from './HeadTrauma';
import IntervertebralDiscDisease from './IntervertebralDiscDisease';
import Seizures from './Seizures';
import VestibularDz from './VestibularDz';
import Horners from './Horners';
import Golpp from '../InternalMedicine/Golpp';
import Larpar from '../InternalMedicine/Larpar';

export default function NeuroCommonConditions() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Golpp />
          <HeadTrauma/>
          <Horners />
          <IntervertebralDiscDisease/>
          <Larpar />
          <Seizures/>
          <VestibularDz />

        </AccordionDetails>
      </Accordion>

  );
}
