import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Urinalysis from '../ClinicalPathology/Urinalysis';

export default function FLUTD() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Feline Lower Urianry Tract Disease</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/blocked-cat" variant="outlined">Blocked Cat Clinical Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Feline Lower Urinary Tract Disease is a blanket term for a range of disorders associated with the bladder and urethra.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs:</span> straining to urinate, painful urination (may vocalize), pollakiuria, hematuria, inappropriate urination, frequent grooming/licking the genitals</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Causes:</span> Most common causes are Feline Idiopathic Cystitis (FIC), Urethral Plugs, Urolithiasis, Bacterial Cystitis (UTI). Less common causes include diabetes, hyperthyroidism, urinary tract tumors, congenital abnormalities, injury (including spinal cord injury).</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>Feline idiopathic cystitis (FIC) is a diagnosis of exclusion. Several studies suggest it is by far the most common cause of FLUTD in cats &le;10 years old. UTIs are rare in young cats, and especially rare in young male cats.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>Urethral obstruction is a life-threatening medical emergency. It causes post-renal azotemia and hyperkalemia. With worsening hyperkalemia the heart slows and eventually stops.</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
            <ul>
              <li>
              <Urinalysis />
              </li>
              <li>
              Focused bladder ultrasound (or lateral radiograph) to rule out (radiopaque) uroliths
              </li>
              <li>
              Consider urine culture (less appropriate in young male cats with financial constraints)
              </li>
              <li>
              CBC, Chemistry, T4 (in older cats)
              </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
            <ul>
            <li>FLUTD and FIC have been linked to stress and diet. Feed small frequent meals, ideally a wet formulation of a prescription urinary diet. Provide more sources of fresh water (fountains may help). Keep litter boxes clean, quiet, and safe. Reduce stress.</li>
            <li>
            Consider buprenorphine and/or gabapentin.
            </li>
            <li>
            Acepromazine may promote urethral smooth muscle relaxation. Prazosin is falling out of favor since a recent study showed that it increased the rate of recurrent urethral obstruction.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Conway et al.</Button> {refs ? " Prazosin administration increases the rate of recurrent urethral obstruction in cats: 388 cases. JAVMA 2022." : "" }</sup>
            </li>
            <li>
            Consider maropitant (theoretical benefit).
            </li>
            <li>
            Consider amitriptyline for some chronic cases. It may help promote bladder relaxation and has been shown to help some humans with idiopathic cystitis.
            </li>
            <li>
            GAGs and PSGAGs may be beneficial. Consider supplementation with Glycoflex 3 supplement or Adequan (off-label subcutaneous protocols exist for cats).
            </li>
            <li>
            Onsior (robenacoxib) is likely of no benefit as there is no COX2 in the feline uroepithelium.
            </li>
            <li>
            In cases with uroliths, surgery to remove the stones or a dissolution diet should be recommended.  The Minnesota Urolith Center offers free stone analysis.
            </li>
            </ul>
            </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
