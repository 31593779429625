import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import FluidTherapy from './FluidTherapy';
import Antimicrobials from './Antimicrobials';
import BySystem from './BySystem';
import AdditionalResources from './AdditionalResources';
import IMInfectious from './InfectiousDiseases';

export default function InternalMedicine() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <FluidTherapy />
        <Antimicrobials />
        <BySystem />
        <IMInfectious />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
