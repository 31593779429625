import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Hematology() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hematology</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>CBC</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>A complete blood count (CBC, hemogram) looks at the red blood cells, white blood cells, and platelets in the blood. This test is used in combination with other tests and may help identify certain infections, cancers, autoimmune diseases, dehydration, and endocrine diseases.</Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography style={{textDecoration: "underline"}}>Reference Ranges</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left">Dog</TableCell>
                        <TableCell align="left">Cat</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">WBC</TableCell>
                        <TableCell align="left">6.0-17.0 K/uL</TableCell>
                        <TableCell align="left">5.5-19.5 K/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">RBC</TableCell>
                        <TableCell align="left">5.5-8.5 M/uL</TableCell>
                        <TableCell align="left">5.0-10.0 M/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Hb</TableCell>
                        <TableCell align="left">10-20 g/dL</TableCell>
                        <TableCell align="left">8-15 g/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">PCV</TableCell>
                        <TableCell align="left">31-56 %</TableCell>
                        <TableCell align="left">31-48 %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">MCV</TableCell>
                        <TableCell align="left">60-77 fL</TableCell>
                        <TableCell align="left">39-55 fL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">MCHC</TableCell>
                        <TableCell align="left">32-36 g/dL</TableCell>
                        <TableCell align="left">31-35 g/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Total Protein</TableCell>
                        <TableCell align="left">6.0-8.0 g/dL</TableCell>
                        <TableCell align="left">6.0-8.0 g/dL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Platelets</TableCell>
                        <TableCell align="left">200-500 K/uL</TableCell>
                        <TableCell align="left">300-800 K/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Seg. Neutrophils</TableCell>
                        <TableCell align="left">60-77 %</TableCell>
                        <TableCell align="left">35-75 %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Abs. Neut.</TableCell>
                        <TableCell align="left">3,000-11,500/uL</TableCell>
                        <TableCell align="left">2,500-12,500/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Lymphocytes</TableCell>
                        <TableCell align="left">12-30 %</TableCell>
                        <TableCell align="left">20-55 %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Abs. Lymph.</TableCell>
                        <TableCell align="left">1,000-4,800/uL</TableCell>
                        <TableCell align="left">1,500-7,000/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Monocytes</TableCell>
                        <TableCell align="left">3-10 %</TableCell>
                        <TableCell align="left">1-4 %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Abs. Mono.</TableCell>
                        <TableCell align="left">150-1,250/uL</TableCell>
                        <TableCell align="left">0-850/uL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Eosinophils</TableCell>
                        <TableCell align="left">2-10 %</TableCell>
                        <TableCell align="left">2-12 %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Abs. Eos.</TableCell>
                        <TableCell align="left">100-1,250/uL</TableCell>
                        <TableCell align="left">0-1,500/uL</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
              <Typography variant="h6">
                <a href="https://eclinpath.com/hematology/tests/hematology-guide/" target="_blank" rel="noopener noreferrer">EClinPath Quick Test Interpretation</a>
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Packed Cell Volume/Total Solids (PCV/TS)</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                <TableBody>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Low PCV</TableCell>
                      <TableCell align="center">Normal PCV</TableCell>
                      <TableCell align="center">High PCV</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Low TPP</TableCell>
                      <TableCell align="center">Blood loss, overhydration</TableCell>
                      <TableCell align="center">PLN, PLE, Liver disease</TableCell>
                      <TableCell align="center">Protein loss with splenic contraction, Dehydration masking more severe hypoproteinemia</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Normal TPP</TableCell>
                      <TableCell align="center">&uarr;RBC destruction, &darr;RBC production, Blood loss (minor, acute), Anemia of Chronic Disease</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Splenic contraction, Primary or Secondary Erythrocytosis, Dehydration masked hypoproteinemia</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">High TPP</TableCell>
                      <TableCell align="center">Anemia of Chronic Disease, inflammation, lymphoid neoplasia, Dehydration masking more severe anemia</TableCell>
                      <TableCell align="center">&uarr; globulin synthesis, Dehydration masked anemia</TableCell>
                      <TableCell align="center">Dehydration</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Blood Smear</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography>
              <a href="https://www.vet.cornell.edu/animal-health-diagnostic-center/laboratories/clinical-pathology/samples-and-submissions/hematology" target="_blank" rel="noopener noreferrer">How to make a blood smear</a> (Cornell Vet Med Blood Smear Instructions)
              </Typography>
              <Typography>
              Always evaluate the monolayer.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <span style={{fontWeight: "700"}}>1. Estimate WBCs:</span> average # WBCs/10x field x 100-150 or average # WBCs/20x field x 400-600
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <span style={{fontWeight: "700"}}>2. Calculate Absolutes:</span> Identify 100 consecutive WBCs and multiply by %
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <span style={{fontWeight: "700"}}>3. Keep track of polychromatophilic red blood cells (suspected reticulocytes).</span> Reticulocytes are immature red blood cells.  They may be larger or stain more blue/purple than mature RBCs.  Increased number of reticulocytes may indicate that the bone marrow is making more red blood cells or releasing them early. A proper reticulocyte count requires special stain (such as new methylene blue)
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <span style={{fontWeight: "700"}}>4. Evaluate WBC and RBC morphology </span><a href="http://eclinpath.com/atlas/hematology/rbc-morphology/" target="_blank" rel="noopener noreferrer">eClinPath RBC Morphology Images</a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <span style={{fontWeight: "700"}}>5. Platelet estimate</span>: average # platelets/100x field x 15-20,000; look at 10 fields; beware of platelet clumps at the feathered edge&mdash;these will decrease your count.
              </Typography>
              </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
