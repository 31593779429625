import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


class AbscessQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Cat Bite Abscess</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography gutterBottom><span style={{fontWeight: '700'}}>Presentation:</span> Often associated with a cat fight, bite, or scratch, an abscess forms following a contaminated puncture wound. Some cats present with open ruptured abscesses, while others present with painful swelling (not always obvious), limp, or fever. Embedded nature, broken tooth, or projectile (BB, pellet) are less common but often delay healing.</Typography>
            <Typography><span style={{fontWeight: '700'}}>Key points for communication:</span></Typography>
            <ul>
            <li>Sedation or anesthesia is usually necessary to clip surround fur, lance (if not ruptured), and flush the abscess.
            </li>
            <li>FIV/FeLV testing should be performed 60 or more days following the bite.  If status of the patient is unknown, testing can help confirm that current status is negative.
            </li>
            <li>The cat should be kept indoors until healed.
            </li>
            <li>Follow up evaluation is recommended in 10-14 days or sooner if worsening or not improving in the first 3-7 days.
            </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Provide adequate sedation and analgesia considering individual patient and wound.</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Clip, clean, lance, drain, and/or flush the wound</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          These wounds are almost never surgically closed.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Consider CBC, chemistry, and FIV/FeLV testing</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Consider injectable antibiotic and anti-inflammatory</span>
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
            Consider 8 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(8)/80).toFixed(2)} mL</span> of 80 mg/mL Convenia (cefovecin) SC for this patient.
            </li>
            <li>
            Consider 2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/20).toFixed(2)} mL</span> of 20 mg/mL Onsior (robenacoxib) SC for this patient.
            </li>
            </ul>
            </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Pull eyelids over the globe"
            inputProps={{ 'aria-label': 'pull eyelids over the globe' }}
            /><span style={{fontWeight: '700'}}>Consider maropitant and subcutaneous fluids, especially if patient is febrile</span>
          </Grid>

          <Grid item xs={12}>
            <ul>
            <li>
            Consider 1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL maropitant IV for this patient.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              checked={this.state.checked}
              onChange={this.setChecked}
              color="primary"
              label="Pull eyelids over the globe"
              inputProps={{ 'aria-label': 'pull eyelids over the globe' }}
              /><span style={{fontWeight: '700'}}>An E-collar should be recommended to prevent self-trauma and contamination</span>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Gabapentin</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              5-10 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(1)).toFixed(1)} mg</span> q8-12h
            </li>
            <li>
              Available most commonly as 100 mg capsules or 50 mg/mL oral solution
            </li>
            <li>
              Can round up dose and/or increase depending on patient response
            </li>
            </ul>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Onsior (robenacoxib)</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours for 3 days.  This is an NSAID. Do not cobmine with other NSAIDs or steroids.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              Give one 6 mg tablet to cats weighing 2.5 to 6 kg; give two 6 mg tablets to cats weighing 6.1 to 12 kg
            </li>
            <li>
              If injection given, Start 24 hours after.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Clavamox</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 12 hours.  This is an antibiotic. Give with food.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              16-20 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(16)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(20)/(1)).toFixed(1)} mg</span> q12h
            </li>
            <li>
              Available most commonly as 62.5 mg tablets or 62.5 mg/mL solution
            </li>
            <li>
              Human generic Augmentin may be significantly cheaper and is available as 80 mg/mL (400mg/5mL) oral suspension (available in 50 mL bottle)
            </li>
            <li>
              This is an alternative to Convenia (cefovecin)
            </li>
            </ul>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951430" target="_blank" rel="noopener noreferrer">Abscesses from Bite Wounds</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default AbscessQS;
