import React, { useState } from "react";
import { Grid, TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>Drug (concentration)</TableCell>
            <TableCell align="center" rowSpan={2}>Dose Range</TableCell>
            <TableCell align="center" colSpan={2}>Volume (mL)</TableCell>

          </TableRow>
          <TableRow>
            <TableCell align="center">min</TableCell>
            <TableCell align="center">max</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">Acepromazine (1 mg/mL **careful of higher concentrations**)</TableCell>
            <TableCell align="left">0.05 - 0.1 mg/kg</TableCell>
            <TableCell align="left">{(weightKg*(0.05)/(1)).toFixed(2)}</TableCell>
            <TableCell align="left">{(weightKg*(0.1)/(1)).toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Acepromazine (10 mg/mL)</TableCell>
            <TableCell align="left">0.05 - 0.1 mg/kg</TableCell>
            <TableCell align="left">{(weightKg*(0.05)/(10)).toFixed(2)}</TableCell>
            <TableCell align="left">{(weightKg*(0.1)/(10)).toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Dexmedetomidine (0.5 mg/mL)</TableCell>
            <TableCell align="left">1-2 ug/kg</TableCell>
            <TableCell align="left">{(weightKg*(0.001)/(0.5)).toFixed(2)}</TableCell>
            <TableCell align="left">{(weightKg*(0.002)/(0.5)).toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell align="left" colSpan={4}>
          <ul>
          <li>
          This calculator is meant to double check your math.  It is not a substitute for calculating your patient's dose.  Always double check drug dosages and concentrations.  These rough dose ranges are based on clinical experience.
          </li>
          <li>
          For acepromazine, titrate to effect IV.  Give IM or SC if IV access not possible.
          </li>
          <li>
          Dexmedetomidine will decrease heart rate, decrease cardiac output, and increase valvular regurgitation; avoid use in patients with suspected heart disease
          </li>
          </ul>
          </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container>
    <Grid item xs={12}>
    <ul>
    <li>
    For fragile patients, start low and avoid dexmedetomidine
    </li>
    <li>
    IM Alfaxalone is off-label
    </li>
    <li>
    Maximum volume per injection site is 0.5 mL/kg
    </li>
    <li>
    Always be prepared to intubate
    </li>
    <li>
    Twitching, tremors, hyper-responsiveness to stimuli may be seen
    </li>
    <li>
    If used, dexmedetomidine can be reversed with an equal volume of atipamezole (Antisedan) IM
    </li>
    </ul>
    </Grid>
    </Grid>
  </>

  );
}
