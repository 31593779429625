import React, {useState} from 'react';

import { Button, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function UreteralObstruction() {
  const [refs, setRefs] = useState(false);
  const [refs2, setRefs2] = useState(false);


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Ureteroliths & Ureteral Obstruction</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvim.14559" target="_blank" rel="noopener noreferrer">ACVIM Small Animal Consensus Recommendations on the Treatment and Prevention of Uroliths in Dogs and Cats</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        Should be suspected over pyelonephritis whenever pyelonephritis is a differential for acute worsening of azotemia. In cats, about 2/3 of total obstructions are stones (usually CaOx, not able to dissolve); about 1/3 of cases involve a stricture; dried solidified blood is also possible. Cases may be associated with infection. Many are partial (not complete) obstructions.        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Feline patients with one big kidney and one little kidney should be suspected
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> AKI on CKD, pyelonephritis, stones, stricture, and tumors can all cause ureteral obstruction
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> Abdominal ultrasound is most helpful (to measure ureteral diameter); some stones may appear on radiographs
        </Typography>
        <Typography>
        Ultrasound is recommended for all CKD cats presenting in a uremic crisis<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Quimby et al.</Button> {refs ? " Renal pelvic and ureteral ultrasonographic characteristics of cats with chronic kidney disease in comparison with normal cats, and cats with pyelonephritis or ureteral obstruction. JFMS 2017." : "" }</sup>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span>        </Typography>

        <ul>
          <li>
          Refer early if interested in SUB (subcutaneous ureteral bypass)
          </li>
          <li>
          The feline ureter is about 0.4 mm in diameter, so extracorporeal shockwave (which breaks stones up into about 1 mm) is not usually recommended.
          </li>
          <li>
          Stents can be considered in canine patients; extracorporeal shockwave can be recommend to canine patients
          </li>
          <li>
          Medical management may include:
          </li>
          <ul>
            <li>
            Medical management should not be attempted for longer than 48 - 72 hours (less if interested in SUBS). Medical management should not be considered or has already failed if oliguria, hyperkalemia, progressive renal pelvis dilation (monitor with serial ultrasound). Big stones will not pass.
            </li>
            <li>
            24-48h IV fluid therapy with weight/fluid overload monitoring
            </li>
            <li>
            Consider prazosin
            </li>
            <li>
            Consider mannitol CRI (0.4 g/kg bolus over 30 min followed by 1 mg/kg/min CRI)
            </li>
            <li>
            Consider amitriptyline<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs2(!refs2)}>Arhar et al.</Button> {refs2 ? " Amitriptyline eliminates calculi through urinary tract smooth muscle relaxation. Kidney International 2003." : "" }</sup>
            </li>
          </ul>
        </ul>
        </Grid>
        
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
