import React, { useState } from "react";
import { TextField, InputAdornment, TableContainer, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);

  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }


  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left"><TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          /><TextField
          id="weightLbs"
          type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
          label="Weight (kg)"
          margin="normal"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
        /></TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">pRBCs</TableCell>
            <TableCell align="left">10 - 15 mL/kg {weightKg ? `= ${(weightKg*(10)).toFixed(0)} mL - ${(weightKg*(15)).toFixed(0)} mL` : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Fresh Whole Blood</TableCell>
            <TableCell align="left">20 mL/kg {weightKg ? `= ${(weightKg*(20)).toFixed(0)} mL` : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">FFP</TableCell>
            <TableCell align="left">10-20 mL/kg {weightKg ? `= ${(weightKg*(10)).toFixed(0)} mL - ${(weightKg*(20)).toFixed(0)} mL` : ""}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>

  );
}
