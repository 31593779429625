import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AdditionalResources() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <a href="https://www.aaha.org/aaha-guidelines/dental-care/dental-care-home/" target="_blank" rel="noopener noreferrer">2019 AAHA Dental Care Guidelines for Dogs and Cats</a>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="http://toothvet.ca/Old%20CUSP%20Articles.htm" target="_blank" rel="noopener noreferrer">Dr. Fraser Hale's Pet Dentistry Articles</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="http://www.vohc.org/" target="_blank" rel="noopener noreferrer">Veterinary Oral Health Council</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://avdc.org/" target="_blank" rel="noopener noreferrer">American Veterinary Dental College (AVDC)</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="http://www.sopforanimals.com/" target="_blank" rel="noopener noreferrer">Specialty Oral Pathology for Animals (SOPA)</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://wsava.org/global-guidelines/global-dental-guidelines/" target="_blank" rel="noopener noreferrer">WSAVA Global Dental Guidelines</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
