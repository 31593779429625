import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AtlantoaxialInstability from '../Neurology/AtlantoaxialInstability';
import IntervertebralDiscDisease from '../Neurology/IntervertebralDiscDisease';
import Wobbler from '../Neurology/Wobbler';

export default function Hindlimb() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Back & Neck</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AtlantoaxialInstability />
          <IntervertebralDiscDisease />
          <Wobbler />
        </AccordionDetails>
      </Accordion>

  );
}
