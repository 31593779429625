import React from "react";

import FelineDktCalculator from './DktCalculator'

export default function x() {

  return (
    <>
      <h1>Feline DKT (Kitty Magic)</h1>
      <FelineDktCalculator/>
  </>

  );
}
