import React, {useState} from 'react';

import { Grid, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Golpp from './Golpp';

export default function Larpar() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Laryngeal Paralysis (LAR PAR)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Congenital Laryngeal Paralysis</span> is rare but affected breeds include: Bouvier de FLanders, Bull Terrier, Dalmatian, Pyrenean Mountain Dog, Rottweiler, Siberian Husky<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Platt and Olby.</Button> {refs ? " BSAVA Manual of Canine and Feline Neurology, 4th edition. BSAVA 2017." : "" }</sup>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Geriatric Onset Laryngeal Paralysis Polyneuropathy (GOLPP)</span> is more common, affects older large breed dogs most frequently, and is part of a progressive generalized neuropathy. (This is what we have historically called “LAR PAR”)
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Golpp />
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
