import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


export default function Pyometra() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Pyometra</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
          <Grid item xs={12}>
          <Typography gutterBottom>Cystic Endometrial Hyperplasia (CEH) may lead to hydrometra, mucometra, or pyometra. It is most common in older, intact, female dogs 4-10 weeks after estrus. Pyometra should be at the top of your differential list in any older intact female patient that is sick. Stump pyometra related to ovarian remnant syndrome is possible even in reportedly spayed patients.</Typography>
          </Grid>
        </Grid>
          <Grid item xs={12}>
          <Typography gutterBottom><span style={{fontWeight: '700'}}>Presentation:</span> Anorexia, Vomiting, Diarrhea, Lethargy, +/- vulvar discharge, PU/PD (result of endotoxemia). With closed-cervix pyometra patients may be severely ill with dehydration and azotemia as the disease progresses from endotoxemia to septic shock.  Patients may have abdominal pain +/- distension. Patients with open-cervix pyometra may have obvious vaginal discharge.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography gutterBottom><span style={{fontWeight: '700'}}>Differential diagnoses:</span> Pregnancy, Neoplasia, Vaginitis </Typography>

          <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span> 
          </Typography>
          <ul>
            <li>CBC/Chemistry: Leukocytosis with neutrophilia, anemia (CEH is a chronic disease), azotemia</li>
            <li>PT/PTT (especially if concern for sepsis)</li>
            <li>Abdominal Imaging (Radiographs and/or ultrasound)</li>
              <ul>
                <li>Uterine dilation on radiographs or ultrasound. Ultrasound is most helpful to differentiate from early pregnancy in patients that were bred. Pyometra may have an echoic appearance within a dilated uterus. Hydrometra will be anechoic.</li>
              </ul>
            <li>Cytology of vaginal fluid may show neutrophils and intracellular bacteria</li>
            <li>Consider thoracic imaging in sick older patients: may help rule out lung metastasis or aspiration pneumonia</li>
            <li>UA</li>
            <ul>
                <li>Avoid cystocentesis prior to surgery. Sample may be collected at surgery and some clinicians prefer to submit urine culture (UTI common) in addition to uterine culture</li>
              </ul>
            <li>Blood glucose monitoring (continue post-operatively)</li>
            <li>Blood pressure monitoring (continue post-operatively)</li>
          </ul>


          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>IV fluid resuscitation</span>
          </Grid>


        <Grid item xs={12}>
        <ul>
        <li>{(weightkg*(22)).toFixed(0)} mL is a 22 mL/kg bolus (1/4 shock bolus for canine patients)</li>
        <li>
        Consider dextrose supplementation as needed
        </li>
        <li>Consider potassium supplementation as needed</li>
        <li>Septic patients may need fresh frozen plasma, especially if coagulopathy detected</li>
        </ul>
        </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Start antibiotic Therapy</span>
          </Grid>
          <Grid item xs={12}>
        <ul>
        <li>
        Epirical choices should provide coverage against E. coli.
        </li>
        <li>
        Consider Unasyn (amoxicillin/clavulanic acid), enrofloxacin, or cefazolin
        </li>
        </ul>
        </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Start opioid analgesia</span>
          </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Consider Methadone at 0.2 mg/kg = {(weightkg*(0.2)/10).toFixed(2)} mL of 10 mg/mL Methadone IM or IV
        </li>
        <li>
        Consider Fentanyl at 3 to 6 mcg/kg = {(weightkg*(3)/50).toFixed(2)} mL to {(weightkg*(6)/50).toFixed(2)} mL of 50 mcg/mL Fentanyl IM or IV followed by a CRI
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Give maropitant (Cerenia)</span>
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        1 mg/kg Maropitant = {(weightkg*(1)/10).toFixed(2)} mL of 10 mg/mL Maropitant IV
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="label"
          inputProps={{ 'aria-label': 'label' }}
          /><span style={{fontWeight: '700'}}>Surgical exploratory laparotomy for ovariohysterectomy (OHE)</span> once the patient is stable
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Surgery is the only reasonable treatment for closed pyometra.
        </li>
        <li>
        Surgery should be recommend for all non-breeding animals, even in cases of open-cervix pyometra.
        </li>
        <li>
        Surgery is the best treatment option for a pus-filled uterus.
        </li>
        <li>Recurrence rate and infertility increase with patient age. OHE should be recommended even to breeding animals if &ge;4 years old.</li>
        </ul>
        </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Very sick patients may need support in hospital for 2-3 days following surgery</span>
          </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Severity and mortality increase in cases of ruptured uterus
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Medical management of open pyometra may be appropriate in select cases</span>
          </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        For open-cervix pyometra, prostaglandin treatment and antibiotic therapy (medical management) can be considered for breeding animals.
        </li>
        <li>
        Careful monitoring is necessary with expected improvement and resolution of discharge within the first week.
        </li>
        </ul>
        </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Medications to consider:
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Clavamox</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 12 hours.  Give with food. This is an antibiotic.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          16 to 20 mg/kg = {(weightkg*(16)).toFixed(1)} mg to {(weightkg*(20)).toFixed(1)} mg
          </li>
          <li>
              Available most commonly as 62.5 mg, 125 mg, 250 mg, or 375 mg chew tablets or 62.5 mg/mL solution
            </li>
            <li>
              Human generic Augmentin may be significantly cheaper and is available as 500/125 mg (625 mg Clavamox equivalent) tablets or 80 mg/mL (400mg/5mL) oral suspension (available in 50 mL bottle)
            </li>
            <li>7-14 day duration is suggested for many after surgery. Some clinicians do not routinely discharge on oral antibiotic following surgery and hospitalization.</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Enrofloxacin</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 24 hours.  Give with food. This is an antibiotic.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          10 mg/kg = {(weightkg*(10)).toFixed(1)} mg
          </li>
          <li>
              Available most commonly as 68 mg and 136 mg chew tablets
          </li>
          <li>
          Feline dose for enrofloxacin is 5 mg/kg (pradofloxacin or marbofloxacin may be more appropriate oral fluoroquinolones)
          </li>
          <li>7-14 day duration is suggested for many after surgery. Some clinicians do not routinely discharge on oral antibiotic following surgery and hospitalization.</li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Gabapentin</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              10-20 mg/kg = {(weightkg*(10)/(1)).toFixed(1)} mg to {(weightkg*(20)/(1)).toFixed(1)} mg q8-12h
            </li>
            <li>
              Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
            </li>
            <li>
              Can round up dose and/or increase depending on patient response
            </li>
            </ul>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Buprenorphine</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give into the mouth onto the gums (transmucosal) every 6-12 hours.  This is an opioid pain medication. May cause dilated pupils, wobbly walking, odd behavior.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              0.015 mg/kg = {(weightkg*(0.015)/(0.3)).toFixed(2)} mL of 0.3 mg/mL Buprenorphine
            </li>
            <li>May be good choice for feline patients</li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>NSAIDs</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>Avoid NSAIDs if azotemia</li>
            <li>May be considered in select cases post-operatively once rehydrated</li>
            </ul>
            </Grid>



            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Informational Handouts:
            </Grid>
            <Grid item xs={12}>
              <a href="https://www.acvs.org/small-animal/pyometra" target="_blank" rel="noopener noreferrer">Pyometra</a> <span style={{fontWeight: "500"}}> from ACVS</span>
            </Grid>
            <Grid item xs={12}>
              <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951481" target="_blank" rel="noopener noreferrer">Pyometra in Dogs and Cats</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
            </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>

          <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};