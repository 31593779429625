import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import juvenileCellulitis from './skinLesionImages/JuvenileCellulitis.jpg'

export default function JuvenileCell() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Juvenile Cellulitis (Puppy Strangles)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Typically seen in puppies age 3-16 weeks.  Presents as painful, swollen face, lips, +/- ear pinnae progressing to papules and pustules.</Typography>
                  <Typography>Rule out pyoderma (although can be secondary), angioedema, demodicosis.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Immunosuppressive glucocorticoids (such as dexamethasone SP followed by prednisone) for 1-2 weeks then slowly taper.  Consider cyclosporine in combination with glucocorticoids in certain cases.
                  </li>
                  <li>
                  Antibiotics in cases of secondary Staphylococcal pyoderma. Consider cefalexin at 20-30 mg/kg q12h.
                  </li>
                  </ul>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Juvenile Cellulitis</span></Typography>
                  <img src={juvenileCellulitis} alt="Muzzle with pustules and papules in juvenile cellulitis case" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
