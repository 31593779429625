import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import DeepSS from './DeepSkinScraping';

import DemodexFace from './skinLesionImages/Demodicosis.jpg'

export default function Demodicosis() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Demodicosis (Demodex canis)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Often seen in young dogs with developing immune systems. Dorsal pedal alopecia, comodones/black heads, or periocular alopecia are cases in which you must do a deep skin scraping.</Typography>
                  <Typography>Rule out pyoderma (although can be secondary) and juvenile cellulitis (do the deep skin scraping).</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Diagnostics:</Typography>
                  <DeepSS />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Treat and prevent with isoxasoline (Simparica, Bravecto, Nexgard, Credelio) flea medication.
                  </li>
                  <li>
                  Antibiotics in cases of secondary Staphylococcal pyoderma. Consider cefalexin at 20-30 mg/kg q12h. Antimicrobial bathing is an alternative for some.
                  </li>
                  <ul>
                  <li>
                  Douxo Chlorhexidine PS + Climbazole Shampoo 200 ml OR VetriMax Command Dog Shampoo OR Dechra MiconaHex Triz Shampoo: Bath up to twice each week in cool to room temperature water. A small amount (palmful) can be diluted in one pint of water to extend use. Focus on problem areas and gently massage into the coat. Let sit for 5-10 minutes before rinsing as contact time kills skin yeast and bacteria.
                  </li>
                  <li>
                  Douxo (Sogeval) Chlorhexidine Mousse Dechra OR MiconaHex + Triz Spray OR Dechra TrizCHLOR 4 Wipes: Use between bathing to reduce yeast and bacterial overgrowth. Gently massage into the coat with the direction of fur and leave in (do not rinse).
                  </li>
                  </ul>
                  </ul>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Demodicosis</span></Typography>
                  <img src={DemodexFace} alt="Dog affected by Demodex with periocular alopecia" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
