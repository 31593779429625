import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Agasaca from './Agasaca';
import Osteosarcoma from './Osteosarcoma';
import LymphomaCanine from './LymphomaCanine';
import SplenicTumors from './SplenicTumors';
import SkinTumorsCanine from './SkinTumorsCanine';

import LymphomaFeline from './LymphomaFeline';
import MastCellTumorFeline from './MastCellTumorFeline';
import InjectionSiteSarcoma from './InjectionSiteSarcoma';
import SquamousCellCarcinoma from './SquamousCellCarcinoma';

export default function CommonCancers() {

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Cancers in Dogs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Agasaca />
          <LymphomaCanine />
          <Osteosarcoma />
          <SkinTumorsCanine />
          <SplenicTumors />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Cancers in Cats</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InjectionSiteSarcoma />
          <LymphomaFeline />
          <MastCellTumorFeline />
          <SquamousCellCarcinoma />
        </AccordionDetails>
      </Accordion>
      </>

  );
}
