import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CongestiveHeartFailure from '../Cardiology/HeartFailure';
import PericardialEffusion from '../Cardiology/PericardialEffusion';

import CanineAvulsion from '../Dentistry/CanineAvulsion';
import Fcgs from '../Dentistry/Fcgs';

import AnalSacAbscess from '../Dermatology/AnalSacAbscess';
import CatAbscess from '../Dermatology/CatAbscess';
import PyotraumaticDermatitis from '../Dermatology/PyotraumaticDermatitis';
import Wounds from './WoundsTraumatic';

import DiabetesMellitus from '../InternalMedicine/DiabetesMellitus';
import Hypoadrenocorticism from '../InternalMedicine/Hypoadrenocorticism';

import Ahds from '../InternalMedicine/AHDS';
import Constipation from '../InternalMedicine/ConstipationFeline';
import GastricDilationVolvulus from '../Surgery/GastricDilationVolvulus';
import GiForeignBody from '../Surgery/GiForeignBody';
import Parvovirus from '../InternalMedicine/Parvovirus';

import Hemoabdomen from './Hemoabdomen';

import HepaticLipidosis from '../InternalMedicine/HepaticLipidosis';
import Hepatopathy from '../InternalMedicine/Hepatopathy';

import Imha from '../InternalMedicine/IMHA';
import Itp from '../InternalMedicine/ITP';

import PancCanine from '../InternalMedicine/PancreatitisCanine';
import PancFeline from '../InternalMedicine/PancreatitisFeline';

import Ivdd from '../Neurology/IntervertebralDiscDisease';
import HeadTrauma from '../Neurology/HeadTrauma';
import Seizures from '../Neurology/Seizures';
import VestibularDz from '../Neurology/VestibularDz';

import CrClD from '../Musculoskeletal/Crcld';
import HipLuxation from '../Musculoskeletal/HipLuxation';
import Fractures from '../Musculoskeletal/FracturesForelimb';

import CornealUlcer from '../Ophthalmology/CornealUlcer';
import Glaucoma from '../Ophthalmology/Glaucoma';
import Proptosis from '../Ophthalmology/Proptosis';

import SplenicTumors from '../Oncology/SplenicTumors';
import LymphomaCanine from '../Oncology/LymphomaCanine';
import Osteosarcoma from '../Oncology/Osteosarcoma';

import Aki from '../InternalMedicine/AcuteKidneyInjury';
import Ckd from '../InternalMedicine/Ckd';
import Flutd from '../InternalMedicine/FLUTD';

import Dystocia from '../Reproduction/Dystocia';
import Pyometra from '../Reproduction/Pyometra';

import FelineAsthma from '../InternalMedicine/FelineAsthma';
import PleuralEffusion from './PleuralEffusion';
import PneumoniaBacterial from '../InternalMedicine/PneumoniaBacterial';
import UpperAirwayObs from './UpperAirwayObs';
import JuvenileCell from '../Dermatology/JuvenileCellulitis';
import JuvenileHypoglycemia from './JuvenileHypoglycemia';
import Urolithiasis from '../InternalMedicine/Urolithiasis';
import LensLux from '../Ophthalmology/LensLuxation';
import Uveitis from '../Ophthalmology/Uveitis';
import PerianalFistula from '../Dermatology/PerianalFistula';
import Horners from '../Neurology/Horners';
import ToothRootAbscess from '../Dentistry/ToothRootAbscess';
import BrokenClaw from '../Dermatology/BrokenClaw';
import MandibularFractures from '../Dentistry/MandibularFractures';
import AorticThromboembolism from './AorticThromboembolism';
import ArterialThrombosis from './ArterialThrombosis';
import ReverseSneeze from './ReverseSneeze';
import Pneumothorax from './Pneumothorax';
import Chylothorax from './Chylothorax';
import FlailChest from './FlailChest';
import BrachycephalicAirway from '../Surgery/BrachycephalicAirway';
import Prostatitis from '../InternalMedicine/Prostatitis';
import Uti from '../InternalMedicine/Uti';
import TrachealCollapse from '../InternalMedicine/TrachealCollapse';
import Golpp from '../InternalMedicine/Golpp';
import Larpar from '../InternalMedicine/Larpar';
import PerinealHernia from '../Surgery/PerinealHernia';
import UreteralObstruction from '../InternalMedicine/UreteralObstruction';
import Triaditis from '../InternalMedicine/Triaditis';
import Leptospirosis from '../InternalMedicine/Leptospirosis';
import Hyperaldosteronism from '../InternalMedicine/Hyperaldosteronism';

export default function EmergenciesBySystem() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Common Emergencies By System</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Cardiovascular</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AorticThromboembolism />
              <ArterialThrombosis />
              <CongestiveHeartFailure/>
              <PericardialEffusion/>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dental</Typography>
            </AccordionSummary>
            <AccordionDetails>
             <CanineAvulsion />
             <Fcgs />
             <MandibularFractures />
             <ToothRootAbscess />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Dermatologic</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AnalSacAbscess />
              <CatAbscess />
              <BrokenClaw />
              <JuvenileCell />
              <PerianalFistula />
              <PyotraumaticDermatitis/>
              <Wounds />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Endocrine</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DiabetesMellitus/>
              <Hyperaldosteronism />
              <Hypoadrenocorticism />
              <JuvenileHypoglycemia />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Gastrointestinal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Ahds />
              <Constipation />
              <GastricDilationVolvulus />
              <GiForeignBody />
              <Parvovirus />
              <PerinealHernia />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Hemolymphatic & Immune</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Hemoabdomen/>
              <Imha />
              <Itp />
              <LymphomaCanine />
              <SplenicTumors />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Hepatobiliary</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HepaticLipidosis />
              <Hepatopathy />
              <Leptospirosis />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Musculoskeletal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CrClD />
              <Fractures />
              <HipLuxation />
              <Osteosarcoma />
              <Wounds />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Pancreatic</Typography>
            </AccordionSummary>
            <AccordionDetails style={{padding: "0"}}>
              <PancCanine />
              <PancFeline />
              <Triaditis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Neurologic</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Golpp />
              <HeadTrauma/>
              <Horners />
              <Ivdd/>
              <Larpar />
              <Seizures/>
              <VestibularDz/>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Ophthalmologic</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CornealUlcer/>
              <Glaucoma />
              <LensLux />
              <Proptosis />
              <Uveitis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Renal & Urinary</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Aki />
              <Prostatitis />
              <Ckd />
              <Flutd />
              <Leptospirosis />
              <UreteralObstruction />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Urethral Obstruction</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Flutd />
                  <Urolithiasis />
                </AccordionDetails>
              </Accordion>
              <Uti />
              <Urolithiasis />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Reproductive</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Dystocia />
              <Pyometra />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Respiratory</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FelineAsthma />
              <BrachycephalicAirway />
              <Chylothorax />
              <FlailChest />
              <Golpp />
              <Larpar />
              <PleuralEffusion />
              <PneumoniaBacterial />
              <Pneumothorax />
              <ReverseSneeze />
              <TrachealCollapse />
              <UpperAirwayObs />
            </AccordionDetails>
          </Accordion>


        </AccordionDetails>
      </Accordion>


  );
}
