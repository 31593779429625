import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ChdRads from '../Radiology/ChdRads';

export default function HipDysplasia() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hip Dysplasia</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Hip dysplasia causes waxing and waning pain in young dogs due to stretch and strain on the soft tissues associated with a loose hip joint.  As these dogs age, instability leads to osteoarthritis and some dogs will have chronic osteoarthritic pain.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Physical examination may reveal pain on hip extension (differentiate from iliopsoas pain) and a normal sit test. Ortolani sign is suggestive (may be a normal finding in dogs &lt;6 months old).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Hip radiographs can confirm diagnosis and show degree of osteoarthritis. Hip radiographs usually require heavy sedation (which will also better allow evaluation of Ortolani sign). OFA-style radiographs may show joint incongruity and poor coverage and osteoarthritis. PennHIP radiographs can be used as early as 4 months of age and may allow predication of risk based on a Distraction Index (DI). Following PennHIP radiographs, Juvenile Pubic Symphysiodesis (JPS), a surgical procedure, can be offered before 20 weeks of age to improve coverage of the femoral head and prevent hip dysplasia.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          Many surgical techniques have been described with good outcomes and may be cheaper than medical management over time. JPS, TPO/DPO, FHO, and THR can all be considered based on the individual patient.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://petparenthandouts.com/hip-dysplasia" target="_blank" rel="noopener noreferrer">Hip Dysplasia Handout</a> from PetParentHandouts.com
        </Typography>

        <Typography>
        <a href="https://www.acvs.org/small-animal/canine-hip-dysplasia" target="_blank" rel="noopener noreferrer">Canine Hip Dysplasia Handout</a> from the American College of Veterinary Surgeons
        </Typography>
        </Grid>
        <ChdRads />
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
