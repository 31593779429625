import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Ohe() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Castration (Neuter)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Castration timing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                Breed-associated competing risks make this an individual decision that should be considered based on the patient. <a href="https://www.aaha.org/aaha-guidelines/life-stage-canine-2019/life-stage-canine-2019/" target="_blank" rel="noopener noreferrer">AAHA 2019 Canine Life Stage Guidelines</a> offer some guidance on <a href="https://www.aaha.org/aaha-guidelines/life-stage-canine-2019/spay-and-neuter-timing/" target="_blank" rel="noopener noreferrer">proposed ages for sterilization</a>. In general, for dogs expected to weigh less than 20kg when fully grown, neuter at 6 months old. For dogs that will weigh &gt;20kg, delaying neutering until after growth stops (9-15 months) may decrease the risk of some cancers and musculoskeletal problems.
              </Typography>
              <Typography>
                Hart et. al. published <a href="https://www.frontiersin.org/articles/10.3389/fvets.2020.00388/full" target="_blank" rel="noopener noreferrer">Assisting Decision-Making on Age of Neutering for 35 Breeds of Dogs: Associated Joint Disorders, Cancers, and Urinary Incontinence</a> in 2020 which offers breed-specific suggestions for neutering ages to avoid long-term health risks.
              </Typography>
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Castration procedure</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{fontWeight:"700"}}>Adult Canine Castration:</Typography>
              <ul>
                <li>
                  Make a midline incision just cranial to scrotum directly over a testicle that has been pushed cranially.
                </li>
                <li>
                Exteriorize the testicle which will be covered in parietal vaginal tunic.
                </li>
                <li>
                  Use a gauze square to strip away and pull apart the tail of the epididymis to create better exposure of the spermatic cord.
                </li>
                <li>Many prefer a closed castration technique which does not create an opening into the peritoneal cavity. This is most appropriate on patients that are &lt;20kg. The open technique does create an opening into the peritoneal cavity, but allows for more secure ligation of the testicular vessels.</li>
                <li>
                  For closed castration, clamp the spermatic cord and double ligate (often encircling proximally with transfixing closer to the clamp/testicle).
                </li>
                <li>
                  For open castration, cut away the parietal vaginal tunic over the testicle and open it to expose the cremaster muscle, pampiniform plexus, and ductus deferens. Double ligate or place a miller's knot around the cremaster/tunic and ductus/pampiniform. Cut away the testicle.
                </li>
                <li>Repeat for the other testicle</li>
                <li>Close the skin with 3-0 or 4-0 monocryl, placed intradermally.</li>
              </ul>
              <Typography sx={{fontWeight:"700"}}>Puppy Castration:</Typography>
              <ul>
                <li>Appropriate for young dogs where scrotal skin does not yet appear different from surrounding skin.</li>
                <li>Cut directly over one testicle off midline.</li>
                <li>Exteriorize the testicle, ligate the spermatic cord, and cut free the testicle.</li>
                <li>Push the second testicle to the first incision, cut through subcutaneous tissue, repeat as above.</li>
                <li>Skin sutures are often not necessary. Pinch the skin with a drop of surgical glue.</li>
              </ul>
              <Typography sx={{fontWeight:"700"}}>Cat Castration:</Typography>
              <ul>
                <li>Cut directly over testicle off midline.</li>
                <li>Exteriorize the testicle, use hemostats to tie the spermatic cord into a figure-eight knot.</li>
                <li>Make a second incision directly over the second testicle and repeat as above.</li>
                <li>Skin sutures are not necessary. Pinch the skin with a drop of surgical glue.</li>
              </ul>
            </Grid>
          </Grid>

          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Castration Complications</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
              Complications include hemorrhage, scrotal hematoma, and self-trauma. Tie good knots. Use open technique on big dogs. Do not use clippers or surgically prep mature dog scrotums (causes irritation and increases the chance of self-trauma).
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
