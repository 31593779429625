import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Agasaca from '../Oncology/Agasaca';

export default function AnalSacAbscess() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Anal Sac Abscess</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Consider underlying disease that may have contributed (allergy, endocrine disease, anal sac impaction, anal sac tumor)</Typography>
                  <Typography>Often presents as painful swelling in the region of the anal sac +/- fistula. Usually requires sedation to palpate/assess, which gives opportunity to clean/flush/infuse with antimicrobial ointment.  Use caution not to confuse with a perineal hernia (which can be differentiated on rectal exam by palpation if any concern for confusion).</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Cytology will show bacteria, yeast, inflammatory cells and can help differentiate from impaction without infection. Culture may be appropriate in some cases to best direct targeted antimicrobial therapy, but expect a mixed bag of anaerobes from the GI tract and aerobes from the skin.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography>In cases where a distinct mass is present, consider and rule out anal gland tumors such as AGASACA.</Typography>
                  <Agasaca />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Sedated anal sac flushing and infusion with Animax or similar antimicrobial ointment should be considered and may hasten resolution.
                  </li>
                  <li>
                  Empirical antibiotic selection may include amoxicillin/clavulanic acid (15mg/kg q12h) or a cephalosporin + clindamycin or metronidazole. Treat for 2 weeks beyond clinical resolution.
                  </li>
                  <li>
                  Reassess in 7-14 days to ensure progress towards resolution and repeat palpation to help reassess for anal sac mass (such as adenocarcinoma).
                  </li>
                  <li>
                  Surgical removal of the anal sacs should be considered if repeated problems or failure of medical management.
                  </li>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
