import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Enucleation from '../Ophthalmology/Enucleation';
import TempTarsorrhaphy from '../Ophthalmology/TempTarsorrhaphy';

export default function OphthProcedures() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Ophthalmic Procedures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Enucleation />
          <TempTarsorrhaphy />
        </AccordionDetails>
      </Accordion>

  );
}
