import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/toothRootAbscess.jpg';

export default function ToothRootAbscess() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Tooth Root Abscess</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    These dogs often present for facial swelling, reluctance to eat, or pain. Sometimes the abscess is open and draining on the outside of the muzzle, often just in front of the eye. These tooth root abscesses form when bacteria sets up shop and becomes trapped around the root of the tooth. Broken teeth can lead to tooth root abscesses, but sometimes the crown of the tooth shows no sign of disease. Tooth root abscesses may respond favorably to antibiotics, but require surgical extraction to permanently fix the problem.                    
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="Tooth Root Abscess" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This patient presented for a large firm swelling in front of the eye. Appropriate antibiotics and anti-inflammatory pain medications were started leading up to the dental procedure. Intraoral radiographs confirmed a tooth root abscess and the affected tooth was removed.</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
