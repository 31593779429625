import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function RootSignature() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Root Signature</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Root signature due to cervical disk herniation causing nerve root entrapment or irritation may present as forelimb lameness or a pet holding up a thoracic limb. It is associated with a C6-T2 localization.
        </Typography>
        <Typography>Methocarbamol may be helpful in cases of cervical pain with root signature.</Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
