import React from 'react';

import {Accordion, Grid, Paper } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Rd1 from './images/fcgs.jpg';
import Rd2 from './images/fcgs2.jpg';

export default function Fcgs() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Feline Chronic Gingivostomatitis</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Seems to be a multifactorial/complex disease but teeth/plaque/bacteria worsen the problem. FeLV and FIV testing are recommended. Successful treatment usually requires full mouth extractions (sometimes successful with just premolars and molars). Medical management may include pain drugs, antibiotics, anti-inflammatory steroids, and immune-modulating drugs (cyclosporine, interferon).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951821" target="_blank" rel="noopener noreferrer">Plasma Cell Stomatitis in Cats</a> from Veterinary Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd1} alt="Stomatitis in a cat" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>Raging inflammation!</Typography>
                  </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <img src={Rd2} alt="Stomatitis in a cat" style={{borderRadius:"2%", maxWidth: "96%"}}/>
                  <Typography>This hurts!</Typography>
                  </Paper>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
