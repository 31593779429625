import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Ckd() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Jaundice (icterus) in cats</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Prehepatic</TableCell>
                <TableCell align="center">Hepatic</TableCell>
                <TableCell align="center">Posthepatic</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">RBC destruction<br/>
                Onion/Garlic Toxicity<br/>
                Mycoplasmosis (Bartonella)<br/>
                Zinc toxicity<br/>
                IMHA
                </TableCell>
                <TableCell align="left">Hepatic lipidosis (secondary more common than idiopathic)<br/>
                Cholangitis/cholangiohepatitis<br/>
                Toxic hepatopathy<br/>
                Neoplastic<br/>
                FIP<br/>
                Drug-related<br/>
                </TableCell>
                <TableCell align="left">Extrahepatic bile duct obstruction (EHBO):<br/>
                Pancreatitis<br/>
                Cholecystitis<br/>
                Cholelithiasis<br/>
                Masses
                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
          </TableContainer>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
