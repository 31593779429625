import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Pneumonia() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Pneumonia (Bacterial)</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
          <Grid item xs={12}>
          <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Usually present with some degree of cough or abnormal breathing +/- fever, lethargy, inappetence. May or may not have known history of boarding/exposure, vomiting or regurgitation, or recent anesthesia. Consider myasthenia gravis, laryngeal paralysis, or hypoadrenocorticism in appropriate cases.</Typography>

          <Typography><span style={{fontWeight: '700'}}>Diagnostics:</span></Typography>
          <ul>
            <li>
            3-view thoracic radiographs
            </li>
            <li>
            CBC/Chemistry
            </li>
            <li>
            Consider SpO2 monitoring
            </li>
            <li>
            Consider sampling by endotracheal or transtracheal wash prior to starting antibiotics
            </li>
            <li>
            Consider Respiratory PCR panel in certain cases
            </li>
          </ul>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Decrease Stress"
            inputProps={{ 'aria-label': 'decrease stress' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen.</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          In select cases, consider butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.2)/10).toFixed(2)} mL to {(weightkg*(.4)/10).toFixed(2)}</span> of 10 mg/mL butorphanol IM for this patient. Butorphanol as an antitussive may be contraindicated as it will decrease mucociliary clearance.<sup>1</sup>
          </li>
          <li>
          In select cases, consider acepromazine at 0.025 mg/kg = {(weightkg*(.025)/10).toFixed(2)} mL of 10 mg/mL acepromazine IV or IM. 
          </li>
          <li>
          Place directly in oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in severely compromised patients.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>3-view chest radiographs</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Go at the pace of the patient to avoid respiratory arrest during initial diagnostics.
          </li>
          <li>
          tFAST may be a more appropriate early diagnostic tool for severely compromised patients that are oxygen dependent. tFAST can also help rule out pleural effusion.
          </li>
          <li>
          Provide O2 while in radiology if possible. Consider a DV in compromised patients (in place of a VD).
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place IV catheter and provide IV fluids</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider blood collection IV catheter placement
          </li>
          <li>
          Fluid therapy goals should be tailored to the individual patient.<sup>2</sup> Avoid fluid overloading and monitor patient weight.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Anesthesia"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>Start empirical antibiotics</span>
          </Grid>

        <Grid item xs={12}>
        <ul>
          <li>
            Remember to de-escalate based on culture and sensitivity testing (if performed).
          </li>
          <li>
           Antibiotics may not be needed in certain patients.<sup>3</sup>
          </li>
          <li>
           Think about the individual patient when making empirical antibiotic selection.
          </li>
          <li>
           Doxycycline should be considered for canine patients with first occurrence of suspect community acquired pneumonia such as Mycoplasma and many feline patients with previous or current history of URI.
          </li>
          <li>
           Ampicillin, Unasyn/Clavamox (Ampicillin-sulbactam), or Cefazolin may be appropriate treatments for simple cases with no recent history of antibiotic use and suspected to be secondary to an aspiration event.<sup>4</sup>
          </li>
          <li>
           If concern for sepsis or severe clinical signs, consider adding a fluoroquinolone (enrofloxacin) or clindamycin (to cover anaerobes).
          </li>
          <li>
            If recurrent or resistant, consider doxycyline, enrofloxacin, azithromycin, or amikacin.
          </li>
          <li>
            History of recent antibiotic administration (within the past 4 weeks) is associated with in vitro bacterial resistance.<sup>5</sup>
          </li>
          <li>
            More recent studies favor shorter duration of treatment.<sup>6, 7</sup> Consider 10-14 day treatment course for most cases with re-evaluation in 7-10 days if doing well. Some criticalists only recommend 7 day treatment duration (with the appropriate antibiotic).
          </li>
        </ul>
        </Grid>

        
        <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Anti-emetics and prokinetics for appropriate cases</span>
          </Grid>
          <Grid item xs={12}>
          <ul>
          <li>
          Consider maropitant at 1 mg/kg = {(weightkg*(1)/10).toFixed(2)} mL of 10 mg/mL maropitant IV.
          </li>
          <li>
          Consider metoclopramide in appropriate canine cases. Clear benefit has not been shown<sup>8, 9</sup>
          </li>
          </ul>
          </Grid>
          
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Nebulization and Chest Physiotherapy</span>
          </Grid>

          <Grid item xs={12}>
            <ul>
              <li>
              There is minimal evidence to support nebulization. It should be considered if well tolerated. Theoretically, you are depositing water on top of the gel layer which will not penetrate to the more aqueous layer of the respiratory secretions. Nebulization was not associated with survival or duration of hospitalization.<sup>10</sup>
              </li>
              <li>
              Coupage is a veterinary term and lacks evidence or rationale for use. Chest physiotherapy may be a better term (used in human literature) and may have some benefit when performed by trained staff. Two recent papers describe the technique and the 2021 paper supports its use in the first 48 hours of hospitalization.<sup>11, 12</sup>
              </li>
              <li>
              Nebulizing drugs is of limited benefit as size of aerosolized particles likely does not allow drug delivery. Furosemide nebulization may help decrease dyspnea (studied in people with COPD) but hasn't been shown to improve oxygenation.
              </li>
              <li>
                The mucolytic/antioxidant N-acetylcysteine (given IV) may be beneficial.<sup>13</sup>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Pull eyelids over the globe"
            inputProps={{ 'aria-label': 'pull eyelids over the globe' }}
            /><span style={{fontWeight: '700'}}>Pain medication</span>
          </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        Gabapentin may be the least controversial suggestion. 
        </li>
        <li>
        NSAIDs are not recommended for human cases of lower respiratory tract infections and may be contraindicated.
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />The use of bronchodilators is not currently recommended in human medicine and lacks evidence to support use in veterinary patients.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Antitussives may decrease mucus clearance and are contraindicated.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Steroids are not currently recommended for patients with bacterial pneumonia.
        </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Medications to consider:
          </Grid>

         
          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Clavamox</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 12 hours.  Give with food. This is an antibiotic.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          16 to 20 mg/kg = {(weightkg*(16)).toFixed(1)} mg to {(weightkg*(20)).toFixed(1)} mg
          </li>
          <li>
              Available most commonly as 62.5 mg, 125 mg, 250 mg, or 375 mg chew tablets or 62.5 mg/mL solution
            </li>
            <li>
              Human generic Augmentin may be significantly cheaper and is available as 500/125 mg (625 mg Clavamox equivalent) tablets or 80 mg/mL (400mg/5mL) oral suspension (available in 50 mL bottle)
            </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Enrofloxacin</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 24 hours.  Give with food. This is an antibiotic.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          10 mg/kg = {(weightkg*(10)).toFixed(1)} mg
          </li>
          <li>
              Available most commonly as 68 mg and 136 mg chew tablets
          </li>
          <li>
            Avoid using in young growing patients when alternatives make sense (enrofloxacin may affect articular cartilage).
          </li>
          <li>
          Feline dose for enrofloxacin is 5 mg/kg (pradofloxacin or marbofloxacin may be more appropriate oral fluoroquinolones)
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <span style={{textDecoration: 'underline'}}>Doxycyline</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            Give by mouth every 12 (or 24 if 10mg/kg) hours. Give with food. This is an antibiotic.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
          5 mg/kg = {(weightkg*(5)).toFixed(1)} mg
          </li>
          <li>
          10 mg/kg = {(weightkg*(10)).toFixed(1)} mg
          </li>
          <li>
            May cause esophageal stricture in cats. Liquid compounded medication is often best for cats and should be followed with water.
          </li>
          </ul>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
          </Grid>
          <Grid item xs={12}>
            <ul>
            <li>
             If doing well, recheck in 7-10 days. Repeat radiographs (x-ray images) may or may not be recommended and often lag behind development and resolution of disease.
            </li>
            <li>
             Recheck immediately if any worsening breathing difficulty (increased rate or effort), feeling worse or not better, or loss of appetite.
            </li>
            </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item xs={12}>
                References:
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <p><sup>1</sup>Dear, Jonathan. Bacterial Pneumonia in Dogs and Cats: An Update.  Vet Clin Small Anim 2020.</p>
                  <p><sup>2</sup>Rozanski et al. Fluid Therapy in Lung Disease. Vet Clin Small Anim 2017.</p>
                  <p><sup>3</sup>Cook et al. Successful management of aspiration pneumopathy without antimicrobial agents: 14 dogs (2014-2021). JSAP 2021.</p>

                  <p><sup>4</sup>Lappin et al. Antimicrobial use Guidelines for Treatment of Respiratory Tract Disease in Dogs and Cats: Antimicrobial Guidelines Working Group of the International Society for Companion Animal Infectious Diseases. JVIM 2017.</p>
                  <p><sup>5</sup>Proulx et al. In vitro bacterial isolate susceptibility to empirically selected antimicrobials in 111 dogs with bacterial pneumonia. JVECC 2014.</p>

                  <p><sup>6</sup>Wayne et al. Outcomes in dogs with uncomplicated, presumptive bacterial pneumonia treated with short or long course antibiotics. CVJ 2017.</p>
                  <p><sup>7</sup>Fernandes Rodrigues et al. Antimicrobial discontinuation in dogs with acute aspiration pneumonia based on clinical improvement and normalization of C-reactive protein concentration. JVIM 2022.</p>
                  <p><sup>8</sup>Wilson et al. Risk factors for the development of aspiration pneumonia after unilateral arytenoid lateralization in dogs with laryngeal paralysis: 232 cases (1987-2012). JAVMA 2016.</p>
                  <p><sup>9</sup>Milovancev et al. Effect of Metoclopramide on the Incidence of Early Postoperative Aspiration Pneumonia in Dogs with Acquired Idiopathic Laryngeal Paralysis. Vet Surg 2016.</p>
                  <p><sup>10</sup>Kogan et al. Etiology and clinical outcome in dogs with aspiration pneumonia: 88 cases (2004-2006). JAVMA 2008.</p>
                  <p><sup>11</sup>Pouzot-Nevoret et al. Evaluation of a new chest physiotherapy technique in dogs with airway fluid accumulation hospitalized in an intensive care unit. JVECC 2018.</p>
                  <p><sup>12</sup>Pouzot-Nevoret et al. Effectiveness of chest physiotherapy using passive slow expiratory techniques in dogs with airway fluid accumulation: A randomized controlled trial. JVIM 2021.</p>

                  <p><sup>13</sup>Zhang et al. N-acetylcysteine improves oxidative stress and inflammatory response in patients with community acquired pneumonia: A randomized controlled trial. Medicine 2018.</p>



                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>


          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
          <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
          </Grid>

          <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};