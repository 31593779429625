import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RetrobulbarBlock from '../Anesthesia/RetrobulbarBlock';

export default function Enucleation() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Enucleation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
            Enucleation is indicated in cases where the globe cannot be saved.
          </Typography>
          </Grid>

          <Grid item xs={12}>
            <ul>
            <li>
            Consider a retrobulbar block.
            </li>
            </ul>
            <RetrobulbarBlock />
            <ul>
            <li>
            Intraoperative orbital splash technique is an alternative if uncomfortable with the retrobulbar block.
            </li>
            <li>
            Be mindful of the oculocardiac reflex which can cause decreased heart rate with manipulation of the globe.  Consider premedication with atropine or glycopyrrolate.
            </li>
            <li>
            Too much tension on the optic nerve during removal can blind the other eye (especially in cats).
            </li>
            <li>
            For <span style={{fontWeight: "700"}}>transpalpebral enucleation</span>, suture the eyelids shut.
            </li>
            <li>
            Make your incisions 2-5mm from the eyelid margins of the upper and lower lids. Removing too much eyelid skin will make closure challenging.
            </li>
            <li>
            Transect the extraocular muscles at their insertions onto the sclera (not across muscle bellies).
            </li>
            <li>
            Cut the optic nerve and associated vessels. You may or may not clamp before cutting.
            </li>
            <li>
            To stop bleeding, pack the orbit with guaze and apply pressure for at least 5 minutes. Larger vessels may require repeat packing and pressure, clamping, or ligation. Gelfoam or Surgicel can be used if needed.
            </li>
            <li>
            Close the periorbital lid fascia using 3-0 or 4-0 PDS. The tissue on the orbital rim will not stretch across the orbit, so you must grab the periorbital lid fascia on the superficial aspect of the rim. You should be able to close this tissue over the orbit without any spanning suture or gaps.
            </li>
            <li>
            Close the subcutis and the skin.
            </li>
            </ul>
          </Grid>
        </Grid>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Post-operative Considerations</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ul>
              <li>
              Ice pack over the orbit during recovery from anesthesia.
              </li>
              <li>
              24-48 hours of analgesia is often sufficient (consider carprofen or other NSAID if not contraindicated)
              </li>
              <li>
              Consider an E-collar (some may not need it).
              </li>
              <li>
              Systemic antibiotics if concern for infection.
              </li>
              </ul>
            </Grid>
          </Grid>
          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
      </Accordion>

  );
}
