import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DermatophytesCanine() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Dermatophytosis (Ringworm)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <ul>
                  <li>
                  Dermatophytes can cause pruritus, alopecia, and secondary bacterial skin disease.  No one skin lesion is pathognomonic for dermatophytosis.
                  </li>
                  <li>
                  Microsporum canis is most common. Mycosporum gypseum (from soil) is less common but may be present in warm, humid regions.
                  </li>
                  <li>
                  Woods lamp evaluation may show fluorescence, but this is true for less than 50% of Micosporum canis strains.  Do not rely on Wood's lamp evaluation to rule out dermatophytosis.
                  </li>
                  <li>
                  Treatments:
                  </li>
                  <ul>
                  <li>
                  Itrafungol (itraconazole oral solution): Give 5-10 mg/kg once daily for 1 month then on alternative weeks for 3 treatment cycles or until two negative cultures.
                  </li>
                    <ul>
                    <li>
                    Ideally, CBC and liver/renal check prior to and monthly during treatment.
                    </li>
                    <li>
                    Ketoconazole is less effective than itraconazole and may have bad side effects.
                    </li>
                    </ul>
                  <li>
                  Shampoo containing an azole anti-fungal agent (miconazole, ketoconazole, fluconazole) should be used once each week 1 month beyond resolution.
                  </li>
                  <li>
                  Mousse, creams, and sprays can be used on localized lesions as an additional treatment, but will not be sufficient alone.
                  </li>
                  <li>
                  Consider clipping fur (especially if long).
                  </li>
                  <li>
                  Alternative (but stinky): Lime sulfur bathing twice weekly; treat for 1 month beyond complete resolution
                  </li>
                    <ul>
                    <li>
                    Towel bathing technique: get two plastic bins, use one for a sopping wet lime sulfur-soaked towel. Thorough wipe down of complete pet (including head/face). Transfer to other bin and use a dry towel to pat off excess.
                    </li>
                    </ul>
                  <li>
                  Environmental treatment is necessary and challenging.
                  </li>
                  </ul>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
