import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HemoabKeyPoints from '../Emergency/HemoabdomenKeyPoints';

import SplenectomyDiagram from './images/splenectomy.png';

export default function Splenectomy() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Splenectomy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Splenectomy is commonly performed to prevent or control bleeding associated with splenic masses/neoplasia, splenic torsion, or splenic trauma.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Key Points for Communication</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <HemoabKeyPoints />
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Diagnostics</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
              aFAST (Gallbladder wall edema should increase suspicion of anaphylaxis in the right patient)
            </li>
            <li>
              diagnostic abdominocentesis when free fluid is present
            </li>
            <li>
              3-view thoracic radiographs
            </li>
            <li>
              abdominal ultrasound (consider cost and availability)
            </li>
            <li>
              CBC, chemistry, PT/PTT, blood type
            </li>
            <li>
               ECG
             </li>
             <li>
               Blood pressure
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Preoperative Considerations</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Blood transfusion may be necessary in attempt to stabilize for surgery
            </li>
            <li>
            Consider autotransfusion before or during surgery if needed
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Splenectomy Procedure</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <ul>
                <li>
                Make a xiphoid to pubis incision to allow complete exploration of the abdomen.
                </li>
                <li>
                Remove falciform fat.
                </li>
                <li>
                Completely exteriorize the spleen.
                </li>
                <li>
                Cover the spleen with a moist lap sponge and perform a complete exploration of the abdomen. Carefully examine the liver and diaphragm.
                </li>
                <li>
                Ligate hilar vessels (near the spleen) using suture or a vessel stapling or sealing device.
                </li>
                  <ul>
                  <li>
                  Ligation of the splenic, left gastroepiploic, and short gastric arteries will not compromise blood flow to the stomach. The splenic artery must be ligated distal to the pancreatic branches, and, when done correctly, will not alter blood supply to the left limb of the pancreas.
                  </li>
                  <li>
                  If using suture at hilar vessels, an assistant can place hemostatic forceps along isolated vessels to save time.
                  </li>
                  </ul>
                <li>
                Many will take a liver biopsy if any concern for neoplasia even if no apparent liver nodules grossly.  Use the guillotine technique at the liver margin if no apparent abnormalities.
                </li>
                <li>
                Many will recommend prophylactic gastropexy.  There is an association with previous splenectomy and GDV.
                </li>
                <li>
                Lavage the abdomen.
                </li>
                <li>
                Close the abdomen.
                </li>
                <li>
                Submit the spleen for histopathology. Be suspicious of a hematoma diagnosis in large breed dogs; masses can be mostly hematoma with adjacent hemangiosarcoma which may result in incorrect diagnosis.
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <img src={SplenectomyDiagram} alt="splenic anatomy" style={{borderRadius:"8%", maxWidth: "96%"}}/>
              <Typography>Blue ellipses indicate ligation points for splenic, left gastroepiploic, and short gastric arteries.</Typography>
              </Paper>
            </Grid>
          </Grid>

          </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Postoperative Considerations</Typography>
            </AccordionSummary>
            <AccordionDetails>
          <ul>
            <li>
            Continuous ECG is best as ventricular tachycardia is a somewhat common complication. Treat ventricular tachyarrhythmias with lidocaine.
            </li>
            <li>
            Monitor serial PCV.
            </li>
          </ul>
          </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
