import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox, Switch, Accordion, AccordionSummary, AccordionDetails, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBack from '@mui/icons-material/ArrowBack';

export default function Pancreatitis() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  const [switchCat, setSwitchCat] =useState(false)
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  function handleSwitchChange(event) {
    setSwitchCat(event.target.checked)
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Pancreatitis (Acute)</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
        <Grid item xs={12}>
          <TextField
            id="standard-textarea"
            label="Name"
            multiline
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
          <Grid item>Canine</Grid>
          <Grid item><Switch checked={switchCat} onChange={handleSwitchChange} name="Cat" />
          </Grid>
          <Grid item>Feline</Grid>
        </Grid>
        
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          {switchCat ?
          <Typography>
            <span style={{fontWeight: '700'}}>Presentation: </span>Nonspecific signs include lethargy, partial or complete anorexia, vomiting, weight loss, diarrhea, and dyspnea.  Dehydration, icterus, hypothermia or fever may be appreciated on examination. Abdominal pain may or may not be recognized on examination.<sup>1</sup>
          </Typography>
          :
          <Typography>
            <span style={{fontWeight: '700'}}>Presentation: </span>Nonspecific signs include partial or complete anorexia, vomiting, lethargy, diarrhea.  Dehydration or fever may be appreciated on examination. Abdominal pain may or may not be recognized on examination.
          </Typography>
          }
          <Typography>
            <span style={{fontWeight: '700'}}>Key points for communication: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            {switchCat ? 
            <>
              <li>
              May be associated with other concurrent diseases, but &gt;95% are thought to be idiopathic<sup>1</sup>
              </li>
              <li>
              Most mild to moderate cases respond well to supportive care with fluid therapy, nutrition, anti-nausea medication, and pain medication
              </li>
              <li>
              Most need 1-3 days in the hospital
              </li>
            </>
            :
            <>
              <li>
              May be associated with other concurrent diseases (especially endocrine diseases or enteropathies), may be caused by a high-fat meal, or may not have an identifiable underlying cause
              </li>
              <li>
              Most mild to moderate cases respond well to supportive care with fluid therapy, nutrition, anti-nausea medication, and pain medication
              </li>
              <li>
              Most need 1-3 days in the hospital
              </li>
            </>
            }
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: '700'}}>Diagnostics:</span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            {switchCat ? 
            <>
              <li>
              Abdominal Radiographs - only helpful to rule out other disease, not sensitive or specific for pancreatitis
              </li>
              <li>
              Abdominal Ultrasound - in acute cases, the pancreas may be enlarged, have a hyperechoic surrounding mesentery, and focal effusion
              </li>
              <li>
              CBC - may see inflammatory leukogram
              </li>
              <li>
              Chemistry - may have elevated ALT, AST, T Bili, azotemia if dehydrated, hypoglycemia has been associated with a poor outcome, electrolyte disturbances; there is no association between hypertriglyceridemia and pancreatitis in cats
              </li>
              <li>
              Spec fPL - a positive indicates pancreatitis as probable, cannot be used to rule out pancreatitis
              </li>
              <li>
              SNAP fPL - if “normal”, consider other causes for clinical signs; “normal” result means unlikely to have pancreatitis, “abnormal” might have pancreatitis or a Spec fPL in equivocal range. Valuable tool to exclude or include pancreatitis in emergency setting.<sup>2</sup>
              </li>
              <li>
              Cytology - only if fluid accumulation
              </li>
              <li>
              Histopathology (rarely performed) is the gold-standard test
              </li>
            </>
            :
            <>
              <li>
              Abdominal Radiographs - only helpful to rule out other disease, not sensitive or specific for pancreatitis
              </li>
              <li>
              Abdominal Ultrasound - in acute cases, the pancreas may be hypoechoic, heterogenous, enlarged, have a hyperechoic surrounding mesentery (steatitis), and focal effusion; normal appearance does not rule out pancreatitis
              </li>
              <li>
              CBC - may see inflammatory leukogram; thrombocytopenia in severe cases with DIC
              </li>
              <li>
              Chemistry - may have elevated ALT, AST, T Bili, azotemia if dehydrated, hypoglycemia has been associated with a poor outcome, electrolyte disturbances
              </li>
              <li>
              Spec cPL - Useful in many clinical scenarios and more sensitive and specific compared to other serum markers for diagnosing histopathologic lesions of pancreatitis in dogs<sup>1</sup>
              </li>
              <li>
              SNAP cPL - if “normal”, consider other causes for clinical signs; “normal” result means unlikely to have pancreatitis, “abnormal” might have pancreatitis or a Spec cPL in equivocal range. Valuable tool to exclude or include pancreatitis in emergency setting.
              </li>
              <li>
              Cytology - only if fluid accumulation
              </li>
              <li>
              Histopathology (rarely performed) is the gold-standard test
              </li>
              <li>
              Clotting times (PT/PTT) if concern for DIC
              </li>
            </>
            }
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{fontWeight: '700'}}>
          Treatment considerations:
          </Typography>
        </Grid>
        
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Treat individual"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Treat inciting cause or concurrent disease based on individual patient</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Fluid rehydration"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Start fluid rehydration</span>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            {switchCat ?
            <li>
            See <Link to={'/calculators/feline-fluids'}>Feline Fluids Calculator</Link>
            </li>
            :
            <li>
            See <Link to={'/calculators/canine-fluids'}>Canine Fluids Calculator</Link>
            </li>
            }
        </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Antiemetics"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Antiemetics and gastrointestinal prokinetics</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Maropitant (Cerenia, 10 mg/mL for injection or tablets PO)</TableCell>
                  <TableCell align="left">Antiemetic, NK<sub>1</sub>R antagonist</TableCell>
                  {switchCat ?
                    <TableCell align="left">1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span> 10 mg/mL IV or SC q24h, or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span> PO</TableCell>
                  :
                    <TableCell align="left">1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span> 10 mg/mL IV or SC q24h, or 2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2)).toFixed(0)} mg</span> PO</TableCell>
                  }
                  <TableCell align="left">May also provide visceral analgesia<sup>3</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Ondansetron (2 mg/mL solution; 4 mg and 8 mg tablets available)</TableCell>
                  <TableCell align="left">Antiemetic, 5HT<sub>3</sub> antagonist</TableCell>
                  <TableCell align="left">0.3 mg/kg <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3/2)).toFixed(2)} mL</span> IV or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3)).toFixed(1)} mg</span> PO q8-12h</TableCell>
                  <TableCell align="left">Can use in conjunction with maropitant</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Metoclopramide (5 mg/mL solution; 5 mg and 10 mg tablets available)</TableCell>
                  <TableCell align="left">Prokinetic, dopamine antagonist at low doses</TableCell>
                  <TableCell align="left">2 mg/kg/day CRI = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2/5/24)).toFixed(2)} mL/hr</span><br/>0.2 - 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2/5)).toFixed(2)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5/5)).toFixed(2)} mL</span> SC<br/>or <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2)).toFixed(1)}</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO TID</TableCell>
                  <TableCell align="left">Some advise against as dopamine antagonism may decrease perfusion of the pancreas; weak anti-emetic; contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cisapride (compounded)</TableCell>
                  <TableCell align="left">Prokinetic, 5HT< sub>4</sub> agonist</TableCell>
                  <TableCell align="left">0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> PO q8-12h </TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Erythromycin</TableCell>
                  <TableCell align="left">Prokinetic</TableCell>
                  <TableCell align="left">0.5 to 1 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.5)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1)).toFixed(1)} mg</span></TableCell>
                  <TableCell align="left">Contraindications include obstruction or perforation</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Pain management"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Pain management</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Methadone (10 mg/mL)</TableCell>
                  <TableCell align="left">0.2 - 0.3 mg/kg IM or IV q4-8 hours = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.2/10)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.3/10)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Fentanyl (0.05 mg/mL)</TableCell>
                  <TableCell align="left">3-5 ug/kg bolus followed by 3-5 ug/kg/hr = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(3/50)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5/50)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Buprenorphine (0.3 mg/mL)</TableCell>
                  <TableCell align="left">0.02 mg/kg IV = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(0.02/0.3)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Maropitant<sup>3</sup> (10 mg/mL)</TableCell>
                  <TableCell align="left">1 mg/kg IV, SC q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(1/10)).toFixed(2)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Gabapentin</TableCell>
                  <TableCell align="left">10-20 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(10)).toFixed(0)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(20)).toFixed(0)} mg</span></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Appetite stimulants"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Appetite stimulants</span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                {switchCat ?
                <></>
                :
                <TableRow>
                  <TableCell align="left">Capromorelin (Entyce, 30 mg/mL)</TableCell>
                  <TableCell align="left">3 mg/kg PO q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(3)/30).toFixed(0)} mL</span></TableCell>
                </TableRow>
                }
                <TableRow>
                  <TableCell align="left">Mirtazapine</TableCell>
                  {switchCat ?
                  <TableCell align="left">¼ of a 7.5 mg tablet (1.875 mg) PO q24h; 5HT<sub>3</sub> antagonist (so may make ondansetron less effective); transdermal (Mirataz) is alternative to tablets</TableCell>
                  :
                  <TableCell align="left">1.875 mg for small dogs; 3.75 mg for medium dogs; 7.5 mg for large dogs PO q24h; 5HT<sub>3</sub> antagonist (so may make ondansetron less effective)</TableCell>
                  }
                </TableRow>
                {switchCat ?
                <>
                <TableRow>
                  <TableCell align="left">Capromorelin (Elura, 20 mg/mL)</TableCell>
                  <TableCell align="left">2 mg/kg PO q24h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(2)/20).toFixed(1)} mL</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Gabapentin<sup>4</sup></TableCell>
                  <TableCell align="left">5 mg/kg PO q8h = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(5)).toFixed(0)} mg</span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Cyproheptadine</TableCell>
                  <TableCell align="left">Not great for critically ill cats, tastes bad, can reverse adverse effects of mirtazapine; 1 to 4 mg/cat PO q24h</TableCell>
                </TableRow>
                </>
                :
                <></>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Checkbox
          color="primary"
          label="Nutritional support"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Nutritional support </span>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Feeding tube indicated if fail to respond to appetite stimulants within 48 hours or history of prolonged anorexia; NG tube {switchCat ? "or esophagostomy tube placed early may expedite recovery (once no longer vomiting)" : "placed early may expedite recovery (once no longer vomiting)"}
            </li>
          </ul>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">¼ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅓ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">½ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/2).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅔ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*2/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">¾ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*3/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">RER = 70 x (kg)^0.75</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))).toFixed(0)} kcal/day</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />ACVIM does not recommend gastroprotectants (such as pantoprazole, omeprazole, famotidine) for pancreatitis or nonerosive gastritis<sup>{switchCat ? "5" : "3"}</sup>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />FFP is not recommend in current consensus statements for humans; reserve for {switchCat ? "cats" : "dogs" } with coagulopathies
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Antibiotics are not recommended unless infection strongly suspected
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Corticosteroids are not {switchCat ? "used routinely, but may be beneficial with certain concurrent diseases (inflammatory enteropathy, sterile cholangitis)" : "recommended for routine use"}
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Cobalamin (B12) supplementation is not usually indicated for treatment of pancreatitis, but may be indicated if concurrent inflammatory bowel disease{switchCat ? <sup>6</sup> : "; if used, start after submission of sample to measure serum cobalamin (B12) and folate"}
        </Grid>
        {switchCat ?
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Pancreatic surgery - rarely indicated and most cats with severe pancreatitis are poor candidates; may be indicated if biliary or pancreatic duct obstruction
        </Grid>
        :
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Pancreatic surgery - rarely indicated; medical management may be a viable treatment option for some dogs with pancreatic fluid accumulation<sup>4</sup>
        </Grid>
        }
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        {switchCat ?
        <>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951457" target="_blank" rel="noopener noreferrer">Pancreatitis in Cats handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>
          <Grid item xs={12}>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102903&id=9754795" target="_blank" rel="noopener noreferrer">Triaditis in Cats handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
          </Grid>
        </>
        :
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952412" target="_blank" rel="noopener noreferrer">Pancreatitis in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        }
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              Reference:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                {switchCat ?
                <>
                <p><sup>1</sup>Forman et al. ACVIM consensus statement on pancreatitis in cats. JVIM 2021.</p>
                <p><sup>2</sup>Schnau&#223; et al. Diagnosis of feline pancreatitis with SNAP fPL and Spec fPL. JFMS 2019.</p>
                <p><sup>3</sup>Niyom et al. Effect of maropitant, a neurokinin-1 receptor antagonist, on the minimum alveolar concentration of sevoflurane during stimulation of the ovarian ligament in cats. Vet Anaesth Analg 2013.</p>
                <p><sup>4</sup>Fantinati et al. Appetite-stimulating effect of gabapentin vs mirtazapine in healthy cats post-ovariectomy. JFMS 2020.</p>
                <p><sup>5</sup>Marks et al. ACVIM consensus statement: Support for rational administration of gastrointestinal protectants to dogs and cats. JVIM 2018.</p>
                <p><sup>6</sup>Simpson. Pancreatitis and triaditis in cats: causes and treatments. JSAP 2015.</p>
                </>
                :
                <>
                <p><sup>1</sup>Trivedi et al. Sensitivity and Specificity of Canine Pancreas-Specific Lipase (cPL) and Other Markers for Pancreatitis in 70 Dogs with and without Histopathologic Evidence of Pancreatitis. JVIM 2011.</p>
                <p><sup>2</sup>Niyom et al. Effect of maropitant, a neurokinin-1 receptor antagonist, on the minimum alveolar concentration of sevoflurane during stimulation of the ovarian ligament in cats. Vet Anaesth Analg 2013.</p>
                <p><sup>3</sup>Marks et al. ACVIM consensus statement: Support for rational administration of gastrointestinal protectants to dogs and cats. JVIM 2018.</p>
                <p><sup>4</sup> Talbot et al. Medical and surgical management of pancreatic fluid accumulations in dogs: A retrospective study of 15 cases. JVIM 2022.</p>
                </>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        </Grid>
    </>
  )
};