import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Infraspinatus() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Infraspinatus Myopathy</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          Most common in larger working dogs. Acute shoulder lameness may turn into persistent non-painful forelimb lameness due to fibrosis of the infraspinatus.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/infraspinatus-muscle-contracture" target="_blank" rel="noopener noreferrer">Infraspinatus Muscle Contracture</a> from the American College of Veterinary Surgeons
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
