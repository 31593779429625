import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import minorProcedurePdf from './MinorProcedureAnesthesiaSheet.pdf';

export default function AnesthesiaChecklistAccord() {
    return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="dex-torb-content"
              id="dex-torb-header"
            >
              <Typography>Safety Checklist and Monitoring Form</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto">
                  <Button href="/clinical-notebook/anesthesia/checklist" variant="outlined">Anesthesia Checklist</Button>
                </Grid>
                <Grid item xs="auto">
                  <Button href={minorProcedurePdf} variant="outlined" target="_blank" rel="noreferrer">Minor Procedure Monitoring Sheet <PictureAsPdfIcon /></Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
    );
  }
