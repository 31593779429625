import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/life-stage-canine-2019/life-stage-canine-2019/" target="_blank" rel="noopener noreferrer">AAHA 2019 Canine Life Stage Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/life-stage-feline-2021/feline-life-stage-home/" target="_blank" rel="noopener noreferrer">2021 AAHA/AAFP Feline Life Stage Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/2022-aaha-canine-vaccination-guidelines/home/" target="_blank" rel="noopener noreferrer">2022 AAHA Canine Vaccination Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.aaha.org/aaha-guidelines/2020-aahaaafp-feline-vaccination-guidelines/feline-vaccination-home/" target="_blank" rel="noopener noreferrer">2020 AAHA/AAFP Feline Vaccination Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://wsava.org/global-guidelines/vaccination-guidelines/" target="_blank" rel="noopener noreferrer">WSAVA Vaccination Guidelines</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://www.heartwormsociety.org/" target="_blank" rel="noopener noreferrer">American Heartworm Society</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://capcvet.org/guidelines/" target="_blank" rel="noopener noreferrer">Companion Animal Parasite Council (CAPC)</a>
          </Typography>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
