import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DexTorbCalculator from '../../Calculators/DexTorbCalculator';

export default function DexTorb() {
  return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="dex-torb-content"
            id="dex-torb-header"
          >
            <Typography>Dexmedetomidine/Butorphanol</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DexTorbCalculator/>
          </AccordionDetails>
        </Accordion>
  );
}
