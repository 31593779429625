import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Stack, TextField } from '@mui/material';



const searchLabelsPathsTerms = [
  ["Addisonian Crisis", "/quick-sheets/addisonian-crisis", "addisonian crisis addison's disease hypoadrenocorticism"],
  ["Acute Hemorrhagic Diarrhea Syndrome", "/quick-sheets/ahds", "acute hemorrhagic diarrhea syndrome ahds hge hemorrhagic gastroenteritis"],
  ["Acute Kidney Injury (AKI)", "/quick-sheets/aki", "acute kidney injury acute renal failure aki arf"],
  ["Anaphylaxis & Hypersensitivity Reactions", "/quick-sheets/anaphylaxis", "anaphylaxis allergic reaction type 1 hypersensitivity reaction insect bite vaccine reaction"],
  ["Blocked Cat (Feline Urethral Obstruction)", "/quick-sheets/blocked-cat", "blocked cat urinary tract feline urethral obstruction FLUTD"],
  ["Blunt Trauma (Hit by Car)", "/quick-sheets/blunt-trauma", "hit by car blunt trauma"],
  ["Canine Parvovirus (CPV)", "/quick-sheets/parvo", "Canine Parvovirus CPV"],
  ["Cardiopulmonary Resuscitation (CPR)", "/quick-sheets/cpr", "cpr cardiopulmonary resuscitation rescue"],
  ["Cat Bite Abscess", "/quick-sheets/abscess", "cat bite abscess wound"],
  ["Congestive Heart Failure (CHF)", "/quick-sheets/chf", "chf congestive heart failure"],
  ["Constipation (Feline)", "/quick-sheets/constipation", "constipation (feline) obstipation"],
  ["Corneal Ulcer", "/quick-sheets/corneal-ulcer", "corneal ulcer eye"],
  ["Diabetic Ketoacidosis (DKA)", "/quick-sheets/dka", "dka diabetes diabetic ketoacidosis"],
  ["Dystocia & C-Section", "/quick-sheets/dystocia", "dystocia c-section c section caesarian"],
  ["Gastric Dilation and Volvulus (GDV)", "/quick-sheets/gdv", "gdv gastric dilation volvulus"],
  ["Glaucoma", "/quick-sheets/glaucoma", "glaucoma eye"],
  ["Induction of Emesis", "/quick-sheets/emesis", "emesis induction of vomiting toxin"],
  ["Head Trauma", "/quick-sheets/head-trauma", "head trauma mgcs modified glascow coma scale"],
  ["Heat Stroke", "/quick-sheets/heat-stroke", "heat stroke"],
  ["Hemoabdomen", "/quick-sheets/hemoabdomen", "hemoabdomen hemoperitoneum hemangiosarcoma splenic mass blood ascites"],
  ["Hepatic Lipidosis", "/quick-sheets/hepatic-lipidosis", "hepatic lipidosis fatty liver fhl"],
  ["Immune Mediated Hemolytic Anemia (IMHA)", "/quick-sheets/imha", "immune mediated hemolytic anemia imha"],
  ["Immune Mediated Thrombocytopenia (ITP)", "/quick-sheets/itp", "immune mediated thrombocytopenia itp imt"],
  ["Intervertebral Disc Disease (IVDD)", "/quick-sheets/ivdd", "intervertebral disk disease intervertebral disc disease ivdd neck pain back pain"],
  ["Juvenile Hypoglycemia", "/quick-sheets/juvenile-hypoglycemia", "juvenile hypoglycemia"],
  ["Pancreatitis (Acute)", "/quick-sheets/pancreatitis", "pancreatitis"],
  ["Pericardial Effusion", "/quick-sheets/pericardial-effusion", "pe pericardial effusion"],
  ["Pneumonia", "/quick-sheets/pneumonia", "pneumonia"],
  ["Pleural Effusion & Thoracocentesis", "/quick-sheets/pleural-effusion", "pleural effusion fluid thoracocentesis"],
  ["Proptosis", "/quick-sheets/proptosis", "proptosis eye popped out"],
  ["Pyometra", "/quick-sheets/pyometra", "pyometra ceh cystic endometrial hyperplasia"],
  ["Respiratory Distress", "/quick-sheets/respiratory-distress", "respiratory distress oxygen dyspnea hyperpnea tachypnea"],
  ["Seizures & Status Epilepticus", "/quick-sheets/seizures", "seizures status epilepticus"],
  ["Snake Bite (Pit Viper Envenomation)", "/quick-sheets/snake-bite", "snake bite pit viper envenomation antivenin"],
  ["Upper Airway Obstruction", "/quick-sheets/upper-airway-obstruction", "upper airway obstruction brachycephalic tracheal collapse laryngeal paralysis"],
  ["Vomiting and Diarrhea", "/quick-sheets/vomiting-diarrhea", "acute vomiting and diarrhea"],
  ["Vestibular Disease", "/quick-sheets/vestibular-disease", "vestibular disease head tilt neurologic"],
  ["Wounds & Lacerations (Canine)", "/quick-sheets/wounds", "wound laceration attacked by dog bite abd"],

]

class QuickSheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      filteredRoutes: searchLabelsPathsTerms
    }
  }

  filterRoutes = (q) => {
    let filtered = searchLabelsPathsTerms.filter(r => r[2].toLowerCase().includes(q.toLowerCase()));
    this.setState({
      filteredRoutes: filtered
    })
  }

  termSearch = (e) => {
    this.setState({
      query: e.target.value
    })
    this.filterRoutes(e.target.value)
  }
  render() {
    return (
      <div>
      <Stack spacing={2} sx={{alignItems: "center", pb: '20px'}}>
          <TextField
            onChange={ this.termSearch }
            type='text'
            label="Search"
            role='form'
            aria-labelledby='search'
            tabIndex="0"
            value={ this.state.query }
            inputProps={{style: { textAlign: 'center' }}}
            sx={{minWidth: '360px'}}
          />
          {this.state.filteredRoutes.map( r =>
            <Button 
              component={Link} 
              variant="outlined" 
              target="" 
              rel="noopener noreferrer" 
              to={r[1]} key={r[1]} 
              sx={{textAlign: 'center', margin: 'auto', textTransform: 'none', color: 'inherit', fontFamily: 'outfit', minWidth: '360px', padding: '10px 0'}}>
              {r[0]}
            </Button>
          )}
          </Stack>
      </div>
    );
  }
}

export default QuickSheets;
