import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AsepticNecrosis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Aseptic Necrosis of the Femoral Head</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
          The blood supply to the femoral head is disrupted (cause unknown, usually young small-breed dogs). Physical examination usually reveals hip pain. Radiographs confirm the diagnosis. Pain medications and strict rest may help, but most will require surgical correction (usually FHO). In most cases, only one hip is affected.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952619" target="_blank" rel="noopener noreferrer">Legg-Perthes Disease in Dogs</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
