import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

import interdigitalTape from './skinCytologyImages/interdigitalTape.gif';
import inguinalTape from './skinCytologyImages/inguinalTape.gif';
import tapeYeast1 from './skinCytologyImages/tapeYeast1.jpg';
import tapeYeast2 from './skinCytologyImages/tapeYeast2.jpg';

export default function AcetateTape() {

  return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Acetate Tape Cytology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                This technique is the mainstay of looking for yeast in body folds. It will also pick up cocci, but these should not be over-interpreted as they are often confused with the acetate granules of the tape itself.
                </Grid>
                <Grid item xs={12}>
                Tape cytology is especially useful for interdigital spaces (between paw pads), lip folds, and axillary and inguinal regions (armpits and groin). These areas are often relatively dry and waxy, and the tape will pick up yeast (Malassezia) and cocci.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>1. Wrap a piece of acetate tape over your finger.  Keep the sticky side out and use it to sample around the patient.</span> Multiple sites (often armpits and groin) or interdigital spaces (get between the toes on several feet) can be sampled with the same piece of tape.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Interdigital Tape</span></Typography>
                  <img src={interdigitalTape} alt="interdigital tape" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Inguinal Tape</span></Typography>
                  <img src={inguinalTape} alt="inguinal tape" style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <Typography>Inguinal and axillary regions are classic for yeast overgrowth.</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>2. Press the tape onto the glass slide</span>. This bit can take some practice.  If the tape still has good stick and you press too hard, it will not take up purple well.  If the tape has almost no stick left you may need to push it on there with maximum force so it doesn't fall off in the stain.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>3. Stain the slide in the purple step only</span>. That's right; skip the first two steps of Diff-Quik and go straight for the purple.  Let it sit in there for a couple of minutes, rinse it off, and press out the excess stain between a couple of paper towels.  The tape might be a bit wavy, and that's okay.  If needed, a drop of oil under the tape can help smooth it out.
                </Grid>
                <Grid item xs={12}>
                <span style={{fontWeight: "700"}}>4. Evaluate the slide under the microscope</span>.  The 10x lens (100x magnification) can be used to find an area rich with purple stuff. Find a good spot, add a drop of immersion oil over the tape, and go to 100x. You may have to zoom in and out frequently to stay at the level of the collected sample. The fine acetate granules let you know you're in the right plane.
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span></Typography>
                <img src={tapeYeast1} alt="tape yeast 1" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <Typography><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span></Typography>
                <img src={tapeYeast2} alt="tape yeast 2" style={{borderRadius:"50%", maxWidth: "96%"}}/>
                </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

  );
}
