import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';


import GBmucocelegif from './images/GBmucocele.gif';
import GBmucoceleimg from './images/GBmucocele.jpg';

export default function GBmuco() {

  return (

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
          <Typography>
          Gallbladder mucocele
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={GBmucocelegif} alt="Gallbladder mucocele" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={GBmucoceleimg} alt="Gallbladder mucocele" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>

        </Grid>


  );
}
