import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class HeadTraumaQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      motor: '',
      brain: '',
      loc: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.handleMotorChange = this.handleMotorChange.bind(this);
    this.handleBrainChange = this.handleBrainChange.bind(this);
    this.handleLocChange = this.handleLocChange.bind(this)

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  handleBrainChange(event) {
  this.setState({
    brain: event.target.value
  })
}
handleLocChange(event) {
  this.setState({
    loc: event.target.value
  })
}  handleMotorChange(event) {
    this.setState({
      motor: event.target.value
    })
  }

  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Head Trauma</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
         <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={12}>
         <TextField
                  id="weightlbs"
                  type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                         id="weightkg"
                         type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                         label="Weight (kg)"
                         margin="normal"
                         variant="outlined"
                         InputProps={{
                           endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                         }}
                       />
                </Grid>

              </Grid>

        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Presentation:</span> history of trauma, possible skull fractures, hypotensive shock, altered mental status, seizures, Cushing reflex (hypertension and bradycardia may indicate herniation of brain)
        </Grid>

        <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Differential diagnoses:</span> hypoglycemia, liver disease, hypovolemic shock, brain mass, encephalitis, hydrocephalus
        </Grid>

        <Grid item xs={12}>
          <span style={{textDecoration: 'underline'}}>Diagnostics:</span> MGCS, blood glucose, blood pressure, point of care blood test with electrolytes (monitor for sodium derangements)
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Modified Glascow Coma Scale
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>



                                  <Grid item xs={12}>
                                  <FormControl fullWidth>

                                    <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Motor Activity</InputLabel>

                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.motor}
                                    label="Motor Activity"
                                    variant="outlined"

                                    onChange={this.handleMotorChange}
                                  >
                                    <MenuItem style={{overflow: 'auto'}} value={6}>6) Normal gait, normal spinal reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={5}>5) Hemiparesis, tetraparesis, or decerebrate activity</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={4}>4) Recumbent, intermittent extensor rigidity</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={3}>3) recumbent, constant extensor rigidity</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={2}>2) Recoment, constant extensor rigidity with opisthotonus</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={1}>1) Recumbent, hypotonia of muscles, depressed or absent spinal reflexes</MenuItem>
                                  </Select>
                                  </FormControl>
                                  <FormControl fullWidth>

                                    <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Brain Stem Reflexes</InputLabel>

                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.brain}
                                    label="Brain Stem Reflexes"
                                    variant="outlined"

                                    onChange={this.handleBrainChange}
                                  >
                                    <MenuItem style={{overflow: 'auto'}} value={6}>6) Normal PLRs and oculocephalic reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={5}>5) Slow PLRs and normal to reduce oculocephalic reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={4}>4) Bilateral unresponsive miosis with normal to reduced oculocephalic reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={3}>3) Pinpoint pupils with reduced to absent oculocephalic reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={2}>2) Unilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={1}>1) Bilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</MenuItem>
                                  </Select>
                                  </FormControl>
                                  <FormControl fullWidth>

                                    <InputLabel id="demo-simple-select-label" style={{marginLeft: '4px'}}>Level of Consciousness</InputLabel>

                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.loc}
                                    label="Level of Consciousness"
                                    variant="outlined"

                                    onChange={this.handleLocChange}
                                  >
                                    <MenuItem style={{overflow: 'auto'}} value={6}>6) Occasional periods of alertness and responsive to environment</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={5}>5) Depression or delirium, capable of responding but response may be inappropriate</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={4}>4) Semicomatose, responsive to visual stimuli</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={3}>3) Semicomatose, responsive to auditory stimuli</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={2}>2) Semicomatose, responsive only to repeated noxious stimuli</MenuItem>
                                    <MenuItem style={{overflow: 'auto'}} value={1}>1) Comatose, unresponsive to repeated noxious stimuli</MenuItem>
                                  </Select>
                                  </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                  Score: <span style={{fontWeight: '700'}}>{(this.state.motor ? this.state.motor : 0) + (this.state.loc ? this.state.loc : 0) + (this.state.brain ? this.state.brain : 0) ? (this.state.motor ? this.state.motor : 0) + (this.state.loc ? this.state.loc : 0) + (this.state.brain ? this.state.brain : 0) : ''}</span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    A score of 3-8 suggests a grave prognosis; 9-14 suggests a guarded prognosis; 15-18 suggests a good prognosis.
                                  </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>


        <Grid item xs={12}>
          <span style={{fontWeight: '700'}}>Goal is to maintain perfusion and decrease secondary effects</span>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider mannitol</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Give 0.5 to 1 g/kg = {(this.state.weightkg*(0.5)).toFixed(2)} g to {(this.state.weightkg*(1.5)).toFixed(2)} g = {(this.state.weightkg*(0.5)/(0.2)).toFixed(2)} mL to {(this.state.weightkg*(1)/(0.2)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>20%</span> (200 mg/mL) Mannitol
        </li>
        <li>
        Administer slowly over 10-30 minutes throuigh a filter needle.
        </li>
        <li>
        Do not give if renal or heart failure.
        </li>
        <li>
        Can repeat every 30 minutes to 1 hour up to three times, but less likely to see improvement if no improvement after initial dose
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider hypertonic saline (HTS)</span> as alternative to mannitol
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Give 5-7 mL/kg = {(this.state.weightkg*(5)).toFixed(2)} mL to {(this.state.weightkg*(7)).toFixed(2)} mL of <span style={{backgroundColor: '#ff726f'}}>7.5%</span> NaCl over 5-10 minutes
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Follow mannitol or HTS with balance crystalloid</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Maintenance rate (exponential formula) = {((this.state.weightkg**(0.75))*70/24).toFixed(0)} mL/hr
        </li>
        <li>
        2x maintenance rate = {((this.state.weightkg**(0.75))*70/12).toFixed(0)} mL/hr
        </li>
        <li>
        Consider 2x maintenance rate for at least 2 hours following mannitol or HTS (diuretics)
        </li>
        <li>
        Avoid low sodium fluids which may increase intracranial pressure
        </li>
        <li>
        0.9% NaCl may be a good option
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Elevate head 30 degrees</span>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider oxygen supplementation</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Flow by without mask or oxygen cage are often the best method of delivery
        </li>
        <li>
        Some patients may require ventilation
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider maropitant (Cerenia)</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        1 mg/kg = {(this.state.weightkg*(1)).toFixed(2)} mg = {(this.state.weightkg*(1)/10).toFixed(2)} mL of 10 mg/mL maropitant
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          />In event of seizure, <span style={{fontWeight: '700'}}>Give 0.25 mg/kg midazolam (5 mg/mL) = {(this.state.weightkg*(0.25)/5).toFixed(2)} mL IV</span>
        </Grid>
        <Grid item xs={12}>
        <ul>
        <li>
        If no IV catheter, give IM or IN (intranasally)
        </li>
        <li>
        Repeat dose in 1 minute if still seizing
        </li>
        <li>
        Patients on phenobarbital may need 2 mg/kg = {(this.state.weightkg*(2)/5).toFixed(2)} mL midazolam (5 mg/mL)
        </li>
        <li>
        Diazepam is an alternative; it is light sensitive, binds to plastic, and should NOT be given IM
        </li>
        <li>
          <Link className="" to="/quick-sheets/seizures">Seizures Clinical Quick Sheet</Link> should be referenced for additional treatments
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider fentanyl for analgesia</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        2-6 mcg/kg/hr after initial bolus = {(this.state.weightkg*(2)).toFixed(2)} mcg to {(this.state.weightkg*(6)).toFixed(2)} mcg = {(this.state.weightkg*(2)/50).toFixed(2)} mL to  {(this.state.weightkg*(6)/50).toFixed(2)} mL of 50 mcg/mL fentanyl
        </li>
        <li>
        Blood brain barrier may be compromised and fentanyl may cause further depression making assessment more difficult
        </li>
        <li>
        Buprenorphine is preferred by some, but is less easily reversed.  Butorphanol is unlikely to provide adequate analgesia and has a short half-life.
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider GI protectants</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Pantoprazole at 1 mg/kg = {(this.state.weightkg*(1)).toFixed(2)} mg = {(this.state.weightkg*(1)/4).toFixed(2)} mL of 4 mg/mL pantoprazole IV every 12 hours
        </li>
        <li>
        Omeprazole at 1 mg/kg = {(this.state.weightkg*(1)).toFixed(2)} mg = {(this.state.weightkg*(1)/20).toFixed(1)} tablet(s) of 20 mg omeprazole PO every 12 hours
        </li>
        <li>
        Sucralfate at 1/4 to 1 g PO every 6-8 hours
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider prokinetics</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Metoclopramide at 2 mg/kg/day = {(this.state.weightkg*(2)).toFixed(2)} mg/day = {((this.state.weightkg**(0.25))*20/7).toFixed(2)} mL of 5 mg/mL metoclopramide to a 1000 mL bag at 2x maintenance rate of {((this.state.weightkg**(0.75))*70/12).toFixed(0)} mL/hr
        </li>
        <li>
        Erythromycin at 0.5 mg/kg = {(this.state.weightkg*(0.5)).toFixed(2)} mg PO every 8 hours
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Early enteral nutrition may require placement of a feeding tube</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Coughing or gagging associated with nasogastric tube placement may increase ICP.
        </li>
        </ul>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Steroids are contraindicated. Do NOT give steroids.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Avoid anything that may increase intracranial pressure (ICP).  This includes nasal cannulas, restraint for jugular venipuncture, and neck wraps.
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          /><span style={{fontWeight: '700'}}>Consider CT</span>
        </Grid>

        <Grid item xs={12}>
        <ul>
        <li>
        Surgery is rarely indicated
        </li>
        <li>
        Craniotomy or Decompressive craniectomy may be appropriate for some cases
        </li>
        </ul>
        </Grid>


        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Discharge Note (Key Points):
        </Grid>
        <Grid item xs={12}>
          <ul>
          <li>
            In patients with traumatic brain injury, seizure disorders may develop months to years later.  Bring this event to the attention of any veterinarian who is treating your pet for a seizure disorder in the future.
          </li>
          <li>
            Diabetes Insipidus (DI) is an uncommon complication of traumatic brain injury that may cause your pet to become increasingly thirsty or urinate more frequently.  Please monitor for increased thirst and urination.
          </li>
          <li>
            Blood clotting disorder, pneumonia, and sepsis are some of the potential serious complications that may result from this traumatic brain injury.  Recheck promptly if not eating, vomiting, lethargy, depression, weakness, or any difficulty breathing.
          </li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              References:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup> Platt et al. The Prognostic Value of the Modified Glasgow Coma Scale in Head Trauma in Dogs. JVIM 2001.</p>

                <p><sup>2</sup> Cameron et al. The prognostic value of admission point-of-care testing and modified Glasgow Coma Scale score in dogs and cats with traumatic brain injuries (2007-2010): 212 cases. JVECC 2019.</p>

                <p><sup>3</sup> Sande et al. Traumatic brain injury: a review of pathophysiology and management. JVECC 2010.</p>


              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <p>
            This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
          </p>
          <p>
            All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
          </p>
        </Grid>



            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default HeadTraumaQS;
