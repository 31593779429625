import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import HeadTrauma from './HeadTrauma';
import IntervertebralDiscDisease from './IntervertebralDiscDisease';
import Seizures from './Seizures';
import VestibularDz from './VestibularDz';
import Myasthenia from './Myasthenia';
import MasticatoryMuscleMyositis from './MasticatoryMuscleMyositis';
import AtlantoaxialInstability from './AtlantoaxialInstability';
import Wobbler from './Wobbler';
import BrachialPlexus from './BrachialPlexus';
import Horners from './Horners';
import NerveSheathTumor from '../Oncology/NerveSheathTumor';
import DiabeticNeuropathy from '../InternalMedicine/DiabeticNeuropathy';
import Hypernatremia from '../Emergency/Hypernatremia';
import Golpp from '../InternalMedicine/Golpp';
import Larpar from '../InternalMedicine/Larpar';

export default function ByLocalization() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Diseases By Localization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Forebrain, Cerebellum, Brainstem</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>Forebrain:</span> altered mentation, behavior, seizures, circling, head pressing</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>Cerebellum:</span> lack inhibition (intention tremors), cerebellar ataxia, wide based stance with trunk sway</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>Brainstem:</span> cranial nerves (CN 3 provides parasympathetic tone to the eye, so herniation of brainstem leads to dilation of pupils), may show proprioceptive or vestibular ataxia, decreased respiratory rate or abnormal pattern, mentation may be affected (comatose, stuporous), vascular events love the brainstem</Typography>
              <HeadTrauma/>
              <Horners />
              <Hypernatremia />
              <Seizures/>
              <VestibularDz />

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>C1 - C5</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>Expect <span style={{fontWeight: "700"}}>UMN forelimb</span> signs and <span style={{fontWeight: "700"}}>UMN rear limb</span> signs</Typography>
              <AtlantoaxialInstability />
              <Horners />
              <IntervertebralDiscDisease />
              <Wobbler />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>C6 - T2</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography gutterBottom>Expect <span style={{fontWeight: "700"}}>LMN forelimb</span> signs and <span style={{fontWeight: "700"}}>UMN rear limb</span> signs</Typography>
              <Horners />
              <IntervertebralDiscDisease />
              <NerveSheathTumor />
              <Wobbler />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>T3 - L3</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography gutterBottom>Expect <span style={{fontWeight: "700"}}>normal forelimbs</span> and <span style={{fontWeight: "700"}}>UMN rear limb</span> signs</Typography>
              <IntervertebralDiscDisease />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>L4 - S3</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography gutterBottom>Expect <span style={{fontWeight: "700"}}>normal forelimbs</span> and <span style={{fontWeight: "700"}}>LMN rear limb</span> signs</Typography>
              <IntervertebralDiscDisease />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Generalized Neuromuscular Diseases</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography gutterBottom>Expect <span style={{fontWeight: "700"}}>flaccid paresis to paralysis</span> and <span style={{fontWeight: "700"}}>generalized hyporeflexia</span></Typography>
              <DiabeticNeuropathy />
              <Golpp />
              <Larpar />
              <Myasthenia />

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Focal Lower Motor Neuron Diseases</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BrachialPlexus />
              <MasticatoryMuscleMyositis />
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

  );
}
