import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import OrthoExam from './OrthoExam';
import Forelimb from './Forelimb';
import Hindlimb from './Hindlimb';
import BackNeck from './BackNeck';
import AdditionalResources from './AdditionalResources';
import CommonDrugs from './CommonDrugs';
import SystemicDzLameness from './SystemicDzLameness';
import Osteoarthritis from './Osteoarthritis';

export default function Dentistry() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <OrthoExam />
        <Forelimb />
        <Hindlimb />
        <BackNeck />
        <CommonDrugs />
        <SystemicDzLameness />
        <Osteoarthritis />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
