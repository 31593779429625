import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Circi() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>CIRCI</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
            In <span style={{fontWeight: "700"}}>Critical Illness-related Corticosteroid Insufficiency (CIRCI)</span> inflammatory cytokines may lead to adrenal insufficiency. In sepsis and septic shock, volume restore and use vasopressors to target MAP of 65. If hypotension persists despite medium to high doses of vasopressors, consider low-dose corticosteroids for several days (Dexamethasone SP at 0.05 mg/kg/day IV may be an alternative).<sup>1</sup> ACTH stimulation test is not indicated unless hypoadrenocorticism is a differential diagnosis for the patient.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{fontSize: "0.8rem"}}><span style={{fontWeight: "700"}}>Reference:</span><br/>
            <sup>1</sup>Annane et al. Guidelines for the Diagnosis and Management of Critical Illness-Related Corticosteroid Insufficiency (CIRCI) in Critically Ill Patients (Part I): Society of Critical Care Medicine (SCCM) and European Society of Intensive Care Medicine (ESICM) 2017. Crit Care Medicine 2017.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
