import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Panosteitis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Panosteitis</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Panosteitis is a condition of young (usually large-breed) growing dogs that causes pain and limping. Other conditions can be ruled out by radiographs. Shifting-leg lameness is common. Radiographs usually show increased density and a classic pattern within the medullary cavity.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
        The disease is usually self-limiting and resolves with time. Rest and consider NSAID and gabapentin.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4953019" target="_blank" rel="noopener noreferrer">Panosteitis: Growing Pains in Dogs</a> from Veterinary Partner
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
