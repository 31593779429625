import React from 'react';
import { Grid, Button } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Splenectomy from '../Surgery/Splenectomy';

export default function SplenicTumors() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Splenic Masses</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto" style={{paddingBottom: "20px"}}>
            <Button href="/quick-sheets/hemoabdomen" variant="outlined">Hemoabdomen Quick Sheet</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Splenic tumors are commonly found in dogs either incidentally with imaging (radiographs or ultrasound) or when patients present on emergency with blood in their abdomen (splenic masses often bleed).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          In cases of hemoabdomen with suspected splenic masses, about 1/4 to 1/3 of splenic masses will be benign, and 2/3 to 3/4 will be malignant (usually hemangiosarcoma).  Surgery is usually necessary to treat bleeding abdominal masses and differentiate between bad cancer and benign masses.
        </Typography>
        <ul>
        <li>
        Consider chest films and full abdominal ultrasound before surgery.
        </li>
        <li>
        For hemangiosarcoma, MST is 1-3 months with surgery alone, 4-6 months with surgery and chemotherapy.
        </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://www.acvs.org/small-animal/splenic-masses" target="_blank" rel="noopener noreferrer">Splenic Masses Handout</a> <span style={{fontWeight: "500"}}>from ACVS</span>
        </Typography>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&catId=102899&id=4951872" target="_blank" rel="noopener noreferrer">Splenic Masses in Dogs (Splenectomy)</a> <span style={{fontWeight: "500"}}> from Veterinary Partner</span>
        </Typography>
        <Typography>
        <a href="https://vsso.org/splenic-hsacanine" target="_blank" rel="noopener noreferrer">Splenic HSA</a> <span style={{fontWeight: "500"}}> from VSSO</span>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Splenectomy />
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
