import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

class AnaphylaxisQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Anaphylaxis & Hypersensitivity Reactions</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            <span style={{fontWeight: '700'}}>Presentation:</span> Fitting history with known or possible exposure to bite, sting, or vaccine leading to urticaria (hives), vomiting, diarrhea, lethargy or excitement, facial swelling/edema, and/or difficulty breathing; gall bladder wall thickening/edema may be present on ultrasound (canine); hypoxia and hypotension in severely affected patients
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Controversy:</span> Lack of evidence creates some controversy around drug therapy.  Diphenhydramine and corticosteroids (i.e. Dexamethasone SP) may be beneficial.  Epinephrine, although a first-line therapy in human medicine, is controversial in dogs.
            </li>
            <li>
            IV fluid therapy to support perfusion and symptomatic and supportive care with monitoring in hospital is recommended for moderate to severe hypersensitivity reactions.
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Diagnostics:</span> Should include ALT, PCV/TS, and Global FAST (or similar); consider PT/aPTT in cases of anaphylaxis
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV cath"
            inputProps={{ 'aria-label': 'place IV cath' }}
            /><span style={{fontWeight: '700'}}>Place IV catheter and provide oxygen if necessary</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="epinephrine"
            inputProps={{ 'aria-label': 'epinephrine' }}
            />Consider <span style={{fontWeight: '700'}}>Epinephrine</span> at 0.01 mg/kg IM = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.01)).toFixed(2)} mL</span> of 1 mg/mL Epinephrine for this patient.<sup>1</sup>
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Maximum of 0.5 mg Epinephrine (0.5 mL of 1 mg/ml) for patients weighing &ge;40 kg
            </li>
            <li>
            For anaphylactic shock with severe hypotension, consider Epinephrine at 0.05 mcg/min/kg IV CRI = {(this.state.weightkg*(0.05)).toFixed(2)} mcg/min Epinephrine for this patient.<sup>2</sup>
            </li>
            <li>
            Can titrate to effect up to 0.5 mcg/min/kg IV CRI
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="diphenhydramine"
            inputProps={{ 'aria-label': 'diphenhydramine' }}
            />Consider <span style={{fontWeight: '700'}}>Diphenhydramine</span> at 2 mg/kg IM = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/50).toFixed(2)} mL</span> of 50 mg/mL Diphenhydramine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>

            <li>
            Consider continuing PO at 2 - 4 mg/kg = {(this.state.weightkg*(2)).toFixed(1)} mg to {(this.state.weightkg*(4)).toFixed(1)} mg for this patient q8h.  25 mg diphenhydramine tablets are common over the counter (Benadryl or generic)
            </li>
            <li>
            Cetirizine or Hydroxyzine may be more effective.<sup>3</sup> Consider giving hydroxyzine (often cheaper than cetirizine and available in 10, 25, and 50 mg tablets) PO at 2 mg/kg = {(this.state.weightkg*(2)).toFixed(1)} mg for this patient q12h
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="diphenhydramine"
            inputProps={{ 'aria-label': 'diphenhydramine' }}
            />Consider <span style={{fontWeight: '700'}}>Famotidine</span> at 1 mg/kg SC or IV = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL Famotidine for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider continuing PO at &ge;1 mg/kg = {(this.state.weightkg*(1)).toFixed(1)} mg for this patient q12h.
            </li>
            <li>
            Tablets are commonly 10 mg and 20 mg over the counter (Pepcid AC and Pepcid AC Maximum Strength)
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="Dex SP"
            inputProps={{ 'aria-label': 'Dex SP' }}
            />Consider <span style={{fontWeight: '700'}}>Dexamethasone SP</span> at 0.15 to 0.3 mg/kg IV = {(this.state.weightkg*(0.15)/3).toFixed(2)} mL to {(this.state.weightkg*(0.3)/3).toFixed(2)} mL of 3 mg/mL Dexamathasone (+ 1 mg/mL SP) for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Onset of action is several hours.
            </li>
            <li>
            Consider continuing PO Prednisone at 0.5 - 1 mg/kg = {(this.state.weightkg*(0.5)).toFixed(1)} mg to {(this.state.weightkg*(1)).toFixed(1)} mg for this patient q12h for 3 days then q24h for 3 days.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV fluids"
            inputProps={{ 'aria-label': 'IV fluids' }}
            /><span style={{fontWeight: '700'}}>IV Fluid Resuscitation</span> when needed
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Fluid bolus of 10 to 20 ml/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL </span> over 20 minutes (canine).  Then reassess.
            </li>
            <li>
            Give 5 to 10 mL/kg = {(this.state.weightkg*(5)).toFixed(1)} mL to {(this.state.weightkg*(10)).toFixed(1)} mL
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="cerenia"
            inputProps={{ 'aria-label': 'cerenia' }}
            />            Consider <span style={{fontWeight: '700'}}>Maropitant</span> at 1 mg/kg IV or SC = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/10).toFixed(2)} mL</span> of 10 mg/mL Maropitant for this patient.
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider continuing PO at &ge;2 mg/kg or &ge;{(this.state.weightkg*(2)).toFixed(1)} mg for this patient q24h.  Available as 16 mg, 24 mg, 60 mg, 160 mg tablets.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="monitoring"
            inputProps={{ 'aria-label': 'monitoring' }}
            />Consider continued monitoring of patient in hospital depending on severity and response to treatments
          </Grid>


          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            />Consider vasopressor CRI for refractory hypotension
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            Consider dopamine at 2.5 mcg/min/kg IV CRI = {(this.state.weightkg*(2.5)).toFixed(2)} mcg/min Dopamine for this patient. Can titrate to 10 mcg/kg/min.
          </li>
          <li>
            Vasopressin (AVP) in its respective dose range is an alternative.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            />Consider fresh frozen plasma (FFP) in cases of significantly elevated aPTT
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <ul>
          <li>
            FFP is generally given at 10-20 mL/kg = {(this.state.weightkg*(10)).toFixed(0)} to {(this.state.weightkg*(20)).toFixed(0)} mL for this patient.
          </li>
          <li>
            Packed RBCs are rarely needed (most anaphylactic hemoabdomen cases appear hemoconcentrated)
          </li>
          </ul>
          </Grid>



            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handout:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951409" target="_blank" rel="noopener noreferrer">Vaccine Allergic Reactions in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Your pet was treated for a severe allergic reaction. Recheck promptly if worsening or not improving. If this was associated with vaccine administration, please bring this to the attention of your primary veterinarian. They may recommend additional treatments (such as diphenhydramine) prior to future vaccination.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '500'}}>
            References:
            </Grid>
            <Grid item xs={12}>

              <p><sup>1</sup> Shumel et al. Anaphylaxis in dogs and cats. JVECC 2013.</p>

              <p><sup>2</sup> Mink et al. Constant infusion of epinephrine, but not bolus treatment, improves haemodynamic recovery in anaphylactic shock in dogs. Clin Exp Allergy 2004.</p>

              <p><sup>3</sup> Banovic et al. Effect of diphenhydramine and cetirizine on immediate and late-phase cutaneous allergic reactions in healthy dogs: a randomized, double-blinded crossover study. Vet Dermatol 2020.</p>


            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default AnaphylaxisQS;
