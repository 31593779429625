import React, {useState} from 'react';

import { Grid, Button, Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import larparGif from './images/larparGif.gif';

export default function Golpp() {
  const [refs, setRefs] = useState(false);

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Geriatric Onset Laryngeal Paralysis Polyneuropathy (GOLPP)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto" style={{paddingBottom: "20px"}}>
            <Button href="/quick-sheets/upper-airway-obstruction" variant="outlined">Upper Airway Obstruction Quick Sheet</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Description & Etiology:</span> Geriatric large breed dogs are most frequently affected by this progressive disease which causes laryngeal paralysis and polyneuropathy.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Clinical Signs & Presentation:</span> Most present with classic stridor or "roar". These may present in respiratory distress and require sedatives, oxygen, or intubation. Pet parents commonly report a bark change and exercise intolerance. Hind limb weakness and muscle loss is often apparent. Some of these dogs will have a cough and some will present with aspiration pneumonia.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Differential Diagnoses:</span> Upper airway mass, foreign body, brachycephalic airway syndrome, tracheal collapse, rule out concurrent pneumonia or heart disease
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Diagnostics:</span> 
        </Typography>
        <ul>
          <li>
          Laryngoscopy (sedated to visualize arytenoids during breathing, sternal, slow propofol to effect)
          </li>
          <ul><li>Doxapram (1 mg/kg IV) can be used to increase respiratory excursions</li></ul>
          <Grid item xs={12} md={6}>
            <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={larparGif} alt="arytenoids not moving" style={{borderRadius:"2%", maxWidth: "98%"}}/>
            </Paper>
          </Grid>
          <li>
          Neck and chest radiographs (megaesophagus and aspiration pneumonia are common)
          </li>
          <li>
          CBC/Chemistry/UA (include T4)
          </li>
          <li>
          Fluoroscopy for esophagram/swallow study (routine where available)
          </li>
          <li>
          Nerve and muscle biopsies and electrodiagnostics
          </li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <span style={{fontWeight: "700"}}>Treatment:</span>
        </Typography>
        <ul>
          <li>“Tie-back” (Arytenoid laryngoplasty) surgery addresses the upper airway part, but does nothing for hind end weakness or esophageal dysfunction.</li>
          <li>Physical Rehabilitation is recommend for progressive hind limb paresis.</li>
          <ul><li><a href="https://helpemup.com/" target="_blank" rel="noopener noreferrer">Help 'Em Up Harness</a> may be helpful for management.</li></ul>
          <li>Metoclopramide for moderate to severe esophageal dysfunction</li>
          <ul><li>Cisapride may be a useful alternative or adjunct</li></ul>
          <li>Doxepin is a tricyclic antidepressant with anecdotal reports of benefit. A double-blinded, randomized, placebo-controlled study failed to show any benefit at 3-5 mg/kg q12h PO.<sup><Button sx={{textTransform: "none", p: "0", lineHeight: "1"}} onClick={()=>setRefs(!refs)}>Rishniw et al.</Button> {refs ? " Effect of doxepin on quality of life in Labradors with laryngeal paralysis: A double-blinded, randomized, placebo-controlled trial. JVIM 2021." : "" }</sup></li>
        </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a href="https://www.acvs.org/small-animal/laryngeal-paralysis" target="_blank" rel="noopener noreferrer">Laryngeal Paralysis</a> from ACVS
          </Typography>
          <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952489" target="_blank" rel="noopener noreferrer">Laryngeal Paralysis in Dogs</a> from Veterinary Partner
          </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
