import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

import TracheostomyProcedure from '../Notebook/Emergency/TracheostomyProcedure';

export default function UpperAirwayObstruction() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Upper Airway Obstruction</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

        <Grid item xs={12}>
          <TextField
                  id="standard-textarea"
                  label="Name"
                  multiline
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <Typography>
          <span style={{fontWeight: '700'}}>Presentation: </span>
          Often with stertor or stridor, extending head and neck to breath (orthopnea), cyanosis may be obvious, occasionally present unconscious
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Ddx: </span>
          Lower airway or parenchymal disease, pleural effusion, pneumothorax, abdominal distension, look-alike disease including anemia
        </Typography>
        <Typography>
          <span style={{fontWeight: '700'}}>Underlying causes: </span>
        </Typography>
        <ul style={{marginTop: 0, marginBottom: 0}}>
            <li>
            Foreign body in airway
            </li>
            <li>
            Brachycephalic Airway Obstructive Syndrome (BAOS)
            </li>
            <ul>
              <li>
              Often worsened by acute edema
              </li>
            </ul>
            <li>
            Laryngeal Paralysis
            </li>
            <li>
            Tracheal collapse (often described as history of “honking”)
            </li>
            <li>
            Upper airway mass
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.2)/10).toFixed(2)} to {(weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol IM.
            </li>
            <li>
            Place directly in the oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in compromised patients.
            </li>
            <li>
            Some patients may require immediate intubation or tracheostomy, especially if unconscious.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider additional sedation</span>
          <ul style={{marginTop: 0}}>
            <li>
            Acepromazine at 0.025 to 0.05 mg/kg (10 mg/mL) = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.025)/10).toFixed(2)} to {(weightkg*(.05)/10).toFixed(2)} mL</span> of 10 mg/mL Acepromazine
            </li>
            <li>
            Dexmedetomidine at 3 ug/kg can be considered if not contraindicated; have reversal ready
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Consider anti-inflammatory corticosteroid if not contraindicated</span>
          <ul style={{marginTop: 0}}>
            <li>
            Consider Dexamethasone SP at 0.2 mg/kg IV = <span style={{backgroundColor: '#fff2ac'}}>{(weightkg*(.2)/4).toFixed(2)} mL</span> of  4 mg/mL Dexamethasone SP
            </li>
            <li>
            May be contraindicated if recent NSAID administration
            </li>
            <li>
            Upper airway swelling and distressed, rapid breathing are self perpetuating&mdash;air force over swollen airway contributes to further swelling.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Intubation and positive pressure ventilation may be necessary in severe cases</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>Tracheostomy may be necessary in severe cases</span>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', marginLeft: '25px'}}>
          <TracheostomyProcedure />
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="Anesthesia"
          inputProps={{ 'aria-label': 'Anesthesia' }}
          /><span style={{fontWeight: '700'}}>If overheating contributed to upper airway obstruction, treat heat stroke</span>
          <ul style={{marginTop: 0}}>
            <li>
            See <Link to={'/quick-sheets/heat-stroke'}>Heat Stroke Quick Sheet</Link>
            </li>
            <li>
            Especially common in brachycephalic breeds with BAOS
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
          Informational Handouts:
        </Grid>
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951534" target="_blank" rel="noopener noreferrer">Brachycephalic Airway Obstruction Syndrome in Flat-Faced Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4951968" target="_blank" rel="noopener noreferrer">Tracheal Collapse in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid><Grid item xs={12}>
          <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952489" target="_blank" rel="noopener noreferrer">Laryngeal Paralysis in Dogs</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>

        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )


};