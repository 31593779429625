import React from 'react'
import { Grid, IconButton, Button } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import NotebookNotes from '../NotebookNotes';

import OpthoExam from './OpthoExam';
import CommonConditions from './CommonConditions';
import SurgicalProcedures from './SurgicalProcedures';
import AdditionalResources from './AdditionalResources';
import ClinicalSigns from './ClinicalSigns';

export default function Ophthalmology() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs="auto">
        <Button href="https://vetnurseguide.com/eye-form" target="_blank" rel="noopener noreferrer" variant="outlined">Ophtho Exam Form</Button>
      </Grid>
      <Grid item xs={12}>
        <OpthoExam />
        <ClinicalSigns />
        <CommonConditions />
        <SurgicalProcedures />
        <AdditionalResources />
      </Grid>
      <NotebookNotes />
      <Grid item xs={12} style={{textAlign: "center"}}>
      <IconButton
        component={Link}
        size="large"
        color="primary"
        aria-label="back"
        aria-controls="back"
        aria-haspopup="false"
        to="/clinical-notebook"
      >
        <ArrowBack />
      </IconButton>
    </Grid>
    </Grid>
  );
}
