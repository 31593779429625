import React, { useState } from 'react';
import { Grid, InputAdornment, TextField, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

export default function HepaticLipidosis() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  return (
    <>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
        <Grid item xs={12} sm={6}>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>

              </Grid>

        </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
               <TableRow>
                  <TableCell align="left">RER = 70 x (kg)^0.75</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">¾ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*3/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅔ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*2/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">½ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/2).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">⅓ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/3).toFixed(0)} kcal/day</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">¼ RER</TableCell>
                  <TableCell align="left">{(70*(weightkg**(3/4))*1/4).toFixed(0)} kcal/day</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography>RER may be the target for hospitalized or obese patients (with weight loss in mind). Most cats should be fed 1.2 to 1.4xRER. Most dogs should be fed 1.6xRER. There is a good deal of variation, especially for working dogs and growing puppies which may need 2xRER or more.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>
            <a href="https://petnutritionalliance.org/dog.php" target="_blank" rel="noopener noreferrer">Pet Nutrition Alliance Calorie Calculator for Dogs</a>
          </Typography>
          <Typography>
            <a href="https://petnutritionalliance.org/cat.php" target="_blank" rel="noopener noreferrer">Pet Nutrition Alliance Calorie Calculator for Cats</a>
          </Typography>
          </Grid>

      </Grid>
    </>
  )


};