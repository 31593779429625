import React from 'react';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiabeticNeuropathy from '../InternalMedicine/DiabeticNeuropathy';
import LymeDisease from '../InternalMedicine/LymeDisease';
import Sepsis from '../Emergency/Sepsis';
import ArterialThrombosis from '../Emergency/ArterialThrombosis';
import AorticThromboembolism from '../Emergency/AorticThromboembolism';
import Impa from '../InternalMedicine/Impa';

export default function SystemicDzLameness() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Systemic Diseases Causing Lameness</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <ul>
          <li>
            <AorticThromboembolism />
          </li>
          <li>
            <ArterialThrombosis />
          </li>
          <li>
            <DiabeticNeuropathy />
          </li>
          <li>
            <Impa />
          </li>
          <li>
          <LymeDisease />
          </li>
          <li>
            Polymyositis
          </li>
          <li>
            Polyradiculoneuritis
          </li>
          <li>
            <Sepsis />
          </li>
        </ul>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
