import React from 'react';
import { Link } from "react-router-dom";
import { Button, Stack } from '@mui/material';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  color: #510505;
  padding: 0 0 10px 0;
  text-align: center;
  text-decoration: none;
  display: inlineBlock;
  box-shadow: 0px 2px 1px rgba(0,0,0,.3);
  margin-bottom: .5rem;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 4px;
  transition: all .25s linear;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: 'Spectral SC', serif;
  &:hover:
    {background-color: white;
`;

const FillPicDiv = styled('div')`
  width: 300px;
  height: 150px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
`;

const FillPicImg = styled('img')`
  width: 100%;
`;

function TopicButton (props) {
  return (
    <StyledButton component={Link} to={props.route} sx={{'&:hover': {backgroundColor: 'white'}}}>
      <Stack>
        <FillPicDiv>
          <FillPicImg src={props.pic} alt={props.topic + " image"}/>
        </FillPicDiv>
        {props.topic}
      </Stack>
    </StyledButton>
  )
}


export default TopicButton;
