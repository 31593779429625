import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Typography, IconButton, Checkbox, Switch, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBack from '@mui/icons-material/ArrowBack';

import Psyllium from '../Notebook/InternalMedicine/Psyllium';

export default function AcuteVomiting() {
  const [weightlbs, setWeightlbs] = useState("");
  const [weightkg, setWeightkg] = useState("");
  const [switchCat, setSwitchCat] =useState(false)
  
  function handlelbsChange(event) {
    setWeightlbs(event.target.value);
    setWeightkg(Number(parseFloat(event.target.value)/2.2).toFixed(2));
  }
  function handlekgChange(event) {
    setWeightkg(event.target.value);
    setWeightlbs(Number(parseFloat(event.target.value)*2.2).toFixed(2));
  }
  function handleSwitchChange(event) {
    setSwitchCat(event.target.checked)
  }
  return (
    <>
      <h1 style={{textAlign: "center"}}>Acute Vomiting and Diarrhea</h1>
      <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
      <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>
        <Grid item xs={12}>
          <TextField
            id="standard-textarea"
            label="Name"
            multiline
            margin="normal"
            size="small"
          />
        </Grid>
        <Grid container>
        <Grid item xs={12}>
          <TextField
                  id="weightlbs"
                  type="number" value={weightlbs} onChange={handlelbsChange}
                  label="Weight (lbs)"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                <TextField
                          id="weightkg"
                          type="number" value={weightkg} onChange={handlekgChange}
                          label="Weight (kg)"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                          }}
                        />
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1} style={{fontWeight: '700', fontSize: '1.2em'}}>
          <Grid item>Canine</Grid>
          <Grid item><Switch checked={switchCat} onChange={handleSwitchChange} name="Cat" />
          </Grid>
          <Grid item>Feline</Grid>
        </Grid>
        
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: '700'}}>Key points for communication: </span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            {switchCat ? 
            <>
              <li>
              Imaging (ultrasound or radiographs) is recommended to help rule out GI obstruction. Ultrasound is best to assess the pancreas and GI wall layering, but is not always available.              </li>
              <li>
              Blood work  will help assess hydration and electrolytes and will help rule out common endocrine diseases and pancreatitis.
              </li>
              <li>
              If imaging and blood work are unremarkable, outpatient therapy with anti-nausea medication and subcutaneous fluids may be attempted as a treatment trial. In this case, pet parents should see significant improvement within 12-24 hours, or prompt recheck is recommended. If present, diarrhea may take several days to fully resolve.
              </li>
            </>
            :
            <>
              <li>
              Imaging (ultrasound or radiographs) is recommended to help rule out GI obstruction. Ultrasound is best to assess the pancreas and GI wall layering, but is not always available.
              </li>
              <li>
              Blood work  will help assess hydration and electrolytes and rule out common diseases such as AHDS, pancreatitis, and some endocrine disease.
              </li>
              <li>
              If imaging and blood work are unremarkable, outpatient therapy with anti-nausea medication and subcutaneous fluids may be attempted as a treatment trial. In this case, you should see significant improvement within 12-24 hours. If present, diarrhea may take several days to fully resolve.
              </li>
            </>
            }
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{fontWeight: '700'}}>Diagnostics:</span>
          </Typography>
          <ul style={{marginTop: 0, marginBottom: 0}}>
            {switchCat ? 
            <>
              <li>
              3-view abdominal radiographs
              </li>
                <ul>
                  <li>
                  Consider  0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(parseFloat(weightkg)*(.03)).toFixed(2)} mL</span> of 10 mg/mL butorphanol IV for sedation
                  </li>
                </ul>
              <li>
              Consider abdominal ultrasound instead of abdominal radiographs if available
              </li>
              <li>
              CBC
              </li>
              <li>
              Chemistry
              </li>
              <li>
              SNAP fPL - highly sensitive, making this a good rule out test for pancreatitis (if normal, not likely to have pancreatitis)
              </li>
              <li>
              tT4 (in older patients)
              </li>
              <li>
            Fecal testing - for common intestinal parasites
            </li>
            <li>
            UA to better assess hydration and kidneys
            </li>
            </>
            :
            <>
            <li>
            3-view abdominal radiographs
            </li>
              <ul>
                <li>
                Consider  0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(parseFloat(weightkg)*(.03)).toFixed(2)} mL</span> of 10 mg/mL butorphanol IV for sedation if needed
                </li>
                <li>
                Start with the left lateral, then VD, then right lateral <sup>1</sup>
                </li>
              </ul>
            <li>
            Consider abdominal ultrasound instead of abdominal radiographs if available
            </li>
            <li>
            CBC - stress leukogram is expected
            </li>
            <li>
            Chemistry - low K+ and Cl- common with upper GI obstruction (hypochloraemic, hypokalaemic metabolic alkalosis)
            </li>
            <li>
            Cortisol - 40% of hypoadrenocorticism is diagnosed as atypical (normonatremic, normokalemic) hypoadrenocorticism <sup>2</sup>
            </li>
            <li>
            SNAP cPL - highly sensitive, making this a good rule out test for pancreatitis (if normal, not likely to have pancreatitis)
            </li>
            <li>
            Fecal testing - for common intestinal parasites
            </li>
            <li>
            UA to better assess hydration and kidneys
            </li>
          </>
          }
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{fontWeight: '700'}}>
          Common outpatient treatment for acute nonspecific gastroenteritis:
          </Typography>
        </Grid>
        
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Consider maropitant (Cerenia)</span>
        </Grid>
        <ul style={{marginTop: 0, marginBottom: 0}}>
          <li>
          1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(parseFloat(weightkg)*((1)/10)).toFixed(2)} mL</span> of 10 mg/mL maropitant SC
          </li>
          <li>
          Inform pet parents that Cerenia (maropitant)  is a strong anti-nausea medication (maropitant, Cerenia).  Any vomiting in the next 24 hours is a serious concern and requires immediate veterinary attention.  Additional diagnostics, including radiographs (x-ray images) may be necessary.
          </li>
        </ul>
        <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          color="primary"
          label="IV catheter"
          inputProps={{ 'aria-label': 'IV catheter' }}
          /><span style={{fontWeight: '700'}}>Consider subcutaneous fluids</span>
        </Grid>
        {switchCat ?
        <></>
        :
        <ul style={{marginTop: 0, marginBottom: 0}}>
          <li>
          20 mL/kg = <span style={{backgroundColor: '#fff2ac'}}>{(parseFloat(weightkg)*(20)).toFixed(2)} mL</span> LRS SC
          </li>
        </ul>
        }
        <Grid item xs={12} style={{textAlign: 'left', marginTop: '15px'}}>
          <Typography style={{fontWeight: '700'}}>Medications to consider:</Typography>
          <Typography style={{padding: '16px'}}>
          Fiber and probiotics once eating
          </Typography>
          
          <Psyllium />
          {switchCat ?
         <></>
          :
          <>
            <ul>
              <li>Pectin is another common prebiotic/soluble fiber source that supports intestinal flora. It can be used in addition to psyllium.
              </li>
              <li>Many canine-specific probiotics are available as powders or capsules.
              </li>
              <li>Pumpkin is not a great fiber source (not much concentrated fiber, limited benefit relative to psyllium).
              </li>
              <li>Sweet potato is not a fiber source; it's more a source of carbohydrates.
              </li>
            </ul>
            <Typography style={{paddingLeft: '16px'}}>
            Omeprazole or Famotidine may be beneficial in select cases with GI ulceration, erosion, or reflux esophagitis.<sup>3</sup>
            </Typography>
          </>
          }
          <Typography style={{padding: '16px'}}>
            Prescription GI veterinary diet
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs={12}>
              Reference:
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <p><sup>1</sup>Hart and Berry. Initial Influence of Right versus left lateral recumbency on the radiograph finding of duodenal gas on subsequent survey ventrodorsal projections of the canine abdomen. Vet Rad & Ultrasound. 2014.</p>
                <p><sup>2</sup>Reagan et al. Machine learning algorithm as a diagnostic tool for hypoadrenocorticism in dogs. Domest Anim Endocrinol 2020</p>
                <p><sup>3</sup>Marks et al. ACVIM consensus statement: Support for rational administration of gastrointestinal protectants to dogs and cats. JVIM 2018.</p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{margin: '20px 0px'}}/>
        </Grid>
        <Grid item xs={12}>
        <p>
          This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
        </p>
        <p>
          All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
        </p>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <IconButton
            component={Link}
            size="large"
            color="primary"
            aria-label="back"
            aria-controls="back"
            aria-haspopup="false"
            to="/quick-sheets"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        </Grid>
    </>
  )
};