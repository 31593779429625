import React from 'react';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SccFeline() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Squamous Cell Carcinoma</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Squamous cell carcinomas are UV-induced tumors that often affect the face, ears, or nose of cats.  They are slow growing and destructive.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography gutterBottom>
          Early identification is key. There are many treatment options including strontium-90 treatment, other radiation treatments, surgical excision (may include nasal planectomy for nasal SCC).
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952685" target="_blank" rel="noopener noreferrer">Squamous Cell Carcinoma Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span>
        </Typography>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
