import React, { useState } from "react";
import { Grid, TableRow, TableHead, TableContainer, TableBody, Paper, TableCell, Table, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import NormalSinusEcg from './images/normalSinusEcg.jpg';
import SinusTachy from './images/sinusTachy.jpg';
import SinusBrady from './images/sinusBrady.jpg';
import Vpcs from './images/vPCs.jpg';
import AvBlock from './images/AVBlock.jpg';

import CapnoCardiogenic from './images/capnoCardiogenic.jpg';
import CapnoCleft from './images/capnoCleft.jpg';
import CapnoSinusoidal from './images/capnoSinusoidal.jpg';
import CapnoLeak from './images/capnoLeak.jpg';
import CapnoObstruction from './images/capnoObstruction.jpg';
import CapnoRebreathing from './images/capnoRebreathing.jpg';
import CapnoEsophageal from './images/capnoEsophageal.jpg';




export default function MonitoringAnesthesia() {

  const [openIds, setOpenIds] = useState([]);
  const handleOpenClick = (i) => {
    let updatedIds=openIds.concat([])
    if (openIds === []) {
      setOpenIds([i]);
    } else if (openIds.includes(i)) {
      setOpenIds(updatedIds.filter(x => x !== i));
    } else {
      setOpenIds(updatedIds.concat([i]));
    }
  }

  return (

    <Accordion>

    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Monitoring Vitals and Troubleshooting</Typography>
    </AccordionSummary>
    <AccordionDetails>


<Grid container spacing={1}>
<Grid item xs={12}>
<TableContainer component={Paper}>
  <Table size="small">
      <TableHead style={{backgroundColor: '#c1d1f7'}}>
        <TableRow>
          <TableCell align="left" colSpan={3}>Circulation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpenClick(0)}
            >
              {openIds.includes(0) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">Peripheral Pulse (rate, rhythm, quality)</TableCell>
          <TableCell align="left">large dogs &ge;60 &le;150<br />small dogs &ge;80 &le;190<br />cats &ge;100 &le;180<br />regular, strong</TableCell>
        </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={openIds.includes(0)} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                <Grid container>
                <Grid item xs={12}>
                <Typography>
                <span style={{fontWeight: 600}}>Tachycardia:</span> do not assume inadequate depth; may be secondary to pain, hypoxemia, hypercarbia, hypovolemia; may be secondary to alfaxalone, ketamine, atropine, dopamine
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography>
                <span style={{fontWeight: 600}}>Bradycardia:</span> may be secondary to alpha-2 (dexmedetomidine) - consider reversal; decrease anesthetic depth; check temperature and address hypothermia; check cardiac rhythm on EKG (consider atropine or glycopyrrolate for AV block, consider insulin/dextrose/calcium if hyperkalemia); consider anticholinergic (atropine or glycopyrrolate) if concurrent hypotension
                </Typography>
                </Grid>
                </Grid>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleOpenClick(1)}
              >
                {openIds.includes(1) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">MM Color and CRT</TableCell>
            <TableCell align="left">Pink, 1-2s</TableCell>
          </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={openIds.includes(1)} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                  <Grid container>
                  <Grid item xs={12}>
                  <Typography>
                  CRT (and MM color) may be affected by alpha-2 drugs (dexmedetomidine). CRT is not a reliable measure of perfusion
                  </Typography>
                  </Grid>
                  </Grid>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleOpenClick(2)}
                >
                  {openIds.includes(2) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell align="left">ECG</TableCell>
              <TableCell align="left">
              <img src={NormalSinusEcg} alt="normal sinus ecg" style={{borderRadius:"2%", maxWidth: "90%"}}/><br />
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={openIds.includes(2)} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={SinusTachy} alt="sinus tachy" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Sinus Tachycardia:</span> do not assume inadequate depth; may be secondary to pain, hypoxemia, hypercarbia, hypovolemia; may be secondary to alfaxalone, ketamine, atropine, dopamine
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={SinusBrady} alt="sinus brady" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Sinus Bradycardia:</span> may be secondary to alpha-2 (dexmedetomidine) - consider reversal; decrease anesthetic depth; check temperature and address hypothermia; check cardiac rhythm on EKG (consider atropine or glycopyrrolate for AV block, consider insulin/dextrose/calcium if hyperkalemia); consider anticholinergic (atropine or gylcopyrrolate) if concurrent hypotension
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={Vpcs} alt="vpcs" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>VPCs:</span> consider a lidocaine bolus (2 mg/kg) +/- CRI when multiform, causing hypotension, or occurring in significant runs
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={AvBlock} alt="av block" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>2nd degree AV block:</span> consider atropine or glycopyrrolate when hypotension or bradycardia (notice P wave without QRS)
                          </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleOpenClick(3)}
                  >
                    {openIds.includes(3) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell align="left">BP</TableCell>
                <TableCell align="left">Systolic &ge;90mmHg<br />MAP 60-80mmHg</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={openIds.includes(3)} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Grid container>
                    <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: 600}}>Hypotension:</span> Consider decreasing inhalant dose, IV fluid bolus (5-20 mL/kg crystalloid), anticholinergic (atropine or glycopyrrolate) if concurrent bradycardia, vasoconstrictor (norepinephrine) if excessive vasodilation or positive inotrope (dopamine or dobutamine) if decreased cardiac contractility.  Correct hypoglycemia, hypothermia, anemia/hypoproteinemia, electrolyte disturbances.
                    </Typography>
                    <Typography>
                    <span style={{fontWeight: 600}}>Hypertension:</span> Unlikely; Consider equipment failure/misuse, response to noxious stimulation, hypoxemia, hypercarbia, hypovolemia, light plane of anesthesia. Do NOT increase inhalant in an attempt to decrease BP.
                    </Typography>
                    </Grid>
                    </Grid>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>

              <TableHead style={{backgroundColor: '#c1d1f7'}}>
                <TableRow>
                  <TableCell align="left" colSpan={3}>Oxygenation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpenClick(4)}
                    >
                      {openIds.includes(4) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">SpO<sub>2</sub></TableCell>
                  <TableCell align="left">&ge;95% on room air<br />&ge;98% on O<sub>2</sub></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openIds.includes(4)} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                      <Grid container>
                      <Grid item xs={12}>
                      <Typography>
                      Check for proper intubation, check for one-lung intubation (ETT inserted past thoracic inlet), check oxygen connection and supply, consider manual or mechanical ventilation, check for bradycardia, tachycardia, hypotension
                      </Typography>
                      </Grid>
                      </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableHead style={{backgroundColor: '#c1d1f7'}}>
                <TableRow>
                  <TableCell align="left" colSpan={3}>Ventilation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                  </TableCell>
                  <TableCell align="left">Respiratory Rate</TableCell>
                  <TableCell align="left">10-15 per minute</TableCell>
                </TableRow>

                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpenClick(5)}
                    >
                      {openIds.includes(5) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">EtCO<sub>2</sub></TableCell>
                  <TableCell align="left">35-40mmHg</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openIds.includes(5)} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                      <Grid container>
                      <Grid item xs={12}>
                      <Typography>
                      May be 40-55mmHg at surgical plane of anesthesia; 28-35mmHg is more appropriate for brain cases
                      </Typography>
                      <Typography>
                      <span style={{fontWeight: 600}}>Hypercapnia:</span> Start positive pressure ventilation if &ge;60mmHg.  Increased inspired CO<sub>2</sub> may be due to excessive dead space, exhausted CO<sub>2</sub> absorbent, machine malfunction, inadequate O2 flow (non-rebreathing circuits)
                      </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoCardiogenic} alt="capno cardiogenic" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Cardiac Oscillations:</span> normal; rhythmic with pulse due to heart bouncing against lung
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoCleft} alt="capno cleft" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Curare Cleft:</span> normal; with chest movement; patient bucking ventilator or abdominal/thoracic manipulation during surgery; patient may be getting too light or not ventilating enough
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoSinusoidal} alt="capno sinusoidal" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Sinusoidal:</span> patient breathing on own, RR high with low tidal volume and low sampling rate (side-stream)
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoLeak} alt="capno leak" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Leak:</span> usually the endotracheal tube (ETT) or ETT cuff
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoObstruction} alt="capno obstruction" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Obstruction:</span> expiratory resistance, asthma, mucous plug
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoRebreathing} alt="capno rebreathing" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Rebreathing:</span> CO<sub>2</sub> absorbent is exhausted or insufficient O<sub>2</sub> flow in NRB systems
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="left" alignItems="center">
                          <Grid item xs='auto' >
                          <img src={CapnoEsophageal} alt="capno esophageal" style={{borderRadius:"2%", maxWidth: "98%"}}/><br />
                          </Grid>
                          <Grid item xs={12} md={6} alignItems="left">
                          <Typography>
                          <span style={{fontWeight: 600}}>Esophageal:</span> breaths not registering, gas from stomach
                          </Typography>
                          </Grid>
                        </Grid>

                      </Grid>
                      </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                  </TableCell>
                  <TableCell align="left">Tidal Volume</TableCell>
                  <TableCell align="left">10-15 mL/kg</TableCell>
                </TableRow>
              </TableBody>
              <TableHead style={{backgroundColor: '#c1d1f7'}}>
                <TableRow>
                  <TableCell align="left" colSpan={3}>Temperature</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpenClick(6)}
                    >
                      {openIds.includes(6) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">Rectal Temperature</TableCell>
                  <TableCell align="left">100-102.5<sup>o</sup>F</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openIds.includes(6)} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                      <Grid container>
                      <Grid item xs={12}>
                      <Typography>
                      <span style={{fontWeight: 600}}>Hypothermia (&le;98<sup>o</sup>F):</span> prevent with circulating warm water blankets or air circulation systems, wrap feet in bubble wrap or baby socks, continue O<sub>2</sub> supplementation for shivering patients following recovery
                      </Typography>
                      <Typography>
                      <span style={{fontWeight: 600}}>Hyperthermia:</span> particularly in cats following sedation/anesthesia, opioids and ketamine have been associated with rebound hyperthermia, degree of hypothermia during anesthesia may determine degree of hyperthermia following
                      </Typography>
                      </Grid>
                      </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>

              </TableBody>

              <TableHead style={{backgroundColor: '#c1d1f7'}}>
                <TableRow>
                  <TableCell align="left" colSpan={3}>Anesthetic Depth</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleOpenClick(7)}
                  >
                    {openIds.includes(7) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell align="left">
                <Typography>
                  Sedation: central depression, drowsiness, unaware of surroundings but responsive to noxious stimuli
                </Typography></TableCell>
                <TableCell align="left">
                <Typography>
                  Surgical Plane: lack of purposeful movement, barely present to absent palpebral, eyes ventral, mild jaw tone (Dissociative drugs such as ketamine may result in central eye position)
                </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={openIds.includes(7)} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Grid container>
                    <Grid item xs={12}>
                    <Typography>
                    <span style={{fontWeight: 600}}>Too deep:</span> eye position may return to central; decrease inhalant
                    </Typography>
                    <Typography>
                    <span style={{fontWeight: 600}}>Too light:</span> increase O<sub>2</sub> flow; give up to 1 mg/kg propofol slowly
                    </Typography>
                    </Grid>
                    </Grid>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              </TableBody>
    </Table>
    </TableContainer>
  </Grid>
  <Grid item xs={12}>
  References: <a href="https://www.aaha.org/anesthesia" target="_blank" rel="noopener noreferrer">2020 AAHA Anesthesia and Monitoring Guidelines for Dogs and Cats</a> &           <a href="https://catvets.com/guidelines/practice-guidelines/anesthesia-guidelines" target="_blank" rel="noopener noreferrer">2018 AAFP Feline Anesthesia Guidelines</a>
  </Grid>
  </Grid>


</AccordionDetails>
</Accordion>

  );
}
