import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Hemostasis() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Hemostasis</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography gutterBottom>Coaggulation (the process of blood clotting) is important, but complicated.  Several different tests may be needed to understand blood clotting problems in dogs and cats.</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>PT/PTT:</span> The combination of these tests can help identify problems with coagulation (clotting) factors.</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>Platelet number:</span> Hematology analyzers or manual counts from blood smears can help identify when platelet number is low.  Cat platelets commonly clump and make our counts inaccurate.  To estimate platelets from a blood smear, count the platelets in 10 microscopic fields at 100x, average this number, and multiply by 15,000.</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>BMBT:</span> Buccal Mucosal Bleeding Time (BMBT) can help detect problems with platelet function.</Typography>
              <Typography gutterBottom><span style={{fontWeight: "700"}}>vWF:</span> This antigen test is sent to a reference lab.  von Willebrand Factor (vWF) plays a role in platelet adhesion.  Doberman Pinschers, German shorthaired pointers, German wirehaired pointers, Scottish terriers, and Shetland sheepdogs are at greatest risk of von Willebrand Disease (vWD).</Typography>
              </Grid>
            </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
