import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CanineBehaviorProbs() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Problem Behaviors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Barking</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Anti-bark collars - citronella; Counter-conditioning: train dog to respond to predictable stimulus in new way; avoid any pet-parent-based punishment as this can increase arousal and fear and worsen the barking
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Chewing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Rotate chew toys; do not give old clothes or shoes; supervise and offer appropriate substitutes
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Digging</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              An intrinsically rewarding behavior. Terriers and Dachshunds are bred for this hunting behavior.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Jumping Up</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Jumping up is normal affectionate greeting behavior. It should be ignored (withdrawal), and a new, incompatible, behavior such as 'sit' should be trained to replace it (Counter-commanding). Withdrawal techniques can increase the frequency of the behavior temporarily.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Mounting & Humping</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Castration will result in rapid decline of mounting in 1/3, gradual decline in 1/3, and no change in remaining 1/3 of dogs. Extra exercise frequently gets rid of mounting behavior. Counter-commanding with 'sit' or 'down' can help redirect these dogs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Mouthing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Play biting and mouthing are normal behaviors especially during puppyhood. Ignore the behavior, use response substitution, and train a new behavior
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Feces Eating</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={1}>
            <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Food additives to change taste:</span> monosodium glutamate (For-Bid), Pineapple, trypsin supplements, crushed breath mints (without Xylitol)
            </Grid>
            <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Treating feces piles:</span> hot sauce, pepper, kerosene, steak sauce, monosodium glutamate
            </Grid>
            <Grid item xs={12}>
            <span style={{fontWeight: "700"}}>Ocassional access to untreated feces will be an intermittent reinforcer</span>
            </Grid>
            </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Rolling in Feces</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              May be socially rewarding (smells good to them). May be trying to cover it up with entire body surface. May be reducing novelty to or increasing investigation by other dogs.
              </Typography>
            </AccordionDetails>
          </Accordion>


        </AccordionDetails>
      </Accordion>

  );
}
