import React from 'react';

import {Accordion, Grid, Button } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function WoundsTraumatic() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Wounds (Traumatic)</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto" style={{paddingBottom: "20px"}}>
                  <Button href="/quick-sheets/blunt-trauma" variant="outlined">Blunt Trauma Quick Sheet</Button>
                </Grid>
                <Grid item xs="auto" style={{paddingBottom: "20px"}}>
                  <Button href="/quick-sheets/wounds" variant="outlined">Wounds Quick Sheet</Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Initial assessment and client communication should include:                    
                    </Typography>
                    <ul>
                      <li>
                      Assess Airway, Breathing, Circulation, and major Disabilities that will dramatically change expectations or outcome.
                      </li>
                      <li>
                      Disabilities that will significantly impact the prognosis should be quickly and tactfully brought to the pet parent's attention as you discuss goals and help guide them forward.
                      </li>
                      <li>
                      Less immediately obvious major problems to rule out include: pneumothorax, diaphragmatic hernia, pelvic fractures, urinary bladder rupture, hemoabdomen, bowel rupture
                      </li>
                      <li>
                      Once stable, sedation or anesthesia is usually necessary to clip, clean, explore, and possibly debride or close the wound(s).
                      </li>
                      <li>
                      The full extent of the injury may not be apparent for several days due to crush injury or loss of blood supply to affected tissue.
                      </li>
                      <li>
                      Common sequelae of bite wounds and lacerations may include infection, abscessation, tissue necrosis, and eschar formation. If closed, complications also include dehiscence, seroma formation, and suture reaction.
                      </li>
                      <li>
                      The wound will require follow up care. In best case scenarios, recheck is recommended in 3-4 days. If a bandage is placed, recheck in 24 hours or sooner if bandage shifts or becomes soiled.
                      </li>
                    </ul>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
