import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PyotraumaticDermatitis() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pyotraumatic Dermatitis (Hot Spot)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography>
            As dogs itch and chew at a patch of fur, they traumatize the skin. Normal skin bacteria take advantage of the moist environment and skin defect and overgrow. This worsens the itch in this area, and the viscous cycle continues. The goals of treatment include clipping away matted fur (which traps moisture and harbors bacteria), decreasing inflammation, preventing further self-trauma, and treating bacterial overgrowth.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            This is generally a presentation of superficial pyoderma.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Impression cytology may show purulent/suppurative inflammation and bacteria</Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span></Typography>
            <ul>
            <li>Likely need sedation to clip and clean lesion.</li>
            <li>Topicals can be used, but oily/occlusive ointments will keep these moist and may delay healing or worsen.  Keep clean and dry. Alcohols will burn and should be avoided.</li>
            <li>Appropriate antibiotic should be given for 1-2 weeks beyond resolution.</li>
            <li>Glucocorticoids (prednisone) is best treatment for pain and skin inflammation. Consider at 0.5 mg/kg q12h for 3-4 days then taper.</li>
            <li>Oclacitinib (Apoquel) can be considered at 0.6 mg/kg q12h in cases where steroids are contraindicated or if wish to avoid steroid use.</li>
            <li>E collar to prevent further self trauma.</li>
            <li>Look for and treat underlying causes.</li>
            </ul>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952981" target="_blank" rel="noopener noreferrer">Hot Spots in Dogs and Cats</a> from Veterinary Partner
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
