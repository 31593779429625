import { API } from "aws-amplify";

export async function getUser(userId) {
  try {
    const user = await API.get("notes", "/users");
    return user
  }
  catch(e) {
    console.log(e)
  }
  // In the event of an error getting user from database:
  return {userId: false, paymentStatus:"error"}
}
