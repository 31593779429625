import React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ElectricalBurn() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Fiberglass Insulation Ingestion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
            <Typography>
              Often present with known history or after eating through drywall to fiberglass insulation.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: "700"}}>Clinical Signs & Diagnostics:</span> Look for oromucosal irritation or any sign of respiratory distress. Significant pneumopathy is possible from irritation due to glass splinters. In asymptomatic patients, radiographs are not usually helpful&mdash;fiberglass insulation will not be obvious.
            </Typography>
            <Typography><span style={{fontWeight: "700"}}>Treatment:</span> Emesis is not usually recommended as aspiration of glass particles and mucosal irritation are the greatest concern. Bulk feed and monitor for passage.  If not eating or any vomiting, investigate possible GI obstruction.
            </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
