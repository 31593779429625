import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function CardioPericardialEffusion() {

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Pericardial Effusion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto" style={{paddingBottom: "20px"}}>
              <Button href="/quick-sheets/pericardial-effusion" variant="outlined">Pericardial Effusion Clinical Quick Sheet</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Causes in dogs include: Neoplastic (by far most common; RA Hemangiosarcoma, Chemodectoma, Mesothelioma, Lymphoma, Ectopic Thyroid Carcinoma, Metastaic Neoplasia), Idiopathic (20%), Infectious, Hypoalbuminemia, PPDH, Atrial rupture, Uremia, Coagulopathy, Pericardial cysts, Foreign Body</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Prognosis is days to weeks in dogs with neoplastic causes. Pericardiectomy and chemotherapy may extend survival. Idiopathic pericardial effusion carries a much better prognosis.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Pericardial effusion in the cat is often due to heart failure.</Typography>
            </Grid>
          </Grid>

        </AccordionDetails>
      </Accordion>

  );
}
