import React from 'react';
import { Grid, Paper } from '@mui/material';

import Typography from '@mui/material/Typography';

import PyoRlat from './images/PyometraRlat.jpg';
import PyoVD from './images/PyometraVD.jpg';

export default function PyometraRadiographs() {

  return (

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
          Massive uterine enlargement due to pyometra.
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={PyoRlat} alt="abdominal radiograph of patient with pyometra" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <img src={PyoVD} alt="abdominal radiograph of patient with pyometra" style={{maxWidth: "96%"}}/>
          </Paper>
          </Grid>


        </Grid>


  );
}
