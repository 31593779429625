import React from 'react';

import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Psyllium from '../InternalMedicine/Psyllium';

export default function PerianalFistula() {

  return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Perianal Fistula</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography>Presents as circumferential swelling and draining tracts around the anus with pain.  It is suspected to be immune-mediated.</Typography>
                  <Typography>German Shepard Dogs are predisposed.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography><span style={{fontWeight: "700"}}>Diagnostics:</span> Usually diagnosed based on history and clinical signs. Rule out anal sac disease, neoplasia.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography style={{fontWeight: "700"}}>Treatment:</Typography>
                  <ul>
                  <li>
                  Consider a novel protein or hydrolyzed protein diet.
                  </li>
                    <li>
                    <Psyllium />
                    </li>
                  <li>
                  Consider cyclosporine at 3-5 mg/kg q12h. Consider topical tacrolimus (0.01%). Consider tapering short course of immunosuppressive prednisone in cases where steroids are contraindicated or as an alternative to cyclosporine.  It may take 4-6 weeks to see improvement and relapse is common.
                  </li>
                  <li>
                  Secondary infection of the skin and anal sacs is common. Empirical antibiotic selection may include amoxicillin/clavulanic acid (15mg/kg q12h) or a cephalosporin + clindamycin or metronidazole. Treat for 2 weeks beyond clinical resolution.
                  </li>
                  <li>
                  An E collar will prevent licking and self trauma.
                  </li>
                  <li>
                  Prepare pet parents for frustration and expense.  Ketoconazole may be used concurrently to reduce the dose of cyclosporine (and reduce cost).
                  </li>
                  </ul>
                  </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>


  );
}
