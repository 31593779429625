import React, { useState } from "react";
import { Grid, TextField, InputAdornment, MenuItem, Typography, TableContainer, Paper, Alert } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

export default function EmergencyDrugCalculator() {
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [canine, setCanine] = useState(true);
  const [calciumGlutonate, setCalciumGlutonate] = useState(230);
  const [atropineInfo, setAtropineInfo] = useState(false);
  const [glycopyrolateInfo, setGlycopyrolateInfo] = useState(false);
  const [crystaloidInfo, setCrystaloidInfo] = useState(false);
  const [colloidInfo, setColloidInfo] = useState(false);



  function handleKgChange(event) {
    setWeightKg(event.target.value)
    event.target.value==="0" || event.target.value==="" ? setWeightLbs("") : setWeightLbs(Number(parseFloat(event.target.value)*2.2).toFixed(1))
  }

  function handleLbsChange(event) {
    setWeightLbs(event.target.value)
    event.target.value==="0" || event.target.value===""  ? setWeightKg("") : setWeightKg(Number(parseFloat(event.target.value)/2.2).toFixed(1))
  }

  function handleCanineChange(event) {
    setCanine(event.target.value)
  }

  function handleCalGlutChange(event) {
    setCalciumGlutonate(event.target.value)
  }

  function handleAtroChange() {
    setAtropineInfo(!atropineInfo)
  }

  function handleGlycoChange() {
    setGlycopyrolateInfo(!glycopyrolateInfo)
  }

  function handleCrystaloidChange() {
    setCrystaloidInfo(!crystaloidInfo)
  }

  function handleColloidChange() {
    setColloidInfo(!colloidInfo)
  }


  return (
    <>
      <Grid container spacing={2} sx={{pl: 2}}>
        <Grid item>
          <TextField
                  id="pet-name"
                  label="Name"
                  margin="normal"
                  size="small"
                />
        </Grid>
        <Grid item>
          <TextField
            id="select-canine-feline"
            select
            value={canine}
            label=""
            margin="normal"
            size="small"
            onChange={handleCanineChange}
          >
            <MenuItem value={true}>
              Canine
            </MenuItem>
            <MenuItem value={false}>
              Feline
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightLbs ? weightLbs : ""} onChange={handleLbsChange}
            label="Weight (lbs)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="weightLbs"
            type="number" value={weightKg ? weightKg : ""} onChange={handleKgChange}
            label="Weight (kg)"
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Drug (concentration)</TableCell>
              <TableCell align="left" rowSpan={2} sx={{verticalAlign: "top"}}>Dose Range</TableCell>
              <TableCell colSpan={2} sx={{verticalAlign: "top", borderBottom: "none", paddingBottom: "0px"}}>Volume (mL)</TableCell>
            </TableRow>
            <TableRow sx={{borderTop:"none"}}>
              <TableCell align="left" sx={{paddingTop: "0px"}}>min</TableCell>
              <TableCell align="left" sx={{paddingTop: "0px"}}>max</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Atropine (0.54 mg/mL)
              <IconButton aria-label="atropine info" component="span" size="small" onClick={handleAtroChange}>
                <InfoOutlined sx={{color: atropineInfo ? "#03a9f4" : "rgba(0, 0, 0, 0..54)"}}/>
              </IconButton>
            </TableCell>
            <TableCell align="left">0.02 - 0.04 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.02)/(0.54)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.04)/(0.54)).toFixed(2) : ""}</TableCell>
          </TableRow>
          {atropineInfo
            ?
            <TableRow>
              <TableCell sx={{p:0}} colSpan={4}>
                <Alert severity="info">Not indicated with dexmedetomidine and reflex bradycardia. Consider with bradycardia and hypotension.</Alert>
              </TableCell>
            </TableRow>
            :
            <>
            </>
          }
          <TableRow>
            <TableCell align="left" style={{display: 'flex', verticalAlign: 'center' }}>
              <Typography sx={{fontSize: '0.875rem', my: 'auto'}}>
                Calcium Gluconate
              </Typography>
              <TextField
                id="select-calcium-glutonate"
                select
                value={calciumGlutonate}
                label=""
                size="small"
                onChange={handleCalGlutChange}
                variant="standard"
                sx={{ml:0.5}}
                inputProps={{sx: {fontSize: '0.875rem', p:0}}}
              >
                <MenuItem value={230} sx={{fontSize: '0.875rem'}}>
                  (230 mg/mL) = 23%
                </MenuItem>
                <MenuItem value={100}  sx={{fontSize: '0.875rem'}}>
                  (100 mg/mL) = 10%
                </MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="left">50 - 100 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(50)/calciumGlutonate).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(100)/calciumGlutonate).toFixed(2) : ""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left">Dexamethasone SP (4 mg/mL)</TableCell>
            <TableCell align="left">0.1 - 0.5 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.1)/4).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.5)/4).toFixed(2) : ""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left">Diphenhydramine (50 mg/mL)</TableCell>
            <TableCell align="left">0.5 - 2 mg/kg (IM)</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.5)/50).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(2)/50).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Epinephrine (1 mg/mL)</TableCell>
            <TableCell align="left">0.01 - 0.1 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.01)/(1)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.1)/(1)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Glycopyrolate (0.2 mg/mL)
              <IconButton aria-label="glycopyrolate info" component="span" size="small" onClick={handleGlycoChange}>
                <InfoOutlined sx={{color: glycopyrolateInfo ? "#03a9f4" : "rgba(0, 0, 0, 0..54)"}}/>
              </IconButton>
            </TableCell>
            <TableCell align="left">0.005 - 0.01 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.005)/(0.2)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.01)/(0.2)).toFixed(2) : ""}</TableCell>
          </TableRow>
          {glycopyrolateInfo
            ?
            <TableRow>
              <TableCell sx={{p:0}} colSpan={4}>
                <Alert severity="info">Not indicated with dexmedetomidine and reflex bradycardia. Consider with bradycardia and hypotension.</Alert>
              </TableCell>
            </TableRow>
            :
            <>
            </>
          }
          <TableRow>
            <TableCell align="left">Lidocaine (20 mg/mL)</TableCell>
            {canine
              ?
                <>
                <TableCell align="left">2 - 4 mg/kg (Canine)</TableCell>
                <TableCell align="left">{weightKg ? (weightKg*(2)/(20)).toFixed(2) : ""}</TableCell>
                <TableCell align="left">{weightKg ? (weightKg*(4)/(20)).toFixed(2) : ""}</TableCell>
                </>
              :
                <>
                <TableCell align="left">0.25 - 0.5 mg/kg (Feline)</TableCell>
                <TableCell align="left">{weightKg ? (weightKg*(0.25)/(20)).toFixed(2) : ""}</TableCell>
                <TableCell align="left">{weightKg ? (weightKg*(0.5)/(20)).toFixed(2) : ""}</TableCell>
                </>
            }
          </TableRow>
        <TableRow>
          <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Reversal Agents</TableCell>
        </TableRow>
          <TableRow>
            <TableCell align="left">Atipamezole (5 mg/mL)</TableCell>
            <TableCell align="left">0.05-0.1 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.05)/(5)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.1)/(5)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Flumazenil (0.1 mg/mL)</TableCell>
            <TableCell align="left">0.01-0.04 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.01)/(0.1)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.04)/(0.1)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Naloxone (0.4 mg/mL)</TableCell>
            <TableCell align="left">0.04 mg/kg</TableCell>
            <TableCell align="left" colSpan={2}>{weightKg ? (weightKg*(0.04)/(0.4)).toFixed(2) : ""}</TableCell>
          </TableRow>
        <TableRow>
          <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Anti-seizure Drugs</TableCell>
        </TableRow>
          <TableRow>
            <TableCell align="left">Midazolam (5 mg/mL)</TableCell>
            <TableCell align="left">0.25-0.5 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.25)/(5)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(0.5)/(5)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Levitiracetam (100 mg/mL)</TableCell>
            <TableCell align="left">20-60 mg/kg</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(20)/(100)).toFixed(2) : ""}</TableCell>
            <TableCell align="left">{weightKg ? (weightKg*(60)/(100)).toFixed(2) : ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" colSpan={4} sx={{fontWeight: "700", pt: "1.4rem"}}>Shock Fluids</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Crystaloid (isotonic)
            <IconButton aria-label="cyrstaloid info" component="span" size="small" onClick={handleCrystaloidChange}>
              <InfoOutlined sx={{color: crystaloidInfo ? "#03a9f4" : "rgba(0, 0, 0, 0..54)"}}/>
            </IconButton>
            </TableCell>
            {canine
              ?
              <>
              <TableCell align="left">10-20 mL/kg (Canine)</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(10)).toFixed(0) : ""}</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(20)).toFixed(0) : ""}</TableCell>
              </>
              :
              <>
              <TableCell align="left">5-10 mL/kg (Feline)</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(5)).toFixed(0) : ""}</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(10)).toFixed(0) : ""}</TableCell>
              </>
            }
          </TableRow>

          {crystaloidInfo
            ?
            <TableRow>
              <TableCell sx={{p:0}} colSpan={4}>
                <Alert severity="info">Give over 10-20 minutes or longer.</Alert>
              </TableCell>
            </TableRow>
            :
            <>
            </>
          }

          <TableRow>
            <TableCell align="left">Colloid
            <IconButton aria-label="cyrstaloid info" component="span" size="small" onClick={handleColloidChange}>
              <InfoOutlined sx={{color: colloidInfo ? "#03a9f4" : "rgba(0, 0, 0, 0..54)"}}/>
            </IconButton>
            </TableCell>
            {canine
              ?
              <>
              <TableCell align="left">10-20 mL/kg (Canine)</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(10)).toFixed(0) : ""}</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(20)).toFixed(0) : ""}</TableCell>
              </>
              :
              <>
              <TableCell align="left">5-10 mL/kg (Feline)</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(5)).toFixed(0) : ""}</TableCell>
              <TableCell align="left">{weightKg ? (weightKg*(10)).toFixed(0) : ""}</TableCell>
              </>
            }
          </TableRow>
          {colloidInfo
            ?
            <TableRow>
              <TableCell sx={{p:0}} colSpan={4}>
                <Alert severity="info">Synthetic colloids have fallen out of favor due to concern for AKI, bleeding, and increased mortality. Consider plasma (such as thawed plasma) as an alternative.</Alert>
              </TableCell>
            </TableRow>
            :
            <>
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>

  );
}
