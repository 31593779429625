import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CardioExam() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/page/journal/19391676/homepage/free_reviews_and_consensus_statements.htm" target="_blank" rel="noopener noreferrer">ACVIM Consensus Statements</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://onlinelibrary.wiley.com/journal/19391676" target="_blank" rel="noopener noreferrer">Journal of Veterinary Internal Medicine</a>
          </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <a href="http://www.iris-kidney.com/" target="_blank" rel="noopener noreferrer">International Renal Interest Society (IRIS)</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <a href="https://vetmed.umn.edu/centers-programs/minnesota-urolith-center/urolith-analysis" target="_blank" rel="noopener noreferrer">Minnesota Urolith Center</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
            <a href="https://vetmed.tamu.edu/gilab/service/faq/" target="_blank" rel="noopener noreferrer">Texas A&M University Gastrointestinal Laboratory</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a href="https://miravistavets.com/" target="_blank" rel="noopener noreferrer">MiraVista Veterinary Diagnostics (for fungal testing)</a>
          </Typography>
        </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
