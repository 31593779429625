import React from 'react';

import {Accordion, Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DentigerousCyst() {

  return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Dentigerous Cyst</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                    Dentigerous cysts may form when unerupted teeth are hidden under the gumline. This is most common in short-faced dogs with unerupted adult teeth. The cyst starts to form around enamel epithelium of the crown of the tooth.                    
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    A missing tooth is the first sign. Destructive swelling starts under the gum line and will go missed until severe. This is a good reason to start dental discussions and prophylaxis early. Eventually, cyst may present as an oral swelling or even cause a pathologic fracture of the jaw.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    Differentials include oral abscess, granuloma, or neoplasia
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    Dental radiographs can confirm an unerupted tooth with surrounding lucency.
                                        </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                    Treatment: Extract unerupted tooth and surrounding cyst tissue. If an unerupted tooth needs to be left for any period of time, periodic (every 6 months) dental radiographs can monitor for cyst development. In some cases there is a enough bone loss (or even a pathologic fracture) that additional treatment is necessary&mdash;this may warrant referral to a dental specialist.
                    </Typography>
                  </Grid>
                </Grid>    
              </AccordionDetails>
             </Accordion>

  );
}
