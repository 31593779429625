import React from 'react';
import { Grid, Paper } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CherryEyePic from './images/cherryEye.jpg';

export default function CherryEye() {

  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cherry Eye (Protrusion of the Gland of the Third Eyelid)</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography>
            Cherry eye is common in young brachycephalic dogs. The gland should not be surgically removed; it should be replaced to preserve good tear production. The Morgan pocket technique (often modified with the starting knot placed on the anterior surface of the third eyelid) can be used to surgically correct the problem and preserve the gland. Prior to surgery, the eye may require additional lubricating drops or steroid drops if the gland is inflamed (and there is no corneal ulceration).
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
            <img src={CherryEyePic} alt="classic cherry eye" style={{borderRadius:"3%", maxWidth: "96%"}}/>
            <div>"Cherry Eye"</div>
          </Paper>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
