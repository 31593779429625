import React from 'react';
import { Grid } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AdditionalResources() {


  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Additional Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
          <Grid item xs={12}>
          <a href="https://www.aaha.org/aaha-guidelines/behavior-management/behavior-management-home/" target="_blank" rel="noopener noreferrer">2015 AAHA Canine and Feline Behavior Management Guidelines</a>
          </Grid>
          <Grid item xs={12}>
          <a href="https://avsab.org/resources/position-statements/" target="_blank" rel="noopener noreferrer">AVSAB Position Statements and Handouts</a>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}
