import React from 'react';
import { Grid, Typography, IconButton, Button, Dialog, AppBar, Toolbar, Slide, Paper } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import normalFetalHR from './images/normalFetalHR.gif';
import slowFetalHR from './images/slowFetalHR.gif';
import intestinalPeristalsis from './images/intestinalPeristalsis.gif';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{marginLeft: "24px",}}>
        Ultrasound examples
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">
              Ultrasound Examples
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} style={{paddingTop: "60px", margin: "auto",}}>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
            <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Normal</span>
            </Grid>
            <Grid item xs={12}>
            <img src={normalFetalHR} alt="Normal Fetal Heart Rate" style={{borderRadius:"8%", maxWidth: "96%"}}/>
            </Grid>
            <Grid item xs={12}>
            <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
            This fetuses heartrate was &ge;200 bpm.  Mom presented happy and healthy towards the end of gestation for pregnancy radiographs.
            </div>
            </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
          <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slow Fetal Heart Rate</span>
          </Grid>
          <Grid item xs={12}>
          <img src={slowFetalHR} alt="Slow Fetal Heart Rate" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          </Grid>
          <Grid item xs={12}>
          <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
          Mom presented with dark green vaginal discharge. This fetal heart rate is &le;160 bpm and needed to be delivered by caesarian section.  The breeding date was unknown, but there was fetal intestinal peristalsis noted on ultrasound.
          </div>
          </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
          <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Intestinal Peristalsis</span>
          </Grid>
          <Grid item xs={12}>
          <img src={intestinalPeristalsis}  alt="Fetal Intestinal Peristalsis" style={{borderRadius:"8%", maxWidth: "96%"}}/>
          </Grid>
          <Grid item xs={12}>
          <div style={{textAlign: "left", paddingLeft: "8px", maxWidth: "480px", margin: "auto"}}>
          Fetal intestinal peristalsis can be visualized by ultrasound at approximately 62-64 days.
          </div>
          </Grid>
          </Grid>
          </Paper>
          </Grid>
        </Grid>

      </Dialog>
    </div>
  );
}
