import React from 'react';
import { Grid, Paper, } from '@mui/material';

import NormalHipsLlat from './images/orthopedic/hindlimb/NormalHips_Llat.jpg';
import NormalHipsVD from './images/orthopedic/hindlimb/NormalHips_VD.jpg';

export default function NormalHipRads() {

  return (
          <>
          <Grid item xs={12} md={4}>
          <Paper style={{textAlign: "center", margin: "auto"}}>
          <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Normal Canine Hips</span></div>
          <img src={NormalHipsVD} alt="Normal Hips" style={{maxWidth: "96%"}}/>
          <img src={NormalHipsLlat} alt="Normal Hips" style={{maxWidth: "96%"}}/>
          <div>Patient positioning could have been better, but notice good coverage of the femoral heads by acetabula.</div>
          </Paper>
          </Grid>
          </>


  );
}
