import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DexTorb from './DexTorb';
import CanineAlfax from './CanineAlfax';
import AceOpioid from './AceOpioid'

export default function CanineSedationProtocols() {
  return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="canine-sedation-content"
          id="canine-sedation-header"
        >
          <Typography>Canine Sedation Protocols</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DexTorb/>
          <CanineAlfax/>
          <AceOpioid/>
        </AccordionDetails>
      </Accordion>

  );
}
